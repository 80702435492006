<div xmlns="http://www.w3.org/1999/html">
  <div  #sectiontoprint id="capture" class="main-container container">
    <div class="row ">
    </div>
    <p><BR>
      To,<BR>
      {{model.donated_by}} <BR>
      {{model.address}},<BR>
      {{model.city}}, {{model.zipcode}}<BR>
      {{model.country}}
    </p>
    <p>Greetings from Access Life!<BR>
      <BR>
      Thank you for your generous donation.</p>
    <hr>
    <p><strong>Receipt No : </strong> {{model.receipt_id}}<BR>
      <strong>Receipt Creation Date:  </strong>{{model.createdAt| date:'medium'}}</p>
    <!--  LOGO HERE-->
    <div class="text-center">
      <img src="../../../../assets/logo.png" class="img-fluid"  alt="logo"/>
      <div class="text-center">
        <h2>Access Life Assistance Foundation</h2>
        <h3>Jolly Land CHS, Bungalow No. 6, Ghatla Village Road, Chembur East, Mumbai, Maharashtra, India 400071<BR> Tel.: 80802 22377, Email: accounts@accesslife.org, www.accesslife.org</h3>
        <h1 class="fw-bolder">RECEIPT</h1>
      </div>
    </div>

    <p>Received with thanks from : {{model.donated_by}}  having PAN : {{model.pan}} </p>
    <p>The sum of :<span class="text-capitalize" >₹ {{model.totalAmountWithoutTax}} ({{amountinWords}})</span> Only  </p>
    <p><strong>Mode of Payment: </strong> (Online), <strong>Ref. No.</strong>{{model.order_id}}, </p>
    <p><strong>Remarks: </strong> Being amount received towards objects of the organization (including<span class="text-capitalize"> {{model.description}}</span>).
      <strong>EXEMPTION U/S.80-G OF THE I.T. ACT VIDE NO. AAMCA9705BF2014 DATED 28.05.2021 <BR>   PAN NO. : AAMCA9705B</strong>
    </p>
    <div class="row">

      <div class="align-content-end">
        <h3>For Accesslife Assistance Foundation</h3>
        <img src="../../../../assets/sign.png" class="img-fluid sign ">
        <h4 class="">Authorised Signatory</h4>
        <BR><BR>
        <!--  contatiner finished-->
        <p>Note: Cheques are subject to realization</p>

      </div>
    </div>
    <div>
    </div>
    <BR>
    <BR>
  </div>
  <div class="icon_image text-center"  title="Share As PDF" (click)="openPDF(sectiontoprint);"><a>
    <button mat-raised-button color="primary" class="mb-5">
      <mat-icon fontIcon="print">print</mat-icon>
      Get Receipt
    </button>
    <!--    <img src="assets/pdf_48.png"> -->
  </a></div>
</div>
