import {Component, OnDestroy, OnInit} from '@angular/core';
import {ChildService} from "../child.service";
import {FullChild} from "../../../interfaces/FullChild";
import {Subject, takeUntil} from "rxjs";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-ad-hoc-admission',
  templateUrl: './ad-hoc-admission.component.html',
  styleUrls: ['./ad-hoc-admission.component.scss']
})
export class AdHocAdmissionComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'action',];
  dataSource: FullChild[] = [];
  _destroyed$ = new Subject()

  constructor(private mChildService: ChildService,
              public dialogRef: MatDialogRef<AdHocAdmissionComponent>,
              ) {

  }

  ngOnInit(): void {
    this.mChildService.childRegistration().pipe(takeUntil(this._destroyed$)).subscribe(childs => {
      this.dataSource = []
      childs.forEach(child => {
        const ch: FullChild =child

        ch.id = child.id
        this.dataSource.push(ch)
      })
    })
  }

  ngOnDestroy(): void {
    this._destroyed$.complete()
  }
  view(){
    console.log('View clicked')
    this.dialogRef.afterClosed().subscribe(result=>{
      console.log('Alloted Closed')
    })
  }
  allot(){
    console.log('Allot clicked!')
  this.dialogRef.afterClosed().subscribe(result=>{
    console.log('Alloted Closed')
  })
  }
}
