import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ViewSponsorComponent} from "../../../components/unit-sponsorship/view-sponsor/view-sponsor.component";
import {MatDialog} from "@angular/material/dialog";
import {ViewOnlineDonorsComponent} from "./view-online-donors/view-online-donors.component";
import {MatPaginator} from "@angular/material/paginator";


export interface Donor {
  donorMobile: string;
  donorName: string;
  donoraddress: string;
  donorcity: string;
  email: string;
  pan: string;
  id: string
}
@Component({
  selector: 'app-online-donors-table',
  templateUrl: './online-donors-table.component.html',
  styleUrls: ['./online-donors-table.component.scss']
})
export class OnlineDonorsTableComponent implements OnInit {
  displayedColumns: string[] = [ 'donorName', 'donorMobile',  'donorCity', 'email', 'action'];
  dataSource!: MatTableDataSource<Donor>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private mFirestore: AngularFirestore,
              private matdialog: MatDialog) { }

  ngOnInit(): void {
    this.mFirestore.collection<Donor>('donors').valueChanges({idField:'id'}).subscribe(res =>{
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;

    })
  }

  openViewDialog(element:Donor): void {
    this.matdialog.open(ViewOnlineDonorsComponent, {
      width: '600px',
      maxHeight: '100vh',
      data: element
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
