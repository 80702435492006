import {MatPaginator} from "@angular/material/paginator";
import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {FullChild} from "../../../interfaces/FullChild";
import {MatSort, Sort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {ChildService} from "../child.service";
import {ChildCreateComponent} from "../child-create/child-create.component";
import {Child} from "../childdata";
import {Observable, Subject, Subscription, takeUntil} from "rxjs";
import {ViewRegisteredChildComponent} from "../view-registered-child/view-registered-child.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CentreService} from "../../../services/centre.service";
import {Centre} from "../../../interfaces/Centres";
import {MatSelectChange} from "@angular/material/select";
import {AuthService} from "../../../auth.service";
import * as XLSX from 'xlsx';
import {shareReplay, tap} from "rxjs/operators";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import dateutil from "rrule/dist/esm/src/dateutil";
import sort = dateutil.sort;
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {LogService} from "../../../services/log.service";
import {child} from "@angular/fire/database";
import {ChildJourneyComponent} from "../child-journey/child-journey.component";

@Component({
    selector: 'app-child-registration-table',
    templateUrl: './child-registration-table.component.html',
    styleUrls: ['./child-registration-table.component.scss']
})
export class ChildRegistrationTableComponent implements AfterViewInit, OnDestroy {
    childs?: Observable<FullChild[]>
    childscombine?: Observable<any[]>
    selectedCentreCode: string = ''
    selectedStatus = 'Staying'
    //To get user permssions
    permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
    registraionPermissions=this.permissions.filter((x)=>x.functionName=='Child Admission')



    displayedColumns: String[] = ['position', 'firstName', 'lastName', 'dateOfAdmission', 'city',  'status', 'selectUnit', 'isFollowUp', 'Action'];
    childData: FullChild[] = []
    private _destroyed$ = new Subject()
    @ViewChild(MatPaginator) paginator1!: MatPaginator;
    // @ViewChild(MatSort) sort!: MatSort;
    // @ts-ignore
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    dataSource!: MatTableDataSource<FullChild>;
    centres$: Observable<Centre[]>
    roleAs: string
    fileName = 'ALChild.xlsx';
    readCount = 0  //Used in tap operator
username=localStorage.getItem('userEmail')
    constructor(private matdialog: MatDialog,
                private router: Router,
                private matDialog: MatDialog,
                private _snackBar: MatSnackBar,
                private readonly centreService: CentreService,
                private readonly mFirestore: AngularFirestore,
                private readonly mLogService:LogService,
                readonly mAuth: AuthService,
                private childService: ChildService) {
        this.dataSource = new MatTableDataSource(this.childData);
        this.centres$ = this.centreService.getAllCentres()
        this.roleAs = mAuth.getRole()
        console.log('PPP', this.permissions[0])
// alert(this.username)
    }

    ngAfterViewInit(): void {
        //  this.getAllChildToExport()
        this.dataSource.sort = this.sort
        this.dataSource.paginator = this.paginator1

        let arr  =[]
        arr.push('1')
        arr.push('1')
        arr.push('1')
        arr.push('2')
        arr.push('1')
        arr.push('2')
        arr.push('1')
        arr.push('2')
        console.log('xcdsfsnflsfs',removeDuplicates(arr))
    }


    openpopup() {
        this.matdialog.open(ChildCreateComponent,
            {
                width: '90%', height: '95%',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '200ms',

            },
        )
    }

    showDischarge() {
        this._snackBar.open('Child Discharged Successfully', '', {duration: 3000})
    }

    datashow(index: Child) {
        console.log('showdata :' + index)
        this.matdialog.open(ViewRegisteredChildComponent,
            {
                width: '97%', height: '95%',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '100ms',
                data: index,
                autoFocus: false
                // @ts-ignore


            }
        )
      let activity={
        date:new Date().getTime(),
        section: 'Child Admission',
        action: 'View',

        description: `Session Viewed in Child Admission Table by user` ,
        currentIp: localStorage.getItem('currentip')!
      }
      this.mLogService.addLog(activity)
    }

    // documentToDomainObject = _ => {
    //   const object = _.payload.doc.data();
    //   object.id = _.payload.doc.id;
    //   return object;
    // }}

    applyFilter($event: Event) {
        const filterValue = ($event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }

    }

    gotoToEdit(element: FullChild) {
        // this.router.navigateByUrl('home/edit-admission', {state: element})
        this.router.navigateByUrl('home/edit-admission', {state: element})
        // this.router.navigate(['home/child-edit-admin',element.id], )
      let activity={
        date:new Date().getTime(),
        section: 'Child Admission',
        action: 'Edit',
        description: `Session Edited in Child Admission Table by user` ,
        currentIp: localStorage.getItem('currentip')!
      }
      this.mLogService.addLog(activity)

    }
    gotoToAdminEdit(element: FullChild) {
        // this.router.navigateByUrl('home/edit-admission', {state: element})
        // this.router.navigateByUrl('home/edit-admission', {state: element})
        this.router.navigate(['home/child-edit-admin',element.id], )
        let activity={
            date:new Date().getTime(),
            section: 'Child Admission',
            action: 'Edit',
            description: `Session Edited in Child Admission Table by user` ,
            currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)

    }

    ngOnDestroy(): void {
        this._destroyed$.next(null)
        this._destroyed$.complete()
    }

    getData(centreCode: string) {
        if (this.selectedCentreCode == '') {
            this._snackBar.open('Please select a centre to continue', '')._dismissAfter(2000)
        }
        if (this.selectedCentreCode == 'All') {
            // this.childs = this.childService.allotedChildRegistrationbyCenter(this.selectedStatus, this.selectedCentreCode).pipe(takeUntil(this._destroyed$))
            this.childs = this.childService.allotedChildRegistrationbyCenterCombineLatest(this.selectedStatus, this.selectedCentreCode).pipe(takeUntil(this._destroyed$))
        } else if (centreCode == 'All' && this.selectedStatus == 'All') this.childs = this.childService.childRegistration().pipe(takeUntil(this._destroyed$))

        else if (this.selectedCentreCode != 'All') this.childscombine = this.childService.allotedChildRegistrationbyCenterCombineLatest2(this.selectedStatus, this.selectedCentreCode)


        this.childscombine?.subscribe(data => {
            console.log('childList Subscription', data)
            //   console.assessment-table(data)
            const tempdocs: FullChild[] = []
            data.forEach((docSnapshots) => {
                // const c:Child
                // const c:Child=docSnapshots.payload.doc.id
                tempdocs.push((docSnapshots as FullChild))
                // console.assessment-table(docSnapshots.payload.doc.id)
            })
            tempdocs.reverse()
            this.childData = removeDuplicates( tempdocs)
            // this.childData  = removeDuplicates(this.childData)
            console.log('xcdsfsnflsfs',removeDuplicates(this.childData))
            // this.dataSource = new MatTableDataSource(this.childData)
            this.dataSource.data = this.childData
            this.dataSource.sort = this.sort
            this.dataSource.paginator = this.paginator1

        })
        // If the user changes the sort order, reset back to the first page.

        // this.sort1.sortChange.subscribe(() => (this.paginator1.pageIndex = 0));

    }

    setSelectedCentreCode($event: MatSelectChange) {
        this.selectedCentreCode = $event.value
        console.log(this.selectedCentreCode)
    }

//** Original Excel  To get All child (Only Visible to Gaurav )
// exportexcel() {
//   /* assessment-table id is passed over here */
//   let element = document.getElementById('excel-assessment-table');
//
//   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.childData);
//   // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
//   // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.receipts)
//   ws['!cols'] = [];
//   // ws['!cols'][19] = {hidden: true};
//
//   /* O1 is your Column in Excel*/
//
//   /* generate workbook and add the worksheet */
//   const wb: XLSX.WorkBook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//
//   /* save to file */
//   XLSX.writeFile(wb, this.fileName);
//
//
// }

    private getAllChildToExport() {
        this.mFirestore.collection<FullChild>('ALChild').get().pipe(
            tap(arr => console.log(`centre: read (${this.readCount += arr.docs.length} )docs`), shareReplay(1)
            ))
            .subscribe(res => {
                let allchild = res.docs.map(y => y.data())
                this.childData = allchild
                this.childData =removeDuplicates(allchild)
                this.dataSource.data = allchild
                // this.dataSource = new MatTableDataSource(allchild)
                this.dataSource.sort = this.sort
                this.dataSource.paginator = this.paginator1

            })
    }

    getDate(date: any): Date {
        return (date as Timestamp).toDate();
    }

    sortData($event: Sort) {
        console.log('sort called', $event.active)
        if ($event.active == 'firstName') {
            // this.dataSource.data.
        }
    }

  openChildJourney(element:any) {

      this.matdialog.open(ChildJourneyComponent,
        {
          width: '90%', height: '95%',
          enterAnimationDuration: '100ms',
          exitAnimationDuration: '100ms',
          data: element
        })

  }
}
function removeDuplicates(arr:any[]) {
    // return [...new Set(arr)];
    return arr.filter((value, index, self) => {
        return self.indexOf(value) === index;
    });




}
