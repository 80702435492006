import { Component, OnInit } from '@angular/core';
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../utils/formats";
import {FormControl, FormGroup, Validators} from "@angular/forms";



@Component({
  selector: 'app-caretakers',
  templateUrl: './caretakers.component.html',
  styleUrls: ['./caretakers.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS,useValue: MY_FORMATS }
  ]
})

export class CaretakersComponent implements OnInit {
  caretakerForm: any;
  submitted = false;
  files: any = [];

  constructor() {
  }

  ngOnInit(): void {
    this.caretakerForm = new FormGroup({
      "caretakercode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
      "childcode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
      "centercode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
      "caretakername": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "caretakerrecords": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "income": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
      "addresscode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*')]),
      "line1": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "line2": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "addresstype": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      // "dob": new FormControl('', [Validators.required]),
      "caretakerphoto": new FormControl('', [Validators.required]),
      "mobile": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'),Validators.minLength(10), Validators.maxLength(10)]),
      "donorCity": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "country": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "state": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "pincode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(6), Validators.maxLength(6)]),
      "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "caretakeraddress": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "relation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    })
  }

  relation: string[] = [
    '--Select--',
    'Father',
    'Mother',
    'Brother',
    'Sister',
    'Paternal Uncle',
    'Paternal Aunt',
    'Maternal Aunt',
    'Paternal Grandfather',
    'Paternal Grandmother',
    'Maternal Grandfather',
    'Maternal Grandmother'
  ];

  uploadFile(event:Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      this.files.push(element.name)
    }
  }
  deleteAttachment(files:any) {
    this.files.splice(files, 1)
  }







  SubmitData() {
    //first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    // if(form.isValid){
    //   then submit data to service
    // }
    console.log(this.caretakerForm.value);
    // this.donorservice.addDonor(this.DonorForm.value)
//then close dialog
//     this.dialogRef.close()
  }

  cancel() {
    // this.dialogRef.close()
    this.submitted = false;
    console.log(this.caretakerForm.reset())
  }

  get caretakercode() {
    return this.caretakerForm.get('caretakercode');
  }

  get childcode() {
    return this.caretakerForm.get('childcode');
  }

  get centercode() {
    return this.caretakerForm.get('centercode');
  }

  get caretakerrecords() {
    return this.caretakerForm.get('caretakerrecords');
  }

  get caretakeraddress() {
    return this.caretakerForm.get('caretakeraddress');
  }

  get income() {
    return this.caretakerForm.get('income');
  }

  get line1() {
    return this.caretakerForm.get('line1');
  }

  get line2() {
    return this.caretakerForm.get('line2');
  }

  get addresscode() {
    return this.caretakerForm.get('addresscode');
  }

  get addresstype() {
    return this.caretakerForm.get('addresstype');
  }

  get caretakername() {
    return this.caretakerForm.get('caretakername');
  }


  get mobile() {
    return this.caretakerForm.get('mobile');
  }

  get city() {
    return this.caretakerForm.get('city');
  }

  get country() {
    return this.caretakerForm.get('country');
  }

  get state() {
    return this.caretakerForm.get('state');
  }

  get pincode() {
    return this.caretakerForm.get('pincode');
  }

  get type() {
    return this.caretakerForm.get('type');
  }

  get occupation() {
    return this.caretakerForm.get('occupation');
  }
}
