import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MealEvent} from "../add-meal-event/add-meal-event.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MealMateService} from "../meal-mate.service";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatDialog} from "@angular/material/dialog";
import {AllotOnlineSlotComponent} from "./allot-online-slot/allot-online-slot.component";
import {OnlineBooking} from "../../../interfaces/OnlineBooking";

@Component({
    selector: 'app-manage-online-slots',
    templateUrl: './manage-online-slots.component.html',
    styleUrls: ['./manage-online-slots.component.scss']
})

export class ManageOnlineSlotsComponent implements OnInit {
    permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
    mealMatePermissions = this.permissions.filter((x) => x.functionName == 'Meal Mate')
    displayedColumns: string[] = ['donated_by', 'city', 'mealSlot',
        // 'Address',
        'pan', 'centreCode', 'start', 'payment_date', 'booking_id', 'otg_certificate', 'recieptNo', 'Action'];
    dataSource: MatTableDataSource<MealEvent>
    mAllData: any[] = []
    mFilteredData: MealEvent[] = []
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        private readonly mealService: MealMateService,
        private readonly mDialog:MatDialog,

    ) {
        this.dataSource = new MatTableDataSource<any>()
    }

    ngOnInit(): void {
        this.mealService.getOnlineBookings().subscribe(res => {
            this.mAllData = res
            console.log(this.mAllData)
            let m = res[0]
            // @ts-ignore
            let d = JSON.parse(m.details)
            console.log('details', d)
            this.mFilteredData = this.mAllData
            this.dataSource.data = this.mFilteredData
            this.dataSource.sort = this.sort
            this.dataSource.paginator = this.paginator
        })
    }

    allotSlot(element:OnlineBooking) {
this.mDialog.open(AllotOnlineSlotComponent,
    {
        data:element
    })
    }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
