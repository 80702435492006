import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {VolunteerService} from "../volunteer.service";



@Component({
  selector: 'app-volunteerregis',
  templateUrl: './volunteerregis.component.html',
  styleUrls: ['./volunteerregis.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class VolunteerregisComponent implements OnInit {
  volunteerForm: any;
  submitted = false;
  files: any = [];


  constructor(public dialogRef: MatDialogRef<VolunteerregisComponent>,
              private volunteerService:VolunteerService) { }

  ngOnInit(): void {
    this.volunteerForm = new FormGroup({
      "centrecode": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "volunteercode": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "employeerole": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "employeestatus": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "firstname": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "lastname": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "middlename": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "dob": new FormControl('', [Validators.required]),
      "mobile": new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}$'), Validators.minLength(10), Validators.maxLength(10)]),
      "donorCity": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "street": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "state": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "pincode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(6), Validators.maxLength(6)]),
      "gender": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    })
  }

  SubmitData() {
    //first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    // if(form.isValid){
    //   then submit data to service
    // }

    console.log(this.volunteerForm.value);
    this.volunteerService.addVolunteer(this.volunteerForm.value)

    console.log(this.volunteerForm.value);
//then close dialog
    this.dialogRef.close()
  }

  cancel() {
    this.dialogRef.close()
    this.submitted = false;
    console.log(this.volunteerForm.reset())
  }

  uploadFile(event:Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      this.files.push(element.name)
    }
  }
  deleteAttachment(files:any) {
    this.files.splice(files, 1)
  }


  get centrecode() {
    return this.volunteerForm.get('centrecode');
  }

  get employeestatus() {
    return this.volunteerForm.get('employeestatus');
  }

  get volunteercode() {
    return this.volunteerForm.get('volunteercode');
  }

  get employeerole() {
    return this.volunteerForm.get('employeerole');
  }

  get firstname() {
    return this.volunteerForm.get('firstname');
  }

  get lastname() {
    return this.volunteerForm.get('lastname');
  }

  get middlename() {
    return this.volunteerForm.get('middlename');
  }


  get mobile() {
    return this.volunteerForm.get('mobile');
  }

  get city() {
    return this.volunteerForm.get('city');
  }

  get street() {
    return this.volunteerForm.get('street');
  }

  get state() {
    return this.volunteerForm.get('state');
  }

  get pincode() {
    return this.volunteerForm.get('pincode');
  }

}
