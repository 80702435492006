import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {EmployeeService} from '../../../services/employee.service';
import {MatSelectChange} from "@angular/material/select";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Employee} from "../../../interfaces/Employee";
import {Centre} from "../../../interfaces/Centres";
import {Observable} from "rxjs";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {uid} from "chart.js/helpers";
import {LogService} from "../../../services/log.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";


@Component({
    selector: 'app-employee',
    templateUrl: './create-employee.html',
    styleUrls: ['./create-employee.scss'],
    providers: [
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    ]
})


export class CreateEmployee implements OnInit {
    employeeForm: any;
    centres$: Observable<Centre[]>
    submitted = false;
    files: any = [];

    data :UserRole[] = []
roles$:Observable<any>
departments$:Observable<any>
  @ViewChild('fileInput') fileInput!: ElementRef;
  constructor(public dialogRef: MatDialogRef<CreateEmployee>,
                public mFirestore: AngularFirestore,
              private readonly mLogService:LogService,
private readonly mDatabase:AngularFireDatabase,
                private employeeService: EmployeeService) {
    this.roles$ = this.mDatabase.list('types/Employee Roles').valueChanges()
    this.departments$ = this.mDatabase.list('types/Employee Departments').valueChanges()
        this.centres$ = this.mFirestore.collection<Centre>('ALCentres').valueChanges({idField: 'id'})
      this.data.push(   {functionName: "Child Admission", all:false,add:false,edit:false, archive:false, view:false, excel:false, showMenu:false })
      this.data.push({functionName: "Child Prescreening",all:false,add:false,edit:false , archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Counselling", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Education", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Meal Mate", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Unit Sponsorship", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "General Donation", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "CMS", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Child Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Counseling Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Education Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Donation Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Weekly Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Centres", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Employees", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Hospital", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Unit", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Types", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Donor", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Event", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Audit Logs", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
      this.data.push({functionName: "Dashboard", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
  }

    ngOnInit(): void {
        this.employeeForm = new FormGroup({
            "userName": new FormControl('', [Validators.required]),
            "email": new FormControl('', [Validators.required, Validators.email]),
            // "centreCode": new FormControl('', [Validators.required]),
            "centre": new FormControl('',
              ),
            // "email": new FormControl('', [ Validators.pattern('[a-zA-Z]*')]),
            // "employeestatus": new FormControl('', [ Validators.pattern('[a-zA-Z]*')]),
            "department": new FormControl('',),
            "jobtitle": new FormControl('', [Validators.pattern('[a-z A-Z ]*')]),
            "empCode": new FormControl('', [Validators.required]),
            "location": new FormControl(''),
            "mobile": new FormControl('', Validators.pattern('^[0-9]{10}$')),
            "role": new FormControl('', [Validators.required]),
          'profileImage':new FormControl(null, ),

          // "vehicleNo": new FormControl('MH-AK-8437', ),
            "permissions":new FormControl(this.data)
        })

    }


    cancel() {
        this.dialogRef.close()
        this.submitted = false;
        console.log(this.employeeForm.reset())
    }

    uploadFile(event: Event) {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList;
        for (let index = 0; index < files.length; index++) {
            const element = files[index];
            this.files.push(element.name)
        }
    }

    deleteAttachment(files: any) {
        this.files.splice(files, 1)
    }

    get centrecode() {
        return this.employeeForm.get('centrecode');
    }

    get employeestatus() {
        return this.employeeForm.get('employeestatus');
    }

    get employeecode() {
        return this.employeeForm.get('employeecode');
    }

    get employeerole() {
        return this.employeeForm.get('employeerole');
    }

    get firstname() {
        return this.employeeForm.get('firstname');
    }

    get lastname() {
        return this.employeeForm.get('lastname');
    }

    get email() {
        return this.employeeForm.get('email');
    }

    get middlename() {
        return this.employeeForm.get('middlename');
    }


    get mobile() {
        return this.employeeForm.get('mobile');
    }

    get city() {
        return this.employeeForm.get('city');
    }

    get street() {
        return this.employeeForm.get('address');
    }

    get state() {
        return this.employeeForm.get('state');
    }

    get pincode() {
        return this.employeeForm.get('pincode');
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.employeeForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    onSelectCentre($event: Event) {

    }
    async createUser(): Promise<void> {
        console.log(this.findInvalidControls())
        //first sumbit data
        this.submitted = true;

      console.log(this.employeeForm)
        // //Ideal Code Should be like this
        if (this.employeeForm.valid) {
            // then submit data to service
            // console.log(this.employeeForm.value);

            try {
                const password = generateRandomPassword(8); // Generate a random password of length 12
                console.log(password);

                const response = await this.employeeService.createUser(this.employeeForm.get('email').value, password);
                // const response = await this.employeeService.createUser('jay.dahale@gmail.com', password);
                console.log(response);

                this.employeeForm.addControl('uid',new FormControl(response.uid))
                let activity={
                    date:new Date().getTime(),
                    section: 'Employee',
                    action: 'Submit',

                    description: `Password Email Sent to ${this.employeeForm.get('email').value}`,
                    currentIp: localStorage.getItem('currentip')!
                }
                this.mLogService.addLog(activity)
                this.employeeService.addEmployee(this.employeeForm.value,response.uid).then(() => {

                  let activity={
                    date:new Date().getTime(),
                    section: 'Employee',
                    action: 'Submit',

                    description: `Employee added to Employee by`,
                    currentIp: localStorage.getItem('currentip')!
                  }
                  this.mLogService.addLog(activity)
                    // then close dialog
                    this.dialogRef.close()
                })
            } catch (error) {
                console.error('Error creating user:', error);
            }


        }
        else if (!this.employeeForm.valid)
        {
            this.submitted = false;
            console.log(this.employeeForm.value)
            alert("Form is Invalid , Please Complete your Form")
            console.log(  'INVALID CONTROLS'+this.findInvalidControls())
        }



  }


  onFileSelected(event: any): void {
    const fileInput = this.fileInput.nativeElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      this.employeeForm.patchValue({
        profileImage: file,
      })
    }
  }

}
function generateRandomPassword(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$&_';
    const passwordArray = [];

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        passwordArray.push(characters.charAt(randomIndex));
    }

    return passwordArray.join('');
}
