import { Component, OnInit } from '@angular/core';
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-floor-plan',
  templateUrl: './floor-plan.component.html',
  styleUrls: ['./floor-plan.component.scss']
})
export class FloorPlanComponent implements OnInit {
floorSelected?:string
  constructor() { }

  ngOnInit(): void {
  }

  setFloor($event: MatSelectChange) {
    this.floorSelected = $event.value
    // console.log(this.floorSelected)
  }
}
