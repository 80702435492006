import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import {MatDialogRef} from "@angular/material/dialog";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable, Observer, Subject} from "rxjs";
import {MatSelectChange} from "@angular/material/select";
import {UnitModel} from "../../../models/UnitModel";
import {SnapshotAction} from "@angular/fire/compat/database";
import {IndianPostalCodesService} from "indian-postal-codes";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-createcenter',
  templateUrl: './createcenter.component.html',
  styleUrls: ['./createcenter.component.scss']
})
export class CreatecenterComponent implements OnInit {
  fb: FormBuilder
  result:any
  // actionbtn: string="Save";
  centerForm: any;
  submitted = false;
  ammenities$: Observable<unknown[]>

  constructor(private matdialogref: MatDialogRef<CreatecenterComponent>,
              private fbApi: FormBuilder,
              private centreServiece: CentreService,
              private _snackBar: MatSnackBar,
              private readonly mLogService:LogService,
              private readonly postalCodeService:IndianPostalCodesService
  ) {
    this.fb = fbApi
    this.ammenities$ = centreServiece.getAmmenities()
  }

  ngOnInit(): void {

    this.centerForm = this.fb.group({
      centreName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      address: new FormControl('', [Validators.required,]),
      // occupiedUnits:new FormControl('',[Validators.required,Validators.pattern('[0-9]*')]),
      // availableUnits:new FormControl('',[Validators.required,Validators.pattern('[0-9]*')]),
      totalUnits: new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      city: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      state: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      country: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      zipcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(6), Validators.minLength(6)]),
      numberOfFloors: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(6), Validators.minLength(6)]),
      centreManager: new FormControl('',Validators.required),
      centreCordinator: new FormControl('',Validators.required),
      ammenities: new FormControl('', Validators.required),
      floorsArray: this.fb.array([]),
    });

  }

//submit

  save() {

    this.submitted = true;

    let center = this.centerForm.value as Centre

    // this.calculateTotalFloors()
    console.log(this.getUnitsMap())
    center.availableUnits = this.calculateTotalFloors()
    // center.totalUnits = +this.centerForm.get('totalUnits').value
    console.log('TOTALFLOORS', this.calculateTotalFloors())
    center.occupiedUnits = 0
    center.nonFunctionalUnits = 0
    center.totalUnits = this.calculateTotalFloors()
    // console.log(`Centre: ${center.centreName}`);
    // console.log(this.centerForm.value);
    if (this.centerForm.valid) {

      this.centreServiece.addCenter2(center, this.getUnitsMap())
      let activity={
        date:new Date().getTime(),
        section: 'Centre',
        action: 'Submit',

        description: `Centre added  by`,
        currentIp: localStorage.getItem('currentip')!
      }
      this.mLogService.addLog(activity)
      this.matdialogref.close();
    } else {
      this._snackBar.open('Please check all  fields', '', {duration: 3000})

      console.log(this.centerForm.valid)
      console.log(this.findInvalidControls())
    }
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.centerForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onReset() {
    // this.submitted = false;
    // console.log(this.centerForm.reset())
    this.matdialogref.close()
  }

  get centreName() {
    return this.centerForm.get('centreName');
  }

  get address() {
    return this.centerForm.get('address');
  }

  get occupiedRooms() {
    return this.centerForm.get('occupiedUnits');
  }

  get availableRooms() {
    return this.centerForm.get('availableUnits');
  }

  get totalRooms() {
    return this.centerForm.get('totalUnits');
  }

  get city() {
    return this.centerForm.get('city');
  }

  get state() {
    return this.centerForm.get('state');
  }

  get country() {
    return this.centerForm.get('country');
  }

  get zipcode() {
    return this.centerForm.get('zipcode');
  }

  get centerCordinator() {
    return this.centerForm.get('centreCordinator');
  }

  get centerManager() {
    return this.centerForm.get('centreManager');
  }

  get Ammenities() {
    return this.centerForm.get('ammenities')
  }

  getTotalFloors($event: MatSelectChange) {
    console.log('FLOOR SELECTION LOGIC TRIGGERED', $event.value)
    this.centerForm.get('floorsArray').clear()
    let floor = 0
    floor = +this.centerForm.get('numberOfFloors').value
    for (let i = 0; i <= floor; i++) {
      console.log('FLOOR', i)
      if (i != 0) this.addFloor()
    }
  }

  get floorFormArray(): FormArray {
    return this.centerForm.get('floorsArray') as FormArray;
  }

  addFloor() {
    this.floorFormArray.push(this.Floor());
  }

  unitsOnFloor(i: number): FormControl {
    const formArray = this.centerForm.get('floorsArray') as FormArray;
    return <FormControl<any>>formArray.at(i).get('unitsOnFloor')
  }

  Floor() {
    return this.fb.group({
      unitsOnFloor: this.fb.control('', [Validators.required, Validators.pattern('[0-9]*')]),
    })
  }

  calculateTotalFloors(): number {
    let TotalUnitsCalculated = 0
    // let unitMap = new Map<String,UnitModel>()

    for (let floorFormArrayKey in this.floorFormArray.controls) {
      console.log('floorFormArrayKey', floorFormArrayKey)
      TotalUnitsCalculated += +this.unitsOnFloor(Number(floorFormArrayKey)).value
      console.log('FLOOR KEY:VALUE', this.unitsOnFloor(+floorFormArrayKey).value)
      console.log('FLOOR NAME', `Floor ${floorFormArrayKey} `)
      console.log(TotalUnitsCalculated)

      let unit = new UnitModel()
      unit.floorNo = `Floor ${floorFormArrayKey} `
      unit.centreName = this.centerForm.get('centreName').value
    }
    this.centerForm.get('totalUnits').setValue(TotalUnitsCalculated)
    console.log('totalunits', this.centerForm.get('totalUnits').value)
    return +this.centerForm.get('totalUnits').value

  }

  getUnitsMap(): Array<UnitModel> {
    let unitArray = []
    for (let floorFormArrayKey in this.floorFormArray.controls) {
      console.log('outer', floorFormArrayKey)
      for (let i = 0; i < this.unitsOnFloor(+floorFormArrayKey).value; i++) {
        let unit = new UnitModel()
        console.log('inner', i)
        unit.floorNo = 'Floor ' + <Number>(+floorFormArrayKey)
        if (+floorFormArrayKey == 0) unit.floorNo = 'Ground Floor'
        unitArray.push(unit)
      }
      console.log(unitArray.length)
    }
    return unitArray
  }
  findPinCode(pinCode: any) {
    console.log('on blur fired')
    if (pinCode.length == 6) {
      this.postalCodeService.findByPinCode(pinCode).subscribe((data) => {
        console.log(data)
        this.result = data[0].PostOffice[0]
        if (data[0].Status === 'Success') {
          this.result = data[0].PostOffice[0];
          this.centerForm.get('state').setValue(this.result.State)
          this.centerForm.get('city').setValue(this.result.Block)
          this.centerForm.get('country').setValue(this.result.Country)
          // this.centerForm.get('district').setValue(this.result.District)
          console.log(this.result)
        } else if (data[0].Status === 'Error') {
          alert(data[0].Message);
          this.result = [];
        } else if (data[0].Status === '404') {
          alert(data[0].Message);
          this.result = [];

        }
        // console.log(data)
      });
    }
  }

}
