import {Component, Input} from '@angular/core';
import {FileUploadService} from "../upload.service";
import {map} from "rxjs/operators";
import {FileReference, FileUpload} from "../file-upload";
import {UploadFile} from "../../../interfaces/uploadFile";

@Component({
    selector: 'app-upload-list',
    templateUrl: './upload-list.component.html',
    styleUrls: ['./upload-list.component.scss']
})
export class UploadListComponent {
    // @ts-ignore
    fileUploads: FileUpload;
    showPreview = true
    @Input() pathToDatabase!: string
    @Input() dbType: 'realtime'|'firestore' = 'realtime'
    @Input ()storagePath?: string ='uploads/'

    constructor(private uploadService: FileUploadService) {
    }

    ngOnInit(): void {
        console.log('UploadListComponent', this.pathToDatabase)
   if(this.dbType=='realtime')     {
            this.uploadService.getFile(6, this.pathToDatabase, this.dbType).valueChanges().subscribe(c => {
                // console.log(c.payload.key)
                console.log('getFile realtime',c?.imageUrl)

                let file = c
                console.log('preview', file?.imageUrl)
                if (file?.imageUrl !== undefined) this.showPreview = true

                // let file:FileReference =new FileReference()
                // changes.map(c => ({ key: c.payload.key, ...c.payload.val()
                console.table(file)
                // @ts-ignore
                this.fileUploads = file
            });
        }
        if(this.dbType=='firestore')     {
            this.uploadService.getFileFromFirestore(6, this.pathToDatabase, this.dbType)
                .valueChanges().subscribe(c => {
                console.log('getFile firestore',c?.imageUrl)

                let file = c
                console.log('preview', file?.imageUrl)
                if (file?.imageUrl !== undefined) this.showPreview = true

                // let file:FileReference =new FileReference()
                // changes.map(c => ({ key: c.payload.key, ...c.payload.val()
                console.table(file)
                // @ts-ignore
                this.fileUploads = file
            });
        }

    }

    hide($event: boolean) {
        this.showPreview = $event
    }
}
