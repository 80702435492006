
<div #capture  id="capture">
<img  [src]="selectedImage" class="passport" style="max-height: '50%';max-width: '50%'" alt="poster">

<div class="child">
  <h2>Name: {{mData.firstName}} {{mData.lastName}}</h2>
  <h4>DOB: {{ mData.dob.toDate() | date: 'dd/MM/yyyy' }}</h4>
  <h4>City/Town/Village: {{mData.childCity}}</h4>
  <h4>State: {{mData.childState}}</h4>
  <h4>Diagnosis: {{ mData.diagnosis[0].name}}</h4>
</div>

<div class="donor text-center">
  <h2>
    ACCESS LIFE IS MY <br>
    HOME AWAY FROM HOME<br>
    because of<br>
    MY ANGEL<br>
    “{{mData.angleName}}”- LIFETIME
  </h2>
</div>


<img class="image" src="assets/unitSponsorship.png" alt="unitSponsorship">

</div>
<div class="mt-2 text-center">
  <input type="file" id="fileInput"  (change)="handleFileInput($event)">
  <label for="fileInput" class="upload-button">
    <button [disabled]="!fileUploaded" mat-raised-button color="primary"(click)="openPDF(capture)">
      <mat-icon>attach_file</mat-icon> Print
    </button>
  </label>
</div>
