<mat-card>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
    <th mat-header-cell *matHeaderCellDef>
      {{column.header}}
    </th>
    <td mat-cell *matCellDef="let row">
      {{column.cell(row)}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

</mat-card>
