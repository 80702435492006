import {Inject, Injectable, Renderer2} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {NgZone} from '@angular/core';
import {Router} from '@angular/router';
import firebase from "firebase/compat/app";
import {Observable} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {object} from "@angular/fire/database";
import {UserRole} from "./components/rename/add-user-role/add-user-role.component";
import {Employee} from "./interfaces/Employee";
import {IpService} from "./services/ip.service";
import {ActivityLog, LogService} from "./services/log.service";

//*
import {combineLatest} from 'rxjs';
import {switchMap} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FirebaseErrors} from "./FirebaseErrors";

export interface UserData {
    centreCode: string;
    email: string;
    role: string;
    uid: string;
    userName: string;
    id: string;
    centre: string;
    department: string;
    empCode: number;
    jobtitle: string;
    location: string;
    mobile: number;
    permissions?: UserRole[];
    centresAllowed: string[];
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    roleAs = '';
    userEmail = '';
    userData$: Observable<any>;
    user$: Observable<firebase.User | null>;
    userData: any
    permissions: UserRole[] = [];

//INJECT DEPENDANCY 2
    constructor(public mAuth: AngularFireAuth,
                public router: Router,
                private mDatabase: AngularFireDatabase,
                public ngZone: NgZone,
                private mLogService: LogService,
                private readonly _snackBar: MatSnackBar) {
        this.user$ = mAuth.authState;
        this.userData$ = this.user$.pipe(
            switchMap((user) =>
                user
                    ? this.mDatabase.object('users/' + user.uid).valueChanges()
                    : []
            )
        );
    }

    async SignIn(email: string, password: string) {
        localStorage.clear()
        try {
            const userCredential = await this.mAuth.signInWithEmailAndPassword(
                email,
                password
            ).catch(err => {
                this._snackBar.open(err.message)._dismissAfter(3000)
                console.log('Something went wrong:', FirebaseErrors.Parse(err.message));
            });
            if (userCredential && userCredential.user) {
                console.log('Login is Successful')
                const user = userCredential.user;
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('uid', user.uid);
                localStorage.setItem('userEmail', user.email!);
                this.userEmail = localStorage.getItem('userEmail')!;
                let activity = {
                    date: new Date().getTime(),
                    section: 'Login',
                    action: 'Login',
                    user: this.userEmail,
                    description: 'Login by user ' + this.userEmail,
                    currentIp: localStorage.getItem('currentip')!,
                };
                await this.setUserData(user.uid)

                await this.mLogService.addLog(activity);

            }

        } catch (err: any) {
            this._snackBar.open(err.message)._dismissAfter(3000)
            console.log('Something went wrong:', FirebaseErrors.Parse(err.message));
        }
    }

    async SignOut() {
        let activity = {
            date: new Date().getTime(),
            section: 'Logout',
            action: 'Logout',
            description: 'Logout by user ',
            currentIp: localStorage.getItem('currentip')!,
        };
        await this.mLogService.addLog(activity);
        try {
            await this.mAuth.signOut();
            localStorage.clear();
            this.router.navigate(['login']);
            console.log('Logged out!');
        } catch (err) {
            console.log('Error while signing out:', err);
        }
    }

    createUser(cred: any) {
        console.log(cred.value['username']);
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        console.log('Logged in user details (isLoggedIn)', user);
        return user !== null;
    }
    get getCurrentUser(): boolean {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        // console.log('Logged in user details (isLoggedIn)', user);
        return user !== null;
    }

    async setUserData(uid: string
    ) {
        console.log('into setUserData')
        console.log(uid)
        try {
            this.mDatabase
                .object<UserData>('users/' + uid)
                .valueChanges().subscribe(res => {
                let userData = res
                // console.log(res)
                console.log('USERDATA===>>>', userData);
                localStorage.setItem('userData', JSON.stringify(userData));

                const resdata = userData as UserData;
                if (resdata.role) {
                    localStorage.setItem('ROLE', resdata.role);
                    console.log(2);
                }

                if (resdata.permissions) {
                    localStorage.setItem('permissions', JSON.stringify(resdata.permissions));
                    console.log(3);
                }

                // console.log('JSON PERMISSIONS', JSON.parse(localStorage.getItem('permissions') || ''));
                console.log(4);

                this.router.navigate(['home/dashboard']).then(() => {
                    console.log('sending to dashboard');
                })


            })
        } catch (error) {
            console.error(error);
        }

    }


    getRole() {
        this.roleAs = localStorage.getItem('ROLE') || '';
        return this.roleAs;
    }

    changePassword(email: string) {
        this.mAuth.sendPasswordResetEmail(email);
    }

    getPermissions(uid: string) {
        this.mDatabase
            .list<Employee>('users/' + uid)
            .snapshotChanges()
            .subscribe((res) => {
                res.forEach((r) => {
                    console.log('EMPLOYEE', r.payload.toJSON());
                    let e = r.payload.toJSON() as Employee;
                    this.permissions = e.permissions!;
                    localStorage.setItem('permissions', JSON.stringify(this.permissions));
                });
            });
    }

}
