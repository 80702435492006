import {Component, Inject, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {MealMateService} from "../meal-mate.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AngularFireDatabase, AngularFireObject, SnapshotAction} from "@angular/fire/compat/database";
import {MealEvent} from "../add-meal-event/add-meal-event.component";
import {Observable, Subject, takeUntil} from "rxjs";
import {EditMealEventComponent} from "../edit-meal-event/edit-meal-event.component";
import {environment} from "../../../../environments/environment";
export interface ViewEvent{
  eventId:string,
  centreCode:string
}
@Component({
  selector: 'app-view-meal-event',
  templateUrl: './view-meal-event.component.html',
  styleUrls: ['./view-meal-event.component.scss']
})
export class ViewMealEventComponent implements OnInit,OnDestroy {
eventRef:Observable<MealEvent | null>
  env = isDevMode() ? environment.testMode : environment.productionMode

  // centre:Centre
  event: MealEvent | undefined
  _destroyed$=new Subject()
  constructor(private mealService: MealMateService,
              private dialog: MatDialog,
              private mDatabase:AngularFireDatabase,
              private dialogRef: MatDialogRef<ViewMealEventComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    // console.log('eventId', data.eventId)
    // console.log('centreCode', data.centreCode)
  this.eventRef=this.mDatabase.object<MealEvent>(`${this.env.mealmatesEvents}${data.eventId}`).valueChanges().pipe(takeUntil(this._destroyed$))
  this.eventRef.subscribe(red=>{
    this.event = red as MealEvent
    // console.assessment-table(this.event)
  })


  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  this._destroyed$.complete()
  }

  openEditdialog() {
    this.dialogRef.close()
    this.dialog.open(EditMealEventComponent,{data:this.data,
    minWidth:'600px',
    autoFocus:false})
  }
}
