import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AddMealEventComponent, MealEvent} from "./add-meal-event/add-meal-event.component";
import {Centre} from "../../interfaces/Centres";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialogRef} from "@angular/material/dialog";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {EditMealEventComponent} from "./edit-meal-event/edit-meal-event.component";
import {update, getDatabase, ref, get, set, increment} from "@angular/fire/database";
import {environment} from "../../../environments/environment";
import {FormGroup} from "@angular/forms";
import {OnlineBooking} from "../../interfaces/OnlineBooking";

interface Donor {
  donorMobile: string
  donorName: string
  donoraddress: string
  donorcity: string
  email: string
  pan: string
}

export class EventLogic {
  totalSlots? = false;
  lunchForChild? = false;
  lunchForFamily? = false;
  // lunchForChildAndFamily? = false
  dinnerForChild? = false
  dinnerForFamily? = false
  // dinnerForChildAndFamily? = false
  lunchAndDinnerForChild? = false
  lunchAndDinnerForFamily? = false
  // lunchAndDinnerForChildAndFamily? = false
}

@Injectable({
  providedIn: 'root'
})
export class MealMateService {
  // readonly EVENTSPATH = 'meal-mate/events/'
  env = isDevMode() ? environment.testMode : environment.productionMode

  // readonly EVENTSCOUNTERPATH = 'meal-mate/meal-mate-counter/'
  donorList?: Donor[]

  constructor(private mDatabase: AngularFireDatabase,
              public _snackBar: MatSnackBar,
              private mFirestore: AngularFirestore) {
  }

  addMealEvent(event: MealEvent, centreCode: string) {
    // event.centreCode=centreCode
    this.mDatabase.object(this.env.mealmatesEvents + `${event.id}`).set(event)
  }

  getMealEvents(centreCode: string) {
    // let q =this.mDatabase.list<MealEvent>(this.EVENTSPATH, ref => ref.orderByChild('centreCode').equalTo(centreCode)).valueChanges()
    let q = this.mDatabase.list<MealEvent>(this.env.mealmatesEvents, ref => ref.orderByChild('centreCode').equalTo(centreCode)).valueChanges()
    if (centreCode === 'All') q = this.mDatabase.list<MealEvent>(this.env.mealmatesEvents).valueChanges()
    return q
  }

  getMealMateCounter(centreCode: string) {
    // let q =this.mDatabase.list<MealEvent>(this.EVENTSPATH, ref => ref.orderByChild('centreCode').equalTo(centreCode)).valueChanges()
    let q = this.mDatabase.list<MealEvent>(this.env.mealmatesEvents, ref => ref.orderByChild('centreCode').equalTo(centreCode)).valueChanges()
    if (centreCode === 'All') q = this.mDatabase.list<MealEvent>(this.env.mealmatesEvents).valueChanges()
    return q
  }

  deleteMealEvent(id: string) {
    return this.mDatabase.object(this.env.mealmatesEvents + id).remove()
  }

  AddEvent(date: string, centre: Centre, event: MealEvent, dialogRef: MatDialogRef<AddMealEventComponent>) {
    console.log('ADD MEAL EVENT', event)
    let bookingFor = 'hhkg'
    switch (event.mealSlot) {
      case "Lunch for child": {
        bookingFor = 'lunchForChild';
        break;
      }
      case "Lunch for family": {
        bookingFor = 'lunchForFamily';
        break;
      }
      case "Lunch for child and family": {
        bookingFor = 'lunchForChildAndFamily';
        break;
      }
      case "Dinner for child": {
        bookingFor = 'dinnerForChild';
        break;
      }
      case "Dinner for family": {
        bookingFor = 'dinnerForFamily';
        break;
      }
      case "Dinner for child and family": {
        bookingFor = 'dinnerForChildAndFamily';
        break;
      }
      case "Lunch and dinner for child": {
        bookingFor = 'lunchAndDinnerForChild';
        break;
      }
      case "Lunch and dinner for family": {
        bookingFor = 'lunchAndDinnerForFamily';
        break;
      }
      case "Lunch and dinner for child and family": {
        bookingFor = 'lunchAndDinnerForChildAndFamily';
        break;
      }
    }


    return this.mDatabase.database.ref(this.env.mealmatesCounter + `${centre.centreCode}/` + date + `/${bookingFor}`).set(true).then(() => {
      this.addEvent(date, centre, event, dialogRef)
    })
  }


  EditEvent(newDate: string, oldDate: string, oldCentre: string, newCentre: string, mealSlot: string, eventId: string, dialogRef: MatDialogRef<EditMealEventComponent>) {
    console.log('INTO EDIT EVENT', mealSlot)
    let bookingFor = 'hhkg'
    bookingFor = this.getMealType(mealSlot, bookingFor);
    const dbRef = ref(getDatabase());

    const updates = {};

    // @ts-ignore
    updates[`${this.env.mealmatesCounter}/${newCentre}/${newDate}/${bookingFor}`] = true;
    // @ts-ignore
    updates[`${this.env.mealmatesCounter}/${oldCentre}/${oldDate}/${bookingFor}`] = false;
    // @ts-ignore
    updates[`${this.env.mealmatesEvents}/${eventId}/start`] = newDate;

    // @ts-ignore
    updates[`${this.env.mealmatesEvents}/${eventId}/centreCode`] = newCentre;
    return update(dbRef, updates);
    // updates[`posts/${key}/starCount`] = firebase.database.ServerValue.increment(1);
    // updates[`user-posts/${key}/stars/${uid}`] = true;
    // updates[`user-posts/${key}/starCount`] = firebase.database.ServerValue.increment(1);
    // firebase.database().ref().update(updates);

    // return this.mDatabase.database.ref(this.EVENTSCOUNTERPATH + `${centreCode}/` + newDate + `/${bookingFor}`).set(true).then(() => {
    //
    //
    //   //this.EditEvent(date, centre, event, dialogRef)
    // })
  }


  private getMealType(mealSlot: string, bookingFor: string) {
    switch (mealSlot) {
      case "Lunch for child": {
        bookingFor = 'lunchForChild';
        break;
      }
      case "Lunch for family": {
        bookingFor = 'lunchForFamily';
        break;
      }
      case "Lunch for child and family": {
        bookingFor = 'lunchForChildAndFamily';
        break;
      }
      case "Dinner for child": {
        bookingFor = 'dinnerForChild';
        break;
      }
      case "Dinner for family": {
        bookingFor = 'dinnerForFamily';
        break;
      }
      case "Dinner for child and family": {
        bookingFor = 'dinnerForChildAndFamily';
        break;
      }
      case "Lunch and dinner for child": {
        bookingFor = 'lunchAndDinnerForChild';
        break;
      }
      case "Lunch and dinner for family": {
        bookingFor = 'lunchAndDinnerForFamily';
        break;
      }
      case "Lunch and dinner for child and family": {
        bookingFor = 'lunchAndDinnerForChildAndFamily';
        break;
      }
    }
    return bookingFor;
  }

  addEvent(date: string, centre: Centre, event: MealEvent, dialogRef: MatDialogRef<AddMealEventComponent>) {
    this.AddDonor(event)

    const key = this.mDatabase.database.ref(this.env.mealmatesEvents + `${centre.centreCode}`).push().key
//               console.log('SERVICE:', key)
    if (key != null) {
      event.id = key
      //** Adding To Events
      if (event.paymentMethod != 'Pending') {
        this.getReceiptName().then(() => {
          event.receipt_id = localStorage.getItem('current_bill_counter')!

        })

      }
      this.mDatabase.object(this.env.mealmatesEvents + `/${key}`).set(event).then(() => {
        // this.mDatabase.database.ref(this.EVENTSPATH + `${centre.centreCode}`).get().then(res => {
        console.log('ADDED EVENT ON NEW NODE')
        console.log(event)
        //* If Payment method == Pending then adding to reminders node with events data else send to donation to generate receipt
        if (event.paymentMethod == 'Pending') {

          this.addReminer(date, centre, event, dialogRef)
        } else {

          // this.mFirestore.collection( `${this.env.mealMateDonations}`'MealmateDonations-test').doc(key).set(event).then(()=>{
          this.mFirestore.collection(`${this.env.mealMateDonations}`).doc(key).set(event).then(() => {

            // this.increaseCounter()

          })
          console.log('ADDED to MealmateDonations-test')


          this._snackBar.open(`EVENT BOOKED FOR ${date}`, 'SUCCESS', {duration: 4000})

          dialogRef.close()
        }
        // })


      })

    }
  }

  editEvent(date: string, centre: Centre, event: MealEvent, dialogRef: MatDialogRef<EditMealEventComponent>) {
    const key = this.mDatabase.database.ref(this.env.mealmatesEvents + `${centre.centreCode}`).push().key
//               console.log('SERVICE:', key)
    if (key != null) {
      event.id = key
      this.mDatabase.object(this.env.mealmatesEvents + `/${key}`).set(event).then(() => {

        // this.mDatabase.database.ref(this.EVENTSPATH + `${centre.centreCode}`).get().then(res => {
        console.log('ADDED EVENT ON NEW NODE')
        if (event.paymentMethod == 'Pending') {
          this.editReminer(date, centre, event, dialogRef)
        } else {
          this._snackBar.open(`EVENT BOOKED FOR ${date}`, 'SUCCESS', {duration: 4000})

          dialogRef.close()
        }
        // })


      })

    }
  }

  private addReminer(date: string, centre: Centre, event: MealEvent, dialogRef: MatDialogRef<AddMealEventComponent>) {

    // data.childCode, {
    //   followUpDate: data?.followUpDate,
    //   childCoe: data?.childCode,
    //   centre: data?.centreCode,
    //   firstName: data?.firstName,
    //   lastName: data?.lastName
    // }
    const _id = event?.id
    const _centreCode = event?.id
    const _donated_by = event?.donated_by
    const _eventDate = event?.start
    const _slot = event?.mealSlot
    const _number = event?.mobile
    const _followUpOn = event?.followUpOn
    const _remark = event?.remark
    const _paymentMethod = event?.paymentMethod

    const _updateData = {
      'id': _id,
      'centre': centre.centreName,
      'centreCode': centre.centreCode,
      'donated_by': _donated_by,
      'eventName': event?.title,
      'mealSlot': _slot,
      'email': event?.email,
      'event': event,
      'remark': _remark,
      'eventDate': _eventDate,
      'mobile': _number,
      'followUpDate': _followUpOn,
      'paymentMethod': _paymentMethod
    }

    this.mFirestore.collection('Reminders').doc('donations').collection('pending').doc(_id).set(
      _updateData).then(r => {
      this._snackBar.open('Reminder Set', 'OK ', {duration: 4000})
      dialogRef.close()
    })
  }

  private editReminer(date: string, centre: Centre, event: MealEvent, dialogRef: MatDialogRef<EditMealEventComponent>) {

    // data.childCode, {
    //   followUpDate: data?.followUpDate,
    //   childCoe: data?.childCode,
    //   centre: data?.centreCode,
    //   firstName: data?.firstName,
    //   lastName: data?.lastName
    // }
    const _id = event?.id
    const _centreCode = event?.id
    const _donated_by = event?.donated_by
    const _eventDate = event?.start
    const _slot = event?.mealSlot
    const _number = event?.mobile
    const _followUpOn = event?.followUpOn
    const _remark = event?.remark
    const _paymentMethod = event?.paymentMethod

    const _updateData = {
      'id': _id,
      'centre': centre.centreName,
      'donated_by': _donated_by,
      'eventName': event?.title,
      'mealSlot': _slot,
      'email': event?.email,
      'remark': _remark,
      'eventDate': _eventDate,
      'mobile': _number,
      'followUpDate': _followUpOn,
      'paymentMethod': _paymentMethod
    }

    this.mFirestore.collection('Reminders').doc('donations').collection('pending').doc(_id).set(
      _updateData).then(r => {
      this._snackBar.open('Reminder Set', 'OK ', {duration: 4000})
      dialogRef.close()
    })
  }

  fetchDonors() {
    return this.mFirestore.collection('donors').valueChanges()
  }


//** To GENERATE RECEIPT
  getReceiptName() {
    console.log('getting  receipt')

// const doc =ref(this.mDatabase,`donationtype/${donationtype}`)
    let msg = `2022-2023-80G-test-`;
    //  msg =   msg.replace('/', '-');
// FOR PRODUCTION
//     const documentRef = ref(this.mDatabase, `receiptcounter/counter`)

// FOR TEST
    const documentRef = ref(this.mDatabase.database, `receiptcounter/testcounter`)
    return get(documentRef).then((snapshot: { val: () => string; }) => {
      console.log('snapshot.val() : ' + snapshot.val())
      if (snapshot.val().toString().length === 1) msg = msg + '00' + snapshot.val();
      else if (snapshot.val().toString().length === 2) msg = msg + '0' + snapshot.val();
      else msg = msg + snapshot.val();
      console.log(msg)

      localStorage.setItem('current_bill_counter', msg);

    })
      .catch(() => {
      });

  }

  increaseCounter() {
    console.log('Increasing counter')
    // let msg = `2022-2023-80G-`;
    //  msg =   msg.replace('/', '-');

    // const documentRef = ref(this.mDatabase, `receiptcounter/counter`)
    const documentRef = ref(this.mDatabase.database, `${this.env.receiptCounter}`)
    return set(documentRef, increment(1)).then(() => {
      console.log('counter updated by 1')
    })
      .catch(() => {
      });
  }

  AddDonor(data: MealEvent) {
    console.log('Adding Donor')
    let donordata = {
      donorName: data.donated_by,
      donorMobile: data.mobile,
      pan: data.pan,
      zipcode: data.zipcode,
      donoraddress: data.address,
      donorcity: data.city,
      state: data.state,
      email: data.email,
    }


    const mobile = data.mobile
    return this.mFirestore.collection(this.env.donors).doc(mobile).set(donordata)
  }

  fetchAvailableSlots(centreCode: string | undefined, selectedDate: string | undefined) {
    console.log('CentreCode : ' + `${centreCode} ${selectedDate}`)
    return this.mDatabase.object<EventLogic>(`${this.env.mealmatesCounter}/${centreCode}/${selectedDate}`).valueChanges()
  }

  async bookBulk(mEventList: MealEvent[], totalAmount: number) {
    const counterUpdates = {}; // Create an empty object to store batch updates
    const eventUpdates = {}
    mEventList.forEach(it => {
      it.isBulkBooking=true
      it.bulkBookingTotalAmount = totalAmount.toString()
      console.log(it.mealFor)
      let bookingFor = ''
      bookingFor = this.getMealType(it.mealFor!, bookingFor)
      const counterRef = `Bulk${this.env.mealmatesCounter}/${it.centreCode}/${it.start}/${bookingFor}`
      let key = this.mDatabase.list(`Bulk${this.env.mealmatesEvents}`).push(it).key
      const eventRef = `${this.env.mealmatesEvents}${key}`
      // @ts-ignore
      counterUpdates[counterRef] = true
      // @ts-ignore
      eventUpdates[eventRef] = it
    })
    console.log('CALCULATED SLOTS', counterUpdates)
    console.log('CALCULATED EVENTS', eventUpdates)

    try {
      await this.mDatabase.object('/').update(counterUpdates); // Perform the batch update
      await this.mDatabase.object('/').update(eventUpdates); // Perform the batch update
      console.log('Batch operations completed successfully.');
    } catch (error) {
      console.error('Error performing batch operations:', error);
    }
  }

  async getSlotsByRange(centre: Centre | null, slot: string, formattedDatesArray: string[]) {
    let slots: any[] = [];

    await Promise.all(formattedDatesArray.map(async (date) => {
      console.log('into getSlotsBy Range Loop');
      let result = await this.mDatabase.database.ref(`ALMEAL-MATE/meal-mate-counter/${centre?.centreCode}/${date}`).get();
      console.log(result.exists());

      if (!result.exists()) {
        slots.push({
          date: date,
          slot: slot,
          slotAvailable: "Available"
        });
      } else {
        let obj = result.val();
        let available = 'AVAILABLE';

        if ((slot == 'lunchForChild' || slot == 'lunchForFamily') && result.exists()) {
          if (obj.lunchForChild || obj.lunchForFamily) {
            available = 'NOT AVAILABLE';
          } else {
            available = 'AVAILABLE';
          }
        }
        if ((slot == 'dinnerForChild' || slot == 'dinnerForFamily') && result.exists()) {
          if (obj.dinnerForChild || obj.dinnerForFamily) {
            available = 'NOT AVAILABLE';
          } else {
            available = 'AVAILABLE';
          }
        }

        console.log("result", result.val());
        slots.push({
          date: date,
          slot: slot,
          slotAvailable: available
        });
      }
    }));

    console.log(slots);
    return slots;
  }


  getOnlineBookings(){
   return  this.mDatabase.list<OnlineBooking>('ALPendingMeals').valueChanges([],{idField:'id'})
  }


}
// fgf
