<div class="shopping-wrapper" style="background: #878787; height: 100%;">
    <div class="container  ">
        <form [formGroup]="myForm" >
            <div class="text-center p-2">
                <img src="assets/cropped-logo.png" alt="" class="img-fluid">
            </div>
            <div class="my-cart-sec pt-2 ">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title></mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="container">

                        </div>
                        <div class="row ">
                            <div class="col-sm-2 col-md-2"></div>
                            <div class="col-sm-4 col-md-4">

                                <mat-card-content>
                                    <div>
                                        <mat-form-field appearance="outline" class="col-sm-12">
                                            <mat-label>Full Name</mat-label>
                                            <input matInput placeholder="Enter Name" formControlName="donated_by">

                                            <mat-error *ngIf="myForm.controls.donated_by.hasError('required')">
                                                Full Name is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline" class="col-sm-12 ">
                                            <mat-label>Address</mat-label>
                                            <textarea matInput formControlName="address"></textarea>
                                            <mat-error
                                                    *ngIf="myForm.controls.address.hasError('email') && !myForm.controls.address.hasError('required')">
                                                Please enter address
                                            </mat-error>
                                            <mat-error *ngIf="myForm.controls.address.hasError('required')">
                                                Address is <strong>required</strong></mat-error>

                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline" class="col-sm-12">
                                            <mat-label>State</mat-label>
                                            <mat-select formControlName="state" class="">
                                                <mat-option *ngFor="let s of stateslist" [value]="s.name">{{s.name}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="myForm.controls.state.hasError('required')">
                                                State is <strong>required</strong></mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline" class="col-sm-12">
                                            <mat-label>Pincode</mat-label>
                                            <input matInput placeholder="Enter pincode" formControlName="zipcode">
                                            <mat-error *ngIf="myForm.controls.zipcode.hasError('required')">Pincode is
                                                <strong>required</strong></mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div>
                                        <mat-form-field appearance="outline" class="col-sm-12">
                                            <mat-label>Id Proof</mat-label>
                                            <input matInput placeholder="Id Proof" formControlName="idproof">
                                            <mat-error
                                                    *ngIf="myForm.controls.idproof.hasError('email') && !myForm.controls.idproof.hasError('required')">
                                                Please enter Id proof
                                            </mat-error>
                                            <mat-error *ngIf="myForm.controls.idproof.hasError('required')">
                                                Id Proof is <strong>required</strong></mat-error>
                                            <mat-icon matSuffix>credit_card</mat-icon>
                                        </mat-form-field>

                                    </div>
                                    <div>

                                        <mat-form-field appearance="outline" class="col-sm-12">
                                            <mat-label>Email</mat-label>
                                            <input matInput placeholder="Email" formControlName="email">
                                            <mat-error
                                                    *ngIf="myForm.controls.email.hasError('email') && !myForm.controls.email.hasError('required')">
                                                Please enter a valid email e.g. <strong>xyz@gmail.com</strong>
                                            </mat-error>
                                            <mat-error *ngIf="myForm.controls.email.hasError('required')">
                                                Email is <strong>required</strong></mat-error>
                                        </mat-form-field>

                                    </div>
                                    <div>

                                        <mat-form-field appearance="outline" class="col-sm-12">
                                            <mat-label>Donation Type</mat-label>
                                            <input matInput placeholder="Donation Type" class="text-capitalize"
                                                   formControlName="donation_type">
                                            <mat-error *ngIf="myForm.controls.donation_type.hasError('required')">
                                                Donation type is <strong>required</strong></mat-error>
                                        </mat-form-field>

                                        <mat-form-field appearance="outline">
                                            <mat-label>Choose a date</mat-label>
                                            <input matInput formControlName="date" [matDatepicker]="picker">
                                            <mat-hint>MM/DD/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>


                                </mat-card-content>

                            </div>

                            <div class="col-sm-4 col-md-4">
                                <div>

                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Nationality</mat-label>
                                        <input matInput placeholder="Indian" class="text-capitalize"
                                               formControlName="nationality">
                                        <mat-error *ngIf="myForm.controls.nationality.hasError('required')">
                                            Nationality is <strong>required</strong></mat-error>
                                    </mat-form-field>


                                </div>



                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>City</mat-label>
                                        <input matInput placeholder="Enter City" formControlName="city">
                                        <mat-icon matSuffix>location_city</mat-icon>
                                        <mat-error *ngIf="myForm.controls.city.hasError('required')">
                                            City is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>

                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Pan</mat-label>
                                        <input matInput placeholder="PAN Number" class="col-sm-12" formControlName="pan">
                                        <mat-error *ngIf="myForm.controls.pan && !myForm.controls.pan.hasError('required') ">
                                            Enter a valid pan <strong>e.g. ABCDE9600Z</strong></mat-error>
                                        <mat-error *ngIf="myForm.controls.pan.hasError('required') ">
                                            PAN is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Mobile</mat-label>
                                        <input matInput placeholder="Enter Mobile Number" formControlName="mobile" [maxLength]="10">
                                        <mat-icon matSuffix>phone</mat-icon>
                                        <mat-error *ngIf="myForm.controls.mobile.hasError('required')">
                                            Mobile Number is <strong>required</strong></mat-error>

                                        <mat-error *ngIf="myForm.controls.mobile.hasError('pattern')">Enter a valid mobile number
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Amount</mat-label>
                                        <input matInput placeholder="Amount" formControlName="amount"
                                               formControlName="donation_amount"
                                               />

                                    </mat-form-field>

                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Group Key</mat-label>
                                        <input matInput placeholder="groupKey" formControlName="groupKey">
                                        <mat-error *ngIf="myForm.controls.groupKey.hasError('required')">
                                            groupKey is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Description</mat-label>
                                        <input matInput placeholder="Description" formControlName="description">
                                        <mat-error *ngIf="myForm.controls.description.hasError('required')">
                                            Description is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Order Id</mat-label>
                                        <input matInput placeholder="Order Id" formControlName="order_id">
                                        <mat-error *ngIf="myForm.controls.order_id.hasError('required')">
                                            Order Id is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Payment Id</mat-label>
                                        <input matInput placeholder="Receipt Id" formControlName="payment_id">
                                        <mat-error *ngIf="myForm.controls.payment_id.hasError('required')">
                                            Payment Id is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="col-sm-12">
                                        <mat-label>Reciept Number</mat-label>
                                        <input matInput placeholder="Receipt Id" formControlName="receipt_id">
                                        <mat-error *ngIf="myForm.controls.receipt_id.hasError('required')">
                                            Order Id is <strong>required</strong></mat-error>
                                    </mat-form-field>
                                </div>

                                <!--                <BR>-->
                                <!--                <BR>-->

                                <!--                <mat-checkbox class="example-margin hidden" formControlName="addtax" (change)="changeIsTransaction($event)">I-->
                                <!--                  agree to cover transaction charges-->
                                <!--                </mat-checkbox>-->
                                <BR>

                            </div>

                            <div class="col-sm-2 col-md-2"></div>
                        </div>
                        <!--ORDER PLACE-->
                        <div class="order-place">
                            <a (click)="submit()" class="btn btn-order">Submit</a>
                        </div>

                    </mat-card-content>
                </mat-card>

                <div class="col-sm-4">
                    <!--          <div class="item-price-details">-->
                    <!--            <div class="price-box">-->
                    <!--              <div class="price-heading heading-border">-->
                    <!--                <h5>PRICE DETAILS</h5>-->
                    <!--              </div>-->
                    <!--              <div class="price-section">-->
                    <!--                <div class="price-item">-->
                    <!--                  Price ({{ quantity }}-->
                    <!--                  {{ quantity > 1 ? "items" : "item" }})<span>-->
                    <!--                    ₹{{ totalPrice }}</span-->
                    <!--                >-->
                    <!--                </div>-->
                    <!--                <div class="price-item">-->
                    <!--                  Delivery<span>-->
                    <!--                    <span class="free-shiping shiping-charges"> free</span>-->
                    <!--                  </span>-->
                    <!--                </div>-->

                    <!--                <div class="price-total">-->
                    <!--                  <div class="price-item">-->
                    <!--                    Total Payable<span> ₹{{ totalPrice }}</span>-->
                    <!--                  </div>-->
                    <!--                </div>-->
                    <!--              </div>-->
                    <!--              <div class="price-save bottom-border">-->
                    <!--                You will save ₹560 on this order-->
                    <!--              </div>-->


                </div>

            </div>

            <!--      RESPONSE-->
        </form>
    </div>
  DATE:  {{
    receiptDate|date:'medium'}}
<!--    <prettyjson [obj]="this.myForm.value"> </prettyjson>-->
</div>

