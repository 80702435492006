<div class="container ">
    <div class="row">
        <div class="col-md-2 col-sm-2">
            <h4 class="text-center"><strong>Admission History</strong></h4>
            <mat-stepper orientation="vertical">
                <ng-container *ngFor="let d of  childData.admissionData;let i = index">
                    <mat-step>
                        <ng-template *ngIf="i==0" matStepLabel>Admission</ng-template>
                        <ng-template *ngIf="i==1" matStepLabel>Discharge</ng-template>
                        <ng-template *ngIf="i%2==0" matStepLabel>Followup Admission</ng-template>
                        <ng-template *ngIf="i%2==1" matStepLabel>Followup Discharge</ng-template>
                        <ng-template matStepContent>
                            <ng-container *ngIf="d.dateOfAdmission">{{d.dateOfAdmission.toDate() | date : 'dd/MM/yyyy' }}
                                <span *ngIf="d.centreCode">  ({{d.centreCode}})</span></ng-container>
                            <ng-container
                                    *ngIf="d.dateOfDischarge">{{d.dateOfDischarge.toDate() | date : 'dd/MM/yyyy' }}</ng-container>
                        </ng-template>

                    </mat-step>

                </ng-container>

            </mat-stepper>

        </div>
        <div class="col-md-6">
            <div class=" pt-5 ">

                <mat-card>
                    <mat-card-header>
                        <!--            <mat-card-title-group>-->
                        <div mat-card-avatar class="example-header-image">
                            <img [src]="profileUrl" alt='childData photo' class="img-fluid">
                        </div>
                        <mat-card-title>{{childData.firstName}} {{childData.lastName}}</mat-card-title>
                        <mat-card-subtitle> {{childData.childCode}} </mat-card-subtitle>
                        <!--              <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" >-->
                        <!--            </mat-card-title-group>-->
                    </mat-card-header>
                </mat-card>
                <hr>

                <mat-accordion class="example-headers-align" multi>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            childData Details
                        </mat-expansion-panel-header>

                        <mat-card>

                            <mat-card-content>
                                <table class="table table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th scope="row"> Current Centre</th>
                                        <td>{{childData?.centreName}} ({{childData?.centreCode}})</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">childData Code</th>
                                        <td>{{childData.childCode}}</td>
                                    </tr>
                                    <tr>
                                        <th>Unit Alloted</th>
                                        <td>{{childData.selectUnit}}</td>
                                    </tr>

                                    <tr>
                                        <th scope="row">First Name</th>
                                        <td>{{childData.firstName}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Middle Name</th>
                                        <td>{{childData.middleName}}</td>
                                    </tr>

                                    <tr>
                                        <th>Last Name</th>
                                        <td>{{childData.lastName}}</td>
                                    </tr>
                                    <tr>
                                        <th>Mobile</th>
                                        <td>{{childData.mobile}}</td>
                                    </tr>
                                    <tr>
                                        <th>Blood Group Type</th>
                                        <td>{{childData.bloodGroup}}</td>
                                    </tr>
                                    <tr>
                                        <th>Date Of Birth (DD/MM/YYYY)</th>
                                        <td>{{dateFormatted| date }}</td>
                                    </tr>
                                    <tr>
                                        <th>Age as on Date</th>
                                        <td>{{getCalculatedAge(childData.dob) }}</td>
                                    </tr>
                                    <tr>
                                        <th>childData Aadhar Code</th>
                                        <td>{{childData.childAadhaar}}</td>
                                    </tr>
                                    <tr>
                                        <th>Address</th>
                                        <td>{{childData.address}}</td>
                                    </tr>
                                    <tr>
                                        <th>City</th>
                                        <td>{{childData.city}}</td>
                                    </tr>
                                    <tr>
                                        <th>District</th>
                                        <td>{{childData.district}}</td>
                                    </tr>
                                    <tr>
                                        <th>State</th>
                                        <td>{{childData.state}}</td>
                                    </tr>
                                    <tr>
                                        <th>Country</th>
                                        <td ><span *ngIf="childData.country==null">India </span>  {{childData.country}}</td>
                                    </tr>
                                    <tr>
                                        <th>Gender</th>
                                        <td>{{childData.gender}}</td>
                                    </tr>
                                    <tr>
                                        <th>Pincode</th>
                                        <td>{{childData.pincode}}</td>
                                    </tr>

                                    <tr>
                                        <th>Hobbies</th>
                                        <td>{{childData.hobbies}}</td>
                                    </tr>
                                    <tr>
                                        <th>Apsirations</th>
                                        <td>{{childData.aspirations}}</td>
                                    </tr>
                                    <tr>
                                        <th>Allergies</th>
                                        <td>{{childData.allergies}}</td>
                                    </tr>
                                    <!--              <tr><th >Method</th><td>{{childData.method}}</td></tr>-->
                                    <!--Basic Info-->
                                    <tr>
                                        <th>Stayed At AL Centre Before</th>
                                        <td>{{childData.stayedBefore}}</td>
                                    </tr>
                                    <tr>
                                        <th>AL  Bag given</th>
                                        <td>{{childData.bag}}</td>
                                    </tr>
                                    <tr>
                                        <th> Mother / Father / Guardian will stay  </th>
                                        <td>{{childData.guardianStayStatus}}</td>
                                    </tr>

                                    <ng-container *ngFor="let g of childData.guardians;let  i =index">
                                        <tr>
                                            <th>Caretaker</th>
                                            <td>{{i + 1}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker First Name</th>
                                            <td>{{g.firstName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Middle Name</th>
                                            <td>{{g.middleName}}</td>
                                        </tr>
                                        <tr>
                                            <th> Last Name</th>
                                            <td>{{g.lastName}}</td>
                                        </tr>

                                        <!--                <tr>-->
                                        <!--                  <th>Caretaker Aadhar Number</th>-->
                                        <!--                  <td>{{g.aadharNumber}}</td>-->
                                        <!--                </tr>-->
                                        <tr>
                                            <th>Annual Income</th>
                                            <td>{{g.annualIncome}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Contact Number</th>
                                            <td>{{g.contactNo}}</td>
                                        </tr>

                                        <tr>
                                            <th>Caretaker Occupation</th>
                                            <td>{{g.occupation}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Relation With childData</th>
                                            <td>{{g.relation}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Other Documents</th>
                                            <td>{{g.otherDocuments}}</td>
                                        </tr>
                                        <tr>
                                            <th>Caretaker Other Skills</th>
                                            <td>{{g.skills}}</td>
                                        </tr>

                                    </ng-container>
                                    <tr>
                                        <th>Emergency Contact 1</th>

                                        <td> {{childData.emergencyContactName1}}<br>
                                            {{childData.emergencyContactNumber1}}<br>
                                            {{childData.emergencyContactRelation1}}</td>

                                    </tr>

                                    <tr>
                                        <th>Emergency Contact 2</th>

                                        <td> {{childData.emergencyContactName2}} <br>
                                            {{childData.emergencyContactNumber2}} <br>
                                            {{childData.emergencyContactRelation2}}</td>

                                    </tr>
                                    <ng-container *ngFor="let s of childData.siblings;let  i =index">

                                        <tr>
                                            <th>Sibling</th>
                                            <td>{{i + 1}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Name</th>
                                            <td>{{s.firstName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Gender</th>
                                            <td>{{s.gender}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Age</th>
                                            <td>{{s.age}}</td>
                                        </tr>
                                        <tr>
                                            <th>Sibling Contact Number</th>
                                            <td>{{s.mobile}}</td>
                                        </tr>
                                    </ng-container>
                                    <tr>
                                        <th>Hospital Name</th>
                                        <td>{{childData?.hospital}}</td>
                                    </tr>

                                    <tr>
                                        <th>Doctors Name</th>
                                        <td>{{childData?.doctorName}}</td>
                                    </tr>
                                    <tr>
                                        <th>File Number</th>
                                        <td>{{childData?.fileNo}}</td>
                                    </tr>

                                    <tr>
                                        <th>Medical Social Worker Name</th>
                                        <td>{{childData?.socialName}}</td>
                                    </tr>
                                    <tr>
                                        <th>Height At the time of Admission</th>
                                        <td>{{childData?.height}} (in cm)</td>
                                    </tr>
                                    <tr>
                                        <th>Weigh At the time of Admission</th>
                                        <td>{{childData?.weight}} (in kg)</td>
                                    </tr>
                                    <tr>
                                        <th>Prescribed Treatment</th>
                                        <td>{{childData?.prescribed}}</td>
                                    </tr>
                                    <tr>
                                        <th>Allergies</th>
                                        <td>{{childData?.allergiesSelected}}</td>
                                    </tr>
                                    <tr>
                                        <th>Diagnosis</th>
                                        <td>{{childData?.diagnosisSelected}}</td>
                                    </tr>
                                    <tr>
                                        <th>childData Story</th>
                                        <td>{{childData?.sickness}}</td>
                                    </tr>

                                    <tr>
                                        <th>Expected Duration of Treatment</th>
                                        <td>{{childData?.months}} </td>
                                        <td>{{childData?.days}} </td>
                                        <td>{{childData?.treatment}} </td>
                                    </tr>
                                    <tr>
                                        <th>Other Medical Information</th>
                                        <td>{{childData.medical}}</td>
                                    </tr>
                                    </tbody>
                                </table>

                            </mat-card-content>
                        </mat-card>
                    </mat-expansion-panel>
                </mat-accordion>

            </div>
            <!--------------------------------------------- TABLE ENDED --------------------------------------------->

            <!--------------------------------------------- DISCHARGE PROCESS FIELDS --------------------------------------------->
            <mat-card class="mt-2" *ngIf="showDischarge">
                <mat-card-header>
                    <mat-card-title *ngIf="childData.admissionData.length==1">Discharge Form</mat-card-title>
                    <mat-card-title *ngIf="childData.admissionData.length!=1">Follow Up Discharge Form</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="dischargeForm">

                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Height (in cm)</mat-label>
                                    <input matInput formControlName="height" maxlength="3">
                                    <mat-error *ngIf="dischargeForm.get('height')?.errors?.required">Please enter
                                        height
                                    </mat-error>
                                    <mat-error *ngIf="dischargeForm.get('height')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Weight</mat-label>
                                    <input matInput formControlName="weight" maxlength="5">
                                    <mat-error *ngIf="dischargeForm.get('weight')?.errors?.required">Please enter
                                        weight
                                    </mat-error>
                                    <mat-error *ngIf="dischargeForm.get('weight')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>

                                </mat-form-field>
                            </div>
                            <div class="col-md-4 col-sm-12">
                                <mat-form-field class="example-form-field" appearance="outline">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput [matDatepicker]="dischargeDatePicker"
                                           formControlName="dischargeDate">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="dischargeDatePicker">
                                        <!--            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #dischargeDatePicker></mat-datepicker>
                                </mat-form-field>

                            </div>

                            <div class="col-md-4 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Next Followup Date</mat-label>
                                    <input matInput [matDatepicker]="followUpPicker"
                                           formControlName="followUpDate">
                                    <mat-hint>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        DD/MM/YYYY
                                    </mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="followUpPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #followUpPicker></mat-datepicker>
                                </mat-form-field>
                            </div>

                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Reason for discharge</mat-label>
                                    <mat-select formControlName="reason">
                                        <mat-option>--Reason for Discharge--</mat-option>
                                        <mat-option value="Always Champion">Always Champion</mat-option>
                                        <mat-option value="Happy Discharge">Happy Discharge</mat-option>
                                        <mat-option value="Followup Treatment Completed">Followup Treatment Completed
                                        </mat-option>
                                        <mat-option value="childData Expired">childData Expired</mat-option>
                                        <mat-option value="Palliative Discharge">Palliative Discharge</mat-option>
                                        <mat-option value="Refer to Outside Hospital">Refer to Outside Hospital
                                        </mat-option>
                                        <mat-option value="DAMA">DAMA</mat-option>
                                        <mat-option value="Non Complieance">Non-Compliance</mat-option>
                                        <mat-option value="Self Discharge">Self Discharge</mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Remark</mat-label>
                                    <textarea matInput placeholder="Enter Remark" formControlName="remark"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 ">
                                <button mat-button mat-raised-button color="primary" (click)="dischareChild()">
                                    Discharge
                                </button>
                            </div>

                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
            <!--------------------------------------------- DISCHARGE PROCESS ENDED --------------------------------------------->

            <!--------------------------------------------- FOLLOWUP ADMISSION PROCESS START --------------------------------------------->
            <mat-card class="mt-2" *ngIf="!showDischarge">
                <mat-card-header>
                    <mat-card-title>Followup Admission Form</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="admissionForm">
                        <div class="row">
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="example-full-width" appearance="outline" class="col-md-12">
                                    <mat-label>Select AL Center</mat-label>
                                    <mat-select formControlName="selectCentre" required
                                                (selectionChange)="fetchUnits($event)">
                                        <mat-option> --Select Centre--</mat-option>
                                        <ng-container *ngFor="let option of (centres$|async) ;let i = index">
                                            <mat-option *ngIf="option.isArchive!=true"
                                                        [value]="this.option">{{option.centreName}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                    <mat-error *ngIf="getCentre?.errors?.required">*It is Required.</mat-error>
                                    <!--        <mat-error style="color: red;" *ngIf="getCentre.errors?.pattern">*Enter Only characters.</mat-error>-->
                                    <!--          <mat-error *ngIf="getCentre.invalid  &&  (getCentre.touched ||getCentre.dirty)"></mat-error>-->

                                </mat-form-field>
                            </div>
                            <!--------------------------------------------- Admission Date --------------------------------------------->
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="example-form-field" appearance="outline">
                                    <mat-label>Admission Date</mat-label>
                                    <input matInput [matDatepicker]="admissionDatePicker"
                                           formControlName="dateOfAdmission">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="admissionDatePicker">
                                        <!--            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #admissionDatePicker></mat-datepicker>
                                </mat-form-field>

                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field class="example-form-field" appearance="outline">
                                    <mat-label>Tentative Discharge Date</mat-label>
                                    <input matInput [matDatepicker]="tentative"
                                           formControlName="tentativeDischargeDate">
                                    <mat-hint>DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matSuffix [for]="tentative">
                                        <!--            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>-->
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #tentative></mat-datepicker>
                                </mat-form-field>

                            </div>
                            <!--------------------------------------------- Unit Selection --------------------------------------------->
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Select Unit</mat-label>
                                    <mat-select formControlName="selectUnit" required>
                                        <mat-option *ngFor="let unit of (units$|async)" [value]="unit.key"
                                                    [disabled]="unit.status==='Alloted'">{{unit.floorNo}}
                                            - {{unit.order}} - {{unit.status}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Height (in cm)</mat-label>
                                    <input matInput formControlName="height" maxlength="3">
                                    <mat-error *ngIf="admissionForm.get('height')?.errors?.required">Please enter
                                        height
                                    </mat-error>
                                    <mat-error *ngIf="admissionForm.get('height')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 col-sm-12">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Weight</mat-label>
                                    <input matInput formControlName="weight" maxlength="5">
                                    <mat-error *ngIf="admissionForm.get('weight')?.errors?.required">Please enter
                                        weight
                                    </mat-error>
                                    <mat-error *ngIf="admissionForm.get('weight')?.errors?.['pattern']">Enter numbers
                                        only
                                    </mat-error>

                                </mat-form-field>
                            </div>
                            <!--              <div class="col-md-4">-->
                            <!--                <mat-form-field appearance="outline" class="example-form-field">-->
                            <!--                  <mat-label>Reason for discharge</mat-label>-->
                            <!--                  <mat-select formControlName="reason">-->
                            <!--                    <mat-option>&#45;&#45;Reason for Discharge&#45;&#45;</mat-option>-->
                            <!--                    <mat-option value="Happy Discharge">Happy Discharge</mat-option>-->
                            <!--                    <mat-option value="childData Expired">childData Expired</mat-option>-->
                            <!--                    <mat-option value="Palliative Discharge">Palliative Discharge</mat-option>-->
                            <!--                    <mat-option value="Refer to Outside Hospital">Refer to Outside Hospital</mat-option>-->
                            <!--                    <mat-option value="DAMA">DAMA</mat-option>-->
                            <!--                    <mat-option value="Non Complieance">Non-Compliance</mat-option>-->
                            <!--                    <mat-option value="Self Discharge">Self Discharge</mat-option>-->
                            <!--                  </mat-select>-->

                            <!--                </mat-form-field>-->
                            <!--              </div>-->
                            <div class="col-md-6">
                                <mat-form-field appearance="outline" class="example-form-field">
                                    <mat-label>Remark</mat-label>
                                    <textarea matInput placeholder="Enter Remark" formControlName="remark"></textarea>
                                </mat-form-field>
                            </div>
                            <!--    Unit Allotment Part-->
                            <div class="col-md-8 col-sm-12 p-2" *ngIf="isSelectedCentre">
                                <ng-container *ngIf="selectedCenter">
                                    <div class="col-md-10 col-sm-12 mb-1 text-center" style="display: flex;flex-direction: row;  justify-content: space-around;
">
                                        <div class=" text-center " id="total" style="flex-grow: 1">
                                            <p>Total <br>
                                                {{selectedCenter.totalUnits}}</p>
                                        </div>
                                        <div class=" text-center" id="available" style="flex-grow: 1">
                                            <p>Available <br>
                                                {{selectedCenter.availableUnits}}</p>
                                        </div>
                                        <div class=" text-center" id="occupied" style="flex-grow: 1">
                                            <p>Occupied <br>
                                                {{selectedCenter.occupiedUnits}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>


                            <div class="col-md-4">
                                <button mat-button mat-raised-button color="primary" (click)="reAdmitChild()">
                                    Admission
                                </button>
                            </div>

                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
            <!--------------------------------------------- FOLLOWUP ADMISSION PROCESS END --------------------------------------------->


        </div>
        <div class="col-md-4 col-sm-12 ">
            <!--------------------------------------------- Admission Documents --------------------------------------------->
            <div class="mt-1">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title> Admission Documents and Images</mat-panel-title>

                        </mat-expansion-panel-header>

                        <!--------------------------------------------- CONTENT START --------------------------------------------->
                        <div>
                            <h4 style="color:darkred">* childData Photo, childData Photo with Family, KYC Documents are
                                compulsory *</h4>
                            <div style="display: flex;flex-direction: row-reverse">
                                <button mat-raised-button color="primary" class="m-3" (click)="UploadAdmissionDocs()">
                                    Upload Document
                                </button>
                            </div>
                            <div class="cdk-virtual-scroll-data-source-example">
                                <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="5"
                                                             class="example-viewport">
                                    <div *cdkVirtualFor="let d of (child$|async)?.admissionDocuments;trackBy:trackByMethod;let i = index"
                                         class="example-item">


                                        <div>

                                            <!--                                            <button (click)="getExtension(d.fileUrl)" mat-icon-button ><mat-icon>search</mat-icon></button>-->
                                            <!--                                            <p>{{getExtension(d.fileUrl)}}</p>-->
                                            <a (click)="openLink(d.fileUrl)">

                                                <img [src]="d.fileUrl" onerror="this.src='src/assets/sign.jpg'" style="width: 250px; height: 250px" class="img-thumbnail img-fluid">

                                            </a>

                                            <div class="d-flex">
                                                <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                                <button mat-icon-button color=warn
                                                        (click)="deleteAdmissionFile(i,d,'admissionDocuments')">
                                                    <mat-icon>delete</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </cdk-virtual-scroll-viewport>
                            </div>
                            <div class="d-flex">

                                <!--        <ng-container  *ngFor="let d of (child$|async)?.admissionDocuments;trackBy:trackByMethod;let i = index">-->
                                <!--        <div>-->
                                <!--          <a (click)="openLink(d.fileUrl)">-->
                                <!--          <img [src]="d.fileUrl" style="width: 250px; height: 250px"  class="img-thumbnail img-fluid">-->
                                <!--         </a>-->

                                <!--          <div  class="d-flex">-->
                                <!--           <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>-->
                                <!--            <button  mat-icon-button color=warn (click)="deleteAdmissionFile(i,d,'admissionDocuments')"><mat-icon>delete</mat-icon></button>-->
                                <!--          </div>-->
                                <!--        </div>-->
                                <!--        </ng-container>-->
                            </div>
                        </div>
                        <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                        <!--------------------------------------------- CONTENT END --------------------------------------------->

                    </mat-expansion-panel>
                </mat-accordion>
                <!--------------------------------------------- Discharge --------------------------------------------->

            </div>
            <!--------------------------------------------- Discharge Documents --------------------------------------------->
            <div class="mt-1">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Discharge Documents and Images
                        </mat-expansion-panel-header>

                        <!--------------------------------------------- CONTENT START --------------------------------------------->
                        <div>
                            <div style="display: flex;flex-direction: row-reverse">
                                <button mat-raised-button color="primary" class="m-3" (click)="UploadDischargeDocs()">
                                    Upload Document
                                </button>
                            </div>
                            <div class="d-flex">
                                <ng-container *ngFor="let d of (child$|async)?.dischargeDocuments;let i=index">
                                    <div>

                                        <a (click)="openLink(d.fileUrl)">
                                            <img [src]="d.fileUrl" style="width: 250px; height: 250px" onerror="this.onerror=null; this.src='src/assets/cloud-2044823_960_720.png';"
                                                 class="img-thumbnail img-fluid"></a>
                                        <div class="d-flex">
                                            <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                            <button mat-icon-button color=warn
                                                    (click)="deleteAdmissionFile(i,d,'dischargeDocuments')">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                        <!--------------------------------------------- CONTENT END --------------------------------------------->

                    </mat-expansion-panel>
                </mat-accordion>
                <!--------------------------------------------- Discharge --------------------------------------------->

            </div>

            <!--------------------------------------------- Follow Up Admission Documents --------------------------------------------->
            <div class="mt-1">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Follow Up Admission Documents and Images
                        </mat-expansion-panel-header>

                        <!--------------------------------------------- CONTENT START --------------------------------------------->
                        <div>
                            <div style="display: flex;flex-direction: row-reverse">
                                <button mat-raised-button color="primary" class="m-3"
                                        (click)="UploadFollowUpAdmissionDocs()">Upload Document
                                </button>
                            </div>
                            <div class="d-flex">
                                <ng-container *ngFor="let d of (child$|async)?.followUpAdmissionDocuments;let i=index">
                                    <div>
                                        <a (click)="openLink(d.fileUrl)">
                                            <img [src]="d.fileUrl" style="width: 250px; height: 250px"
                                                 class="img-thumbnail img-fluid"></a>
                                        <div class="d-flex">
                                            <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                            <button mat-icon-button color=warn
                                                    (click)="deleteAdmissionFile(i,d,'followUpAdmissionDocuments')">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                        <!--------------------------------------------- CONTENT END --------------------------------------------->

                    </mat-expansion-panel>
                </mat-accordion>
                <!--------------------------------------------- Discharge --------------------------------------------->

            </div>

            <!--------------------------------------------- Follow Up Discharge Documents --------------------------------------------->
            <div class="mt-1">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            Follow Up Discharge Documents and Images
                        </mat-expansion-panel-header>

                        <!--------------------------------------------- CONTENT START --------------------------------------------->
                        <div>
                            <div style="display: flex;flex-direction: row-reverse">
                                <button mat-raised-button color="primary" class="m-3"
                                        (click)="UploadFollowUpDischargeDocs()">Upload Document
                                </button>
                            </div>
                            <div class="d-flex">
                                <ng-container *ngFor="let d of (child$|async)?.followUpDischargeDocuments;let i= index">
                                    <div>
                                        <a (click)="openLink(d.fileUrl)">

                                            <img  [src]="d.fileUrl" style="width: 250px; height: 250px"
                                                  class="img-thumbnail img-fluid">
                                        </a>
                                        <div class="d-flex">
                                            <p style="flex-grow: 9" class="text-center"> {{d.description}}</p>
                                            <button mat-icon-button color=warn
                                                    (click)="deleteAdmissionFile(i,d,'followUpDischargeDocuments')">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <!--------------------------------------------- Admission Documents Ended --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs --------------------------------------------->
                        <!--------------------------------------------- Discharg Docs ENded --------------------------------------------->

                        <!--------------------------------------------- CONTENT END --------------------------------------------->

                    </mat-expansion-panel>
                </mat-accordion>
                <!--------------------------------------------- Discharge --------------------------------------------->

            </div>
            <!*--------------------------------------------- ADMISSION DOCS--------------------------------------------->
        </div>
        <!--------------------------------------------- EVENTS --------------------------------------------->
        <div class="col-md-5">
            <h2 *ngIf="childData?.events" class="text-center bold">&nbsp;</h2>
            <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
                <ng-container *cdkVirtualFor="let e of childData.events;let  i =index">
                    <!--------------------------------------------- NO BMI --------------------------------------------->
                    <mat-card *ngIf="!e.isBMI" class="example-card">
                        <mat-card-header>
                            <!--            <div mat-card-avatar class="example-header-image"></div>-->
                            <mat-card-title class="text-capitalize">{{e.title}}</mat-card-title>
                            <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
                        </mat-card-header>
                        <ng-container *ngIf="e.res_type=='video'">
                            <iframe mat-card-image
                                    src="https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/childData%2F47d6bb7d-0cf1-47b7-8084-526be0fbd4244608434017738393552.mp4?alt=media&token=ba5189e6-f018-45fa-8121-895e36975c56"
                                    alt="Event Image Here"></iframe>
                        </ng-container>
                        <ng-container *ngIf="e.res_type!='video'">
                            <img mat-card-image [src]="e.res_url" alt="Event Image Here">
                        </ng-container>
                        <mat-card-content>
                            <p class="text-capitalize">
                                {{e.description}}
                            </p>
                        </mat-card-content>

                    </mat-card>
                    <!--------------------------------------------- WITH BMI --------------------------------------------->
                    <mat-card *ngIf="e.isBMI" class="example-card">
                        <mat-card-header>
                            <!--            <div mat-card-avatar class="example-header-image"></div>-->
                            <mat-card-title class="text-capitalize">{{e.title}}</mat-card-title>
                            <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
                        </mat-card-header>
                        <ng-container *ngIf="e.res_type=='video'">
                            <iframe mat-card-image
                                    src="https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/childData%2F47d6bb7d-0cf1-47b7-8084-526be0fbd4244608434017738393552.mp4?alt=media&token=ba5189e6-f018-45fa-8121-895e36975c56"
                                    alt="Event Image Here"></iframe>
                        </ng-container>
                        <ng-container *ngIf="e.res_type!='video'">
                            <img mat-card-image [src]="e.res_url" alt="Event Image Here">
                        </ng-container>
                        <mat-card-content>
                            <p class="text-capitalize">

                                {{e.description}}
                            </p>
                            <table style="width: 100%">
                                <th>Height</th>
                                <th>Weight</th>
                                <!--          <th>BMI</th>-->
                                <tr>
                                    <!--                <th>Height </th>-->
                                    <td> {{e?.height}}</td>
                                    <!--                <th>Weight </th>-->
                                    <td> {{e?.weight}}</td>
                                    <!--                <th>BMI </th>-->
                                    <!--                <td> {{e.bmi}}</td>-->
                                </tr>
                            </table>
                        </mat-card-content>

                    </mat-card>
                </ng-container>
            </cdk-virtual-scroll-viewport>
        </div>
    </div>

    <!--      RESPONSE-->

</div>

