
<div class="container">

  <form class="content" [formGroup]="centerForm">
    <h3 class="text-center"><b>AL Centre Form</b></h3>
<!--    <div id="info">&nbsp;Information</div>-->
    <br>
    <br>
    <div class="row">
      <!--        <div class="col-md-10-p3">-->
      <!-- <div class="col-6 col-md-4">
        <label> *AL Center Name</label>
      </div> -->
      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>AL Center Name</mat-label>
          <input matInput placeholder="Enter your Centre name"
                 formControlName="centreName">
          <mat-error *ngIf=" centreName.invalid  &&  (centreName.touched || centreName.dirty)">
            <span style="color: red;" *ngIf="centreName.errors?.required">*Centre Name is Required.</span>
            <span style="color: red;" *ngIf="centreName.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>
      </div>



      <!-- <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>Center Name</mat-label>
          <input matInput placeholder="Center Name" required formControlName="centername">
        </mat-form-field>
        <div *ngIf="centerName.invalid  &&  (centerName.touched || centerName.dirty)">
          <span style="color: red;" *ngIf="centerName.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="centerName.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div> -->

      <!-- <p>
        <mat-form-field appearance="fill">
          <mat-label>Fill form field</mat-label>
          <input matInput placeholder="Placeholder" formControlName="centreName" class="form-control">
          <div *ngIf=" centreName.invalid  &&  (centreName.touched || centreName.dirty)">
            <span style="color: red;" *ngIf="centreName.errors?.required">*Centre Name is Required.</span>
            <span style="color: red;" *ngIf="centreName.errors?.pattern">*Enter Only characters.</span>
          </div>
        </mat-form-field>
      </p>
      <p> -->

      <br>
      <br>

      <!-- <div class="col-6 col-md-4">
        <label>Total Rooms</label>
      </div>
      <div class="col-8"> -->
      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>Total Units</mat-label>
          <input matInput placeholder="Total Rooms" required maxlength="2" formControlName="totalUnits">
          <mat-error *ngIf="totalRooms.invalid  &&  (totalRooms.touched || totalRooms.dirty)">
            <span style="color: red;" *ngIf="totalRooms.errors?.required">*It is  Required.</span>
            <span style="color: red;" *ngIf="totalRooms.errors?.pattern">*Enter Only Numbers.</span>
            <span style="color: red;" *ngIf="totalRooms.errors?.['maxlength']">Total units  must of 2 digits only</span>
            <span style="color: red;" *ngIf="totalRooms?.errors['min']">Total Units of centre can only be incremented</span>

          </mat-error>
        </mat-form-field>
      </div>
      <br>
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Amenities</mat-label>
          <mat-select multiple type="text" placeholder="Amenities" required formControlName="ammenities" >
            <mat-option *ngFor="let am of (ammenities$|async)" [value]="am">{{am}} </mat-option>
          </mat-select>
          <mat-error *ngIf="Ammenities.invalid  &&  (Ammenities.touched || Ammenities.dirty)">
            <span style="color: red;" *ngIf="Ammenities.errors?.required">*Required.</span>
          </mat-error>
        </mat-form-field>
      </div>
      <!--        <br>-->
      <!--        <div class="col-6 col-md-4">-->
      <!--          <label>Available Rooms</label>-->
      <!--        </div>-->
      <!--        <div class="col-8">-->
      <!--          <input type="text" placeholder="Available Rooms" formControlName="availableUnits" class="form-control">-->
      <!--          <div *ngIf="  availableRooms.invalid  &&  (availableRooms.touched || availableRooms.dirty)">-->
      <!--            <span style="color: red;" *ngIf="availableRooms.errors?.required">*It is  Required.</span>-->
      <!--            <span style="color: red;" *ngIf="availableRooms.errors?.pattern">*Enter Only Numbers.</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <br>-->
      <!--        <br>-->
      <!--        <div class="col-6 col-md-4">-->
      <!--          <label>Occupied Rooms</label>-->
      <!--        </div>-->

      <!--        <div class="col-8">-->
      <!--          <input type="text" placeholder="Occupied Rooms" formControlName="occupiedUnits" class="form-control">-->
      <!--          <div *ngIf="  occupiedRooms.invalid  &&  (occupiedRooms.touched || occupiedRooms.dirty)">-->
      <!--            <span style="color: red;" *ngIf="occupiedRooms.errors?.required">*It is  Required.</span>-->
      <!--            <span style="color: red;" *ngIf="occupiedRooms.errors?.pattern">*Enter Only Numbers.</span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <br>-->
      <!--        <br>-->

      <!-- <div class="col-6 col-md-4">
        <label>Street</label>
      </div> -->

      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>Address</mat-label>
          <input  formControlName="address" matInput placeholder="Address">

          <mat-error  *ngIf="  address.invalid  &&  (address.touched || address.dirty)">
            <span style="color: red;" *ngIf="address.errors?.required">*Street is Required.</span>
          </mat-error >
        </mat-form-field>
      </div>

      <!-- <div class="col-auto">
        <label> City</label>
      </div>&nbsp;&nbsp;&nbsp;&nbsp; -->

      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>City</mat-label>
          <input matInput placeholder="City" required formControlName="city">
          <mat-error  *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
            <span style="color: red;" *ngIf="city.errors?.required">*City is Required.</span>
            <span style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
          </mat-error >
        </mat-form-field>
      </div>

      <!-- <div class="col-auto">
        <label style="line-height: 1.2"> State/<br>Province</label>
      </div>&nbsp;&nbsp; -->

      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>State</mat-label>
          <input  matInput placeholder="State/Province" required formControlName="state">
          <mat-error  *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
            <span style="color: red;" *ngIf="state.errors?.required">* State/Province is Required.</span>
            <span style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</span>
          </mat-error >
        </mat-form-field>
      </div>



      <!-- <div class="row g-2 align-items-center"> -->
      <!-- <div class="col-auto">
        <label> Country</label>
      </div> -->
      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>Country</mat-label>
          <input  matInput placeholder="Country" required formControlName="country">

          <mat-error  *ngIf="  country.invalid  &&  (country.touched || country.dirty)">
            <span style="color: red;" *ngIf="country.errors?.required">*Country is Required.</span>
            <span style="color: red;" *ngIf="country.errors?.pattern">*Enter Only characters.</span>
          </mat-error >
        </mat-form-field>
      </div>

      <!--
                <div class="col-auto">
                  <label style="line-height: 1.2">Zip/<br>Postal Code</label>
                </div> -->

      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>Pin Code</mat-label>
          <input matInput required placeholder="Zip/Postal Code" maxlength="6" formControlName="zipcode">
          <mat-error *ngIf=" zipcode.invalid  &&  (zipcode.touched || zipcode.dirty)">
            <span style="color: red;" *ngIf="zipcode.errors?.required">*Zip/Postal Code is Required.</span>
            <span style="color: red;" *ngIf="zipcode.errors?.pattern">*Enter Only Numbers.</span>
            <span style="color: red;" *ngIf="zipcode.errors?.['maxlength']">Zipcode must be of 6 digits only</span>

          </mat-error>
        </mat-form-field>
      </div>
      <!-- </div> -->

      <!-- <div class="col-6 col-md-4">
        <label style="line-height: 1.2">Allocated Center Manager</label>
      </div> -->

      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label>Centre Manager</mat-label>

          <input   matInput placeholder=" Centre Manager" formControlName="centreManager"
          >
          <mat-error *ngIf=" centerManager.invalid  &&  (centerManager.touched || centerManager.dirty)">
            <span style="color: red;"
                  *ngIf="centerManager.errors?.required">* Centre Manager is Required.</span>
            <span style="color: red;" *ngIf="centerManager.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="col-6 col-md-4">
        <label style="line-height: 1.2">Allocated Center Coordinator </label>
      </div> -->

      <div class="col-6">
        <mat-form-field appearance="outline" class="mb-1">
          <mat-label> Centre Co-ordinators</mat-label>
          <input  matInput placeholder="Centre Co-ordinator"  formControlName="centreCordinator">
          <mat-error *ngIf="  centerCordinator.invalid  &&  (centerCordinator.touched || centerCordinator.dirty)">
            <span style="color: red;" *ngIf="centerCordinator.errors?.required">*Allocated Centre Co-ordinator is Required.</span>
            <span style="color: red;" *ngIf="centerCordinator.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-checkbox formControlName="mealMate">Meal-Mate</mat-checkbox>
      </div>

      <div class="col-6">
        <mat-checkbox formControlName="unitSponsorship">Unit-Sponsorship</mat-checkbox>
      </div>
      <hr>
      <div id="botn" class="col-12">
        <button color="warn" mat-raised-button (click)="onReset()"><b>Cancel</b></button>&nbsp;
        <!--        <button   class="btn btn-primary" type="submit" (click)="savennew()"> Save & New </button>&nbsp;-->
        <button color="primary" mat-raised-button type="submit" (click)="update()">Update</button>
      </div>
    </div>
  </form>

</div>


<!--<b>{{actionbtn}}</b>-->




