<h3 class="text-center">Child Documents</h3>

<div class="about">
  <div class="row">
    <div class="col-md">

      <mat-card>
    <div class="row">
      <div class="col-sm-4">
    <mat-card-content>Adhaar Card</mat-card-content>
      </div>

      <div class="col-sm-8 text-center">
        <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
          <input hidden type="file" #fileInput (change)="uploadFile($event)">
          <button mat-fab color="accent" class="icon-button-large" aria-label=" large button Example icon button with a add icon ">
            <mat-icon>add</mat-icon>
          </button>
          <h6> Drag and Drop your files here .JPG.PNG. </h6>
          <p> </p>
        </div>
        <div  class="files-list" *ngFor="let file of files;let i= index">
          <p> {{ file }} </p>
          <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">
            <mat-icon>delete</mat-icon>
          </button>
        </div>

     </div>
      </div>

  </mat-card>
    </div>

    <div class="col-md">

      <mat-card>
        <div class="row">
          <div class="col-sm-4">
            <mat-card-content>Pan Card</mat-card-content>
          </div>

          <div class="col-sm-8 text-center">
            <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
              <input hidden type="file" #fileInput (change)="uploadFile($event)">
              <button mat-fab color="accent" class="icon-button-large" aria-label=" large button Example icon button with a add icon ">
                <mat-icon>add</mat-icon>
              </button>
              <h6> Drag and Drop your files here .JPG.PNG. </h6>
              <p> </p>
            </div>
            <div  class="files-list" *ngFor="let file of files;let i= index">
              <p> {{ file }} </p>
              <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

          </div>
        </div>

      </mat-card>
    </div>

<!--    <div class="col-md">-->

<!--      <mat-card>-->
<!--        <div class="row">-->
<!--          <div class="col-sm-4">-->
<!--            <mat-card-content>Birth Certificate</mat-card-content>-->
<!--          </div>-->

<!--          <div class="col-sm-8 text-center">-->
<!--            <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">-->
<!--              <input hidden type="file" #fileInput (change)="uploadFile($event)">-->
<!--              <button mat-fab color="accent" class="icon-button-large" aria-label=" large button Example icon button with a add icon ">-->
<!--                <mat-icon>add</mat-icon>-->
<!--              </button>-->
<!--              <h6> Drag and Drop your files here .JPG.PNG. </h6>-->
<!--              <p> </p>-->
<!--            </div>-->
<!--            <div  class="files-list" *ngFor="let file of files;let i= index">-->
<!--              <p> {{ file }} </p>-->
<!--              <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">-->
<!--                <mat-icon>delete</mat-icon>-->
<!--              </button>-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

<!--      </mat-card>-->
<!--    </div>-->


</div>

</div>


<div class="about">
  <div class="row">
    <div class="col-md">

      <mat-card>
        <div class="row">
          <div class="col-sm-4">
            <mat-card-content>Birth Certificate</mat-card-content>
          </div>

          <div class="col-sm-8 text-center">
            <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
              <input hidden type="file" #fileInput (change)="uploadFile($event)">
              <button mat-fab color="accent" class="icon-button-large" aria-label=" large button Example icon button with a add icon ">
                <mat-icon>add</mat-icon>
              </button>
              <h6> Drag and Drop your files here .JPG.PNG. </h6>
              <p> </p>
            </div>
            <div  class="files-list" *ngFor="let file of files;let i= index">
              <p> {{ file }} </p>
              <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

          </div>
        </div>

      </mat-card>
    </div>

    <div class="col-md">

      <mat-card>
        <div class="row">
          <div class="col-sm-4">
            <mat-card-content>Bank Details</mat-card-content>
          </div>

          <div class="col-sm-8 text-center">
            <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
              <input hidden type="file" #fileInput (change)="uploadFile($event)">
              <button mat-fab color="accent" class="icon-button-large" aria-label=" large button Example icon button with a add icon ">
                <mat-icon>add</mat-icon>
              </button>
              <h6> Drag and Drop your files here .JPG.PNG. </h6>
              <p> </p>
            </div>
            <div  class="files-list" *ngFor="let file of files;let i= index">
              <p> {{ file }} </p>
              <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">
                <mat-icon>delete</mat-icon>
              </button>
            </div>

          </div>
        </div>

      </mat-card>
    </div>
  </div>

</div>
