import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FullChild} from "../../../interfaces/FullChild";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import { Observable } from 'rxjs';
import {Centre} from "../../../interfaces/Centres";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;


@Component({
  selector: 'app-view-child',
  templateUrl: './view-child.component.html',
  styleUrls: ['./view-child.component.scss']
})
export class ViewChildComponent implements OnInit {
  centreName$?:Observable<Centre | undefined>
  data:any
  constructor(@Inject(MAT_DIALOG_DATA) public child: FullChild,
              private mFirestore:AngularFirestore) {
    this.data = child
  }

  ngOnInit(): void {
    console.log(this.data)
  }

}
