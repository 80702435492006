import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import axios from "axios";
@Injectable({
  providedIn: 'root'
})
export class RazorpayDataService {
  private apiUrl = 'https://api.razorpay.com/v1/payments'; // Replace with the appropriate API endpoint
  private transactionApi = 'https://api.razorpay.com/v1/transactions';
  key_secret = 'nuYSZGm2PvUjbnZphjPcIDRA';
  key_id = 'rzp_live_n1im1rcGlUYmyU';
  private apiKey = this.key_id+':'+this.key_secret// Replace with your Razorpay API key
  constructor(private http: HttpClient)
  {

  }

  getTransactions(): Observable<any> {
    alert('getting transactions')
    const headers = new HttpHeaders({
      'Authorization': `Basic ${btoa(this.key_id + ':'+this.key_secret)}`
    });

    return this.http.get(this.apiUrl, { headers });
  }
fetchPayments2(){
  const url = 'https://corsproxy.org/?' + encodeURIComponent(environment.cloudFunctions.fetchRazoryPaycors);

  // alert('getting payments')

  return this.http.get(url);
}

  fetchPayments(){
    // alert('getting payments')

    return this.http.get(environment.cloudFunctions.getpayments);
  }
getPaymentsByDate(startDate:Date,endDate:Date){
  // const startDate = new Date(2023, 11, 19).getTime(); // Replace with your date
  // const endDate = new Date(2023, 11, 20).getTime();

  const url = `https://api.razorpay.com/v1/payments?from=${startDate.getTime()}&to=${endDate.getTime()}`;
const yourApiKey='rzp_live_n1im1rcGlUYmyU:nuYSZGm2PvUjbnZphjPcIDRA'
  axios.get(url, {
    headers: {
      Authorization: `Bearer ${yourApiKey}`,
    },
  })
      .then(response => {
        // Process the received transaction data (array of objects)
        console.log(response.data);
        // ... Handle successful data retrieval
      })
      .catch(error => {
        console.error(error);
        // Handle any errors during the request or response
      });
}
}
