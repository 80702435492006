<h4 class="rounded" mat-dialog-title>Select Floor</h4>
<div mat-dialog-content>
  <br>

  <mat-form-field appearance="outline">
    <mat-label>Select Floor</mat-label>
    <mat-select (selectionChange)="setFloor($event)">
      <mat-option value="Ground Floor" >Ground Floor</mat-option>
      <mat-option value="Floor 1">Floor 1</mat-option>
      <mat-option value="Floor 2">Floor 2</mat-option>
      <mat-option value="Floor 3">Floor 3</mat-option>
      <mat-option value="Floor 4">Floor 4</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="text-center">
  <button mat-stroked-button color="primary" [mat-dialog-close]="floorSelected" cdkFocusInitial>Ok</button>
</div>
