import {Component, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {CreatecenterComponent} from "../createcenter/createcenter.component";
import {MatDialog} from "@angular/material/dialog";
import {CentreService} from "../../../services/centre.service";
import {MatSort} from '@angular/material/sort';
import {Centre} from "../../../interfaces/Centres";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {EditCentreComponent} from "../edit-centre/edit-centre.component";
import {ViewCentreComponent} from "../view-centre/view-centre.component";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subject, takeUntil} from "rxjs";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {logger} from "html2canvas/dist/types/core/__mocks__/logger";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {LogService} from "../../../services/log.service";

@Component({
    selector: 'app-centrelist',
    templateUrl: './centrelist.component.html',
    styleUrls: ['./centrelist.component.scss']
})
export class CentrelistComponent implements AfterViewInit, OnDestroy {
// @ViewChild('datashow',{read:ViewContainerRef,static:true})
// datashow!: ViewContainerRef;
    allCentres: Centre[] = [];
    private _destroyed$ = new Subject()
    userData: any
    dataSource: MatTableDataSource<Centre>;
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  centres=this.permissions.filter((x)=>x.functionName=='Centres')


  @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) matSort!: MatSort;
    constructor(private matdialog: MatDialog,
                private centreService: CentreService,
                private mDatabase: AngularFireDatabase,
                private _snackBar: MatSnackBar,
                private readonly mLogService: LogService,
                private readonly mFirestore: AngularFirestore
    ) {
        // Create 100 users
        // Assign the data to the data source for the assessment-table to render
        this.dataSource = new MatTableDataSource(this.allCentres);
        this.userData = JSON.parse(localStorage.getItem('userData')!)
    }

    displayedColumns: String[] = ['centername', 'city', 'availableunits', 'occupiedunits', 'totalunits', 'Action'];

    // @ts-ignore
    // dataSource:Centre[] ;




    ngAfterViewInit() {
        this.matSort.sortChange.pipe(takeUntil(this._destroyed$)).subscribe(() => (this.paginator.pageIndex = 0));
        //Read
        this.centreService.getAllCentres()
            .subscribe(centres => {
                this.allCentres = []
                console.log('CENTRE LIST TABLE', centres)
                this.allCentres = centres

                this.dataSource = new MatTableDataSource(this.allCentres);
                this.dataSource.paginator = this.paginator;

            })
        console.log('allCentres', this.allCentres.length)

    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    // ngOnInit(): void {
    //   // this.centreService.getAllCentres().pipe(
    //   //   map(changes => {
    //   //     console.log(changes)
    //   //   })
    //   // ).subscribe(data => {
    //   //   // this.allCentres = data
    //   //   this.dataSource = new MatTableDataSource<any>(this.allCentres)
    //   //   console.log(this.dataSource)
    //   // })
    //
    // }

    // createComponent(){
    //   this.datashow.createComponent(CreatecenterComponent)
    // }

    datashow() {
        this.matdialog.open(CreatecenterComponent,
            {
                width: '50%', height: '550px',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '100ms',
                autoFocus: false
            }
        )
    }

    openpopup() {
        this.matdialog.open(CreatecenterComponent,
            {
                width: '70%', height: '600px',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '200ms',
                autoFocus: false
            }
        )
    }

    editDialog(id: string, element: Centre) {
        console.log(id)
        this.matdialog.open(EditCentreComponent,
            {
                width: '70%', height: '600px',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '200ms',
                data: {id: id, value: element},
                autoFocus: false

            },
        )
    }

    viewDialog($key: any, element: Centre) {
        this.matdialog.open(ViewCentreComponent,
            {
                width: '70%', height: '600px',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '200ms',
                data: {id: $key, value: element},
                autoFocus: false

            })
    }

    @needConfirmation()
    archiveCentre(c: Centre) {
        this.centreService.archiveCenter(c).then(() => {
            this._snackBar.open('Center Archived', '', {duration: 3000})
          let activity={
            date:new Date().getTime(),
            section: 'Centre',
            action: 'Delete',

            description: `Centre Deleted by`,
            currentIp: localStorage.getItem('currentip')!
          }
          this.mLogService.addLog(activity)
        })

    }

    ngOnDestroy(): void {
        // @ts-ignore
        this._destroyed$.next()
        this._destroyed$.complete()
    }
}
