<div class="col-md-12">
  <h1 class="bg-warning rounded text-dark text-center">Edit Users Information</h1>
  <form [formGroup]='form'>
    <table class="table table-bordered">
      <tbody>
      <tr>
        <th>Name</th>
        <td><input type="text" class="form-control" formControlName="userName" value="{{md.userName}}"></td>
      </tr>
      <tr>
        <th>Email</th>
        <td><input type="email" class="form-control" formControlName="email" value="{{md.email}}"></td>
      </tr>
      <tr>
        <th>Mobile</th>
        <td><input type="tel" class="form-control" formControlName="mobile" value="{{md.mobile}}"></td>
      </tr>
      <tr>
        <th>Location</th>

        <td><input type="text" class="form-control" formControlName="location" value="{{md.location}}"></td>
      </tr>
      <tr>
        <th>Department</th>
        <td> <mat-form-field appearance="outline" class="col-md-6">
          <mat-select formControlName="department">
            <mat-option *ngFor="let d of departments$|async" [value]="d">{{d}}</mat-option>
          </mat-select>
        </mat-form-field></td>
      </tr>
      <tr>
        <th>Job Title</th>
        <td><input type="text" class="form-control" formControlName="jobtitle" value="{{md.jobtitle}}"></td>
      </tr>

      <tr *ngIf="md.vehicleNo">
        <th>Vehicle No.</th>
        <td><input type="text" class="form-control" formControlName="vehicleNo" value="{{md.vehicleNo}}"></td>
      </tr>
      <tr>
        <th>Active</th>
        <td><mat-slide-toggle formControlName="disabled" (change)="onToggleChange($event)">
<!--          <mat-form-field>-->
<!--            <mat-label>Enable User  </mat-label>-->
<!--            <mat-select>-->
<!--              <mat-option value="true"  >Enable</mat-option>-->
<!--              <mat-option value="false">Disable</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->
        </mat-slide-toggle>
        </td>
      </tr>

      </tbody>
    </table>
    <div class="d-flex justify-content-center">
    <button class="btn btn-warning w-25" (click)="updateUser()">Submit</button>
    </div>
  </form>
</div>
