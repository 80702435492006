<form [formGroup]="pictureForm" class="post-form">
    <h4>{{title}}</h4>
    <!--      <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>-->
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Document Image</mat-label>
                <ngx-mat-file-input formControlName="photo" placeholder="">
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
                <mat-error *ngIf="pictureForm.controls['photo'].hasError('required')">
                    Click to Select File or Image
                </mat-error>
                <mat-error *ngIf="pictureForm.controls['photo'].hasError('image')">
                    Wrong File Format Images and pdf only
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Description</mat-label>
                <textarea
                        formControlName="description"
                        matInput
                        placeholder="Enter file name"
                >
        </textarea>
                <mat-error *ngIf="pictureForm.controls['description'].hasError('required')">
                    Enter Document Remark
                </mat-error>
            </mat-form-field>
            <button
                    (click)="postKitty()"
                    [disabled]="pictureForm.invalid || submitted"
                    color="primary"
                    mat-raised-button
            >
                Upload
            </button>
        </div>

        <div class="col-md-6">
            <img
                    *ngIf="kittyImagePreview"
                    [src]="kittyImagePreview"
                    alt="Image"
                    class="preview-image"
            />
        </div>
    </div>
    <mat-progress-bar *ngIf="submitted" [value]="uploadProgress$ | async" mode="determinate"></mat-progress-bar>

</form>
