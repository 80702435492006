<div class="container">

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element">{{element.firstName}} {{element.lastName}}  </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button (click)="view()" color="accent">View</button>&nbsp;<button mat-raised-button (click)="allot()" color="primary">Allot</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


</div>
