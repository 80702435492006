<!-- trip-view.component.html -->

<div class="container mt-4 w-100">
  <h1 class="bg-warning text-center mb-4">Trip Details</h1>

  <table class="table table-active w-100">
    <tbody>

    <!-- Trip Date -->

    <tr>
      <th>Round Tip ?</th>
      <td>{{ tripData.isRoundTrip ? 'Yes' : 'No' }}</td>

    </tr>
    <tr>
      <th>Trip Date</th>
      <td>{{ displayDate2(tripData.trip_started_at) }}</td>
    </tr>

    <!-- Trip From -->
    <tr>
      <th>Trip From</th>
      <td>{{ tripData.pick_up_location }}</td>
    </tr>

    <!-- Trip To -->
    <tr>
      <th>Trip To</th>
      <td>{{ tripData.drop_location }}</td>
    </tr>

    <!-- Vehicle Number -->
    <tr>
      <th>Vehicle Number</th>
      <td>{{ tripData.vehicle_info.vehicleNumber }}</td>
    </tr>

    <!-- Driver Name -->
    <tr>
      <th>Driver Name</th>
      <td>{{ tripData.vehicle_info.driverName }}</td>
    </tr>

    <!-- Start Date and Time -->
    <tr>
      <th>Start Date and Time</th>
      <td>{{ displayDate(tripData.trip_started_at) }}</td>
    </tr>

    <!-- End Date and Time -->
    <tr>
      <th>End Date and Time</th>
      <td>{{displayDate(tripData.trip_ended_at) }}</td>
    </tr>

    <!-- Purpose of Trip -->
    <tr>
      <th>Purpose of Trip</th>
      <td>{{ tripData.purpose_of_trip }}</td>
    </tr>

    <tr class="cursor-pointer" (click)="openGoogleMaps(tripData.pick_up_location_lat_lng.lat, tripData.pick_up_location_lat_lng.lang)">
      <th>Actual Pick Up Location</th>
      <td>{{ tripData.pick_up_location_lat_lng.lat }}, {{ tripData.pick_up_location_lat_lng.lang }}</td>
    </tr>


    <tr class="cursor-pointer" (click)="openGoogleMaps(tripData.drop_location_lat_lng.lat, tripData.drop_location_lat_lng.lang)">
      <th>Actual Drop Location</th>
      <td>{{ tripData.drop_location_lat_lng.lat }}, {{ tripData.drop_location_lat_lng.lang }}</td>
    </tr>




    <!-- Reason -->
    <tr>
      <th>Reason</th>
      <td>{{ tripData.reason || 'N/A' }}</td>
    </tr>

    <!-- Remark -->
    <tr>
      <th>Remark</th>
      <td>{{ tripData.remark }}</td>
    </tr>

    <!-- Status -->
    <tr>
      <th>Status</th>
      <td>{{ tripData.status }}</td>
    </tr>

    <!-- Total KM -->
    <tr>
      <th>Total KM</th>
      <td>{{calculateTotalKms(tripData)}}</td>
    </tr>

    <!-- Start Trip Image -->
    <tr>
      <th>Start Trip Image</th>
      <td><img src="{{ tripData.startTripImage }}" alt="Start Trip Image" class="w-100 h-10rem"></td>
    </tr>

    <tr>
      <th>End Trip Image</th>
      <td><img src="{{ tripData.roundTripImage }}" alt="End Trip Image" class="w-100 h-10rem"></td>
    </tr>
    <!-- End Trip Image -->
    <tr>
      <th>End Trip Image</th>
      <td><img src="{{ tripData.endTripImage }}" alt="End Trip Image" class="w-100 h-10rem"></td>
    </tr>

    </tbody>
  </table>
</div>
