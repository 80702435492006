import {Component, Input, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl,  FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {EMPTY, Observable, Subject, takeUntil} from "rxjs";
import {catchError} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../auth.service";
import {UtilService} from "../../services/util.service";
import {FileStorageService} from "../../services/file-storage.service";
import {needConfirmation} from "../../confirm-dialog/confirm-dialog.decorator";
import {arrayUnion} from "@angular/fire/firestore";
import {environment} from "../../../environments/environment";

// !Reference Url : https://dev.to/angular/implement-file-upload-with-firebase-storage-in-our-angular-app-the-simple-way-1ofi
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  env = isDevMode() ? environment.testMode : environment.productionMode

  @Input() title = 'Title'
  @Input() subtitle = 'Subtitle'
  @Input() storagePath? = 'testfileuploads'
  @Input() dbPath? = 'testfileuploads'
  destroy$: Subject<null> = new Subject();
  fileToUpload!: File;
  submitted = false;
  uploadProgress$?: Observable<number | undefined>;
  kittyImagePreview!: string | ArrayBuffer;
  pictureForm!: FormGroup;
  user?: firebase.User | null;
  MEDIA_STORAGE_PATH = 'testfileuploads'
  @Input() fileCount: 'single' | 'multi' = 'multi'

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly utilService: UtilService,
    private readonly storageService: FileStorageService,
    private readonly snackBar: MatSnackBar,
    private readonly mFirestore: AngularFirestore
  ) {
  }

  ngOnInit(): void {
    if (this.storagePath != null) this.MEDIA_STORAGE_PATH = this.storagePath
    this.pictureForm = this.formBuilder.group({
      photo: [null, [Validators.required, this.image.bind(this)]],
      description: [null, Validators.required],
    });
    this.pictureForm.get('photo')?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.handleFileChange(newValue.files);
      });
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => (this.user = user));
    console.log(this.user)
  }

  // @ts-ignore
  handleFileChange([kittyImage]) {
    this.fileToUpload = kittyImage;
    const reader = new FileReader();
    reader.onload = (loadEvent) => (this.kittyImagePreview = loadEvent.target?.result!);
    reader.readAsDataURL(kittyImage);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.authService.user$?.pipe(takeUntil(this.destroy$))
      .subscribe((user) => (this.user = user));
  }


  private image(photoControl: AbstractControl): { [key: string]: boolean } | null {
    if (photoControl.value) {
      const [kittyImage] = photoControl.value.files;
      return this.utilService.validateFile(kittyImage)
        ? null
        : {
          image: true,
        };
    }
    // @ts-ignore
    return;
  }

  @needConfirmation()
  postKitty() {
    this.submitted = true;
    if (this.storagePath != null || this.storagePath != '') this.MEDIA_STORAGE_PATH = this.storagePath!
    const mediaFolderPath = `${this.MEDIA_STORAGE_PATH}/`;
    const {downloadUrl$, uploadProgress$} = this.storageService.uploadFileAndGetMetadata(
      mediaFolderPath,
      this.fileToUpload,
    );
    this.uploadProgress$ = uploadProgress$;
    downloadUrl$
      .pipe(
        takeUntil(this.destroy$),
        catchError((error) => {
          this.snackBar.open(`${error.message} 😢`, 'Close', {
            duration: 4000,
          });
          return EMPTY;
        }),
      )
      .subscribe((downloadUrl) => {
        this.submitted = false;
        console.log('DownloadUrl', downloadUrl)
        // if(this.dbPath!=null ||this.dbPath!=undefined) this.dbPath
        if (this.fileCount == 'multi') {
          let documentMap = {
            url: downloadUrl,
            description: this.pictureForm.get('description')?.value,
            fileName: this.fileToUpload.name

          }
          this.mFirestore.doc(this.dbPath!).set({documents: arrayUnion(documentMap)}, {merge: true}).then(r =>
            this.snackBar.open('Upload Completed!')._dismissAfter(2000)
          )
        } else if (this.fileCount == 'single') {
          let documentMap = {
            url: downloadUrl,
            description: this.pictureForm.get('description')?.value,
            fileName: this.fileToUpload.name
          }
          this.mFirestore.doc(this.dbPath!).set(documentMap,{merge:true}).then((r) => {
              this.pictureForm.reset();
              this.snackBar.open('Upload Completed!')._dismissAfter(2000)
            }
          )
        }
        // this.router.navigate([`/${FEED}`]);
      });
  }

}
