import {Component, Inject, OnInit, Renderer2} from '@angular/core';

import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from "../auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Direction} from "@angular/cdk/bidi";
import {InConfiguration} from "../models/config.interface";
import {DirectionService} from "../services/direction.service";
import {DOCUMENT} from "@angular/common";
import {ConfigService} from "../services/config.service";
import {LogService} from "../services/log.service";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    direction!: Direction;
    public config!: InConfiguration;
    changePassword = false
// regArray:any={}
    changePasswordForm!: FormGroup
    loginForm!: FormGroup;
    type: string = "password";
    isText: Boolean = false;
    eyeIcon: string = "fa-eye-slash";


    constructor(private router: Router,
                private _matSnackbar: MatSnackBar,
                private logService: LogService,
                private authService: AuthService,
                private directoryService: DirectionService,
                @Inject(DOCUMENT) private document: Document,
                private configService: ConfigService,
                private renderer: Renderer2) {
        this.initlayout()
    }

    ngOnInit(): void {
        this.loginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(16)]),
        })
        this.changePasswordForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        })

    }


    loginUser() {
        console.log(this.loginForm.value)

        this.authService.SignIn(this.loginForm.value['email'], this.loginForm.value['password'])
    }

    get username() {
        return this.loginForm.get('email');
    }

    get password() {
        return this.loginForm.get('password');
    }

    hideshowpass() {
        this.isText = !this.isText;
        this.isText ? this.eyeIcon = "fa-eye" : this.eyeIcon = " fa fa-eye-slash";
        this.isText ? this.type = "text" : this.type = "password";
    }

    logout() {
        this.authService.SignOut();
        console.log(this.loginForm)
    }

    forgotPassword() {

        this.changePassword = true

    }

    submitForgotPassword(email: string) {
        this.authService.changePassword(email)
        this._matSnackbar.open(`A verification email has been sent to ${email} `, 'EMAIL SENT')._dismissAfter(4000)
    }

    private initlayout() {
        this.config = this.configService.configData;
        this.directoryService.currentData.subscribe((currentData) => {
            if (currentData) {
                this.direction = currentData === 'ltr' ? 'ltr' : 'rtl';
            } else {
                if (localStorage.getItem('isRtl')) {
                    if (localStorage.getItem('isRtl') === 'true') {
                        this.direction = 'rtl';
                    } else if (localStorage.getItem('isRtl') === 'false') {
                        this.direction = 'ltr';
                    }
                } else {
                    if (this.config) {
                        if (this.config.layout.rtl === true) {
                            this.direction = 'rtl';
                            localStorage.setItem('isRtl', 'true');
                        } else {
                            this.direction = 'ltr';
                            localStorage.setItem('isRtl', 'false');
                        }
                    }
                }
            }
        });

        // set theme on startup
        if (localStorage.getItem('theme')) {
            this.renderer.removeClass(this.document.body, this.config.layout.variant);
            this.renderer.addClass(
                this.document.body,
                localStorage.getItem('theme') as string
            );
        } else {
            this.renderer.addClass(this.document.body, this.config.layout.variant);
        }
    }

}
