import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import axios from "axios";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class RazorpayService {
    response: any[] = []
env = environment

    constructor(
        private http: HttpClient,

    ) {

    }
    getPayments(){
    return this.http.get(environment.cloudFunctions.fetchPaymentByDate);

    }
    getTransactionsByDateRange(startDate: string, endDate: string): Observable<any[]> {
        const url = `${this.env.cloudFunctions.fetchPaymentByDate}?startDate=${startDate}&endDate=${endDate}`;
        console.log(url)
        return this.http.get<any[]>(url);
    }

}
