
<!--<div class="d-flex justify-content-end">-->
<!--<button mat-raised-button color="accent" class="m-4" (click)="openDialog()">Add Glossary</button>-->
<!--</div>-->
<section>
  <div class="row m-1" >
  <mat-form-field appearance="outline" class="col-md-4">
    <mat-label>Select Menu</mat-label>
    <mat-select [formControl]="selectMenu" (selectionChange)="onMenuSelect($event)">
      <mat-option value="admission-report">Admission Report</mat-option>
      <mat-option value="audit-logs">Audit Logs</mat-option>
      <mat-option value="centres">Centres</mat-option>
      <mat-option value="child-admission">Child-Admission</mat-option>
      <mat-option value="child-report">Child Report</mat-option>
      <mat-option value="cms">CMS</mat-option>
      <mat-option value="counselling">Counselling</mat-option>
      <mat-option value="dashboard">Dashboard</mat-option>
      <mat-option value="donation">Donation</mat-option>
      <mat-option value="donation-report">Donation Report</mat-option>
      <mat-option value="donor">Donor</mat-option>
      <mat-option value="education">Education</mat-option>
      <mat-option value="events">Events</mat-option>
      <mat-option value="hospital">Hospital</mat-option>
      <mat-option value="inventory">Inventory</mat-option>
      <mat-option value="masters">Masters</mat-option>
      <mat-option value="mealmate">Mealmate</mat-option>
      <mat-option value="reminders">Reminders</mat-option>
      <mat-option value="reports">Reports</mat-option>
      <mat-option value="types">Types</mat-option>
      <mat-option value="unit-sponsorship">Unit Sponsorship</mat-option>
      <mat-option value="units">Units</mat-option>
      <mat-option value="users">Users</mat-option>
      <mat-option value="weekly-report">Weekly Report</mat-option>
    </mat-select>
  </mat-form-field>
  </div>
<!--  <prettyjson [obj]="data"></prettyjson>-->

  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Glossary</h2>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button mat-mini-fab color="primary">
          <mat-icon class="col-white" (click)="openDialog()">add</mat-icon>
        </button>
      </li>
<!--      <li>-->
<!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
<!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
<!--        </div>-->
<!--      </li>-->
    </ul>
  </div>

<mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Name Column -->

  <ng-container matColumnDef="menu">
    <mat-header-cell *matHeaderCellDef>Menu</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.menu }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
  </ng-container>

  <!-- Description Column -->
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
    <mat-cell *matCellDef="let row">{{ row.description }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="action">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let row"><button mat-icon-button color="primary" matTooltip="Edit Description" (click)="editDialog(row)" ><mat-icon>edit</mat-icon></button></mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
  <mat-paginator [pageSizeOptions]="[25,50,100,200,250,500,1000]"
                 showFirstLastButtons
                 aria-label="Select page">
  </mat-paginator>
</section>
