<div class="container">
    <h1 class="rounded text-center">Update Pending Payment </h1>
    <table class="table table-striped">
        <tbody>
        <tr>
            <th>Event Name</th>
            <td>{{data.eventName}}</td>
        </tr>
        <tr>
            <th>Centre</th>
            <td>{{data.centre}}</td>
        </tr>
        <tr>
            <th>Date</th>
            <td>{{data.eventDate}}</td>
        </tr>

        <tr>
            <th>Booked Slot</th>
            <td>{{data.mealSlot}}</td>
        </tr>
        <tr>
            <th>Donor Name</th>
            <td>{{data.donated_by}}</td>

        </tr>
        <tr>
            <th>Email</th>
            <td>{{data.email}}</td>
        </tr>
        <tr>
            <th>Mobile</th>
            <td>{{data.mobile}}</td>
        </tr>
        <tr>
            <th>Remark</th>
            <td>{{data.remark}}</td>
        </tr>
        </tbody>
    </table>
    <form [formGroup]="mealForm">
        <div class="row">
            <mat-form-field appearance="outline" class="col-md-12">
                <mat-label>Payment By</mat-label>
                <mat-select formControlName="paymentMethod">
                    <mat-option value="Cash">Cash</mat-option>
                    <mat-option value="Cheque">Cheque</mat-option>
                    <mat-option value="Netbanking">Netbanking</mat-option>
                    <mat-option value="UPI">UPI</mat-option>
                    <mat-option value="Credit/Debit Card">Credit/Debit Card</mat-option>
                </mat-select>
                <mat-error
                        *ngIf="mealForm.get('paymentMethod')?.invalid  &&  (mealForm.get('paymentMethod')?.touched || mealForm.get('paymentMethod')?.dirty)">
                    <span style="color: red;"
                          *ngIf="mealForm.get('paymentMethod')?.errors?.required">*It is Required.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width">
                <mat-label>Remark/Transaction ID /Reference Number</mat-label>
                <input matInput formControlName="remark">
                <mat-error
                        *ngIf="mealForm.get('remark')?.invalid  &&  (mealForm.get('remark')?.touched || mealForm.get('remark')?.dirty)">
                        <span style="color: red;"
                              *ngIf="mealForm.get('remark')?.errors?.required">*It is Required.</span>
                </mat-error>
            </mat-form-field>

        </div>
        <div class="d-flex justify-content-center">
            <button mat-raised-button color="primary"  (click)="UpdatePayment()" > Update </button>
        </div>

    </form>

</div>
