import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Volunteerdata} from "./volunteerdata"
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {environment} from "../../../environments/environment";

export interface VolunteerRequest{
  id:string,
  contact_number:number,
  email_number:string,
  emergency_number:string
  first_name:string
  last_name:string

}

@Injectable({
  providedIn: 'root'
})



export class VolunteerService {

  constructor(private mDatabase: AngularFireDatabase,
              private mFirestore:AngularFirestore) { }
  env= isDevMode()? environment.testMode :environment.productionMode
// addvolunteer(data:any){
//   let array = []
//   array.push(data)
//   //Method to set data in firestore database where folder name is testchild and file name is testdocument
//
// }

  addVolunteer(Volunteerdata:Volunteerdata) {
    this.mDatabase.list(this.env.volunteers).push(Volunteerdata)
  }


  getVolunteer() {
    return this.mDatabase.list(this.env.volunteers).snapshotChanges()
  }
  getRequestedVolunteer() {
    const volunteerRef = this.mFirestore.doc<any>('volunteer/volunteers')
    return volunteerRef.valueChanges()
  }

  getVolunteerList() {
    const volunteerRef = this.mFirestore.doc('volunteer/volunteers')
    return volunteerRef.snapshotChanges()
  }

}
