<!---->
<div class="container">
  <!--  <div>-->
  <!--    <BR>-->
  <!--  <div class="materialTableHeader">-->
  <!--    <div class="left">-->
  <!--      <ul class="header-buttons-left ms-0">-->
  <!--        <li class="tbl-title mt-3">-->
  <!--          <h2>Users</h2>-->
  <!--        </li>-->
  <!--        <li class="tbl-search-box">-->
  <!--          <label for="search-input"><i class="material-icons search-icon">search</i></label>-->
  <!--          <input placeholder="Search" type="text" #filter class="browser-default search-field"-->
  <!--                 aria-label="Search box" (keyup)="applyFilter($event)" #input>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--    <div class="right">-->
  <!--      <ul class="tbl-export-btn">-->
  <!--        <li class="tbl-header-btn">-->
  <!--          <div class="m-l-10" matTooltip="ADD">-->
  <!--            <button mat-mini-fab color="primary" *ngIf="employees[0].all==true || employees[0].add==true"-->
  <!--                    (click)="openpopup()">-->
  <!--              <mat-icon class="col-white">add</mat-icon>-->
  <!--            </button>-->
  <!--          </div>-->
  <!--        </li>-->
  <!--        <li class="tbl-header-btn">-->
  <!--          <div class="m-l-10" matTooltip="REFRESH">-->
  <!--            <button mat-mini-fab color="primary">-->
  <!--              <mat-icon class="col-white">refresh</mat-icon>-->
  <!--            </button>-->
  <!--          </div>-->
  <!--        </li>-->
  <!--        <li>-->
  <!--          <div class="export-button m-l-10" matTooltip="XLSX">-->
  <!--            <img src="assets/images/icons/xlsx.png" alt=""/>-->
  <!--          </div>-->
  <!--        </li>-->
  <!--      </ul>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Users</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button mat-mini-fab color="primary">
          <mat-icon class="col-white" (click)="openpopup()">add</mat-icon>
        </button>
      </li>
<!--      <li>-->
<!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
<!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
<!--        </div>-->
<!--      </li>-->
    </ul>
  </div>

  <!--    <div class="d-flex justify-content-end mt-2">-->
  <!--        <button color="accent" *ngIf="employees[0].all==true || employees[0].add==true"  mat-raised-button id="create-center" (click)="openpopup()">-->
  <!--            <mat-icon>person_add</mat-icon>&nbsp;User-->
  <!--        </button>&nbsp;-->
  <!--    </div>-->
  <!--  </div>-->
  <!--    <br>-->

  <!--    <mat-form-field>-->
  <!--        <mat-label>Filter</mat-label>-->
  <!--        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>-->
  <!--    </mat-form-field>-->

  <section class="example-container mat-elevation-z8" tabindex="0">
    <table mat-table [dataSource]="dataSource" matSort class="child-table" matSortDisableClear
           matSortDirection="desc">
      <!--  POSITION Column-->
      <!--      <ng-container matColumnDef="position">-->
      <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header="#"> Num.</th>-->
      <!--        <td mat-cell *matCellDef="let i = index">-->
      <!--          <mat-checkbox-->
      <!--            [ngClass]="'tbl-checkbox'">-->
      <!--          </mat-checkbox>-->
      <!--          &lt;!&ndash;          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}&ndash;&gt;-->
      <!--        </td>-->
      <!--      </ng-container>-->

      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Image</th>
        <td mat-cell *matCellDef="let element" class="table-img">
          <img src="/assets/images/table-profile.jpg"/>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element"> {{element.userName}} </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <!-- Center Column -->
      <!--            <ng-container matColumnDef="centercode">-->
      <!--                <th mat-header-cell *matHeaderCellDef>Center Code</th>-->
      <!--                <td mat-cell *matCellDef="let element">{{element.centerCode}} </td>-->
      <!--            </ng-container>-->

      <!-- Name Column -->
      <!--            <ng-container matColumnDef="employeecode">-->
      <!--                <th mat-header-cell *matHeaderCellDef>Employee Code</th>-->
      <!--                <td mat-cell *matCellDef="let element"> {{element.employeecode}} </td>-->
      <!--            </ng-container>-->
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef>Mobile</th>
        <td mat-cell *matCellDef="let element"> {{element.mobile}} </td>
      </ng-container>
      <!--      Child Name Column-->


      <!--      Last Name Column-->


      <!-- City Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef> Location</th>
        <td mat-cell *matCellDef="let element"> {{element.location}} </td>
      </ng-container>
      <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef> Department</th>
        <td mat-cell *matCellDef="let element"> {{element.department}}
        </td>
      </ng-container>
      <ng-container matColumnDef="jobtitle">
        <th mat-header-cell *matHeaderCellDef>Job Title</th>
        <td mat-cell *matCellDef="let element"> {{element.jobtitle}} </td>
      </ng-container>
      <!-- Symbol Column -->
      <!--    <ng-container matColumnDef="symbol">-->
      <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
      <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
      <!--    </ng-container>-->

      <!--     Action-->

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let element"> {{getStatus(element)}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action</th>
        <td mat-cell *matCellDef="let element;let i = index">
          <div class="d-flex ">
            <button mat-icon-button *ngIf="employees[0].edit==true || employees[0].all==true" color="primary"
                    (click)="viewDialog(element)" matTooltip="Edit Permissions">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button *ngIf="employees[0].edit==true || employees[0].all==true" color="primary"
                    (click)="editDialog(element)" matTooltip="Edit Info">
              <mat-icon>person</mat-icon>
            </button>
          </div>
          <!--          <button mat-icon-button>-->
          <!--            <mat-icon>archive</mat-icon>Archive-->

          <!--          </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
          <!--          <button  (click)="datashow(element)" mat-icon-button >-->
          <!--            <mat-icon>remove_red_eye</mat-icon>View-->
          <!--          </button>&nbsp;&nbsp;&nbsp;-->

          <!--          <button  mat-icon-button >-->
          <!--            <mat-icon >remove_red_eye</mat-icon>View-->
          <!--          </button>-->
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>

    </table>

  </section>
  <mat-paginator [pageSizeOptions]="[25, 50, 100,500]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
</div>

