import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class GlossaryService {

  constructor(
      private  readonly mFirestore:AngularFirestore
  ) { }


    fetchData(value: string) {
     return  this.mFirestore.collection('glossary').doc(value!).valueChanges()
    }

  fetchCounsellingGlossary() {
    return  this.mFirestore.collection('glossary').doc('counselling').valueChanges()
  }


}
