import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ActivityLog, LogService} from "../../services/log.service";
import {takeUntil} from "rxjs/internal/operators/takeUntil";
import {MatSelectChange} from "@angular/material/select";
import {map} from "rxjs/operators";
import {AddUnitSponsorshipComponent} from "../unit-sponsorship/add-unit-sponsorship/add-unit-sponsorship.component";
import {MatDialog} from "@angular/material/dialog";
import {ChangesComponent} from "./changes/changes.component";

@Component({
    selector: 'app-activity-log',
    templateUrl: './activity-log.component.html',
    styleUrls: ['./activity-log.component.scss']
})
export class ActivityLogComponent implements OnInit ,OnDestroy{

    startDateFilter: Date | null = null;
    endDateFilter: Date | null = null;
    private _destroyed$=new Subject()
    @ViewChild(MatPaginator) paginator1!: MatPaginator;
    @ViewChild(MatSort) sort1!: MatSort;
    dataSource: MatTableDataSource<ActivityLog>;
    data:ActivityLog[]=[]
    filterdata:ActivityLog[]=[]
    actionSelected='All'
    sectionSelected='All'
    displayedColumns: String[] = ['date', 'section','action','user','description' ,'currentIp', 'changes'];
  action: string[] = ['All', 'Delete', 'Login', 'Logout', 'Submit', 'Update', 'View'];
  section: string[] = [ 'All','Admission','Assessment Counseling', 'Centre', 'Donor', 'Employee', 'Group Counseling', 'Group Education', 'Hospital', 'Individual Counseling', 'Individual Education', 'Inventory', 'Login', 'Logout', 'Prescreening', 'Types', 'Unit Sponsorship', 'Vehicle'];
  filterstring='';
    constructor(private mService:LogService,
                private matdialog: MatDialog) {
        this.dataSource = new MatTableDataSource<ActivityLog>(this.data)

    }

    ngOnInit(): void {
      this.mService.getLogsByCount(3000)
      .pipe(
        takeUntil(this._destroyed$),
        map(changes =>
          changes.map(c =>
            ({ key: c.payload.key, ...c.payload.val() })
          )
        )
      )
      .subscribe(res => {
        // @ts-ignore
        this.data = res;
        this.data.reverse();
        this.dataSource = new MatTableDataSource<ActivityLog>(this.data);
        this.dataSource.paginator = this.paginator1;
      });

    }

    ngOnDestroy(): void {
        this._destroyed$.next('')
        this._destroyed$.complete()
    }

    onAction($event: MatSelectChange) {
        console.log($event.value)
        this.actionSelected =$event.value
        this.filterdata= this.data.filter((x)=>x.action==this.actionSelected)
        this.dataSource.data =this.filterdata
        if($event.value=='All')this.filterdata=this.data
    }

    onSection($event: MatSelectChange) {
        console.log($event.value)
        this.sectionSelected =$event.value
        this.filterdata= this.data.filter((x)=>x.section==this.sectionSelected)
        this.dataSource.data =this.filterdata
        if($event.value=='All')this.filterdata=this.data

    }

    clearAll() {
        this.actionSelected ='All'
        this.sectionSelected='All'
      this.startDateFilter=null
      this.endDateFilter=null
      this.filterstring=''
      this.dataSource.data=this.data
    }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  applyDateRangeFilter() {
    if (this.startDateFilter && this.endDateFilter) {
      this.filterdata =this.data.filter(x => x.date > this.startDateFilter?.getTime()! && x.date < this.endDateFilter?.getTime()!)
    this.dataSource.data =this.filterdata
    }

  }

  // @ts-ignore

  viewChanges(element:any) {
    this.matdialog.open(ChangesComponent,
      {
        width: '50%', height: '300px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
        data: element
      })


  }
}
