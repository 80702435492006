<h1 class="bg-warning rounded text-dark text-center">Edit</h1>
<form [formGroup]="menuForm" (ngSubmit)="submitForm()" class="m-4">
  <div class="d-flex flex-column">
    <mat-form-field>
      <mat-label>Select a Menu</mat-label>
      <mat-select formControlName="menu" [disabled]="isFormDisabled">
        <mat-option [value]="data.menu">{{ data.menu }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <input matInput type="text" formControlName="name" placeholder="Name"   >
    </mat-form-field>


    <mat-form-field>
      <textarea matInput type="text" formControlName="description" placeholder="Description" rows="6"></textarea>
    </mat-form-field>

    <div class="d-flex justify-content-center">
      <button mat-raised-button color="warn" type="reset" (click)="closeDialog()" >Cancel</button>
      <button mat-raised-button color="primary" type="submit" class="mx-2">Update</button>
    </div>
  </div>
</form>
