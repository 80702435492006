<div class="container p-5">
    <mat-form-field appearance="outline">
        <mat-label>Section</mat-label>
        <mat-select (selectionChange)="onSection($event)"  [(ngModel)]="sectionSelected">
            <mat-option *ngFor="let a of section" [value]="a">{{a}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="mx-2">
        <mat-label>Action</mat-label>
        <mat-select  (selectionChange)="onAction($event)" [(ngModel)]="actionSelected" >
            <mat-option *ngFor="let a of action" [value]="a">{{a}}</mat-option>
        </mat-select>
    </mat-form-field>





    <mat-form-field appearance="outline" class="mx-2">
      <input matInput [matDatepicker]="startDatePicker" placeholder="Start Date" [(ngModel)]="startDateFilter">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="mx-2">
      <input matInput [matDatepicker]="endDatePicker" placeholder="End Date" [(ngModel)]="endDateFilter">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
    </mat-form-field>

  <button mat-mini-fab class="mx-2" color="accent" (click)="applyDateRangeFilter()"><mat-icon>search</mat-icon></button>
  <button mat-mini-fab color="accent"  (click)="clearAll()" matTooltip="Clear">
    <mat-icon>clear</mat-icon>
  </button>
<!--  <mat-form-field appearance="outline" class="mx-2">-->
<!--    <input matInput [(ngModel)]="filterstring" (keyup)="applyFilter($event)" placeholder="Filter">-->
<!--  </mat-form-field>-->



  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Audit Logs</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">

<!--      <li>-->
<!--        <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
<!--          <img src="assets/images/icons/xlsx.png" alt="" />-->
<!--        </div>-->
<!--      </li>-->
    </ul>
  </div>

    <section class="example-container mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="dataSource" matSort class="child-table" matSortDisableClear matSortDirection="desc"  >
            <!--  POSITION Column-->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="#">Sr.</th>
                <td mat-cell *matCellDef="let i = index">
                    {{this.paginator1.pageIndex == 0 ? i + 1 : 1 + i + this.paginator1.pageIndex * this.paginator1.pageSize}}
                </td>
            </ng-container>
            <!-- Center Column -->
            <!--            <ng-container matColumnDef="position">-->
            <!--                <th mat-header-cell *matHeaderCellDef mat-sort-header> Position </th>-->
            <!--                <td mat-cell *matCellDef="let element;let i=index"> {{i+1  }} </td>-->
            <!--            </ng-container>-->

            <!-- Name Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Date </th>
                <td mat-cell *matCellDef="let element"> {{element.date|date:'dd/MM/yyyy hh:mm:aa'}} </td>
            </ng-container>
            <!--      Child Name Column-->
            <ng-container matColumnDef="section">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Section </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.key"> {{element.section   }} </td>
            </ng-container>

            <!--      Last Name Column-->
            <ng-container matColumnDef="action" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                <td mat-cell *matCellDef="let element"> {{element.action  }} </td>
            </ng-container>
            <ng-container matColumnDef="user" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
                <td mat-cell *matCellDef="let element"> {{element.user  }} </td>
            </ng-container>
            <!-- City Column -->
            <ng-container matColumnDef="description" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
                <td mat-cell *matCellDef="let element"> {{element.description}} </td>
            </ng-container>
            <ng-container matColumnDef="currentIp" >
                <th mat-header-cell *matHeaderCellDef mat-sort-header> IP </th>
                <td mat-cell *matCellDef="let element"> {{element.currentIp}} </td>
            </ng-container>


          <ng-container  matColumnDef="changes" >
            <th mat-header-cell   *matHeaderCellDef mat-sort-header> Changes </th>
            <td mat-cell  *matCellDef="let element" ><button *ngIf="element.changes" mat-icon-button color="warn"  (click)="viewChanges(element)"><mat-icon>toc</mat-icon></button> </td>

          </ng-container>
            <!-- Symbol Column -->
            <!--    <ng-container matColumnDef="symbol">-->
            <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
            <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
            <!--    </ng-container>-->

            <!--     Action-->

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr *matNoDataRow class="mat-row">
                <!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
            </tr>


        </table>
    </section>
    <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
</div>
