<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Sponsor">
<app-sponsor-table></app-sponsor-table>
  </mat-tab>

  <mat-tab label="Sponsored Children">
    <app-unit-sponsorship></app-unit-sponsorship>
  </mat-tab>
  <mat-tab label="Non Sponsored Children">
    <app-unsponsor-children-table></app-unsponsor-children-table>
  </mat-tab>
</mat-tab-group>



<!--Add Group Education Table Component-->
<!--Add it to Group Session Tab-->
<!--Create View Group Session Component-->
<!--Add Group Session Component to group Table View Button-->
