<div class="container">

  <form class="content" [formGroup]="centerForm">
    <h3 class="text-center"><b>AL Centre Form</b></h3>
    <div id="info">&nbsp;Information</div>
    <div class="row">

      <div class="col-md-6">

        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>AL Centre Name</mat-label>
              <input matInput placeholder="Enter your Centre name"
                     formControlName="centreName">
              <mat-error *ngIf=" centreName.invalid  &&  (centreName.touched || centreName.dirty)">
                <span style="color: red;" *ngIf="centreName.errors?.required">*Centre Name is Required.</span>
                <span style="color: red;" *ngIf="centreName.errors?.pattern">*Enter Only characters.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <!--------------------------------------------- Total Units --------------------------------------------->
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Amenities</mat-label>
              <mat-select multiple type="text" placeholder="Amenities" required formControlName="ammenities" >
                <mat-option *ngFor="let am of (ammenities$|async)" [value]="am">{{am}} </mat-option>
              </mat-select>
              <mat-error *ngIf="Ammenities.invalid  &&  (Ammenities.touched || Ammenities.dirty)">
                <span style="color: red;" *ngIf="Ammenities.errors?.required">*Required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <!--------------------------------------------- Address --------------------------------------------->
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label>Address</mat-label>
              <input formControlName="address" matInput placeholder="Address">

              <mat-error *ngIf="  address.invalid  &&  (address.touched || address.dirty)">
                <span style="color: red;" *ngIf="address.errors?.required">*Street is Required.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input matInput placeholder="City" required formControlName="city">
              <mat-error *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
                <span style="color: red;" *ngIf="city.errors?.required">*City is Required.</span>
                <span style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <input matInput placeholder="State/Province" required formControlName="state">
              <mat-error *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
                <span style="color: red;" *ngIf="state.errors?.required">* State/Province is Required.</span>
                <span style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Country</mat-label>
              <input matInput placeholder="Country" required formControlName="country">

              <mat-error *ngIf="  country.invalid  &&  (country.touched || country.dirty)">
                <span style="color: red;" *ngIf="country.errors?.required">*Country is Required.</span>
                <span style="color: red;" *ngIf="country.errors?.pattern">*Enter Only characters.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>Pin Code</mat-label>
              <input matInput required placeholder="Zip/Postal Code" (input)="findPinCode(zipcode.value)" formControlName="zipcode" maxlength="6"
                     minlength="6">
              <mat-error *ngIf=" zipcode.invalid  &&  (zipcode.touched || zipcode.dirty)">
                <span style="color: red;" *ngIf="zipcode.errors?.required">*Zip/Postal Code is Required.</span>
                <span style="color: red;" *ngIf="zipcode.errors?.pattern">*Enter Only Numbers.</span>
                <span style="color: red;" *ngIf="zipcode.errors?.maxlength">Zipcode must of 6 digits only</span>
                <span style="color: red;" *ngIf="zipcode.errors?.minlength">Zipcode must of 6 digits only</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline">
           <mat-label>Centre Manager</mat-label>
              <input matInput placeholder=" Centre Manager" formControlName="centreManager">
              <mat-error *ngIf=" centerManager.invalid  &&  (centerManager.touched || centerManager.dirty)">
            <span style="color: red;"
                  *ngIf="centerManager.errors?.required">*Allocated Centre Manager is Required.</span>
                <span style="color: red;" *ngIf="centerManager.errors?.pattern">*Enter Only characters.</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline">
              <mat-label> Centre Co-ordinators</mat-label>
              <input matInput placeholder=" Centre Co-ordinator" formControlName="centreCordinator"
              >
              <mat-error *ngIf="  centerCordinator.invalid  &&  (centerCordinator.touched || centerCordinator.dirty)">
                <!--            <span style="color: red;" *ngIf="centerCordinator.errors?.required">*Allocated Center Coordinator is Required.</span>-->
                <span style="color: red;" *ngIf="centerCordinator.errors?.pattern">*Enter Only characters.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

      </div>
      <div class="col-md-6 ">
        <p class="rounded text-center " style="font-weight: bolder">Floor Plan</p>
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Number Of Floors</mat-label>
            <mat-select   placeholder="Number Of Floors" formControlName='numberOfFloors' (selectionChange)="getTotalFloors($event)" required>
        <mat-option [value]=1>1</mat-option>
        <mat-option [value]=2>2</mat-option>
        <mat-option [value]=3>3</mat-option>
        <mat-option [value]=4>4</mat-option>
        <mat-option [value]=5>5</mat-option>
        <mat-option [value]=6>6</mat-option>
        <mat-option [value]=7>7</mat-option>
        <mat-option [value]=8>8</mat-option>
        <mat-option [value]=9>9</mat-option>
            </mat-select>
            <mat-error *ngIf="  centerCordinator.invalid  &&  (centerCordinator.touched || centerCordinator.dirty)">
              <!--            <span style="color: red;" *ngIf="centerCordinator.errors?.required">*Allocated Center Coordinator is Required.</span>-->
              <span style="color: red;" *ngIf="centerCordinator.errors?.pattern">*Enter Only characters.</span>
            </mat-error>
          </mat-form-field>
<!--          <h2>{{getTotalFloors}}</h2>-->



          <ng-container *ngIf="floorFormArray.length>0" >
            <div class="row" *ngFor="let control of floorFormArray.controls;let i=index"  formArrayName='floorsArray'>
              <div class="row" [formArrayName]="i">
              <mat-form-field appearance="outline">
                <mat-label *ngIf="i==0" >Total Units on Ground Floor </mat-label>
                <mat-label *ngIf="i>0" >Total Units on Floor {{i}}</mat-label>
                <input matInput maxlength="2" placeholder="Enter Number of Units on floor {{i+1}} " id="floor{{i+1}}" formControlName="unitsOnFloor">
                <mat-error *ngIf="  unitsOnFloor(i).invalid  &&  (unitsOnFloor(i).touched || unitsOnFloor(i).dirty)">
                              <span style="color: red;" *ngIf="unitsOnFloor(i).errors?.required">*Enter Total Number Of Units of floor{{i+1}}</span>
                  <span style="color: red;" *ngIf="unitsOnFloor(i).errors?.pattern">*Enter Only characters.</span>
                </mat-error>
              </mat-form-field>
            </div>
            </div>
          </ng-container>
        </div>

      </div>
      <div id="botn" class="col-12">
        <button color="warn" mat-raised-button (click)="onReset()"><b>Cancel</b></button>&nbsp;
        <!--        <button   class="btn btn-primary" type="submit" (click)="savennew()"> Save & New </button>&nbsp;-->
        <button color="primary" mat-raised-button type="submit" (click)="save()">Save</button>

      </div>
    </div>
  </form>

</div>


<!--<b>{{actionbtn}}</b>-->




