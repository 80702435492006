import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Router} from "@angular/router";
import {EventLogic} from "../../meals/meal-mate.service";

import {map} from "rxjs/operators";
import {doc} from "@angular/fire/firestore";
import {MatSnackBar} from "@angular/material/snack-bar";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {RemindersService} from "../reminders.service";
import {MatDialog} from "@angular/material/dialog";
import {UpdatePaymentComponent} from "../update-payment/update-payment.component";
import {MealEvent} from "../../meals/add-meal-event/add-meal-event.component";

export interface PaymentReminder {
    donated_by: string
    eventDate: string
    centre: string
    followUpDate: number
    event?:MealEvent
    id: string
    email: string
    mealSlot: string
    mobile: string
    paymentMethod: string
    remark: string
    eventName: string
}

@Component({
    selector: 'app-reminders',
    templateUrl: './payment-reminders.component.html',
    styleUrls: ['./payment-reminders.component.scss']
})
export class PaymentRemindersComponent implements OnInit {
// reminders$:string
    reminders: PaymentReminder[] = []

    constructor(
        private mFirestore: AngularFirestore,
        private router: Router,
        private _snackBar: MatSnackBar,
        private readonly reminderService: RemindersService,
        private  readonly matDialog:MatDialog
    ) {
        // this.reminders$ =
        this.reminderService.getMealEventReminders()
            .subscribe((res: PaymentReminder[]) => {
                this.reminders = res
            })
    }

    ngOnInit(): void {
    }

    updatePayment(r: PaymentReminder) {
this.matDialog.open(UpdatePaymentComponent,{
    data:r
})
        // console.assessment-table(r)
        // this.mFirestore.collection('Reminders').doc('donations').collection('pending').doc(r.id).update({paymentMethod: 'paid'}).then(() => {
        //     this._snackBar.open('Payment Received', '', {duration: 3000})
        //
        // })

    }
}
