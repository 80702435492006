export class UnitModel {
  centreCode?='NA';
  key?='NA';
  name?='NA';
  floorNo?:string;
  allotedTo?: string='NA'
  status?:string ="Available";
  centreName?:string
  order?:number
}
