import {Component, Inject, OnInit} from '@angular/core';
import {PaymentReminder} from "../payment-reminders/payment-reminders.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {RemindersService} from "../reminders.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {get, ref} from "@angular/fire/database";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {LogService} from "../../../services/log.service";

@Component({
    selector: 'app-update-payment',
    templateUrl: './update-payment.component.html',
    styleUrls: ['./update-payment.component.scss']
})
export class UpdatePaymentComponent implements OnInit {
    mealForm: FormGroup

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: PaymentReminder,
        private readonly matSnackBar: MatSnackBar,
        private readonly matDialogRef:MatDialogRef<UpdatePaymentComponent>,
        private readonly remindersService: RemindersService,

        private  readonly mLogService:LogService

    ) {
        this.mealForm = new FormGroup<any>({
            paymentMethod: new FormControl('', Validators.required),
            remark: new FormControl('', Validators.required)
        })
    }

    ngOnInit(): void {


    }
    @needConfirmation({title: 'Confirm Payment?', message: 'Are you sure payment Received?'})

    UpdatePayment() {

        if (this.mealForm.valid) {

            this.remindersService.PaymentComplete(this.mealForm, this.data).then(() => {
                this.remindersService.increaseCounter()
                this.matSnackBar.open('Payment Updated')._dismissAfter(4000)
                let activity={
                    date:new Date().getTime(),
                    section: 'Pending Payment',
                    action: 'Update',

                    description: `Pending status update by`,
                    currentIp: localStorage.getItem('currentip')!
                }
                this.mLogService.addLog(activity)
                this.matDialogRef.close()
            })
        } else {
            this.matSnackBar.open('Please enter the fields properly')._dismissAfter(3000)
        }
    }

}
