import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CentreService} from "../../../services/centre.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Centre} from "../../../interfaces/Centres";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Observable} from "rxjs";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-edit-centre',
  templateUrl: './edit-centre.component.html',
  styleUrls: ['./edit-centre.component.scss']
})
export class EditCentreComponent implements OnInit {
  centerForm: any;
  submitted = false;
  key: string
  centreModel: Centre
  oldTotalUnits!:number
  ammenities$: Observable<unknown[]>

  constructor(private matdialogref: MatDialogRef<EditCentreComponent>,
              private centreServiece: CentreService,
              private _snackBar: MatSnackBar,
              private readonly mLogService: LogService,
              @Inject(MAT_DIALOG_DATA) public data: { id: string, value: any }
  ) {
    this.key = data.id
    this.centreModel = data.value
    this.oldTotalUnits= this.centreModel.totalUnits!
    console.log('Edit Component key', data.id,)
    console.log('Edit Component key', data.value,)
    console.table(data.value)

    this.ammenities$ = centreServiece.getAmmenities()

  }

  ngOnInit(): void {
    console.log(JSON.stringify(this.centreModel))
    this.centerForm = new FormGroup({
      "centreName": new FormControl(this.centreModel.centreName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "address": new FormControl(this.centreModel.address, [Validators.required]),
      // "availableUnits":new FormControl(this.centreModel.availableUnits,[Validators.required,Validators.pattern('[0-9]*')]),
      // "occupiedUnits":new FormControl(this.centreModel.occupiedUnits,[Validators.required,Validators.pattern('[0-9]*')]),
      "totalUnits": new FormControl(this.centreModel.totalUnits, [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(2),Validators.min(this.oldTotalUnits)]),
      "city": new FormControl(this.centreModel.city, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "ammenities": new FormControl(this.centreModel.ammenities ),
      "state": new FormControl(this.centreModel.state, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "country": new FormControl(this.centreModel.country, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "zipcode": new FormControl(this.centreModel.zipcode, [Validators.required, Validators.pattern('[0-9]*')]),
      "centreManager": new FormControl(this.centreModel.centreManager,),
      "centreCordinator": new FormControl(this.centreModel.centreCordinator,),
      "centreCode": new FormControl(this.centreModel.centreCode, [Validators.required]),
      "mealMate": new FormControl(this.centreModel.mealMate), // Initialize with data from model
      "unitSponsorship": new FormControl(this.centreModel.unitSponsorship) // Initialize with data from model
    });
    console.log('edit Centre ngOnInit', this.centreModel.centreName)
    // this.centerForm.setValue(this.centreModel)
  }

  update() {
    this.submitted = true;
    if (this.centerForm.valid) {
      let center = this.centerForm.value
      this.centerForm.addControl('occupiedUnits', new FormControl(this.centreModel.occupiedUnits))
      this.centerForm.addControl('availableUnits', new FormControl(this.centreModel.availableUnits))
      this.centerForm.get('totalUnits').patchValue(+this.centerForm.get('totalUnits').value)

      const mOccupiedUnits = this.centreModel.occupiedUnits
      const fTotalUnits = +this.centerForm.get('totalUnits').value
      center.availableUnits = (fTotalUnits - <number>this.centreModel.occupiedUnits) as number
      if (this.centreModel.totalUnits != +this.centerForm.get('totalUnits').value) {
        console.log('Units changed')
      }

      this.centerForm.get('availableUnits').setValue(center.availableUnits)
      console.log(this.centerForm.value);
      this.centerForm.removeControl('$key')
      this.centreServiece.updateCenter(this.data.id, this.centerForm.value, this.centreModel.totalUnits!, center.totalUnits).then(() => {
        let activity = {
          date: new Date().getTime(),
          section: 'Centre',
          action: 'Update',
          description: `Centre Updated by`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
        this.matdialogref.close()
      })
    } else {
      console.log(this.findInvalidControls())
      this._snackBar.open('Please fill out the form properly', '', { duration: 3000 })
    }
  }

  //

  get centreName() {
    return this.centerForm.get('centreName');
  }

  get address() {
    return this.centerForm.get('address');
  }

  get occupiedRooms() {
    return this.centerForm.get('occupiedUnits');
  }

  get availableRooms() {
    return this.centerForm.get('availableUnits');
  }

  get totalRooms() {
    return this.centerForm.get('totalUnits');
  }

  get city() {
    return this.centerForm.get('city');
  }

  get state() {
    return this.centerForm.get('state');
  }

  get country() {
    return this.centerForm.get('country');
  }

  get zipcode() {
    return this.centerForm.get('zipcode');
  }

  get centerCordinator() {
    return this.centerForm.get('centreCordinator');
  }

  get centerManager() {
    return this.centerForm.get('centreManager');
  }
  get Ammenities() {
    return this.centerForm.get('ammenities')
  }

  onReset() {
    // this.submitted = false;
    // console.log(this.centerForm.reset())
    this.matdialogref.close()
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.centerForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

}
