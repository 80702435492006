<div class=" mt-2">
    <div class="row">
        <div class="">
            <div class="card">
                <div class="header">
                    <h2>Age Chart</h2>
                </div>
                <div class="body">
                    <apx-chart [series]="areaChartOptions.series!" [chart]="areaChartOptions.chart!"
                               [xaxis]="areaChartOptions.xaxis!" [yaxis]="areaChartOptions.yaxis!" [colors]="areaChartOptions.colors!"
                               [stroke]="areaChartOptions.stroke!" [legend]="areaChartOptions.legend!" [grid]="areaChartOptions.grid!"
                               [title]="areaChartOptions.title!"
                               [tooltip]="areaChartOptions.tooltip!" [dataLabels]="areaChartOptions.dataLabels!"></apx-chart>
                    <!--                <div class="row">-->
                    <!--                    <div class="col-4">-->
                    <!--                        <p class="text-muted font-15 text-truncate">Target</p>-->
                    <!--                        <h5>-->
                    <!--                            <i class="fas fa-arrow-circle-up col-green m-r-5"></i>$15.3k-->
                    <!--                        </h5>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-4">-->
                    <!--                        <p class="text-muted font-15 text-truncate">Last-->
                    <!--                            week</p>-->
                    <!--                        <h5>-->
                    <!--                            <i class="fas fa-arrow-circle-down col-red m-r-5"></i>$2.8k-->
                    <!--                        </h5>-->
                    <!--                    </div>-->
                    <!--                    <div class="col-4">-->
                    <!--                        <p class="text-muted text-truncate">Last-->
                    <!--                            Month</p>-->
                    <!--                        <h5>-->
                    <!--                            <i class="fas fa-arrow-circle-up col-green m-r-5"></i>$12.5k-->
                    <!--                        </h5>-->
                    <!--                    </div>-->
                    <!--                </div>-->
                </div>
            </div>
        </div>
    </div>
</div>
