import {Component, Inject, OnInit} from '@angular/core';
import {HospitalService} from "../../../services/hospital.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Hospitals} from "../../../interfaces/Hospitals";

@Component({
  selector: 'app-display-hospital',
  templateUrl: './display-hospital.component.html',
  styleUrls: ['./display-hospital.component.scss']
})
export class DisplayHospitalComponent implements OnInit {
  hospitals: Hospitals[] = [];
  datasource:Hospitals[]=[]
  constructor(  private mService: HospitalService,
                @Inject(MAT_DIALOG_DATA) public data: any){


                }

  ngOnInit(): void {

    this.mService.getAllHosptitals().subscribe(hospitals => {
      this.hospitals = []


      hospitals.forEach(c => {
        // console.assessment-table( c.payload.toJSON())
        let hospital = c.payload.toJSON() as Hospitals
        let key = c.payload.key

        if (key != null) {
          hospital['id'] = key;
        }
        this.hospitals.push(hospital)
        this.hospitals.reverse()
        this.datasource = this.hospitals
        console.log(this.datasource)
      })

    })

  }

}
