<div class="container">
<!--  <h2 class="rounded text-center mt-2">Follow Up Admissions</h2>-->
<!--  <ng-container class="d-flex flex-row" *ngFor="let recipient of map | keyvalue">-->
<!--  <div>-->
<!--    <mat-card class="m-1">-->
<!--      <h2> Child Code: {{recipient.key}}</h2>-->
<!--        <p>Name {{(recipient.value.firstName)}} {{recipient.value.lastName}}</p>-->
<!--        <p *ngIf="recipient.value.followUpDate != null">Follow Up Date: {{(recipient.value.followUpDate)?.toDate()|date:'dd/MM/yyyy'}}</p>-->
<!--    </mat-card>-->
<!--  </div>-->
<!--&lt;!&ndash;    {{recipient.key}} &ndash;&gt; {{recipient.value.centreCode}}&ndash;&gt;-->
<!--  </ng-container>-->
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Follow Up Admission</h2>
      </li>
            <li class="dropdown m-l-20">
              <label for="search-input"><i class="material-icons search-icon">search</i></label>
              <input placeholder="Search" type="text" class="browser-default search-field"
                     (keyup)='applyFilter($event)' aria-label="Search box" #input>
            </li>
    </ul>
    <ul class="header-buttons mr-2">

      <!--        <li>-->
      <!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
      <!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
      <!--          </div>-->
      <!--        </li>-->
    </ul>
  </div>

  <mat-table [dataSource]="dataSource" class="mat-elevation-z8 example-container"  matSort>
    <!-- Position Column -->
<!--    <ng-container matColumnDef="position">-->
<!--      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>-->
<!--      <mat-cell *matCellDef="let element"> {{element|json}} </mat-cell>-->
<!--    </ng-container>-->

    <!-- Name Column -->
    <ng-container matColumnDef="centreName">
      <mat-header-cell *matHeaderCellDef mat-sort-header > Centre Name </mat-header-cell>
      <mat-cell *matCellDef="let element" [matTooltip]="element.centreCode"> {{element.centreName}} </mat-cell>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="childName">
      <mat-header-cell *matHeaderCellDef  mat-sort-header> Child Name </mat-header-cell>
      <mat-cell *matCellDef="let element" [matTooltip]="element.childCode"> {{element.childName}} </mat-cell>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="followUpDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Follow Up Date </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.followUpDate.toDate()|date:'dd/MM/yyyy'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remark">
      <mat-header-cell *matHeaderCellDef  mat-sort-header> Remark </mat-header-cell>
      <mat-cell *matCellDef="let element" > {{element.remark}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action" class="text-center">
      <mat-header-cell *matHeaderCellDef  > Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="mx-2"><button color="accent" mat-icon-button (click)="datashow(element)" matTooltip="View">
          <mat-icon>remove_red_eye</mat-icon>&nbsp;
        </button>&nbsp;</div>
        <div class="mx-2"> <button color="primary" mat-icon-button (click)="edit(element)" matTooltip="Edit">
          <mat-icon>edit</mat-icon>&nbsp;
        </button>&nbsp;</div>
        <div class="mx-2"><button color="warn"   mat-icon-button (click)="delete(element)" matTooltip="Delete">
          <mat-icon>delete</mat-icon>&nbsp;
        </button>&nbsp;</div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator [length]="100"
                 [pageSize]="10"
                 [pageSizeOptions]="[25, 50, 100,1000]"
                 aria-label="Select page">
  </mat-paginator>

<!--  <h3>{{map  | json }}</h3>-->
</div>
