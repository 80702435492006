<div class="container">

<h1 class="rounded text-center" >  Add Type</h1>

<form [formGroup]="form" class="text-center">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>Enter  Name</mat-label>
      <input matInput type="text" formControlName="name" #inputField>
      <mat-error *ngIf="  Name?.invalid  &&  (Name?.touched || Name?.dirty)">
        <span style="color: red;" *ngIf="Name?.errors?.required">*It is Required.</span>
      </mat-error>
    </mat-form-field>
  </div>
<!--  <div>-->
<!--    <mat-form-field appearance="fill">-->
<!--      <mat-label>Enter Type Name</mat-label>-->
<!--      <input matInput type="text" formControlName="type">-->
<!--      <mat-error *ngIf="  form.get('type').invalid  &&  (form.get('type').touched || form.get('type').dirty)">-->
<!--        <span style="color: red;" *ngIf="form.get('type').errors?.required">*It is Required.</span>-->
<!--      </mat-error>-->
<!--    </mat-form-field>-->
<!--  </div>-->
<!--<div>STATUS: {{form.status}}</div>-->


  <button mat-raised-button color="primary" (click)="AddTypeList()">Submit</button>


</form>
</div>
