import {Injectable, isDevMode} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {PaymentReminder} from "./payment-reminders/payment-reminders.component";
import {Centre} from "../../interfaces/Centres";
import {shareReplay, tap} from "rxjs/operators";
import {Observable} from "rxjs";
import {FormGroup} from "@angular/forms";
import {environment} from "../../../environments/environment";
import {get, increment, ref, set} from "@angular/fire/database";
import {AngularFireDatabase} from "@angular/fire/compat/database";


@Injectable({
    providedIn: 'root'
})
export class RemindersService {
    readcount = 0
    env = isDevMode() ? environment.testMode : environment.productionMode

    constructor(private readonly mFirestore: AngularFirestore,
                private readonly mDatabase: AngularFireDatabase) {
    }

    getPaymentReminders() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query: any = this.mFirestore.collection<PaymentReminder>('Reminders/donations/pending').valueChanges({idField: 'id'})
        if (userData.role == 'Centre') {
            query = this.getRemindersByRole(userData.centreCode)
            console.log('Getting data by role ' + userData.role)
            console.log('Getting data by centre ' + userData.centreCode)
        }

        return query
    }

    getMealEventReminders() {
        let userData = JSON.parse(localStorage.getItem('userData')!)
        console.log('into getallcentres')
        let query: any = this.mFirestore.collection<PaymentReminder>('Reminders/donations/pending').valueChanges({idField: 'id'})
        if (userData.role == 'Centre') {
            query = this.getRemindersByRole(userData.centreCode)
            console.log('Getting data by role ' + userData.role)
            console.log('Getting data by centre ' + userData.centreCode)
        }
        return query
    }


    getRemindersByRole(centreCode: string) {
        // const centerRef = this.mFirestore.collection<PaymentReminder>('Reminders/donations/pending', ref1 => ref1.where('centreCode','==',centreCode)
        //*Fetching all events sorting using paymentmethod field
        const centerRef = this.mFirestore.collection<PaymentReminder>('Reminders/donations/pending', ref1 => ref1.where('centreCode', '==', centreCode).where('paymentMethod', '==', 'Pending')
            // .orderBy("sr", 'desc')
        )

        return centerRef.valueChanges({idField: 'key'}).pipe(
            tap(arr => console.log(`centre: read (${this.readcount += arr.length} )docs`), shareReplay(1)
            ))
    }

    async PaymentComplete(mealForm: FormGroup, data: PaymentReminder) {
        let obj = data.event!
        const writeBatch = this.mFirestore.firestore.batch()
        obj.paymentMethod = mealForm.get('paymentMethod')?.value
        obj.remark = mealForm.get('remark')?.value
        await this.getReceiptName().then(() => {
            obj.receipt_id = localStorage.getItem('current_bill_counter')!

        })
        await this.mFirestore.firestore.batch()
        let pendingDocRef: any = this.mFirestore.firestore.doc(`${this.env.reminders}/donations/pending/${obj.id}`)
        let updateDonationRef: any = this.mFirestore.firestore.doc(`${this.env.mealMateDonations}/${obj.id}`)
        writeBatch.delete(pendingDocRef)
        writeBatch.set(updateDonationRef, obj, {merge: true})
        return writeBatch.commit();


    }

    getReceiptName() {
        console.log('getting  receipt')

// const doc =ref(this.mDatabase,`donationtype/${donationtype}`)
        let msg = `2022-2023-80G-test-`;
        //  msg =   msg.replace('/', '-');
// FOR PRODUCTION
//     const documentRef = ref(this.mDatabase, `receiptcounter/counter`)

// FOR TEST
        const documentRef = ref(this.mDatabase.database, `${this.env.receiptCounter}`)
        return get(documentRef).then((snapshot: { val: () => string; }) => {
            console.log('snapshot.val() : ' + snapshot.val())
            if (snapshot.val().toString().length === 1) msg = msg + '00' + snapshot.val();
            else if (snapshot.val().toString().length === 2) msg = msg + '0' + snapshot.val();
            else msg = msg + snapshot.val();
            console.log(msg)

            localStorage.setItem('current_bill_counter', msg);

        })
            .catch(() => {
            });

    }

    increaseCounter() {
        console.log('Increasing counter')
        // let msg = `2022-2023-80G-`;
        //  msg =   msg.replace('/', '-');

        // const documentRef = ref(this.mDatabase, `receiptcounter/counter`)
        const documentRef = ref(this.mDatabase.database, `${this.env.receiptCounter}`)
        return set(documentRef, increment(1)).then(() => {
            console.log('counter updated by 1')
        })
            .catch(() => {
            });
    }
}
