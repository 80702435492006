<div class="container mt-3">
    <!*--------------------------------------------- FILTERS--------------------------------------------->
<!--    <span><strong> startDate {{startDate}} endDate {{endDate}} gender {{selectedGender}}-->
<!--        centre {{selectedCentreCode}}</strong></span>-->
<!--    <p>CASE {{case}}</p>-->

        &nbsp;&nbsp;<div class="row">
        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>From</mat-label>
            <input matInput [matDatepicker]="start" [(ngModel)]="startDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>To</mat-label>
            <input matInput [matDatepicker]="end" [(ngModel)]="endDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
        </mat-form-field>

        <!*--------------------------------------------- GENDER --------------------------------------------->

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Gender</mat-label>
            <mat-select (selectionChange)="onSelectGender($event)" [(ngModel)]="selectedGender">
                <mat-option value="Female">Female</mat-option>
                <mat-option value="Male">Male</mat-option>
            </mat-select>
        </mat-form-field>

        <!*--------------------------------------------- Location --------------------------------------------->

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>State</mat-label>
            <mat-select (selectionChange)="onSelectLocation($event)" [(ngModel)]="selectedLocation">

                <mat-option *ngFor="let s of StateNames" [value]=s.name>{{s.name}}</mat-option>

            </mat-select>
        </mat-form-field>
        <!*--------------------------------------------- Hospital --------------------------------------------->

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Hospital</mat-label>
            <mat-select (selectionChange)="onSelectHospital($event)" [(ngModel)]="selectedHospital">
                <mat-option *ngFor="let h of (hospitals$|async)" [value]="h.hospitalName">{{h.hospitalName}}</mat-option>
                <!--                <mat-option value="Female">Female</mat-option>-->
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-4">
            <mat-label>Diagnosis</mat-label>
            <mat-select (selectionChange)="onDiagnosisSelect($event)" [(ngModel)]="selectedDiagnosis">
                <mat-option *ngFor="let d of (_diagnosis$|async)" [value]="d">{{d}}</mat-option>
                <!--                <mat-option value="Female">Female</mat-option>-->
            </mat-select>
        </mat-form-field>


        <!*--------------------------------------------- END DATE --------------------------------------------->

        <mat-form-field appearance="outline" class="col-md-5">
            <mat-label>Select Centre</mat-label>
            <mat-select (selectionChange)="setSelectedCentreCode($event)" [(ngModel)]="selectedCentreCode">
                <mat-option *ngFor=" let c of (centres$|async) "
                            [value]="c.centreCode">{{c.centreName}} </mat-option>
                <!--      <mat-option  value="All">All Centres</mat-option>-->
            </mat-select>
        </mat-form-field>
        <!--  <button mat-icon-button (click)="getData(selectedCentreCode)">-->
        <!--    <mat-icon>search</mat-icon>-->
        <!--  </button>-->
        <mat-form-field appearance="outline" class="col-md-5">
            <mat-label>Status</mat-label>
            <mat-select (selectionChange)="onSelectStatus($event)" [(ngModel)]="selectedStatus">
                <mat-option  value="Discharged">Discharged </mat-option>
                <mat-option  value="OM SHANTI">OM SHANTI </mat-option>
                <mat-option  value="Staying">Staying </mat-option>
                <!--      <mat-option  value="All">All Centres</mat-option>-->
            </mat-select>
        </mat-form-field>
        <!--  <button mat-icon-button (click)="getData(selectedCentreCode)">-->
        <!--    <mat-icon>search</mat-icon>-->
        <!--  </button>-->

    <div class="col-md-2">
        <div class="d-flex  justify-content-end">
        &nbsp;&nbsp;<button *ngIf="childReport[0].view" mat-mini-fab color="accent"  (click)="dynamicQuery()" matTooltip="Search">
        <mat-icon>search</mat-icon>
    </button>&nbsp;&nbsp;&nbsp;&nbsp;
     &nbsp;&nbsp;&nbsp;&nbsp;   <button mat-mini-fab color="accent" *ngIf="childReport[0].view"  (click)="clearAll()" matTooltip="Clear">
            <mat-icon>clear</mat-icon>

        </button>
        </div>
    </div>

    </div>

<!--    <div class="m-3 d-flex justify-content-between">-->
<!--        <!*-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; START DATE -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
<!--        <mat-form-field appearance="outline">-->
<!--            <mat-label>Search</mat-label>-->
<!--            <input matInput (keyup)="applyFilter($event)" placeholder="Search Here.." #input>-->
<!--        </mat-form-field>-->

<!--      <div>  <button *ngIf="childReport[0].excel" mat-raised-button   class="m-1" (click)="saveComplexObject()" color="primary">  <mat-icon> cloud_download</mat-icon> &nbsp; Download  </button>-->
<!--      </div>-->
<!--      -->

<!--    </div>-->
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Child Report</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <!--      <li>-->
      <!--        <button   mat-mini-fab color="primary" (click)="openAddCarLog()">-->
      <!--          <mat-icon class="col-white" >add</mat-icon>-->
      <!--        </button>-->
      <!--      </li>-->
      <li>
        <div *ngIf="childReport[0].excel" class="export-button m-l-10" matTooltip="XLSX" (click)="saveComplexObject()">
          <img src="assets/images/icons/xlsx.png" alt="" />
        </div>
      </li>
    </ul>
  </div>

    <section class="example-container mat-elevation-z8" tabindex="1">
        <table mat-table [dataSource]="dataSource" matSort class="child-table table table-striped table-bordered" id="child-table" matSortDisableClear matSortDirection="desc">
            <!--  POSITION Column-->
            <ng-container matColumnDef="position">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="#"> Num. </th>
                <td mat-cell *matCellDef="let i = index">
                    {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                </td>
            </ng-container>
            <!-- Center Column -->
            <!--      <ng-container matColumnDef="centercode">-->
            <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header> Center Code </th>-->
            <!--        <td mat-cell *matCellDef="let element"> {{element.centreCode  }} </td>-->
            <!--      </ng-container>-->

            <!--      &lt;!&ndash; Name Column &ndash;&gt;-->
            <!--      <ng-container matColumnDef="childcode">-->
            <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Child Code </th>-->
            <!--        <td mat-cell *matCellDef="let element"> {{element.childCode}} </td>-->
            <!--      </ng-container>-->
            <!--      Child Name Column-->
            <ng-container matColumnDef="firstname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.childCode"> {{element.firstName}}  </td>
            </ng-container>
            <!*--------------------------------------------- Date Of Admission --------------------------------------------->
<!--            <ng-container matColumnDef="DOA">-->
<!--                <th mat-header-cell *matHeaderCellDef mat-sort-header> DOA </th>-->
<!--                <td mat-cell *matCellDef="let element"-->
<!--                    [matTooltip]="element.id"> {{getDate(element.dateOfAdmission)|date:'dd/MM/yyyy'}}  </td>-->
<!--            </ng-container>-->
<!--            <ng-container matColumnDef="dob">-->
<!--                <th mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Birth </th>-->
<!--                <td mat-cell *matCellDef="let element"-->
<!--                    [matTooltip]="element.id"> {{getDate(element.dob)|date:'dd/MM/yyyy'}}  </td>-->
<!--            </ng-container>-->
            <!--      Last Name Column-->
            <ng-container matColumnDef="lastname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                <td mat-cell *matCellDef="let element"> {{element.lastName  }} </td>
            </ng-container>

            <!-- City Column -->
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
                <td mat-cell *matCellDef="let element"> {{element.state}} </td>
            </ng-container>
            <ng-container matColumnDef="age">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Age as Of Date </th>
                <td mat-cell *matCellDef="let element"> {{element.age}}  </td>
            </ng-container>
            <!-- City Column -->
            <ng-container matColumnDef="enrolledIn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre </th>
                <td mat-cell *matCellDef="let element"> {{element.centreCode}} </td>
            </ng-container>
            <ng-container matColumnDef="gender">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
                <td mat-cell *matCellDef="let element"> {{element.gender}} </td>
            </ng-container>
            <!--      Status Column-->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element" [matTooltip]="element.selectUnit">
                    <ng-container>{{element.status}}</ng-container>
                </td>
                <!--      <td mat-cell *matCellDef="let element"> {{element.isAlloted  }} </td>-->
            </ng-container>


            <!-- Symbol Column -->
            <!--    <ng-container matColumnDef="symbol">-->
            <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
            <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
            <!--    </ng-container>-->

            <!--     Action-->


            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr *matNoDataRow class="mat-row">
                <!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
            </tr>


        </table>
    </section>
    <mat-paginator [pageSizeOptions]="[25,50,100,200,250,500,1000]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>

</div>


<!*--------------------------------------------- DYNAMIC TABLE --------------------------------------------->
<!--<ng-container *ngIf="childData.length>1 && show">-->
<!--<app-dynamic-assessment-table [data]="childData" [show] =show ></app-dynamic-assessment-table>-->
<!--</ng-container>-->


<!--<app-dynamic-assessment-table></app-dynamic-assessment-table>-->
