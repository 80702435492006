<div class="m-3 d-flex justify-content-between">
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search Here.." #input>
    </mat-form-field>
    <div>
        <mat-form-field>
            <mat-label>Select Status</mat-label>
            <mat-select  [(ngModel)]="selectedStatus">
                <mat-option value="Staying">Staying </mat-option>
                <mat-option value="Discharged">Discharged </mat-option>
                <mat-option value="All">All </mat-option>
                <!--      <mat-option  value="All">All Centres</mat-option>-->
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Select Centre</mat-label>
            <mat-select (selectionChange)="setSelectedCentreCode($event)">
                <mat-option *ngFor=" let c of (centres$|async) " [value]="c.centreCode">{{c.centreName}} </mat-option>
                <!--      <mat-option  value="All">All Centres</mat-option>-->
            </mat-select>
        </mat-form-field>
        <!--  <button mat-icon-button (click)="getData(selectedCentreCode)">-->
        <!--    <mat-icon>search</mat-icon>-->
        <!--  </button>-->
        <!--<button mat-raised-button  class="m-1" (click)="exportexcel()" color="primary">  <mat-icon> cloud_download</mat-icon> &nbsp; Download Table </button>-->

    </div>
</div>
<prettyjson [obj]="finalchildlist"></prettyjson>
<section class="example-container mat-elevation-z8" tabindex="1">
    <table mat-table [dataSource]="dataSource" matSort class="child-table" matSortDisableClear matSortDirection="desc" *ngIf="childData.length>1">
        <!--  POSITION Column-->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="#">Num.</th>
            <td mat-cell *matCellDef="let i = index">
                {{this.paginator1.pageIndex == 0 ? i + 1 : 1 + i + this.paginator1.pageIndex * this.paginator1.pageSize}}
            </td>
        </ng-container>
        <!-- Center Column -->
        <!--      <ng-container matColumnDef="centercode">-->
        <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header> Center Code </th>-->
        <!--        <td mat-cell *matCellDef="let element"> {{element.centreCode  }} </td>-->
        <!--      </ng-container>-->

        <!--      &lt;!&ndash; Name Column &ndash;&gt;-->
        <!--      <ng-container matColumnDef="childcode">-->
        <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Child Code </th>-->
        <!--        <td mat-cell *matCellDef="let element"> {{element.childCode}} </td>-->
        <!--      </ng-container>-->
        <!--      Child Name Column-->
        <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.id"> {{element.firstName}}  </td>
        </ng-container>

        <!--      Last Name Column-->
        <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name</th>
            <td mat-cell *matCellDef="let element"> {{element.lastName  }} </td>
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
        </ng-container>
        <!-- City Column -->
        <ng-container matColumnDef="enrolledIn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre</th>
            <td mat-cell *matCellDef="let element"> {{element.centreCode}} </td>
        </ng-container>
        <!--      Last Name Column-->
        <ng-container matColumnDef="unitCode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Code</th>
            <td mat-cell *matCellDef="let element"> {{element.selectUnit  }} </td>
        </ng-container>

        <!--      Status Column-->
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.selectUnit">
                <ng-container *ngIf="element.isAlloted==true"> Alloted</ng-container>
                <ng-container *ngIf="element.isAlloted==false">Discharged</ng-container>
            </td>
            <!--      <td mat-cell *matCellDef="let element"> {{element.isAlloted  }} </td>-->
        </ng-container>


        <!-- Symbol Column -->
        <!--    <ng-container matColumnDef="symbol">-->
        <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
        <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
        <!--    </ng-container>-->

        <!--     Action-->
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action</th>
            <td mat-cell *matCellDef="let element;let i = index">
                <!--                  <button mat-icon-button>-->
                <!--                    <mat-icon>archive</mat-icon>Archive-->
                <!---->
                <!--                  </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
                <button color="accent" mat-raised-button (click)="datashow(element)">
                    <mat-icon>remove_red_eye</mat-icon>&nbsp;View
                </button>&nbsp;
                <button color="primary" mat-raised-button (click)="gotoToEdit(element)">
                    <mat-icon>assignment</mat-icon>&nbsp;Edit
                </button>&nbsp;

                <!--          <button   mat-icon-button >-->
                <!--            <mat-icon>remove_red_eye</mat-icon>-->
                <!--          </button>View-->

                <!--          <button  mat-icon-button >-->
                <!--            <mat-icon >assignment</mat-icon>-->
                <!--          </button>Allotment-->

            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr *matNoDataRow class="mat-row">
            <!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
        </tr>


    </table>
</section>
<mat-paginator [pageSizeOptions]="[ 30, 50,100,250,500,1000,]"
               showFirstLastButtons
               aria-label="Select page of periodic elements">
</mat-paginator>
