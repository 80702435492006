import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit{

  @Input ()path: string ='test'
  @Input ()storagePath?: string ='uploads/'
  @Input ()dbType:'realtime'|'firestore'='realtime'
  // fetch=(this.path !='test')
  fetch=true
  title='Image Upload'
  constructor() {
  }

  ngOnInit(): void {
    console.log('Into  upload component',this.path)

  }

}
