import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import statesdata from '../../../../assets/json/states.json';
import {States} from "../../../interfaces/States";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ActivatedRoute, Route, Router} from "@angular/router";

@Component({
  selector: 'app-manual-donation',
  templateUrl: './manual-donation.component.html',
  styleUrls: ['./manual-donation.component.scss']
})
export class ManualDonationComponent implements OnInit {
  myForm!: any
  receiptDate:Date|undefined
  stateslist = statesdata as States[]
  private mId:string|undefined
  private  mData:any|null
  constructor(private readonly mFirestore:AngularFirestore,
             private router:Router,
             private mRoute:ActivatedRoute) {
  // private setReceiptDate(date:number,month:number,year:number,hour:number,minutes:number,ss:number) {

    this.setReceiptDate(23,3,2024,14,38,10)
    if(this.mId == null)   this.myForm = new FormGroup({
         donated_by: new FormControl('', Validators.required),
         address: new FormControl('', Validators.required),
         state: new FormControl('', Validators.required),
         donation_amount: new FormControl(0, Validators.required),
         donation_type: new FormControl({value: 'Online', disabled: true}, Validators.required),
         idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
         email: new FormControl('', [Validators.required, Validators.email]),
         country: new FormControl({value: 'India', disabled: true}, Validators.required),
         nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
         zipcode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
         city: new FormControl('', Validators.required),
         pan: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
         mobile: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]),
         details: new FormControl('details'),
         receipt_id: new FormControl(""),
         order_id: new FormControl(''),
         payment_id: new FormControl(''),
         groupKey: new FormControl('GF'),
         description: new FormControl('DESC'),
         method: new FormControl('ONLINE'),
         totalAmountWithoutTax: new FormControl(0),
         addtax: new FormControl(false),
         createdAt: new FormControl(this.receiptDate!!.getTime()),
       });
 this.mRoute.params.subscribe(res=>{
   this.mId= res['id']

   console.log('id is',this.mId)
   this.mFirestore.collection('failedDonations').doc(this.mId).get().subscribe(snap=>{
     this.mData = snap.data()
     // alert(JSON.stringify(this.mData))
     if(this.mData!=null){
         let details = JSON.parse(this.mData.details).error.metadata
       // alert(details)
       this.myForm = new FormGroup({
         donated_by: new FormControl(this.mData.donated_by, Validators.required),
         address: new FormControl(this.mData.address, Validators.required),
         state: new FormControl(this.mData.state, Validators.required),
         donation_amount: new FormControl(this.mData.totalAmountWithoutTax, Validators.required),
         donation_type: new FormControl({value: 'Online', disabled: true}, Validators.required),
         idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
         email: new FormControl(this.mData.email, [Validators.required, Validators.email]),
         country: new FormControl({value: 'India', disabled: true}, Validators.required),
         nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
         zipcode: new FormControl(this.mData.zipcode, [Validators.required, Validators.maxLength(6)]),
         city: new FormControl(this.mData.city, Validators.required),
         pan: new FormControl(this.mData.pan, Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
         mobile: new FormControl(this.mData.mobile, [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{10}$')]),
         details: new FormControl('details'),
         receipt_id: new FormControl(this.mData.receipt_id),
         order_id: new FormControl(details.order_id),
         payment_id: new FormControl(details.payment_id),
         groupKey: new FormControl(this.mData.groupkey),
         description: new FormControl(this.mData.description),
         method: new FormControl('ONLINE'),
         totalAmountWithoutTax: new FormControl(this.mData.totalAmountWithoutTax),
         addtax: new FormControl(false),
         createdAt: new FormControl(this.mData.date!!.toDate().getTime()),
         date: new FormControl((this.mData.date)!!.toDate()),
       });

     }

   })
 })





  }

  ngOnInit(): void {

  }



  submit() {
    if(this.myForm.valid) {
      this.mFirestore.collection('donations').add(this.myForm.value).then(res=>{
          this.router.navigateByUrl('/home')
      })
    }
else {
      console.log(this.findInvalidControls())

    }
  }
  public findInvalidControls() {
    const invalid = [];
    const controls = this.myForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  private setReceiptDate(date:number,month:number,year:number,hour:number,minutes:number,ss:number) {
  let d = new Date()
    d.setDate(date)
   d.setMonth(month)
    d.setFullYear(year)
    d.setHours(hour)
    d.setMinutes(minutes)
    d.setSeconds(ss)
    this.receiptDate =d
    return d
  }
}
