import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-add-glossary',
  templateUrl: './add-glossary.component.html',
  styleUrls: ['./add-glossary.component.scss']
})
export class AddGlossaryComponent implements OnInit {
  menuForm!: FormGroup;


  constructor(private formBuilder: FormBuilder,
              private mDatabase: AngularFirestore,
              public dialogRef: MatDialogRef<AddGlossaryComponent>) { }

  ngOnInit(): void {
    this.menuForm = this.formBuilder.group({
      menu: ['', Validators.required],
      name: ['', Validators.required],
      description: ['']
    });
  }

  submitForm() {
    if (this.menuForm.valid) {
      const formData = this.menuForm.value;
      const name = this.menuForm.get('name')?.value
      const menu = this.menuForm.get('menu')?.value
      const desc = this.menuForm.get('description')?.value


      const map = {menu: this.menuForm.value}
      // Add the form data to Firestore collection
      this.mDatabase.collection('glossary').doc(menu).set({[name]: desc}, {merge: true}).then(() => {
        console.log('Form data stored in Firestore');
        this.dialogRef.close();
      }).catch(error => {
        console.error('Error storing form data:', error);
      });

      // this.dataSource.data.push(formData);
      // this.dataSource._updateChangeSubscription();
      // this.data.push(formData);

    }


  }


  closeDialog() {
    this.dialogRef.close()
  }
}
