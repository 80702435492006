import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Reciept} from "../DonationModel";

@Component({
  selector: 'app-view-donation',
  templateUrl: './view-donation.component.html',
  styleUrls: ['./view-donation.component.scss']
})
export class ViewDonationComponent implements OnInit {
  myForm!: FormGroup;
  heading = 'Payment';
  description = 'Payment';
  calculatedAmount = 0;
  totalAmountWithoutTax = 500;
  minimumamount = ''

  constructor(public dialogRef: MatDialogRef<ViewDonationComponent>,
              @Inject(MAT_DIALOG_DATA) public receipt: Reciept) {
  }

  ngOnInit(): void {
    this.myForm = new FormGroup({
      donated_by: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      donation_amount: new FormControl(this.minimumamount, Validators.required),
      groupkey: new FormControl({value:'', disabled: true}, Validators.required),
      // idproof: new FormControl({value: 'PAN', disabled: true}, Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      // country: new FormControl({value: 'India', disabled: true}, Validators.required),
      // nationality: new FormControl({value: 'Indian', disabled: true}, Validators.required),
      zipcode: new FormControl('', [Validators.required, Validators.maxLength(6)]),
      donorCity: new FormControl('', Validators.required),
      pan: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')])),
      mobile: new FormControl('', [Validators.required, Validators.maxLength(12), Validators.minLength(10)]),
      // details: new FormControl(''),
      // addtax: new FormControl(this.istransactionIncluded)

    });
  this.myForm.setValue(this.receipt)
  }

}
