import {Component, OnInit, ViewChild} from '@angular/core';
import {volunteer} from "../volunteerdata";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {VolunteerRequest, VolunteerService} from "../volunteer.service";
import {VolunteerregisComponent} from "../volunteerregis/volunteerregis.component";
import {Centre} from "../../../interfaces/Centres";

@Component({
  selector: 'app-requested-volunteer',
  templateUrl: './requested-volunteer.component.html',
  styleUrls: ['./requested-volunteer.component.scss']
})
export class RequestedVolunteerComponent implements OnInit {
  ngOnInit(): void {
  }
  displayedColumns: String[] = ['position','firstname','lastname','centercode', 'volunteercode', 'city','Action'];
  volunteerData!:VolunteerRequest[]

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<VolunteerRequest>;

  constructor(private matdialog: MatDialog,
              private volunteerService:VolunteerService)
  {  // Create 100 users
    const users = Array.from({length: 100} )
    this.dataSource = new MatTableDataSource(this.volunteerData);}

  ngAfterViewInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    //Read
    this.volunteerService.getRequestedVolunteer().subscribe(c=>{
      this.volunteerData=[]
        // @ts-ignore
      console.log('VOLUNTEER REQUEST',c.volunteers)
        let volunteer = c.payload

        // @ts-ignore
      this.volunteerData= c.volunteers
        this.dataSource =new MatTableDataSource(this.volunteerData)
        this.dataSource.paginator = this.paginator;
      })

  }

  openpopup() {
    this.matdialog.open(VolunteerregisComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
