<div class=" container">
  <h2>Razorpay Transactions</h2>
<prettyjson [obj]="payments.length"></prettyjson>
  <h3>{{status}}</h3>
  <div class="example-loading-shade"
       *ngIf="isLoadingResults ">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>

  </div>
  <mat-table [dataSource]="datasource" matSort  class=" example-table-container mat-elevation-z8">
    <!-- Define the table columns -->
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="entity">
      <mat-header-cell *matHeaderCellDef>Entity</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.entity }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.amount/100 }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="currency">
      <mat-header-cell *matHeaderCellDef>Currency</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.currency }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.email }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="contact">
      <mat-header-cell *matHeaderCellDef>Number</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.contact }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="vpa">
      <mat-header-cell *matHeaderCellDef>VPA</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.vpa }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.created_at }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.status }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="order_id">
      <mat-header-cell *matHeaderCellDef>Order Id</mat-header-cell>
      <mat-cell *matCellDef="let payment">{{ payment.order_id }}</mat-cell>
    </ng-container>
    <!-- Repeat the above ng-container for other columns -->

    <!-- Define the table rows using MatTableRowDef -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </mat-table>
<!--  displayedColumns: string[] = ['id', 'entity', 'amount', 'currency', 'status', 'order_id', 'email', 'contact', 'vpa', 'created_at'];-->

  <mat-paginator [length]="payments.length" [pageSize]="30" aria-label="Select page of GitHub search results"></mat-paginator>

</div>
