import { Component, OnInit } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {distinct, Observable} from "rxjs";
import {distinctUntilChanged, map} from "rxjs/operators";
import * as XLSX from "xlsx";

@Component({
  selector: 'app-count-table',
  templateUrl: './count-table.component.html',
  styleUrls: ['./count-table.component.scss']
})
export class CountTableComponent implements OnInit {
  displayedColumns: String[] = ['yearRange','newAdmission','followUp',];
dataSource:any[]=[]
  years = [2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
  admissionCounts: { [year: number]: number } = {};
  fileName = 'Child_Count_Report.xlsx';

  constructor(private mFirestore: AngularFirestore) { }

  ngOnInit(): void {
    this.getYearlyAdmissionCounts()
  }

  getAdmissionCountForYear(year: number): Observable<number> {
    const startDate = new Date(`${year}-04-01T00:00:00Z`);
    const endDate = new Date(`${year + 1}-03-31T23:59:59Z`);

    return this.mFirestore
      .collection('ALChild', (ref) =>
        ref
          .where('dateOfAdmission', '>=', startDate)
          .where('dateOfAdmission', '<=', endDate)
      )
      .valueChanges()
      .pipe(
        map((data: any[]) => data.map(entry => entry.dateOfAdmission)),
        distinctUntilChanged(),
        map((dates) => dates.length)
      );
  }

  getYearlyAdmissionCounts(): void {
    for (const year of this.years) {
      this
        .getAdmissionCountForYear(year)
        .subscribe((count) => {
          (this.admissionCounts[year] = count)
          console.log(this.admissionCounts[year])
          }
        );

    }
  }

  exportexcel() {

    /* assessment-table id is passed over here */
    let element = document.getElementById('excel-table');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
    // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.receipts)
    ws['!cols'] = [];
    ws['!cols'][19] = {hidden: true};

    /* O1 is your Column in Excel*/

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);


  }



}
