import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private imageFileTypes = [

      'image/apng',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'application/pdf',
    'application/zip',
    'video/mp4',
    'video/mp4',
    'video/mpeg',
    'video/mpeg',
  ];

  validateFile(file: File): boolean {
    return this.imageFileTypes.includes(file.type);
  }
}
