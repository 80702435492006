<div class="container">
  <h1 class="bg-warning d-flex justify-content-center"> Vehicles Add Form</h1>
  <form [formGroup]="vehicleForm" (ngSubmit)="onSubmit()">
    <h2>Vehicle Information Form</h2>
    <div class="row">
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Vehicle Name</mat-label>
        <input matInput formControlName="vehicleName" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Vehicle Number</mat-label>
        <input matInput formControlName="vehicleNumber" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Vehicle Brand</mat-label>
        <input matInput formControlName="vehicleBrand" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Type of Body</mat-label>
        <input matInput formControlName="typeOfBody" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Type of Vehicle</mat-label>
        <input matInput formControlName="typeOfVehicle" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Maker's Name</mat-label>
        <input matInput formControlName="makersName" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Month and Year of Manufacture</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="manufactureDate" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Number of Cylinders</mat-label>
        <input matInput formControlName="numberOfCylinders" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Horse Power</mat-label>
        <input matInput formControlName="horsePower" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Cubic Capacity</mat-label>
        <input matInput formControlName="cubicCapacity" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Wheel Base</mat-label>
        <input matInput formControlName="wheelBase" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Chassis No.</mat-label>
        <input matInput formControlName="chassisNumber" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Engine Number</mat-label>
        <input matInput formControlName="engineNumber" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Seating Capacity</mat-label>
        <input matInput formControlName="seatingCapacity" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Fuel Used in the Engine</mat-label>
        <input matInput formControlName="fuelUsed" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Unladen Weight</mat-label>
        <input matInput formControlName="unladenWeight" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Particulars of Previous Registration and Registered Number</mat-label>
        <input matInput formControlName="previousRegistration" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Color or Colors of Body Wings and Front End</mat-label>
        <input matInput formControlName="bodyColor" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Servicing Date</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="servicingDate" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Servicing Duration</mat-label>
        <input matInput formControlName="servicingDuration" required>
      </mat-form-field>
      <hr>
      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Driver</mat-label>
        <input matInput formControlName="driver" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Vehicle (Purchased/Donated)</mat-label>
        <input matInput formControlName="vehicleStatus" required>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
        <mat-label>Provider Name</mat-label>
        <input matInput formControlName="providerName" required>
      </mat-form-field>
    </div>
    <div class="d-flex justify-content-center">
      <button mat-raised-button color="primary" type="submit">Submit</button>
    </div>
  </form>
</div>
