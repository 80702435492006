<div class="container">
<h2 class="font-bold m-4">Add User Role</h2>
  <div class="col-md-12 mb-4">
    <div class="row">
      <div class="col-md-6">
        <table class="table table-bordered">
          <tbody>
          <tr>
            <th>Name</th>
            <td>{{md.userName}}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{md.email}}</td>
          </tr>
          <tr>
            <th>Mobile</th>
            <td>{{md.mobile}}</td>
          </tr>
          <tr>
            <th>Location</th>
            <td>{{md.location}}</td>
          </tr>
          <tr>
            <th>Department</th>
            <td>{{md.department}}</td>
          </tr>
          <tr>
            <th>Job Title</th>
            <td>{{md.jobtitle}}</td>
          </tr>
          <tr *ngIf="md.vehicleNo">
            <th>Vehicle Number</th>
            <td>{{md.vehicleNo}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
  <mat-form-field appearance="outline" class="col-md-4">
  <mat-label>Select</mat-label>
  <mat-select [ngModel]="roleSelected" (selectionChange)="onRoleSelect($event)"  >
    <mat-option *ngFor="let u of roles" [value]="u" >{{u}}</mat-option>
  </mat-select>
</mat-form-field>

  <mat-form-field  appearance="outline" class="col-md-8">
    <mat-label>Select Centres</mat-label>
    <mat-select [(value)]="selectedCentres"  multiple>
      <mat-option *ngFor="let c of centres" [value]="c" >{{c}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
  <div class="text-center">
    <label class="mx-5 font-bold ">User Role</label>
<!--    <input type="text" placeholder="Enter Counselor" class="border border-secondary rounded bg-white text-dark p-2 ms-5 w-50 ">-->
  </div>

  <table class="table mt-4 ">
    <thead>
    <tr >
      <th class="head">MySpace Function</th>
      <th class="head">All</th>
      <th class="head">Add</th>
      <th class="head">Edit</th>
      <th class="head">Archive</th>
      <th class="head">View</th>
      <th class="head">Excel</th>
      <th class="head">Menu</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let d of (data)">
      <td *ngIf="d.functionName!='Employee'" >{{d.functionName}}</td>
      <td *ngIf="d.functionName=='Employee'" >Users</td>
      <td><input type="checkbox" [(ngModel)]="d.all" (change)="selectAllCheckbox()"></td>
      <td><input type="checkbox" [(ngModel)]="d.add"></td>
      <td><input type="checkbox" [(ngModel)]="d.edit"></td>
      <td><input type="checkbox" [(ngModel)]="d.archive"></td>
      <td><input type="checkbox" [(ngModel)]="d.view"></td>
      <td><input type="checkbox" [(ngModel)]="d.excel"></td>
      <td><input type="checkbox" [(ngModel)]="d.showMenu"></td>
    </tr>

    </tbody>
  </table>
<div class="text-center">
  <button mat-raised-button class="text-center bg-warning w-50 mt-4" (click)="submit()">Submit</button>
</div>
  <hr>
<!--<p>{{data|json}}</p>-->
<!--  <prettyjson [obj]="selectedCentres"></prettyjson>-->
<!--  <prettyjson [obj]="md.centresAllowed"></prettyjson>-->
</div>
