<h2>Donor Information</h2>
<table class="table table-active ">
  <tbody>
  <tr>
    <td><strong>Donor Name:</strong></td>
    <td>{{ data2.donorName }}</td>
  </tr>
  <tr>
    <td><strong>Mobile No.:</strong></td>
    <td>{{ data2.mobile }}</td>
  </tr>
  <tr>
    <td><strong>Address:</strong></td>
    <td>{{ data2.address }}</td>
  </tr>
  <tr>
    <td><strong>State:</strong></td>
    <td>{{ data2.state }}</td>
  </tr>
  <tr>
    <td><strong>Country:</strong></td>
    <td>{{ data2.country }}</td>
  </tr>
  <tr>
    <td><strong>City:</strong></td>
    <td>{{ data2.city }}</td>
  </tr>
  <tr>
    <td><strong>Pin code:</strong></td>
    <td>{{ data2.pincode }}</td>
  </tr>
  <tr>
    <td><strong>Email:</strong></td>
    <td>{{ data2.email }}</td>
  </tr>
  <tr>
    <td><strong>From:</strong></td>
    <td>{{ data2.date.toDate() | date }}</td>
  </tr>
  <tr>
    <td><strong>To:</strong></td>
    <td>{{ data2.date2.toDate() | date }}</td>
  </tr>
  <tr>
    <td><strong>Angle Name:</strong></td>
    <td>{{ data2.angleName }}</td>
  </tr>
  <tr>
    <td><strong>Payment Information:</strong></td>
    <td>{{ data2.paymentInformation }}</td>
  </tr>
  <tr>
    <td><strong>Transaction ID:</strong></td>
    <td>{{ data2.remark }}</td>
  </tr>
  <tr>
    <td><strong>Total Transaction Amount:</strong></td>
    <td>{{ data2.totalTransactionAmount }}</td>
  </tr>
  <tr>
    <td><strong>Remaining Amount:</strong></td>
    <td>{{ data2.remainingAmount }}</td>
  </tr>
  <tr>
    <td><strong>Pan Number:</strong></td>
    <td>{{ data2.panNumber }}</td>
  </tr>
  </tbody>
</table>
