<div class="container">
    <form [formGroup]="mealForm">
        <h2 class="text-center">Change Date</h2>
        <hr>
        <h4>Existing Event Date: {{date|date}}</h4>
        <!--    <h5>For Child : 1000INR For Family: 4000INR</h5>-->
        <!--------------------------------------------- Slot Status --------------------------------------------->
        <div class="mb-5 " *ngIf="mSlots!=undefined && mSlots!=null">
            <h2>Existing Booked Slots for the day</h2>
            <span class="heading" style="margin-left: 0" *ngIf="mSlots.lunchForChild">Lunch For Child  </span>
            <span class="heading" style="margin-left: 0" *ngIf="mSlots.lunchForFamily">Lunch For Family  </span>

            <span class="heading" style="margin-left: 0" *ngIf="mSlots.dinnerForChild">Dinner For Child   </span>
            <span class="heading" style="margin-left: 0" *ngIf="mSlots.dinnerForFamily">Dinner For Family  </span>

            <span class="heading" style="margin-left: 0"
                  *ngIf="mSlots.lunchAndDinnerForChild">Lunch and Dinner For Child   </span>
            <span class="heading" style="margin-left: 0"
                  *ngIf="mSlots.lunchAndDinnerForFamily">Lunch and Dinner For Family  </span>
            <!--      <span class="heading" *ngIf="!mSlots.bookedForLunch">Available For Lunch  :{{mSlots.bookedForLunch}} </span>-->
            <!--      <span class="heading" *ngIf="!mSlots.bookedForDinner">Available For Dinner:{{ mSlots.bookedForDinner}} </span>-->
        </div>
        <!--    <div class="mb-5 " *ngIf="mSlots==undefined">-->
        <!--      <h3>slots are null</h3>-->
        <!--      <span class="heading" style="margin-left: 0">Total Slots :{{ centre.occupiedUnits}}  </span>-->
        <!--      <span class="heading" *ngIf="true">Available For Lunch  :{{centre.occupiedUnits}} </span>-->
        <!--      <span class="heading" *ngIf="true">Available For Dinner:{{ centre.occupiedUnits}} </span>-->
        <!--    </div>-->
        <!--------------------------------------------- Slot Status Ended --------------------------------------------->


        <!--------------------------------------------- EVENT NAME --------------------------------------------->
        <table class="table">

            <tr>
                <th>Event Name</th>
                <td>{{old?.eventName}}</td>
            </tr>
            <tr>
                <th>Meal Slot</th>
                <td>{{old?.mealSlot}}</td>
            </tr>
            <tr>

                <th>Donor Name</th>
                <td>{{old?.donated_by}}</td>
            </tr>
            <tr>
                <th>Donor Number</th>
                <td>{{old?.mobile}}</td>
            </tr>
            <tr>
                <th>PAN</th>
                <td>{{old?.pan}}</td>
            </tr>
            <tr>
                <th>Address</th>
                <td>{{old?.address}}</td>
            </tr>
            <tr>
                <th>City</th>
                <td>{{old?.city}}</td>
            </tr>
            <tr>
                <th>Pincode</th>
                <td>{{old?.zipcode}}</td>
            </tr>

            <tr>
                <th>State</th>
                <td>{{old?.state}} </td>
            </tr>
            <tr>
                <th>Country</th>
                <td>India</td>
            </tr>
            <tr>
                <th>Email</th>
                <td>{{old?.email}} </td>
            </tr>
            <tr>
                <th>Payment Method</th>
                <td>{{old?.paymentMethod}} </td>
            </tr>
            <tr>
                <th>Remark</th>
                <td>{{old?.remark}} </td>
            </tr>

        </table>
        <!--------------------------------------------- DONOR NAME --------------------------------------------->

        <!--------------------------------------------- MOBILE --------------------------------------------->

        <!--------------------------------------------- ADDRESS --------------------------------------------->

        <!--------------------------------------------- STATE --------------------------------------------->

        <!--------------------------------------------- ZIPCODE --------------------------------------------->

        <!--------------------------------------------- EMAIL --------------------------------------------->
        <div class="row">
            <!--------------------------------------------- NEW EVENT DATE --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Centres</mat-label>
                    <mat-select formControlName="centreSelect"  [(value)]="toSelect">
                        <mat-option *ngFor="let c of centres"  [value]="c.centreCode">{{c.centreName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Change Event To Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="newDate"
                           (dateChange)="checkAvailableSlots($event)">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Meal Slot</mat-label>
                    <mat-select formControlName="mealSlot" placeholder="" (selectionChange)="selectionChange()"
                                #mMealSlot>
                        <mat-option
                                [disabled]="!(old?.mealSlot=='Lunch for child')  || mSlots?.lunchAndDinnerForChild|| mSlots?.lunchAndDinnerForFamily|| mSlots?.lunchForChild ||mSlots?.lunchAndDinnerForChild ||mSlots?.lunchForFamily "
                                value="Lunch for child">Lunch for Child
                        </mat-option>
                        <mat-option
                                [disabled]="!(old?.mealSlot=='Lunch for family') || mSlots?.lunchAndDinnerForChild|| mSlots?.lunchAndDinnerForFamily|| mSlots?.lunchForFamily || mSlots?.lunchAndDinnerForFamily  || mSlots?.lunchForChild  "
                                value="Lunch for family">Lunch for Family
                        </mat-option>

                        <mat-option
                                [disabled]="!(old?.mealSlot=='Dinner for child')||  mSlots?.lunchAndDinnerForChild|| mSlots?.lunchAndDinnerForFamily|| mSlots?.dinnerForChild || mSlots?.dinnerForFamily ||mSlots?.lunchAndDinnerForChild "
                                value="Dinner for child">Dinner for Child
                        </mat-option>
                        <mat-option
                                [disabled]="!(old?.mealSlot=='Dinner for family') ||   mSlots?.lunchAndDinnerForChild || mSlots?.lunchAndDinnerForFamily|| mSlots?.dinnerForFamily ||mSlots?.lunchAndDinnerForFamily! ||mSlots?.dinnerForChild "
                                value="Dinner for family">Dinner for Family
                        </mat-option>

                        <!--          <mat-option [disabled]="mSlots?.dinnerForChildAndFamily || mSlots?.dinnerForChild || mSlots?.dinnerForFamily "-->
                        <!--                      value="Dinner for child and family">Dinner for Child and Family-->
                        <!--          </mat-option>-->
                        <mat-option
                                [disabled]="!(old?.mealSlot=='Lunch and dinner for child') ||  mSlots?.lunchAndDinnerForChild|| mSlots?.lunchAndDinnerForFamily|| mSlots?.lunchAndDinnerForChild || mSlots?.lunchForChild ||mSlots?.dinnerForChild ||mSlots?.lunchForFamily|| mSlots?.dinnerForFamily"
                                value="Lunch and dinner for child">Lunch and Dinner for Child
                        </mat-option>
                        <mat-option
                                [disabled]="!(old?.mealSlot=='Lunch and dinner for family') || mSlots?.lunchAndDinnerForChild|| mSlots?.lunchAndDinnerForFamily|| mSlots?.lunchForChild || mSlots?.lunchForFamily ||mSlots?.dinnerForChild || mSlots?.dinnerForFamily "
                                value="Lunch and dinner for family">Lunch and Dinner for Family
                        </mat-option>
                        <!--          <mat-option [disabled]="mSlots?.lunchAndDinnerForFamily ||mSlots?.lunchForFamily"-->
                        <!--                      value="Lunch for child and dinner for family">Lunch for child and Dinner for Family-->
                        <!--          </mat-option>-->
                        <!--          -->
                        <!--          <mat-option-->
                        <!--            [disabled]=" mSlots?.lunchForChild || mSlots?.lunchAndDinnerForChildAndFamily || mSlots?.lunchForChild ||mSlots?.lunchForChildAndFamily ||mSlots?.lunchForFamily"-->
                        <!--            value="Lunch and dinner for child and family">Lunch and Dinner for Child and Family-->
                        <!--          </mat-option>-->
                        <!--            <mat-option   value="Lunch and Dinner">Both</mat-option>-->
                    </mat-select>
                    <mat-error *ngIf="mealSlot?.invalid  &&  (mealSlot?.touched || mealSlot?.dirty)">
                        <span style="color: red;" *ngIf="mealSlot?.errors?.required">*It is Required.</span>
                    </mat-error>
                </mat-form-field>

            </div>


        </div>
        <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
              <button mat-raised-button color="warn" type="reset" (click)="closeDialog()" class="mx-2" >Cancel</button>

              <button mat-raised-button [disabled]="!mealForm!.valid" (click)="changeMealDate()" type="submit"
                        color="accent"
                        >SUBMIT
                </button>
            </div>
            <div class="col-md-4">
                <div class="heading" *ngIf="totalAmount!=0" style="background: #ff3d00;color: white;font-weight: bold">
                    Total
                    Amount:{{old?.donation_amount}} INR
                </div>

            </div>
        </div>


    </form>

</div>
