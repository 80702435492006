export class FileUpload {
  // @ts-ignore
  key: string;
  // @ts-ignore

  fileName: string;
  // @ts-ignore
  imageUrl: string;
  file: File;

  constructor(file: File) {
    this.file = file;
  }
}
export class FileReference {
  // @ts-ignore
  key: string|undefined|null;
  // @ts-ignore

  fileName: string|undefined|null;
  // @ts-ignore
  imageUrl: string|undefined|null;
  // file: File;


}
