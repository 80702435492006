import {Component, OnInit, ViewChild} from '@angular/core';
import {RazorpayDataService} from "./razorpay-data.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
    selector: 'app-razorpay-transaction',
    templateUrl: './razorpay-transaction.component.html',
    styleUrls: ['./razorpay-transaction.component.scss']
})
export class RazorpayTransactionComponent implements OnInit {
    displayedColumns: string[] = ['id', 'entity', 'amount', 'currency', 'status', 'order_id', 'email', 'contact', 'vpa', 'created_at'];
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    transactions: any;
    datasource: MatTableDataSource<any>
    status: any
    isLoadingResults = false
    mData: any
    payments: any[] = []

    key_secret = 'nuYSZGm2PvUjbnZphjPcIDRA';
    key_id = 'rzp_live_n1im1rcGlUYmyU';

    constructor(private razorpayService: RazorpayDataService,
    ) {
        this.datasource = new MatTableDataSource<any>()
    }

    ngOnInit(): void {
        this.isLoadingResults = true;
        // this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

        this.razorpayService.fetchPayments2().subscribe({
            next: (data: any) => {
                this.isLoadingResults = false;

                this.mData = data.items
                // let obj = JSON.parse(data.body)
                // this.mData = obj.items
                this.transactions = data.items;
                for (let itemsKey in this.transactions) {
                    this.payments.push(this.transactions[itemsKey])
                }
                // Convert created_at to formatted date
                this.payments.forEach((payment) => {
                    const timestamp = payment.created_at * 1000; // Convert to milliseconds
                    payment.created_at = new Date(timestamp).toLocaleString();
                    payment.amount = payment.amount / 100
                });
                this.datasource.data = this.payments
                this.datasource.paginator = this.paginator
                this.datasource.sort = this.sort
                // this.isLoadingResults = false;

            },
            error: (error: any) => {
                this.status = 'Error fetching transactions:  ' + JSON.stringify(error)
                console.error('Error fetching transactions:', error);
            }
        });

        // this.razorpayService.fetchPayments2().subscribe(data => {
        // this.mData = data


        // })
    }
}
