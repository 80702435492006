import {Component, Inject, isDevMode, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Centre} from "../../../interfaces/Centres";
import {EventLogic, MealMateService} from "../meal-mate.service";
import {Observable, takeUntil} from "rxjs";
import {Donor} from "../../donoradmin/donor-table/donor-table.component";
import statesdata from "../../../../assets/states.json";
import {States} from "../../../interfaces/States";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFireDatabase, AngularFireObject} from "@angular/fire/compat/database";
import {MatSelectChange} from "@angular/material/select";
import {map, startWith} from "rxjs/operators";
import {MealEvent} from "../add-meal-event/add-meal-event.component";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {DatePipe} from "@angular/common";
import {environment} from "../../../../environments/environment";
import {CentreService} from "../../../services/centre.service";
import {LogService} from "../../../services/log.service";

@Component({
    selector: 'app-edit-meal-event',
    templateUrl: './edit-meal-event.component.html',
    styleUrls: ['./edit-meal-event.component.scss']
})
export class EditMealEventComponent implements OnInit {
    fb: FormBuilder
    // @ts-ignore
    mealForm: FormGroup
    centre!: Centre
    date!: string
    mSlots: EventLogic | undefined
    AvailableSlots$: AngularFireObject<EventLogic> | undefined
    disabled = false
    event!: MealEvent
    totalAmount = 0
    selectedPaymentMethod = ''
    // filteredOptions?: Observable<string[]>;
    donorList!: Donor[];
    stateslist = statesdata as States[]
    myControl = new FormControl(null, Validators.required);
    filteredOptions?: Observable<Donor[]>;
    dropped?: string;
    added?: string;
    old?: MealEvent
    options = this.donorList
    dialogData: any
    centres?: Centre[]
    env = isDevMode() ? environment.testMode : environment.productionMode
    inputdata: any
    defaultCentre: Centre
    toSelect!: string

    constructor(private fbApi: FormBuilder,
                private datePipe: DatePipe,
                public dialogRef: MatDialogRef<EditMealEventComponent>,
                public _matSnackbar: MatSnackBar,
                private mealService: MealMateService,
                private  readonly mLogService:LogService,
                private readonly centreService: CentreService,
                private mDatabase: AngularFireDatabase,
                @Inject(MAT_DIALOG_DATA) data: any) {
        this.inputdata = data
        this.defaultCentre = {
          mealMate: false, unitSponsorship: false,
          centreName: this.inputdata.centreName,
            centreCode: this.inputdata.centreCode
        }
        console.log('EDIT', data)
        this.fb = fbApi
        this.dialogData = data
        this.centre = this.dialogData.centreCode

        // @ts-ignore
        this.mDatabase.object<MealEvent>(this.env.mealmatesEvents + this.dialogData.eventId).valueChanges().subscribe(res => {
            this.date = res?.start!
            this.old = res!
            console.log('RES', res)

            this.checkAvailableSlots()
        })
    }

    ngOnInit(): void {
        console.log(this.inputdata)
        //  this.fetchDonorsList()
        // alert(new Date(this.old!.start))
        this.mealForm = this.fb.group({
            //MEAL FORM INSTANCE
            // /** childCode for latest child counter to genereate child code from database. */
            mealSlot: new FormControl(this.old?.mealSlot, [Validators.required]),
            // quantity: new FormControl(null, [Validators.required]),
            newDate: new FormControl('', Validators.required),
            centreSelect: new FormControl('', Validators.required),

        })
        this.fetchCentres()

    }

    private fetchCentres() {
        this.centreService.getAllCentres().pipe().subscribe(centres => {
            this.centres = centres
            let c = {centreCode: 'All', centreName: "All Centres", totalUnits: 99, key$: 'All'} as unknown as Centre
            this.centres.push(c)
            this.centres.push({
              mealMate: false,
              unitSponsorship: false,
              centreCode: 'GurunanakB', centreName: 'Gurunanak B', totalUnits: 99, $key: 'GurunanakB'})
            this.toSelect = this.centres?.find(c => c.centreCode == this.inputdata.centreCode)?.centreCode!;
            console.log('TOSELECT', this.toSelect)
            // this.mealForm.get('centreSelect')?.setValue(this.toSelect)

        })
    }

    changeMealDate() {
        // console.log('onsubmit', this.mealForm.get('donated_by')?.value)
        this.disabled = true
        console.log('INTO Change Meal Date')
        // this.mSlots!.totalSlots = this.centre.occupiedUnits!
        if (this.mealForm.valid) {
            this.disabled = true
            // EditEvent(newDate: string,oldDate:string, centreCode: string, mealSlot: string,eventId :string, dialogRef: MatDialogRef<EditMealEventComponent>) {
            alert((this.selectCentre!.value))
            let newCenre = (this.selectCentre!.value)
            this.mealService.EditEvent(this.date!, this.old!.start, this.old!.centreCode!, newCenre, this.mealForm.get('mealSlot')!.value, this.dialogData.eventId!, this.dialogRef).then(() => {
                    let activity={
                        date:new Date().getTime(),
                        section: 'Mealmate',
                        action: 'Update',

                        description: `Meal updated by`,
                        currentIp: localStorage.getItem('currentip')!
                    }
                    this.mLogService.addLog(activity)
                this._matSnackbar.open('Date Changed Successfully', '')._dismissAfter(3000)
                this.dialogRef.close()
            })

        } else {
            console.log(this.findInvalidControls())
            this._matSnackbar.open('Please fill out the form properly', '', {duration: 3000})
            this.disabled = false
        }

        // console.log("MEAL FORM: ", this.mealForm.value)
    }


    get mealSlot() {
        return this.mealForm.get('mealSlot')
    }

    get selectCentre() {
        return this.mealForm.get('centreSelect')
    }

    // get mealFor() {
    //   return this.mealForm.get('mealFor')
    // }

    get donorName() {
        return this.mealForm.get('donated_by')
    }

    get eventName() {
        return this.mealForm.get('eventName')
    }

    //
    // get quantity() {
    //   return this.mealForm.get('quantity')
    // }

    //
    // get onlyChildInDinner() {
    //   return this.mealForm.get('onlyChildInDinner')
    // }
    //
    get donorNumber() {
        return this.mealForm.get('mobile')
    }


    selectionChange() {
        this.disabled = false
        const mealEvent = this.mealForm.value as MealEvent
        // console.log(mealEvent)
        // console.log('', ` ${this.mealSlot!.value} ${this.mealFor?.value}`)
        // @ts-ignore
        switch (mealEvent.mealSlot) {
            case "Lunch for child": {
                this.totalAmount = 1000

                console.log('INTO LUNCH FOR CHILD')
                mealEvent.id = this.date + 'L-C'
                mealEvent.title = ` ${this.eventName?.value}`
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = 'black'
                mealEvent.backgroundColor = '#fbc02d'
                mealEvent.allday = true
                mealEvent.email = '' + this.mealForm.get('email')?.value
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                break;
            }
            case "Lunch for family": {
                this.totalAmount = 4000

                console.log('INTO LUNCH')
                mealEvent.id = this.date + 'L-F'
                mealEvent.title = `${this.eventName?.value}`
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = 'black'
                mealEvent.backgroundColor = '#fbc02d'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                break;
            }

            case "Dinner for child": {
                console.log('INTO DINNER FOR CHILD')
                this.totalAmount = 1000;
                // mealEvent.id= this.date + 'L',
                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#fff'
                mealEvent.backgroundColor = '#00796b'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                // allDay: selectInfo.allDay

                break;
            }
            case "Dinner for family": {
                console.log('INTO DINNER')
                this.totalAmount = 4000

                // mealEvent.id= this.date + 'L',
                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#fff'
                mealEvent.backgroundColor = '#00796b'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                // allDay: selectInfo.allDay

                break;
            }
            case "Lunch and dinner for child": {
                console.log('INTO LUNCH AND DINNER')
                this.totalAmount = 2000

                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#ddd'
                mealEvent.backgroundColor = '#1976d2'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                // allDay: selectInfo.allDay
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)
                break;
            }
            case "Lunch and dinner for family": {
                console.log('INTO LUNCH AND DINNER')
                this.totalAmount = 8000

                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#ddd'
                mealEvent.backgroundColor = '#1976d2'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                // allDay: selectInfo.allDay
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)
                break;
            }

        }
        this.mealForm.addControl('totalAmount', new FormControl(this.totalAmount))
        this.event = mealEvent
    }

    /**
     * makes the field required if the predicate function returns true
     */
    /**
     * makes the field required if the predicate function returns true
     */


//in ngOnInit:


    selectedPayment($event: MatSelectChange) {
        this.selectedPaymentMethod = $event.value

    }

    private fetchDonorsList() {
        this.mealService.fetchDonors().subscribe(
            res => {
                this.donorList = res as Donor[]
                // console.log(this.donorList)
                this.options = res as Donor[]
                this.filteredOptions = this.myControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value!))
                    );
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(donor => (donor ? this._filter(donor) : this.donorList.slice())),
                );
            }
        )
    }

    // private _filter(value: string): Donor[] {
    //   const filterValue = value.toLowerCase();
    //   // this.options  = this.donorList
    //   console.log('OPTIONS',this.donorList)
    //   return (this.options.filter(option => option.donorName.toLowerCase().includes(filterValue)));
    // }
    private _filter(value: string): Donor[] {
        const filterValue = value.toLowerCase();
        return this.donorList.filter(state => state.donorName.toLowerCase().includes(filterValue));
    }

    // add(el:string){
    //   this.added = el;
    //   this.myControl.setValue(null);
    // }
    //
    // drop(el:string){
    //   this.dropped = el;
    //   this.myControl.setValue(null);
    // }
    displayFn(country: Donor[]): string {
        return 'hehe';
    }

    optionSelect($event: Donor) {
        console.log('SELECTED', $event)
        this.mealForm.get('mobile')?.setValue($event.donorMobile)
        this.mealForm.get('pan')?.setValue($event.pan)
        this.mealForm.get('email')?.setValue($event.email)
        this.mealForm.get('city')?.setValue($event.donorcity)
        this.mealForm.get('address')?.setValue($event.donoraddress)
        this.mealForm.get('state')?.setValue($event.state)
        this.mealForm.get('zipcode')?.setValue($event.pincode)
// this.mealForm.get('zipcode')?.setValue($event.zipcode)


    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.mealForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    checkAvailableSlots($event?: MatDatepickerInputEvent<any>) {
        console.log($event?.value)
        this.mealForm.get('mealSlot')?.setValue(null)
        let newDate = this.date
        console.log('OLDDATE', newDate)
        if ($event?.value) {
            let newDate = this.datePipe.transform($event?.value, 'yyyy-MM-dd');
            console.log('todays_date_str', newDate)
            // console.log('NEWDATE',newDate)
            this.date = newDate!
        }
        this.AvailableSlots$ = this.mDatabase.object<EventLogic>(`${this.env.mealmatesCounter}${this.centre}/${this.date}`)
        this.AvailableSlots$.valueChanges().subscribe((res: EventLogic | null | undefined) => {
            console.log('EDIT EVENTLOGIC', res)
            if (res != null || res != undefined) {
                console.log('res', res)
                this.mSlots = res as EventLogic
            } else if (res == null) {
                console.log('into null logic for mslots')
                this.mSlots = new EventLogic()
                console.log('EDIT EVENTLOGIC', this.mSlots)

            }
        })
        // console.log('mSlots',this.mSlots)
    }

    compareCategoryObjects(object1: Centre, object2: Centre) {
        return object1 && object2 && object1.centreCode === object2.centreCode;
    }

  closeDialog() {
    this.dialogRef.close()
  }
}
