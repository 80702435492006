import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Guardian, Guardians} from "../Guardians";
import {MatTableDataSource} from "@angular/material/table";
import {map} from "rxjs/operators";

// const ELEMENT_DATA: Guardians[] = [
//   {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
//   {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
//   {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
//   {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
//   {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
//   {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
//   {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
//   {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
//   {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
//   {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
// ];
@Component({
  selector: 'app-list-caretaker',
  templateUrl: './list-caretaker.component.html',
  styleUrls: ['./list-caretaker.component.scss']
})
export class ListCaretakerComponent implements OnInit {
  guardiansData:Guardians[]=[]
  guardianmap  =new Map<String,Guardians[]>()
  columns = [

    {
      columnDef: 'firstname',
      header: 'First Name',
      cell: (element: Guardians) => `${element.key}`,
    },
    {
      columnDef: 'lastname',
      header: 'Last Name',
      cell: (element: Guardians) => `${element.key}`,
    },
    {
      columnDef: 'occupation',
      header: 'Occupation',
      cell: (element: Guardians) => `${element.key }`,
    },
    {
      columnDef: 'skills',
      header: 'Skills',
      cell: (element: Guardians) => `${element.key}`,
    },
  ];
  dataSource =new  MatTableDataSource(this.guardiansData);
  displayedColumns = this.columns.map(c => c.columnDef);
  constructor(private mDatabase:AngularFireDatabase) {
    const ref =  mDatabase.list('dummy/guardians').valueChanges().pipe(
      map(x=>{
        console.log(x)
      })
    )
      .subscribe((snaps:any)=>{
      // this.guardiansData=[]
      // snaps.forEach(snap=>{
      //   console.assessment-table(snap)

      // })
      this.dataSource =new  MatTableDataSource(this.guardiansData);

    })

  }

  ngOnInit(): void {
  }

}
