  <div>
<div class="m-3 d-flex justify-content-between">
<div>
  <mat-form-field appearance="outline">
    <mat-label>Select Status</mat-label>
    <mat-select  [(ngModel)]="selectedStatus">
      <mat-option value="Staying">Staying </mat-option>
      <mat-option value="Discharged">Discharged </mat-option>
      <mat-option value="OM SHANTI">OM SHANTI </mat-option>
      <mat-option value="All">All </mat-option>
<!--      <mat-option value="All">All </mat-option>-->
      <!--      <mat-option  value="All">All Centres</mat-option>-->
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="outline" class="ms-5" >
    <mat-label>Select Centre</mat-label>
    <mat-select (selectionChange)="setSelectedCentreCode($event)">
      <mat-option *ngFor=" let c of (centres$|async) " [value]="c.centreCode">{{c.centreName}} </mat-option>
<!--      <mat-option  value="All">All Centres</mat-option>-->
    </mat-select>
  </mat-form-field>
  <button  class="ms-5" mat-mini-fab color="accent" (click)="getData(selectedCentreCode)"><mat-icon>search</mat-icon></button>
<!--<button mat-raised-button  class="m-1" (click)="exportexcel()" color="primary">  <mat-icon> cloud_download</mat-icon> &nbsp; Download Table </button>-->

</div>
</div>
    <div class="ngxTableHeader">
      <ul class="header-buttons-left ms-0 mb-0">
        <li class="dropdown">
<!--          <h2>Child List</h2>-->
        </li>
        <li class="dropdown m-l-20">
          <label for="search-input"><i class="material-icons search-icon">search</i></label>
          <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
                 (keyup)='applyFilter($event)' aria-label="Search box" #input>
        </li>
      </ul>
      <ul class="header-buttons mr-2">
        <!--      <li>-->
        <!--        <button   mat-mini-fab color="primary" (click)="openAddCarLog()">-->
        <!--          <mat-icon class="col-white" >add</mat-icon>-->
        <!--        </button>-->
        <!--      </li>-->
<!--        <li>-->
<!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
<!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
<!--          </div>-->
<!--        </li>-->
      </ul>
    </div>

<section class="example-container card " tabindex="1">
  <table mat-table [dataSource]="dataSource"  class="child-table "   matSort matSortDisableClear matSortDirection="desc" (matSortChange)="sortData($event)">
    <!--  POSITION Column-->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>No.</th>
      <td mat-cell *matCellDef="let i = index;let element" [ngClass]="element.admissionData?.length>2?'followUp':'new'" >
        {{this.paginator1.pageIndex == 0 ? i + 1 : 1 + i + this .paginator1.pageIndex * this.paginator1.pageSize}}
      </td>
    </ng-container>
    <!-- Center Column -->
    <!--      <ng-container matColumnDef="centercode">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header> Center Code </th>-->
    <!--        <td mat-cell *matCellDef="let element"> {{element.centreCode  }} </td>-->
    <!--      </ng-container>-->

    <!--      &lt;!&ndash; Name Column &ndash;&gt;-->
    <!--      <ng-container matColumnDef="childcode">-->
    <!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Child Code </th>-->
    <!--        <td mat-cell *matCellDef="let element"> {{element.childCode}} </td>-->
    <!--      </ng-container>-->
    <!--      Child Name Column-->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.id" [ngClass]="element.admissionData?.length>2?'followUp':'new'" > {{element.firstName}}  </td>
    </ng-container>

    <!--      Last Name Column-->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.admissionData?.length>2?'followUp':'new'" > {{element.lastName  }} </td>
    </ng-container>

    <!-- City Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> City</th>
      <td mat-cell *matCellDef="let element" [ngClass]=" element.admissionData?.length>2?'followUp':'new'" > {{element.city}} </td>
    </ng-container>
    <!-- City Column -->
    <ng-container matColumnDef="centreName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.admissionData?.length>2?'followUp':'new'" > {{element.centreName}} </td>
    </ng-container>
    <!--      Last Name Column-->
    <ng-container matColumnDef="selectUnit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Code</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.admissionData?.length>2?'followUp':'new'" > {{element.selectUnit  }} </td>
    </ng-container>
    <ng-container matColumnDef="dateOfAdmission">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> DoA</th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.admissionData?.length>2?'followUp':'new'"  > {{getDate(element.dateOfAdmission)|date:'dd/MM/yyyy'}}</td>
    </ng-container>
    <ng-container matColumnDef="isFollowUp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.admissionData?.length>2?'followUp':'new' "  >
<!--        {{element.type}}-->
        <span *ngIf="element.admissionData.length>2">Follow Up</span>
        <span *ngIf="element.admissionData.length<=2">New</span>
      </td>
    </ng-container>
    <!--      Status Column-->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.selectUnit" [ngClass]="element.admissionData?.length>2?'followUp':'new'" >
        <ng-container *ngIf="element.isAlloted==true"> Alloted</ng-container>
        <ng-container *ngIf="element.isAlloted==false">Discharged</ng-container>
      </td>
      <!--      <td mat-cell *matCellDef="let element"> {{element.isAlloted  }} </td>-->
    </ng-container>


    <!-- Symbol Column -->
    <!--    <ng-container matColumnDef="symbol">-->
    <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
    <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
    <!--    </ng-container>-->

    <!--     Action-->
    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef> Action</th>
      <td mat-cell *matCellDef="let element;let i = index" [ngClass]="element.admissionData?.length>2?'followUp':'new'" >
        <!--                  <button mat-icon-button>-->
        <!--                    <mat-icon>archive</mat-icon>Archive-->
        <!---->
        <!--                  </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
        <button  *ngIf="registraionPermissions[0].view || registraionPermissions[0].all" color="accent" mat-icon-button (click)="datashow(element)">
          <mat-icon>remove_red_eye</mat-icon>
        </button>&nbsp;
        <!*--------------------------------------------- Add User Role Logic--------------------------------------------->
        <button  *ngIf="registraionPermissions[0].edit || registraionPermissions[0].all"  color="primary" mat-icon-button (click)="gotoToEdit(element)">
          <mat-icon>assignment</mat-icon>
        </button>&nbsp;
        <button  *ngIf="username=='test1@gmail.com'|| username=='genmgr@accesslife.org' "  color="primary" mat-icon-button (click)="gotoToAdminEdit(element)">
          <mat-icon>supervisor_account</mat-icon>
        </button>&nbsp;

        <button color="primary"  mat-icon-button   (click)="openChildJourney(element)" matTooltip="view child details" ><mat-icon>account_box</mat-icon></button>&nbsp;


        <!--          <button   mat-icon-button >-->
        <!--            <mat-icon>remove_red_eye</mat-icon>-->
        <!--          </button>View-->

        <!--          <button  mat-icon-button >-->
        <!--            <mat-icon >assignment</mat-icon>-->
        <!--          </button>Allotment-->

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow class="mat-row">
      <!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
    </tr>


  </table>

</section>
    <mat-paginator class="card" *ngIf="dataSource.data" [pageSizeOptions]="[25,50,100,200,250,500,1000]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
