<div class="container p-3">
  <span style="float:right">
 </span>
    <div class="row">
      <div class="col-md-1 p-0 m-0">
      <button  mat-mini-fab color="accent" class="mat-small " matTooltip="Back" routerLink="/home/meals"><mat-icon>arrow_back</mat-icon></button>
      </div>
        <mat-form-field appearance="outline" class="col-md-3 ">
            <mat-label>Select Centre</mat-label>
            <mat-select  placeholder="" [(ngModel)]="selectedCentre">
                <mat-option *ngFor="let c of centres" [value]="c.centreCode"
                            [disabled]="notManipalOrPune(c)">{{c.centreName}}</mat-option>
                <!--            <mat-option  value="All">All Centres</mat-option>-->
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Select Meal</mat-label>
            <mat-select aria-label="Default select example" [(ngModel)]="mealSlotSelected">
                <mat-option value="Lunch for child">Lunch for child</mat-option>
                <mat-option value="Dinner for child">Dinner for child</mat-option>
                <mat-option value="Lunch for family">Lunch for family</mat-option>
                <mat-option value="Dinner for family">Dinner for family</mat-option>
            </mat-select>
        </mat-form-field>


        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="rangePicker">
                <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                <input matEndDate placeholder="End date" [(ngModel)]="endDate">
            </mat-date-range-input>
            <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
                <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                </mat-date-range-picker-actions>
            </mat-date-range-picker>
        </mat-form-field>
        <button mat-mini-fab color="accent" matTooltip="Search" (click)="Search()">
            <mat-icon>search</mat-icon>
        </button>

    </div>

    <section>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Position Column -->
            <!--            <ng-container matColumnDef="position">-->
            <!--                <th mat-header-cell *matHeaderCellDef>Sr.No</th>-->
            <!--                <td mat-cell *matCellDef="let element"></td>-->
            <!--            </ng-container>-->

            <!-- Name Column -->
            <ng-container matColumnDef="donated_by">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Donor Name</th>
                <td mat-cell *matCellDef="let element">{{element.donated_by}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="city">
                <th mat-header-cell *matHeaderCellDef>City</th>
                <td mat-cell *matCellDef="let element"> {{element.city}}</td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="Address">
                <th mat-header-cell *matHeaderCellDef>Address</th>
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <ng-container matColumnDef="pan">
                <th mat-header-cell *matHeaderCellDef>Pan No.</th>
                <td mat-cell *matCellDef="let element">{{element.pan}}</td>
            </ng-container>

            <ng-container matColumnDef="mealSlot">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Meal Slot</th>
                <td mat-cell *matCellDef="let element">{{element.mealSlot}}</td>
            </ng-container>

            <ng-container matColumnDef="eventName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Event Name</th>
                <td mat-cell *matCellDef="let element">{{element.eventName}}</td>
            </ng-container>

            <ng-container matColumnDef="event_message">
                <th mat-header-cell *matHeaderCellDef>Event Message</th>
                <td mat-cell *matCellDef="let element">{{element.eventMessage}}</td>
            </ng-container>

            <ng-container matColumnDef="centreCode">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Centre Code</th>
                <td mat-cell *matCellDef="let element">{{element.centreCode}}</td>
            </ng-container>

            <ng-container matColumnDef="start">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking Date</th>
                <td mat-cell *matCellDef="let element">{{element.start}}</td>
            </ng-container>
            <ng-container matColumnDef="payment_date">
                <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                <td mat-cell *matCellDef="let element">{{element.start}}</td>
            </ng-container>
            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>State</th>
                <td mat-cell *matCellDef="let element">{{element.state}}</td>
            </ng-container>
            <ng-container matColumnDef="booking_id">
                <th mat-header-cell *matHeaderCellDef>Booking ID</th>
                <td mat-cell *matCellDef="let element">{{element.order_id}}</td>
            </ng-container>
            <ng-container matColumnDef="otg_certificate">
                <th mat-header-cell *matHeaderCellDef>Need 80G</th>
                <td mat-cell *matCellDef="let element">{{element.need80g ? 'Yes' : 'No'}}</td>
            </ng-container>
            <ng-container matColumnDef="recieptNo">
                <th mat-header-cell *matHeaderCellDef>Reciept No</th>
                <td mat-cell *matCellDef="let element">{{element.receipt_id}}</td>
            </ng-container>
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                <td mat-cell *matCellDef="let element">
<!--                                        <button mat-icon-button color="accent" (click)="viewDialog(element)" matTooltip="View"><mat-icon>remove_red_eye</mat-icon></button>-->
                 <div class="d-flex">
                   <button *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].edit " mat-icon-button color="primary" (click)="viewEdit(element)" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
                   <button  mat-icon-button color="warn" *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].archive " (click)="delete(element)" matTooltip="Delete"><mat-icon>delete</mat-icon></button>
                   <button mat-icon-button *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].view " color="accent" (click)="showEmailDialog(element)" matTooltip="Send Email"><mat-icon>email</mat-icon></button>

                 </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>


    </section>
</div>
