import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/compat/firestore";
import {CounselingService} from "../../modules/counseling/counseling.service";
import {forkJoin, Observable} from "rxjs";
import {Centre} from "../../interfaces/Centres";
import {CentreService} from "../../services/centre.service";
import {MatSelectChange} from "@angular/material/select";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import firebase from "firebase/compat";
import {map} from "rxjs/operators";
import {GalleryComponent} from "../../modules/cms/website/gallery/gallery.component";

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  _centres$: Observable<Centre[]>
  dataSource: any;
  path = `ALDocuments/${this.data.type}/individualSessions`
  baseRef: AngularFirestoreCollection<any>;
  newPath = ""
  idArray: string[] = [];
  selectedImages: string[] = [];
  dateParameter=''
  constructor(private mFirestore: AngularFirestore,
              private readonly mSnackBar: MatSnackBar,
              private mCounselingService: CounselingService,
              private  readonly matDialogRef:MatDialogRef<PhotoGalleryComponent>,
              private centreService: CentreService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this._centres$ = this.centreService.getAllCentres()
    this.mSnackBar.open(JSON.stringify(this.data), '', {
      duration: 3000
    })
this.newPath = this.data.path
    this.baseRef = this.mFirestore.collection<any>(this.newPath);
if(data.type=='Education')this.dateParameter ='sessionDate'
if(data.type=='Counselling')this.dateParameter ='date'

  }

  ngOnInit(): void {


    this.dynamicQueryForData(this.data)


    // this.mFirestore
    //   .collection(this.path)
    //   .valueChanges()
    //   .subscribe((res: any[]) => {
    //     this.dataSource = res.flatMap(x => x.documents);
    //     console.log(this.dataSource);
    //   });
  }

  dynamicQueryForData(data: { type: string, startDate: any, endDate: any, centreCode: any }) {
    let myQuery: firebase.firestore.Query<any> = this.baseRef.ref;

    if (data.startDate) {
      myQuery = myQuery.where(this.dateParameter, '>=', data.startDate);
    }

    if (data.endDate) {
      myQuery = myQuery.where(this.dateParameter, '<=', data.endDate);
    }

    if (data.centreCode) {
      myQuery = myQuery.where('centreCode', '==', data.centreCode);
    }

    // Add other conditions as needed

    // this.fetchData(myQuery);
    myQuery.get().then(res => {
      res.docs.map(res => {
        // console.log(res.id)
        this.idArray.push(res.id)


      })
      console.log('idArray.length',this.idArray.length)
      this.fetchDataFromFirestore(this.idArray).subscribe(res=>{
        console.log("here", res)
        // this.dataSource = res.map(item =>
        // console.log(item.documents ,"here3")
        //
        // )
        // console.log(this.dataSource, "here2")

        const documentsArray = res
          .filter(item => item && item.documents)
          .map(item => item.documents);
        this.dataSource = documentsArray

// Output the result
        console.log("here2",documentsArray);
      })


    })

  }


  fetchData(query: any) {
    this.mFirestore
      .collection(this.newPath, ref => query)
      .valueChanges()
      .subscribe((res: any) => {
        this.dataSource = res.flatMap((x: { documents: any; }) => x.documents);
        console.log(this.dataSource);
      });


  }

onClose(){
    this.matDialogRef.close(this.selectedImages)
}
  fetchDataFromFirestore(documentIds: string[]): Observable<any[]> {
    const observables: Observable<any>[] = [];
    // console.log('into fetchDataFromFirestore Method')
    // Create an observable for each document ID
    documentIds.forEach((docId) => {
      const docRef = this.mFirestore.collection(this.path).doc(docId).get().toPromise()
      // Convert the promise-based Firestore get() to an observable
      const observable = new Observable<any>((observer) => {
        docRef
          .then((snapshot: any) => {
            if (snapshot) {
              observer.next(snapshot.data());
              // console.log('Snaphost Exits')
            } else {
              observer.next(null); // Document doesn't exist
            }
            observer.complete();
          })
          .catch((error) => {
            observer.error(error);
          });
      });

      observables.push(observable);
// alert(observables.length)
    });

    // Use forkJoin to combine all observables into one
    return forkJoin(observables).pipe(
      map((results: any[]) => results.filter((data) => data !== null))
    );
  }



  fetchDocuments() {
    const documentsObservables = this.idArray.map(id => {
      this.mFirestore.collection(this.path).doc(id).valueChanges()
    })
    return documentsObservables
  }


  selectImage(url: string): void {
    if (!this.selectedImages.includes(url)) {
      this.selectedImages.push(url);
    } else {
      this.selectedImages = this.selectedImages.filter(item => item !== url);
    }

    console.log(this.selectedImages);
  }
  isSelected(url: string): boolean {
    return this.selectedImages.includes(url);
  }
}
