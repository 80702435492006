import {Component, Inject, Input, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {EMPTY, Observable, Subject, takeUntil} from "rxjs";
import {catchError} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../auth.service";
import {UtilService} from "../../services/util.service";
import {FileStorageService} from "../../services/file-storage.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DialogRef} from "@angular/cdk/dialog";
import {environment} from "../../../environments/environment";
import {LogService} from "../../services/log.service";

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.scss']
})

export class FileUploadDialogComponent implements OnInit, OnDestroy {
  @Input() title = 'Title'
  @Input() subtitle = 'Subtitle'
  destroy$: Subject<null> = new Subject();
  fileToUpload!: File;
  submitted = false;
  uploadProgress$?: Observable<number | undefined>;
  kittyImagePreview!: string | ArrayBuffer;
  pictureForm!: FormGroup;
  user?: firebase.User | null;
  env = isDevMode() ? environment.testMode : environment.productionMode
  MEDIA_STORAGE_PATH = 'Documents'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly matDialogRef:DialogRef<FileUploadDialogComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private readonly utilService: UtilService,
    private readonly storageService: FileStorageService,
    private readonly snackBar: MatSnackBar,
    private  readonly  mLogService:LogService,
    private readonly mFirestore: AngularFirestore
  ) {
  }

  ngOnInit(): void {
    console.log(this.data)
    this.pictureForm = this.formBuilder.group({
      photo: [null, [Validators.required, this.image.bind(this)]],
      description: [null, Validators.required],
    });

    this.pictureForm.get('photo')?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((newValue) => {
        this.handleFileChange(newValue.files);
        console.log(newValue.files)
      });
    this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => (this.user = user));
    console.log(this.user)

  }

  // @ts-ignore
  handleFileChange([kittyImage]) {
    this.fileToUpload = kittyImage;
    const reader = new FileReader();
    reader.onload = (loadEvent) => (this.kittyImagePreview = loadEvent.target?.result!);
    reader.readAsDataURL(kittyImage);
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.authService.user$?.pipe(takeUntil(this.destroy$))
      .subscribe((user) => (this.user = user));
  }


  private image(photoControl: AbstractControl): { [key: string]: boolean } | null {
    if (photoControl.value) {
      const [kittyImage] = photoControl.value.files;
      return this.utilService.validateFile(kittyImage)
        ? null
        : {
          image: true,
        };
    }
    // @ts-ignore
    return;
  }

  postKitty() {
    //? Image size Validation 4mb in (bytes)
  if(this.fileToUpload.size<4000000)  {
      this.submitted = true;

      this.MEDIA_STORAGE_PATH = `Documents/Child/${this.data.childCode}`
      const mediaFolderPath = `${this.MEDIA_STORAGE_PATH}/${this.data.type}/`;

      const {downloadUrl$, uploadProgress$} = this.storageService.uploadFileAndGetMetadata(
        mediaFolderPath,
        this.fileToUpload,
      );

      this.uploadProgress$ = uploadProgress$;

      downloadUrl$
        .pipe(
          takeUntil(this.destroy$),
          catchError((error) => {
            this.snackBar.open(`${error.message} `, 'Close', {
              duration: 4000,
            });
            return EMPTY;
          }),
        )
        .subscribe((downloadUrl) => {
          this.submitted = false;
          console.log('DownloadUrl', downloadUrl)
          const uploadData = {
            'fileUrl': downloadUrl,
            'description': this.pictureForm.get('description')?.value,
            'uploadedAt': new Date()

          }
          this.mFirestore.collection(this.env.child).doc(this.data.childCode).update({
            [this.data.type]: firebase.firestore.FieldValue.arrayUnion(uploadData)
          }).then(() => {
            this.snackBar.open('File Uploaded', '')._dismissAfter(3000)
            let activity={
              date:new Date().getTime(),
              section: 'Admission',
              action: 'Upload',
              description: `Document Uploaded into ${this.data.childCode}  by  `,
              currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
            this.matDialogRef.close()
          })
          // this.router.navigate([`/${FEED}`]);
        });
    }
  else {
    this.snackBar.open('Maximum File size allowed is 4mb','ERROR',{duration:3000})
  }
  }
}
