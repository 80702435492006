import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {ChartConfiguration, ChartOptions, ChartType} from "chart.js";
import {count} from "rxjs";

@Component({
    selector: 'app-hospitals-bar-graph',
    templateUrl: './hospitals-bar-graph.component.html',
    styleUrls: ['./hospitals-bar-graph.component.css']
})
export class HospitalsBarGraphComponent implements OnInit, AfterViewInit {
    @Input() mLabels!: string[]
  dataLoaded = false;
    @Input() mCount!: any[]
    @Input() datmodel!: any[]
    public barChartOptions: ChartOptions = {
        responsive: true,
        indexAxis: 'x',
        backgroundColor: '#fbc02d'
    };
    // public barChartLabels = this.mLabels
    public barChartLabels = ['Kasturba', 'TMH', 'LTMGH', 'Wadia', 'KEM', 'Nair', 'CTC', 'KDAHRI', 'SRCC', 'KJSHRC', 'Bharati', 'DMHRC', 'Sassoon', 'PGIMER'];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [];

    public barChartData: any[] = [
        {data: [28, 48, 40, 19, 86, 27, 90], label: 'By Age Group'},


    ];

    // public barChartData: ({ data: number[]; label: string } | { data: number[]; label: string })[] = [
    //   { data: [28, 48, 40, 19, 86, 27, 90], label: 'By Age Group' },
    //   // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
    // ];
    constructor() {
// this.barChartData.push({this.mc})
//*        console.log('barChartData', this.barChartData)
//*        console.log('lables', this.mLabels)
    }

    ngAfterViewInit(): void {
        // this.mLabels =this.barChartLabels
        // this.mCount =this.barChartData
        // this.mCount?.forEach((it,i)=>{
        //     console.log('into for each')
        //             this.barChartData.push({data: this.mCount[i], label: this.mLabels[i]})
        //             console.log('itc',{data: this.mCount[i], label: this.mLabels[i]})
        //
        //     }
        // )
        //*     console.log('LABEL', this.mLabels)
        //*     console.log('COUNTS', this.mCount)
        //*     console.log('bchat', this.barChartData)

    }

    ngOnInit(): void {
       // console.log('MCUOUNT=>', this.mCount)
       // console.log('MLABELS=>', this.mLabels)
        this.barChartLabels = this.mLabels
        this.barChartData = [
            // {data: [28, 48, 40, 19, 86, 27, 90], label: 'Static'},
            {data: this.mCount, label: 'By Hospital Count'},


            // {data: this.mCount, label: 'Series A'},
            // {data: [this.mCount[0]], label: 'Kasturba'}
        ];
// this.datmodel.forEach(it=>{
//     this.barChartData.push(it)
// })
    }
}
