<div class="container">
<!--  <div class="col-lg-12 col-md-12 table">-->
<!--    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>-->
<!--      <ng-container matColumnDef="yearRange">-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Year Range</th>-->
<!--        <td mat-cell *matCellDef="let element"></td>-->
<!--      </ng-container>-->
<!--      <ng-container matColumnDef="newAdmission">-->
<!--        <th mat-header-cell *matHeaderCellDef  mat-sort-header>New Admission</th>-->
<!--        <td mat-cell *matCellDef="let element"></td>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="followUp" >-->
<!--        <th mat-header-cell *matHeaderCellDef mat-sort-header>Follow-UP</th>-->
<!--        <td mat-cell *matCellDef="let element"></td>-->
<!--      </ng-container>-->


<!--           <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>-->

<!--    </table>-->
<!--    <mat-paginator [pageSizeOptions]="[  20,50,100,200]"-->
<!--                   showFirstLastButtons-->
<!--                   aria-label="Select page of periodic elements">-->
<!--    </mat-paginator>-->
<!--  </div>-->


  <!-- admission-counts.component.html -->

  <!-- admission-table.component.html -->
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Yearly Counter Report</h2>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <!--    <li>-->
      <!--      <button   mat-mini-fab color="primary" *ngIf="unitSponsorship[0].all || unitSponsorship[0].add" class="ms-3 mt-3" (click)="AddUnitSponsorship()" >-->
      <!--        <mat-icon class="col-white" >add</mat-icon>-->
      <!--      </button>-->
      <!--    </li>-->
              <li>
                <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">
                  <img src="assets/images/icons/xlsx.png" alt="" />
                </div>
              </li>
    </ul>
  </div>

  <table class="table border-1 table-bordered"  id="excel-table">
    <thead>
    <tr>
      <th>Year Range</th>
      <th>New Admission</th>
      <th>Follow-Up</th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>June-2014 to March-2015</td>
      <td>13</td>
      <td>0</td>
    </tr>
    <tr>
      <td>April-2015 to March-2016</td>
      <td>11</td>
      <td>0</td>
    </tr>
    <tr>
      <td>April-2016 to March-2017</td>
      <td>58</td>
      <td>0</td>
    </tr>
    <tr>
      <td>April-2017 to March-2018</td>
      <td>100</td>
      <td>0</td>
    </tr>
    <tr>
      <td>April-2018 to March-2019</td>
      <td>156</td>
      <td>0</td>
    </tr>
    <tr>
      <td>April-2019 to March-2020</td>
      <td>145</td>
      <td>56</td>
    </tr>
    <tr>
      <td>April-2020 to March-2021</td>
      <td>87</td>
      <td>44</td>
    </tr>
    <tr>
      <td>April-2021 to March-2022</td>
      <td>121</td>
      <td>161</td>
    </tr>
    <tr>
      <td>April-2022 to March-2023</td>
      <td>169</td>
      <td>277</td>
    </tr>
    <tr>
      <td>April-2023 to March-2024</td>
      <td>236</td>
      <td>347</td>
    </tr>

    <tr>
      <th>Total</th>
      <th>1096</th>
      <th>885</th>
    </tr>

    </tbody>
  </table>


</div>
