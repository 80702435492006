<div class="container">
<div class="d-flex justify-content-between">
  <mat-form-field appearance="outline" class="col-md-4 mt-1">
    <mat-label>Select Centre</mat-label>
    <mat-select  [(ngModel)]="selectedCentre" (selectionChange)="onCentreSelect($event)">
      <mat-option *ngFor=" let c of (centres$|async) "
                  [value]="c.centreCode">{{c.centreName}} </mat-option>
      <mat-option value="All">All Centres </mat-option>
      <!--      <mat-option  value="All">All Centres</mat-option>-->
    </mat-select>
  </mat-form-field>

</div>

<!--<mat-form-field appearance="outline" class="ms-2">-->
<!--  <mat-label>Search</mat-label>-->
<!--  <input matInput (keyup)="Search($event)" placeholder="Ex. ium" #input>-->
<!--</mat-form-field>-->

<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Sponsored Children</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='Search($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <!--      <li>-->
      <!--        <button   mat-mini-fab color="primary" *ngIf="unitSponsorship[0].all || unitSponsorship[0].add" class="ms-3 mt-3" (click)="AddUnitSponsorship()" >-->
      <!--          <mat-icon class="col-white" >add</mat-icon>-->
      <!--        </button>-->
      <!--      </li>-->
      <!--        <li>-->
      <!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
      <!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
      <!--          </div>-->
      <!--        </li>-->
    </ul>
  </div>

  <table mat-table [dataSource]="dataSource" class="">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="SR. N0">
      <th mat-header-cell *matHeaderCellDef> SR. N0 </th>
      <td mat-cell *matCellDef="let element; let i = index;"> {{ i + 1 }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="Profile">
      <th mat-header-cell *matHeaderCellDef> Profile </th>
      <td mat-cell *matCellDef="let element"> {{element.profile}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="ChildName">
      <th mat-header-cell *matHeaderCellDef> Child Name </th>
      <td mat-cell *matCellDef="let element">  {{element.firstName}} {{element.lastName}}
        <br>
        <span *ngIf="element.oldChild" class="bg-warning" >{{element.oldChild.firstName}} {{element.oldChild.lastName}}</span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Unit Allowed">
      <th mat-header-cell *matHeaderCellDef> Unit Allowed </th>
      <td mat-cell *matCellDef="let element">  {{element.unit}}</td>
    </ng-container>


    <ng-container matColumnDef="Sponsorship Program">
      <th mat-header-cell *matHeaderCellDef> Sponsorship Program </th>
      <td mat-cell *matCellDef="let element">  {{element.duration}} </td>
    </ng-container>

    <ng-container matColumnDef="Donor Name">

      <th mat-header-cell *matHeaderCellDef>Donor Name</th>
      <td mat-cell *matCellDef="let element">  {{element.donorName}} </td>
    </ng-container>

    <ng-container matColumnDef="Mobile No.">
      <th mat-header-cell *matHeaderCellDef> Mobile No. </th>
      <td mat-cell *matCellDef="let element">  {{element.mobile}} </td>
    </ng-container>

    <ng-container matColumnDef="Payment Terms">
      <th mat-header-cell *matHeaderCellDef> Payment Terms </th>
      <td mat-cell *matCellDef="let element">  {{element.paymentInformation}} </td>
    </ng-container>
    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell  *matCellDef="let element"> <div class="d-flex">
        <button mat-raised-button [disabled]="element.oldChild"  class="mx-2" color="primary" (click)="transfer(element)">Transfer</button>
        <button mat-raised-button color="accent" (click)="renewal(element)">Renewal</button>
        <button mat-raised-button color="accent" (click)="openPoster(element)">Poster</button>
      </div> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>


</div>



