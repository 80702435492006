<table class="table table-bordered">
  <thead>
  <tr>
    <th>Field</th>
    <th>Initial Value</th>
    <th>Submitted Value</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let change of Object.keys(changesData.changes)">
    <td>{{ change }}</td>
    <td>{{ changesData.changes[change].initialValue }}</td>
    <td>{{ changesData.changes[change].submittedValue }}</td>
  </tr>
  </tbody>
</table>
