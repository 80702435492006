import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileUploadService} from "../upload.service";
import {FileUpload} from "../file-upload";
import * as path from "path";

@Component({
  selector: 'app-upload-file-detail',
  templateUrl: './upload-file-detail.component.html',
  styleUrls: ['./upload-file-detail.component.scss']
})
export class UploadFileDetailComponent {
  // @ts-ignore
  @Input() fileUpload: FileUpload;
  @Input() path!: string
  @Input ()storagePath?: string ='uploads/'

  @Input() dbType: 'realtime'|'firestore' = 'realtime'

  // @Output() preview:EventEmitter<boolean>= new EventEmitter();
  constructor(private uploadService: FileUploadService) {
  }

  ngOnInit(): void {
    console.log('uploadfiledetail',JSON.stringify(this.fileUpload))

  }

  // @ts-ignore
  deleteFileUpload(fileUpload): void {
  if(this.dbType=="realtime")  this.uploadService.deleteFile(fileUpload,this.path);
  else if(this.dbType=="firestore")  this.uploadService.deleteFileFirestore(fileUpload,this.storagePath!);
  //  this.preview.emit(false)
  }
}
