<mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
    (click)="toggledAction()">

      <mat-icon>menu</mat-icon>
    </button>
  <img alt="" src="assets/cropped-logo.png" class="img-fluid logo  "  >

<!--  <span>  &nbsp; &nbsp; Accesslife Dashboard</span>-->
    <span class="example-spacer"></span>
    <!-- <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
      <mat-icon>favorite</mat-icon>
    </button> -->

<!--    <button mat-stroked-button style="vertical-align: middle;" aria-label="Example icon-button with share icon" class ='logout' [matMenuTriggerFor]="menu">-->
<!--     <mat-icon>person_pin</mat-icon>Welcome&nbsp;{{username}}-->
<!--    </button>-->
<!--  <mat-menu #menu="matMenu">-->

<!--    <button (click)="logout()" mat-menu-item>Logout</button>-->
<!--  </mat-menu>-->

  <ul class="nav navbar-nav navbar-right">

  <li class="nav-item user_profile">
    <button mat-button [matMenuTriggerFor]="profilemenu">
      <div class="chip dropdown-toggle" ngbDropdownToggle class="">
        <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
      </div>
    </button>
    <mat-menu #profilemenu="matMenu" class="profile-menu">
      <div class="noti-list">
        <div class="menu ">
          <div class="user_dw_menu">
            <button mat-menu-item>
              <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
            </button>
            <button mat-menu-item>
              <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox
            </button>
            <button mat-menu-item>
              <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings
            </button>
            <button mat-menu-item (click)="logout()">
              <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
            </button>
          </div>
        </div>
      </div>
    </mat-menu>
  </li>
  </ul>

<!--<div class="text-white version">v2.9</div>-->
     <!-- dropdown -->
<!--    <button style="float:left;" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">-->
<!--        <mat-icon>more_vert</mat-icon>-->
<!--      </button>-->
<!--      <mat-menu #menu="matMenu">-->
<!--        <button mat-menu-item>-->
<!--          <mat-icon>dialpad</mat-icon>-->
<!--          <span>Redial</span>-->
<!--        </button>-->
<!--        <button mat-menu-item disabled>-->
<!--          <mat-icon>voicemail</mat-icon>-->
<!--          <span>Check voice mail</span>-->
<!--        </button>-->
<!--        <button mat-menu-item>-->
<!--          <mat-icon>notifications_off</mat-icon>-->
<!--          <span>Disable alerts</span>-->
<!--        </button>-->
<!--      </mat-menu>-->


  </mat-toolbar>

<nav #navbar class="navbar">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
         (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
<!--      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>-->
      <a class="navbar-brand" routerLink="dashboard/main">
        <img src="assets/images/logo.png" alt="" />
        <span class="logo-name">Atrio</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngClass]="isNavbarCollapsed ? '' : 'show'">
      <ul class="pull-left collapse-menu-icon">
<!--        <li class="menuBtn">-->
<!--          <button mat-button (click)="callSidemenuCollapse()" class="sidemenu-collapse nav-notification-icons">-->
<!--            <app-feather-icons [icon]="'menu'" [class]="'header-icon'"></app-feather-icons>-->
<!--          </button>-->
<!--        </li>-->
      </ul>
      <ul class="nav navbar-nav navbar-right">


        <!-- #END# Notifications-->
        <li class="nav-item user_profile">
          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div class="chip dropdown-toggle" ngbDropdownToggle class="">
              <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu">
            <div class="noti-list">
              <div class="menu ">
                <div class="user_dw_menu">
                  <button mat-menu-item>
                    <app-feather-icons [icon]="'user'" [class]="'user-menu-icons'"></app-feather-icons>Account
                  </button>
<!--                  <button mat-menu-item>-->
<!--                    <app-feather-icons [icon]="'mail'" [class]="'user-menu-icons'"></app-feather-icons>Inbox-->
<!--                  </button>-->
<!--                  <button mat-menu-item>-->
<!--                    <app-feather-icons [icon]="'settings'" [class]="'user-menu-icons'"></app-feather-icons>Settings-->
<!--                  </button>-->
                  <button mat-menu-item (click)="logout()">
                    <app-feather-icons [icon]="'log-out'" [class]="'user-menu-icons'"></app-feather-icons>Logout
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>>


