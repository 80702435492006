<h3 class="text-center container">
    Employee Registration Form
</h3>
<br>
<form class="" [formGroup]="employeeForm">
    <div class="row">




                <mat-form-field appearance="outline" class="col-md-6">
                    <mat-label>Name</mat-label>
                    <input matInput  required formControlName="userName" >
                </mat-form-field>


        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Email Address</mat-label>
            <input matInput placeholder="Email Address" pattern="[a-z0-9._%+-]+@[a-z.-]+\.[a-z]{2,4}$" required
                   formControlName="email">
        </mat-form-field>


        <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Centre</mat-label>
                <mat-select   (selectionchange)="onSelectCentre($event)" multiple formControlName="centre">
                    <mat-option *ngFor="let c of (centres$|async) " [value]="c.centreCode">{{c.centreName}}</mat-option>
                </mat-select>
            </mat-form-field>




            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Department</mat-label>
              <mat-select formControlName="department">
                <mat-option *ngFor="let d of departments$|async" [value]="d">{{d}}</mat-option>
              </mat-select>
            </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-6">
            <mat-label>Job Title</mat-label>
            <input matInput  required formControlName="jobtitle">
        </mat-form-field>


            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Employee Code</mat-label>
                <input matInput required formControlName="empCode">
            </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Location</mat-label>
                <input matInput placeholder="Location" required formControlName="location">
            </mat-form-field>




            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Mobile</mat-label>
                <input matInput placeholder="Enter Number" required formControlName="mobile" maxlength="10">
                <mat-icon matSuffix>phone</mat-icon>
            </mat-form-field>




        <!--    pattern="[0-9]{10}$"-->

            <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Employee Role</mat-label>
<mat-select formControlName="role">
  <mat-option *ngFor="let role of roles$|async" [value]="role">{{role}}</mat-option>
</mat-select>
            </mat-form-field>


<!--      <div class="col-md-6 text-center" >-->
<!--        <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">-->
<!--          <input hidden type="file" #fileInput (change)="uploadFile($event)">-->
<!--          <button mat-icon-button  class="icon-button-large" aria-label="large button">-->
<!--            <mat-icon>cloud_upload</mat-icon>-->
<!--          </button>-->
<!--          <h6>Upload your photo <br> Drag and Drop your files here <br>.JPG.PNG. </h6>-->
<!--          <p> </p>-->
<!--        </div>-->
<!--        <div  class="files-list" *ngFor="let file of files;let i= index">-->
<!--          <p> {{ file }} </p>-->
<!--          <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">-->
<!--            <mat-icon>delete</mat-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </div>-->





    </div>

    <div class="d-flex justify-content-center">
        <button color="warn" mat-raised-button (click)="cancel()">Cancel</button> &nbsp;
        <button color="primary" mat-raised-button type="submit" (click)="createUser()">Save</button>
    </div>

</form>


