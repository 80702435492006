import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-view-sponsor',
  templateUrl: './view-sponsor.component.html',
  styleUrls: ['./view-sponsor.component.scss']
})
export class ViewSponsorComponent implements OnInit {
data2:any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  this.data2 = this.data.exampleData
  }

  ngOnInit(): void {
    console.log(this.data)
  }

}
