import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {AddUnitSponsorshipComponent} from "../add-unit-sponsorship/add-unit-sponsorship.component";
import {AllotChildSponsorshipComponent} from "../allot-sponsorship-child/allot-child-sponsorship.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {UnitSponsorshipService} from "../unit-sponsorship.service";
import {ViewSponsorComponent} from "../view-sponsor/view-sponsor.component";

interface UnitSP{
  sr:string
  profile:string
}

@Component({
  selector: 'app-sponsor-table',
  templateUrl: './sponsor-table.component.html',
  styleUrls: ['./sponsor-table.component.scss']
})
export class SponsorTableComponent implements OnInit {
  selectedCentre?:string|null=''
  centres$: Observable<Centre[]>
  displayedColumns: string[] = [
    'donorName',
    'mobile',
    // 'address',
    // 'state',
    // 'country',
    'city',
    // 'pincode',
    // 'email',
    'date',
    'date2',
    'angleName',
    // 'paymentInformation',
    // 'transactionID',
    'transactionAmount',
    'remainingAmount',
    // 'panNumber',
    'action'
  ];
  dataSource:any
  data:UnitSP[]   = []
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

  allpermission = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  unitSponsorship = this.allpermission.filter((x) => x.functionName == 'Unit Sponsorship')
  constructor(private centreService: CentreService,
              private matdialog: MatDialog,
              private mDatabase: AngularFirestore,
              private mService:UnitSponsorshipService
  ) {
    this.centres$ = this.centreService.getAllCentres()

  }

  ngOnInit(): void {

    this.mService.getSponsorships().subscribe(res =>{
      this.dataSource = res
      console.log(this.dataSource)
      this.dataSource.data.paginator = this.paginator;
      this.dataSource.sort = this.sort


    })


  }

  AddUnitSponsorship() {
    this.matdialog.open(AddUnitSponsorshipComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )

  }

  openViewDialog(element:any): void {
     this.matdialog.open(ViewSponsorComponent, {
      width: '600px',
       maxHeight: '100vh',
      data: {
        exampleData: element
      },
    });
  }

  showChild(data:any) {
    this.matdialog.open(AllotChildSponsorshipComponent,
      {
        data:data,
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }
}
