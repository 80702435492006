<table>
    <thead>
    <tr>
        <th>Hospital</th>
        <th>Centres</th>
        <th>Count</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of tableData">
        <td>{{ data.hospital }}</td>
        <td>
            <table>
                <tr *ngFor="let centre of data.centres">
                    <td>{{ centre.centre }}</td>
                    <td>{{ centre.count }}</td>
                </tr>
            </table>
        </td>
    </tr>
    </tbody>
</table>

