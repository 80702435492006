import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {TripData} from "../car-log-book/car-log-book.component";

@Component({
  selector: 'app-view-car-log-book',
  templateUrl: './view-car-log-book.component.html',
  styleUrls: ['./view-car-log-book.component.scss']
})
export class ViewCarLogBookComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public tripData:any,) {

  }

  ngOnInit(): void {
  }
  displayDate(dateObject: { nanoseconds: number; seconds: number } | null): string {
    if (!dateObject) {
      return 'N/A'; // or any default value for null dates
    }

    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  }


// Assuming this function is inside your Angular component
  displayDate2(dateObject: { nanoseconds: number; seconds: number }): string {

    const date = new Date(dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6);

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear().toString().slice(-2);

    return `${day}/${month}/${year}`;
  }



  openGoogleMaps(lat: number, lng: number): void {
    // Use default values or handle undefined cases
    const defaultLat = 0; // Replace with your default value
    const defaultLng = 0; // Replace with your default value

    const finalLat = lat !== undefined ? lat : defaultLat;
    const finalLng = lng !== undefined ? lng : defaultLng;

    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${finalLat},${finalLng}`;
    window.open(googleMapsUrl, '_blank');
  }
  calculateTotalKms(element: any): number {
    const pickUpMeterReading = parseFloat(element.pick_up_meter_reading);
    const dropMeterReading = parseFloat(element.drop_meter_reading);

    if (pickUpMeterReading == null || dropMeterReading == null) {
      // Handle missing or invalid data, e.g., return 0 or show an error
      return 0;
    }
    else if (isNaN(pickUpMeterReading) || isNaN(dropMeterReading)) {
      // Handle missing or invalid data, e.g., return 0 or show an error
      return 0;
    }


    const totalKms = Math.max(0, dropMeterReading - pickUpMeterReading);



    return totalKms;
  }

}
