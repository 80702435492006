import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-changes',
  templateUrl: './changes.component.html',
  styleUrls: ['./changes.component.scss']
})
export class ChangesComponent implements OnInit {
  changesData:any

  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.changesData =data
  }

  ngOnInit(): void {
    console.log(this.data)
  }

  protected readonly Object = Object;
}
