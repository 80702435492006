import {Component, OnInit, ViewChild} from '@angular/core';
import {AddInfoComponent, Driverdata} from "../add-info/add-info.component";
import {MatDialog} from "@angular/material/dialog";
import {VehicleService} from "../vehicle.service";
import {UpdateDriverComponent} from "../update-driver/update-driver.component";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {ViewVehicleComponent} from "../view-vehicle/view-vehicle.component";
import {ViewDriverComponent} from "../view-driver/view-driver.component";

@Component({
  selector: 'app-driver-table',
  templateUrl: './driver-table.component.html',
  styleUrls: ['./driver-table.component.scss']
})
export class DriverTableComponent implements OnInit {
  dataSource: MatTableDataSource<any>
  displayedColumns: string[] = ['driverName', 'phone', 'dateOfJoining', 'license', 'expirationDate', 'action']; // Add other column names here
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  vehicle=this.permissions.filter((x)=>x.functionName=='Vehicles')
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private mDialog:MatDialog,
              private mService: VehicleService) {
    this.dataSource =new MatTableDataSource<any>()
  }

  ngOnInit(): void {
    this.mService.getDriver().subscribe(res => {
      this.dataSource = new MatTableDataSource(res)
      console.log(this.dataSource)
      this.dataSource.paginator = this.paginator
      this.dataSource.sort = this.sort
    })


  }

  openDiver() {
    const dialogRef = this.mDialog.open(AddInfoComponent, {
      width: '900px',
      maxHeight: '90vh',
      panelClass: 'custom-dialog-container'
    });

  }

  editVehicle(element:any) {
    this.mDialog.open(UpdateDriverComponent, {
      data: element,
      maxHeight: '100vh',
      panelClass: 'custom-dialog-container'
    });

  }


  deleteVehicle(element:any) {
    // console.log(element.id)
    this.mService.deleteDriverData(element.id).then(res => {
      alert("Data deleted successfully")
    })

  }
  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    // @ts-ignore
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  openView(element:any) {
    this.mDialog.open(ViewDriverComponent, {
      data:element,
      maxHeight: '100vh',
      width: '100%'
    })

  }
}
