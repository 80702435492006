<h2 class="text-center">Child Admission Information </h2>

<form  class="about" [formGroup]="childadmissionForm">
  <div class="row">


    <div class="col-5">
      <form class="example-form">
        <mat-form-field class="example-full-width" appearance="outline">
          <mat-label>Select AL Center</mat-label>
          <input type="text"
                 placeholder="Pick one"
                 aria-label="Number"
                 matInput
                 required formControlName="selectcentre"
                 [formControl]="myControl"
                 [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of options" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
      </form>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Stayed at AL Before</mat-label>
        <mat-select formControlName="stayedbefore" required>
          <mat-option  *ngFor="let state of states" [value]="state">{{state}}</mat-option>
          </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Select Unit</mat-label>
        <mat-select formControlName="selectunit" required>
          <mat-option  *ngFor="let state of states" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>AL Bag Given?</mat-label>
        <mat-select formControlName="bag" required>
          <mat-option  *ngFor="let state of states" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Mother/Guardian & Father/Guardian will Stay with Kid</mat-label>
        <mat-select formControlName="guardian" required>
          <mat-option  *ngFor="let state of states" [value]="state">{{state}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Date of Birth</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="DOB"  required formControlName="dob">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Accompanied By</mat-label>
        <input matInput placeholder="Accompanied By" required formControlName="accompaniedby">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <div *ngIf="accompaniedby.invalid  &&  (accompaniedby.touched || accompaniedby.dirty)">
        <span style="color: red;" *ngIf="accompaniedby.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="accompaniedby.errors?.pattern">*Enter Only Character.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Accompanier Relation</mat-label>
        <mat-select formControlName="accompanierel" required>
          <mat-option  *ngFor="let relation of relation" [value]="relation">{{relation}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>

  <button color="warn" mat-raised-button mat-dialog-close="cancel" (click)="cancel()" >Cancel</button> &nbsp;

  <button  color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>



</form>
