
<h2 class="text-center">Caretaker Form</h2>


<form  class="about" [formGroup]="caretakerForm">
  <div class="row">

    <div class="col-md-10 text-center" >
      <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
        <input hidden type="file" #fileInput (change)="uploadFile($event)" required  formControlName="caretakerphoto">
        <button mat-icon-button  class="icon-button-large" aria-label="large button">
          <mat-icon>cloud_upload</mat-icon>
        </button>
        <h6>Upload your photo <br> Drag and Drop your files here <br>.JPG.PNG. </h6>
        <p> </p>
      </div>
      <div  class="files-list" *ngFor="let file of files;let i= index">
        <p> {{ file }} </p>
        <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <br>
    </div>


    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Caretaker code</mat-label>
        <input matInput placeholder="Caretaker code" required formControlName="caretakercode">
      </mat-form-field>
      <div *ngIf="caretakercode.invalid  &&  (caretakercode.touched || caretakercode.dirty)">
        <span style="color: red;" *ngIf="caretakercode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="caretakercode.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Child code</mat-label>
        <input matInput placeholder="Child code" required formControlName="childcode">
      </mat-form-field>
      <div *ngIf="childcode.invalid  &&  (childcode.touched || childcode.dirty)">
        <span style="color: red;" *ngIf="childcode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="childcode.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Center code</mat-label>
        <input matInput placeholder="Center code" required formControlName="centercode">
      </mat-form-field>
      <div *ngIf="centercode.invalid  &&  (centercode.touched || centercode.dirty)">
        <span style="color: red;" *ngIf="centercode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="centercode.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>


    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Caretaker Name</mat-label>
        <input matInput placeholder="Caretaker Name" required   formControlName="caretakername">
      </mat-form-field>
      <div *ngIf="  caretakername.invalid  &&  (caretakername.touched || caretakername.dirty)">
        <span style="color: red;" *ngIf="caretakername.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="caretakername.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>


    <div class="col-md-10">
      <mat-form-field appearance="outline">
        <mat-label>Caretaker Records</mat-label>
        <input matInput placeholder="Caretaker Records" required formControlName="caretakerrecords"  >
      </mat-form-field>
      <div *ngIf="  caretakerrecords.invalid  &&  (caretakerrecords.touched || caretakerrecords.dirty)">
        <span style="color: red;" *ngIf="caretakerrecords.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="caretakerrecords.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-md-10">
      <mat-form-field appearance="outline">
        <mat-label>Caretaker Address</mat-label>
        <input matInput placeholder="Caretaker Address" required formControlName="caretakeraddress"  >
      </mat-form-field>
      <div *ngIf="  caretakeraddress.invalid  &&  (caretakeraddress.touched || caretakeraddress.dirty)">
        <span style="color: red;" *ngIf="caretakeraddress.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="caretakeraddress.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-md-10">
      <mat-form-field appearance="outline">
        <mat-label>Occupation</mat-label>
        <input matInput placeholder="Occupation" required formControlName="occupation"  >
      </mat-form-field>
      <div *ngIf="  occupation.invalid  &&  (occupation.touched || occupation.dirty)">
        <span style="color: red;" *ngIf="occupation.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="occupation.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Annual Income</mat-label>
        <input matInput placeholder="Annual Income" required formControlName="income"  >
      </mat-form-field>
      <div *ngIf="  income.invalid  &&  (income.touched || income.dirty)">
        <span style="color: red;" *ngIf="income.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="income.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Relation to Child</mat-label>
        <mat-select formControlName="relation" required>
          <mat-option  *ngFor="let relation of relation" [value]="relation">{{relation}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="col-md-10">
      <mat-form-field appearance="outline">
        <mat-label>Address Code</mat-label>
        <input matInput placeholder="Address Code" required formControlName="addresscode"  >
      </mat-form-field>
      <div *ngIf="  addresscode.invalid  &&  (addresscode.touched || addresscode.dirty)">
        <span style="color: red;" *ngIf="addresscode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="addresscode.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-md-10">
      <mat-form-field appearance="outline">
        <mat-label>Address line1</mat-label>
        <input matInput placeholder="Address line1" required formControlName="line1"  >
      </mat-form-field>
      <div *ngIf="  line1.invalid  &&  (line1.touched || line1.dirty)">
        <span style="color: red;" *ngIf="line1.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="line1.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-md-10">
      <mat-form-field appearance="outline">
        <mat-label>Address line2</mat-label>
        <input matInput placeholder="Address line2" required formControlName="line2"  >
      </mat-form-field>
      <div *ngIf="  line2.invalid  &&  (line2.touched || line2.dirty)">
        <span style="color: red;" *ngIf="line2.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="line2.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>


    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Address Type</mat-label>
        <input matInput placeholder="Address Type" required formControlName="addresstype"  >
      </mat-form-field>
      <div *ngIf="  addresstype.invalid  &&  (addresstype.touched || addresstype.dirty)">
        <span style="color: red;" *ngIf="addresstype.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="addresstype.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>


    <!--    <div class="col-md-5">-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>Date of Birth</mat-label>-->
<!--        <input matInput [matDatepicker]="picker" placeholder="DOB"  required formControlName="dob">-->
<!--        <mat-hint>DD/MM/YYYY</mat-hint>-->
<!--        <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--        <mat-datepicker #picker></mat-datepicker>-->
<!--      </mat-form-field>-->
<!--    </div>-->

    <!--    pattern="[0-9]{10}$"-->
    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Mobile No.</mat-label>
        <input matInput placeholder="Mobileno" maxlength="10" required   formControlName="mobile">
        <mat-icon matSuffix>phone</mat-icon>
      </mat-form-field>
      <div *ngIf="  mobile.invalid  &&  (mobile.touched || mobile.dirty)">
        <span style="color: red;" *ngIf="mobile.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="mobile.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" required formControlName="city">
      </mat-form-field>
      <div *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
        <span style="color: red;" *ngIf="city.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>


    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Country</mat-label>
        <input matInput placeholder="Country Name" required formControlName="country">
      </mat-form-field>
      <div *ngIf="  country.invalid  &&  (country.touched || country.dirty)">
        <span style="color: red;" *ngIf="country.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="country.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>State</mat-label>
        <input matInput placeholder="State Name"  required formControlName="state">
      </mat-form-field>
      <div *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
        <span style="color: red;" *ngIf="state.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-md-5">
      <mat-form-field appearance="outline">
        <mat-label>Pin Code</mat-label>
        <input matInput #postalCode maxlength="6" required  placeholder="pincode" formControlName="pincode"  >
        <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
      </mat-form-field>
      <div *ngIf="  pincode.invalid  &&  (pincode.touched || pincode.dirty)">
        <span style="color: red;" *ngIf="pincode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="pincode.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>


  </div>


  <button color="warn" mat-raised-button (click)="cancel()" >Cancel</button> &nbsp;
  <button color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>


</form>
