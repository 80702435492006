export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY', // This will format the datepicker calendar view
    // dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};



export const States = [
  {code: "AN", name: "Andaman and Nicobar Islands"},
  {code: "AP", name: "Andhra Pradesh"},
  {code: "AR", name: "Arunachal Pradesh"},
  {code: "AS", name: "Assam"},
  {code: "BR", name: "Bihar"},
  {code: "CG", name: "Chandigarh"},
  {code: "CH", name: "Chhattisgarh"},
  {code: "DH", name: "Dadra and Nagar Haveli"},
  {code: "DD", name: "Daman and Diu"},
  {code: "DL", name: "Delhi"},
  {code: "GA", name: "Goa"},
  {code: "GJ", name: "Gujarat"},
  {code: "HR", name: "Haryana"},
  {code: "HP", name: "Himachal Pradesh"},
  {code: "JK", name: "Jammu and Kashmir"},
  {code: "JH", name: "Jharkhand"},
  {code: "KA", name: "Karnataka"},
  {code: "KL", name: "Kerala"},
  {code: "LD", name: "Lakshadweep"},
  {code: "MP", name: "Madhya Pradesh"},
  {code: "MH", name: "Maharashtra"},
  {code: "MN", name: "Manipur"},
  {code: "ML", name: "Meghalaya"},
  {code: "MZ", name: "Mizoram"},
  {code: "NL", name: "Nagaland"},
  {code: "OR", name: "Odisha"},
  {code: "PY", name: "Puducherry"},
  {code: "PB", name: "Punjab"},
  {code: "RJ", name: "Rajasthan"},
  {code: "SK", name: "Sikkim"},
  {code: "TN", name: "Tamil Nadu"},
  {code: "TS", name: "Telangana"},
  {code: "TR", name: "Tripura"},
  {code: "UK", name: "Uttarakhand"},
  {code: "UP", name: "Uttar Pradesh"},
  {code: "WB", name: "West Bengal"}]
export const StateName = [
  { name: "Andaman and Nicobar Islands"},
  { name: "Andhra Pradesh"},
  { name: "Arunachal Pradesh"},
  { name: "Assam"},
  { name: "Bihar"},
  { name: "Chandigarh"},
  { name: "Chhattisgarh"},
  { name: "Dadra and Nagar Haveli"},
  { name: "Daman and Diu"},
  { name: "Delhi"},
  { name: "Goa"},
  { name: "Gujarat"},
  { name: "Haryana"},
  { name: "Himachal Pradesh"},
  { name: "Jammu and Kashmir"},
  { name: "Jharkhand"},
  { name: "Karnataka"},
  { name: "Kerala"},
  { name: "Lakshadweep"},
  { name: "Madhya Pradesh"},
  { name: "Maharashtra"},
  { name: "Manipur"},
  { name: "Meghalaya"},
  { name: "Mizoram"},
  { name: "Nagaland"},
  { name: "Odisha"},
  { name: "Puducherry"},
  { name: "Punjab"},
  { name: "Rajasthan"},
  { name: "Sikkim"},
  { name: "Tamil Nadu"},
  { name: "Telangana"},
  { name: "Tripura"},
  { name: "Uttarakhand"},
  { name: "Uttar Pradesh"},
  { name: "West Bengal"}]
