import {Component, OnDestroy, OnInit} from '@angular/core';

import {ChartConfiguration, ChartType} from 'chart.js';
import {Observable, Subject, takeUntil} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {CentreService} from "../../../services/centre.service";


@Component({
  selector: 'app-allcenters',
  templateUrl: './all-centers.component.html',
  styleUrls: ['./all-centers.component.scss']
})
export class AllCentersComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject()
  doughnutChartType: ChartType = 'doughnut';
  colors: any[] = [{backgroundColor: ["#0b5ba8", "#6114be", "#15dcdc"]}];

//  doughnutChartType
  centres$!: Observable<Centre[]>;
  centreArray: Centre[] = []
  userData: any
  chartconfigurationsArray: ChartConfiguration<'doughnut'>['data']['datasets'][] = []

  constructor(private firestore: AngularFirestore,
              private centerService: CentreService) {
    
    this.userData = JSON.parse(localStorage.getItem('userData')!)
    if (this.userData.role == 'Centre') {
      this.centres$ = centerService.getSortedCenterListByRole(this.userData.centreCode)
    } else if (this.userData.role != 'Centre') {
      this.centres$ = centerService.getCentreList()
    }

    // console.log('All Centres User DaTA', localStorage.getItem('userData'))
  }

  ngOnInit(): void {
    this.centres$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe(snapshot => {
      this.centreArray = []
      snapshot.forEach(centre => {

        centre.nonFunctionalUnits = 0
        if (centre.isArchive != true) this.centreArray?.push(centre)

        // console.log(this.centreArray.length)
        const doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
          {data: [centre.occupiedUnits!, centre.availableUnits!, centre.nonFunctionalUnits], label: centre.centreName ,backgroundColor: ["#fbc02d", "#00695c", "#af1111"]},

          // { data: [ 50, 150, 120 ], label: 'Series B' },
          // { data: [ 250, 130, 70 ], label: 'Series C' }
        ];
        this.chartconfigurationsArray.push(doughnutChartDatasets)

      })
    })
  }


  // Doughnut
  public doughnutChartLabels: string[] = ['Occupied', 'Available', 'Non-Functional'];
  public doughnutChartDatasets: ChartConfiguration<'doughnut'>['data']['datasets'] = [
    {data: [550, 150, 200], label: 'Series A'},
    // { data: [ 50, 150, 120 ], label: 'Series B' },
    // { data: [ 250, 130, 70 ], label: 'Series C' }
  ];
  public doughnutChartDatasets1: ChartConfiguration<'doughnut'>['data']['datasets'] = [
    {data: [150, 550, 200], label: 'Series A'},
    // { data: [ 50, 150, 120 ], label: 'Series B' },
    // { data: [ 250, 130, 70 ], label: 'Series C' }
  ];
  public doughnutChartDatasets2: ChartConfiguration<'doughnut'>['data']['datasets'] = [
    {data: [300, 600, 100], label: 'Series A'},
    // { data: [ 50, 150, 120 ], label: 'Series B' },
    // { data: [ 250, 130, 70 ], label: 'Series C' }
  ];
  public doughnutChartDatasets3: ChartConfiguration<'doughnut'>['data']['datasets'] = [
    {data: [600, 100, 300], label: 'Series A'},
    // { data: [ 50, 150, 120 ], label: 'Series B' },
    // { data: [ 250, 130, 70 ], label: 'Series C' }
  ];
  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: false
  };

  ngOnDestroy(): void {
    this._destroyed$.next(null)
    this._destroyed$.complete()
  }

// Doughnut

}
