import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  series2: ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  labels: string[];
  responsive: ApexResponsive[];
};
@Component({
  selector: 'app-unique-admissions-chart',
  templateUrl: './unique-admissions-chart.component.html',
  styleUrls: ['./unique-admissions-chart.component.scss']
})
export class UniqueAdmissionsChartComponent implements  OnInit,AfterViewInit {
@Input() totalRegisteredChildren: number=0
@Input() totalRegisteredBoys: number=0
@Input() totalRegisteredGirls: number=0
@ViewChild('chart') chart!: ChartComponent;
public pieChartOptions!: Partial<ChartOptions>;
  constructor() {

  }

private smallChart2() {
  setTimeout(() => {
    this.pieChartOptions = {

      series2: [this.totalRegisteredChildren, this.totalRegisteredBoys,this.totalRegisteredGirls],
      chart: {
        type: 'donut',
        width: 200,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      labels: ['Total Registered', ' Boys', 'Girls'],
      responsive: [
        {
          breakpoint: 480,
          options: {},
        },
      ],
    };
  }, 100);

  }

  ngOnInit() {
    this.smallChart2()

  }

  ngAfterViewInit(): void {
  }

}
