import {Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from "@angular/router";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Events, FullChild} from "../../../interfaces/FullChild";
import {EventModel} from "../events.component";
import {pipe, Subject, takeUntil} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.scss']
})
export class ViewEventComponent implements OnInit,OnDestroy {
  id?: string
  env = isDevMode() ? environment.testMode : environment.productionMode

  event?: EventModel
_destroyed= new Subject()
  constructor(private router: Router,
              private mFirestore: AngularFirestore,
              private route: ActivatedRoute) {
    this.route.params.subscribe(res => {
      console.log('PARAM', res.id)
      this.id = res.id
    })
  }

  ngOnInit(): void {
    this.mFirestore.collection<EventModel>(this.env.events).doc(this.id!).valueChanges({idField: 'key'}).
    pipe(
      takeUntil(this._destroyed)
    )
      .subscribe(
      res => {
        this.event = res
        console.log(this.event)
      }
    )
  }

  // getChildName(c: string):string {
  //   console.log('getChild Name called!')
  //   let name = ''
  //   this.mFirestore.collection<FullChild>('testRegistrations').doc(c).valueChanges({idField:'key'}).pipe(
  //     takeUntil(this._destroyed)
  //   ).subscribe(res=>{
  //  name= res?.firstName + ' '+ res?.lastName
  //   })
  //   return  name
  // }

  ngOnDestroy(): void {
    // @ts-ignore
    this._destroyed.next()
    this._destroyed.complete()
  }


  shareOnWhatsApp(): void {
    // Replace 'yourShareText' with the text you want to share
    const shareText = encodeURIComponent('Your share text here');
    const whatsappUrl = `https://api.whatsapp.com/send?text=${shareText}`;
    window.open(whatsappUrl, '_blank');
  }

  shareOnEmail(): void {
    // Replace 'yourShareText' with the text you want to share
    const shareText = encodeURIComponent('Your share text here');
    const emailUrl = `mailto:?subject=Your Subject&body=${shareText}`;
    window.location.href = emailUrl;
  }
}
