import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {DynamicTableDataSource, DynamicTableItem} from './dynamic-table-datasource';
// @ts-ignore
import HEADERS from '../../../../assets/child-headers.json'

@Component({
    selector: 'app-dynamic-assessment-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.css']
})
export class DynamicTableComponent implements AfterViewInit, OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatTable) table!: MatTable<DynamicTableItem>;
    dataSource!: MatTableDataSource<any>;

    headers = [

        "srNo",
        "childCode",
        "lastName",
        "firstName",
        "admittedBy",
        "dateOfAdmission",
        "dob",
        "emergencyContactName1",
        "emergencyContactRelation1",
        "address",
        "guardians/0/firstName",
        "guardians/0/lastName",
        "guardians/0/contactNo",
        "guardians/1/firstName",
        "guardians/1/lastName",
        "guardians/1/occupation",
        "centreName",
        "state",
        "hospital",
        "diagnosisSelected/0/name",
        "socialName",
        "district",
        "admissionData/0/type",
        "admissionData/0/dateOfAdmission",
        "admissionData/1/dateOfDischarge",
        "admissionData/1/type",
        "isAlloted",
        "type",
        "id",
        "fileNo",
        "centreCode",
        "emergencyContactNumber1",
        "city",
        "age",
        "dateOfDischarge",
        "diagnosis",
        "ageAsOnAdmission",
        "gender",
        "REMARKS",
        "Diagnosis",
        "pincode",
        "aspirations",
        "emergencyContactNumber2",
        "emergencyContactRelation2",
        "emergencyContactName2",
        "hobbies",
        "guardians/0/occupation",
        "middleName",
        "lifeStatus",
        "guardians/1/contactNo",
        "guardians/0/middleName",
        "guardians/1/middleName",
        "status",
        "FolloupCount",
        "siblings/0/gender",
        "siblings/0/Age",
        "siblings/0/firstName",
        "guardians/0/annualIncome",
        "guardians/0/skills",
        "guardians/0/alternateContactNo",
        "guardians/1/skills",
        "bloodGroup",
        "allergies",
        "doctorName",
        "FDischargeCount",
        "admissionData/0/centreName",
        "admissionData/2/dateOfAdmission",
        "admissionData/2/centreName",
        "admissionData/2/type",
        "admissionData/3/type",
        "admissionData/3/dateOfDischarge",
        "admissionData/4/centreName",
        "admissionData/4/type",
        "admissionData/4/dateOfAdmission",
        "admissionData/5/dateOfDischarge",
        "admissionData/5/type",
        "admissionData/6/dateOfAdmission",
        "admissionData/6/type",
        "admissionData/6/centreName",
        "AgeAsOnDate",
        "bag",
        "dataTakenBy",
        "weight",
        "admissionData/0/currentUnit",
        "admissionData/1/currentUnit",
        "admissionData/2/currentUnit",
        "admissionData/3/currentUnit",
        "admissionData/4/currentUnit",
        "admissionData/5/currentUnit",
        "admissionData/6/currentUnit",
        "admissionData/7/dateOfDischarge",
        "admissionData/7/type",
        "admissionData/7/currentUnit",
        "admissionData/8/currentUnit",
        "admissionData/8/dateOfAdmission",
        "admissionData/8/centreName",
        "admissionData/8/type",
        "admissionData/9/currentUnit",
        "admissionData/9/dateOfDischarge",
        "admissionData/9/type",
        "admissionData/10/type",
        "admissionData/10/currentUnit",
        "admissionData/10/dateOfAdmission",
        "admissionData/10/centreName",
        "admissionData/11/dateOfDischarge",
        "admissionData/11/type",
        "admissionData/11/currentUnit",
        "selectUnit",
        "height",
        "siblings/1/firstName",
        "siblings/1/gender",
        "siblings/1/age",
        "admissionData/0/centreCode",
        "admissionData/1/centreName",
        "admissionData/1/centreCode",
        "centreCodeFirst",
        "admissionData/12/dateOfAdmission",
        "admissionData/12/centreName",
        "admissionData/12/currentUnit",
        "admissionData/12/type",
        "admissionData/13/dateOfDischarge",
        "admissionData/13/type",
        "admissionData/13/currentUnit",
        "siblings/0/age",
        "reason",
        "followUpDate/seconds",
        "followUpDate/nanoseconds",
        "admissionData/14/dateOfAdmission",
        "admissionData/14/centreName",
        "admissionData/14/type",
        "admissionData/14/currentUnit",
        "admissionData/15/type",
        "admissionData/15/currentUnit",
        "admissionData/15/dateOfDischarge",
        "admissionData/16/type",
        "admissionData/16/currentUnit",
        "admissionData/16/dateOfAdmission",
        "admissionData/16/centreName",
        "admissionData/17/type",
        "admissionData/17/dateOfDischarge",
        "admissionData/17/currentUnit",
        "admissionData/18/dateOfAdmission",
        "admissionData/18/centreName",
        "admissionData/18/currentUnit",
        "admissionData/18/type",
        "admissionData/19/type",
        "admissionData/19/currentUnit",
        "admissionData/19/dateOfDischarge",
        "admissionData/20/centreName",
        "admissionData/20/dateOfAdmission",
        "admissionData/20/type",
        "admissionData/20/currentUnit",
        "admissionData/20/centreCode",
        "admissionData/21/currentUnit",
        "admissionData/21/dateOfDischarge",
        "admissionData/21/weight",
        "admissionData/21/height",
        "admissionData/21/type",
        "admissionData/21/centreCode",
        "admissionData/22/dateOfAdmission",
        "admissionData/22/tentativeDischargeDate",
        "admissionData/22/centreCode",
        "admissionData/22/currentUnit",
        "admissionData/22/type",
        "admissionData/22/weight",
        "admissionData/22/height",
        "admissionData/23/dateOfDischarge",
        "admissionData/23/centreCode",
        "admissionData/23/height",
        "admissionData/23/type",
        "admissionData/23/currentUnit",
        "admissionData/23/weight",
        "dischargeDate",
        "remark",
        "admissionData/16/cetreCode",
        "guardians/1/alternateContactNo",
        "admissionData/8/centreCode",
        "siblings/2/age",
        "siblings/2/gender",
        "siblings/2/firstName",
        "admissionData/20/weight",
        "admissionData/20/tentativeDischargeDate",
        "admissionData/20/height",
        "admissionData/21/dateOfAdmission",
        "admissionData/22/dateOfDischarge",
        "childAadhaar",
        "guardianStayStatus",
        "medical",
        "allergiesSelected",
        "stayedBefore",
        "days",
        "diagnosisSelected/0",
        "country",
        "months",
        "treatment",
        "guardians/0/otherDocuments",
        "guardians/0/relation",
        "guardians/1/annualIncome",
        "guardians/1/relation",
        "guardians/1/otherDocuments",
        "mobile",
        "prescribed",
        "siblings/3/firstName",
        "siblings/3/gender",
        "siblings/3/age",
        "admissionData/14/height",
        "admissionData/14/weight",
        "admissionData/14/centreCode",
        "admissionData/14/tentativeDischargeDate",
        "admissionData/15/weight",
        "admissionData/15/centreCode",
        "admissionData/15/height",
        "admissionData/16/tentativeDischargeDate",
        "admissionData/16/height",
        "admissionData/16/centreCode",
        "admissionData/16/weight",
        "admissionData/17/height",
        "admissionData/17/centreCode",
        "admissionData/17/weight",
        "admissionData/12/centreCode",
        "admissionData/12/tentativeDischargeDate",
        "admissionData/12/weight",
        "admissionData/12/height",
        "admissionData/13/centreCode",
        "admissionData/13/height",
        "admissionData/13/weight",
        "centreCodeOld",
        "admissionData/6/centreCode",
        "admissionData/10/weight",
        "admissionData/10/tentativeDischargeDate",
        "admissionData/10/height",
        "admissionData/10/centreCode",
        "admissionData/11/height",
        "admissionData/11/centreCode",
        "admissionData/11/weight",
        "events",
        "admissionData/6/weight",
        "admissionData/6/height",
        "admissionData/6/tentativeDischargeDate",
        "admissionData/7/centreCode",
        "admissionData/7/weight",
        "admissionData/7/height",
        "admissionData/8/height",
        "admissionData/8/tentativeDischargeDate",
        "admissionData/8/weight",
        "admissionData/9/centreCode",
        "admissionData/9/weight",
        "admissionData/9/height",
        "admissionData/4/weight",
        "admissionData/4/tentativeDischargeDate",
        "admissionData/4/height",
        "admissionData/4/centreCode",
        "admissionData/5/height",
        "admissionData/5/weight",
        "admissionData/5/centreCode",
        "admissionData/5/centreName",
        "admissionData/1/weight",
        "admissionData/1/height",
        "FollowUpCount",
        "admissionData/2/centreCode",
        "admissionData/3/centreName",
        "admissionData/3/centreCode",
        "admissionData/18/weight",
        "admissionData/18/centreCode",
        "admissionData/18/tentativeDischargeDate",
        "admissionData/18/height",
        "admissionData/19/weight",
        "admissionData/19/height",
        "admissionData/19/centreCode",
        "admissionData/2/weight",
        "admissionData/2/tentativeDischargeDate",
        "admissionData/2/height",
        "admissionData/3/weight",
        "admissionData/3/height",
        "siblings/0/mobile",
        "currentUnit",
        "siblings/1/mobile",
        "createdAt/seconds",
        "createdAt/nanoseconds",
        "siblings/2/mobile",
        "guardians/0/childCode",
        "guardians/1/childCode",
        "chlidCode",
        "documents",
        "baseCentreName",
        "baseCentreCode"

    ]
basicHeaders=[  "srNo",
    "childCode",
    "lastName",
    "firstName",
    "dateOfAdmission",
    "dob",]    /** Columns displayed in the assessment-table. Columns IDs can be added, removed, or reordered. */
    // displayedColumns = Object.keys(HEADERS);
    displayedColumns = this.basicHeaders;


    @Input() data: any[] = []
    @Input() show = false

    constructor() {
     // this.displayedColumns=  keys
        if (this.data.length > 2) {
            this.dataSource = new MatTableDataSource(this.data!);
            this.table.dataSource = this.dataSource;
        }

    }

    ngAfterViewInit(): void {
        // this.dataSource =new MatTableDataSource(this.data!);
        this.dataSource = new MatTableDataSource(this.data!);

        this.dataSource.sort = this.sort;

        this.dataSource.paginator = this.paginator;
        this.table.dataSource = this.dataSource;
    }

    ngOnInit(): void {
        // if(this.data.length>=2) {
        //  this.dataSource = new MatTableDataSource(this.data!);
        //  this.assessment-table.dataSource = this.dataSource;
        // }
    }
}
