
import { Injectable } from '@angular/core';
import {InConfiguration} from "../models/config.interface";

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public configData!: InConfiguration;
public navbarColor='white'
  constructor() {
    this.setConfigData();
  }

  setConfigData() {
    this.configData = {
      layout: {
        rtl: false, // options:  true & false
        variant: 'light', // options:  light & dark
        theme_color: 'orange', // options:  white, black, purple, blue, cyan, green, orange
        logo_bg_color: 'white', // options:  white, black, purple, blue, cyan, green, orange
        sidebar: {
          collapsed: false, // options:  true & false
          backgroundColor: 'dark', // options:  light & dark
        },
      },
    };
  }
}
