import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {LogService} from "../../../services/log.service";
import {MatDialogRef} from "@angular/material/dialog";

export interface Vehicle {
  vehicleName: string;
  vehicleNumber: string;
  vehicleBrand: string;
  bodyType: string;
  vehicleType: string;
  makerName: string;
  manufactureDate: string;
  numberOfCylinders: number;
  horsePower: number;
  cubicCapacity: number;
  makerClassification: string;
  wheelBase: number;
  chassisNumber: string;
  engineNumber: string;
  seatingCapacity: number;
  fuelType: string;
  unladenWeight: number;
  previousRegistration: string;
  color: string;
  servicingDate: string;
  servicingDuration: string;
  driver: string;
  vehicleStatus: string;
  providerName: string;
  vehiclePhoto: string;
}

@Component({
  selector: 'app-add-vehicles',
  templateUrl: './add-vehicles.component.html',
  styleUrls: ['./add-vehicles.component.scss']
})


export class AddVehiclesComponent implements OnInit {
  vehicleForm: FormGroup;
  selectedFile: File | null = null;
  constructor(private formBuilder: FormBuilder,
              private mService: VehicleService,
              private mLogService: LogService,
              private dialogRef: MatDialogRef<AddVehiclesComponent>,) {
    this.vehicleForm = this.formBuilder.group({
      vehicleName: ['', Validators.required],
      vehicleNumber: ['', Validators.required],
      vehicleBrand: ['', Validators.required],
      typeOfBody: [''],
      typeOfVehicle: [''],
      makersName: [''],
      manufactureDate: [''],
      numberOfCylinders: [''],
      horsePower: [''],
      cubicCapacity: [''],
      // makersClassification: [''],
      wheelBase: [''],
      chassisNumber: [''],
      engineNumber: [''],
      seatingCapacity: [''],
      fuelUsed: [''],
      unladenWeight: [''],
      previousRegistration: [''],
      bodyColor: [''],
      servicingDate: [''],
      servicingDuration: [''],
      driver: [''],
      vehicleStatus: [''],
      providerName: [''],
      vehiclePhoto: ['',],
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.vehicleForm.valid) {
      // Form submission logic here
      const vehicleData: Vehicle = this.vehicleForm.value;
      const id = ""
      this.mService.addVehicle(vehicleData).then(r => {
        alert("Data added successfully")
        let activity = {
          date: new Date().getTime(),
          section: 'Vehicle',
          action: 'Submit',
          description: `Vehicle added by user`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
        this.dialogRef.close();
      })
    } else {
      // Handle invalid form submission
      console.log('Form is invalid');
    }
  }


  onFileSelected(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.selectedFile = fileList[0];
    }
  }
}
