import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AddDonorComponent} from "../add-donor/add-donor.component";

@Component({
  selector: 'app-donor-admin',
  templateUrl: './donor-admin.component.html',
  styleUrls: ['./donor-admin.component.scss']
})
export class DonorAdminComponent implements OnInit {

  constructor(
    private readonly dialog:MatDialog
  ) { }

  ngOnInit(): void {
  }
openDonor(){
    this.dialog.open(AddDonorComponent)
}
}
