<div class="container">
<!--<div *ngIf="userData.role!='Centre'">-->
<!--  <BR>-->
<!--  <button color="accent" *ngIf="centres[0].all || centres[0].add " mat-raised-button id="create-center"  (click)="openpopup()"><mat-icon>add</mat-icon>&nbsp;Create Centre</button>&nbsp;-->

<!--</div>-->
<!--<br>-->
<!--  <mat-form-field>-->
<!--    <mat-label>Filter</mat-label>-->
<!--    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>-->
<!--  </mat-form-field>-->
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Centres</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button mat-mini-fab color="primary" *ngIf="userData.role!='Centre'">
          <mat-icon class="col-white" (click)="openpopup()">add</mat-icon>
        </button>
      </li>
      <!--      <li>-->
      <!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
      <!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
      <!--        </div>-->
      <!--      </li>-->
    </ul>
  </div>

<section class="example-container mat-elevation-z8" tabindex="0">
  <table mat-table [dataSource]="dataSource" matSort >

    <!-- Position Column -->
    <ng-container matColumnDef="centername">
      <th mat-header-cell *matHeaderCellDef> Centre Name </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'" [matTooltip]="element.centreCode"> {{element.centreName}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef> City </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> {{element.city}} </td>
    </ng-container>

    <!-- Available Units  Column -->
    <ng-container matColumnDef="availableunits">
      <th mat-header-cell *matHeaderCellDef> Available Units </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'" > {{element.availableUnits}}   </td>
    </ng-container>
    <!-- Weight Column -->
    <ng-container matColumnDef="occupiedunits">
      <th mat-header-cell *matHeaderCellDef> Occupied Units </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> {{element.occupiedUnits}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="totalunits">
      <th mat-header-cell *matHeaderCellDef> Total Units </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> {{element.totalUnits}} </td>
    </ng-container>


    <!-- Symbol Column -->
<!--    <ng-container matColumnDef="symbol">-->
<!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
<!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
<!--    </ng-container>-->

<!--     Action-->
    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'">

        <button *ngIf="centres[0].view || centres[0].all"  mat-icon-button color="primary" (click)="viewDialog(element.$key,element)" matTooltip="View" >
          <mat-icon >remove_red_eye</mat-icon>
        </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button *ngIf="centres[0].edit || centres[0].all"  (click)="editDialog(element.$key,element)" mat-icon-button color="accent"  matTooltip="Edit">
            <mat-icon>edit</mat-icon>
          </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <button *ngIf="centres[0].archive || centres[0].all" mat-icon-button (click)="archiveCentre(element)" color="warn" matTooltip="Archive">
          <mat-icon>archive</mat-icon>

        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>

    <!-- Row shown when there is no matching data. -->
    <tr *matNoDataRow class="mat-row">
<!--      <td class="mat-cell" colspan="4"  >No data matching the filter "{{input.value}}"</td>-->
    </tr>

  </table>

</section>
  <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
</div>

