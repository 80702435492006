<div class="container">
<!--  <div>-->
<!--    <BR>-->
<!--    <button color="accent" *ngIf="donor[0].all || donor[0].add" mat-raised-button id="create-center"-->
<!--            (click)="openpopup()">-->
<!--      <mat-icon>group_add</mat-icon>&nbsp;Donor-->
<!--    </button>&nbsp;-->
<!--  </div>-->
<!--  <br>-->

  <mat-form-field appearance="outline">
    <mat-label>Type</mat-label>
    <mat-select matNativeControl required (selectionChange)="onDonorType($event)">
      <mat-option *ngFor="let type of donorType"  value="{{type}}">{{type.donorType}}</mat-option>
    </mat-select>
  </mat-form-field>

<!--  <mat-form-field>-->
<!--    <mat-label>Filter</mat-label>-->
<!--    <input matInput (keyup)="applyFilter($event)" placeholder="Search here" #input>-->
<!--  </mat-form-field>-->
<div class="card p-2">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Donor</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button mat-mini-fab color="primary">
          <mat-icon class="col-white" (click)="openpopup()">add</mat-icon>
        </button>
      </li>
      <!--      <li>-->
      <!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
      <!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
      <!--        </div>-->
      <!--      </li>-->
    </ul>
  </div>

  <section class="example-container mat-elevation-z8" tabindex="0">
    <table mat-table [dataSource]="dataSource" matSort class="child-table" matSortDisableClear matSortDirection="desc">
      <!--  POSITION Column-->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="#"> Num.</th>
        <td mat-cell *matCellDef="let i = index">{{i + 1}}
          <!--          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}-->
        </td>
      </ng-container>

      <!-- Center Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let element">{{element.donorName}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef>Number</th>
        <td mat-cell *matCellDef="let element"> {{element.donorMobile}} </td>
      </ng-container>
      <!--      Child Name Column-->
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element"> {{element.donoraddress}} </td>
      </ng-container>

      <!--      Last Name Column-->
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef> City</th>
        <td mat-cell *matCellDef="let element"> {{element.donorcity  }} </td>
      </ng-container>

      <!-- City Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email</th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
      </ng-container>
      <!-- Pan Column -->
      <ng-container matColumnDef="pan">
        <th mat-header-cell *matHeaderCellDef> Pan</th>
        <td mat-cell *matCellDef="let element"> {{element.pan}} </td>
      </ng-container>

      <ng-container matColumnDef="donorType">
        <th mat-header-cell *matHeaderCellDef> Donor Type</th>
        <td mat-cell *matCellDef="let element"> {{element.donorType}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <!--    <ng-container matColumnDef="symbol">-->
      <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
      <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
      <!--    </ng-container>-->

      <!--     Action-->
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action</th>
        <td mat-cell *matCellDef="let element;let i = index">
          <div class="d-flex">
            <button mat-icon-button color="accent" *ngIf="donor[0].view || donor[0].all" aria-label="View Donor"
                    matTooltip="View Donor" (click)="ViewDonor(element)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>

            <button *ngIf="donor[0].edit ||donor[0].all " mat-icon-button color="primary" (click)="EditDonor(element)"
                    matTooltip="Edit Donor">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="donor[0].edit ||donor[0].all " mat-icon-button color="warn" (click)="DeleteDonor(element.id)"
                    matTooltip="Delete Donor">
              <mat-icon>delete</mat-icon>
            </button>


          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <!-- Row shown when there is no matching data. -->
      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>


    </table>

  </section>

  <mat-paginator [length]="200" [pageSizeOptions]="[25, 50, 100, 200, 500]" aria-label="Select page">
  </mat-paginator>
</div>

</div>


