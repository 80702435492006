import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Centre} from "../../../interfaces/Centres";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {CentreService} from "../../../services/centre.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Observable, of, Subject, takeUntil} from "rxjs";
import {ChildService} from "../../child/child.service";
import {FullChild} from "../../../interfaces/FullChild";
import {child} from "@angular/fire/database";
import {interactionSettingsToStore} from "@fullcalendar/core/internal";
import _default from "chart.js/dist/plugins/plugin.tooltip";
import numbers = _default.defaults.animations.numbers;
import {FormControl} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";
import firebase from "firebase/compat";
import {Hospitals} from "../../../interfaces/Hospitals";
import {HospitalService} from "../../../services/hospital.service";
import {end} from "@popperjs/core";

@Component({
    selector: 'app-mis',
    templateUrl: './mis.component.html',
    styleUrls: ['./mis.component.scss']
})
export class MisComponent implements OnInit, OnDestroy {
    mStartDate?: Date | null
    mEndDate?: Date | null
    show = false  //For refetching tables and graphs
    dataSource: MatTableDataSource<Centre>;
    centreSelectionArray = [
        {
            centreName: 'All Centres',
            centreCode: 'All'
        },
        {
            centreName: 'AL Bandra',
            centreCode: 'ALBandra'
        },
        {
            centreName: 'AL Ahmedabad',
            centreCode: 'ALAhmedabad'
        },
        {
            centreName: 'AL Bangalore',
            centreCode: 'ALBangalore'
        },
        {
            centreName: 'AL Chandigarh',
            centreCode: 'ALChandigarh'
        }, {
            centreName: 'AL Gurunanak Niwas',
            centreCode: 'ALGurunanak'
        },
        {
            centreName: 'AL Diamond Garden',
            centreCode: 'ALDiamondGarden'
        },
        {
            centreName: 'AL Manipal',
            centreCode: 'ALManipal'
        }, {
            centreName: 'AL Pune',
            centreCode: 'ALPune'
        },

    ]
    userData: any
    private _destroyed$ = new Subject()
    mCentres: Centre[] = []
    allCentres: Centre[] = [];
    extraRowAdded = false
    mChildren: FullChild[] = []
    mAllChildren: FullChild[] = []
    mAdmissionChartCentres: string[] = []
    mAdmissionChartUniqueCount: number[] = []
    mAdmissionChartFollowUpCount: number[] = []
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) matSort!: MatSort;
    displayedColumns: String[] = ['centername', 'totalunits', 'occupiedunits', 'availableunits', 'percentage',];
    //*********** For Occupancy card******************
    totalUnits = 0
    totalOccupiedUnits = 0
    totalAvailableUnits = 0
//***********************
//************CHILD DATA************
    totalRegisteredChildren = 0
    totalRegisteredGirls = 0
    totalRegisteredBoys = 0
    freshBoys = 0
    freshGirls = 0
    followupBoys = 0
    followupGirls = 0
//*********************************
    ageArray: number[] = []
    mFollowUpAgeArray: number[] = []
    a = 0
    af = 0
    b = 0
    bf = 0
    c1 = 0
    cf = 0
    d = 0
    df = 0
    e = 0
    ef = 0
    HospitalLables: any[] = []
    HospitalCount: any[] = []
    mFollowUpHospitalCount: any[] = []
    hosptalLables$ = of(this.HospitalLables)
    hospitalCount$ = of(this.HospitalCount)
    mFollowUpHospitalCount$ = of(this.mFollowUpHospitalCount)
    hData: any[] = []
    sortBy: "byDate" | 'byYear' | 'byCentre' = 'byCentre'
    startDate: Date = new Date('2023-04-01')
    endDate: Date = new Date('2023-06-30')
    selectCentre = new FormControl('All')
    sortBySelect = new FormControl(this.sortBy)
    loading =false
    currentCentreName = 'All Centres'
    selectedCentreOption: any;
    uniqueChildrenCount: any;
    followUpChildrenCount: any;
    totalAdmissionCount = 0;
    followUpAdmissionsCount = 0
    uniqueAdmissionsCount = 0;
    showAdmissionChart = false;
    hospitals$ :Observable<Hospitals[]>
    mHospitals:Hospitals[]=[]

    constructor(private matdialog: MatDialog,
                private centreService: CentreService,
                private childService: ChildService,
                private mDatabase: AngularFireDatabase,
                private _snackBar: MatSnackBar,
                private mHospitalService:HospitalService,
                private readonly mFirestore: AngularFirestore) {

        this.dataSource = new MatTableDataSource(this.allCentres);
        this.userData = JSON.parse(localStorage.getItem('userData')!)
        this.hospitals$=this.mHospitalService.getHospitalList()
        this.hospitals$.subscribe(res=>{
            this.mHospitals=res
            // console.log(this.mHospitals)
        })

    }

    ngOnInit(): void {

        // this.getAllCentresData()
        //* TO GET ALL CENTRES DATA
        this.initAllCentresData('All')
        // this.getAllCentresData('All');

        this.initChildData()
    }

    ngAfterViewInit() {
        //  this.matSort.sortChange.pipe(takeUntil(this._destroyed$)).subscribe(() => (this.paginator.pageIndex = 0));
        //Read

    }

//* GET ALL CENTRES DATA


    //* On ngInit to get all centres data
    lastRow?: Centre;


    private initAllCentresData(centreCode: string) {
        console.log('getAllCentre Called with:', centreCode);

        if (centreCode === 'All') {
            // At startup when centreCode == "All"
            this.centreService.getAllCentres().pipe(takeUntil(this._destroyed$))
                .subscribe(centres => {
                    this.allCentres = centres;
                    this.mCentres = this.allCentres
                    // console.log('CENTRE LIST TABLE', centres);

                    this.totalUnits = 0;
                    this.totalAvailableUnits = 0;
                    this.totalOccupiedUnits = 0;

                    this.allCentres.forEach(c => {
                        this.totalUnits += +c.totalUnits!;
                        this.totalAvailableUnits += +c.availableUnits!;
                        this.totalOccupiedUnits += +c.occupiedUnits!;
                    });

                    let totalName = 'Total';

                    this.dataSource = new MatTableDataSource(this.allCentres);

                    this.dataSource.paginator = this.paginator;
                    this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
                });
        } else {
            console.log('Into getAllCentresData with centreCode ==', centreCode);
            this.mCentres = this.allCentres.filter(c => c.centreCode === centreCode);
            console.log('CENTRE LIST TABLE', this.mCentres);

            this.totalUnits = 0;
            this.totalAvailableUnits = 0;
            this.totalOccupiedUnits = 0;

            this.mCentres.forEach(c => {
                this.totalUnits += +c.totalUnits!;
                this.totalAvailableUnits += +c.availableUnits!;
                this.totalOccupiedUnits += +c.occupiedUnits!;
            });
            let totalName = 'Total';
            this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
            this.dataSource = new MatTableDataSource(this.mCentres);
            this.dataSource.paginator = this.paginator;
        }
    }
    private initAllCentresDataGPT(centreCode: string) {
        console.log('getAllCentre Called with:', centreCode);

        if (centreCode === 'All') {
            this.fetchAllCentresData();
        } else {
            this.fetchSpecificCentreData(centreCode);
        }
    }

    private fetchAllCentresData() {
        this.centreService.getAllCentres().pipe(takeUntil(this._destroyed$))
            .subscribe(centres => {
                this.allCentres = centres;
                this.mCentres =centres
                this.updateCentreData(this.allCentres);
                this.prepareDataSource(this.allCentres);
            });
    }

    private fetchSpecificCentreData(centreCode: string) {
        console.log('Into getAllCentresData with centreCode ==', centreCode);
        this.mCentres = this.allCentres.filter(c => c.centreCode === centreCode);
        console.log('CENTRE LIST TABLE', this.mCentres);

        this.updateCentreData(this.mCentres);
        this.prepareDataSource(this.mCentres);
    }

    private updateCentreData(centres: Centre[]) {
        this.totalUnits = centres.reduce((total, c) => total + (+c.totalUnits! || 0), 0);
        this.totalAvailableUnits = centres.reduce((total, c) => total + (+c.availableUnits! || 0), 0);
        this.totalOccupiedUnits = centres.reduce((total, c) => total + (+c.occupiedUnits! || 0), 0);
    }

    private prepareDataSource(centres: Centre[]) {
        let totalName = 'Total';
        if (centres.length > 0) {
            this.dataSource = new MatTableDataSource(centres);
            this.dataSource.paginator = this.paginator;
            // console.log('')
            this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);

        }

    }




    //* ON DATA INIT
    private initChildData() {
        this.totalAdmissionCount=0
        this.totalRegisteredBoys = 0;
        this.totalRegisteredGirls = 0;

        this.childService.getLatestData()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(children => {
                this.totalRegisteredChildren = children.docs.length;
                this.mAllChildren = children.docs.map(c => c.data());
                this.mChildren = [...this.mAllChildren]; // Clone the array

                let freshBoys = 0;
                let freshGirls = 0;
                let followupBoys = 0;
                let followupGirls = 0;
                let totalAdmissions = 0;
                let uniqueAdmissions = 0;
                let followUpAdmissions = 0;

                this.mAllChildren.forEach(child => {
                    const admissions = child.admissionData.length;
                    //TO CALCULATE TOTAL ADMISSION ONLY
                    child.admissionData.forEach((y, i) => {
                        if (i % 2 == 0) this.totalAdmissionCount++
                    })
                    if (child.gender === 'Male') {
                        this.totalRegisteredBoys++;
                        if (admissions <= 2) freshBoys++;
                        else followupBoys += admissions > 2 ? 1 : 0;
                    } else if (child.gender === 'Female') {
                        this.totalRegisteredGirls++;
                        if (admissions <= 2) freshGirls++;
                        else followupGirls += admissions > 2 ? 1 : 0;

                    }

                    uniqueAdmissions += admissions > 0 ? 1 : 0;
                    followUpAdmissions += admissions > 2 ? Math.floor((admissions - 2) / 2) : 0;
                });

                this.uniqueChildrenCount = this.mAllChildren.filter(x => x.admissionData.length <= 2).length;
                this.followUpChildrenCount = followUpAdmissions;
                this.uniqueAdmissionsCount = uniqueAdmissions;
                this.freshBoys = freshBoys;
                this.freshGirls = freshGirls;
                this.followupBoys = followupBoys;
                this.followupGirls = followupGirls;

                const followUpChildrens = this.mAllChildren.filter(x => x.admissionData.length > 2);
                this.followUpAdmissionsCount = followUpChildrens.length
                this.fetchHospitalData(this.mChildren); // Data for hospital widget
                this.fetchAgeTableData(this.mChildren, followUpChildrens); // Data for age table widget
                this.fetchAdmissionChartData(this.mChildren); // Data for admission chart
            });
    }

    private initChildData2() {
        this.resetCounts();
        console.log('INIT CHILD DATA')
        this.childService.getLatestData()
            .pipe(takeUntil(this._destroyed$))
            .subscribe(children => {
                this.totalRegisteredChildren = children.docs.length;
                this.mAllChildren = children.docs.map(c => c.data());
                this.mChildren = [...this.mAllChildren]; // Clone the array

                let freshBoys = 0, freshGirls = 0, followupBoys = 0, followupGirls = 0;
                let uniqueAdmissions = 0, followUpAdmissions = 0;

                this.mAllChildren.forEach(child => {
                    const admissions = child.admissionData.length;

                    child.admissionData.forEach((y, i) => {
                        if (i % 2 == 0) this.totalAdmissionCount++;
                    });

                    if (child.gender === 'Male') {
                        this.totalRegisteredBoys++;
                        if (admissions <= 2) freshBoys++;
                        else followupBoys += admissions > 2 ? 1 : 0;
                    } else if (child.gender === 'Female') {
                        this.totalRegisteredGirls++;
                        if (admissions <= 2) freshGirls++;
                        else followupGirls += admissions > 2 ? 1 : 0;
                    }

                    uniqueAdmissions += admissions > 0 ? 1 : 0;
                    followUpAdmissions += admissions > 2 ? Math.floor((admissions - 2) / 2) : 0;
                });

                this.uniqueChildrenCount = this.mAllChildren.filter(x => x.admissionData.length <= 2).length;
                this.followUpChildrenCount = followUpAdmissions;
                this.uniqueAdmissionsCount = uniqueAdmissions;
                this.freshBoys = freshBoys;
                this.freshGirls = freshGirls;
                this.followupBoys = followupBoys;
                this.followupGirls = followupGirls;
                this.followUpAdmissionsCount=this.followupBoys+this.followupGirls
                const followUpChildrens = this.mAllChildren.filter(x => x.admissionData.length > 2);
                setTimeout(() => {
                    this.fetchHospitalData(this.mChildren); // Data for hospital widget
                    this.fetchAgeTableData(this.mChildren, followUpChildrens); // Data for age table widget
                    this.fetchAdmissionChartData(this.mChildren); // Data for admission chart
                }, 1000);

            });
    }

    private resetCounts() {
        this.totalAdmissionCount = 0;
        this.totalRegisteredBoys = 0;
        this.totalRegisteredGirls = 0;
        this.followUpAdmissionsCount =0
        this.uniqueAdmissionsCount =0
    }


    onCentreSelection($event: MatSelectChange) {
        this.totalRegisteredBoys = 0;
        this.totalRegisteredGirls = 0;
        this.followUpChildrenCount = 0;
        this.uniqueAdmissionsCount = 0;
        this.followUpAdmissionsCount = 0;
        this.totalAdmissionCount = 0;
        this.currentCentreName = this.allCentres.find(res => res.centreCode === $event.value)?.centreName || ' ';
        this.show = false;
        let c = $event.value;
        this.totalUnits = 0;
        this.totalOccupiedUnits = 0;
        this.totalAvailableUnits = 0;

        if (this.mStartDate == null && this.mEndDate == null) {
            // this._snackBar.open('dates are null running Get All Child Data by Centre')._dismissAfter(3000)
            this.getAllCentresData(c);
            this.getAllChildDataByCentre(c);
        }

        if (this.mStartDate != null && this.mEndDate != null) {
            this.getAllCentresDataRange(c);
            this.getAllChildDataByCentreRange(c, this.mStartDate!, this.mEndDate!);
        }
    }

    private getAllCentresData(centreCode: string) {

        // console.log('getAllCentre Called with:', centreCode)
        // this.centreService.getCentreByCode(centreCode)
        if (centreCode == 'All') {
//? At startup when centreCode == "All"

            // this.allCentres = []
            // console.log('CENTRE LIST TABLE', centres)
            // this.allCentres = centres
            this.mCentres = this.allCentres
            this.totalUnits = 0
            this.totalAvailableUnits = 0
            this.totalOccupiedUnits = 0
            this.mCentres.forEach(c => {
                this.totalUnits = this.totalUnits += +c.totalUnits!
                this.totalAvailableUnits = this.totalAvailableUnits += +c.availableUnits!
                this.totalOccupiedUnits = this.totalOccupiedUnits += +c.occupiedUnits!
            })

            let totalName = 'Total'
            this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
            this.dataSource = new MatTableDataSource(this.allCentres);
            this.dataSource.paginator = this.paginator;
            // this.getTotalUnitsCount(this.allCentres)


            // console.log('allCentres if', this.allCentres.length)
        } else if (centreCode != 'All') {
            {
                // console.log('Into getAllCentresData with centreCode ==', centreCode)
                this.mCentres = this.allCentres.filter(c => c.centreCode == centreCode)
                // console.log('CENTRE LIST TABLE', this.mCentres)
                let totalCentres = 0
                this.totalUnits = 0
                this.totalAvailableUnits = 0
                this.totalOccupiedUnits = 0
                this.allCentres.forEach(c => {
                    this.totalUnits = this.totalUnits += +c.totalUnits!
                    this.totalAvailableUnits = this.totalAvailableUnits += +c.availableUnits!
                    this.totalOccupiedUnits = this.totalOccupiedUnits += +c.occupiedUnits!
                })
                let totalName = 'Total'
                this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
                this.dataSource = new MatTableDataSource(this.mCentres);
                this.dataSource.paginator = this.paginator;
                //    this.getTotalUnitsCount(this.allCentres)


                // console.log('allCentres else', this.allCentres.length)
            }
        }


    }

    private getAllCentresDataRange(centreCode: string) {

        // console.log('getAllCentre Called')

        if (centreCode == 'All') {

            // console.log('CENTRE LIST TABLE', this.allCentres)
            this.mCentres = this.allCentres
//Calculating total units
            this.totalUnits = 0
            this.totalAvailableUnits = 0
            this.totalOccupiedUnits = 0
            this.mCentres.forEach(c => {
                this.totalUnits = this.totalUnits += +c.totalUnits!
                this.totalAvailableUnits = this.totalAvailableUnits += +c.availableUnits!
                this.totalOccupiedUnits = this.totalOccupiedUnits += +c.occupiedUnits!
            })

            let totalName = 'Total'
            this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
            this.dataSource = new MatTableDataSource(this.mCentres);
            this.dataSource.paginator = this.paginator;
            // this.getTotalUnitsCount(this.allCentres)


            // console.log('allCentres if', this.allCentres.length)
        } else if (centreCode != 'All') {
            {

                // console.log('CENTRE LIST TABLE', this.mCentres)
                this.mCentres = this.allCentres.filter(c => c.centreCode == centreCode)
                this.totalUnits = 0
                this.totalAvailableUnits = 0
                this.totalOccupiedUnits = 0
                this.mCentres.forEach(c => {
                    this.totalUnits = this.totalUnits += +c.totalUnits!
                    this.totalAvailableUnits = this.totalAvailableUnits += +c.availableUnits!
                    this.totalOccupiedUnits = this.totalOccupiedUnits += +c.occupiedUnits!
                })
                let totalName = 'Total'
                this.addextraRowtoOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, totalName);
                this.dataSource = new MatTableDataSource(this.mCentres);
                this.dataSource.paginator = this.paginator;
                //    this.getTotalUnitsCount(this.allCentres)


                // console.log('allCentres else', this.allCentres.length)
            }
        }

    }

    private addextraRowtoOccupancyTable(totalUnits: number, totalAvailableUnits: number, totalOccupiedUnits: number, totalCentres: string) {
        totalUnits = 0
        totalAvailableUnits = 0
        totalOccupiedUnits = 0
        console.log(this.mCentres)
        this.mCentres.forEach(c => {
            totalUnits = totalUnits += c.totalUnits!
            totalAvailableUnits = totalAvailableUnits += c.availableUnits!
            totalOccupiedUnits = totalOccupiedUnits += c.occupiedUnits!

        })
        let c = {
            centreName: totalCentres,
            availableUnits: totalAvailableUnits,
            occupiedUnits: totalOccupiedUnits,
            totalUnits: totalUnits
        }
        this.lastRow = c as unknown as Centre
        // if(!this.extraRowAdded)     this.mCentres.push(this.lastRow)
        this.extraRowAdded = true

    }


    ngOnDestroy(): void {
        // @ts-ignore
        this._destroyed$.next()
        this._destroyed$.complete()
    }


    private fetchAgeTableData(fullChildren: FullChild[], FollowUpChildrens: FullChild[]) {
        // * console.log('into age calculation***')
        this.ageArray = []
        this.mFollowUpAgeArray = []
        this.a = 0
        this.af = 0
        this.b = 0
        this.bf = 0
        this.c1 = 0
        this.cf = 0
        this.d = 0
        this.df = 0
        this.e = 0
        this.ef = 0
        fullChildren.forEach(c => {
            if (c.age == null) {
                //    console.log('INVALID DOB', c.firstName + ' ' + c.lastName)
            }
            if (c.age != null) {
                if (c.age <= 3 && c.age > 0) {
                    this.a++
                } else if (c.age >= 4 && c.age <= 7) {
                    this.b++
                } else if (c.age >= 8 && c.age <= 10) {
                    this.c1++
                } else if (c.age >= 11 && c.age <= 14) {
                    this.d++
                } else this.e++
            }
        })
        // this._snackBar.open(fullChildren.length.toString(),'')
        alert('Followup in age '+ FollowUpChildrens.length)
        FollowUpChildrens.forEach(c => {
            if (c.age == null) {
                //    console.log('INVALID DOB', c.firstName + ' ' + c.lastName)
            }
            if (c.age != null) {
                if (c.age <= 3 && c.age > 0) {
                    this.af++
                } else if (c.age >= 4 && c.age <= 7) {
                    this.bf++
                } else if (c.age >= 8 && c.age <= 10) {
                    this.cf++
                } else if (c.age >= 11 && c.age <= 14) {
                    this.df++
                } else this.ef++
            }
        })
        //* console.log('a', this.a)
        //* console.log('b', this.b)
        //* console.log('c1', this.c1)
        //* console.log('d', this.d)
        //* console.log('e', this.e)
        if (this.a != undefined) this.ageArray.push(this.a)
        if (this.b != undefined) this.ageArray.push(this.b)
        if (this.c1 != undefined) this.ageArray.push(this.c1)
        if (this.d != undefined) this.ageArray.push(this.d)
        if (this.e != undefined) this.ageArray.push(this.e)

        if (this.af != undefined) this.mFollowUpAgeArray.push(this.af)
        if (this.bf != undefined) this.mFollowUpAgeArray.push(this.bf)
        if (this.cf != undefined) this.mFollowUpAgeArray.push(this.cf)
        if (this.df != undefined) this.mFollowUpAgeArray.push(this.df)
        if (this.ef != undefined) this.mFollowUpAgeArray.push(this.ef)
        this.show = true
    }

    //? Sending this data to hospital Widget
    private fetchHospitalData2(children: FullChild[]) {
        // console.log('into hospital ', children.length)
        // alert('Hospital Data '+children.length)
        this.show = false
        // console.log('INTO HOSPITAL BAR')
        this.HospitalLables = []
        this.HospitalCount = []
        //* JMCH
        let JMCH = children.filter((child, i) => child.hospital == 'St Johns Medical College Hospital')
        // console.log('Bharti', Bharati.length)
        this.HospitalLables.push("JMCH")
        this.HospitalCount.push(JMCH.length)
        this.hData.push({data: [JMCH.length], label: 'JMCH'})


//* KASTURBA
        let kasturba = children.filter((child, i) => child.hospital == 'Kasturba Hospital' || child.hospital == 'Kasturba Manipal Hospital')
        this.HospitalLables.push("Kasturba")
        this.HospitalCount.push(kasturba.length)
        // console.log('KASTURBA', kasturba.length)
        this.hData.push({data: [kasturba.length], label: 'KMC'})
//* LTMGH
        let LTMGH = children.filter((child, i,) => child.hospital == 'Lokmanya Tilak Municipal General Hospital' || child.hospital == 'LTMGH' || child.hospital == 'LTMG Hospital, Sion' || child.hospital == "Lokmanya Tilak Municipal General Hospital" || child.hospital == 'LTMG Hospital, Sion')
        this.HospitalLables.push("LTMGH")
        this.HospitalCount.push(LTMGH.length)
        // console.log('LTMGH', LTMGH.length)
        this.hData.push({data: [LTMGH.length], label: 'LTMGH'})

//* BHARTI
        let Bharati = children.filter((child, i) => child.hospital == 'Bharti' || child.hospital == "Bharati Hospital & Research Center" || child.hospital == "Bharati Hospital & Research Center")
        // console.log('Bharti', Bharati.length)
        this.HospitalLables.push("Bharati")
        this.HospitalCount.push(Bharati.length)
        this.hData.push({data: [Bharati.length], label: 'Bharati'})

        //* SASOON
        let SASSON = children.filter((child, i) => child.hospital == 'Sassoon General Hospital')
        // console.log('Bharti', Bharati.length)
        this.HospitalLables.push("SASOON")
        this.HospitalCount.push(SASSON.length)
        this.hData.push({data: [SASSON.length], label: 'SASOON'})

        //    console.log('hospitalLables', this.HospitalLables.length)
        //     console.log('hospitalCount', this.HospitalCount.length)
//* TMH
        let TMH = children.filter((child, i) => child.hospital == 'TMH' || child.hospital == 'Tata Memorial Hospital' || child.hospital == 'TMH Mumbai')
        //    console.log('TMH', TMH.length)
        this.HospitalLables.push("TMH")
        this.HospitalCount.push(TMH.length)
//*Wadia

        let WADIA = children.filter((child, i) => child.hospital == 'WADIA' || child.hospital == 'WADIA ' || child.hospital == 'Wadia - Bai Jerbai Wadia Hospital for Children' || child.hospital == 'Bai Jerbai Wadia Hospital for Children')
        //    console.log('WADIA', WADIA.length)
        this.HospitalLables.push("Wadia")
        this.HospitalCount.push(WADIA.length)

        //*NAIR

        let NAIR = children.filter((child, i) => child.hospital == 'NAIR' || child.hospital == "BYL Nair Hospital")
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("Nair")
        this.HospitalCount.push(NAIR.length)

//* KDHRI
        let KDAHRI = children.filter((child, i) => child.hospital == 'Kokilaben Dhirubhai Ambani Hospital & Research Institute')
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("KDAHRI")
        this.HospitalCount.push(KDAHRI.length)
//*KJHRC
        let KJSHRC = children.filter((child, i) => child.hospital == 'K J Somaiya Hospital & Research Center')
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("KJSHRC")
        this.HospitalCount.push(KJSHRC.length)
//*CTC
        let CTC = children.filter((child, i) => child.hospital == 'CTC , PHO & BMT Centre of MCGM')
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("CTC")
        this.HospitalCount.push(CTC.length)


//* SRCC
        let SRCC = children.filter((child, i) => child.hospital == "NH SRCC Children\'s Hospital" || child.hospital == "NH SRCC Children\'s Hospital" || child.hospital == "BH SRCC Children's Hospital")
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("SRCC")
        this.HospitalCount.push(SRCC.length)
//* BHSRCC
        let BHSRCC = children.filter((child, i) => child.hospital == "BH SRCC Children's Hospital")
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("BHSRCC")
        this.HospitalCount.push(BHSRCC.length)


//* DMHRC
        let DMHRC = children.filter((child, i) => child.hospital == "Deenanath Mangeshkar Hospital and Research Center")
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("DMHRC")
        this.HospitalCount.push(DMHRC.length)

//* KEM
        let KEM = children.filter((child, i) => child.hospital == "King Edward Memorial Hospital")
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("KEM")
        this.HospitalCount.push(KEM.length)
//* PGIMER
        let PGIMER = children.filter((child, i) => child.hospital == "Post Graduate Institute of Medical Education & Research")
        //   console.log('NAIR', NAIR.length)
        this.HospitalLables.push("PGIMER")
        this.HospitalCount.push(PGIMER.length)
        this.hospitalCount$
        this.show = true

    }
    private fetchHospitalDatax(children: FullChild[]) {
        this.resetHospitalData();
        this.show = false;

        const hospitals = [
            { name: 'St Johns Medical College Hospital', label: 'JMCH' },
            { name: 'Kasturba Hospital', label: 'Kasturba' },
            { name: 'Lokmanya Tilak Municipal General Hospital', label: 'LTMGH' },
            { name: 'Bharti', label: 'Bharati' },
            { name: 'Sassoon General Hospital', label: 'SASOON' },
            { name: 'TMH', label: 'TMH' },
            { name: 'WADIA', label: 'Wadia' },
            { name: 'NAIR', label: 'Nair' },
            { name: 'Kokilaben Dhirubhai Ambani Hospital & Research Institute', label: 'KDAHRI' },
            { name: 'K J Somaiya Hospital & Research Center', label: 'KJSHRC' },
            { name: 'CTC , PHO & BMT Centre of MCGM', label: 'CTC' },
            { name: "NH SRCC Children's Hospital", label: 'SRCC' },
            { name: "BH SRCC Children's Hospital", label: 'BHSRCC' },
            { name: 'Deenanath Mangeshkar Hospital and Research Center', label: 'DMHRC' },
            { name: 'King Edward Memorial Hospital', label: 'KEM' },
            { name: 'Post Graduate Institute of Medical Education & Research', label: 'PGIMER' },
        ];

        const hospitals3 = this.mHospitals.map(x => ({ name: x.hospitalName, label: x.shortName }));
        // alert(  children[0].hospital)
        hospitals3.forEach(it=>{
            hospitals.push(it)
        })
        hospitals.forEach(hospital => {

            const filteredChildren = children.filter(child => child.hospital.includes(hospital.name));
            if (filteredChildren.length > 0) {
                this.HospitalLables.push(hospital.label);
                this.HospitalCount.push(filteredChildren.length);
                this.hospitalCount$ =of(this.HospitalCount)
                this.hosptalLables$ =of(this.HospitalLables)
                this.hospitalCount$.subscribe(res=>this.HospitalCount)
                this.hosptalLables$.subscribe(res=>this.HospitalLables)
                this.hData.push({ data: [filteredChildren.length], label: hospital.label });
            }

        });
        this.show = true;

    }
    private fetchHospitalData(children: FullChild[]) {
        this.resetHospitalData();
        this.show = false;
        let uniqueChildren  :FullChild[]=[]
        let followUpChildren  :FullChild[]=[]

        const hospitals = this.mHospitals.map(x => ({ name: x.hospitalName, label: x.shortName }));

        hospitals.forEach(hospital => {

            if(hospital.name)  {
                try {
                    // children.forEach(it=>{
                    // // if(it.hospital ===undefined || it.hospital=='null' ||it.hospital=='')  console.log(`HOSPITAL ISSUE  ${it.childCode} `)
                    // })
                    uniqueChildren =   children.filter(child => child.hospital != null  && child.hospital.includes(hospital.name) && child.admissionData.length<=2 );
                    followUpChildren =   children.filter(child => child.hospital != null  && child.hospital.includes(hospital.name) && child.admissionData.length>2);

                }catch (e:any){
                    console.log( `${e.message} }`)
                }


                if (uniqueChildren.length > 0) {

                    this.HospitalLables.push(hospital.label);
                    this.HospitalCount.push(uniqueChildren.length);
                    this.mFollowUpHospitalCount.push(followUpChildren.length)
                    console.log(hospital.name,followUpChildren.length)
                    this.hData.push({data: [uniqueChildren.length,followUpChildren.length], label: hospital.label});

                }
            }

        });
        this.show = true;

    }


    private resetHospitalData() {
        this.show = false;
        this.HospitalLables = [];
        this.HospitalCount = [];
        this.mFollowUpHospitalCount=[]
        this.hData = [];
    }


//? On centre selection


//* ON CENTRE CHANGE ONLY
    private getAllChildDataByCentre2(centreCode: string) {
        this._snackBar.open(`GET CHILD DATA BY ${centreCode} `)._dismissAfter(3000)
        let childData = this.mAllChildren


        if (centreCode != 'All') {
            childData = this.mAllChildren.filter((x) => x.centreCode == centreCode)
        }


        // this._snackBar.open(children.docs.length.toString());

        this.totalRegisteredChildren = childData.length
        this.totalRegisteredBoys = childData.filter(x => x.gender == 'Male').length;
        this.totalRegisteredGirls = childData.filter(x => x.gender == 'Female').length;

        this.uniqueChildrenCount = childData.filter(x => x.admissionData.length <= 2).length;
        this.followUpChildrenCount = childData.filter(x => x.admissionData.length > 2).length;

        this.freshBoys = childData.filter(x => x.admissionData.length <= 2 && x.gender == 'Male').length;
        this.freshGirls = childData.filter(x => x.admissionData.length <= 2 && x.gender == 'Female').length;
        this.followupBoys = childData.filter(x => x.admissionData.length > 2 && x.gender == 'Male').length;
        this.followupGirls = childData.filter(x => x.admissionData.length > 2 && x.gender == 'Female').length;
        let followupAdmissions = childData //* All children Data
        this.totalAdmissionCount = 0
        this.followUpAdmissionsCount = 0
        followupAdmissions.forEach(it => {
            it.admissionData.forEach((y, i) => {
                if (i == 0) {
                    this.uniqueAdmissionsCount++
                }
                if (i % 2 == 0) this.totalAdmissionCount++

                if (i >= 2 && i % 2 == 0) {
                    this.followUpAdmissionsCount++
                }
            })
        })
        let FollowUpChildrens: FullChild[] = []
        FollowUpChildrens = childData.filter(x => x.admissionData.length >= 2)

        setTimeout(() => {
            this.fetchHospitalData(childData);
            this.fetchAgeTableData(childData, FollowUpChildrens);
        }, 100);


    }


    //*  MIX SEARCH
    private mixSearch(centreCode: string) {
        this._snackBar.open(`MIX GET CHILD DATA BY ${centreCode} `)._dismissAfter(3000);

        this.mChildren = this.mAllChildren;

        if (centreCode != 'All') {
            this.mChildren = this.mAllChildren.filter(child => child.centreCode === centreCode);

        }
 //* To get total registere Children Boys and Girls
        this.totalRegisteredChildren = this.mChildren.length;  //*TOTAL REGISTERED CHILDREN
        this.totalRegisteredBoys = this.mChildren.filter(child => child.gender === 'Male').length;
        this.totalRegisteredGirls = this.mChildren.filter(child => child.gender === 'Female').length;
        this.freshBoys = this.mChildren.filter(child => child.admissionData.length <= 2 && child.gender === 'Male').length;
        this.freshGirls = this.mChildren.filter(child => child.admissionData.length <= 2 && child.gender === 'Female').length;

        //*---------------------------------------------------------------------------------------------------------
        this.uniqueChildrenCount = this.mChildren.filter(child => child.admissionData.length <= 2).length;

        this.followUpChildrenCount = this.mChildren.filter(child=>child.admissionData.length>2).length;
        const followUpChildrens = this.mChildren.filter(child => child.admissionData.length > 2);
        this.followupBoys = followUpChildrens.filter(child => child.gender === 'Male').length;
        this.followupGirls = followUpChildrens.filter(child => child.gender === 'Female').length;
        this.uniqueAdmissionsCount = this.freshBoys+this.freshGirls
        let totalAdmissionsCount = 0;
        let followUpAdmissionsCount = 0;

        this.mChildren.forEach(child => {
            totalAdmissionsCount +=  child.admissionData.length;
            followUpAdmissionsCount += Math.max(0, child.admissionData.length - 2);
        });

        this.followUpAdmissionsCount = followUpAdmissionsCount;

        this.totalAdmissionCount = this.followUpAdmissionsCount+this.uniqueAdmissionsCount;

        setTimeout(() => {

            console.log(`${this.mChildren.length} ${followUpChildrens.length}`)

            this.fetchHospitalData(this.mChildren);
            this.fetchAgeTableData(this.mChildren, followUpChildrens);
        }, 100);
    }
    private getAllChildDataByCentre(centreCode: string) {
        this.totalRegisteredBoys = 0;
        this.totalRegisteredGirls = 0;
        this.followUpChildrenCount = 0;
        this.uniqueAdmissionsCount = 0;
        this.followUpAdmissionsCount = 0;
        this.totalAdmissionCount = 0;
        this._snackBar.open(`GET CHILD DATA BY ${centreCode} `)._dismissAfter(3000);

        let filteredChildData = this.mAllChildren;

        if (centreCode != 'All') {
            filteredChildData = this.mAllChildren.filter(child => child.centreCode === centreCode);

        }

        this.totalRegisteredChildren = filteredChildData.length;
        const followUpChildrens = filteredChildData.filter(child => child.admissionData.length > 2);

        this.totalRegisteredBoys = filteredChildData.filter(child => child.gender === 'Male').length;
        this.totalRegisteredGirls = filteredChildData.filter(child => child.gender === 'Female').length;

        this.uniqueChildrenCount = filteredChildData.filter(child => child.admissionData.length <= 2).length;
        this.followUpChildrenCount = filteredChildData.filter(child=>child.admissionData.length>2).length;

        this.freshBoys = filteredChildData.filter(child => child.admissionData.length <= 2 && child.gender === 'Male').length;
        this.freshGirls = filteredChildData.filter(child => child.admissionData.length <= 2 && child.gender === 'Female').length;
        this.followupBoys = followUpChildrens.filter(child => child.gender === 'Male').length;
        this.followupGirls = followUpChildrens.filter(child => child.gender === 'Female').length;

        let totalAdmissionsCount = 0;
        let followUpAdmissionsCount = 0;

        filteredChildData.forEach(child => {
            totalAdmissionsCount +=  child.admissionData.length;
            followUpAdmissionsCount += Math.max(0, child.admissionData.length - 2);
        });

        this.followUpAdmissionsCount = followUpAdmissionsCount;
        this.uniqueAdmissionsCount = this.freshBoys+this.freshGirls
        this.totalAdmissionCount = this.followUpAdmissionsCount+this.uniqueAdmissionsCount;
        setTimeout(() => {

            console.log(`${filteredChildData.length} ${followUpChildrens.length}`)

            this.fetchHospitalData(filteredChildData);
            this.fetchAgeTableData(filteredChildData, followUpChildrens);
        }, 100);
    }


//*OLD

//* Calculating by startdate and enddate in centre
    private getAllChildDataByCentreRange(centreCode: string, startDate: Date, endDate: Date) {
        this.totalRegisteredBoys = 0;
        this.totalRegisteredGirls = 0;
        this.followUpChildrenCount = 0;
        this.uniqueAdmissionsCount = 0;
        this.followUpAdmissionsCount = 0;
        this.totalAdmissionCount = 0;
        let uniqueArray: string[] = []
        let childData: FullChild[] = []
        if (centreCode == 'All') {
            childData = this.mAllChildren
            // query = this.childService.getRegisteredChildListByDateRange(this.mStartDate!, this.mEndDate!);
        }
        //* calculating data for particular centre
        else {
            childData = this.mAllChildren.filter((x) => x.centreCode == centreCode)
            // query = this.childService.getRegisteredChildListByDateRangeAndCentre(centreCode, this.mStartDate!, this.mEndDate!);
            this._snackBar.open('Calculating by centre and date : ' + centreCode)._dismissAfter(5000)
        }
        // alert(childData.filter((x)=>x.admissionData[0].dateOfAdmission!.toDate()>=this.mStartDate! && x.admissionData[0].dateOfAdmission!.toDate()<=this.mStartDate!).length)

        let arr = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!)
        arr.forEach(it => {
            uniqueArray.push(`${it.firstName} ${it.lastName}`)
        })
        this.uniqueAdmissionsCount = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!).length
        this.freshBoys = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Male').length
        this.freshGirls = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Female').length
        this.followupBoys = 0
        this.followupGirls = 0
        // this.freshBoys = childData.filter(x => x.admissionData.length <= 2 && x.gender === 'Male').length;
        // this.freshGirls = childData.filter(x => x.admissionData.length <= 2 && x.gender === 'Female').length;
        // this.followupBoys = childData.filter(x => x.admissionData.length > 2 && x.gender === 'Male').length;
        // this.followupGirls = childData.filter(x => x.admissionData.length > 2 && x.gender === 'Female').length;
//* Calculating followup boys and girls
        childData.forEach(it => {
                it.admissionData.forEach((y, i) => {
                    if (i >= 2 && i % 2 == 0) {
                        if (y.dateOfAdmission!.toDate() >= startDate! && y.dateOfAdmission!.toDate() <= endDate!) {
                            this.logInvalidCentre(it)
                            this.followUpAdmissionsCount++;
                            it.gender == 'Male' ? this.followupBoys++ : this.followupGirls++
                            if(it.gender!="Male" && it.gender !='Female') console.log('MISSING GENDER',it.childCode)
                        }
                    }
                });

            }
        );

        this.totalAdmissionCount = this.uniqueAdmissionsCount + this.followUpAdmissionsCount
        console.log('uniqueChildrenCount', this.uniqueChildrenCount)
        console.log('followUpAdmissionsCount', this.followUpAdmissionsCount)
        console.log('totalAdmissionCount', this.totalAdmissionCount)
        let sortchildbyadmissionsDate = childData.filter((x, i) => childData.filter((x) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!))
        this.totalRegisteredChildren = childData.length;
        this.totalRegisteredChildren = childData.filter((x, i) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate!).length
        this.totalRegisteredBoys = sortchildbyadmissionsDate.filter(x => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Male').length;
        this.totalRegisteredGirls = sortchildbyadmissionsDate.filter(x => x.admissionData[0].dateOfAdmission!.toDate() >= startDate! && x.admissionData[0].dateOfAdmission!.toDate() <= endDate! && x.gender === 'Female').length;
        setTimeout(() => {
            const FollowUpChildrens: FullChild[] = []
            if (childData) {
                let childF = childData.filter(child => child.admissionData.length >= 2)
                childF.forEach(child => {
                    child.admissionData.forEach((admission, i: number) => {
                        if (i > 2 && admission.dateOfAdmission?.toDate()! >= this.startDate! && admission.dateOfAdmission?.toDate()! <= this.endDate) {
                            FollowUpChildrens.push(child)
                        }
                    })
                })
                // alert('loading chart')
                this.getChildAccordingtoAdmissionDate(childData, startDate, endDate)
                this.fetchHospitalData(this.getChildAccordingtoAdmissionDate(childData, startDate, endDate));
                this.fetchAgeTableData(this.getChildAccordingtoAdmissionDate(childData, startDate, endDate), FollowUpChildrens);
            }
        }, 100);
        // this.fetchHospitalData(childData);
        // this.fetchAgeTableData(childData);
    }

    showSelections($event: MatSelectChange) {
        // console.log('SORT BY', $event.value)
        this.sortBy = $event.value
    }

    getPercentage(element: Centre|any) {

        if (element.totalUnits != null && element.occupiedUnits != null) {
            let total = element.totalUnits
            let available = element.occupiedUnits
            let percentage = available / total * 100
            // console.log(` ${total} ${available} ${percentage}`)
            return percentage.toFixed(2)
        }
        return ''
    }

    resetDate() {
        this.mStartDate = null
        this.mEndDate = null
        this.selectedCentreOption = 'All'
    }



    private getChildAccordingtoAdmissionDate(childData: FullChild[], startDate: Date, endDate: Date) {
        return childData.filter((x) => x.admissionData[0].dateOfAdmission!.toDate() >= startDate && x.admissionData[0].dateOfAdmission!.toDate() <= endDate)
    }



    private fetchAdmissionChartData(mChildren: FullChild[]) {
        this.showAdmissionChart = false
        let data = of(mChildren)
        let centrescodes: string[] = this.mCentres.map(x => x.centreCode).reverse()
        let centreNames: string[] = this.mCentres.map(x => x.centreName).reverse()
        // centrescodes.reverse()
        // centres.reverse()
        // this.mCentres.forEach(it=>{
        //     centres.push(it.centreName)
        // })
        data.subscribe((children) => {
            setTimeout(() => {

                this.mAdmissionChartCentres = centreNames
                // this._snackBar.open('CENTRES :',centres.length.toString())
                centrescodes.forEach(it => {
                    let c = children.filter((x) => x.centreCode == it)
                    let f = children.filter((x) => x.centreCode == it && x.admissionData.length >= 2)
                    this.mAdmissionChartUniqueCount.push(c.length)
                    this.mAdmissionChartFollowUpCount.push(f.length)

                })
                this.showAdmissionChart = true
            }, 1000)
        })

    }

    private logInvalidCentre(child: FullChild) {
        if (
            child.centreCode == 'ALBandra' ||
            child.centreCode == 'ALAhmedabad' ||
            child.centreCode == 'ALChandigarh'||
            child.centreCode == 'ALManipal' ||
            child.centreCode == 'ALBangalore' ||
            child.centreCode == 'ALDiamondGarden' ||
            child.centreCode == 'ALGurunanak' ||
            child.centreCode == 'ALPune')
        {

        }
        else console.log(`INvALID CENTRE ${child.centreCode} ${child.childCode}`)
    }

    // * Generated using chatGPT
    private getAllChildDataByCentreRangeGPT(centreCode: string, startDate: Date, endDate: Date) {
        this.resetCounters();

        let childData: FullChild[] = this.mAllChildren;

        if (centreCode !== 'All') {
            childData = this.mAllChildren.filter(x => x.centreCode === centreCode);
            this._snackBar.open(`Calculating by centre and date: ${centreCode}`)._dismissAfter(5000);
        }

        const filteredData = childData.filter(x => {
            const admissionDate = x.admissionData[0]?.dateOfAdmission?.toDate();
            return admissionDate && admissionDate >= startDate && admissionDate <= endDate;
        });

        this.calculateCounts(filteredData, startDate, endDate);
        const followUpChildrens = this.calculateFollowUpChildren(filteredData, startDate, endDate);
        const filted_data$ = of(filteredData)
        filted_data$.subscribe(res=>{
            this.fetchHospitalData(res);

        })
        const foll$ = of(followUpChildrens)
        foll$.subscribe(res=>{
            this.fetchAgeTableData(filteredData, res);

        })
        // setTimeout(() => {
        //   this.fetchHospitalData(filteredData);
        //   this.fetchAgeTableData(filteredData, followUpChildrens);
        // }, 100);
    }

    private resetCounters() {
        this.totalRegisteredBoys = 0;
        this.totalRegisteredGirls = 0;
        this.followUpChildrenCount = 0;
        this.uniqueAdmissionsCount = 0;
        this.followUpAdmissionsCount = 0;
        this.totalAdmissionCount = 0;
    }

    private calculateCounts(data: FullChild[], startDate: Date, endDate: Date) {

        this.totalRegisteredChildren = data.length;
        this.totalRegisteredBoys = data.filter(x => x.gender === 'Male').length;
        this.totalRegisteredGirls = data.filter(x => x.gender === 'Female').length;

        // @ts-ignore
        const uniqueData = data.filter(x => x.admissionData[0]?.dateOfAdmission?.toDate() >= startDate && x.admissionData[0]?.dateOfAdmission?.toDate() <= endDate);
        this.uniqueAdmissionsCount = uniqueData.length;
        this.freshBoys = uniqueData.filter(x => x.gender === 'Male').length;
        this.freshGirls = uniqueData.filter(x => x.gender === 'Female').length;

        this.totalAdmissionCount = this.uniqueAdmissionsCount + this.followUpAdmissionsCount;

    }

    private calculateFollowUpChildren(data: FullChild[], startDate: Date, endDate: Date): FullChild[] {
        let followUpChildrens: FullChild[] = [];
        data.forEach(child => {
            child.admissionData.forEach((admission, i) => {
                // @ts-ignore
                if (i >= 2 && i % 2 === 0 && admission.dateOfAdmission?.toDate() >= startDate && admission.dateOfAdmission?.toDate() <= endDate) {
                    this.followUpAdmissionsCount++;
                    if (child.gender === 'Male') {
                        this.followupBoys++;
                    } else if (child.gender === 'Female') {
                        this.followupGirls++;
                    }
                    followUpChildrens.push(child);
                }
            });
        });
        return followUpChildrens;
    }

}

function hasHospital(child: FullChild, index: any, array: any) {
    return child.hospital == '0';
}
