import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
  selector: 'app-edit-glossary',
  templateUrl: './edit-glossary.component.html',
  styleUrls: ['./edit-glossary.component.scss']
})
export class EditGlossaryComponent implements OnInit {
  menuForm!: FormGroup;
  isFormDisabled = true;

  constructor( @Inject(MAT_DIALOG_DATA) public data:any,
               private formBuilder: FormBuilder,
               private mDatabase: AngularFirestore,
               public dialogRef: MatDialogRef<EditGlossaryComponent>) {
  }

  ngOnInit(): void {
    this.menuForm = this.formBuilder.group({
      menu: [this.data.menu, Validators.required],
      name: [{value:this.data.name,disabled:true}, Validators.required],
      description: [this.data.description,Validators.required]
    });
  }


  submitForm() {
    if (this.menuForm.valid) {
      const formData = this.menuForm.value;
      const name = this.menuForm.get('name')?.value
      const menu = this.menuForm.get('menu')?.value
      const desc = this.menuForm.get('description')?.value


      const map = {menu: this.menuForm.value}
      // Add the form data to Firestore collection
      this.mDatabase.collection('glossary').doc(menu).set({[name]: desc}, {merge: true}).then(() => {
        console.log('Form data stored in Firestore');
        this.dialogRef.close();
      }).catch(error => {
        console.error('Error storing form data:', error);
      });

      // this.dataSource.data.push(formData);
      // this.dataSource._updateChangeSubscription();
      // this.data.push(formData);

    }


  }


  closeDialog() {
    this.dialogRef.close();
  }
}
