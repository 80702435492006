<!--<prettyjson [obj]="data"></prettyjson>-->
<div class="container">
  <h2 class="font-bold m-4">Add User Role</h2>

  <div class="text-center">
    <label class="mx-5 font-bold ">User Role</label>
    <input type="text" placeholder="Enter Counselor" class="border border-secondary rounded bg-white text-dark p-2 ms-5 w-50 ">
  </div>


  <table class="table mt-4 ">
    <thead>
    <tr >
      <th class="head">MySpace Function</th>
      <th class="head">All</th>
      <th class="head">Add</th>
      <th class="head">Edit</th>
      <th class="head">Archive</th>
      <th class="head">View</th>
      <th class="head">Excel</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let d of data">
      <td>{{d.functionName}}</td>
      <td><input type="checkbox" [(ngModel)]="d.all"></td>
      <td><input type="checkbox" [(ngModel)]="d.add"></td>
      <td><input type="checkbox" [(ngModel)]="d.edit"></td>
      <td><input type="checkbox" [(ngModel)]="d.archive"></td>
      <td><input type="checkbox" [(ngModel)]="d.view"></td>
      <td><input type="checkbox" [(ngModel)]="d.excel"></td>
    </tr>

    </tbody>
  </table>

<!--  <button mat-raised-button (click)="submit()">Submit</button>-->

</div>
