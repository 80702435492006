
<div class="container">
<div class="row p-3 ">
  <div class="col-md-8">
    <div class=" pt-5 ">
      <mat-card>
<!--        <mat-card-header>-->
<!--          &lt;!&ndash;            <mat-card-title-group>&ndash;&gt;-->
<!--          <div  mat-card-avatar class="example-header-image">-->
<!--&lt;!&ndash;          <img [src]="mDocuments[0]?.url">&ndash;&gt;-->
<!--          </div>-->
<!--          <mat-card-title>{{centreModel.centreName}}  </mat-card-title>-->
<!--&lt;!&ndash;          <mat-card-subtitle> {{centreModel.centreCode}} </mat-card-subtitle>&ndash;&gt;-->
<!--          &lt;!&ndash;              <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" >&ndash;&gt;-->
<!--          &lt;!&ndash;            </mat-card-title-group>&ndash;&gt;-->
<!--        </mat-card-header>-->
<!--        <hr>-->
        <mat-card-content>
          <table class="table table-striped">

            <tbody>
            <tr><th scope="row">Centre Name</th>
              <td>{{centreModel.centreName}}</td>
            </tr>
            <!--          <tr><th scope="row">Address</th><td>{{centreModel.address}}</td></tr>-->
            <tr><th >Amenities</th><td>{{centreModel.ammenities}}</td></tr>
            <tr><th >Address</th><td>{{centreModel.address}}</td></tr>
            <tr><th >City</th><td>{{centreModel.city}}</td></tr>
            <tr><th >State</th><td>{{centreModel.state}}</td></tr>
            <tr><th >PinCode</th><td>{{centreModel.zipcode}}</td></tr>
            <tr><th >Country</th><td>{{centreModel.country}}</td></tr>
            <tr><th >Total Units</th><td>{{centreModel.totalUnits}}</td></tr>
            <tr><th >Available Units</th><td>{{centreModel.availableUnits}}</td></tr>
            <tr><th >Allocated Units</th><td>{{centreModel.occupiedUnits}}</td></tr>
            <tr><th >Non Functional Units</th><td>{{centreModel.nonFunctionalUnits}}</td></tr>
            <tr><th >Centre Coordinator</th><td>{{centreModel.centreCordinator}}</td></tr>
            <tr><th >Centre Manager</th><td>{{centreModel.centreManager}}</td></tr>
            <!--          <tr><th >Address</th><td>{{centreModel.address}}</td></tr>-->
            <!--          <tr><th >State</th><td>{{centreModel.state}}</td></tr>-->
            <!--          <tr><th >Zipcode</th><td>{{centreModel.zipcode}}</td></tr>-->
            <!--          <tr><th >Country</th><td>India</td></tr>-->
            <!--          <tr><th >Nationality</th><td>Indian</td></tr>-->
            <!--              <tr><th >Method</th><td>{{centreModel.method}}</td></tr>-->


            </tbody>
          </table>
          <hr>
          <app-file-upload [storagePath]="mStoragePath" [dbPath]="mPath" fileCount="multi"></app-file-upload>
          <div *ngIf="mDocuments && mDocuments.length>1">
          <hr>
          <h3 class="text-center">Uploaded Images</h3>
          <div class="cdk-virtual-scroll-data-source-example">
            <cdk-virtual-scroll-viewport orientation="horizontal" itemSize="5" class="example-viewport">
              <div class="d-flex  flex-row">
                <div *cdkVirtualFor="let d of (mDocuments);trackBy:trackByMethod;let i = index" class="example-item ">
                  <a (click)="openLink(d.url)">
                    <img [src]="d.url" style="width: 250px; height: 250px" class="img-thumbnail img-fluid">
                  </a>
                  <div class="d-flex justify-content-between">
                    <h3 style="flex-grow: 9" class="text-center pt-1 ">{{d.description}}</h3>
                    <button mat-icon-button color="warn" (click)="deleteAdmissionFile(i,d,'documents')">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>

                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>
          </div>
        </mat-card-content>
      </mat-card>

    </div>

  </div>
  <div class="col-md-4">
    <h2 *ngIf="centreModel?.events" class="text-center bold">&nbsp;</h2>
    <cdk-virtual-scroll-viewport itemSize="5" class="viewport">
    <ng-container *cdkVirtualFor="let e of centreModel.events;let  i =index">
    <mat-card class="example-card mx-2" style="margin-bottom: 10px">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{e.title}}</mat-card-title>
        <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
      </mat-card-header>
      <img mat-card-image [src]="e.res_url" alt="Event Image Here">
      <mat-card-content>
        <p>
          {{e.description}}
        </p>
      </mat-card-content>

    </mat-card>

    </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

  <!--      RESPONSE-->

</div>

