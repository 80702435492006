import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EmbeddedViewRef,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Calendar, CalendarOptions, DateSelectArg, EventApi, EventClickArg} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {AddMealEventComponent, MealEvent} from "../add-meal-event/add-meal-event.component";
import {MealMateService} from "../meal-mate.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatSelectChange} from "@angular/material/select";
import {ViewMealEventComponent} from "../view-meal-event/view-meal-event.component";
import {TooltipComponent} from "@angular/material/tooltip";
import {FullCalendarComponent} from "@fullcalendar/angular";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";

@Component({
    selector: 'app-meals-calender',
    templateUrl: './meals-calender.component.html',
    styleUrls: ['./meals-calender.component.scss'],
})
export class MealsCalenderComponent implements OnInit {
    startTime = ''
    endTime = ''
    permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
    mealmatePermissions=this.permissions.filter((x)=>x.functionName=='Meal Mate')[0]

    //* To block click on date event click
    maxEventCount = 2
    dbEvents: MealEvent[] = []
    @ViewChild('calendar', {static: false}) calendarRef: Calendar | undefined;
    centres: Centre[] = []
    isCentreSelected = false
    centreSelected!: Centre | null
    calendarVisible = true;
    calendarOptions!: CalendarOptions
    currentEvents: EventApi[] = [];
    // dayCellContentCallback = (arg: { dayNumberText: any; dayEvents: string | any[]; }) => {
    dayCellContentCallback = (arg: any) => {
        // console.log(arg.dayEvents)
        // arg.dayNumberText
        //  arg.dayNumberText = arg.dayNumberText + ' (' + this.getEventsCount(arg.date) + ')';
        let msg = ''
        let totalSlots = 2
        this.maxEventCount =totalSlots
        if (this.centreSelected?.centreCode == 'All') totalSlots = (this.centres.length - 1) * 2
        let eventCount = this.getEventsCount(arg.date).toString()
        this.maxEventCount =totalSlots
        if (eventCount == '0' && this.centres.length != 0) msg = 'All Slots Available'
        else if (eventCount != '0' && this.centres.length != 0) msg = 'Slots booked: ' + eventCount + '/' + totalSlots
        else if (eventCount == totalSlots.toString() && this.centres.length != 0) msg = 'All Slots Booked'
        return {
            html: `<div class="fc-daygrid-day-events-count ">${arg.dayNumberText} </div>
<div class="msg">${msg}</div>`
        }
        //  let daytext=arg.dayNumberText
        //  const eventCount =0
        //  const eventCounts = arg.dayEvents?.length;
        //  if (eventCount == 0) {
        //    return{ html: `<span class="fc-daygrid-day-events-count">${daytext} ${eventCounts}</span>`}
        //  } else {
        //    return null;
    }
    //     const eventCount = arg.view
    //         .getEvents()
    //         .filter((event: any) => {
    //           return (
    //               event.start.getFullYear() === arg.date.getFullYear() &&
    //               event.start.getMonth() === arg.date.getMonth() &&
    //               event.start.getDate() === arg.date.getDate()
    //           );
    //         }).length;
    //     if (eventCount > 0) {
    //       return `<span class="fc-daygrid-day-events-count">${eventCount}</span>`;
    //     } else {
    //       return '';
    //     }
    //   };


    @ViewChild("fcEventContent") eventContent: TemplateRef<any> | undefined;
    // references the #calendar in the template
    @ViewChild('calendar') calendarComponent!: FullCalendarComponent;
    private readonly contentRenderers = new Map<string, EmbeddedViewRef<any>>();

    constructor(private formBuilder: FormBuilder,
                private changeDetector: ChangeDetectorRef,
                private router: Router,
                private dialog: MatDialog,
                private mealService: MealMateService,
                private centreService: CentreService,
                private _matSnackBar: MatSnackBar
    ) {
        this.calendarOptions! = {
            plugins: [
                interactionPlugin,
                dayGridPlugin,
                listPlugin,
            ],
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,listWeek'
            },
            initialView: 'dayGridMonth',
            initialEvents: this.dbEvents, // alternatively, use the `events` setting to fetch from a feed
            weekends: true,
            editable: false,
            dayCellContent: this.dayCellContentCallback,
            selectable: true,
            selectMirror: true,
            events: this.dbEvents,
            eventDrop: ((arg) => {
                console.log('DROP ARG', arg.oldEvent)
            }),
            dayMaxEvents: false,//* Select true if you want to show max  events and event dialog
            select: this.handleDateSelect.bind(this),
            // eventClick: this.handleEventClick.bind(this),
            eventClick: this.myHandleEventClick.bind(this),
            eventsSet: this.handleEvents.bind(this),
            // selectOverlap: false,
            eventColor: 'green',
            themeSystem: 'bootstrap5',
            eventTextColor: 'white',

            // views: {
            //   month: {
            //     eventLimit: 2
            //   }
            // }

            /* you can update a remote database when these fire:
            eventAdd:
            eventChange:
            eventRemove:
            */

        };
    // alert(JSON.stringify(this.mealmatePermissions))
    }

    ngOnInit(): void {
        this.fetchCentres();

    }

    private fetchCentres() {
        this.centreService.getAllCentresForMealmate().pipe().subscribe(centres => {
            this.centres = centres
            let c = {centreCode: 'All', centreName: "All Centres", totalUnits: 99, key$: 'All'} as unknown as Centre
            this.centres.push(c)
            this.centres.push({
              mealMate: false,
              unitSponsorship: false,
              centreCode:'GurunanakB',centreName:'AL Gurunanak B',totalUnits:99,$key:'GurunanakB'})
        })
this.centres =this.centres.filter(x=>x.mealMate==true && x.isArchive==false)
    }

    getEventsCount(date: Date): number {
        let count = 0;
        // @ts-ignore
        this.calendarOptions!.events.forEach((event) => {
            //  console.log('DATE:  ', 'calculating events')
            // console.log('EVDATE:  ' ,new Date(event.start))
            const evdate = new Date(event.start)
            if (evdate.getDate() == date.getDate() && evdate.getMonth() == date.getMonth()) {
                count++;
            }
        });
        return count;
    }

    handleDayRender(arg: any) {
        console.log(arg)
        // this.calendarOptions
        let customHtml = `<BR>` +
            `Therichpost`;

        let mystring = arg.dayNumberText;
        return mystring + customHtml;
    }

    eventRender(info: any) {
        var tooltip = new TooltipComponent(info.el, {

            // @ts-ignore
            title: "gf",
            placement: 'top',
            trigger: 'hover',
            container: 'body'
        });
    }

    // @ts-ignore


    //  this.dayCellContent = (arg: any) => {
    //     const eventCount = arg.view
    //         .getEvents()
    //         .filter((event: any) => {
    //           return (
    //               event.start.getFullYear() === arg.date.getFullYear() &&
    //               event.start.getMonth() === arg.date.getMonth() &&
    //               event.start.getDate() === arg.date.getDate()
    //           );
    //         }).length;
    //     if (eventCount > 0) {
    //       return `<span class="fc-daygrid-day-events-count">${eventCount}</span>`;
    //     } else {
    //       return '';
    //     }
    //   };
    // }

// eventAfterAllRender(view:any){
//   for(let cDay = view.start.clone(); cDay.isBefore(view.end) ; cDay.add(1, 'day') ) {
//     var dateNum = cDay.format('YYYY-MM-DD');
//     var dayEl = ('.fc-day[data-date="' + dateNum + '"]');
//     var eventCount = ('.fc-event[date-num="' + dateNum + '"]').length;
//     if (eventCount) {
//       var html = '<span class="event-count">' +
//           '<i>' +
//           eventCount +
//           '</i>' +
//           ' Events' +
//           '</span>';
//       // Uncomment
//       // dayEl.append(html);
//
//     }
//   }
// }
    handleCalendarToggle() {
        this.calendarVisible = !this.calendarVisible;
    }

    handleWeekendsToggle() {
        const {calendarOptions} = this;
        calendarOptions.weekends = !calendarOptions.weekends;
    }

    renderEventContent(arg: any) {
        let renderer = this.contentRenderers.get(arg.event.id)
        if (!renderer) {
            // Make a new renderer and save it so that we can destroy when the event is unmounted.
            renderer = this.eventContent?.createEmbeddedView({arg: arg});
            this.contentRenderers.set(arg.event.id, renderer!);
        } else {
            // Just update the existing renderer.
            renderer.context.arg = arg;
            renderer.markForCheck();
        }
        renderer?.detectChanges();
        return renderer?.rootNodes[0];
    }

    unrenderEvent(arg: any) {
        const renderer = this.contentRenderers.get(arg.event.id);
        if (renderer) {
            renderer.destroy();
        }
    }

    handleDateSelect(selectInfo: DateSelectArg) {
if(!this.mealmatePermissions.all && !this.mealmatePermissions.add) {
    return;
}

        const date = new Date(selectInfo.startStr);
        const today = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
        const currentDate = new Date(today)
        console.log('TODAY CALENDAR', currentDate)
        // const currentDate2= new Date()
        // console.log('calcDate',currentDate)
        // console.log('backdateLogic',date>=currentDate)
        // alert(JSON.stringify(this.mealmatePermissions))

        let eventCount = this.getEventsCount(date)
        console.log('GETEVENT COUNT ON CLICK',eventCount)
        console.log('maxeventcount COUNT ON CLICK',this.maxEventCount)
        // if(this.mealmatePermissions.all || !this.mealmatePermissions.add) {
        //     alert(JSON.stringify(this.mealmatePermissions))
        //     return;
        // }
        if (eventCount == this.maxEventCount) {
            this._matSnackBar.open('All slots are full', '', {duration: 3000})
            return
        }

        if (!this.isCentreSelected) {
            this._matSnackBar.open('Please select a centre first', '', {duration: 3000})
            return
        }

        if (date < currentDate) {
            console.log('backdate logic working')
            return;
        }

        const maxEvents = selectInfo.view.calendar.getEvents().length
        // if(maxEvents>4){
        //   return
        // }
        const alldaybooked = selectInfo.startStr + 'A'
        const calendarApi = selectInfo.view.calendar;
        // const title = prompt('Please enter a new title for your event');

        console.log('ON SELECT', selectInfo)
        this.dialog.open(AddMealEventComponent, {
            data: {
                centreData: this.centreSelected,
                datestr: selectInfo.startStr
            }
        }).afterClosed().subscribe(result => {
        })
    }


    private addMeal(event: MealEvent, res: MealEvent) {
        event.mealFor = res.mealFor
        event.mealSlot = res.mealSlot
        this.mealService.addMealEvent(event, this.centreSelected!.centreCode)
    }

    handleEventClick(clickInfo: EventClickArg) {
        if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            clickInfo.event.remove();
            this.mealService.deleteMealEvent(clickInfo.event.id)
        }

    }

    myHandleEventClick(info: EventClickArg) {
        console.log(info.event.id)
        // alert('Event: ' + info.event.title);
        // alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        // alert('View: ' + info.view.type);

        // change the border color just for fun
        // info.el.style.borderColor = 'white';
        this.dialog.open(ViewMealEventComponent, {
            width: "600px",
            data: {eventId: info.event.id, centreCode: this.centreSelected?.centreCode}
        })
    }

    handleEvents(events: EventApi[]) {
        this.currentEvents = events;
        this.changeDetector.detectChanges();

    }


    centreSelection($event: MatSelectChange) {
        if ($event.value != 'All') {
            this.isCentreSelected = true
            this.centreSelected = $event.value as Centre
            // console.log(this.centreSelected)
            this.fetchCalendarData(this.centreSelected?.centreCode)
        } else if ($event.value == 'All') {
            this.isCentreSelected = true
            this.fetchCalendarData('All')
        }

    }

    fetchCalendarData(centreCode: string) {

        this.mealService.getMealEvents(centreCode).pipe().subscribe(res => {
            this.dbEvents = []
            console.log('EVENTS FETCHED', res.length)
            this.calendarOptions.events = res
            this.dbEvents = res

        })

    }

    // Helper function to get events for a given date

    notManipalOrPune(c: Centre) {
        return (c.centreName == "Pune CM" || c.centreName == 'Manipal IT' || c.centreName == 'ALManipal' || c.centreName == 'ALPune')
    }

}
