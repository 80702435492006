<div class="container">
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Hospitals</h2>
      </li>
      <li class="dropdown m-l-20">
        <label for="search-input"><i class="material-icons search-icon">search</i></label>
        <input placeholder="Search" type="text" class="browser-default search-field"
               (keyup)='applyFilter($event)' aria-label="Search box" #input>
      </li>
    </ul>
    <ul class="header-buttons mr-2">
      <li>
        <button mat-mini-fab color="primary" *ngIf="hospital[0].all || hospital[0].add" (click)="createHopital()">
          <mat-icon class="col-white" >add</mat-icon>
        </button>
      </li>
<!--      <li>-->
<!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
<!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
<!--        </div>-->
<!--      </li>-->
    </ul>
  </div>



<!--  <div class="container">-->
<!--    <BR>-->
<!--    <button *ngIf="hospital[0].all || hospital[0].add" color="accent" mat-raised-button id="create-center"  (click)="createHopital()"><mat-icon>add</mat-icon>&nbsp;Add Hospital</button>&nbsp;-->
<!--  </div>-->
<!--<BR>-->
<!--  <br>-->
<!--  <mat-form-field>-->
<!--    <mat-label>Filter</mat-label>-->
<!--    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>-->
<!--  </mat-form-field>-->
<div class="mat-elevation-z8">
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource">
    <!-- Id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="hospitalName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let row">{{row.hospitalName}}</td>
    </ng-container>
    <ng-container matColumnDef="shortName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Short Name</th>
      <td mat-cell *matCellDef="let row">{{row.shortName}}</td>
    </ng-container>
    <!-- City Column -->
    <ng-container matColumnDef="city">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
      <td mat-cell *matCellDef="let row">{{row.city}}</td>
    </ng-container>
    <!-- State Column -->
    <ng-container matColumnDef="doctorsName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Doctor </th>
      <td mat-cell *matCellDef="let row">{{row.doctorsName}}</td>
    </ng-container>
    <!-- State Column -->
    <ng-container matColumnDef="socialWorker">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Social Worker</th>
      <td mat-cell *matCellDef="let row">{{row.socialWorkerName}}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element">
<!--        <button mat-icon-button>-->
<!--          <mat-icon>archive</mat-icon>Archive-->
<!--        </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
<!--        <button  mat-icon-button >-->
<!--          <mat-icon>edit</mat-icon>Edit-->
<!--        </button>&nbsp;&nbsp;&nbsp;-->
        <button *ngIf="hospital[0].view || hospital[0].all" mat-icon-button color="accent" (click)="viewDialog(element)" matTooltip="View">

          <mat-icon>remove_red_eye</mat-icon>
        </button>

        <button *ngIf="hospital[0].edit || hospital[0].all"  mat-icon-button color="primary" (click)="viewHospital(element)"  >

          <mat-icon >edit</mat-icon>
        </button>



        <button *ngIf="hospital[0].archive || hospital[0].all" mat-icon-button color="warn" (click)="delete(element)" matTooltip="Delete"><mat-icon>delete</mat-icon></button>

      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator
      [length]="dataSource.data.length"
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[25, 50, 100,1000]"
      aria-label="Select page">
  </mat-paginator>
</div>

</div>
