export class Reciept {
  // name : string | undefined;
  // address :string | undefined;
  // city :string | undefined;
  // pan  :string | undefined;
  // state :string | undefined;
  // email : string | undefined;
  // zipcode: string | undefined;
  // country: string | undefined;
  // // tslint:disable-next-line:variable-name
  // receipt_id: string | undefined;
  // amount: number | undefined;
  // // tslint:disable-next-line:variable-name
  // created_at: number | undefined;
  // date: string | undefined;
//
  // tslint:disable-next-line:variable-name
  donated_by ='';
  description ='';
  address='';
  method='';
  // tslint:disable-next-line:variable-name
  order_id='';
  // tslint:disable-next-line:variable-name
  doc_id='';
  uid='';
  city =''
  pan  ='';
  state ='';
  email ='';
  groupkey ='';
  zipcode='';
  country ='India';
  // tslint:disable-next-line:variable-name
  receipt_id ='';
  amount=1;
  totalAmountWithoutTax?=0;
  // tslint:disable-next-line:variable-name
  created_at=0;
  createdAt=0;
  date=''
  mobile?=''
  donation_type?:string;
  // tslint:disable-next-line:variable-name
  donation_amount?:number;
  id?:string;
}
