import {Component, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import {EMPTY, Observable, Subject, takeUntil} from "rxjs";
import {AuthService} from "../auth.service";
import {UtilService} from "../services/util.service";
import {FileStorageService} from "../services/file-storage.service";
import {catchError, map} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {FullChild} from "../interfaces/FullChild";

// !Reference Url : https://dev.to/angular/implement-file-upload-with-firebase-storage-in-our-angular-app-the-simple-way-1ofi
@Component({
    selector: 'app-my-test',
    templateUrl: './my-test.component.html',
    styleUrls: ['./my-test.component.scss']
})
export class MyTestComponent implements OnInit, OnDestroy {
    destroy$: Subject<null> = new Subject();
    fileToUpload!: File;
    log: any[] = []
    submitted = false;
    uploadProgress$?: Observable<number | undefined>;
    kittyImagePreview!: string | ArrayBuffer;
    pictureForm!: FormGroup;
    user?: firebase.User | null;
    MEDIA_STORAGE_PATH = 'testfileuploads'
    data: any
    datasize: any

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly authService: AuthService,
        private readonly utilService: UtilService,
        private readonly storageService: FileStorageService,
        private readonly mDatabase: AngularFireDatabase,
        private readonly snackBar: MatSnackBar,
        private readonly mFirestore: AngularFirestore
    ) {
    }

    ngOnInit(): void {
      // this.getInvalidDOB()
// this.createCopyofAlChild()
//         this.checkGender()
//     this.findNullChildCode()

    }

    // @ts-ignore

    ngOnDestroy() {
        this.destroy$.next(null);
        this.authService.user$?.pipe(takeUntil(this.destroy$))
            .subscribe((user) => (this.user = user));
    }


    private createCopyofAlChild() {
        alert('into createCopiy')
        // this.mFirestore.collection<FullChild>('ALChild',ref => ref.limit(2)).valueChanges()
        //     .subscribe(res=>{
        //       this.data =res.docs.map(r=>r.data())
        //
        //       this.mFirestore.collection('BackupALChild')
        //       this.datasize =res.docs.length
        //       console.log(res)
        //     })
        this.mFirestore.collection<FullChild>('ALChildBackUp').get().pipe(
            map(r => r.docs.map(s => s.data()))
        ).subscribe(res => {
            res.forEach(it => {
                this.mFirestore.collection('ALChild').doc(it.childCode).set(it).then(res => {
                    this.log.push(`Child Added with id` + it.childCode)

                }).catch(reason => {
                    console.log(reason)
                    console.log(it.childCode)
                    this.log.push(it.childCode + 'error')
                })
            })
        })

    }

    //GET
    private checkGender() {
        this.mFirestore.collection<FullChild>('ALChild',).valueChanges()
            .subscribe(res=>{
              let genderless = res.filter(c=> c.gender == null)
              genderless.forEach(it=>{
                // this.log.push(`ChildCode ${it.childCode}` + it.gender)
              })
res.forEach(it=>{
  this.log.push({childCode:it.childCode,gender:it.gender
})
console.table(this.log)
})

              // alert(genderless.length)

            })
    }

    private findNullChildCode() {
        this.mFirestore.collection<FullChild>('ALChild',ref=>ref.where('centreCode','==',null)).valueChanges({idField:'id'}).subscribe(res=>{
            alert(res.length)
            res.forEach(c=>{
           if(c.centreCode==null)     this.log.push('firstName =>'+c.firstName + ' lastName => '+c.lastName )
                if(c.centreCode==null)          console.log(c.firstName+ '' + c.lastName +''+ c.id)
            })
        })
    }

  private getInvalidDOB() {
    console.log('INTO INVALID DOB')
    this.mFirestore.collection<FullChild>('ALChild').get().subscribe(res => {
      let childData = res.docs.map(x => x.data())
      console.log('TOTAL CHILDREN FETCHED',childData.length)
let invalidDobs = childData.filter(x=>x.dateOfAdmission ==null)
      console.log(invalidDobs.length)
      invalidDobs.forEach(it=>{
        console.log(it.childCode + ""+ it.dob)
        this.log.push(it.childCode + ""+ it.dob)
      })
    })
  }
}

