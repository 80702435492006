<mat-card class="bg-warning text-dark">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title><strong>{{ mData.firstName }} {{mData.lastName}}</strong></mat-card-title>
    <mat-card-subtitle><strong> {{ mData.unit }}</strong>  </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex justify-content-between">
    <div class=" ">
      <div>Sponsorship Program: <strong> {{ mData.duration }}</strong></div>
      <div>Donor Name:<strong> {{mData.donorName}}</strong></div>
      <div>Mobile Number:<strong> {{mData.mobile}}</strong></div>
      <div>Payment Terms:<strong> {{mData.paymentInformation}}</strong></div>

    </div>

    <div class="d-flex justify-content-between ">

    </div>
    <div class=" ">
      <div>Start Date <strong> {{ mData.startDate.toDate() | date: 'dd/MM/yyyy' }}</strong></div>

      <div>End Date <strong> {{ mData.endDate.toDate() | date: 'dd/MM/yyyy' }}</strong></div>
      <mat-form-field >
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date"  [(ngModel)]="startDate">
          <input matEndDate placeholder="End date" [(ngModel)]="endDate">
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    </div>
  </mat-card-content>
</mat-card>

<div class=" m-2">

  <textarea class="email-textarea" placeholder="Write your email here..."></textarea>

<div class="col-md-6">
  <app-file-upload [storagePath]="mStoragePath" [dbPath]="mPath" fileCount="multi"></app-file-upload>
</div>
</div>





<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <ng-container matColumnDef="SR. N0">
    <th mat-header-cell *matHeaderCellDef> SR. N0 </th>
    <td mat-cell *matCellDef="let element">  {{element.profile}}</td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Profile">
    <th mat-header-cell *matHeaderCellDef> Profile </th>
    <td mat-cell *matCellDef="let element"> {{element.profile}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element">  {{element.profile}} </td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Age">
    <th mat-header-cell *matHeaderCellDef> Age </th>
    <td mat-cell *matCellDef="let element">  {{element.profile}}</td>
  </ng-container>


  <ng-container matColumnDef="Address">
    <th mat-header-cell *matHeaderCellDef> Address </th>
    <td mat-cell *matCellDef="let element">  {{element.profile}} </td>
  </ng-container>

  <ng-container matColumnDef="Case Study">

    <th mat-header-cell *matHeaderCellDef>Case Study</th>
    <td mat-cell *matCellDef="let element">  {{element.profile}} </td>
  </ng-container>




  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
