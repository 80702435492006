<div class="container">
<h3 class="text-center rounded m-2"> Admin Edit Child Registration</h3>
<!--     <prettyjson [obj]="findInvalidControls()"></prettyjson>-->
<!--    <prettyjson [obj]="guardiansFormArray.length"></prettyjson>-->
<!--    <prettyjson [obj]="siblingFormArray.length"></prettyjson>-->
<!--    <prettyjson [obj]="getAdmissionData.value"></prettyjson>-->

<div class="d-flex">
    <form class="about" [formGroup]="childForm">
        <div class="row">
            <div class="col-md-9">
                <h2>Child Information</h2>
                <div class="row">
                    <!--                                          First Name                                              -->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>First Name</mat-label>
                            <input matInput (blur)="onBlur(firstname)" placeholder="First Name" formControlName="firstName">
                            <mat-error *ngIf="  firstname.invalid  &&  ( firstname.dirty)">
                                <mat-error style="color: red;" *ngIf="firstname.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="firstname.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <!--                                         Middle Name                         -->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Middle Name</mat-label>
                            <input matInput (blur)="onBlur(middlename)" placeholder="Middle Name"  formControlName="middleName">
                            <!--            <mat-error *ngIf="  middlename.invalid  &&  (middlename.touched || middlename.dirty)">-->
                            <!--              <mat-error style="color: red;" *ngIf="middlename.errors?.required">*It is Required.</mat-error>-->
                            <mat-error style="color: red;" *ngIf="middlename.errors?.pattern">*Enter Only characters.</mat-error>
                            <!--            </mat-error>-->
                        </mat-form-field>
                        <!---->
                    </div>
                    <!--                                          Last Name       -->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Last Name</mat-label>
                            <input matInput (blur)="onBlur(lastname)" placeholder="Last Name" required formControlName="lastName">
                            <mat-error *ngIf=" lastname.invalid  &&  (lastname.touched || lastname.dirty)">
                                <mat-error style="color: red;" *ngIf="lastname.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="lastname.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                        <!---->
                    </div>
                    <!--------------------------------------------- bloog group --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Blood Group</mat-label>
                            <mat-select formControlName="bloodGroup" >
                                <ng-container *ngFor="let bl of bloodGroupsArray">
                                    <mat-option [value]="bl">{{bl}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- GENDER --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Gender</mat-label>
                            <mat-select formControlName="gender">
                                <mat-option value="Female">Female</mat-option>
                                <mat-option value="Male">Male</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- DOB --------------------------------------------->
                    <!--        <div class="col-md-4 col-sm-12">-->
                    <!--          <mat-form-field appearance="outline">-->
                    <!--            <mat-label>Date of Birth</mat-label>-->
                    <!--            <input matInput [matDatepicker]="picker" placeholder="DOB" required formControlName="dob" [min]="minDate" [max]="maxDate" >-->
                    <!--            <mat-hint>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DD/MM/YYYY</mat-hint>-->
                    <!--            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
                    <!--            <mat-datepicker #picker></mat-datepicker>-->
                    <!--          </mat-form-field>-->
                    <!--        </div>-->

                    <!--------------------------------------------- mobile --------------------------------------------->
                    <!--          pattern="[0-9]{10}$"-->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Mobile No.</mat-label>
                            <input matInput placeholder="Mobileno" #getMobile type="text" maxlength="10" required formControlName="mobile">
                            <mat-icon matSuffix>phone</mat-icon>
                            <mat-hint align="end">{{getMobile.value.length}} / 10</mat-hint>

                            <mat-error *ngIf="mobile.invalid  &&  (mobile.touched || mobile.dirty)">
                                <span style="color: red;" *ngIf="mobile.errors?.required">*It is Required.</span>
                                <span style="color: red;" *ngIf="mobile.errors?.pattern">*Enter Only Number.</span>
                                <span style="color: red;" *ngIf="mobile.errors?.['minlength']">Mobile number of 10 digits required</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- PINCODE --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Pin Code</mat-label>
                            <input matInput #postalCode maxlength="6" required placeholder="pincode" formControlName="pincode"   (blur)="findPinCode(pincode.value)">
                            <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
                            <mat-error *ngIf="  pincode.invalid  &&  (pincode.touched || pincode.dirty)">
                                <span style="color: red;" *ngIf="pincode.errors?.required">*It is Required.</span>
                                <span style="color: red;" *ngIf="pincode.errors?.pattern">*Enter Only Number.</span>
                                <span style="color: red;" *ngIf="pincode.errors?.['minlength']">Zipcode must be of 6 digits</span>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- CITY --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>City</mat-label>
                            <input matInput (blur)="onBlur(city)" placeholder="City" required formControlName="city">
                            <mat-error *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
                                <mat-error style="color: red;" *ngIf="city.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- DISTRICT --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>District</mat-label>
                            <input matInput (blur)="onBlur(District)" placeholder="District" required formControlName="district">
                            <mat-error *ngIf="District.invalid  &&  (District.touched || District.dirty)">
                                <mat-error style="color: red;" *ngIf="District.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="District.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- COUNTRY --------------------------------------------->

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Country</mat-label>
                            <input matInput (blur)="onBlur(country)" placeholder="Country Name" required formControlName="country">
                            <mat-error *ngIf="  country.invalid  &&  (country.touched || country.dirty)">
                                <mat-error style="color: red;" *ngIf="country.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="country.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <!--------------------------------------------- STATE --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>State</mat-label>
                            <input matInput (blur)="onBlur(state)" placeholder="State Name" required formControlName="state">
                            <mat-error *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
                                <mat-error style="color: red;" *ngIf="state.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <!--------------------------------------------- CHILD ADHAR --------------------------------------------->
                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Aadhar Card Number/Birth Certificate</mat-label>
                            <input matInput #childAdhaar maxlength="15" min="10"   placeholder="Aadhar Card Number" formControlName="childAadhaar">
                            <!--            <mat-hint align="end">{{childAdhaar.value.length}} / 12</mat-hint>-->
                            <mat-error class="row"  *ngIf="childAadhar.invalid  &&  (childAadhar.touched || childAadhar.dirty)">
                                <!--            <mat-error style="color: red;" *ngIf="childAadhar.errors?.required">*It is Required.</mat-error>-->
                                <mat-error style="color: red;" *ngIf="childAadhar.errors?.['maxlength']">Max 15 digits  required</mat-error>
                                <mat-error style="color: red;" *ngIf="childAadhar.errors?.['minlength']">Min 10 digits </mat-error>
                                <mat-error style="color: red;" *ngIf="childAadhar.errors?.pattern">Only Digits of length 12 required</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--        &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; DIAGNOSIS -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
                    <!--        <div class="col-md-12 col-sm-12">-->
                    <!--          <mat-form-field appearance="outline">-->
                    <!--            <mat-label>Diagnosis</mat-label>-->
                    <!--            <input matInput placeholder="Diagnosis" required formControlName="diagnosis">-->
                    <!--            <mat-error *ngIf="  getdiagnosis.invalid  &&  (getdiagnosis.touched || getdiagnosis.dirty )">-->
                    <!--              <mat-error style="color: red;" *ngIf="getdiagnosis.errors?.required">*It is Required.</mat-error>-->
                    <!--              <mat-error style="color: red;" *ngIf="getdiagnosis.errors?.pattern">*Enter Only characters.</mat-error>-->
                    <!--            </mat-error>-->
                    <!--          </mat-form-field>-->

                    <!--        </div>-->
                    <!--------------------------------------------- HOBBIES --------------------------------------------->
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Hobbies</mat-label>
                            <input matInput (blur)="onBlur(Hobbies)" placeholder="Hobbies" required formControlName="hobbies">
                            <mat-error *ngIf="  Hobbies.invalid  &&  (Hobbies.touched || Hobbies.dirty)">
                                <mat-error style="color: red;" *ngIf="Hobbies.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="Hobbies.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!--------------------------------------------- ALLERGIES SELECTED --------------------------------------------->
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Allergies</mat-label>
                            <input matInput (blur)="onBlur(Allergies)" placeholder="Allergies" required formControlName="allergies">
                            <mat-error *ngIf="  Allergies.invalid  &&  (Allergies.touched ||Allergies.dirty)">
                                <mat-error style="color: red;" *ngIf="Allergies.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="Allergies.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Aspirations</mat-label>
                            <input matInput (blur)="onBlur(Aspirations)" placeholder="Aspirations" required formControlName="aspirations">
                            <mat-error *ngIf="  Aspirations.invalid  &&  (Aspirations.touched || Aspirations.dirty)">
                                <mat-error style="color: red;" *ngIf="Aspirations.errors?.required">*It is Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="Aspirations.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <hr>
                    <!--------------------------------------    Basic Info--------------------------------------------------------------------->
                    <h2>Registration Info </h2>
                    <div class="col-md-12 col-sm-12">
                        <table class="table">
                            <tr>
                                <th>Centre Name</th>
                                <td>{{this.routerData?.centreName}}</td>
                            </tr>
                            <tr>
                                <th>Unit</th>
                                <td>{{this.routerData?.selectUnit}}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td *ngIf="routerData?.isAlloted">Staying</td>
                                <td *ngIf="!routerData?.isAlloted">Out</td>
                            </tr>
                            <tr>
                                <th>Date Of Birth</th>
                                <td>
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="dobPicker" formControlName="dob" >
                                        <mat-hint>DD/MM/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                                        <mat-datepicker #dobPicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th>Date Of Admission</th>
                                <td>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Choose a date</mat-label>
                                        <input matInput [matDatepicker]="admissionDatePicker" formControlName="dateOfAdmission" >
                                        <mat-hint>DD/MM/YYYY</mat-hint>
                                        <mat-datepicker-toggle matSuffix [for]="admissionDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #admissionDatePicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>

                        </table>
                        <p>Centre:{{this.routerData?.centreName}}</p>
                    </div>
                    <!--        <div class="col-md-4 col-sm-12">-->
                    <!--          <p>Unit:{{this.routerData.selectUnit}}</p>-->
                    <!--        </div>-->
                    <!--        <div class="col-md-4 col-sm-12">-->
                    <!--          <mat-form-field class="example-full-width" appearance="outline">-->
                    <!--            <mat-label>Select AL Center</mat-label>-->
                    <!--            <mat-select formControlName="selectCentre" required  (selectionChange)="fetchUnits($event)"   >-->
                    <!--              <ng-container *ngFor="let option of (centers$|async) ;let i = index">-->
                    <!--                <mat-option  *ngIf="option.isArchive!=true" [value]="option.centreName">{{option.centreName}}</mat-option>-->
                    <!--              </ng-container>-->
                    <!--            </mat-select>-->
                    <!--            <mat-icon matSuffix>search</mat-icon>-->
                    <!--            <mat-error style="color: red;" *ngIf="getCentre?.errors?.required">*It is Required.</mat-error>-->
                    <!--            &lt;!&ndash;        <mat-error style="color: red;" *ngIf="getCentre.errors?.pattern">*Enter Only characters.</mat-error>&ndash;&gt;-->
                    <!--            &lt;!&ndash;          <mat-error *ngIf="getCentre.invalid  &&  (getCentre.touched ||getCentre.dirty)"></mat-error>&ndash;&gt;-->

                    <!--          </mat-form-field>-->
                    <!--        </div>-->


                    <!--    Unit Allotment part ended-->
                    <!--        <div class="col-md-4 col-sm-12">-->
                    <!--          <mat-form-field appearance="outline">-->
                    <!--            <mat-label>Stayed at AL Centre Before</mat-label>-->
                    <!--            <mat-select formControlName="stayedBefore" required>-->
                    <!--              <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>-->
                    <!--            </mat-select>-->
                    <!--          </mat-form-field>-->
                    <!--        </div>-->
                    <!--        <div class="col-md-4 col-sm-12">-->
                    <!--          <mat-form-field appearance="outline">-->
                    <!--            <mat-label>Select Unit</mat-label>-->
                    <!--            <mat-select formControlName="selectUnit" required>-->
                    <!--              <mat-option *ngFor="let unit of (units$|async)" [value]="unit.key" [disabled]="unit.status==='Alloted'">{{unit.floorNo}} - {{unit.order}} - {{unit.status}}</mat-option>-->
                    <!--            </mat-select>-->
                    <!--          </mat-form-field>-->
                    <!--        </div>-->

                    <!--    Unit Allotment Part-->
                    <!--        <div class="col-md-4 col-sm-12 p-2" *ngIf="isSelectedCentre">-->
                    <!--          <ng-container *ngIf="selectedCenter">-->
                    <!--            <div class="col-md-10 col-sm-12 mb-1 text-center" style="display: flex;flex-direction: row;  justify-content: space-around;-->
                    <!--" >-->
                    <!--              <div class=" text-center " id="total" style="flex-grow: 1">-->
                    <!--                <p>Total  <br>-->
                    <!--                  {{selectedCenter.totalUnits}}</p>-->
                    <!--              </div>-->
                    <!--              <div class=" text-center" id="available" style="flex-grow: 1" >-->
                    <!--                <p>Available  <br>-->
                    <!--                  {{selectedCenter.availableUnits}}</p>-->
                    <!--              </div>-->
                    <!--              <div class=" text-center" id="occupied" style="flex-grow: 1">-->
                    <!--                <p>Occupied  <br>-->
                    <!--                  {{selectedCenter.occupiedUnits}}</p>-->
                    <!--              </div>-->
                    <!--            </div>-->
                    <!--          </ng-container>-->
                    <!--        </div>-->


                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>AL Bag Given?</mat-label>
                            <mat-select formControlName="bag" >
                                <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Mother/Guardian & Father/Guardian will Stay with Kid</mat-label>
                            <mat-select formControlName="guardianStayStatus" >
                                <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!--        <div class="col-md-4 col-sm-12">-->
                    <!--          <mat-form-field appearance="outline">-->
                    <!--            <mat-label>Date of Admission</mat-label>-->
                    <!--            <input matInput [matDatepicker]="picker4" placeholder="Admission Date" required formControlName="dateOfAdmission">-->
                    <!--            <mat-hint>DD/MM/YYYY</mat-hint>-->
                    <!--            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>-->
                    <!--            <mat-datepicker #picker4></mat-datepicker>-->
                    <!--          </mat-form-field>-->
                    <!--        </div>-->



                    <!-------------------------------------------------------Basic Info ---------------------------------------------->
                    <hr>


                    <!-------------------------------------------------------- Guardian Info------------------------------------------------------------>
                    <h2>Caretaker Info</h2>

                    <div class="" *ngFor="let control of guardiansFormArray.controls;let i=index"  formArrayName='guardians'>
                        <!--    <div class="" *ngFor="let control of childForm.get('guardians').controls;let i=index"  formArrayName='guardians'>-->
                        <!--------------------------------------------- Guardian View --------------------------------------------->
                        <div [formArrayName]="i">
                            <!--        <p>{{i}}</p>-->
                            <p>Caretaker {{i+1}}</p>
                            <!--Guardian First Name-->
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>First Name</mat-label>
                                        <input matInput (blur)="onBlur(firstname)" placeholder="First Name" required formControlName="firstName">
                                        <mat-error *ngIf="gfirstName(i)?.invalid  &&  (gfirstName(i)?.touched || gfirstName(i)?.dirty)">
                                            <mat-error style="color: red;" *ngIf="gfirstName(i)?.errors?.['required']">Enter Guardian First Name Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="gfirstName(i)?.errors?.['pattern']">*Enter Only Characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Guardian Middle Name-->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Middle Name</mat-label>
                                        <input matInput (blur)="onBlur(middlename)" placeholder="Middle Name"  formControlName="middleName">
                                        <mat-error *ngIf="gmiddleName(i)?.invalid  &&  (gmiddleName(i)?.touched ||gmiddleName(i)?.dirty)">
                                            <!--                  <mat-error style="color: red;" *ngIf="gmiddleName(i)?.errors?.['required']">Guardian Middle Name Required.</mat-error>-->
                                            <mat-error style="color: red;" *ngIf="gmiddleName(i)?.errors?.['pattern']">*Enter Only Characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Guardian Last Name-->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Name</mat-label>
                                        <input matInput (blur)="onBlur(lastname)" placeholder="Last Name" required formControlName="lastName">
                                        <mat-error *ngIf="glastname(i).invalid  &&  (glastname(i)?.touched || glastname(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="glastname(i).errors?.['required']">*Last Name is  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="glastname(i).errors?.['pattern']">*Enter Only Characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Guardian Addhar-->
                                <!--            <div class="col-md-4 col-sm-12">-->
                                <!--              <mat-form-field appearance="outline">-->
                                <!--                <mat-label>Aadhaar Number</mat-label>-->
                                <!--                <input matInput type="text" placeholder="Aadhaar Number" maxlength="12" required formControlName="aadharNumber">-->
                                <!--                <mat-hint align="end">{{gaadhaarnumber(i).value.length}} / 12</mat-hint>-->

                                <!--                <mat-error *ngIf="gaadhaarnumber(i).invalid  &&  (gaadhaarnumber(i)?.touched || gaadhaarnumber(i).dirty)">-->
                                <!--                  <span style="color: red;" *ngIf="gaadhaarnumber(i).errors?.['required']">*It is  Required.</span>-->
                                <!--                  &lt;!&ndash;            <span style="color: red;" *ngIf="gaadhaarnumber(i).errors?.['maxlength']">12 digit number required</span>&ndash;&gt;-->
                                <!--                  <span style="color: red;" *ngIf="gaadhaarnumber(i).errors?.['minlength']">12 digit number required</span>-->
                                <!--                  <span style="color: red;" *ngIf="gaadhaarnumber(i).errors?.['pattern']">Only Digits of length 12 required</span>-->
                                <!--                </mat-error>-->
                                <!--              </mat-form-field>-->
                                <!--            </div>-->
                                <!-- Guardian relation-->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Relation</mat-label>
                                        <mat-select formControlName="relation" >
                                            <!--              <mat-option  >&#45;&#45;Select Relation&#45;&#45;</mat-option>-->
                                            <mat-option *ngFor="let relation of (_relation$|async)" [value]="relation">{{relation}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="gRelation(i).invalid  &&  (gRelation(i)?.touched || gRelation(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="gRelation(i).errors?.['required']">*It is  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="gRelation(i).errors?.['pattern']">*Enter Only Number.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--------------------------------------------- Guardian Occuapation --------------------------------------------->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Occupation</mat-label>
                                        <mat-select  formControlName="occupation" >
                                            <mat-option  *ngFor="let occupation of (_occupation$|async)" [value]="occupation">{{occupation}}</mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="guardianOccupation(i).invalid  &&  (guardianOccupation(i).touched || guardianOccupation(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="guardianOccupation(i).errors?.['required']">*It is  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="guardianOccupation(i).errors?.['pattern']">*Enter Only characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!------------------4-------------------------- Guardian Contact No --------------------------------------------->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contact No.</mat-label>
                                        <input matInput placeholder="Contact No" required maxlength="10" formControlName="contactNo">
                                        <mat-icon matSuffix>phone</mat-icon>

                                        <mat-error *ngIf="gContactNo(i).invalid  &&  (gContactNo(i).touched || gContactNo(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="gContactNo(i).errors?.['minlength']">10 digit number required</mat-error>
                                            <!--            <mat-error style="color: red;" *ngIf="gContactNo(i).errors?.['pattern']">*Enter Only Number.</mat-error>-->
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--------------------------------------------- Guardian AlternateNumber --------------------------------------------->
                                <!--              <div class="col-md-4 col-sm-12">-->
                                <!--                <mat-form-field appearance="outline">-->
                                <!--                  <mat-label>Alternative Contact No.</mat-label>-->
                                <!--                  <input matInput placeholder="Alternative Contact No" maxlength="10" required formControlName="alternateContactNo">-->
                                <!--                  <mat-icon matSuffix>phone</mat-icon>-->

                                <!--                  <mat-error *ngIf="gAlteranate(i).invalid  &&  (gAlteranate(i)?.touched || gAlteranate(i).dirty)">-->
                                <!--                    <span style="color: red;" *ngIf="gAlteranate(i).errors?.['required']">*It is  Required.</span>-->
                                <!--                    &lt;!&ndash;            <span style="color: red;" *ngIf="gAlteranate(i).errors?.['pattern']">*Enter Only Number.</span>&ndash;&gt;-->
                                <!--                    <span style="color: red;" *ngIf="gAlteranate(i).errors?.['minlength']">10 digits required</span>-->
                                <!--                  </mat-error>-->
                                <!--                </mat-form-field>-->
                                <!--              </div>-->
                                <!--            &lt;!&ndash;-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; Guardian Election ID -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&ndash;&gt;-->
                                <!--            <div class="col-md-4 col-sm-12">-->
                                <!--              <mat-form-field appearance="outline">-->
                                <!--                <mat-label>Election ID of Parent</mat-label>-->
                                <!--                <input matInput placeholder="Election ID of Parent" required formControlName="electionId">-->

                                <!--                <mat-error *ngIf="gElectionId(i).invalid  &&  (gElectionId(i).touched || gElectionId(i).dirty)">-->
                                <!--                  <mat-error style="color: red;" *ngIf="gElectionId(i).errors?.['required']">*It is  Required.</mat-error>-->
                                <!--                  <mat-error style="color: red;" *ngIf="gElectionId(i).errors?.['pattern']">*Enter Only Number.</mat-error>-->
                                <!--                </mat-error>-->
                                <!--              </mat-form-field>-->
                                <!--            </div>-->
                                <!--------------------------------------------- Guardian Ration Card --------------------------------------------->
                                <!--------------------------------------------- Annual Income --------------------------------------------->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Annual Income</mat-label>
                                        <mat-select  placeholder="--Select Annual Income--" required formControlName="annualIncome">
                                            <mat-option disabled >Select Annual Income</mat-option>
                                            <mat-option value="No Income" >No Income </mat-option>
                                            <mat-option value="Below 50 Thousand" >Below 50 Thousand </mat-option>
                                            <mat-option value="50K to 1 Lakh" >50K to 1 Lakh </mat-option>
                                            <mat-option value="More than 1 Lakh to 5 Lakhs" >More than 1 Lakh to 5 Lakhs </mat-option>
                                            <mat-option value="More than 5 Lakh to 10 Lakhs" >More than 5 Lakh to 10 Lakhs </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="gAnnualIncome(i).invalid  &&  (gAnnualIncome(i).touched || gAnnualIncome(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="gAnnualIncome(i).errors?.['required']">*It is  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="gAnnualIncome(i).errors?.['pattern']">*Enter Only Number.</mat-error>
                                            <mat-error style="color: red;" *ngIf="gAnnualIncome(i).errors?.['maxlength']">Only figure in six digits supported</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--------------------------------------------- Annual Income --------------------------------------------->
                                <!--            <div class="col-md-4 col-sm-12">-->
                                <!--              <mat-form-field appearance="outline">-->
                                <!--                <mat-label>Ration Card No.</mat-label>-->
                                <!--                <input matInput placeholder="Ration Card No." required formControlName="rationCard">-->

                                <!--                <mat-error *ngIf="gRationCard(i).invalid  &&  (gRationCard(i).touched || gRationCard(i).dirty)">-->
                                <!--                  <mat-error style="color: red;" *ngIf="gRationCard(i).errors?.['required']">*It is  Required.</mat-error>-->
                                <!--                  <mat-error style="color: red;" *ngIf="gRationCard(i).errors?.['pattern']">*Enter Only Number.</mat-error>-->
                                <!--                </mat-error>-->
                                <!--              </mat-form-field>-->
                                <!--            </div>-->
                                <!--------------------------------------------- Guardian Driving License --------------------------------------------->
                                <!--            <div class="col-md-4 col-sm-12">-->
                                <!--              <mat-form-field appearance="outline">-->
                                <!--                <mat-label>Driving License of Parent</mat-label>-->
                                <!--                <input matInput placeholder="Driving License of Parent" required formControlName="drivingLicense">-->

                                <!--                <mat-error *ngIf="gDrivingLicense(i).invalid  &&  (gDrivingLicense(i).touched || gDrivingLicense(i).dirty)">-->
                                <!--                  <mat-error style="color: red;" *ngIf="gDrivingLicense(i).errors?.['required']">*It is  Required.</mat-error>-->
                                <!--                  <mat-error style="color: red;" *ngIf="gDrivingLicense(i).errors?.['pattern']">*Enter Only Number.</mat-error>-->
                                <!--                </mat-error>-->
                                <!--              </mat-form-field>-->
                                <!--            </div>-->
                                <!--------------------------------------------- Other Documents --------------------------------------------->
                                <div class="col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Other Documents</mat-label>
                                        <input matInput  placeholder="Other Documents" required formControlName="otherDocuments">
                                        <mat-error *ngIf="gOtherDocuments(i).invalid  &&  (gOtherDocuments(i).touched || gOtherDocuments(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="gOtherDocuments(i).errors?.['required']">*Other Documents  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="gOtherDocuments(i).errors?.['pattern']">*Enter Only characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--------------------------------------------- Guardian Skills --------------------------------------------->
                                <div class="col-md-6 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Skill</mat-label>
                                        <mat-select multiple formControlName="skills" >
                                            <mat-option  *ngFor="let skill of (_skills$|async)" [value]="skill">{{skill}}</mat-option>
                                        </mat-select>
                                        <mat-error  *ngIf="gSkills(i).invalid  &&  (gSkills(i).touched || gSkills(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="gSkills(i).errors?.['required']">*Skill is  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="gSkills(i).errors?.['pattern']">*Enter Only characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                            </div>

                        </div>
                        <!--------------------------------------------- Guardian View Ended --------------------------------------------->
                    </div>
                    <div class="mb-2 d-flex justify-content-end" >
                        <button mat-raised-button  color="primary" type="button" (click)="addGuardianControl()"  >Add Caretaker</button>
                    </div>

                    <div class=" mb-2" *ngIf="guardiansFormArray.length>1">
                        <div class="add-remove">
                            <button mat-raised-button  color="primary" type="button" (click)="addGuardianControl()"  >Add Caretaker</button>&nbsp;
                            <button mat-raised-button  color="warn" type="button" (click)="removeGuardainControl()"  >Remove</button>
                        </div>
                    </div>
                    <br>
                    <!------------------------------------------Guardian Ended------------------------------------------------------------------>
                    <hr>

                    <!--------------------------------------------- Emergency Contact  --------------------------------------------->

                    <h2>Emergency Contact</h2>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Emergency Contact Name</mat-label>
                            <input matInput (blur)="onBlur(emergencyContactName1)" placeholder="Name"  formControlName="emergencyContactName1" >
                            <mat-error *ngIf="emergencyContactName1.invalid  &&  emergencyContactName1.touched || emergencyContactName1.dirty">
                                <mat-error style="color: red;" *ngIf="emergencyContactName1.errors?.['required']">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactName1.errors?.['pattern']">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Emergency Contact Number</mat-label>
                            <input  matInput placeholder=" Number" required formControlName="emergencyContactNumber1" maxlength="10">
                            <mat-error *ngIf="emergencyContactNumber1.invalid  &&  emergencyContactNumber1.touched || emergencyContactNumber1.dirty">
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber1.errors?.['required']">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber1.errors?.['pattern']">*Enter Only Number.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber1.errors?.['maxlength']">*Number should be of minimum 10 digits.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber1.errors?.['minlength']">*Number should be of minimum 10 digits.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Relation</mat-label>
                            <mat-select  formControlName="emergencyContactRelation1" >
                                <mat-option  *ngFor="let relation of (_relation$|async)" [value]="relation">{{relation}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="emergencyContactRelation1.invalid  &&  emergencyContactRelation1.touched || emergencyContactRelation1.dirty">
                                <mat-error style="color: red;" *ngIf="emergencyContactRelation1.errors?.['required']">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactRelation1.errors?.['pattern']">*Enter Only Characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Emergency Contact Name</mat-label>
                            <input matInput (blur)="onBlur(emergencyContactName2)" placeholder="Name"  formControlName="emergencyContactName2" >
                            <mat-error *ngIf="emergencyContactName2.invalid  &&  emergencyContactName2.touched || emergencyContactName2.dirty">
                                <mat-error style="color: red;" *ngIf="emergencyContactName2.errors?.['required']">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactName2.errors?.['pattern']">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Emergency Contact Number</mat-label>
                            <input matInput placeholder=" Number"  formControlName="emergencyContactNumber2" maxlength="10">
                            <mat-error *ngIf="emergencyContactNumber2.invalid  &&  emergencyContactNumber2.touched || emergencyContactNumber2.dirty">
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber2.errors?.['required']">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber2.errors?.['pattern']">*Enter Only Number.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber2.errors?.['maxlength']">*Number should be of minimum 10 digits.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactNumber2.errors?.['minlength']">*Number should be of minimum 10 digits.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Relation</mat-label>
                            <mat-select  formControlName="emergencyContactRelation2" >
                                <mat-option  *ngFor="let relation of (_relation$|async)" [value]="relation">{{relation}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="emergencyContactRelation2.invalid  &&  emergencyContactRelation2.touched || emergencyContactRelation2.dirty">
                                <mat-error style="color: red;" *ngIf="emergencyContactRelation2.errors?.['required']">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="emergencyContactRelation2.errors?.['pattern']">*Enter Only Characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <hr>

                    <!--------------------------------------------- Emergency Contact Ended --------------------------------------------->



                    <!--------------------------------------------- Siblings Section --------------------------------------------->
                    <div>
                        <p><strong>Siblings </strong></p>

                        <div *ngIf="siblingFormArray.controls.length>=1">
                        </div>
                        <div class="row" *ngFor="let control of siblingFormArray.controls;let i=index"  formArrayName='siblings'>
                            <!--    <div class="" *ngFor="let control of childForm.get('guardians').controls;let i=index"  formArrayName='guardians'>-->
                            <!--------------------------------------------- Guardian View --------------------------------------------->
                            <div class="row" [formArrayName]="i">
                                <!--        <p>{{i}}</p>-->
                                <!--        <p>Sibling {{i+1}}</p>-->
                                <!--Guardian First Name-->
                                <!--        <div class="row">-->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Name</mat-label>
                                        <input matInput (blur)="onBlur(firstname)"  formControlName="firstName">
                                        <mat-error *ngIf="sFirstName(i)?.invalid  &&  (sFirstName(i)?.touched || sFirstName(i)?.dirty)">
                                            <mat-error style="color: red;" *ngIf="sFirstName(i)?.errors?.['required']"> Sibling First Name Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="sFirstName(i)?.errors?.['pattern']">*Enter Only Characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Guardian Middle Name-->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Age</mat-label>
                                        <input matInput   formControlName="age"  maxlength="2">
                                        <mat-error *ngIf="sAge(i)?.invalid  &&  (sAge(i)?.touched ||sAge(i)?.dirty)">
                                            <mat-error style="color: red;" *ngIf="sAge(i)?.errors?.['required']">Sibling Age Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="sAge(i)?.errors?.['pattern']">*Enter Only Numbers.</mat-error>
                                            <mat-error style="color: red;" *ngIf="sAge(i)?.errors?.['maxlength']">Age can be of 2 digits only</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!--Guardian Last Name-->
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Gender</mat-label>
                                        <mat-select  formControlName="gender">
                                            <mat-option >--Select gender--</mat-option>
                                            <mat-option value="Male">Male</mat-option>
                                            <mat-option value="Female">Female</mat-option>
                                        </mat-select>

                                        <mat-error *ngIf="sGender(i).invalid  &&  (sGender(i)?.touched || sGender(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="sGender(i).errors?.['required']">*Gender is  Required.</mat-error>
                                            <mat-error style="color: red;" *ngIf="sGender(i).errors?.['pattern']">*Enter Only Characters.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Contact Number</mat-label>
                                        <input matInput formControlName="mobile" maxlength="10">

                                        <mat-error *ngIf="sContactNumber(i).invalid  &&  (sContactNumber(i)?.touched || sContactNumber(i).dirty)">
                                            <mat-error style="color: red;" *ngIf="sContactNumber(i).errors?.['pattern']">*Enter Only Numbers.</mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>


                                <!--        </div>-->

                            </div>
                            <!--------------------------------------------- Sibling View Ended --------------------------------------------->
                        </div>

                        <div class="mt-2 mb-2 d-flex justify-content-end ">
                            <button mat-raised-button  color="primary" type="button" (click)="addSiblingControl()" class="add-button " >Add Sibling</button>&nbsp;
                            <button *ngIf="siblingFormArray.length>=1"  mat-raised-button  color="warn" type="button" (click)="removeSiblingControl()"   > Remove</button>
                        </div>
                    </div>

                    <hr>
                    <!------------------------------------------  Child Health Form  ---------------------------------------------------->
                    <h2> Health Info </h2>
                    <!--        <div class="col-md-11 col-sm-8">-->
                    <!--          <mat-form-field appearance="outline">-->
                    <!--            <mat-label>Hospital</mat-label>-->
                    <!--            <mat-select formControlName="hospital" required >-->
                    <!--              <mat-option *ngFor="let h of (hospitals$ | async)" [value]="h.hospitalName" (onSelectionChange)="HospitalChange(h)" >{{h.hospitalName}}</mat-option>-->
                    <!--            </mat-select>-->
                    <!--          </mat-form-field>-->
                    <!--        </div>-->


                    <mat-form-field appearance="outline" class="col-md-11 col-sm-8">
                        <mat-label>Hospital</mat-label>
                        <mat-select formControlName="hospital" required>
                            <mat-option *ngFor="let h of (hospitals$ | async)" [value]="h.hospitalName"
                                        (onSelectionChange)="HospitalChange(h)">{{h.hospitalName}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!--        <div class="col-md-1 col-sm-4">-->
                    <!--          <button mat-stroked-button type="button"  color="primary" (click)="openHospital()">Add</button>-->
                    <!--        </div>-->


                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Doctor's Name</mat-label>
                            <input matInput (blur)="onBlur(doctorname)" placeholder="Doctor Name"  formControlName="doctorName">
                            <mat-error *ngIf="doctorname.invalid  &&  (doctorname.touched || doctorname.dirty)">
                                <mat-error style="color: red;" *ngIf="doctorname.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="doctorname.errors?.pattern">*Enter Only Characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>File Number</mat-label>
                            <input matInput placeholder="File Number"  formControlName="fileNo">
                            <mat-error *ngIf="fileno.invalid  &&  (fileno.touched ||fileno.dirty)">
                                <mat-error style="color: red;" *ngIf="fileno.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="fileno.errors?.pattern">*Enter Only Number.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Medical Social Worker Name</mat-label>
                            <input matInput (blur)="onBlur(socialname)" placeholder="Social Worker Name"  formControlName="socialName">
                            <mat-error *ngIf="  socialname.invalid  &&  (socialname.touched || socialname.dirty)">
                                <mat-error style="color: red;" *ngIf="socialname.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="socialname.errors?.pattern">*Enter Only Characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Height at the Time of Admission(in cm)</mat-label>
                            <input matInput placeholder="Height"  formControlName="height" maxlength="5">
                            <mat-error *ngIf="height.invalid  &&  (height.touched || height.dirty)">
                                <mat-error style="color: red;" *ngIf="height.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="height.errors?.pattern">*Enter Only Number.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-md-6 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Weight at the Time of Admission(kg)</mat-label>
                            <input matInput placeholder="Weight" maxlength="5"  formControlName="weight" >
                            <mat-error *ngIf="weight.invalid  &&  (weight.touched || weight.dirty)">
                                <mat-error style="color: red;" *ngIf="weight.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="weight.errors?.pattern">*Enter Only Number.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Prescribed Treatment</mat-label>
                            <input matInput (blur)="onBlur(prescribed)" placeholder="Prescribed Treatment"  formControlName="prescribed">
                            <mat-error *ngIf="prescribed.invalid  &&  (prescribed.touched || prescribed.dirty)">
                                <mat-error style="color: red;" *ngIf="prescribed.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="prescribed.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>


                    <div class="col-md-11 col-sm-8">
                        <mat-form-field appearance="outline">
                            <mat-label>Allergies</mat-label>
                            <mat-select multiple formControlName="allergiesSelected" >
                                <mat-option disabled >--Select Allergies--</mat-option>
                                <mat-option *ngFor="let allergies of (_allergies$|async)" [value]="allergies">{{allergies}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-md-1 col-sm-4">
                        <button mat-stroked-button type="button"  color="primary" (click)="addAllergy()">Add</button>
                    </div>


                    <div class="col-md-11 col-sm-8 col-xs-8">
                        <mat-form-field appearance="outline">
                            <mat-label>Diagnosis</mat-label>
                            <mat-select multiple  formControlName="diagnosisSelected">
                                <mat-option disabled >--Select Diagnosis--</mat-option>
                                <mat-option *ngFor="let diagnosis of (_diagnosis$|async)"  [value]="diagnosis">{{diagnosis}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="diagnosisSelected.invalid  &&  (diagnosisSelected.touched ||diagnosisSelected.dirty)"></mat-error>
                            <mat-error *ngIf="diagnosisSelected.errors?.required" > *It is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-1 col-sm-4 col-xs-4">
                        <button mat-stroked-button type="button"  color="primary" (click)="addDiagnosis()">Add</button>
                    </div>
                    <div class="col-md-12 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>How Did you Learn about the Sickness of the Child,What did you do then?(Child Story)</mat-label>
                            <textarea matInput (blur)="onBlur(sickness)" placeholder="Child Story"  formControlName="sickness"></textarea>
                            <mat-error *ngIf="sickness.invalid  &&  (sickness.touched ||sickness.dirty)">
                                <mat-error style="color: red;" *ngIf="sickness.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="sickness.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <p><strong>Expected Duration of Treatment</strong></p>

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Months</mat-label>
                            <input   matInput type="text" maxlength="2" formControlName="months" >
                            <mat-error style="color: red;" *ngIf="Months.errors?.pattern">*Enter Only Numbers.</mat-error>
                            <!--          <mat-error style="color: red;" *ngIf="Months.errors?.required">*It is  Required.</mat-error>-->

                            <!--          <mat-option *ngFor="let months of months" [value]="months">{{months}}</mat-option>-->
                            <!--        </input>-->

                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Days</mat-label>
                            <input matInput type="text" maxlength="2" placeholder="Days" formControlName="days">
                            <mat-error style="color: red;" *ngIf="Days.errors?.pattern">*Enter Only Numbers.</mat-error>
                            <!--          <mat-error style="color: red;" *ngIf="Days.errors?.required">*It is  Required.</mat-error>-->

                            <!--        <mat-select formControlName="days" required>-->
                            <!--&lt;!&ndash;          <mat-option  *ngFor="let days of days" [value]="days">{{days}}</mat-option>&ndash;&gt;-->
                            <!--        </mat-select>-->
                        </mat-form-field>
                    </div>

                    <div class="col-md-4 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Treatment Duration</mat-label>
                            <input matInput placeholder="Treatment Duration"  formControlName="treatment">
                            <mat-error *ngIf="treatment.invalid  &&  (treatment.touched ||treatment.dirty)">
                                <!--            <mat-error style="color: red;" *ngIf="treatment.errors?.required">*It is  Required.</mat-error>-->
                                <mat-error style="color: red;" *ngIf="treatment.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-12 col-sm-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Other Medical Information</mat-label>
                            <textarea (blur)="onBlur(medical)" matInput placeholder="Medical Information"  formControlName="medical"></textarea>
                            <mat-error *ngIf="medical.invalid  &&  (medical.touched ||medical.dirty)">
                                <mat-error style="color: red;" *ngIf="medical.errors?.required">*It is  Required.</mat-error>
                                <mat-error style="color: red;" *ngIf="medical.errors?.pattern">*Enter Only characters.</mat-error>
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="text-center">
                    <!--    <button color="warn" mat-raised-button mat-dialog-close="cancel" (click)="cancel()">Cancel</button> &nbsp;-->

                    <button color="primary"  mat-raised-button  [disabled]="childForm.invalid" (click)="SubmitData()">Update</button>
                    <!--    <button color="primary"  mat-raised-button type="submit" (click)="TestForm()">Save</button>-->
                </div>

            </div>
            <!--------------------------------------------- Expansion Panel --------------------------------------------->

            <div class="col-md-3 col-sm-2">
<!--                <prettyjson [obj]="getAdmissionData.value"></prettyjson>-->
                <h4 class="text-center"><strong>Admission History</strong></h4>
                <div orientation="vertical">

                    <ng-container *ngFor="let d of getAdmissionData.controls; let i = index" formArrayName="admissionData" >
                        <ng-container [formArrayName]="i" >
                            <div  >
                                <ng-template *ngIf="i == 0" >Admission</ng-template>
                                <ng-template *ngIf="i == 1" >Discharge</ng-template>
                                <ng-template *ngIf="i % 2 == 0" >Followup Admission</ng-template>
                                <ng-template *ngIf="i % 2 == 1" >Followup Discharge</ng-template>
                                <div >
                                    <!*--------------------------------------------- ADMISSION --------------------------------------------->
                                    <ng-container *ngIf="i%2==0" class="p-2 ">
<mat-card class="admission-view p-1">
    <p *ngIf="i == 0" > Unique Admission</p>
    <p *ngIf="i == 1" > Unique Discharge</p>
    <p *ngIf="i % 2 == 0" >Followup Admission</p>
    <p *ngIf="i % 2 == 1" >Followup Discharge</p>
                                        <mat-form-field class="mb-2">
                                            <mat-label>Date of Admission:</mat-label>
                                            <input matInput [matDatepicker]="admissionDatePicker"  formControlName="dateOfAdmission" (dateChange)="updateAdmissionDate($event)">
                                            <mat-hint>DD/MM/YYYY</mat-hint>
                                            <mat-datepicker-toggle matSuffix [for]="admissionDatePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #admissionDatePicker></mat-datepicker>
                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline">
                                                                            <mat-label >Centre Code:</mat-label>

                                                                            <input matInput  type="text" formControlName="centreCode" >

                                                                        </mat-form-field>
                                                                        <mat-form-field appearance="outline">
                                                                            <mat-label >Current Unit:</mat-label>

                                                                            <input matInput  type="text" formControlName="currentUnit" >

                                                                        </mat-form-field>

</mat-card>
                                    </ng-container>
                                    <!*--------------------------------------------- DISCHARGE  --------------------------------------------->
                                    <ng-container *ngIf="i%2!=0" class="discharge-view p-2 ">
                                        <mat-card class="discharge-view p-1">
                                            <p *ngIf="i == 0" > Unique Admission</p>
                                            <p *ngIf="i == 1" >Unique Discharge</p>
                                            <p *ngIf="i % 2 == 0" >Followup Admission</p>
                                            <p *ngIf="i % 2 == 1" >Followup Discharge</p>
                                            <mat-form-field class="" appearance="outline">
                                                <mat-label>Choose a date</mat-label>
                                                <input matInput [matDatepicker]="dischargePicker" formControlName="dateOfDischarge" >
                                                <mat-hint>DD/MM/YYYY</mat-hint>
                                                <mat-datepicker-toggle matSuffix [for]="dischargePicker"></mat-datepicker-toggle>
                                                <mat-datepicker #dischargePicker></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field  appearance="outline">
                                                <mat-label >Centre Code:</mat-label>

                                                <input matInput  type="text" formControlName="centreCode" >

                                            </mat-form-field>
                                            <mat-form-field appearance="outline">
                                                <mat-label >Current Unit:</mat-label>

                                                <input matInput  type="text" formControlName="currentUnit" >

                                            </mat-form-field>

                                            <!--                <input type="date" [(ngModel)]="d.dateOfDischarge.seconds" (change)="updateDate(d)">-->

                                        </mat-card>

                                    </ng-container>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
<div class="d-flex justify-content-center">
    <button mat-raised-button color="accent" (click)="updateHistory()">Update  </button>
</div>
            </div>

        </div>



    </form>



</div>
</div>
