import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {Centre} from "../../interfaces/Centres";
import {CentreService} from "../../services/centre.service";
import {MatDialog} from "@angular/material/dialog";
import {AddUnitSponsorshipComponent} from "./add-unit-sponsorship/add-unit-sponsorship.component";
import {MatTableDataSource} from "@angular/material/table";
import {
  ViewIndividualAssessmentComponent
} from "../../modules/counseling/components/view-individual-assessment/view-individual-assessment.component";
import {TransferUnitSponsorshipComponent} from "./transfer-unit-sponsorship/transfer-unit-sponsorship.component";
import {RenewalUnitSponsorshipComponent} from "./renewal-unit-sponsorship/renewal-unit-sponsorship.component";
import {SponsorshipImageComponent} from "./sponsorship-image/sponsorship-image.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatSelectChange} from "@angular/material/select";
import {UnitSponsorshipService} from "./unit-sponsorship.service";
import {UserRole} from "../rename/add-user-role/add-user-role.component";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
export interface SponsorData {
  sponsor: {
    paymentInformation: string;
    donorName: string;
    date2: { seconds: number; nanoseconds: number };
    remark: string;
    mobile: string;
    panNumber: string;
    id: string;
    date: { seconds: number; nanoseconds: number };
    country: string;
    city: string;
    state: string;
    pincode: string;
    amount: string;
    email: string;
    address: string;
    angleName: string;
  };
  children: {
    duration: string;
    firstName: string;
    lastName: string;
    centreName: string;
    unit: string;
    amount: number;
    childCode: string;
    endDate: { seconds: number; nanoseconds: number };
    centreCode: string;
    startDate: { seconds: number; nanoseconds: number };
  }[];
  totalAmount: number;
  id: string;
}

@Component({
  selector: 'app-unit-sponsorship',
  templateUrl: './unit-sponsorship.component.html',
  styleUrls: ['./unit-sponsorship.component.scss']
})

export class UnitSponsorshipComponent implements OnInit,OnDestroy {
  _destroyed$=new Subject()
  selectedCentre?:string|null=''
  centres$: Observable<Centre[]>
  displayedColumns: string[] = ['SR. N0', 'Profile', 'ChildName', 'Unit Allowed', 'Sponsorship Program', 'Donor Name', 'Mobile No.', 'Payment Terms', 'Action'];
  dataSource:any
  data:any[]   = []
  allpermission = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  unitSponsorship = this.allpermission.filter((x) => x.functionName == 'Unit Sponsorship')
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;
  constructor(private centreService: CentreService,
              private matdialog: MatDialog,
              private readonly matDialog: MatDialog,
              private readonly mFirestore: AngularFirestore,
              private unitSponsorshipService: UnitSponsorshipService
              ) {
    this.centres$ = this.centreService.getAllCentres()

  }

  ngOnInit(): void {

  }

  AddUnitSponsorship() {
    this.matdialog.open(AddUnitSponsorshipComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )

  }

  Search($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource) {
      this.dataSource.firstPage();
    }

  }

  transfer(element:any) {
    this.matDialog.open(TransferUnitSponsorshipComponent, {
      data: element,
      width: '800%',
      height: '90%'
    })

  }

  renewal(element:any) {
    this.matDialog.open(RenewalUnitSponsorshipComponent, {
      data: element,
      width: '800%',
      height: '90%'
    })

  }

  openPoster(element:any) {
    this.matDialog.open(SponsorshipImageComponent, {
      data: element,
      width: '800%',
      height: '90%'
    })


  }

  ngOnDestroy(): void {
    this._destroyed$.next('')
    this._destroyed$.complete()
  }


  onCentreSelect($event: MatSelectChange) {

 let query = this.unitSponsorshipService.getSponsorByCentre($event.value)
    if($event.value=='All')query= this.unitSponsorshipService.getSponsor()
query.pipe(takeUntil(this._destroyed$)).subscribe(res=>{
  this.data =res
  this.dataSource = new MatTableDataSource<any>(this.data)
  this.dataSource.data =this.data
  console.log("data source",this.dataSource.data)
  this.dataSource.data.paginator = this.paginator
  this.dataSource.data.sort = this.sort


})
  }
}
