<div class="container">
<div class="row">
  <div class="col-md-3">
  <div class=" mt-3" routerLink="../payments">
  <mat-card>
<h2><mat-icon class="scale-icon">alarm</mat-icon>  &nbsp;&nbsp;&nbsp; Payments</h2>
  </mat-card>
  </div>
  </div>
  <div class="col-md-4">
    <div class=" mt-3" routerLink="../followup-admissions">
      <mat-card>
        <h2><mat-icon class="scale-icon">edit</mat-icon>  &nbsp;&nbsp;&nbsp; Follow Up Admissions</h2>
      </mat-card>
    </div>
  </div>
  <div class="col-md-3"></div>
  <div class="col-md-3"></div>
</div>


</div>
