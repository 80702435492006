<h2 class="text-center">Child Health information</h2>

<form  class="about" [formGroup]="childguardianForm">
  <div class="row">

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Doctor's Name</mat-label>
        <input matInput placeholder="Doctor Name" required formControlName="doctorname">
        <mat-error *ngIf="doctorname.invalid  &&  (doctorname.touched || doctorname.dirty)">
        <mat-error style="color: red;" *ngIf="doctorname.errors?.required">*It is  Required.</mat-error>
        <mat-error style="color: red;" *ngIf="doctorname.errors?.pattern">*Enter Only Characters.</mat-error>
      </mat-error>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>File Number</mat-label>
        <input matInput placeholder="File Number" required formControlName="fileno" >
      </mat-form-field>
      <mat-error *ngIf="fileno.invalid  &&  (fileno.touched ||fileno.dirty)">
        <mat-error style="color: red;" *ngIf="fileno.errors?.required">*It is  Required.</mat-error>
        <mat-error  style="color: red;" *ngIf="fileno.errors?.pattern">*Enter Only Number.</mat-error>
      </mat-error>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Social Worker Name</mat-label>
        <input matInput placeholder="Social Worker Name" required formControlName="socialname" >
      </mat-form-field>
      <mat-error *ngIf="  socialname.invalid  &&  (socialname.touched || socialname.dirty)">
        <mat-error style="color: red;" *ngIf="socialname.errors?.required">*It is  Required.</mat-error>
        <mat-error  style="color: red;" *ngIf="socialname.errors?.pattern">*Enter Only Characters.</mat-error>
      </mat-error>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Height at the Time of Admission(cm)</mat-label>
        <input matInput placeholder="Height"   required   formControlName="height">
      </mat-form-field>
      <mat-error *ngIf="height.invalid  &&  (height.touched || height.dirty)">
        <mat-error style="color: red;" *ngIf="height.errors?.required">*It is  Required.</mat-error>
        <mat-error  style="color: red;" *ngIf="height.errors?.pattern">*Enter Only Number.</mat-error>
      </mat-error>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Weight at the Time of Admission(kg)</mat-label>
        <input matInput placeholder="Weight" maxlength="12"  required   formControlName="weight">
      </mat-form-field>
      <div *ngIf="weight.invalid  &&  (weight.touched || weight.dirty)">
        <span style="color: red;" *ngIf="weight.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="weight.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Prescribed Treatment</mat-label>
        <input matInput placeholder="Prescribed Treatment" required   formControlName="prescribed">
      </mat-form-field>
      <div *ngIf="prescribed.invalid  &&  (prescribed.touched || prescribed.dirty)">
        <span style="color: red;" *ngIf="prescribed.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="prescribed.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>How Did you Learn about the Sickness of the Child,What did you do then?</mat-label>
        <input matInput placeholder="Sickness" required    formControlName="sickness">
      </mat-form-field>
      <div *ngIf="sickness.invalid  &&  (sickness.touched ||sickness.dirty)">
        <span style="color: red;" *ngIf="sickness.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="sickness.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>


    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Hospital</mat-label>
        <mat-select formControlName="hospital" required>
          <mat-option  *ngFor="let hospital of hospital" [value]="hospital">{{hospital}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Allergies</mat-label>
        <mat-select formControlName="allergies" required>
          <mat-option  *ngFor="let allergies of allergies" [value]="allergies">{{allergies}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Diagnosis</mat-label>
        <mat-select formControlName="diagnosis" required>
          <mat-option  *ngFor="let diagnosis of diagnosis" [value]="diagnosis">{{diagnosis}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <p>Expected Duration of Treatment</p>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Months</mat-label>
        <mat-select formControlName="months" required>
          <mat-option  *ngFor="let months of months" [value]="months">{{months}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Days</mat-label>
        <input matInput placeholder="Days" required   formControlName="days">
<!--        <mat-select formControlName="days" required>-->
<!--&lt;!&ndash;          <mat-option  *ngFor="let days of days" [value]="days">{{days}}</mat-option>&ndash;&gt;-->
<!--        </mat-select>-->
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Treatment Duration</mat-label>
        <input matInput placeholder="Treatment Duration" required   formControlName="treatment">
      </mat-form-field>
      <div *ngIf="treatment.invalid  &&  (treatment.touched ||treatment.dirty)">
        <span style="color: red;" *ngIf="treatment.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="treatment.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Other Medical Information</mat-label>
        <textarea matInput placeholder="Medical Information" required   formControlName="medical"></textarea>

      </mat-form-field>
      <div *ngIf="medical.invalid  &&  (medical.touched ||medical.dirty)">
        <span style="color: red;" *ngIf="medical.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="medical.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

  </div>
  <button color="warn" mat-raised-button (click)="cancel()" >Cancel</button> &nbsp;
  <button color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>


</form>

