<h2 class="text-center">Guardian</h2>
<form  class="about" [formGroup]="childguardianForm">
  <div class="row">

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" required formControlName="firstname">
      </mat-form-field>
      <div *ngIf="firstname.invalid  &&  (firstname.touched || firstname.dirty)">
        <span style="color: red;" *ngIf="firstname.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="firstname.errors?.pattern">*Enter Only Characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Middle Name</mat-label>
        <input matInput placeholder="Middle Name" required formControlName="middlename" >
      </mat-form-field>
      <div *ngIf="middlename.invalid  &&  (middlename.touched ||middlename.dirty)">
        <span style="color: red;" *ngIf="middlename.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="middlename.errors?.pattern">*Enter Only Characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" required formControlName="lastname" >
      </mat-form-field>
      <div *ngIf="  lastname.invalid  &&  (lastname.touched || lastname.dirty)">
        <span style="color: red;" *ngIf="lastname.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="lastname.errors?.pattern">*Enter Only Characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Aadhaar Number</mat-label>
        <input matInput placeholder="Aadhaar Number" maxlength="12"  required   formControlName="aadhaarnumber">
      </mat-form-field>
      <div *ngIf="aadhaarnumber.invalid  &&  (aadhaarnumber.touched || aadhaarnumber.dirty)">
        <span style="color: red;" *ngIf="aadhaarnumber.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="aadhaarnumber.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Relation</mat-label>
        <mat-select formControlName="relation" required>
          <mat-option  *ngFor="let relation of relation" [value]="relation">{{relation}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Occupation</mat-label>
        <input matInput placeholder="Occupation" required   formControlName="occupation">
      </mat-form-field>
      <div *ngIf="occupation.invalid  &&  (occupation.touched || occupation.dirty)">
        <span style="color: red;" *ngIf="occupation.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="occupation.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Contact No.</mat-label>
        <input matInput placeholder="Contact No" required  maxlength="10"  formControlName="contactno">
        <mat-icon matSuffix>phone</mat-icon>
      </mat-form-field>
      <div *ngIf="contactno.invalid  &&  (contactno.touched || contactno.dirty)">
        <span style="color: red;" *ngIf="contactno.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="contactno.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Alternative Contact No.</mat-label>
        <input matInput placeholder="Alternative Contact No" maxlength="10"   required   formControlName="altcontactno">
        <mat-icon matSuffix>phone</mat-icon>
      </mat-form-field>
      <div *ngIf="altcontactno.invalid  &&  (altcontactno.touched || altcontactno.dirty)">
        <span style="color: red;" *ngIf="altcontactno.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="altcontactno.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Election ID of Parent</mat-label>
        <input matInput placeholder="Election ID of Parent" required   formControlName="electionid">
      </mat-form-field>
      <div *ngIf="electionid.invalid  &&  (electionid.touched || electionid.dirty)">
        <span style="color: red;" *ngIf="electionid.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="electionid.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Ration Card No.</mat-label>
        <input matInput placeholder="Ration Card No." required   formControlName="rationcard">
      </mat-form-field>
      <div *ngIf="rationcard.invalid  &&  (rationcard.touched || rationcard.dirty)">
        <span style="color: red;" *ngIf="rationcard.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="rationcard.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Driving License of Parent</mat-label>
        <input matInput placeholder="Driving License of Parent" required   formControlName="drivinglicense">
      </mat-form-field>
      <div *ngIf="drivinglicense.invalid  &&  (drivinglicense.touched || drivinglicense.dirty)">
        <span style="color: red;" *ngIf="drivinglicense.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="drivinglicense.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Other Documents</mat-label>
        <input matInput placeholder="Other Documents" required   formControlName="documents">
      </mat-form-field>
      <div *ngIf="documents.invalid  &&  (documents.touched || documents.dirty)">
        <span style="color: red;" *ngIf="documents.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="documents.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Skill</mat-label>
        <mat-select formControlName="skill" required>
          <mat-option  *ngFor="let skill of skill" [value]="skill">{{skill}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </div>
    <button color="warn" mat-raised-button (click)="cancel()" >Cancel</button> &nbsp;
    <button color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>


</form>
