
<h1 class="bg-warning rounded text-dark text-center">Add Glossary Section</h1>
<form [formGroup]="menuForm" (ngSubmit)="submitForm()">
  <div class="d-flex flex-column">
  <mat-form-field >
    <mat-label>Select a Menu</mat-label>
    <mat-select formControlName="menu">
      <mat-option value="dashboard">Dashboard</mat-option>
      <mat-option value="child-admission">Child-Admission</mat-option>
      <mat-option value="counselling">Counselling</mat-option>
      <mat-option value="education">Education</mat-option>
      <mat-option value="units">Units</mat-option>
      <mat-option value="types">Types</mat-option>
      <mat-option value="inventory">Inventory</mat-option>
      <mat-option value="reports">Reports</mat-option>
      <mat-option value="donation-report">Donation Report</mat-option>
      <mat-option value="child-report">Child Report</mat-option>
      <mat-option value="admission-report">Admission Report</mat-option>
      <mat-option value="weekly-report">Weekly Report</mat-option>
      <mat-option value="donation">Donation</mat-option>
      <mat-option value="mealmate">Mealmate</mat-option>
      <mat-option value="unit-sponsorship">Unit Sponsorship</mat-option>
      <mat-option value="reminders">Reminders</mat-option>
      <mat-option value="cms">CMS</mat-option>
      <mat-option value="masters">Masters</mat-option>
      <mat-option value="centres">Centres</mat-option>
      <mat-option value="users">Users</mat-option>
      <mat-option value="hospital">Hospital</mat-option>
      <mat-option value="donor">Donor</mat-option>
      <mat-option value="events">Events</mat-option>
      <mat-option value="audit-logs">Audit Logs</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field >
    <input matInput type="text" formControlName="name" placeholder="Name">
  </mat-form-field>

  <mat-form-field>
    <textarea matInput type="text" formControlName="description" placeholder="Description" rows="6"></textarea>
  </mat-form-field>
<div class="d-flex justify-content-center">
  <button mat-raised-button color="warn" type="reset" (click)="closeDialog()" >Cancel</button>

  <button mat-raised-button color="primary" type="submit" class="mx-2">Submit</button>
</div>
  </div>
</form>
