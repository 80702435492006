<form [formGroup]="addressForm"  >
  <mat-card class="shipping-card">
      <mat-card-title class="w-100 mb-5 mx-auto text-center">{{data.element.hospitalName}}</mat-card-title>

    <mat-card-content>
      <!--      <div class="row">-->
      <!--        <div class="col">-->
      <!--          <mat-form-field class="full-width">-->
      <!--            <input matInput placeholder="Company" formControlName="company">-->
      <!--          </mat-form-field>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="row">

        <div class="col-md-10">
          <mat-form-field class="full-width">
            <input matInput (blur)="onBlur(hospitalName)" placeholder="Hospital Name" formControlName="hospitalName">
            <mat-error *ngIf="addressForm.controls['hospitalName'].hasError('required')">
              Hospital name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field class="full-width">
            <input matInput  placeholder="Short Name" formControlName="shortName">
            <mat-error *ngIf="addressForm.controls['shortName'].hasError('required')">
              Hospital Short name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>



        <div class="col-md-6">
          <mat-form-field class="full-width">
            <input matInput (blur)="onBlur(doctorsName)" placeholder="Doctors Name" formControlName="doctorsName">
            <mat-error *ngIf="addressForm.controls['doctorsName'].hasError('required')">
              Hospital name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="full-width">
            <input matInput (blur)="onBlur(socialWorkerName)" placeholder="Social Worker Name" formControlName="socialWorkerName">
            <mat-error *ngIf="addressForm.controls['socialWorkerName'].hasError('required')">
              Hospital name is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea matInput (blur)="onBlurSentence(address)"  placeholder="Address" formControlName="address"></textarea>
            <mat-error *ngIf="addressForm.controls['address'].hasError('required')">
              Address is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput  placeholder="City" formControlName="city">
            <mat-error *ngIf="addressForm.controls['city'].hasError('required')">
              City is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="State" formControlName="state">
              <mat-option *ngFor="let state of mState" [value]="state.name">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
              State is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput #postalCode maxlength="6" placeholder="Pin Code" type="number" formControlName="zipcode">
            <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
          </mat-form-field>
        </div>
      </div>
      <!--      <div class="row">-->
      <!--        <div class="col">-->
      <!--          <mat-radio-group formControlName="shipping">-->
      <!--            <mat-radio-button value="free">Free Shipping</mat-radio-button>-->
      <!--            <mat-radio-button value="priority">Priority Shipping</mat-radio-button>-->
      <!--            <mat-radio-button value="nextday">Next Day Shipping</mat-radio-button>-->
      <!--          </mat-radio-group>-->
      <!--        </div>-->
      <!--      </div>-->
    </mat-card-content>
    <mat-card-actions class="text-center">
<!--      <button mat-raised-button color="accent" type="button" (click)="onCancel()">Cancel</button>-->
<!--      <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Submit</button>-->
    </mat-card-actions>
  </mat-card>
</form>

<!--<prettyjson [obj]="data.element.id"></prettyjson>-->
  <div class="d-flex justify-content-center mt-3 mb-4 ">
  <button mat-raised-button color="primary" type="submit" class="btn btn-success"
          (click)="Submit()" [disabled]="addressForm.invalid">Update
  </button>&nbsp;&nbsp;
  <button mat-raised-button color="warn" type="button" (click)="close()"
          class="btn btn-danger">Cancel
  </button>
</div>
