import {Component, OnInit, ViewChild} from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  series2: ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  labels: string[];
  responsive: ApexResponsive[];
};

@Component({
  selector: 'app-centres-chart',
  templateUrl: './centres-chart.component.html',
  styleUrls: ['./centres-chart.component.scss']
})
export class CentresChartComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  public pieChartOptions!: Partial<ChartOptions>;
  constructor() {
    this.chartOptions = {
      series: [
        {
          name: 'Angular',
          data: [45, 52, 38, 24, 33, 26, 21],
        },
        {
          name: 'Wordpress',
          data: [35, 41, 62, 42, 13, 18, 29],
        },
        {
          name: 'Java',
          data: [87, 57, 74, 99, 75, 38, 62],
        },
      ],
      chart: {
        height: 300,
        type: 'line',
        foreColor: '#9aa0ac',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 5,
        curve: 'straight',
        dashArray: [0, 8, 5],
      },

      legend: {
        tooltipHoverFormatter: function (val, opts) {
          return (
              val +
              ' - <strong>' +
              opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
              '</strong>'
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        labels: {
          trim: false,
        },
        categories: ['2010', '2011', '2012', '2013', '2014', '2015', '2016'],
      },
      tooltip: {
        theme: 'dark',
        y: [
          {
            title: {
              formatter: function (val: string) {
                return val + ' (mins)';
              },
            },
          },
          {
            title: {
              formatter: function (val: string) {
                return val + ' per session';
              },
            },
          },
          {
            title: {
              formatter: function (val: string) {
                return val;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
      },
    };
  }

  private smallChart2() {
    this.pieChartOptions = {
      series2: [44, 55, 13, 43, 22],
      chart: {
        type: 'donut',
        width: 200,
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      labels: ['Project 1', 'Project 2', 'Project 3', 'Project 4', 'Project 5'],
      responsive: [
        {
          breakpoint: 480,
          options: {},
        },
      ],
    };
  }

  ngOnInit() {
    this.smallChart2();
  }

}
