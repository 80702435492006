import {Component, isDevMode, OnInit} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {CreateEventComponent} from "./create-event/create-event.component";
import {AngularFirestore, DocumentChangeAction} from "@angular/fire/compat/firestore";
import {Observable, Subscription} from "rxjs";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {MatButtonToggleChange} from "@angular/material/button-toggle";
import {Events} from "../../interfaces/FullChild";
import {Router} from "@angular/router";
import {Centre} from "../../interfaces/Centres";
import {environment} from "../../../environments/environment";
import {UserRole} from "../rename/add-user-role/add-user-role.component";

export interface ChildObject {
    child_code: string,
    child_name: string
}

export interface CentreObject {
    id: string,
    centreName: string
}

export interface EventModel {
    eventName: string;
    key: string;
    description: string;
    eventDate: Timestamp;
    eventType: string;
    eventSort: 'all' | 'child' | 'centre'
    eventTime: Timestamp;
    name: string;
    email: string;
    eventFor: string;
    code: CentreObject[]
    number: string
    eventLocations: []
    events: Events[]
}


@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
    events$: Observable<EventModel[]>
    env = isDevMode() ? environment.testMode : environment.productionMode
    admissions:UserRole[] = (localStorage.getItem('permissions') != null) ?  JSON.parse(localStorage.getItem('permissions')!) as UserRole[]: [
        {
            functionName: "Child Admission",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Child Prescreening",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Counselling",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Education",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Meal Mate",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Unit Sponsorship",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "General Donation",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "CMS",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Child Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Counseling Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Education Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Donation Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Weekly Report",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Centres",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Employees",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Hospital",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Unit",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Types",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Donor",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Event",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Audit Logs",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        },
        {
            functionName: "Dashboard",
            all: false,
            add: false,
            edit: false,
            archive: false,
            view: false,
            excel: false,
            showMenu: false
        }
    ];

    Events = this.admissions.filter((x) => x.functionName == 'Event')

    // mRef:Observable<EventModel[]>

    constructor(private matDialog: MatDialog,
                private mFirestore: AngularFirestore,
                private router: Router) {
        // this.mRef   =  mFirestore.collection<EventModel>('events').valueChanges()
        this.events$ = mFirestore.collection<EventModel>(this.env.events).valueChanges({idField: 'key'})
    }

    ngOnInit(): void {

    }

    openCreateCenterDialog() {
        this.matDialog.open(CreateEventComponent)
    }

    selectionChange($event: MatButtonToggleChange) {
        console.log($event.value)
        switch ($event.value) {
            case 'all' : {
                this.events$ = this.mFirestore.collection<EventModel>(this.env.events).valueChanges({idField: 'key'})
                break;
            }
            case 'Child' : {
                console.log('Child selected')
                this.events$ = this.mFirestore.collection<EventModel>(this.env.events, ref => ref.where('eventFor', '==', `${$event.value}`)).valueChanges({idField: 'key'})
                break;
            }
            case 'Centre': {
                console.log('Centre selected')
                this.events$ = this.mFirestore.collection<EventModel>(this.env.events, ref => ref.where('eventFor', '==', `${$event.value}`)).valueChanges()
                break;
            }
            default: {
                this.events$ = this.mFirestore.collection<EventModel>(this.env.events).valueChanges()
                break;
            }
        }
    }

    // ViewEvent(key: any) {
    //   console.log('EVENT KEY',key)
    // this.router.navigate(['Events',{id:key}])
    // }


    getCenterName(l: string): string {
        console.log('getCEntre', l)
        let name = ''
        this.mFirestore.firestore.doc(`${this.env.centres}/${l}`).get().then(res => {
            let d: Centre;
            d = res as unknown as Centre;
            name = d?.centreName!
        })
        return name
    }
}
