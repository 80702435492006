<mat-card class="figure-card card">
    <div class="card-header" [ngStyle]="{ 'background': linearColor, 'box-shadow': boxShadow }">
        <i class="material-icons">{{ headerIcon }}</i>
    </div>
    <div class="card-content">
        <p class="category">{{ category }}</p>
        <h3 class="title">{{ title }}</h3>
    </div>
    <div class="card-footer" hidden *ngIf="footContent">
        <i class="material-icons text-danger">{{ footerIcon }}</i>
    </div>
</mat-card>
