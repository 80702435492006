<div class="card">
    <div class="header">
        <h2>Chart Sample</h2>
    </div>
    <div class="body">
        <div id="chart">
            <apx-chart [series]="pieChartOptions.series2!" [chart]="pieChartOptions.chart!"
                       [labels]="pieChartOptions.labels!" [responsive]="pieChartOptions.responsive!"
                       [dataLabels]="pieChartOptions.dataLabels!" [legend]="pieChartOptions.legend!" class="apex-pie-center">
            </apx-chart>
        </div>
        <div class="table-responsive m-t-15">
            <table class="table align-items-center">
                <tbody>
                <tr>
                    <td><i class="fa fa-circle col-blue msr-2"></i>Project 1</td>
                    <td>44</td>
                    <td class="col-green">+12%</td>
                </tr>
                <tr>
                    <td><i class="fa fa-circle col-green msr-2"></i>Project 2</td>
                    <td>55</td>
                    <td class="col-orange">-12%</td>
                </tr>
                <tr>
                    <td><i class="fa fa-circle col-orange msr-2"></i>Project 3</td>
                    <td>13</td>
                    <td class="col-green">+3%</td>
                </tr>
                <tr>
                    <td><i class="fa fa-circle col-red msr-2"></i>Project 3</td>
                    <td>43</td>
                    <td class="col-green">+3%</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
