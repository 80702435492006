<div class="card mt-2  p-3 ">
    <h5 class="header">Children Data (By Age Group)</h5>
<div class="body">
    <canvas baseChart
  [datasets]="barChartData"
  [labels]="barChartLabels"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="barChartLegend"
  [type]="barChartType">
</canvas>
</div>
</div>
