import {Component, Inject, OnInit, ViewChild,AfterViewInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatDialog} from "@angular/material/dialog";
import {CentreService} from "../../../services/centre.service";
import {MatSort} from '@angular/material/sort';
import {Centre} from "../../../interfaces/Centres";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {AngularFireDatabase} from "@angular/fire/compat/database";
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}

@Component({
  selector: 'app-meal-slots',
  templateUrl: './meal-slots.component.html',
  styleUrls: ['./meal-slots.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MealSlotsComponent implements AfterViewInit {
  allCentres:Centre[]=[];
slotData:any
  constructor(private matdialog: MatDialog,
              private centreService: CentreService,
              private  mDatabase:AngularFireDatabase
            ) {
    // Create 100 users
   const center = Array.from({length: 100});
    // Assign the data to the data source for the assessment-table to render
    this.dataSource = new MatTableDataSource(this.allCentres);
  }

  columnsToDisplay: String[] = ['centername', 'city','availableunits', 'occupiedunits','totalunits', 'Action'];

  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: PeriodicElement | null = null;

  dataSource: MatTableDataSource<Centre>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;


  ngAfterViewInit() {
    const map = new Map<string, string>()
    map.set('sf', 'fsf')
    this.mDatabase.object('dummy/units/test').set(map).then(() => {
      console.log('added')
    }).catch((reason) => {
      console.log(reason)
    })

    this.centreService.getAllCentres().subscribe(centres => {
      this.allCentres = []
      this.allCentres = centres
      //   centres.forEach(c=>{
      //     // console.log("payload: ",c.payload.toJSON())
      //     let centre = c.payload.doc.data() as Centre
      //     let key =c.payload.doc.id
      //
      //     // @ts-ignore
      //     if (c.payload.doc.id != null) {
      //       centre['$key'] = c.payload.doc.id;
      //     }
      //     this.allCentres.push(centre)
      //     this.dataSource = new MatTableDataSource(this.allCentres);
      //     this.dataSource.paginator = this.paginator;
      //   })
      // })

    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addMeal(){

  }
}
