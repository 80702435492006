import {Component, Input, OnInit} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexGrid, ApexLegend,
  ApexMarkers, ApexPlotOptions,
  ApexStroke, ApexTitleSubtitle, ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-apex-age-chart',
  templateUrl: './apex-age-chart.component.html',
  styleUrls: ['./apex-age-chart.component.scss']
})
export class ApexAgeChartComponent implements  OnInit {
  public areaChartOptions!: Partial<ChartOptions>;
  @Input() ageArrayInput:number[]=[];
  @Input() followUpArray:number[]=[];

  constructor() {
  }
  ngOnInit(): void {
    this.areachart()
  }
  private areachart() {

    this.areaChartOptions = {
      title: {text:'Age Chart'},
      series: [

        {
          name: 'Unique Children',
          data: this.ageArrayInput,
        },
        {
          name: 'Follow Up Children',
          data: this.followUpArray,
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: true,
        },
        foreColor: '#9aa0ac',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      colors: ['#0e28a6', '#e83108'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: ['0-3', '4-7','8-10' ,'11-14','Others'],
        // categories: [
        //     '2018-09-19',
        //     '2018-09-20',
        //     '2018-09-21',
        //     '2018-09-22',
        //     '2018-09-23',
        //     '2018-09-24',
        //     '2018-09-25',
        // ],
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        offsetX: 0,
        offsetY: 0,
      },
      grid: {
        show: true,
        borderColor: '#9aa0ac',
        strokeDashArray: 1,
      },
      tooltip: {
        theme: 'dark',
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };
  }
}
