import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-adminssion-faq',
  templateUrl: './adminssion-faq.component.html',
  styleUrls: ['./adminssion-faq.component.scss']
})
export class AdminssionFaqComponent implements OnInit {
  faqForm!: FormGroup

  constructor() {
  }

  ngOnInit(): void {
    this.faqForm = new FormGroup({
      q1: new FormControl(''),
      q2: new FormControl(''),
      q3: new FormControl(''),
      q4: new FormControl(''),
      q5: new FormControl(''),
      q6: new FormControl(''),
      q7: new FormControl(''),
      q8: new FormControl(''),
      q9: new FormControl(''),

    })
  }

}
