<div class="container">
    <h2 class="rounded text-center mt-2">Edit Follow Up Reminder</h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" >


            <mat-form-field class="col-md-12 col-sm-12" appearance="outline">
                <mat-label>Child Name</mat-label>
                <input type="text" matInput formControlName="childName" />
            </mat-form-field>

            <mat-form-field class="col-md-12 col-sm-12" appearance="outline">
                <mat-label>Follow-up Date</mat-label>
                <input matInput [matDatepicker]="followUpPicker" formControlName="followUpDate" />
                <mat-datepicker-toggle matSuffix [for]="followUpPicker"></mat-datepicker-toggle>
                <mat-datepicker #followUpPicker></mat-datepicker>
                <mat-hint>dd/MM/yyyy</mat-hint>
            </mat-form-field>

            <mat-form-field class="col-md-12 col-sm-12 mt-1"  appearance="outline">
                <mat-label>Remark</mat-label>
                <textarea matInput formControlName="remark"></textarea>
            </mat-form-field>


        <div class="d-flex justify-content-center">
            <button mat-raised-button  color="warn" (click)="onDelete()">Cancel</button>&nbsp;
            <button mat-raised-button type="submit" [disabled]="form.invalid" color="primary" >Submit</button>
        </div>
    </form>
</div>
