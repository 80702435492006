import { Component, EventEmitter , OnInit, Output } from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AuthService} from "../../auth.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  email : string | null | undefined
  username:string|undefined
  isNavbarCollapsed = true;

  @Output()toggledEvent:any=new EventEmitter();
  constructor(private mAuth:AngularFireAuth,private mService:AuthService,
              private readonly router:Router) {
    this.mAuth.user.subscribe((user)=>{
      console.log(user?.email)
      this.email = user?.email
    })
    const userData=JSON.parse( localStorage.getItem('userData')!)
    this.username=userData?.userName
  }

  ngOnInit(): void {
 this.toggledAction()
  }

  toggledAction(){
    this.toggledEvent.emit('');
  }
  logout(){
    // localStorage.clear()
this.router.navigateByUrl('/logout')  }
}
