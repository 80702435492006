<div class="container">

  <br>
  <h3> Donation Registration Form</h3>
  <br>

  <form  class="about" [formGroup]="donationForm">
    <div class="row">

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Center Name</mat-label>
          <input matInput placeholder="Center Name  " required formControlName="centername">
        </mat-form-field>
        <div *ngIf="  centername.invalid  &&  (centername.touched || centername.dirty)">
          <span style="color: red;" *ngIf="centername.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="centername.errors?.pattern">*Enter Only Numbers.</span>
        </div>
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Street Line 1</mat-label>
          <input matInput placeholder="Street line 1" required formControlName="streetline1" >
        </mat-form-field>
        <div *ngIf="  streetline1.invalid  &&  (streetline1.touched ||streetline1.dirty)">
          <span style="color: red;" *ngIf="streetline1.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="streetline1.errors?.pattern">*Enter Only Numbers.</span>
        </div>
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Street Line 2</mat-label>
          <input matInput placeholder="Centerno" required formControlName="streetline2" >
        </mat-form-field>
        <div *ngIf="  streetline2.invalid  &&  (streetline2.touched || streetline2.dirty)">
          <span style="color: red;" *ngIf="streetline2.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="streetline2.errors?.pattern">*Enter Only Numbers.</span>
        </div>
      </div>

      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>Date of Birth</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="DOB"  required formControlName="dob">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <!--    pattern="[0-9]{10}$"-->
      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>Mobile No.</mat-label>
          <input matInput placeholder="Mobileno" required   formControlName="mobile">
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>
        <div *ngIf="  mobile.invalid  &&  (mobile.touched || mobile.dirty)">
          <span style="color: red;" *ngIf="mobile.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="mobile.errors?.pattern">*Enter Only Number.</span>
        </div>
      </div>

      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput placeholder="City" required formControlName="city">
        </mat-form-field>
        <div *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
          <span style="color: red;" *ngIf="city.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>

      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <input matInput placeholder="State Name"  required formControlName="state">
        </mat-form-field>
        <div *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
          <span style="color: red;" *ngIf="state.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>

      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <input matInput placeholder="Country Name" required formControlName="country">
        </mat-form-field>
        <div *ngIf="  country.invalid  &&  (country.touched || country.dirty)">
          <span style="color: red;" *ngIf="country.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="country.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>

      <div class="col-5">
        <mat-form-field appearance="outline">
          <mat-label>Pin Code</mat-label>
          <input matInput #postalCode maxlength="6" required  placeholder="pincode" formControlName="pincode"  >
          <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
        </mat-form-field>
        <div *ngIf="  pincode.invalid  &&  (pincode.touched || pincode.dirty)">
          <span style="color: red;" *ngIf="pincode.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="pincode.errors?.pattern">*Enter Only Number.</span>
        </div>
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Occupation</mat-label>
          <input matInput placeholder="Diagnosis" required formControlName="occupation">
        </mat-form-field>
        <div *ngIf="  occupation.invalid  &&  (occupation.touched || occupation.dirty)">
          <span style="color: red;" *ngIf="occupation.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="occupation.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Date</mat-label>
          <input matInput  [matDatepicker]="picker" placeholder="Date" required  formControlName="date">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
<!--        <div *ngIf="  Hobbies.invalid  &&  (Hobbies.touched || Hobbies.dirty)">-->
<!--          <span style="color: red;" *ngIf="Hobbies.errors?.required">*It is  Required.</span>-->
<!--          <span  style="color: red;" *ngIf="Hobbies.errors?.pattern">*Enter Only characters.</span>-->
<!--        </div>-->
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Types</mat-label>
          <input matInput placeholder="Types" required formControlName="types">
        </mat-form-field>
        <div *ngIf="  types.invalid  &&  (types.touched ||types.dirty)">
          <span style="color: red;" *ngIf="types.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="types.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>How Would you like to Support us</mat-label>
          <input matInput placeholder="s" required formControlName="support">
        </mat-form-field>
        <div *ngIf="  support.invalid  &&  (support.touched || support.dirty)">
          <span style="color: red;" *ngIf="support.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="support.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>

      <div class="col-10">
        <mat-form-field appearance="outline">
          <mat-label>Need Follow Up</mat-label>
          <input matInput placeholder="s" required formControlName="needfollow">
        </mat-form-field>
        <div *ngIf="  needfollow.invalid  &&  (needfollow.touched || needfollow.dirty)">
          <span style="color: red;" *ngIf="needfollow.errors?.required">*It is  Required.</span>
          <span  style="color: red;" *ngIf="needfollow.errors?.pattern">*Enter Only characters.</span>
        </div>
      </div>


    </div>

    <button mat-raised-button (click)="cancel()" >Cancel</button> &nbsp;
    <button mat-raised-button type="submit" (click)="SubmitData()">Save</button>


  </form>
</div>
