<mat-form-field appearance="outline" class="col-md-12">
  <mat-label>Select </mat-label>
  <mat-select multiple [(ngModel)]="displayedColumns">
    <mat-option *ngFor=" let h of headers" [value]="h">{{h}}</mat-option>
  </mat-select>
</mat-form-field>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
  <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
    <th mat-header-cell *matHeaderCellDef>
      {{column}}
    </th>
    <td mat-cell *matCellDef="let row">
      {{row[column]}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<!--<prettyjson [obj]="data"></prettyjson>-->
<!--  <mat-paginator #paginator-->
<!--      [length]="dataSource?.data?.length"-->
<!--      [pageIndex]="0"-->
<!--      [pageSize]="10"-->
<!--      [pageSizeOptions]="[25,50,100,200,250,500,1000]"-->
<!--      aria-label="Select page">-->
<!--  </mat-paginator>-->

  <mat-paginator #paginator
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[25,50,100,200,250,500,1000]"
      aria-label="Select page">
  </mat-paginator>
