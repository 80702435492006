import {Component, Input, OnInit} from '@angular/core';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexGrid, ApexLegend,
  ApexMarkers, ApexPlotOptions,
  ApexStroke, ApexTitleSubtitle, ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  plotOptions: ApexPlotOptions;
};
@Component({
  selector: 'app-apex-hospital-chart',
  templateUrl: './apex-hospital-chart.component.html',
  styleUrls: ['./apex-hospital-chart.component.scss']
})
export class ApexHospitalChartComponent implements  OnInit {
public areaChartOptions!: Partial<ChartOptions>;
  @Input() mLabels!: string[]
  @Input() mCount!: any[]
  @Input() mFollowUpCount!: any[]

  constructor() {
  }
  ngOnInit(): void {
    this.areachart()
  }
private areachart() {
  this.areaChartOptions = {
    series: [
      {
        name: 'Unique',
        data: this.mCount,
      },
      {
        name: 'Follow Up',
        data: this.mFollowUpCount,
      }
    ],
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
      },
      foreColor: '#9aa0ac',
    },
    colors: ['#3537b0', '#cc3333'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'category',
      categories: this.mLabels,
    },
    legend: {
      show: true,
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 0,
      offsetY: 0,
    },
    grid: {
      show: true,
      borderColor: '#9aa0ac',
      strokeDashArray: 1,
    },
    tooltip: {
      theme: 'dark',
      marker: {
        show: true,
      },
      x: {
        show: true,
      },
    },
  };

}
}
