<mat-form-field appearance="outline" class="col-md-3">
  <mat-label>Driver Name:</mat-label>
  <mat-select  (selectionChange)="onDriverSelect($event)">
    <mat-option *ngFor="let model of drivers" [value]="model.firstName+' '+model.lastName">
      {{ model.firstName }} {{ model.lastName }}
    </mat-option>
  </mat-select>
</mat-form-field>&nbsp;&nbsp;

<mat-form-field appearance="outline" class="col-md-3">
  <mat-label>Vehicle Number</mat-label>
  <mat-select placeholder="Select Number" (selectionChange)="OnVehicleSelect($event)">
    <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.vehicleNumber">{{ vehicle.vehicleNumber }}</mat-option>
  </mat-select>
</mat-form-field>
<div class="card p-2">
<div class="ngxTableHeader">
  <ul class="header-buttons-left ms-0 mb-0">
    <li class="dropdown">
      <h2>Fuels Details</h2>
    </li>
    <li class="dropdown m-l-20">
      <label for="search-input"><i class="material-icons search-icon">search</i></label>
      <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
             (keyup)='applyFilter($event)' aria-label="Search box" #input>
    </li>
  </ul>
  <ul class="header-buttons mr-2">
    <li>
      <button   mat-mini-fab color="primary" (click)="OpenDialog()">
        <mat-icon class="col-white" >add</mat-icon>
      </button>
    </li>
    <!--      <li>-->
    <!--        <div class="export-button m-l-10" matTooltip="XLSX">-->
    <!--          <img src="assets/images/icons/xlsx.png" alt=""/>-->
    <!--        </div>-->
    <!--      </li>-->
  </ul>
</div>

<table mat-table [dataSource]="dataSource">

  <!-- Display Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{(element.date).toDate() | date:'dd/MM/yyyy'}} </td>
  </ng-container>

  <!-- Location Column -->
  <ng-container matColumnDef="vehicleNumber">
    <th mat-header-cell *matHeaderCellDef> Vehicle Number </th>
    <td mat-cell *matCellDef="let element"> {{element.vehicleNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="driverName">
    <th mat-header-cell *matHeaderCellDef> Driver Name </th>
    <td mat-cell *matCellDef="let element"> {{element.driverName}} </td>
  </ng-container>

  <ng-container matColumnDef="fuels">
    <th mat-header-cell *matHeaderCellDef> Fuel(Diesel) </th>
    <td mat-cell *matCellDef="let element"> {{element.fuel}} </td>
  </ng-container>

  <ng-container matColumnDef="kilometers">
    <th mat-header-cell *matHeaderCellDef> Kilometers </th>
    <td mat-cell *matCellDef="let element">{{element.kilometers}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element">
    <button mat-icon-button matTooltip="Edit" color="primary" (click)="editInfo(element)"> <mat-icon>edit</mat-icon></button>
      <!--  <button mat-icon-button matTooltip="delete" color="warn" (click)="deleteInfo(element)"> <mat-icon>delete</mat-icon></button> -->
       </td>
     </ng-container>


     <!-- Add more columns as needed -->

  <!-- Table Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
  <mat-paginator [length]="200" [pageSizeOptions]="[25, 50, 100, 200, 500]" aria-label="Select page">
  </mat-paginator>
</div>
