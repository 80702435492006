import {Injectable, isDevMode} from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {environment} from "../../../environments/environment";
import {collection, Firestore, where, writeBatch} from "@angular/fire/firestore";
import {add} from "lodash";
import {FullChild} from "../../interfaces/FullChild";

@Injectable({
  providedIn: 'root'
})
export class UnitSponsorshipService {
  env = isDevMode() ? environment.testMode : environment.productionMode


  constructor(private mFirestore: AngularFirestore,
              private firestore: Firestore) {


  }

  getSponsor() {
    return this.mFirestore.collection(this.env.sponsoredChildren).valueChanges({idField: 'transactionId'})
  }

  getSponsorByCentre(centreCode: string) {
    return this.mFirestore.collection(this.env.sponsoredChildren, ref => ref.where('centreCode', '==', centreCode)).valueChanges({idField: 'transactionId'})
  }

  addSponsorship(formData: any) {
    return this.mFirestore.collection(this.env.unitsponsorship).add(formData)
  }

  getAllAllootedChildByCentre(centreCode: string) {
    let query = this.mFirestore.firestore.collection(this.env.child).where('isAlloted', '==', true).get()
    if (centreCode != 'All') query = this.mFirestore.firestore.collection(this.env.child).where('isAlloted', '==', true).where('centreCode', '==', centreCode).get()
    return query
  }

  addSponsoredChildren(mergedObject: any) {

    return this.mFirestore.collection(this.env.sponsoredChildren).add(mergedObject)


  }

  getSponsorships() {
    return this.mFirestore.collection(this.env.unitsponsorship).valueChanges({idField: 'transactionId'})
  }

  updateSponsoredChildren(transactionId: string, mergedObject: {
    centreName: any;
    firstName: any;
    lastName: any;
    unit: any;
    childCode: any;
    endDate: any;
    lastEndDate: any;
    centreCode: any;
    oldChild: {
      centreName: any;
      firstName: any;
      lastName: any;
      unit: any;
      childCode: any;
      endDate: any;
      centreCode: any;
      startDate: any
    };
    startDate: any
  }) {
    console.log(`${transactionId} ${mergedObject}`)
    return this.mFirestore.collection(this.env.sponsoredChildren).doc(transactionId).update(mergedObject)
  }

  updateTransactionAmount(transactionId: string, remainingAmount: number) {
    return this.mFirestore.collection(this.env.unitsponsorship).doc(transactionId).update({'remainingAmount': remainingAmount})
  }

  fetchNonSponsoredChildren(centreCode: string) {
    if (centreCode === 'All') {
      return this.mFirestore.collection(this.env.child, ref => ref.where("isAlloted", '==', true)).get();
    } else {
      return this.mFirestore.collection(this.env.child, ref => ref.where('isAlloted', '==', true).where('centreCode', '==', centreCode)).get();
    }
  }

  fetchNonSponsoredChildren2(centreCode: string) {
    if (centreCode === 'All') {
      return this.mFirestore.collection<FullChild>(this.env.child, ref => ref.where("isAlloted", '==', true)
        // .where("sponsored", '!=', true)
      )
        .valueChanges();
    } else {
      return this.mFirestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true)
        // .where("sponsored", '!=', true)
      ).valueChanges();
    }
  }

}
