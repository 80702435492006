import {Component, Inject, OnInit} from '@angular/core';
import {Donor} from "../donor-table/donor-table.component";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DonorService} from "../donor.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-edit-donor',
  templateUrl: './edit-donor.component.html',
  styleUrls: ['./edit-donor.component.scss']
})
export class EditDonorComponent implements OnInit {
  DonorForm: any

  constructor(@Inject(MAT_DIALOG_DATA) public data: Donor,
              public dialogRef: MatDialogRef<EditDonorComponent>,
              private donorservice: DonorService,
              private readonly snackBar: MatSnackBar,
              private readonly mLogService:LogService,) {
    this.DonorForm = new FormGroup({
      donorName: new FormControl(data?.donorName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      address: new FormControl(data?.donoraddress, [Validators.required]),
      dob: new FormControl(data?.dob,),
      donorMobile: new FormControl(data?.donorMobile, [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
      alternateNumber: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
email:new FormControl(data?.email,[Validators.required,Validators.email]),
      pan: new FormControl(data?.pan, [Validators.required, Validators.pattern("([A-Z]){5}([0-9]){4}([A-Z]){1}$"), Validators.minLength(10), Validators.maxLength(10)]),
      donorCity: new FormControl(data?.donorcity, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      country: new FormControl(data?.country, [Validators.pattern('[a-zA-Z ]*')]),
      state: new FormControl(data?.state, [Validators.pattern('[a-zA-Z]*')]),
      pincode: new FormControl(data?.pincode, [ Validators.pattern('[0-9 ]*'), Validators.minLength(6), Validators.maxLength(6)]),
      donorType: new FormControl(data?.donorType ,),
      // "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      // "type": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      // "support": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    })
  }


  ngOnInit(): void {

  }

  get donated_by() {
    return this.DonorForm.get('donorName');
  }

  get address() {
    return this.DonorForm.get('address');
  }


  get pan() {
    return this.DonorForm.get('pan');
  }

  get donorMobile() {
    return this.DonorForm.get('donorMobile');
  }

  get city() {
    return this.DonorForm.get('donorCity');
  }

  get country() {
    return this.DonorForm.get('country');
  }

  get state() {
    return this.DonorForm.get('state');
  }
  get alternateNumber() {
    return this.DonorForm.get('alternateNumber');
  }

  get pincode() {
    return this.DonorForm.get('pincode');
  }
  get mEmail() {
    return this.DonorForm.get('email');
  }

  SubmitData() {
//first sumbit data
//     this.submitted = true;
    //Ideal Code Should be like this
    if(this.DonorForm.valid){
      // then submit data to service
      this.donorservice.editDonor(this.DonorForm.value, this.pan.value).then(()=>{
        let activity={
          date:new Date().getTime(),
          section: 'Donor',
          action: 'Edit',
          description: `Session Edited in Donor by user` ,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)

        this.dialogRef.close()
      })

    }
    else{
      this.snackBar.open('Please fill the form properly','')._dismissAfter(3000)
    }
    console.log(this.DonorForm.value);
//then close dialog

  }

  cancel() {
    this.dialogRef.close()
    // this.submitted = false;
    console.log(this.DonorForm.reset())
  }
}
