import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Units} from "../../../interfaces/Units";
import {CentreService} from "../../../services/centre.service";
import {MatSelectChange} from "@angular/material/select";
import {Observable, Subject, takeUntil} from "rxjs";
import {UnitService} from "../../../services/unit.service";
import {Centre} from "../../../interfaces/Centres";
import {FullChild} from "../../../interfaces/FullChild";
import {ChildService} from "../../child/child.service";
import {AdmissionType} from "../../../interfaces/AdmissionType";

@Component({
    selector: 'app-unit-transfer',
    templateUrl: './unit-transfer.component.html',
    styleUrls: ['./unit-transfer.component.scss']
})
export class UnitTransferComponent implements OnInit {
    centre: Observable<Centre[]>
    fromChild: any;
    toChild: any;
    tempChild: any
    mChildAData!: FullChild
    mChildBData!: FullChild
    shifttoA: any
    shifttoB: any

    selectedCentre: Centre|undefined;
    unitsArray: Units[] = [];
    _destroyed$ = new Subject()
mAdmissionDataA:AdmissionType|undefined
mAdmissionDataB:AdmissionType|undefined
    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private mCentreService: CentreService,
                private readonly unitService:UnitService,
                private readonly mChildService: ChildService,
                private mUnitService: UnitService) {
        this.centre = mCentreService.getAllCentres()
    }

    ngOnInit(): void {
    }

    onCenterSelected(event$: MatSelectChange) {
        this.selectedCentre = event$.value
        this.unitsArray = []
        //   console.log(event$.value)
        let centre = event$.value
        this.mUnitService.getUnit(centre.centreCode).pipe(takeUntil(this._destroyed$)).subscribe((units) => {
            this.unitsArray = []

            units.forEach(unit => {
                this.unitsArray.push(unit as Units)
                //  console.log('unit', unit)
            })
        })

    }


    submit() {
        // this.getChildData(this.fromChild).then(childA => {
        //     this.mChildAData = childA?.data()!!
        //     this.getChildData(this.toChild).then(childB => {
        //         this.mChildBData = childB?.data()!!
        //         console.log(this.mChildAData)
this.swapUnits()
      // this.swapChildrenData()
      // console.log(this.toChild)
      // console.log(this.fromChild)
                // console.log(this.mChildAData.admissionData[this.mChildAData.admissionData.length - 1])
                // console.log('admissionDataA', this.mChildAData.admissionData[this.mChildAData.admissionData.length - 1])
                // console.log('admissionDataB', this.mChildBData.admissionData[this.mChildBData.admissionData.length - 1])

        //     })
        //
        // })

        // console.log('admissionDataB',this.mChildBData.admissionData[this.mChildBData.admissionData.length-1])

        //For swap
        // 1
        // this.tempChild = this.mChildAData
        // this.mChildBData = this.tempChild
        // this.tempChild =this.mChildAData


        // const temp = myObject.childAData;
        //
        // myObject.childAData = myObject.childBData;
        //
        // myObject.childBData = temp;

        // 2
        // Update swapped data into units in ALUNITS


        //3 Update swapped unitcode to swapped childrens
        //
    }

    private getChildData(fromChild: any) {
        console.log('getCHildData A', fromChild)
        return this.mChildService.getChildByCode(fromChild.allotedTo)

    }

    fetchChildDataFromUnitA($event: MatSelectChange) {
     this.mChildService.getChildByCode(this.fromChild.allotedTo).then(res=>{
       this.mChildAData =res?.data()as FullChild
       this. mAdmissionDataA = this.mChildAData?.admissionData?.[this.mChildAData.admissionData.length - 1];
       console.log('A',this.mAdmissionDataA)

     })
    }

    fetchChildDataFromUnitB($event: MatSelectChange) {
       this.mChildService.getChildByCode(this.toChild.allotedTo).then(res=>{
         this.mChildBData=res?.data() as FullChild
         // console.log('getCHildData A', this.toChild)

         this. mAdmissionDataB = this.mChildBData?.admissionData?.[this.mChildBData.admissionData.length - 1];
         console.log('B',this.mAdmissionDataB)

       })
    }

  private swapUnits() {
    console.log(`child A0 ${this.mChildAData.childCode} `,this.mChildAData.admissionData)
    console.log(`child B0 ${this.mChildBData.childCode}`,this.mChildBData.admissionData)
      this.unitService.swapUnits(this.selectedCentre!,this.fromChild,this.toChild,this.mChildAData,this.mChildBData)

    }


}
