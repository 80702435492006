import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FullChild} from "../../../interfaces/FullChild";
import {where} from "@angular/fire/firestore";

@Component({
    selector: 'app-recent-admissions',
    templateUrl: './recent-admissions.component.html',
    styleUrls: ['./recent-admissions.component.scss']
})
export class RecentAdmissionsComponent implements OnInit,AfterViewInit {
    recentAdmissions: FullChild[] = []
    @Input() mChildren?: FullChild[]

    constructor() {


    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        // alert(this.mChildren?.length)

        this.mChildren?.sort((a, b) => {
            const dateA = new Date(a!.dateOfAdmission.toDate());
            const dateB = new Date(b!.dateOfAdmission.toDate());
            // @ts-ignore
            return dateA - dateB;
        });
        if(this.mChildren) this.recentAdmissions =this.mChildren?.slice(-10).reverse()

        // this.recentAdmissions =  this.mChildren!!
    }

}
