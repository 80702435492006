import {Component, Inject, isDevMode, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Centre} from "../../../interfaces/Centres";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {EventLogic, MealMateService} from "../meal-mate.service";
import {States} from "../../../interfaces/States";
import statesdata from '../../../../assets/states.json';
import {MatSelectChange} from "@angular/material/select";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {map, startWith} from "rxjs/operators";
import {Donor} from "../../donoradmin/donor-table/donor-table.component";
import {donor} from "../../donoradmin/donordata";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {MatCheckboxChange} from "@angular/material/checkbox";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {environment} from "../../../../environments/environment";
import {LogService} from "../../../services/log.service";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";

export interface MealEvent {
    mealSlot?: string,
    centreCode?: string,
    mealFor?: string,
    backgroundColor?: string
    receipt_id?:string
    description?:string

    email?: string
    donated_by?: string,
    mobile?: string,
    eventMessage?: string,
    need80g: boolean,
    quantity: number
    id: string,
    start: string,
    end?: string,
    allday?: boolean;
    title: string;
    textColor?: string;
    donation_amount?: number
    paymentMethod?: string
    remark?: string
    eventName?: string
    pan?: string
    address?: string
    state?: string
    city?: string
    zipcode?: string
    createdAt?: number
    order_id?: string
    totalAmountWithoutTax?: number
    followUpOn?: number | null
    isBulkBooking?:boolean|undefined
    bulkBookingTotalAmount?:string
}

@Component({
    selector: 'app-add-meal-event',
    templateUrl: './add-meal-event.component.html',
    styleUrls: ['./add-meal-event.component.scss']
})
export class AddMealEventComponent implements OnInit {
  env = isDevMode() ? environment.testMode : environment.productionMode

  fb: FormBuilder
    // @ts-ignore
  mealForm: FormGroup

    centre!: Centre
    date: string
    mSlots: EventLogic | undefined
    AvailableSlots$: Observable<EventLogic>
    disabled = false
restrictLunch=false
    restrictDinner=false

    event!: MealEvent

    totalAmount = 0
    selectedPaymentMethod = ''
    // filteredOptions?: Observable<string[]>;
    donorList!: Donor[];
    stateslist = statesdata as States[]
    myControl = new FormControl('', Validators.required);
    filteredOptions?: Observable<Donor[]>;
    dropped?: string;
    added?: string;
    options = this.donorList
    todaysDate= new Date()
     TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  mealMatePermissions=this.permissions.filter((x)=>x.functionName=='Meal Mate')

  showpan=true
    constructor(private fbApi: FormBuilder,
                public dialogRef: MatDialogRef<AddMealEventComponent>,
                public _matSnackbar: MatSnackBar,
                private readonly mLogService:LogService,
                private mealService: MealMateService,
                private mDatabase: AngularFireDatabase,
                @Inject(MAT_DIALOG_DATA) data: any) {
        this.fb = fbApi
        this.centre = data.centreData
        this.date = data.datestr
        if(this.TODAY_STR == this.date){
            //* SAME DATE BOOKING
if(this.todaysDate.getHours()>9)this.restrictLunch=true
if(this.todaysDate.getHours()>16)this.restrictDinner=true

        }
        // @ts-ignore
        this.AvailableSlots$ = this.mDatabase.object<EventLogic>(`${this.env.mealmatesCounter}${this.centre.centreCode}/${this.date}`).valueChanges().subscribe(res => {
            if (res != null || res != undefined) {
                console.log('res', res)
                this.mSlots = res as EventLogic
            }

        })
        // console.log('mSlots',this.mSlots)
        if (this.mSlots == undefined) {
            this.mSlots = new EventLogic()

        }

    }

    ngOnInit(): void {

        this.mealForm = this.fb.group({
                //MEAL FORM INSTANCE
                // /** childCode for latest child counter to genereate child code from database. */
                donated_by: this.myControl,
                mobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
                mealSlot: new FormControl('', [Validators.required]),
                // quantity: new FormControl(null, [Validators.required]),
                eventName: new FormControl('', [Validators.required]),
                eventMessage: new FormControl('', [Validators.required]),
                email: new FormControl('', [Validators.required, Validators.email]),
                pan: new FormControl('', Validators.compose([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}'),emailConditionallyRequiredValidator])),
                address: new FormControl('', Validators.required),
                state: new FormControl('', Validators.required),
                donation_amount: new FormControl(this.totalAmount, Validators.required),
                donation_type: new FormControl({value: 'MealMate', disabled: true}),
                idproof: new FormControl({value: 'PAN', disabled: true}),
                country: new FormControl({value: 'India', disabled: true}),
                nationality: new FormControl({value: 'Indian', disabled: true}),
                need80g: new FormControl(false),
                zipcode: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(6), Validators.minLength(6)]),
                city: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
                // details: new FormControl(''),
                paymentMethod: new FormControl('', Validators.required),
                remark: new FormControl('', Validators.required),
                followUpOn: new FormControl(null)
            }
        )
        this.fetchDonorsList()
        this.mealForm.get('need80g')?.valueChanges
            .subscribe(value => {

     if(value) {
         this.showpan= true
         this.mealForm.get('pan')?.updateValueAndValidity();
     }
     else if(!value){
         this.mealForm.get('pan')?.updateValueAndValidity();
         this.showpan =false
     }
            });
    }

    @needConfirmation()
    addMeal() {
        console.log('onsubmit', this.mealForm.get('donated_by')?.value)
        this.disabled = true
        console.log('INTO ADD MEAL')
        // this.mSlots!.totalSlots = this.centre.occupiedUnits!
        if (this.mealForm.valid) {
            this.disabled = true
            console.table(this.mealForm.value)
            // this.calculateAmount()
//      if (this.mealSlot?.value == "Lunch and Dinner") this.totalAmount = this.totalAmount * 2
            this.mealForm.addControl('totalAmount', new FormControl(this.totalAmount))
            const mealEvent = this.event
            mealEvent.remark = this.mealForm.get('remark')?.value
            mealEvent.paymentMethod = this.mealForm.get('paymentMethod')?.value
            mealEvent.donated_by = this.mealForm.get('donated_by')?.value
            mealEvent.city = this.mealForm.get('city')?.value
            mealEvent.address = this.mealForm.get('address')?.value
            mealEvent.email = this.mealForm.get('email')?.value
            mealEvent.eventMessage = this.mealForm.get('eventMessage')?.value
            mealEvent.eventName = this.mealForm.get('eventName')?.value
            mealEvent.mobile = this.mealForm.get('mobile')?.value
            mealEvent.pan = this.mealForm.get('pan')?.value
            mealEvent.title = this.mealForm.get('mealSlot')?.value + ' booked '+ this.centre.centreName
            mealEvent.state = this.mealForm.get('state')?.value
            mealEvent.zipcode = this.mealForm.get('zipcode')?.value
            mealEvent.need80g = this.mealForm.get('need80g')?.value
            mealEvent.createdAt = new Date().getTime()
            mealEvent.order_id = mealEvent.createdAt.toString()
            mealEvent.totalAmountWithoutTax =this.totalAmount
            mealEvent.description ='Mealmate'
            // console.log('DATE TEST',(new Date(this.mealForm.get('followUpOn')?.value)).getTime())
            if (this.mealForm.get('followUpOn')?.value != '') mealEvent.followUpOn = ((new Date(this.mealForm.get('followUpOn')?.value)).getTime())
            console.log(mealEvent)
            this.mealService.AddEvent(this.date, this.centre, mealEvent, this.dialogRef).then(() => {
                let activity={
                    date:new Date().getTime(),
                    section: 'Mealmate',
                    action: 'Submit',

                    description: `Meal added by`,
                    currentIp: localStorage.getItem('currentip')!
                }
                this.mLogService.addLog(activity)
                console.log('Add event succeeded!')
            })

        } else {
            console.log(this.findInvalidControls())
            this._matSnackbar.open('Please fill out the form properly', '', {duration: 3000})
            this.disabled = false
        }

        // console.log("MEAL FORM: ", this.mealForm.value)
    }

    testForm() {
        console.table(this.mealForm.value)
        console.log(this.findInvalidControls())
    }

    get mealSlot() {
        return this.mealForm.get('mealSlot')
    }

    // get mealFor() {
    //   return this.mealForm.get('mealFor')
    // }

    get donorName() {
        return this.mealForm.get('donated_by')
    }

    get eventName() {
        return this.mealForm.get('eventName')
    }

    get eventMessage() {
        return this.mealForm.get('eventMessage')
    }

    //
    // get quantity() {
    //   return this.mealForm.get('quantity')
    // }

    //
    // get onlyChildInDinner() {
    //   return this.mealForm.get('onlyChildInDinner')
    // }
    //
    get donorNumber() {
        return this.mealForm.get('mobile')
    }


    selectionChangeOld() {
        this.disabled = false
        const mealEvent = this.mealForm.value as MealEvent
        // console.log(mealEvent)
        // console.log('', ` ${this.mealSlot!.value} ${this.mealFor?.value}`)
        // @ts-ignore
        switch (mealEvent.mealSlot) {
            case "Lunch for child": {
                this.totalAmount = 1000

                console.log('INTO LUNCH FOR CHILD')
                mealEvent.id = this.date + 'L-C'
                mealEvent.title = ` ${this.eventName?.value}`
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = 'black'
                mealEvent.backgroundColor = '#fbc02d'
                mealEvent.allday = true
                mealEvent.email = '' + this.mealForm.get('email')?.value
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                break;
            }
            case "Lunch for family": {
                this.totalAmount = 4000

                console.log('INTO LUNCH')
                mealEvent.id = this.date + 'L-F'
                mealEvent.title = `${this.eventName?.value}`
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = 'black'
                mealEvent.backgroundColor = '#fbc02d'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                break;
            }

            case "Dinner for child": {
                console.log('INTO DINNER FOR CHILD')
                this.totalAmount = 1000;
                // mealEvent.id= this.date + 'L',
                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#fff'
                mealEvent.backgroundColor = '#00796b'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)

                // allDay: selectInfo.allDay

                break;
            }
            case "Dinner for family": {
                console.log('INTO DINNER')
                this.totalAmount = 4000
                // mealEvent.id= this.date + 'L',
                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#fff'
                mealEvent.backgroundColor = '#00796b'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)
                // allDay: selectInfo.allDay
                break;
            }
            case "Lunch and dinner for child": {
                console.log('INTO LUNCH AND DINNER')
                this.totalAmount = 2000

                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#ddd'
                mealEvent.backgroundColor = '#1976d2'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                // allDay: selectInfo.allDay
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)
                break;
            }
            case "Lunch and dinner for family": {
                console.log('INTO LUNCH AND DINNER')
                this.totalAmount = 8000

                mealEvent.title = `${this.eventName?.value} `
                mealEvent.start = this.date
                // mealEvent.end= this.date + 'T15:00:00',
                mealEvent.textColor = '#ddd'
                mealEvent.backgroundColor = '#1976d2'
                mealEvent.allday = true
                mealEvent.centreCode = this.centre.centreCode
                // allDay: selectInfo.allDay
                mealEvent.donation_amount = this.totalAmount
                // this.mealService.checkDateNode(this.date, this.centre, mealEvent, this.dialogRef)
                break;
            }
        }
        this.mealForm.addControl('totalAmount', new FormControl(this.totalAmount))
        this.event = mealEvent
    }
    selectionChange() {
        this.disabled = false;
        const mealEvent = this.mealForm.value as MealEvent;

        switch (mealEvent.mealSlot) {
            case "Lunch for child": {
                this.totalAmount = 1000;
                mealEvent.id = this.date + 'L-C';
                break;
            }
            case "Lunch for family": {
                this.totalAmount = 4000;
                mealEvent.id = this.date + 'L-F';
                break;
            }
            case "Dinner for child": {
                this.totalAmount = 1000;
                break;
            }
            case "Dinner for family": {
                this.totalAmount = 4000;
                break;
            }
            case "Lunch and dinner for child": {
                this.totalAmount = 2000;
                break;
            }
            case "Lunch and dinner for family": {
                this.totalAmount = 8000;
                break;
            }
        }

        mealEvent.title = `${this.eventName?.value}`;
        mealEvent.start = this.date;
        // @ts-ignore
        mealEvent.textColor = (mealEvent.mealSlot.includes('Dinner')) ? '#fff' : 'black';
        // @ts-ignore
        mealEvent.backgroundColor = (mealEvent.mealSlot.includes('Dinner')) ? '#00796b' : '#fbc02d';
        mealEvent.allday = true;
        mealEvent.email = '' + this.mealForm.get('email')?.value;
        mealEvent.centreCode = this.centre.centreCode;
        mealEvent.donation_amount = this.totalAmount;

        this.mealForm.addControl('totalAmount', new FormControl(this.totalAmount));
        this.event = mealEvent;
    }

    selectedPayment($event: MatSelectChange) {
        this.selectedPaymentMethod = $event.value
    }

    private fetchDonorsList() {
        this.mealService.fetchDonors().subscribe(
            res => {
                this.donorList = res as Donor[]
                console.log(this.donorList)
                this.options = res as Donor[]
                this.filteredOptions = this.myControl.valueChanges
                    .pipe(
                        startWith(''),
                        map(value => this._filter(value!))
                    );
                this.filteredOptions = this.myControl.valueChanges.pipe(
                    startWith(''),
                    map(donor => (donor ? this._filter(donor) : this.donorList.slice())),
                );
            }
        )
    }

    // private _filter(value: string): Donor[] {
    //   const filterValue = value.toLowerCase();
    //   // this.options  = this.donorList
    //   console.log('OPTIONS',this.donorList)
    //   return (this.options.filter(option => option.donorName.toLowerCase().includes(filterValue)));
    // }
    private _filter(value: string): Donor[] {
        const filterValue = value.toLowerCase();
        return this.donorList.filter(state => state.donorName.toLowerCase().includes(filterValue));
    }

    // add(el:string){
    //   this.added = el;
    //   this.myControl.setValue(null);
    // }
    //
    // drop(el:string){
    //   this.dropped = el;
    //   this.myControl.setValue(null);
    // }
    displayFn(country: Donor[]): string {
        return 'hehe';
    }

    optionSelect($event: MatAutocompleteSelectedEvent) {
        console.log('SELECTED', $event)
        const donorSelected = $event.option.value as Donor
        this.myControl.setValue(donorSelected.donorName)
        this.mealForm.get('address')?.setValue(donorSelected.donoraddress)
        this.mealForm.get('zipcode')?.setValue(donorSelected.zipcode)
        this.mealForm.get('mobile')?.setValue(donorSelected.donorMobile)
        this.mealForm.get('pan')?.setValue(donorSelected.pan)
        this.mealForm.get('email')?.setValue(donorSelected.email)
        this.mealForm.get('city')?.setValue(donorSelected.donorcity)
        this.mealForm.get('state')?.setValue(donorSelected.state)
// this.mealForm.get('zipcode')?.setValue($event.zipcode)


    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.mealForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    checkNeed80G($event: MatCheckboxChange) {
        console.log('need80G', $event.checked)
        console.log(this.mealForm.value)
    }


}
//* Conditional validator on is 80G Check Box for pan form field

function emailConditionallyRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
        return null;
    }

    if (formControl.parent.get('need80g')?.value) {
        return Validators.required(formControl);
    }
    return null;
}
/*
*  lunch dinner both
*1 lunch for child
*2 lunch for family
*3 dinner for child
*4 dinner for family
*5 both for child
*6 both for family
*
* */
