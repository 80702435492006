import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatTableDataSource} from "@angular/material/table";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatSelectChange} from "@angular/material/select";
import {GlossaryService} from "./glossary.service";
import {Subject} from "rxjs/internal/Subject";
import {takeUntil} from "rxjs/internal/operators/takeUntil";
import {EditEmployeeInfoComponent} from "../employee/edit-employee-info/edit-employee-info.component";
import {MatDialog} from "@angular/material/dialog";
import {AddGlossaryComponent} from "./add-glossary/add-glossary.component";
import {EditGlossaryComponent} from "./edit-glossary/edit-glossary.component";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

interface GlossaryModel {
    menu: string
    name: string;
    description: string
}

@Component({
    selector: 'app-glossary',
    templateUrl: './glossary.component.html',
    styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent implements OnInit, OnDestroy {
    destroyed$ = new Subject()
    menuForm!: FormGroup;
    dataSource: MatTableDataSource<GlossaryModel>;
    selectMenu = new FormControl('dashboard')
    displayedColumns: string[] = ['menu', 'name', 'description', 'action'];
    data: GlossaryModel[] = []
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) matSort!: MatSort;

    constructor(private formBuilder: FormBuilder,
                private readonly mService: GlossaryService,
                private mDatabase: AngularFirestore,
                private  readonly matDialog:MatDialog,) {
        this.dataSource = new MatTableDataSource(this.data);
    }

    ngOnInit(): void {
        this.menuForm = this.formBuilder.group({
            menu: ['', Validators.required],
            name: ['', Validators.required],
            description: ['']
        });


    }

    onMenuSelect($event: MatSelectChange) {
        let option = $event.value
        this.mService.fetchData($event.value).pipe(takeUntil(this.destroyed$)).subscribe(res => {
            this.data = []

            // alert(JSON.stringify(res))
            // @ts-ignore
            // let response = JSON.stringify(res)

            // const d = JSON.parse(response)

            // alert(d.test2)

            let hashMap: { [key: string]: string } = {};
let response =res
            if (response) {
                // @ts-ignore
                Object.keys(response).forEach((key) => {
                    // @ts-ignore
                    hashMap[key] = response[key];
                });

                // alert(JSON.stringify(res.data()))
                for (let hashMapKey in hashMap) {
                    const item = {
                        name: hashMapKey,
                        description: hashMap[hashMapKey],
                        menu: this.selectMenu.value
                    } as GlossaryModel
                    this.data.push(item)

                }

                this.dataSource.data = this.data; // Update the dataSource with new data
              this.dataSource.paginator = this.paginator

            }


            // @ts-ignore
            // hashMap.forEach((it: { key: any; value: any; })=>{
            //     const item  ={ name: it.key,
            //         description:it.value,
            //         menu:this.selectMenu.value
            // } as GlossaryModel
            // this.data.push(item)
            //
            // })

        })
    }


    submitForm() {
        if (this.menuForm.valid) {
            const formData = this.menuForm.value;
            const name = this.menuForm.get('name')?.value
            const menu = this.menuForm.get('menu')?.value
            const desc = this.menuForm.get('description')?.value


            const map = {menu: this.menuForm.value}
            // Add the form data to Firestore collection
            this.mDatabase.collection('glossary').doc(menu).set({[name]: desc}, {merge: true}).then(() => {
                console.log('Form data stored in Firestore');
            }).catch(error => {
                console.error('Error storing form data:', error);
            });

            // this.dataSource.data.push(formData);
            // this.dataSource._updateChangeSubscription();
            // this.data.push(formData);
            console.log(this.data)
            this.menuForm.reset();
        }


    }

    ngOnDestroy(): void {
        this.destroyed$.next('')
        this.destroyed$.complete()
    }

  openDialog() {
    this.matDialog.open(AddGlossaryComponent,{
      width:'40%',
      height:'50%'})

  }

  editDialog(row: any) {
    this.matDialog.open(EditGlossaryComponent,{data:row,
      width:'40%',
      height:'50%'})


  }
}
