
<div class="container">

  <div class=" pt-5 ">
    <mat-card>
      <mat-card-header>
        <!--            <mat-card-title-group>-->
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>Child Details</mat-card-title>
        <mat-card-subtitle> {{child.childCode}} </mat-card-subtitle>
        <!--              <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg" >-->
        <!--            </mat-card-title-group>-->
      </mat-card-header>
      <hr>
      <mat-card-content>
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
<!--          <tr><th scope="row"> Selected Centre</th><td>{{(centreName$ |async)?.centreName}}</td></tr>-->
<!--          <tr><th scope="row">Child Code</th><td>{{child.childCode}}</td></tr>-->
<!--          <tr><th >Unit Alloted</th><td>{{child.selectUnit}}</td></tr>-->
          <tr><th scope="row">First Name</th>
            <td>{{child.firstName}}</td>
          </tr>
          <tr><th scope="row">Middle Name</th>
            <td>{{child.middleName}}</td>
          </tr>

          <tr><th >Last Name</th><td>{{child.lastName}}</td></tr>
          <tr><th >Age</th><td>{{child.age}}</td></tr>

          <tr><th >Hospital Name</th><td>{{data.hospitalname}}</td></tr>
          <tr><th >Mobile</th><td>{{child.mobile}}</td></tr>
<!--          <tr><th >Blood Group Type</th><td>{{child.bloodGroup}}</td></tr>-->

<!--          <tr><th >Child Aadhar Code</th><td>{{child.childAadhaar}}</td></tr>-->
          <tr><th >City</th><td>{{child.city}}</td></tr>
<!--<tr><th >Pincode</th><td>{{child.pincode}}</td></tr>-->
          <tr><th >State</th><td>{{child.state}}</td></tr>
          <tr><th >Gender</th><td>{{child.gender}}</td></tr>
<!--          <tr><th >Pincode</th><td>{{child.pincode}}</td></tr>-->
          <tr><th >Diagnosis</th><td>{{child.diagnosis}}</td></tr>
<!--          <tr><th >Allergies</th><td>{{child.allergies}}</td></tr>-->
<!--          <tr><th >Hobbies</th><td>{{child.hobbies}}</td></tr>-->
<!--          <tr><th >Apspirations</th><td>{{child.aspirations}}</td></tr>-->
          <!--              <tr><th >Method</th><td>{{child.method}}</td></tr>-->
          <!--Basic Info-->
<!--          <tr><th >Stayed At AL Centre Before</th><td>{{child.stayedBefore}}</td></tr>-->
<!--          <tr><th >Accompanied By</th><td>{{child.accompaniedBy}}</td></tr>-->
<!--          <tr><th >Relation With Child</th><td>{{child.accompaniesRelation}}</td></tr>-->
<!--          <tr><th >Relation With Child</th><td>{{child.accompaniesRelation}}</td></tr>-->
          <ng-container *ngFor="let g of child.guardians;let  i =index">
            <tr><th >Guardian </th><td>{{i+1}}</td></tr>
            <tr><th >Guardian First Name</th><td>{{g.firstName}}</td></tr>
            <tr><th >Guardian Middle Name</th><td>{{g.middleName}}</td></tr>
            <tr><th >guardian Last Name</th><td>{{g.lastName}}</td></tr>
            <tr><th >guardian Last Name</th><td>{{g.lastName}}</td></tr>
            <tr><th >Guardian Adhaar Number</th><td>{{g.aadharNumber}}</td></tr>
            <tr><th >Guardian Contact Number</th><td>{{g.contactNo}}</td></tr>
            <tr><th >Guardian Alternate Contact Number</th><td>{{g.alternateContactNo}}</td></tr>
            <tr><th >Guardian Occupation</th><td>{{g.occupation}}</td></tr>

            <tr><th >Guardian Relation With Child</th><td>{{g.relation}}</td></tr>
<!--            <tr><th >Guardian Driving License</th><td>{{g.drivingLicense}}</td></tr>-->
<!--            <tr><th >Guardian Election Id</th><td>{{g.electionId}}</td></tr>-->
<!--            <tr><th >Guardian Ration Card</th><td>{{g.rationCard}}</td></tr>-->
<!--            <tr><th >Guardian Other Documents</th><td>{{g.otherDocuments}}</td></tr>-->
            <tr><th >Guardian Other Skills</th><td>{{g.skills}}</td></tr>
          </ng-container>
<tr><th >Remark</th><td>{{child.remark}}</td></tr>


          </tbody>
        </table>
       <h2 class="text-center ">FAQ</h2>
        <p><strong>Q1.</strong> Confirm the name of the child along with age. (Age limit 0 To under 14)<br>
         {{child.q1}}
       </p>

          <p><strong>Q2.</strong> When did the family reach / come to the hospital? And where are they staying currently?
            <br>{{child.q2}}</p>

          <p><strong>Q3.</strong>Where does the family hail from (village/state/city)? <br>{{child.q3}}</p>

          <p><strong>Q4.</strong>What is the father's profession (work)? <br>{{child.q4}}</p>

          <p><strong>Q5.</strong> Is the child able to walk by himself/herself? <br>{{child.q5}}  </p>

          <p><strong>Q6.</strong>Do they have a patient mother father Aadhar card & Ration card? If the child does not have an Aadhaar card, then share the birth certificate.
            <br>{{child.q6}}</p>


          <p><strong>Q7.</strong>How many children do they have? If more than one then check whom have they left them with?
            <br>{{child.q7}}</p>


          <p><strong>Q8.</strong>If the patient's mother is pregnant then she is not allowed to stay in the center / in her place only one other person can stay with the patient like grandfather, grandmother, maternal uncle of the patient.
            <br>{{child.q8}}</p>


          <p><strong>Q9.</strong>Do they have any relatives/ friends staying in the same city (In this case closer to center)?
            <br>{{child.q9}}</p>

      </mat-card-content>
    </mat-card>



  </div>

  <!--      RESPONSE-->

</div>

