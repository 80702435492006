import {Component, Input, OnInit} from '@angular/core';
import {ChartConfiguration, ChartOptions, ChartType} from "chart.js";
// import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
// import { Label } from "ng2-charts";

@Component({
  selector: 'app-age-bar',
  templateUrl: './age-bar.component.html',
  styleUrls: ['./age-bar.component.css']
})
export class AgeBarComponent implements OnInit{
  public barChartColors = [
    { backgroundColor: '#fbc02d' }, // Color for 'FollowUP'
    { backgroundColor: '#ff5722' }, // Color for 'Unique'
    // Add more colors as needed
  ];
  public barChartOptions: ChartOptions = {
    responsive: true,
    backgroundColor: '#00695c',
    // scales: { x: [{ barThickness: 20 }],
    //   y: [{ beginAtZero: true }], },
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };
  public barChartOptions2: ChartOptions = {
    responsive: true,
    backgroundColor:'#fbc02d'
  };
  public barChartLabels = ['0-3', '4-7','8-10' ,'11-14','Others'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  @Input() ageArrayInput?:number[];
  @Input() followUpArray?:number[];
  @Input() a!:number;
  @Input() af!:number;
  @Input() b!:number;
  @Input() bf!:number;
  @Input() c1!:number;
  @Input() cf!:number;
  @Input() d!:number;
  @Input() df!:number;
  @Input() e!:number;
  @Input() ef!:number;


  public barChartData = [
    { data: this.ageArrayInput!, label: 'By Age Group', backgroundColor: '#fbc02d' },
    { data: [28, 48, 40, 19, 86], label: 'Series B', backgroundColor: '#ff5722' },
    { data: [this.a, this.b, this.c1, this.d, this.e], label: 'Series C', backgroundColor: '#yourColor' },
  ];


  constructor() {
    // console.log('IN AGE_BAR',this.ageArrayInput)
  }

  ngOnInit(): void {
    this.barChartData= [ { data: this.ageArrayInput!, label: 'Unique',backgroundColor: '#0b5ba8' },
      // { data: [28, 48, 40, 19, 86, 27, 90], label: 'Follow Up',backgroundColor: '#f8d240' },
      // { data: [this.a, this.b, this.c1, this.d, this.e,], label: 'Series B' }
    ]
  }
}
