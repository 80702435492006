

<!--<mat-card class="example-card">-->
<!--    <mat-card-title-group>-->
<!--        <mat-card-title>Booked Trips</mat-card-title>-->
<!--        -->
<!--        &lt;!&ndash; <img mat-card-sm-image src="https://material.angular.io/assets/img/examples/shiba2.jpg"> &ndash;&gt;-->
<!--    </mat-card-title-group>-->
<!--    <button mat-raised-button color="yellow">Details</button>-->
<!--    <mat-card-content>-->
<!--       -->
<!--    </mat-card-content>-->
<!--</mat-card>-->


<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Driver">
    <app-add-info></app-add-info>
  </mat-tab>

  <mat-tab label="Vehicle">
    <app-add-vehicles></app-add-vehicles>
  </mat-tab>
</mat-tab-group>



