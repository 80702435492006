import {enableProdMode, ENVIRONMENT_INITIALIZER, importProvidersFrom, inject} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {DialogService} from "./app/services/dialog.service";
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";

if (environment.production) {
  enableProdMode();
}
export function initializeDialogService() {
  return () => {
    inject(DialogService)
  };
}
//
// bootstrapApplication(AppComponent, { providers : [
//     importProvidersFrom(MatDialogModule),
//     {
//       provide: ENVIRONMENT_INITIALIZER,
//       useFactory: initializeDialogService,
//       deps: [MatDialog],
//       multi: true
//     }
//   ]
// })
//   .catch(err => console.error(err));

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
