import {AfterViewInit, Component,  ViewChild} from '@angular/core';
import {VolunteerregisComponent} from "../volunteerregis/volunteerregis.component";
import {MatDialog} from "@angular/material/dialog";
import {volunteer} from "../volunteerdata";
import {VolunteerService} from "../volunteer.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";




@Component({
  selector: 'app-volunteerlist',
  templateUrl: './volunteerlist.component.html',
  styleUrls: ['./volunteerlist.component.scss']
})
export class VolunteerlistComponent implements AfterViewInit {
  displayedColumns: String[] = ['position','firstname','lastname','centercode', 'volunteercode', 'city','Action'];
  volunteerData!:volunteer[]



  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<volunteer>;

  constructor(private matdialog: MatDialog,
              private volunteerService:VolunteerService)
  {  // Create 100 users
    const users = Array.from({length: 100} )
    this.dataSource = new MatTableDataSource(this.volunteerData);}

  ngAfterViewInit(): void {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    //Read
    this.volunteerService.getVolunteer().subscribe(nitu=>{
      this.volunteerData=[]
      nitu.forEach(b=>{
        // console.assessment-table(b.payload.toJSON())
        let volunteer = b.payload.toJSON() as volunteer
        if (b.key != null) {
          volunteer ['$key'] = b.key;
        }
        this.volunteerData.push(volunteer);
         this.dataSource =new MatTableDataSource(this.volunteerData)
        this.dataSource.paginator = this.paginator;
      })

    })

  }

  openpopup() {
    this.matdialog.open(VolunteerregisComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


}
