<div class="container p-2 ">
  <h1 class=" text-shadow" >View Mealmate Booking</h1>
  <hr>
  <div class="d-flex justify-content-end">
    <button mat-stroked-button color="primary" (click)="openEditdialog()" >Change Event Date</button>
  </div>


  <!--------------------------------------------- TABLE START --------------------------------------------->
  <table class="table table-striped">
  <thead>
  <tr>
    <th scope="col">Event Date </th>
    <th scope="col">{{event?.start|date}}</th>
<!--    <th scope="col">Last</th>-->
<!--    <th scope="col">Handle</th>-->
  </tr>
  </thead>
  <tbody>

  <tr>
    <th scope="row">Donor Name</th>
    <td>{{event?.donated_by}}</td>
  </tr>
  <tr>
    <th scope="row">Event</th>
    <td>{{event?.eventName}}</td>
  </tr>

  <tr>
    <th scope="row">Message</th>
    <td>{{event?.eventMessage}}</td>
  </tr>
  <tr>
    <th scope="row">Address</th>
    <td>{{event?.address}}</td>
  </tr>
  <tr>
    <th scope="row">City</th>
    <td>{{event?.city}}</td>
  </tr>
  <tr>
    <th scope="row">State</th>
    <td>{{event?.state}}</td>
  </tr>
  <tr>
    <th scope="row">Pincode</th>
    <td>{{event?.zipcode}}</td>
  </tr>
  <tr>
    <th scope="row">Donor Number</th>
    <td>{{event?.mobile}}</td>
  </tr>
<!--  <tr>-->
<!--    <th scope="row">{{event?.mealSlot}}</th>-->
<!--    <td>{{event?.mealFor}}</td>-->
<!--  </tr>-->
  <tr>
    <th scope="row">Description</th>
    <td>{{event?.mealSlot}}</td>
  </tr>
<!--  <tr>-->
<!--    <th scope="row">Quantity</th>-->
<!--    <td>{{event?.quantity}}</td>-->
<!--  </tr>-->
  <tr>
    <th scope="row">Total Amount Donated:</th>
    <td>{{event?.donation_amount}}/-INR</td>
  </tr>
  <tr>
    <th scope="row">Payment Method</th>
    <td>{{event?.paymentMethod}}</td>
  </tr>
  <tr>
    <th scope="row">Transaction / Reference No/ Remark</th>
    <td>{{event?.remark}}</td>
  </tr>
  <tr *ngIf="event?.followUpOn">
    <th scope="row" >Follow Up Date</th>
    <td>{{event?.followUpOn|date }}</td>
  </tr>
  </tbody>
</table>
  <!--------------------------------------------- TABLE END --------------------------------------------->

</div>
