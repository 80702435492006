<div class="container">
<div class="d-flex gap-2" >
  <mat-form-field appearance="outline" class="col-md-4">
    <mat-label>Select Type</mat-label>
    <mat-select #typeSelect [value]="selectedType" (selectionChange)="applyFilter($event)" [(ngModel)]="selectedType"  #input>
      <mat-option *ngFor="let o of (typeList$|async)" [value]="o.name">{{o.name}}</mat-option>
    </mat-select>
  </mat-form-field>
<div class="col-md-4">
  &nbsp;  <button  *ngIf="type[0].all || type[0].add"  mat-raised-button color="accent" (click)="AddType('300ms','300ms')">Add Type</button>
</div>
</div>
  <hr>
  <ng-container *ngIf="this.selectedType!=''" class="">
    <div class="row"  [formGroup]="fieldForm">

      <span >
        <mat-form-field appearance="outline" #fieldInput required>
          <mat-label>Enter Field</mat-label>
          <input matInput formControlName="field" >
          <mat-error>
             <mat-error *ngIf="  fieldForm.get('field')?.invalid ">
        <span style="color: red;" *ngIf="fieldForm.get('field')?.errors?.required">*It is Required.</span>
      </mat-error>
          </mat-error>
        </mat-form-field>

&nbsp;      <button *ngIf="type[0].add" mat-stroked-button  color="primary" type="submit" (click)="AddTypData()">Add {{selectedType}}</button>
    </span>
    </div>
    <hr>
  </ng-container>



  <p></p>
  <div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
      <li class="dropdown">
        <h2>Types</h2>
      </li>
<!--      <li class="dropdown m-l-20">-->
<!--        <label for="search-input"><i class="material-icons search-icon">search</i></label>-->
<!--        <input placeholder="Search" type="text" class="browser-default search-field"-->
<!--               (keyup)='applyFilter($event)' aria-label="Search box" >-->
<!--      </li>-->
    </ul>
    <ul class="header-buttons mr-2">
<!--            <li>-->
<!--              <button   mat-mini-fab color="primary">-->
<!--                <mat-icon class="col-white" >add</mat-icon>-->
<!--              </button>-->
<!--            </li>-->
<!--      <li>-->
<!--        <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
<!--          <img src="assets/images/icons/xlsx.png" alt="" />-->
<!--        </div>-->
<!--      </li>-->
    </ul>
  </div>


  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let row"> {{row.key}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <!-- Fruit Column -->
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
        <td mat-cell *matCellDef="let row">
<!--        <button mat-raised-button color="primary">Add  </button>&nbsp;-->
        <button *ngIf="type[0].archive" mat-raised-button color="primary" (click)="Delete(row.key)">Delete </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

<!--      &lt;!&ndash; Row shown when there is no matching data. &ndash;&gt;-->
<!--      <tr class="mat-row" *matNoDataRow>-->
<!--        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>-->
<!--      </tr>-->
    </table>

    <mat-paginator [pageSizeOptions]="[25,50,100,200,250,500,1000]" aria-label="Select page of users"></mat-paginator>
  </div>

</div>
