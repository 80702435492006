import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexTooltip,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  series2: ApexNonAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  tooltip: ApexTooltip;
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
  labels: string[];
  responsive: ApexResponsive[];
};
@Component({
  selector: 'app-total-units-chart',
  templateUrl: './total-units-chart.component.html',
  styleUrls: ['./total-units-chart.component.scss']
})
export class TotalUnitsChartComponent implements  OnInit,AfterViewInit {
  @Input() totalUnits: number=0
  @Input() occupied: number=0
  @Input() available: number=0
@ViewChild('chart') chart!: ChartComponent;
public pieChartOptions!: Partial<ChartOptions>;
  constructor() {

  }

private smallChart2() {

    // this.pieChartOptions = {
    //
    //   series2: [this.totalUnits, this.occupied,this.available],
    //   chart: {
    //     type: 'donut',
    //     width: 200,
    //   },
    //   legend: {
    //     show: false,
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   labels: ['Total Units', 'Occupied', 'Available'],
    //   responsive: [
    //     {
    //       breakpoint: 480,
    //       options: {},
    //     },
    //   ],
    // };
    setTimeout(() => {
      this.pieChartOptions = {

        series2: [this.totalUnits, this.occupied,this.available],
        chart: {
          type: 'donut',
          width: 200,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        labels: ['Total Units', 'Occupied', 'Available'],
        responsive: [
          {
            breakpoint: 480,
            options: {},
          },
        ],
      };
  }, 100);
  }

  ngOnInit() {
    this.smallChart2()

  }

  ngAfterViewInit(): void {
  }

}
