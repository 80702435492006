import {Injectable, isDevMode} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {donor} from "./donordata";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Donor} from "./donor-table/donor-table.component";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DonorService {
  env = isDevMode() ? environment.testMode : environment.productionMode

  constructor(private mDatabase: AngularFireDatabase,
              private mFirestore: AngularFirestore) {
  }

  addDonor(Donordata: donor, pancard: string) {
    // this.mDatabase.list('dummy/donorlist').push(Donordata)
    return this.mFirestore.collection(this.env.donors).doc(pancard).set(Donordata)
  }


  getDonorFromfirestore() {
    return this.mFirestore.collection<Donor>(this.env.donors).valueChanges({idField: 'id'})
  }
  fetchDonors() {
    return this.mFirestore.collection<Donor>(this.env.donors).get()
  }


  editDonor(Donordata: donor, pan: string) {
    return this.mFirestore.collection(this.env.donors).doc(pan).update(Donordata)

  }
  deleteDonor( id: string) {
    return this.mFirestore.collection(this.env.donors).doc(id).delete()

  }
}
