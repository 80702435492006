import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {CentreService} from "../../../../services/centre.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {start} from "@popperjs/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {OnlineBooking} from "../../../../interfaces/OnlineBooking";

@Component({
    selector: 'app-allot-online-slot',
    templateUrl: './allot-online-slot.component.html',
    styleUrls: ['./allot-online-slot.component.scss']
})
export class AllotOnlineSlotComponent implements OnInit {
    form: FormGroup
    mData: OnlineBooking
    slotData:any

    constructor(private readonly centreService: CentreService,
                @Inject(MAT_DIALOG_DATA) public data: OnlineBooking,
                private readonly mDatabase: AngularFireDatabase) {
        this.mData = data

        this.form = new FormGroup<any>({
            centreCode: new FormControl(this.mData?.centreCode, Validators.required),
            start: new FormControl('', Validators.required),
        })
        console.log(this.mData)
    }

    ngOnInit(): void {

    }

    Search() {
        // const TODAY_STR = this.form.get('start')?.value.toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
        console.error('Error occured in cloud functions')
        alert(' Error occured 504')

        this.mDatabase.object(`ALMEAL-MATE/meal-mate-counter/${this.mData.centreCode}/${this.mData.start}`).valueChanges().subscribe(res => {
            this.slotData = res

        })
    }
}
