<app-header (toggledEvent)="drawer.toggle()">
    All Centres
</app-header>
<mat-drawer-container class="example-container">
    <mat-drawer #drawer class="example-sidenav" mode="side" (openedStart)="true">
        <mat-list role="list">
            <mat-list-item  *ngIf="  dashboardPermission[0].showMenu || dashboardPermission[0].all" class="matlink" role="listitem" routerLinkActive="active" routerLink="dashboard">
                <mat-icon>dashboard</mat-icon>&nbsp;Dashboard
            </mat-list-item>

            <mat-list-item class="matlink" *ngIf="    childAdmissionPermission[0].showMenu || childAdmissionPermission[0].all" role="listitem" routerLinkActive="active" routerLink="childdetails">
                <mat-icon>child_care</mat-icon>&nbsp;Child-Admission
            </mat-list-item>
            <mat-list-item  *ngIf=" counsellingPermission[0].all || counsellingPermission[0].showMenu " class="matlink" role="listitem" routerLinkActive="active" routerLink="counseling">
                <mat-icon>tag_faces</mat-icon>&nbsp;Counselling
            </mat-list-item>
            <mat-list-item class="matlink" *ngIf=" educationPermission[0].all || counsellingPermission[0].showMenu" role="listitem" routerLinkActive="active" routerLink="education">
                <mat-icon>book</mat-icon>&nbsp;Education
            </mat-list-item>
            <mat-list-item *ngIf="Unit[0].showMenu" class="matlink" role="listitem" routerLinkActive="active" routerLink="Units">
                <mat-icon>group_work</mat-icon>&nbsp;Units
            </mat-list-item>
            <mat-list-item *ngIf="Types[0].showMenu" class="matlink" role="listitem" routerLinkActive="active" routerLink="types">
                <mat-icon>arrow_drop_down_circle</mat-icon>&nbsp;Types
            </mat-list-item>
            <mat-list-item *ngIf="Types[0].showMenu" class="matlink" role="listitem" routerLinkActive="active" routerLink="glossary">
                <mat-icon>info</mat-icon>&nbsp;Glossary
            </mat-list-item>

            <hr>
<!--            <mat-accordion class="mat-body" *ngIf="  roleAs=='SuperAdmin'">-->

<!--                <mat-expansion-panel class=" mat-body " (opened)="panelOpenState = true"-->
<!--                                     (closed)="panelOpenState = false"  >-->
<!--                    <mat-expansion-panel-header>-->
<!--                        <mat-panel-title class="mat-body">-->
<!--                            Transport-->
<!--                        </mat-panel-title>-->
<!--                    </mat-expansion-panel-header>-->
<!--                    <mat-list-item class="matlink" role="listitem" routerLinkActive="active" routerLink="Vehicles">-->
<!--                        <mat-icon>directions_car</mat-icon>&nbsp;Vehicles-->
<!--                    </mat-list-item>-->
<!--                </mat-expansion-panel>-->
<!--            </mat-accordion>-->

                <mat-accordion class="mat-body"  *ngIf="env.showInventory"  >
                    <mat-expansion-panel class=" mat-body " (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title class="mat-body">
                                Inventory
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <mat-list-item  class="matlink" role="listitem" routerLinkActive="active" routerLink="inventory/contact_tbl">
                            <mat-icon>contact</mat-icon>Contact
                        </mat-list-item>
                      <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/products">
                        <mat-icon></mat-icon>Add Products
                      </mat-list-item>
                      <mat-list-item  class="matlink" role="listitem" routerLinkActive="active" routerLink="inventory/purchase_product_tbl">
                        <mat-icon>product</mat-icon>Purchase
                      </mat-list-item>
                      <mat-list-item  class="matlink" role="listitem" routerLinkActive="active" routerLink="inventory/centreInventory">
                        <mat-icon>product</mat-icon>Centre Inventory
                      </mat-list-item>
                      <mat-list-item  class="matlink" role="listitem" routerLinkActive="active" routerLink="inventory/stock_adjustment">
                        <mat-icon></mat-icon>&nbsp;Add Stock Adjustment
                      </mat-list-item>
                      <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/IncomingOutgoingTransfer">
                        <mat-icon></mat-icon>&nbsp;Add Stock Transfer
                      </mat-list-item>


<!--                        <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/inventory_report">-->
<!--                            <mat-icon></mat-icon>&nbsp;Inventory Report-->
<!--                        </mat-list-item>-->
                        <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/issue_tbl">
                            <mat-icon></mat-icon>&nbsp;Issue Product
                        </mat-list-item>
                      <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/Issued_product_list">
                        <mat-icon></mat-icon>&nbsp;Issued Product List
                      </mat-list-item>

                        <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/request_product_new">
                            <mat-icon></mat-icon>Request Product
                        </mat-list-item>
                      <mat-list-item class="matlink"   role="listitem" routerLinkActive="active" routerLink="inventory/inventory-requests">
                            <mat-icon></mat-icon>Requests
                        </mat-list-item>
                      <mat-list-item  class="matlink" role="listitem" routerLinkActive="active" routerLink="inventory/tbl_kit">
                        <mat-icon>product</mat-icon>Hygiene Kit
                      </mat-list-item>

                    </mat-expansion-panel>
                </mat-accordion>

          <!--inventory-->
          <!--invenotry--->
            <mat-accordion class="mat-body" >
            <mat-expansion-panel class=" mat-body " (opened)="panelOpenState = true"
                                 (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="mat-body">
                        Reports
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-list-item *ngIf="donationReport[0].showMenu "  class="matlink" role="listitem" routerLinkActive="active" routerLink="donation">
                    <mat-icon> card_giftcard</mat-icon>&nbsp;Donation Report
                </mat-list-item>
              <mat-list-item class="matlink"  *ngIf="donationReport[0].showMenu " role="listitem" routerLinkActive="active" routerLink="inventory/failed-donation">
                <mat-icon></mat-icon>Failed Donation
              </mat-list-item>
              <mat-list-item class="matlink"  *ngIf="donationReport[0].showMenu "  role="listitem" routerLinkActive="active" routerLink="inventory/attempted-donation">
                <mat-icon></mat-icon>Attempted Donation
              </mat-list-item>
                <mat-list-item  class="matlink"  *ngIf="  childReport[0].showMenu ||childReport[0].all" role="listitem" routerLinkActive="active" routerLink="reports/child-report">
                    <mat-icon>child_care</mat-icon>&nbsp;Child Report
                </mat-list-item>
                <mat-list-item  class="matlink"  *ngIf="  childReport[0].showMenu ||childReport[0].all" role="listitem" routerLinkActive="active" routerLink="reports/admission-report">
                    <mat-icon>child_care</mat-icon>&nbsp;Admission Report
                </mat-list-item>
                <mat-list-item class="matlink"  *ngIf="  roleAs=='SuperAdmin'  || weeklyReport[0].showMenu" role="listitem" routerLinkActive="active" routerLink="reports/weekly-report">
                    <mat-icon>report</mat-icon>&nbsp;Weekly Report
                </mat-list-item>
<!--                <mat-list-item class="matlink"  *ngIf=" || roleAs=='SuperAdmin'||childReport[0].showMenu"  role="listitem" routerLinkActive="active" routerLink="/home/counseling/counseling-report">-->
<!--                    <mat-icon>dashboard</mat-icon>&nbsp;Counselling Report-->
<!--                </mat-list-item>-->
<!--                <mat-list-item class="matlink"  *ngIf=" || roleAs=='SuperAdmin'||childReport[0].showMenu"  role="listitem" routerLinkActive="active" routerLink="/home/education/education-report">-->
<!--                    <mat-icon>dashboard</mat-icon>&nbsp;Education Report-->
<!--                </mat-list-item>-->
            </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion class="mat-body" *ngIf="  mealMate[0].showMenu || unitSponsership[0].showMenu">
                <mat-expansion-panel class=" mat-body " (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mat-body">
                            Donation
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list-item *ngIf="mealMate[0].showMenu==true " class="matlink" role="listitem" routerLinkActive="active" routerLink="meals">
                        <mat-icon>local_dining</mat-icon>&nbsp;Mealmate
                    </mat-list-item>
                    <mat-list-item *ngIf="unitSponsership[0].showMenu" class="matlink" role="listitem" routerLinkActive="active"
                                   routerLink="unitsponsorship">
                        <mat-icon>language</mat-icon>&nbsp;Unit Sponsorship
                    </mat-list-item>
                </mat-expansion-panel>

            </mat-accordion>
            <mat-list-item  class="matlink"  role="listitem" routerLinkActive="active" routerLink="reminders"  *ngIf=" childAdmissionPermission[0].add ||donationReport[0].showMenu ">
                <mat-icon>alarm</mat-icon>&nbsp;Reminders
            </mat-list-item>
            <mat-list-item class="matlink" *ngIf="roleAs=='SuperAdmin'|| CMS[0].showMenu==true" role="listitem" routerLinkActive="active"
                           routerLink="CMS">
                <mat-icon>language</mat-icon>&nbsp;CMS
            </mat-list-item>

            <mat-accordion class="mat-body" *ngIf="roleAs=='Admin' || roleAs=='SuperAdmin' || Hospital[0].showMenu ||centres[0].showMenu ||Employees[0].showMenu" >
                <mat-expansion-panel class=" mat-body " (opened)="panelOpenState = true"
                                     (closed)="panelOpenState = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="mat-body">
                            Masters
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-list-item *ngIf="centres[0].showMenu==true" class="matlink" role="listitem" routerLinkActive="active" routerLink="allcentres">
                        <mat-icon>home</mat-icon>&nbsp;Centres
                    </mat-list-item>
                    <mat-list-item *ngIf="Employees[0].showMenu==true " class="matlink" role="listitem" routerLinkActive="active" routerLink="employees">
                        <mat-icon>group</mat-icon>&nbsp;Users
                    </mat-list-item>
                    <!*--------------------------------------------- VOLUNTEERS--------------------------------------------->
<!--                    <mat-list-item class="matlink" role="listitem" routerLinkActive="active" routerLink="volunteers">-->
<!--                        <mat-icon>group</mat-icon>&nbsp;Volunteers-->
<!--                    </mat-list-item>-->

                    <mat-list-item *ngIf="Hospital[0].showMenu" class="matlink" role="listitem" routerLinkActive="active" routerLink="hospitals">
                        <mat-icon>local_hospital</mat-icon>&nbsp;Hospital
                    </mat-list-item>

                    <mat-list-item *ngIf="Donor[0].showMenu" class="matlink" role="listitem" routerLinkActive="active" routerLink="Donor">
                        <mat-icon>groups</mat-icon>&nbsp;Donor
                    </mat-list-item>


                    <mat-list-item *ngIf="Events[0].showMenu " class="matlink" role="listitem" routerLinkActive="active" routerLink="Events">
                        <mat-icon>event</mat-icon>&nbsp;Events
                    </mat-list-item>
                    <mat-list-item *ngIf="AuditLogs[0].showMenu" class="matlink" role="listitem" routerLinkActive="active" routerLink="activitylog">
                        <mat-icon> local_activity</mat-icon>&nbsp;Audit Logs
                    </mat-list-item>
<!--                    <mat-list-item class="matlink" role="listitem" routerLinkActive="active" routerLink="Caretakers">-->
<!--                        <mat-icon>wc</mat-icon>&nbsp;Caretakers-->
<!--                    </mat-list-item>-->

                </mat-expansion-panel>
            </mat-accordion>


        </mat-list>
    </mat-drawer>
    <mat-drawer-content>
        <!--              <mat-card>-->
        <!--            <mat-card-content>-->
        <router-outlet>

        </router-outlet>
        <!--             </mat-card-content>-->
        <!--          </mat-card>-->
    </mat-drawer-content>
</mat-drawer-container>



