import {Component, isDevMode, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MatDialogRef} from "@angular/material/dialog";
import {CreatecenterComponent} from "../../centers/createcenter/createcenter.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSelectChange} from "@angular/material/select";
import {EventModel} from "../../../interfaces/EventModel";
import {Centre} from "../../../interfaces/Centres";
import {FullChild} from "../../../interfaces/FullChild";
import {ChildObject} from "../events.component";
import {environment} from "../../../../environments/environment";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";

@Component({
    selector: 'app-create-event',
    templateUrl: './create-event.component.html',
    styleUrls: ['./create-event.component.scss']
})

export class CreateEventComponent implements OnInit {
    env = isDevMode() ? environment.testMode : environment.productionMode

    centre$ = this.mFirestore.collection<Centre>(this.env.centres).valueChanges({idField: 'id'})

    timeSelected: any
    myForm!: FormGroup
    locations$: Observable<unknown[]>
    eventFor = 'Centre'
    child$ = this.mFirestore.collection<FullChild>(this.env.child).valueChanges({idField: 'id'})

    codeSelected: ChildObject | undefined | null
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  event=this.permissions.filter((x)=>x.functionName=='Event')


  constructor(private mDatabase: AngularFireDatabase,
                private mFirestore: AngularFirestore,
                private _matSnackbar: MatSnackBar,
                private matDialogRef: MatDialogRef<CreatecenterComponent>) {
        this.locations$ = this.mDatabase.list('types/Locations').valueChanges()
        this.myForm = new FormGroup({
            eventName: new FormControl('', Validators.required),
            eventType: new FormControl('', Validators.required),
            eventDate: new FormControl('', Validators.required),
            eventFor: new FormControl(this.eventFor, Validators.required),
            code: new FormControl(this.codeSelected, Validators.required),
            // eventLocations: new FormControl('', Validators.required),
            eventTime: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required),
            number: new FormControl('', Validators.required),
        })
    }

//GETTERS
    get eventName() {
        return this.myForm.get('eventName')
    }

    get eventType() {
        return this.myForm.get('eventType')
    }

    get description() {
        return this.myForm.get('description')
    }

    get eventTime() {
        return this.myForm.get('eventTime')
    }

    get Name() {
        return this.myForm.get('Name')
    }

    get email() {
        return this.myForm.get('email')
    }

    get number() {
        return this.myForm.get('number')
    }

    ngOnInit(): void {


    }

    createEvent() {
        if (this.myForm.valid) {
            console.table(this.myForm.value)
            let finalEvent: EventModel = this.myForm.value
            console.log(finalEvent)
            this.mFirestore.collection(this.env.events).add(finalEvent).then(res => {
                this._matSnackbar.open('Event Created', '', {duration: 3000})
                this.matDialogRef.close()
            })
        } else {
            console.log(this.findInvalidControls())
            this._matSnackbar.open('Please enter all fields properly', '')._dismissAfter(3000)
        }
    }


    selectCode($event: MatSelectChange) {
        this.codeSelected = $event.value
        console.log('Code Selected', this.codeSelected)
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.myForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

  cancel() {
    this.matDialogRef.close();

  }
}
