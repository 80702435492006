import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

@Component({
  selector: 'app-sponsorship-image',
  templateUrl: './sponsorship-image.component.html',
  styleUrls: ['./sponsorship-image.component.scss']
})
export class SponsorshipImageComponent implements OnInit {
selectedImage:any
  mData:any
  private childName!: string;
  fileUploaded: boolean = false;
  constructor( @Inject(MAT_DIALOG_DATA) public data: any,) {
  this.mData = data
  }

  ngOnInit(): void {
  }

  handleFileInput($event: Event) {
    const fileInput = $event.target as HTMLInputElement;
    const file = fileInput?.files?.[0];
    if (file) {
      this.selectedImage =file.stream()
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.selectedImage = event.target.result;
        this.fileUploaded = true
      }
      // @ts-ignore
      reader.readAsDataURL($event.target.files[0]);
      // Perform actions with the selected file here (e.g., upload to server, display preview, etc.)
      console.log('Selected File:', file);
    }
  }

  public openPDF(divId: HTMLDivElement): void {
    // const DATA: any =divId
    const DATA: any = document.querySelector('#capture');

    // @ts-ignore
    html2canvas(DATA).then((canvas) => {
        const PDF = new jsPDF('l', 'cm', 'a4');
        const fileWidth = 222;
        const fileHeight = (canvas.height * fileWidth) / canvas.width;
        const pdfwidth = PDF.internal.pageSize.getWidth()
        const pfheight = (canvas.height * pdfwidth) / canvas.width;
        const FILEURI = canvas.toDataURL('image/png');
        const position = 0;
        // PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
        const d = PDF.addImage(FILEURI, 'PNG', 0, position, 29.7, 21.7, '', 'FAST');
        // const filename = this.model.receipt_id
        const output = PDF.output()
        // console.log(output)
        /** To save PDF File */
        PDF.save(this.childName, {returnPromise: true}).then(()=>
          {
            /** To Upload Reciept Image to Database */
            // this.extracted(FILEURI, filename);

          }
        );

      },
    )
    //   console.log(JSON.stringify(this.mUri))
//    sendEmail()


  }

    getDiagnosis(diagnosisSelected: any) {
            let str=''
            diagnosisSelected.forEach((it: { centreName: string; },index:number)=>{
                if(diagnosisSelected.length-1 ==index)  str += it+' '
                else  str += it+', '
            })
            return str
    }
}
