import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Donor} from "../donor-table/donor-table.component";

@Component({
  selector: 'app-view-donor',
  templateUrl: './view-donor.component.html',
  styleUrls: ['./view-donor.component.scss']
})
export class ViewDonorComponent implements OnInit {

  constructor(    @Inject(MAT_DIALOG_DATA) public donor: Donor) {
    console.log(donor)
  }

  ngOnInit(): void {
  }

}
