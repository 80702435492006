import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChildService} from "../../child/child.service";

@Component({
  selector: 'app-render-receipt',
  templateUrl: './render-receipt.component.html',
  styleUrls: ['./render-receipt.component.scss']
})
export class RenderReceiptComponent implements OnInit {
link:string|undefined
  constructor(public dialogRef: MatDialogRef<RenderReceiptComponent>,
              @Inject(MAT_DIALOG_DATA) private receipt_id: string) {
    this.link = receipt_id
  }

  ngOnInit(): void {
  }

}
