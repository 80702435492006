import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ChildService} from "../child.service";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatSelectChange} from "@angular/material/select";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {CounselingService} from "../../../modules/counseling/counseling.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {FullChild} from "../../../interfaces/FullChild";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels, ApexGrid, ApexLegend,
  ApexMarkers, ApexPlotOptions,
  ApexStroke, ApexTitleSubtitle, ApexTooltip,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import {AdmissionType} from "../../../interfaces/AdmissionType";
import {DatePipe} from "@angular/common";


 type ChartOptions = {
   series: ApexAxisChartSeries;
   chart: ApexChart;
   xaxis: ApexXAxis;
   stroke: ApexStroke;
   dataLabels: ApexDataLabels;
   markers: ApexMarkers;
   colors: string[];
   yaxis: ApexYAxis;
   grid: ApexGrid;
   legend: ApexLegend;
   title: ApexTitleSubtitle;
   tooltip: ApexTooltip;
   plotOptions: ApexPlotOptions;
 };

@Component({
  selector: 'app-child-journey',
  templateUrl: './child-journey.component.html',
  styleUrls: ['./child-journey.component.scss']
})
export class ChildJourneyComponent implements OnInit {
  centres$: Observable<Centre[]>
  childInfo!: FullChild
  public areaChartOptions!: Partial<ChartOptions>;
  educationSession: any[] = []
  counsellingSession: any[] = [];
  daysDifference!: number;
  counsellingGroupSession: any[] = [];
  admissionData: any[] = [];
  childGroupSessionCount!: number;
  educationGroupSession: any[] = [];
  childEducationGroupSessionCount!: number;
  displayedColumns: string[] = ['dateOfAdmission', 'dateOfDischarge', 'duration'];
  displayedColumns2: string[] = ['sr', 'date', 'height', 'weight'];
  datasource = new MatTableDataSource()
  // datasource2: FullChild = new MatTableDataSource()
  mData: any[] = []
  show=false
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  weight: number[] = [];
  height: number[] =[];
  dates: string[] = [];
showChart=false
  constructor(private mChildService: ChildService,
              private readonly centreService: CentreService,
              private readonly mFirestore: AngularFirestore,
              private readonly mDatePipe:DatePipe,
              private readonly mCounselling: CounselingService,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.centres$ = this.centreService.getAllCentres()
    // this.datasource2 =this.childInfo

  }

  ngOnInit(): void {
    // this.areaChartOptions = {
    //   title: {text:'Height and Weight Chart'},
    //   series: [
    //
    //     heightData,weightData
    //   ],
    //   chart: {
    //     height: 350,
    //     type: 'bar',
    //     toolbar: {
    //       show: true,
    //     },
    //     foreColor: '#9aa0ac',
    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: true,
    //       dataLabels: {
    //         position: "top"
    //       }
    //     }
    //   },
    //   colors: ['#0e28a6', '#e83108'],
    //   dataLabels: {
    //     enabled: true,
    //   },
    //   stroke: {
    //     curve: 'smooth',
    //   },
    //   xaxis: {
    //     type: 'category',
    //     categories:this.dates,
    //     // categories: [
    //     //     '2018-09-19',
    //     //     '2018-09-20',
    //     //     '2018-09-21',
    //     //     '2018-09-22',
    //     //     '2018-09-23',
    //     //     '2018-09-24',
    //     //     '2018-09-25',
    //     // ],
    //   },
    //   legend: {
    //     show: true,
    //     position: 'top',
    //     horizontalAlign: 'center',
    //     offsetX: 0,
    //     offsetY: 0,
    //   },
    //   grid: {
    //     show: true,
    //     borderColor: '#9aa0ac',
    //     strokeDashArray: 1,
    //   },
    //   tooltip: {
    //     theme: 'dark',
    //     marker: {
    //       show: true,
    //     },
    //     x: {
    //       show: true,
    //     },
    //   },
    // };

    console.log(this.data)
    this.mFirestore.collection<FullChild>('ALChild').doc(`${this.data.childCode}`).valueChanges({idField: 'id'}).subscribe(res => {
      console.log(res)
      this.childInfo = res!!


      this.admissionData = this.childInfo!!.admissionData;
      this.calculateStay(this.childInfo)
      console.log(this.admissionData)
      this.admissionData.forEach(res => {
        console.log(res.dateOfAdmission)
        // const dateOfAdmission = new Date(this.childInfo!!.dateOfAdmission.seconds * 1000);
        // const dischargeDate = new Date(this.childInfo!!.dischargeDate!!.seconds * 1000);

        // this.calculateDays(dateOfAdmission, dischargeDate);

      })

      this.childInfo!!.diagnosisSelected
      setTimeout(() => {
        this.areachart()
      }, 2000);

    })

    this.mFirestore.collection('educationTest', ref => ref.where('childCode', '==', `${this.data.childCode}`)).valueChanges().subscribe(res => {
      console.log(res)
      this.educationSession = res
      console.log(this.educationSession.length)
    })

    this.mFirestore.collection('educationGroupTest').valueChanges().subscribe(res => {
      console.log(res)
      this.educationGroupSession = res
      console.log(this.educationSession.length)
      const desiredChildCode2 = `${this.data.childCode}`;

      let childCount = 0;

      this.educationGroupSession.forEach(session => {
        childCount += session.children.filter((child: {
          childCode: string
        }) => child.childCode === desiredChildCode2).length;
      });

      console.log(`${desiredChildCode2} is present ${childCount} times in the counselling group session.`);

      this.childEducationGroupSessionCount = childCount
    })

    this.mFirestore.collection('Counselling').doc('allSessions').collection('sessions', ref => ref.where('childCode', '==', `${this.data.childCode}`)).valueChanges().subscribe(res => {
      console.log(res)
      this.counsellingSession = res
    })

    this.mFirestore.collection('Counselling').doc('allSessions').collection('groupSessions').valueChanges().subscribe(res => {
      console.log(res)
      this.counsellingGroupSession = res
      const desiredChildCode = `${this.data.childCode}`;

      let childCount = 0;

      this.counsellingGroupSession.forEach(session => {
        childCount += session.children.filter((child: {
          childCode: string
        }) => child.childCode === desiredChildCode).length;
      });

      console.log(`${desiredChildCode} is present ${childCount} times in the counselling group session.`);

      this.childGroupSessionCount = childCount
    })


  }

  calculateDays(admissionDate: Date, dischargeDate: Date): number {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const admissionTime = admissionDate.getTime();
    const dischargeTime = dischargeDate.getTime();

    const timeDifference = dischargeTime - admissionTime;
    this.daysDifference = Math.round(timeDifference / millisecondsPerDay);
    console.log("here", this.daysDifference)
    return this.daysDifference;
  }

  setSelectedCentreCode($event: MatSelectChange) {

  }

  private areachartOld() {
    const weight: number[] = [];
    const height: number[] = [];
    const dates: string[] = [];

    this.childInfo.medical_history.forEach((it:any,i:number)=>{
      if(i%2!=0) {
        if (it.height) height.push(parseInt(it.height))
        if (it.weight) weight.push(parseInt(it.weight))
        if (it.res_timing) dates.push(new Date(it.res_timing).toLocaleDateString())
      }
    })

    // Assuming you want to display a single data point for each metric
    const weightData = { x: `${this.childInfo.dateOfDischarge}`, y: weight };
    const heightData = { x: `${this.childInfo.dateOfDischarge}`, y: height };
    console.log(weight)
    console.log(weightData)
    this.areaChartOptions = {
      title: {text:'Height & Weight Chart'},
      series: [

        {
          name: 'Height',
          data: this.height,
        },
        {
          name: 'Weight',
          data: this.weight,
        },
      ],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: true,
        },
        foreColor: '#9aa0ac',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      colors: ['#0e28a6', '#e83108'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: this.dates,

      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        offsetX: 0,
        offsetY: 0,
      },
      grid: {
        show: true,
        borderColor: '#9aa0ac',
        strokeDashArray: 1,
      },
      tooltip: {
        theme: 'dark',
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };


  }

  private areachart() {
this.showChart=false
    this.childInfo.medical_history.forEach((it: any, i: number) => {
      if (i % 2 == 1) {
        if (it.height!=null && it.res_timing!=null && it.weight!=null) {
          this.weight.push(parseInt(it.height));
          this. height.push(parseInt(it.weight));
       this.dates.push(  new Date(it.res_timing).toLocaleDateString())
        }

      }
      if (i % 2 == 0) {
        if (it.height!=null && it.res_timing!=null && it.weight!=null) {
          this.weight.push(parseInt(it.height));
          this. height.push(parseInt(it.weight));
        this.dates.push(new Date(it.res_timing).toLocaleDateString())
        }
      }
    });

    const weightData = {
      name: 'Weight',
      data:this.weight,
      // data:this.  weight,
    };
    const heightData = {
      name: 'Height',
      data:this.height,
    };
    console.log('weight data',this.weight.length)
    console.log('height data',this.height.length)
    this.areaChartOptions = {
      title: {text:'Height and Weight Chart'},
      series: [
       heightData,weightData
      ],
      chart: {
        height: 350,
        type: 'bar',
        toolbar: {
          show: true,
        },
        foreColor: '#9aa0ac',
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      colors: ['#0e28a6', '#e83108'],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories:this.dates,
        // categories: [
        //     '2018-09-19',
        //     '2018-09-20',
        //     '2018-09-21',
        //     '2018-09-22',
        //     '2018-09-23',
        //     '2018-09-24',
        //     '2018-09-25',
        // ],
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'center',
        offsetX: 0,
        offsetY: 0,
      },
      grid: {
        show: true,
        borderColor: '#9aa0ac',
        strokeDashArray: 1,
      },
      tooltip: {
        theme: 'dark',
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };
 if(this.dates.length>=1 && this.height.length>=1  && this.weight.length>=1) {
   this.show = true
   this.showChart=true
 }
  }
  calculateAge(dob: any): number {
    const today = new Date();
    const birthDate = dob.toDate()
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  getChildPhotoUrl(admissionDocuments: any[]): string {
    const childPhotoDocument = admissionDocuments.find(doc => doc.description === 'Child Photo');

    return childPhotoDocument ? childPhotoDocument.fileUrl : '';
  }

  isString(value: any): value is string {
    return typeof value === 'string';
  }

  isObject(value: any): value is { name: string } {
    return typeof value === 'object' && value !== null && 'name' in value;
  }

  private calculateStay(selectedChild: FullChild) {
   let currentDate=new Date()
    this.mData = []
    selectedChild?.admissionData.forEach((ad, index) => {
      if (index % 2 == 0 && selectedChild.admissionData[index + 1] != null) {
        let obj = {
          childName: selectedChild.firstName + " " + selectedChild?.lastName,
          childCode: selectedChild.childCode,
          centreName: selectedChild.centreName,
          admissionDate: ad.dateOfAdmission?.toDate(),
          dischargeDate: selectedChild.admissionData[index + 1].dateOfDischarge!!.toDate(),
          treatmentDuration: this.calculateDurationBetweenDatesInDays(ad.dateOfAdmission?.toDate()!!, selectedChild.admissionData[index + 1].dateOfDischarge!!.toDate())
        }
// Push obj to this.mData or do something with it as needed.
        this.mData.push(obj);
        this.datasource = new MatTableDataSource(this.mData)
        this.datasource.paginator = this.paginator
        this.datasource.sort = this.sort
      } else if (index % 2 == 0 && selectedChild.admissionData[index + 1] == null) {
        let obj = {
          childName: selectedChild.firstName + " " + selectedChild?.lastName,
          childCode: selectedChild.childCode,
          centreName: selectedChild.centreName,
          admissionDate: ad.dateOfAdmission?.toDate(),
          // dischargeDate:selectedChild.admissionData[index+1].dateOfDischarge!!.toDate(),
          treatmentDuration:this.calculateDurationBetweenDatesInDays(ad.dateOfAdmission?.toDate()!!,currentDate),
        }
// Push obj to this.mData or do something with it as needed.
        this.mData.push(obj);
        this.datasource = new MatTableDataSource(this.mData)
        this.datasource.paginator = this.paginator
        this.datasource.sort = this.sort
      }

    })

    console.log(this.mData)
  }

  private calculateDurationBetweenDatesInDays(toDate: Date, date: Date): string {
    // Calculate the duration in days
    const timeDifference = toDate.getTime() - date.getTime();
    const daysDifference = -Math.floor(timeDifference / (1000 * 3600 * 24));

    // Return the duration as a string
    return daysDifference.toString();
  }

  calculateTotalDuration(data: any[]): number {
    // Adapt this based on your data structure and duration type
    return data.reduce((acc, row) => acc + +row.treatmentDuration, 0);
  }
}
