<mat-card class=" container mat-elevation-z3" style="width:550px">
  <mat-card-header style="font-weight: bolder;color: #5c636a;font-size: 20px" ><mat-icon class="mx-1">camera_alt</mat-icon> Image Upload</mat-card-header>

  <div class="my-3">
<!--    <h3>Data will be uploaded to {{storagePath}} in {{dbType}} at {{path}} </h3>-->
    <app-image-upload [pathToDatabase]="path" [dbType]="dbType" [storagePath]="storagePath"></app-image-upload>
  </div>
  <div class="my-2" *ngIf="fetch">
    <app-upload-list [pathToDatabase]="path" [dbType]="dbType" [storagePath]="storagePath" ></app-upload-list>
  </div>
</mat-card>
