<table mat-table
       [dataSource]="dataSource" multiTemplateDataRows
       class="mat-elevation-z8">
       <ng-container matColumnDef="centername">
        <th mat-header-cell *matHeaderCellDef> Centre Name </th>
        <td mat-cell *matCellDef="let element"> {{element.centreName}} </td>
      </ng-container>
  
      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef> City </th>
        <td mat-cell *matCellDef="let element"> {{element.city}} </td>
      </ng-container>
  
      <ng-container matColumnDef="availableunits">
        <th mat-header-cell *matHeaderCellDef> Available Units </th>
        <td mat-cell *matCellDef="let element"> {{element.availableUnits}} </td>
      </ng-container>
      <ng-container matColumnDef="occupiedunits">
        <th mat-header-cell *matHeaderCellDef> Occupied Units </th>
        <td mat-cell *matCellDef="let element"> {{element.occupiedUnits}} </td>
      </ng-container>
  
      <ng-container matColumnDef="totalunits">
        <th mat-header-cell *matHeaderCellDef> Total Units </th>
        <td mat-cell *matCellDef="let element"> {{element.totalUnits}} </td>
      </ng-container>
  
  
     
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
         -
        </td>
      </ng-container>
  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail" >
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
      <div class="example-element-detail"
           [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
       
        <div style="padding-bottom: 10px;">

          
          <div class="flex-container">

            <div class="flex-child magenta">
              <mat-list>
                <mat-list-item>Meal Type</mat-list-item>
                <mat-list-item>
                  <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>brightness_6</mat-icon>
                        Lunch</span>
                    </label>
                  </div>                 
                </mat-list-item>           
              </mat-list>
            </div>
            
            <div class="flex-child green">
              <mat-list>
                <mat-list-item>Meal For</mat-list-item>
                <mat-list-item>
                  <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>child_care</mat-icon>Only child</span>
                    </label>
                  </div>
                  <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>supervised_user_circle</mat-icon>
                        Family</span>
                    </label> 
                  </div>
                </mat-list-item>           
              </mat-list>
            </div>
            <div class="flex-child green">

            </div>
          </div>
          <div class="flex-container">

            <div class="flex-child magenta">
              <mat-list>
                <mat-list-item>
                  <!-- <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>brightness_6</mat-icon>
                        Lunch</span>
                    </label>
                  </div> -->
                  <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>brightness_3</mat-icon>
                        Dinner</span>
                    </label> 
                  </div>
                </mat-list-item>           
              </mat-list>
            </div>
            
            <div class="flex-child green">
              <mat-list>
               
                <mat-list-item>
                  <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>child_care</mat-icon>Only child</span>
                    </label>
                  </div>
                  <div class="cat action">
                    <label>
                      <input type="checkbox" value="1"><span><mat-icon>supervised_user_circle</mat-icon>
                        Family</span>
                    </label> 
                  </div>
                </mat-list-item>           
              </mat-list>
            </div>
            <div class="flex-child green">
<button (click)="addMeal">Add Meal</button>
            </div>
          </div>
  
       
          <!-- {{element| json}} ------------>
          <!-- <span class="example-element-description-attribution"> -- Wikipedia </span> --> 
        </div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
</table>