import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {EMPTY, Observable, Subject, takeUntil} from "rxjs";
import {DialogRef} from "@angular/cdk/dialog";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../auth.service";
import {UtilService} from "../../../services/util.service";
import {FileStorageService} from "../../../services/file-storage.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {catchError} from "rxjs/operators";
import firebase from "firebase/compat/app/dist/compat/app";
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {UploadModel} from "../../../models/UploadModel";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-send-photo-email',
  templateUrl: './send-photo-email.component.html',
  styleUrls: ['./send-photo-email.component.scss']
})
export class SendPhotoEmailComponent implements OnInit ,OnDestroy{
  destroy$: Subject<null> = new Subject();
  fileToUpload!: File;
  submitted = false;
  show=false
  uploadProgress$?: Observable<number | undefined>;
  kittyImagePreview!: string | ArrayBuffer;
  MEDIA_STORAGE_PATH = 'Documents'
  pictureForm!: FormGroup;
  documents$: Observable<unknown | undefined>
   mPath='MealMatePhotoEmail';
  photoview:any
  mDocuments?:any
  _destroyed = new Subject()
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private readonly matDialogRef:DialogRef<SendPhotoEmailComponent>,
      private readonly formBuilder: FormBuilder,
      private readonly authService: AuthService,
      private readonly utilService: UtilService,
      private readonly mLogService:LogService,
      private readonly storageService: FileStorageService,
      private readonly snackBar: MatSnackBar,
      private readonly mStorage:AngularFireStorage,

      private readonly mFirestore: AngularFirestore,
      private functions: AngularFireFunctions
  ) {
    this.mPath =this.mPath + '/' + data.id

    this.documents$  = this.mFirestore.doc( this.mPath).valueChanges({idField:'id'})
    this.documents$.pipe(takeUntil(this._destroyed)).subscribe(res=>{

      // @ts-ignore
      this.mDocuments = res
    if(this.mDocuments.fileUrl!=null)this.show =true
    })
  }

  ngOnInit(): void {
    this.pictureForm = this.formBuilder.group({
      photo: [null, [Validators.required, this.image.bind(this)]],
      id: [this.data.id, Validators.required],
      description: [null, Validators.required],
      sentAt: [new Date(), Validators.required],
    });

    this.pictureForm.get('photo')?.valueChanges.pipe(takeUntil(this.destroy$))
        .subscribe((newValue) => {
          this.handleFileChange(newValue.files);
          console.log(newValue.files)
        });

    // this.authService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => (this.user = user));
    // console.log(this.user)
  }
  // @ts-ignore

  handleFileChange([kittyImage]) {
    this.fileToUpload = kittyImage;
    const reader = new FileReader();
    reader.onload = (loadEvent) => (this.kittyImagePreview = loadEvent.target?.result!);
    reader.readAsDataURL(kittyImage);
  }
  private image(photoControl: AbstractControl): { [key: string]: boolean } | null {
    if (photoControl.value) {
      const [kittyImage] = photoControl.value.files;
      return this.utilService.validateFile(kittyImage) ? null : {
            image: true,
          };
    }
    // @ts-ignore
    return;
  }
@needConfirmation({title:'Are you sure you want to submit?',message:'An email will be sent to donor on completion'})
  postKitty() {
    //? Image size Validation 4mb in (bytes)
    if(this.fileToUpload.size<2000000)  {
      this.submitted = true;

      this.MEDIA_STORAGE_PATH = `MealmatePhoto/${this.data.id}`
      const mediaFolderPath = this.MEDIA_STORAGE_PATH

      const {downloadUrl$, uploadProgress$} = this.storageService.uploadFileAndGetMetadata(
          mediaFolderPath,
          this.fileToUpload,
      );

      this.uploadProgress$ = uploadProgress$;

      downloadUrl$
          .pipe(
              takeUntil(this.destroy$),
              catchError((error) => {
                this.snackBar.open(`${error.message} `, 'Close', {
                  duration: 4000,
                });
                return EMPTY;
              }),
          )
          .subscribe((downloadUrl) => {
            this.submitted = false;
            console.log('DownloadUrl', downloadUrl)
            const uploadData = {
              'fileUrl': downloadUrl,
              'description': this.pictureForm.get('description')?.value,
              'uploadedAt': new Date()

            }
            this.mFirestore.collection('MealMatePhotoEmail').doc(this.data.id).set(
              // [this.data.type]: firebase.firestore.FieldValue.arrayUnion(uploadData)
              uploadData
            ).then(() => {
              const callable = this.functions.httpsCallable('mealMatePhoto');
              return callable({ email: this.data.email, photoUrl: downloadUrl }).toPromise().then(()=>{
                this.snackBar.open('File Uploaded', '')._dismissAfter(3000)
                let activity={
                  date:new Date().getTime(),
                  section: 'Mealmate',
                  action: 'Submit',

                  description: `Meal Photo updated by`,
                  currentIp: localStorage.getItem('currentip')!
                }
                this.mLogService.addLog(activity)
                this.matDialogRef.close()
              })

            })
            // this.router.navigate([`/${FEED}`]);
          });
    }
    else {
      this.snackBar.open('Maximum File size allowed is 2mb','ERROR',{duration:3000})
    }
  }

  openLink(fileUrl: string) {
    window.open(fileUrl)

  }

  @needConfirmation()
  deleteAdmissionFile(d: any) {
    const iRef = this.mStorage.storage.refFromURL(d)
    iRef.delete().then(() => {
      this.mFirestore.doc(this.mPath).delete().then(() => {
        this.snackBar.open('File Deleted Successfully')._dismissAfter(2000)
      })
    })
    console.log(iRef)
  }

  ngOnDestroy(): void {
  this._destroyed.next('')
  this._destroyed.complete()
  }
}
