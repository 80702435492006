<div class="container">
  <h1 class=" bg-warning text-center">Unit Transfer Section</h1>
<div class="row">
  <mat-form-field class="col-md-12">
    <mat-label>Select Centre</mat-label>
    <mat-select (selectionChange)="onCenterSelected($event)" >
      <!--        <mat-option value="centreCode">centreName</mat-option>-->
      <ng-container *ngFor="let center of (centre|async );" >
        <mat-option  *ngIf="center.isArchive!=true" [value]="center">{{center.centreName}}</mat-option></ng-container>
    </mat-select>
  </mat-form-field>

<mat-form-field class="col-md-12">
  <mat-label>From</mat-label>
  <mat-select [(ngModel)]="fromChild" (selectionChange)="fetchChildDataFromUnitA($event)">
    <mat-option *ngFor="let u of unitsArray" [value]="u">{{u.name}}  {{u.floorNo}} {{u.key}} </mat-option>
  </mat-select>
</mat-form-field>

  <mat-form-field class="col-md-12">
    <mat-label>To</mat-label>
    <mat-select [(ngModel)]="toChild" (selectionChange)="fetchChildDataFromUnitB($event)">
      <mat-option *ngFor="let u of unitsArray" [value]="u">{{u.name}}  {{u.floorNo}} {{u.key}} </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="accent" (click)="submit()">Submit</button>
</div>
</div>
