import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {
    AngularFirestore,
    AngularFirestoreCollection,
    CollectionReference,
    DocumentChangeAction
} from "@angular/fire/compat/firestore";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSelectChange} from "@angular/material/select";
import {FormControl} from "@angular/forms";
import {map} from "rxjs/operators";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;

import {AngularFireDatabase} from "@angular/fire/compat/database";
import {where} from "@angular/fire/firestore";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {Workbook} from "exceljs";
import {FullChild} from "../../../../interfaces/FullChild";
import {Centre} from "../../../../interfaces/Centres";
import {Hospitals} from "../../../../interfaces/Hospitals";
import {UserRole} from "../../../../components/rename/add-user-role/add-user-role.component";
import {CentreService} from "../../../../services/centre.service";
import {HospitalService} from "../../../../services/hospital.service";
import {AuthService} from "../../../../auth.service";
import {LogService} from "../../../../services/log.service";
import {ExportExcelService} from "../../../../services/export-excel.service";
import {ChildService} from "../../../../components/child/child.service";

@Component({
    selector: 'app-child-report',
    templateUrl: './child-report.component.html',
    styleUrls: ['./child-report.component.scss']
})
export class ChildReportComponent implements OnInit {
    fileName = 'Child_Report.xlsx';

    childs?: Observable<DocumentChangeAction<FullChild>[]>
    selectedCentreCode?: string | null
    displayedColumns: String[] = ['position', 'firstname', 'lastname', 'gender', 'state', 'enrolledIn', 'status'];
    childData: FullChild[] = []
    flatData: {}[] = []
    case?: string
    private _destroyed$ = new Subject()
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
    dataSource: MatTableDataSource<FullChild>;
    centres$: Observable<Centre[]>

    startDate?: Date | null
    endDate?: Date | null
    selectedGender?: string | null
    selectedStatus?: string | null
    gender = new FormControl('')
    selectedLocation?: string | null;
    selectedHospital?: string | null;
    hospitals$: Observable<Hospitals[]>;
    selectedDiagnosis?: string | null;
    _diagnosis$: Observable<String[]>;
    private baseRef: AngularFirestoreCollection<FullChild>;
    dataForExcel: any[] = [];

    permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
    childReport = this.permissions.filter((x) => x.functionName == 'Child Report')


    show = false //for dynamic repory component
    constructor(private matdialog: MatDialog,
                private router: Router,
                private matDialog: MatDialog,
                private _snackBar: MatSnackBar,
                private readonly mdatabase: AngularFireDatabase,
                private readonly centreService: CentreService,
                private readonly hospitalService: HospitalService,
                readonly authService: AuthService,
                readonly mLogService: LogService,
                private readonly mFirestore: AngularFirestore,
                private readonly exportExcelService: ExportExcelService,
                private childService: ChildService) {
        this.dataSource = new MatTableDataSource(this.childData);
        this.centres$ = this.centreService.getAllCentres()
        this.hospitals$ = hospitalService.getHospitalList()
      this._diagnosis$ = this.mdatabase.list<String>('types/Diagnosis').valueChanges().pipe(
        map(diagnosis => diagnosis.map(d => d.toString()).sort((a, b) => a.localeCompare(b)))
      );
        this.baseRef = this.mFirestore.collection<FullChild>('ALChild');

    }

    ngAfterViewInit(): void {

        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;
    }


    applyFilter($event: Event) {
        const filterValue = ($event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }

    }


    ngOnDestroy(): void {
        this._destroyed$.next(null)
        this._destroyed$.complete()
    }

    getData() {
        // console.log(`startDate: ${this.startDate} endDate: ${this.endDate}  `)
        console.log(`startDate: ${this.startDate}`)
        console.log(`endDate: ${this.endDate}`)
        console.log(`gender: ${this.selectedGender}`)
        console.log(`centre: ${this.selectedCentreCode}`)
        let query = this.mFirestore.collection('ALChild', ref => ref.limit(10))
        // this.fetchquery(query)

// * startDate & endDate
//         if (this.startDate != null && this.endDate!=null) {


        //* FROM TO CENTRE GENDER LOCATION HOSPITAL DIAGONSIS
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null && this.selectedLocation && this.selectedHospital != null && this.selectedDiagnosis != null) {
            console.log('INTO' + 'FROM TO CENTRE GENDER LOCATION HOSPITAL DIAGONSIS')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                .where('gender', '==', this.selectedGender)
                .where('state', '==', this.selectedLocation)
                .where('hospital', '==', this.selectedHospital)
                .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }


        //* FROM TO CENTRE GENDER LOCATION HOSPITAL
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null && this.selectedLocation && this.selectedHospital != null && this.selectedDiagnosis == null) {
            console.log('INTO' + 'FROM TO CENTRE GENDER LOCATION HOSPITAL ')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                .where('gender', '==', this.selectedGender)
                .where('state', '==', this.selectedLocation)
                .where('hospital', '==', this.selectedHospital)
                // .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }
        //* FROM TO CENTRE GENDER LOCATION
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null && this.selectedLocation) {
            console.log('INTO' + 'FROM TO CENTRE GENDER LOCATION')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                .where('gender', '==', this.selectedGender)
                .where('state', '==', this.selectedLocation)
                // .where('hospital', '==', this.selectedHospital)
                // .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }

        //* FROM TO CENTRE GENDER
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null) {
            console.log('INTO' + 'FROM TO CENTRE GENDER ')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                .where('gender', '==', this.selectedGender)
                // .where('state', '==', this.selectedLocation)
                // .where('hospital', '==', this.selectedHospital)
                // .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }

        //* FROM TO CENTRE
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null) {
            console.log('INTO' + 'FROM TO CENTRE ')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                // .where('gender', '==', this.selectedGender)
                // .where('state', '==', this.selectedLocation)
                // .where('hospital', '==', this.selectedHospital)
                // .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }
        //* FROM TO  GENDER
        if (this.startDate != null && this.endDate != null && this.selectedGender != null) {
            console.log('INTO' + 'FROM TO CENTRE GENDER LOCATION HOSPITAL DIAGONSIS')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('gender', '==', this.selectedGender)
                // .where('gender', '==', this.selectedGender)
                // .where('state', '==', this.selectedLocation)
                // .where('hospital', '==', this.selectedHospital)
                // .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }
        //* FROM TO CENTRE GENDER LOCATION !HOSPITAL DIAGONSIS
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null && this.selectedLocation && this.selectedHospital == null && this.selectedDiagnosis != null) {
            console.log('INTO' + 'FROM TO CENTRE GENDER LOCATION HOSPITAL DIAGONSIS')
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref
                .where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                .where('gender', '==', this.selectedGender)
                .where('state', '==', this.selectedLocation)
                // .where('hospital', '==', this.selectedHospital)
                .where('diagnosis', '==', this.selectedDiagnosis)
                .limit(5))
        }

        //* startDate EndDate selectionCentre selectGender
        if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null && this.selectedGender != null) {
            this.case = 'start and end date'
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref.where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate)
                .where('centreCode', '==', this.selectedCentreCode)
                .where('gender', '==', this.selectedGender)
                .limit(5))
            this.fetchquery(query)
        }
        //* startDate endDate selectedGender
        else if (this.startDate != null && this.endDate != null && this.selectedCentreCode != null) {
            this.case = 'start and end date'
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref.where('dateOfAdmission', '>=', this.startDate).where('centreCode', '==', this.selectedCentreCode)
                .where('dateOfAdmission', '<=', this.endDate).limit(5))
            this.fetchquery(query)
        } else if (this.startDate != null && this.endDate != null) {
            this.case = 'start and end date'
            query = this.mFirestore.collection<FullChild>('ALChild', ref => ref.where('dateOfAdmission', '>=', this.startDate)
                .where('dateOfAdmission', '<=', this.endDate).limit(5))
            this.fetchquery(query)
        }

    }

    setSelectedCentreCode($event: MatSelectChange) {
        this.selectedCentreCode = $event.value

        console.log(this.selectedCentreCode)
        //this.getData(this.selectedCentreCode)
    }

    ngOnInit(): void {
    }

    searchByDate() {


    }

    getDate(date: Timestamp): Date {
        return (date as Timestamp).toDate();
    }

    getFormattedDate(childCode: string, date: any, type: string): string {
        if (date == null) {
            // console.error(`error parsing date in ${childCode} at ${type}  `,date)
            return ''
        } else {
            try {
                let y = (date as unknown as Timestamp).toDate()
                let d = y.toLocaleDateString();
                console.log(y)
                console.log(d)
                return d
            } catch (e) {
                console.error(`error parsing date in ${childCode} at ${type}  `, date)
                return ''
            }
        }
    }

    getFormattedDateOfDischarge(childCode: string, date: any, type: string): string {


        try {
            return ((date as unknown as Timestamp).toDate()).toLocaleDateString();
        } catch (e) {
            console.error(`error parsing date in ${childCode} at ${type}  `, date)
            return ''
        }

    }

    private fetchquery(query: AngularFirestoreCollection<unknown>) {
        query.get().pipe(
            map(x => x.docs),

            takeUntil(this._destroyed$)
        )
            .subscribe(data => {
                let cdata = data.map(y => y.data() as FullChild)
                cdata.forEach(it => {
                    it.age = this.CalculateAge(it.dob as Timestamp)
                })
                console.log('childList Subscription', data)
                //   console.assessment-table(data)
                this.childData = cdata
                this.dataSource = new MatTableDataSource(this.childData)
                this.dataSource.data = this.childData
                this.dataSource.sort = this.sort
                this.dataSource.paginator = this.paginator
                // const x = data as Child;
                // this.childData.(x)
                // console.log(x.children)
                // console.log('Child: ', y[0])
            })
    }

    private fetchquery2(query: firebase.firestore.Query<FullChild>) {
        console.log('into fetchquery')
        query.get().then(res => {
            let c = res.docs.map(x => x.data() as FullChild)
            c.forEach(child => {
                // child.age = this.CalculateAge(child.dob as Timestamp)

            })
            console.log('c=> ' + c.length)
            this.childData = c
            this.dataSource = new MatTableDataSource(this.childData)
            // this.dataSource.data = this.childData
            this.dataSource.sort = this.sort
            this.dataSource.paginator = this.paginator
        })
        let activity = {
            date: new Date().getTime(),
            section: 'Child Report',
            action: 'Search',

            description: `Search done by`,
            currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
    }

    onSelectGender($event: MatSelectChange) {
        this.selectedGender = $event.value
    }

    CalculateAge(dob: Timestamp) {
        const d = Date.now()
        let timeDiff = Math.abs(d - dob.toDate().getTime());
        let m = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

        // let m = moment().diff(dob.toDate(),'year',false)
        console.log(m)
        return m
    }

    onSelectLocation($event: MatSelectChange) {
        this.selectedLocation = $event.value
    }

    onSelectHospital($event: MatSelectChange) {
        this.selectedHospital = $event.value as string
    }

    StateNames = [
        {name: "Andaman and Nicobar Islands"},
        {name: "Andhra Pradesh"},
        {name: "Arunachal Pradesh"},
        {name: "Assam"},
        {name: "Bihar"},
        {name: "Chandigarh"},
        {name: "Chhattisgarh"},
        {name: "Dadra and Nagar Haveli"},
        {name: "Daman and Diu"},
        {name: "Delhi"},
        {name: "Goa"},
        {name: "Gujarat"},
        {name: "Haryana"},
        {name: "Himachal Pradesh"},
        {name: "Jammu and Kashmir"},
        {name: "Jharkhand"},
        {name: "Karnataka"},
        {name: "Kerala"},
        {name: "Lakshadweep"},
        {name: "Madhya Pradesh"},
        {name: "Maharashtra"},
        {name: "Manipur"},
        {name: "Meghalaya"},
        {name: "Mizoram"},
        {name: "Nagaland"},
        {name: "Odisha"},
        {name: "Puducherry"},
        {name: "Punjab"},
        {name: "Rajasthan"},
        {name: "Sikkim"},
        {name: "Tamil Nadu"},
        {name: "Telangana"},
        {name: "Tripura"},
        {name: "Uttarakhand"},
        {name: "Uttar Pradesh"},
        {name: "West Bengal"}]

    onDiagnosisSelect($event: MatSelectChange) {
        this.selectedDiagnosis = $event.value
    }

    isFrom() {
        return this.startDate != null
    }

    isTo() {
        return this.endDate != null
    }

    isCentre() {
        return this.selectedCentreCode != null
    }

    isGender() {
        return this.selectedGender != null
    }

    isLocation() {
        return this.selectedLocation != null
    }

    isHospital() {
        return this.selectedHospital != null
    }

    isType() {
        return this.selectedStatus != null
    }

    isDiagnosis() {
        return this.selectedDiagnosis
    }

    isStatus() {
        return this.selectedStatus
    }


    dynamicQuery() {
        // FROM TO CENTRE GENDER LOCATION HOSPITAL DIAGONSIS
        let myQuery: firebase.firestore.Query<FullChild> = this.baseRef.ref;

        if (this.isFrom()) {
            console.log('into from')
            myQuery = myQuery.where('dateOfAdmission', '>=', this.startDate);
        }
        if (this.isTo()) {
            console.log('into to')

            myQuery = myQuery.where('dateOfAdmission', '<=', this.endDate);
        }
        if (this.isCentre()) {
            console.log('into centrecode')

            myQuery = myQuery.where('centreCode', '==', this.selectedCentreCode);
        }
        if (this.isGender()) {
            console.log('into gender')

            myQuery = myQuery.where('gender', '==', this.selectedGender);
        }
        if (this.isLocation()) {
            console.log('into state')

            myQuery = myQuery.where('state', '==', this.selectedLocation);
        }
        if (this.isDiagnosis()) {
            console.log('into diagonsis')

            myQuery = myQuery.where('Diagnosis', '==', this.selectedDiagnosis);
        }
        // if (this.isType()) {
        //     console.log('into alloted')
        //
        //     myQuery = myQuery.where('type', '==', this.selectedStatus);
        // }
        if (this.isHospital()) {
            console.log('into hospital')

            myQuery = myQuery.where('hospital', '==', this.selectedHospital);
        }
        if (this.isStatus()) {
            console.log('into status')
            if (this.selectedStatus == 'Staying') myQuery = myQuery.where('isAlloted', '==', true);
            else if (this.selectedStatus == 'Discharged') myQuery = myQuery.where('isAlloted', '==', false);
            else if (this.selectedStatus == 'OM SHANTI') myQuery = myQuery.where('status', '==', 'OM SHANTI');
        }
        this.fetchquery2(myQuery)
        // return  myQuery.get().then(qs=>{
        // qs.docs.map(x=>x.data() as FullChild)}
        // )


    }

    dynamicQueryShortVersion() {
        let myQuery: firebase.firestore.Query<FullChild> = this.baseRef.ref;
        const filters: { [key: string]: any } = {
            dateOfAdmission: {from: this.isFrom(), to: this.isTo()},
            centreCode: this.isCentre(),
            gender: this.isGender(),
            state: this.isLocation(),
            Diagnosis: this.isDiagnosis(),
            hospital: this.isHospital(),
            isAlloted: this.isStatus(),
        };

        Object.entries(filters).forEach(([field, conditions]) => {
            if (conditions.from) {
                myQuery = myQuery.where(field, '>=', this.startDate);
                console.log(`into ${field}`);
            }
            if (conditions.to) {
                myQuery = myQuery.where(field, '<=', this.endDate);
                console.log(`into ${field}`);
            }
            if (conditions === true) {
                // @ts-ignore
                myQuery = myQuery.where(field, '==', this[field]);
                console.log(`into ${field}`);
            }
        });

        this.fetchquery2(myQuery);
    }

    startquery(val: any) {
        return (val != null) ? where(val, '==', val) : false
    }

    onSelectStatus($event: MatSelectChange) {
        this.selectedStatus = $event.value
    }

    clearAll() {
        this.startDate = null
        this.endDate = null
        this.selectedStatus = null
        this.selectedHospital = null
        this.selectedDiagnosis = null
        this.selectedLocation = null
        this.selectedGender = null
        this.selectedCentreCode = null
    }

    exportexcel() {
        /* assessment-table id is passed over here */
        let element = document.getElementById('child-assessment-table');

        // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
        // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.childData)
        ws['!cols'] = [];
        // console.log(ws)
        // ws['!cols'][19] = {hidden: true};

        /* O1 is your Column in Excel*/

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, this.fileName);


    }

    exportToExcelUsingExcelJS(data: any[]) {
        let headers = [

            "emergencyContactName1",
            "emergencyContactRelation1",
            "address",
            "childCode",
            "guardians/0/firstName",
            "guardians/0/lastName",
            "guardians/0/contactNo",
            "guardians/1/firstName",
            "guardians/1/lastName",
            "guardians/1/occupation",
            "lastName",
            "centreName",
            "admittedBy",
            "srNo",
            "state",
            "hospital",
            "diagnosisSelected/0/name",
            "socialName",
            "district",
            "admissionData/0/type",
            "admissionData/0/dateOfAdmission",
            "admissionData/1/dateOfDischarge",
            "admissionData/1/type",
            "dateOfAdmission",
            "dob",
            "isAlloted",
            "type",
            "id",
            "fileNo",
            "centreCode",
            "emergencyContactNumber1",
            "city",
            "age",
            "dateOfDischarge",
            "diagnosis",
            "ageAsOnAdmission",
            "gender",
            "REMARKS",
            "firstName",
            "Diagnosis",
            "pincode",
            "aspirations",
            "emergencyContactNumber2",
            "emergencyContactRelation2",
            "emergencyContactName2",
            "hobbies",
            "guardians/0/occupation",
            "middleName",
            "lifeStatus",
            "guardians/1/contactNo",
            "guardians/0/middleName",
            "guardians/1/middleName",
            "status",
            "FolloupCount",
            "siblings/0/gender",
            "siblings/0/Age",
            "siblings/0/firstName",
            "guardians/0/annualIncome",
            "guardians/0/skills",
            "guardians/0/alternateContactNo",
            "guardians/1/skills",
            "bloodGroup",
            "allergies",
            "doctorName",
            "FDischargeCount",
            "admissionData/0/centreName",
            "admissionData/2/dateOfAdmission",
            "admissionData/2/centreName",
            "admissionData/2/type",
            "admissionData/3/type",
            "admissionData/3/dateOfDischarge",
            "admissionData/4/centreName",
            "admissionData/4/type",
            "admissionData/4/dateOfAdmission",
            "admissionData/5/dateOfDischarge",
            "admissionData/5/type",
            "admissionData/6/dateOfAdmission",
            "admissionData/6/type",
            "admissionData/6/centreName",
            "AgeAsOnDate",
            "bag",
            "dataTakenBy",
            "weight",
            "admissionData/0/currentUnit",
            "admissionData/1/currentUnit",
            "admissionData/2/currentUnit",
            "admissionData/3/currentUnit",
            "admissionData/4/currentUnit",
            "admissionData/5/currentUnit",
            "admissionData/6/currentUnit",
            "admissionData/7/dateOfDischarge",
            "admissionData/7/type",
            "admissionData/7/currentUnit",
            "admissionData/8/currentUnit",
            "admissionData/8/dateOfAdmission",
            "admissionData/8/centreName",
            "admissionData/8/type",
            "admissionData/9/currentUnit",
            "admissionData/9/dateOfDischarge",
            "admissionData/9/type",
            "admissionData/10/type",
            "admissionData/10/currentUnit",
            "admissionData/10/dateOfAdmission",
            "admissionData/10/centreName",
            "admissionData/11/dateOfDischarge",
            "admissionData/11/type",
            "admissionData/11/currentUnit",
            "selectUnit",
            "height",
            "siblings/1/firstName",
            "siblings/1/gender",
            "siblings/1/age",
            "admissionData/0/centreCode",
            "admissionData/1/centreName",
            "admissionData/1/centreCode",
            "centreCodeFirst",
            "admissionData/12/dateOfAdmission",
            "admissionData/12/centreName",
            "admissionData/12/currentUnit",
            "admissionData/12/type",
            "admissionData/13/dateOfDischarge",
            "admissionData/13/type",
            "admissionData/13/currentUnit",
            "siblings/0/age",
            "reason",
            "followUpDate/seconds",
            "followUpDate/nanoseconds",
            "admissionData/14/dateOfAdmission",
            "admissionData/14/centreName",
            "admissionData/14/type",
            "admissionData/14/currentUnit",
            "admissionData/15/type",
            "admissionData/15/currentUnit",
            "admissionData/15/dateOfDischarge",
            "admissionData/16/type",
            "admissionData/16/currentUnit",
            "admissionData/16/dateOfAdmission",
            "admissionData/16/centreName",
            "admissionData/17/type",
            "admissionData/17/dateOfDischarge",
            "admissionData/17/currentUnit",
            "admissionData/18/dateOfAdmission",
            "admissionData/18/centreName",
            "admissionData/18/currentUnit",
            "admissionData/18/type",
            "admissionData/19/type",
            "admissionData/19/currentUnit",
            "admissionData/19/dateOfDischarge",
            "admissionData/20/centreName",
            "admissionData/20/dateOfAdmission",
            "admissionData/20/type",
            "admissionData/20/currentUnit",
            "admissionData/20/centreCode",
            "admissionData/21/currentUnit",
            "admissionData/21/dateOfDischarge",
            "admissionData/21/weight",
            "admissionData/21/height",
            "admissionData/21/type",
            "admissionData/21/centreCode",
            "admissionData/22/dateOfAdmission",
            "admissionData/22/tentativeDischargeDate",
            "admissionData/22/centreCode",
            "admissionData/22/currentUnit",
            "admissionData/22/type",
            "admissionData/22/weight",
            "admissionData/22/height",
            "admissionData/23/dateOfDischarge",
            "admissionData/23/centreCode",
            "admissionData/23/height",
            "admissionData/23/type",
            "admissionData/23/currentUnit",
            "admissionData/23/weight",
            "dischargeDate",
            "remark",
            "admissionData/16/cetreCode",
            "guardians/1/alternateContactNo",
            "admissionData/8/centreCode",
            "siblings/2/age",
            "siblings/2/gender",
            "siblings/2/firstName",
            "admissionData/20/weight",
            "admissionData/20/tentativeDischargeDate",
            "admissionData/20/height",
            "admissionData/21/dateOfAdmission",
            "admissionData/22/dateOfDischarge",
            "childAadhaar",
            "guardianStayStatus",
            "medical",
            "allergiesSelected",
            "stayedBefore",
            "days",
            "diagnosisSelected/0",
            "country",
            "months",
            "treatment",
            "guardians/0/otherDocuments",
            "guardians/0/relation",
            "guardians/1/annualIncome",
            "guardians/1/relation",
            "guardians/1/otherDocuments",
            "mobile",
            "prescribed",
            "siblings/3/firstName",
            "siblings/3/gender",
            "siblings/3/age",
            "admissionData/14/height",
            "admissionData/14/weight",
            "admissionData/14/centreCode",
            "admissionData/14/tentativeDischargeDate",
            "admissionData/15/weight",
            "admissionData/15/centreCode",
            "admissionData/15/height",
            "admissionData/16/tentativeDischargeDate",
            "admissionData/16/height",
            "admissionData/16/centreCode",
            "admissionData/16/weight",
            "admissionData/17/height",
            "admissionData/17/centreCode",
            "admissionData/17/weight",
            "admissionData/12/centreCode",
            "admissionData/12/tentativeDischargeDate",
            "admissionData/12/weight",
            "admissionData/12/height",
            "admissionData/13/centreCode",
            "admissionData/13/height",
            "admissionData/13/weight",
            "centreCodeOld",
            "admissionData/6/centreCode",
            "admissionData/10/weight",
            "admissionData/10/tentativeDischargeDate",
            "admissionData/10/height",
            "admissionData/10/centreCode",
            "admissionData/11/height",
            "admissionData/11/centreCode",
            "admissionData/11/weight",
            "events",
            "admissionData/6/weight",
            "admissionData/6/height",
            "admissionData/6/tentativeDischargeDate",
            "admissionData/7/centreCode",
            "admissionData/7/weight",
            "admissionData/7/height",
            "admissionData/8/height",
            "admissionData/8/tentativeDischargeDate",
            "admissionData/8/weight",
            "admissionData/9/centreCode",
            "admissionData/9/weight",
            "admissionData/9/height",
            "admissionData/4/weight",
            "admissionData/4/tentativeDischargeDate",
            "admissionData/4/height",
            "admissionData/4/centreCode",
            "admissionData/5/height",
            "admissionData/5/weight",
            "admissionData/5/centreCode",
            "admissionData/5/centreName",
            "admissionData/1/weight",
            "admissionData/1/height",
            "FollowUpCount",
            "admissionData/2/centreCode",
            "admissionData/3/centreName",
            "admissionData/3/centreCode",
            "admissionData/18/weight",
            "admissionData/18/centreCode",
            "admissionData/18/tentativeDischargeDate",
            "admissionData/18/height",
            "admissionData/19/weight",
            "admissionData/19/height",
            "admissionData/19/centreCode",
            "admissionData/2/weight",
            "admissionData/2/tentativeDischargeDate",
            "admissionData/2/height",
            "admissionData/3/weight",
            "admissionData/3/height",
            "siblings/0/mobile",
            "currentUnit",
            "siblings/1/mobile",
            "createdAt/seconds",
            "createdAt/nanoseconds",
            "siblings/2/mobile",
            "guardians/0/childCode",
            "guardians/1/childCode",
            "chlidCode",
            "documents",
            "baseCentreName",
            "baseCentreCode"

        ]
        // console.assessment-table(data)
        data.forEach((row: any, index) => {

            for (let x1 of data) {
                let x2 = Object.keys(x1);
                let temp = []
                for (let y of x2) {
                    // @ts-ignore
                    temp.push(x1[y])
                }
                // worksheet.addRow(temp)
                this.dataForExcel.push(Object.values(temp))
            }

            // this.dataForExcel.push(Object.values(row))
        })

        let reportData = {
            title: 'Child Admission Report - Jan 2020',
            data: this.dataForExcel,
            headers: headers
        }

        this.exportExcelService.exportExcel(reportData);
    }

    saveAsExcelFile(data: any[]) {
        const fileName = this.fileName
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        this.saveBuffer(excelBuffer, fileName);
        let activity = {
            date: new Date().getTime(),
            section: 'Child Report',
            action: 'Download',

            description: `Excel sheet downloaded by`,
            currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
    }

    // saveAsExcelFile() {
    //     const fileName = this.fileName
    //     const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.childData);
    //     const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    //     const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    //     this.saveBuffer(excelBuffer, fileName);
    //
    // }

    private saveBuffer(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {type: 'application/octet-stream'});
        saveAs(data, fileName + '.xlsx');
    }

    saveComplexObject(): void {
        let data: any[] = this.childData as any[]
        // console.log('initial data',data)

        data.forEach(x => {
            // console.log( x.createdAt)
            // console.log( x.dateOfAdmission)
            // console.log( x.dateOfDischarge)
            // console.log( x.dob)

            if (typeof x.createdAt != undefined) {
                // console.log('into createdAt')
                try {
                    x.createdAt = this.getFormattedDate(x.child.code, x.createdAt, 'createdAt')

                } catch (e) {
                    // console.log(x.id,e)
                }
                // console.log('createdAt ', x.createdAt)
            }
            if (x.dob != undefined) {
                // console.log('into dob')
                // console.log('into admission')
                try {
                    x.dob = this.getFormattedDate(x.childCode, x.dob, 'dob')

                } catch (e) {
                    // console.log(x.id,e)
                }
                // console.log('dob ', x.dob)
            }

            if (x.dateOfDischarge != undefined) {
                // console.log('into admission')
                try {
                    x.dateOfDischarge = this.getFormattedDate(x.childCode, x.dateOfDischarge, 'dateOfDischarge')

                } catch (e) {
                    // console.log(x.id,e)
                }
                // console.log('dateOfAdmission ', x.dateOfAdmission)
            }
            if (x.dateOfAdmission != undefined) {
                // console.log('into admission')
                try {
                    x.dateOfAdmission = this.getFormattedDate(x.childCode, x.dateOfAdmission, 'dateofAdmission')

                } catch (e) {
                    // console.log(x.id,e)
                }
                // console.log('dateOfAdmission ', x.dateOfAdmission)
            }

            if (x.dischargeDate != undefined) {
                // console.log('into admission')
                try {
                    x.dischargeDate = this.getFormattedDate(x.childCode, x.dischargeDate, 'dischargeDate')

                } catch (e) {
                    // console.log(x.id,e)
                }
                // console.log('dateOfAdmission ', x.dateOfAdmission)
            }
            //@ts-ignore
            x.admissionData.map(y => {
                if (y.dateOfAdmission != undefined) y.dateOfAdmission = this.getFormattedDate(y.childCode, y.dateOfAdmission, 'AdmissionData>dateOfAdmission')
                if (y.dateOfDischarge != undefined) y.dateOfDischarge = this.getFormattedDate(y.childCode, y.dateOfDischarge, 'AdmissionData>dateOfDischarge')
                if (y.tentativeDischargeDate != undefined) y.tentativeDischargeDate = this.getFormattedDate(y.childCode, y.tentativeDischargeDate, 'AdmissionData>tentativeDateOfDischarge')
            })
            x.admissionData = flattenObject(x.admissionData)
            x.guardians = flattenObject(x.guardians)
            x.siblings = flattenObject(x.siblings)
            x.diagnosisSelected = flattenObject(x.diagnosisSelected)
            x.admissionDocuments = null
            x.dischargeDocuments = null
            x.followUpDischargeDocuments = null
            x.followUpAdmissionDocuments = null
            x.sickness = null
            // x=flattenObject(x)
            // x.guardian=flattenObject(x.guardian)

        })
        console.log('DATA==>', data)
        let ydata = data.map(x => flattenObject(x))
        // flattenObject(this.childData[this.childData.length - 1])
        console.log('flatten data', data)
        //
        // convertToCSV(data)

// const csv = convertToCSV(data);
// downloadCSV(csv, 'data.csv');
        this.flatData = ydata
        // this.show=true
        this.changeObjectBeforeExport(ydata)
//        this.exportToExcelUsingExcelJS(ydata);
    }

    private changeObjectBeforeExport(ydata: {}[]) {
        console.log(ydata[0])
        let data = ydata as unknown as any[]
        const FormattedJSON: any = data.map(x => ({
                "Adission Type": x.status,
                "Child Code": x.childCode,
                "Child Aadhar No": x.childAadhaar,
                // "Admission Centre": x['admissionData/0/centreCode'],
                "First Name": x.firstName,
                "Middle Name": x.middleName,
                "Last Name": x.lastName,
                "DOB": x.dob,
                "Blood Group": x.bloodGroup,
                "Gender": x.gender,
                // "Date Of Admission":x.admissionData.0.dateOfadmission,
                "Age as On Admission": "FUNCTION PENDING",
                "Address": x.address,
                "City": x.city,
                "District": x.district,
                "Pincode": x.pincode,
                "State": x.state,

                'Age as On Date': x.age,
                'Height at the Time Of Admission': x.height,
                'Weight at the Time Of Admission': x.weight,
                'Diagnosis': x.diagnosisSelected,
                "Prescribed": x.prescribed,
                "Hospital Name": x.hospital,
                "File No": x.fileNo,
                "Social Worker Name": x.socialName,
                "Dr's Name": x.doctorName,
                "Allergies": x.allergies,
                "Hobbies": x.hobbies,
                "Aspiration": x.aspirations,
                "Transfer from Center ": '',
                "Transfer Date": '',
                'Child Status Type': '',
                'Last Discharge Date': x.dateOfDischarge,

                'Followup In Date 1': x['admissionData/0/dateOfAdmission'],
                'Followup In Centre 1': x['admissionData/0/centreCode'],
            "Unit":x['admissionData/1/currentUnit'],
            "Floor":'',
                'Followup Out Date 1': x['admissionData/1/dateOfDischarge'],

                'Followup In Date 2': x['admissionData/2/dateOfAdmission'],
                'Followup In Centre 2': x['admissionData/2/centreCode'],
            "Unit 2":x['admissionData/2/currentUnit'],
            "Floor 2":'',
                'Followup Out Date 2': x['admissionData/3/dateOfDischarge'],

                'Followup In Date 3': x['admissionData/4/dateOfAdmission'],
                'Followup In Centre 3': x['admissionData/4/centreCode'],
            "Unit 3":x['admissionData/4/currentUnit'],
            "Floor 3":'',
                'Followup Out Date 3': x['admissionData/5/dateOfDischarge'],


                'Followup In Date 4': x['admissionData/6/dateOfAdmission'],
                'Followup In Centre 4': x['admissionData/6/centreCode'],
            "Unit 4":x['admissionData/6/currentUnit'],
            "Floor 4":'',
                'Followup Out Date 4': x['admissionData/7/dateOfDischarge'],


                'Followup In Date 5': x['admissionData/8/dateOfAdmission'],
                'Followup In Centre 5': x['admissionData/8/centreCode'],
                "Unit 5":x['admissionData/8/currentUnit'],
                "Floor 5":'',
                'Followup Out Date 5': x['admissionData/9/dateOfDischarge'],


                'Followup In Date 6': x['admissionData/10/dateOfAdmission'],
                'Followup In Centre 6': x['admissionData/10/centreCode'],
                "Unit 6":x['admissionData/10/currentUnit'],
                "Floor 6":'',
                'Followup Out Date 6': x['admissionData/11/dateOfDischarge'],


                'Followup In Date 7': x['admissionData/12/dateOfAdmission'],
                'Followup In Centre 7': x['admissionData/12/centreCode'],
            "Unit 7":x['admissionData/12/currentUnit'],
            "Floor 7":'',
                'Followup Out Date 7': x['admissionData/13/dateOfDischarge'],
                'Followup In Date 8': x['admissionData/14/dateOfAdmission'],
                'Followup In Centre 8': x['admissionData/14/centreCode'],
            "Unit 8":x['admissionData/15/currentUnit'],
            "Floor 8":'',
                'Followup Out Date 8': x['admissionData/15/dateOfDischarge'],


                'Followup In Date 9': x['admissionData/16/dateOfAdmission'],
                'Followup In Centre 9': x['admissionData/16/centreCode'],
            "Unit 9":x['admissionData/16/currentUnit'],
            "Floor 9":'',
                'Followup Out Date 9': x['admissionData/17/dateOfDischarge'],

                'Followup In Date 10': x['admissionData/18/dateOfAdmission'],
                'Followup In Centre 10': x['admissionData/18/centreCode'],
            "Unit 10":x['admissionData/18/currentUnit'],
            "Floor 10":'',
                'Followup Out Date 10': x['admissionData/19/dateOfDischarge'],

                'Followup In Date 11': x['admissionData/20/dateOfAdmission'],
                'Followup In Centre 11': x['admissionData/20/centreCode'],

            "Unit 11":x['admissionData/201/currentUnit'],
            "Floor 11":'',
                'Followup Out Date 11': x['admissionData/21/dateOfDischarge'],

                'Followup In Date 12': x['admissionData/22/dateOfAdmission'],
                'Followup In Centre 12': x['admissionData/22/centreCode'],
            "Unit 12":x['admissionData/22/currentUnit'],
            "Floor 12":'',
                'Followup Out Date 12': x['admissionData/23/dateOfDischarge'],

                'Followup In Date 13': x['admissionData/24/dateOfAdmission'],
                'Followup In Centre 13': x['admissionData/24/centreCode'],
            "Unit 13":x['admissionData/24/currentUnit'],
            "Floor 13":'',
                'Followup Out Date 13': x['admissionData/25/dateOfDischarge'],
            }),
        )
        this.saveAsExcelFile(FormattedJSON)

    }
}


// @ts-ignore
function flattenObject(obj, prefix = '') {
    const flattened = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const propKey = prefix ? `${prefix}/${key}` : key;
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                Object.assign(flattened, flattenObject(obj[key], propKey));
            } else {
                // @ts-ignore
                flattened[propKey] = obj[key];
            }
        }
    }
    // console.log(flattened)
    return flattened;
}

//CSV

// @ts-ignore
function convertToCSV(obj) {
    const header = Object.keys(obj).join(',');
    const values = Object.values(obj).map(value => {
        if (typeof value === 'object' && !Array.isArray(value)) {
            return JSON.stringify(value);
        }
        return value;
    });
    const row = values.join(',');
    return `${header}\n${row}`;
}

function CalculateAge(dob: Date) {
    let timeDiff = Math.abs(Date.now() - dob.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    console.log(age)

}

// @ts-ignore
function downloadCSV(csv, filename) {
    const csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', filename);
    tempLink.click();
}

const obj = {
    // Your object goes here
};

const csv = convertToCSV(obj);

// downloadCSV(csv, 'data.csv');

async function processWorkbook(book: Workbook): Promise<void> {
    // @ts-ignore
    for await (const sheet of book) {
        const headers: string[] = [];

        for await (const row of sheet) {
            const item: Record<string, any> = {};

            if (row.model.number === 1) {
                // @ts-ignore

                row.eachCell({includeEmpty: true}, (c, i) => {
                    if (i) {
                        headers.push(c.value as string);
                    }
                });
            }

            if (row.model.number > 1) {
                const rowValues = row.values;
                headers.forEach((header, i) => {
                    item[header] = rowValues[i + 1];
                });
            }

            console.log(headers, item);
        }
    }
}
