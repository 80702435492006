import { Component, OnInit } from '@angular/core';
import * as importData from '../../assets/json/occupancy.json'
import {FullChild} from "../interfaces/FullChild";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.scss']
})
export class BulkUploadComponent implements OnInit {
data:any[]=[]
  constructor(private readonly  mFirestore:AngularFirestore) {
    this.data = importData as  any[]
  }

  ngOnInit(): void {
   this.data = importData as any[]
    // this.data.forEach(c=>{
    //   c.admissionData.forEach((v: { dateOfAdmission: string | number | Date; dateOfDischarge: string | number | Date | Timestamp; }, i: number)=>{
    //     if(i%2==0){
    //       //ITS AN ADMISSION
    //       // const dateOfAdmission = Date.parse(v.dateOfAdmission);
    //       // @ts-ignore
    //       let d = new Date(v.dateOfAdmission!)
    //       v.dateOfAdmission=  d
    //     }
    //     else if(i%2!=0){
    //       //ITS AN DISCHARGE
    //       // @ts-ignore
    //       let d = new Date(v.dateOfDischarge!)
    //       v.dateOfDischarge=  Timestamp.fromDate(d)
    //     }
    //   })
    // })


}
upload(){

  this.data.forEach(it=>{
    console.log(it)
    this.mFirestore.collection('test-ranking').doc(it.centreCode).set(it).then(res=>{
      console.log('uploaded',res)
    }).catch(reason => {
      console.log(reason)
    })

  })
}
}
