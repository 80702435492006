import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-childdocument',
  templateUrl: './childdocument.component.html',
  styleUrls: ['./childdocument.component.scss']
})
export class ChilddocumentComponent implements OnInit {
  files: any;

  constructor() { }

  ngOnInit(): void {
  }

  uploadFile(event:Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      this.files.push(element.name)
    }
  }
  deleteAttachment(files:any) {
    this.files.splice(files, 1)
  }
}
