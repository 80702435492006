<table class="table table-active">
 <tbody>
 <tr>
   <td>
<a class="text-dark mx-5" href="{{ fileUpload.imageUrl }}">{{ fileUpload?.fileName }}</a>
<img  [src]="fileUpload.imageUrl" class="img-thumbnail" alt="upload_image"  style="width: 20%" alt="">
   </td>
 </tr>

 </tbody>
</table>
<button (click)="deleteFileUpload(fileUpload)"
        class="btn btn-danger btn-sm" style="float: right">
  Delete
</button>
