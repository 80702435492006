<div class="container">
  <div class="row">
    <!--------------------------------------------- payments --------------------------------------------->

    <div class="ngxTableHeader">
      <ul class="header-buttons-left ms-0 mb-0">
        <li class="dropdown">
          <h2>Mealmate Pending Payments</h2>
        </li>
      </ul>
    </div>

    <ng-container *ngFor="let r of reminders">
        <div class="col-md-6 col-sm-12" *ngIf="r.paymentMethod =='Pending'">
        <mat-card class="mt-3">
          <mat-card-header>
            <mat-card-title>Donor Name : {{r.donated_by}} on {{r.eventDate}}</mat-card-title>
            <mat-card-subtitle>{{r.eventName}}  Center: {{r.centre}}  </mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="container" >

            <div class="row">
              <!--------------------------------------------- Contact Number --------------------------------------------->
              <div class="col-md-6">Mobile</div>
              <div class="col-md-6">{{r.mobile}}</div>
              <!--------------------------------------------- Email --------------------------------------------->
              <div class="col-md-6">Email</div>
              <div class="col-md-6">{{r.email}}</div>
              <!--------------------------------------------- EVENT NAME --------------------------------------------->
              <div class="col-md-6">Type</div>
              <div class="col-md-6">{{r.mealSlot}}</div>
              <!--------------------------------------------- DONOR --------------------------------------------->
              <div class="col-md-6">Donor Name</div>
              <div class="col-md-6">{{r.donated_by}}</div>
              <!---------------------------------------------Payment Method  --------------------------------------------->
              <div class="col-md-6">Payment Method</div>
              <div class="col-md-6">{{r.paymentMethod}}</div>
              <!--------------------------------------------- followupOn --------------------------------------------->
              <div class="col-md-6">FollowUp On</div>
              <div class="col-md-6">{{r.followUpDate|date}}</div>


              <button mat-raised-button color="primary" class="mt-2" (click)="updatePayment(r)">Update </button>

            </div>
          </mat-card-content>
        </mat-card>
        </div>



      </ng-container>

    </div>
    <div class="col-md-6"></div>


</div>
