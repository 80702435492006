<div class="container">
    <h1 class='heading'>Add Treatment Records</h1>
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Upload Image</mat-label>
                <input matInput type="file" (change)="onFileSelected($event)">
            </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" required>
        </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" required></textarea>
        </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-label>Height (in cm)</mat-label>
            <input matInput formControlName="height" type="number" required>
        </mat-form-field>

        <mat-form-field appearance="outline" >
            <mat-label>Weight (in kg)</mat-label>
            <input matInput formControlName="weight" type="number" required>
        </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Next OPD date</mat-label>
            <input matInput formControlName="next_opd_date" [matDatepicker]="picker">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </form>


</div>
