
    <div class="card">
        <div class="header">
            <h2>Recent Unique Admissions</h2>

        </div>
        <div class="tableBody">
            <div class="table-responsive">
                <table class="table table-hover dashboard-task-infos">
                    <thead>
                    <tr>
                        <th>Pic</th>
                        <th>Name</th>
<!--                        <th>Centre</th>-->
                        <th>Unit</th>
                        <th>Date Of Admission</th>

                    </tr>
                    </thead>
                    <tbody>

                   <ng-container *ngFor="let child of recentAdmissions">
                       <tr>
                           <td class="table-img">

                               <img *ngIf="child.admissionDocuments" [src]="child.admissionDocuments[0]?.fileUrl"  [alt]="child.childCode">

                            </td>
                           <td [matTooltip]="child.childCode"> {{child.firstName}} {{child.lastName}} </td>
<!--                           <td>{{child.centreName}}</td>-->
                           <td><span class="label bg-green shadow-style">{{child.admissionData[0].currentUnit}}</span></td>
                           <td>{{child.dateOfAdmission?.toDate()|date:'dd/MM/yyyy'}}</td>
                       </tr>

                   </ng-container>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
