import {Component, isDevMode, OnInit, ViewChild} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MealEvent} from "../add-meal-event/add-meal-event.component";
import {EventLogic} from "../meal-mate.service";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {environment} from "../../../../environments/environment";
import {el} from "@fullcalendar/core/internal-common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EditMealEventComponent} from "../edit-meal-event/edit-meal-event.component";
import {MatDialog} from "@angular/material/dialog";
import {SendPhotoEmailComponent} from "../send-photo-email/send-photo-email.component";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {ViewMealEventComponent} from "../view-meal-event/view-meal-event.component";

@Component({
    selector: 'app-meal-mate-event-assessment-table',
    templateUrl: './meal-mate-event-table.component.html',
    styleUrls: ['./meal-mate-event-table.component.scss']
})
export class MealMateEventTableComponent implements OnInit {
    displayedColumns: string[] = ['donated_by', 'city', 'mealSlot',
        // 'Address',
       'pan', 'centreCode', 'start', 'payment_date', 'booking_id', 'otg_certificate','recieptNo', 'Action'];
    dataSource: MatTableDataSource<MealEvent>
    selectedCentre: any | null
    mealSlotSelected: any
    startDate?: Date | null
    endDate?: Date | null
    centres: Centre[] = []
    mAllData: MealEvent[] = []
    mFilteredData: MealEvent[] = []
    env = isDevMode() ? environment.testMode : environment.productionMode
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  mealMatePermissions=this.permissions.filter((x)=>x.functionName=='Meal Mate')

  @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(private readonly mDatabase: AngularFireDatabase,
                private readonly  _matSnackBar:MatSnackBar,
                private  readonly dialog:MatDialog,

                private readonly centreService: CentreService) {
        this.dataSource = new MatTableDataSource<MealEvent>()
    }

    ngOnInit(): void {
        this.mDatabase.list<MealEvent>(this.env.mealmatesEvents).valueChanges().subscribe(res => {
            this.mAllData = res
            this.mFilteredData = this.mAllData
            this.dataSource.data = this.mFilteredData
            this.dataSource.sort = this.sort
            this.dataSource.paginator = this.paginator
        })
        this.fetchCentres()
    }

  @needConfirmation()
    delete(element: MealEvent) {
    let sl=  this.getSlotId(element)
this.mDatabase.object(this.env.mealmatesEvents+element.id).remove().then(
    ()=>{

        this.mDatabase.object(this.env.mealmatesCounter+element.centreCode+'/'+element.start+'/'+sl).set(false).then(()=>{
            this._matSnackBar.open('Deleted Successfully')._dismissAfter(3000)
        })
    }
)
    }

    private fetchCentres() {
        this.centreService.getAllCentresForMealmate().pipe().subscribe(centres => {
            this.centres = centres
            let c = {centreCode: 'All', centreName: "All Centres", totalUnits: 99, key$: 'All'} as unknown as Centre
            this.centres.push(c)

            this.centres.push({
              mealMate: false, unitSponsorship: false,
              centreCode: 'GurunanakB',
                centreName: 'AL Gurunanak B',
                totalUnits: 99,
                $key: 'GurunanakB'
            })
        })

    }

    Search() {

        this.mFilteredData = this.mAllData
        console.log('Start Date', this.startDate?.toLocaleDateString())
        console.log('End Date', this.endDate?.toLocaleDateString())

        if (this.selectedCentre != null) {
            console.log('into selected centre', this.selectedCentre)
            this.mFilteredData = this.mFilteredData.filter(x=> x.centreCode == this.selectedCentre)

        }
        if (this.mealSlotSelected != null) {
            this.mFilteredData = this.mFilteredData.filter(x => x.mealSlot == this.mealSlotSelected)
        }

        function FormatDate(b: string) {
            let c = b.replace('/', '-')

            c = c.replace('/', '-')
            console.log('ff',c)
            return c
        }

        if (this.startDate !== null && this.endDate != null) {
            this.mFilteredData = this.mFilteredData.filter(x => x.start >this.startDate!.toISOString().replace(/T.*$/, '')&& x.start <= this.endDate!.toISOString().replace(/T.*$/, ''))
        }
        console.log(this.mFilteredData)
        this.dataSource.data = this.mFilteredData
    }

    notManipalOrPune(c: Centre) {
        return (c.centreName == "Pune CM" || c.centreName == 'Manipal IT' || c.centreName == 'ALManipal' || c.centreName == 'ALPune')
    }

    private getSlotId(element: MealEvent) {
    let bookingFor =''
        switch (element.mealSlot) {
            case "Lunch for child": {
                bookingFor = 'lunchForChild';
                break;
            }
            case "Lunch for family": {
                bookingFor = 'lunchForFamily';
                break;
            }
            case "Lunch for child and family": {
                bookingFor = 'lunchForChildAndFamily';
                break;
            }
            case "Dinner for child": {
                bookingFor = 'dinnerForChild';
                break;
            }
            case "Dinner for family": {
                bookingFor = 'dinnerForFamily';
                break;
            }
            case "Dinner for child and family": {
                bookingFor = 'dinnerForChildAndFamily';
                break;
            }
            case "Lunch and dinner for child": {
                bookingFor = 'lunchAndDinnerForChild';
                break;
            }
            case "Lunch and dinner for family": {
                bookingFor = 'lunchAndDinnerForFamily';
                break;
            }
            case "Lunch and dinner for child and family": {
                bookingFor = 'lunchAndDinnerForChildAndFamily';
                break;
            }
        }
    return bookingFor
    }

    viewEdit(element:MealEvent) {


            this.dialog.open(EditMealEventComponent,{data:element,
                minWidth:'600px',
                autoFocus:false})

    }

    showEmailDialog(element:MealEvent) {
        this.dialog.open(SendPhotoEmailComponent,{data:element,
            minWidth:'600px',
            autoFocus:false})
    }

  viewDialog(element:any) {
    this.dialog.open(ViewMealEventComponent,{data:element,
      minWidth:'600px',
      autoFocus:false})

  }
}
