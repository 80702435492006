import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-car-fuel-details',
  templateUrl: './all-car-fuel-details.component.html',
  styleUrls: ['./all-car-fuel-details.component.scss']
})
export class AllCarFuelDetailsComponent implements OnInit {
  displayedColumns: string[] = ['date', 'fuels', 'kilometers',];
  dataSource: any[] = []
  constructor() { }

  ngOnInit(): void {
  }

}
