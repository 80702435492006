
<div class='container'>
<div class="d-flex justify-content-between mt-4 ms-5">
  <div>
    <mat-form-field appearance="outline" class=" ">
            <mat-label>Select Centre</mat-label>
            <mat-select matInput  placeholder="" (selectionChange)="centreSelection($event)">
            <mat-option *ngFor="let c of centres" [value]="c" [disabled]="notManipalOrPune(c)" >{{c.centreName}}</mat-option>
<!--            <mat-option  value="All">All Centres</mat-option>-->
            </mat-select>
          </mat-form-field>
  </div>
    <div>
        <button *ngIf="mealmatePermissions.all ||mealmatePermissions.add||mealmatePermissions.view" mat-stroked-button color="primary" routerLink="allevents" >Meals Table</button>
    </div>

</div>
<ng-container *ngIf="isCentreSelected" >
<!--  <div class="text-center rounded container"><span>Selected Centre: </span> <b>{{centreSelected?.centreName}}</b> <span>Number of children: </span><b>{{centreSelected?.occupiedUnits}}</b></div>-->
</ng-container>


    <div class='demo-app-main' #main>
      <full-calendar #calendar  *ngIf='calendarVisible' [options]='calendarOptions'
          >
      </full-calendar>
    </div>

  </div>
