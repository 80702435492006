import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-center',
  templateUrl: './delete-center.component.html',
  styleUrls: ['./delete-center.component.scss']
})
export class DeleteCenterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
