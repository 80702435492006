import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-view-online-donors',
  templateUrl: './view-online-donors.component.html',
  styleUrls: ['./view-online-donors.component.scss']
})
export class ViewOnlineDonorsComponent implements OnInit {
donors:any
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)
  }

  ngOnInit(): void {
    this.donors = this.data

  }

}
