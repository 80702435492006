import {Injectable, isDevMode} from "@angular/core";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {Hospitals} from "../interfaces/Hospitals";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})

export class HospitalService {
  env= isDevMode()? environment.testMode :environment.productionMode

  constructor(private mDatabase: AngularFireDatabase,
  private mFirestore:AngularFireDatabase) {

  }

  addHospital(hospital: any) {
    // const ref  = this.mDatabase.list('dummy/hospitals')
   return  this.mDatabase.list(this.env.hospitals).push(hospital)
  }
  updatehospital(key:String,hospital: Hospitals) {

    return  this.mDatabase.object(`${this.env.hospitals}/${key}`).update(hospital)
  }

  getAllHosptitals() {
    return this.mDatabase.list(this.env.hospitals).snapshotChanges()
  }

  getHospitalList(): Observable<Hospitals[]> {
    const centreRef = this.mDatabase.list<Hospitals>(this.env.hospitals);
    return centreRef.valueChanges().pipe(
      map(hospitals => hospitals.sort((a, b) => a.hospitalName.localeCompare(b.hospitalName)))
    );
  }
  deleteHospital(id:string) {
   return  this.mDatabase.object(this.env.hospitals+'/'+id).remove()
  }
}
