import {FullChild} from "../../../interfaces/FullChild";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
import {AngularFirestore, AngularFirestoreDocument} from "@angular/fire/compat/firestore";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UnitService} from "../../../services/unit.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatSelectChange} from "@angular/material/select";
import {Units} from "../../../interfaces/Units";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {FileUploadComponent} from "../../../upload/file-upload/file-upload.component";
import {FileUploadDialogComponent} from "../../../upload/file-upload-dialog/file-upload-dialog.component";
import {UploadFile} from "../../../interfaces/uploadFile";
import {AngularFireStorage, AngularFireStorageReference} from "@angular/fire/compat/storage";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {CentreService} from "../../../services/centre.service";
import { HttpClient } from '@angular/common/http';
import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ViewImageComponent} from "../../child/view-registered-child/view-image/view-image.component";
@Component({
  selector: 'app-view-follow-up-child-details',
  templateUrl: './view-follow-up-child-details.component.html',
  styleUrls: ['./view-follow-up-child-details.component.scss']
})
export class ViewFollowUpChildDetailsComponent implements OnInit{
  dateFormatted: any
  centreName$?: Observable<Centre | undefined>
  dischargeForm!: FormGroup
  isSelectedCentre = false
  selectedCenter: Centre | undefined | null
  units$!: Observable<Units[]>
  admissionForm!: FormGroup
  fb: FormBuilder
  showDischarge = true
  centres$: Observable<Centre[]>
  profileUrl?:string
  child$: Observable<FullChild | undefined>
  // admissionDocuments$:Observable<UploadFile>
  // admissionDocuments$:Observable<UploadFile>
childData!:FullChild
  constructor(@Inject(MAT_DIALOG_DATA) public child: FullChild,
              private mFirestore: AngularFirestore,
              private mDatabase: AngularFireDatabase,
              private fbApi: FormBuilder,
              private centerService: CentreService,
              private mStorage: AngularFireStorage,
              private unitService: UnitService,
              private _snackBar: MatSnackBar,
              private readonly matDialog: MatDialog,
              private http:HttpClient,
              private _dialog: MatDialogRef<ViewFollowUpChildDetailsComponent>
  ) {
    // console.assessment-table(child)
    // this.dob = child.dob
    // this.dob =(this.dob).toDate()
    //Need to change
    //Need to change
    // this.dob = (child.dob as Timestamp)
    this.fb = fbApi
    this.centres$ = this.centerService.getSortedCenterList()
    // mFirestore.collection<Centre>('centres', ref1 => ref1.orderBy("sr", 'desc')).valueChanges()
    this.child$ = this.mFirestore.collection<FullChild>(this.centerService.env.child).doc<FullChild>(this.child.childCode).valueChanges()

  }
  get getCentre() {
    return this.admissionForm.get('selectCentre');
  }

  ngOnInit(): void {
    this.child$.subscribe(res=>{
      this.childData =res!
      res?.admissionDocuments?.forEach(it=>{
        if(it.description=="Child Photo") this.profileUrl = it.fileUrl
        this.dateFormatted = (this.childData.dob as unknown as Timestamp).toDate()

      })
    })
    this.centreName$ = this.mFirestore.collection<Centre>(this.centerService.env.centres).doc(this.child.centreCode).valueChanges()
    this.showDischarge = this.child.isAlloted
    if (this.showDischarge) this.initDischargeForm()
    if (!this.showDischarge) this.initAdmissionForm()
  }

  showDischargeToast() {
    this._snackBar.open('Child Discharged Successfully', '', {duration: 4000})
  }

  showAdmissionToast() {
    this._snackBar.open('Child Admitted Successfully', '', {duration: 4000})
  }

  initDischargeForm() {
    this.dischargeForm = this.fb.group({
      dischargeDate: new FormControl('', Validators.required),
      followUpDate: new FormControl(null),
      reason: new FormControl('', Validators.required),
      remark: new FormControl('', Validators.required),
      height: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
      weight: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
    })
  }

  initAdmissionForm() {
    this.admissionForm = this.fb.group({
      dateOfAdmission: new FormControl('', Validators.required),
      tentativeDischargeDate: new FormControl('', Validators.required),
      selectCentre: new FormControl(''),
      selectUnit: new FormControl(''),
      height: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),

      weight: new FormControl('', [Validators.required, Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
      //  reason: new FormControl('', Validators.required),
      remark: new FormControl('', Validators.required),
    })
  }

  dischareChild() {
    //  console.assessment-table(this.dischargeForm.value)
    if (this.dischargeForm.valid) {
      //  this.showDischarge = false
      console.log('discharge Reminder', this.dischargeForm.get('followUpDate')?.value)
      this.unitService.dischargeProcess(this.dischargeForm.value, this.child, this.dischargeForm.get('followUpDate')?.value != null).then(() => {
        this.showDischargeToast()
        this._dialog.close()
      })

      // console.assessment-table(this.child)
    }
  }

  reAdmitChild() {
    // console.assessment-table(this.admissionForm.value)
    // if (this.admissionForm.valid) {
    //   this.showDischarge = false
    this.unitService.reAdmissionProcess(this.selectedCenter, this.admissionForm!.get('selectUnit')?.value, this.child.childCode, this.admissionForm.value, this.child)
        .then(() => {
          this.showAdmissionToast()
          this._dialog.close()
        })
  }

  fetchUnits($event: MatSelectChange) {
    this.isSelectedCentre = true
    console.log('fetchUnits', $event.value)
    // this.selectedCenter  = this.allCentres.at()
    this.selectedCenter = $event.value
    this.units$ = this.mDatabase.list<Units>(`${this.centerService.env.units}/${this.selectedCenter?.centreCode}`, ref => ref.orderByChild('order')).valueChanges()
    console.log('selected', $event.value)
// this.childForm.setControl()
    this.admissionForm.addControl('centreCode', new FormControl(this.selectedCenter?.centreCode),)
    this.admissionForm.addControl('centreName', new FormControl(this.selectedCenter?.centreName),)
    // this.childForm.setControl( 'centreCode', this.selectedCenter?.centreCode)
  }

  UploadAdmissionDocs() {
    this.matDialog.open(FileUploadDialogComponent, {
      data: {
        type: 'admissionDocuments',
        childCode: this.child.childCode
      }
    })
  }

  UploadDischargeDocs() {
    this.matDialog.open(FileUploadDialogComponent, {
      data: {
        type: 'dischargeDocuments',
        childCode: this.child.childCode
      }
    })
  }

  UploadFollowUpAdmissionDocs() {
    this.matDialog.open(FileUploadDialogComponent, {
      data: {
        type: 'followUpAdmissionDocuments',
        childCode: this.child.childCode
      }
    })
  }

  UploadFollowUpDischargeDocs() {
    this.matDialog.open(FileUploadDialogComponent, {
      data: {
        type: 'followUpDischargeDocuments',
        childCode: this.child.childCode
      }
    })
  }

  viewImage(followUpDischargeDocuments: UploadFile[], uploadFile: UploadFile) {
    this.matDialog.open(ViewImageComponent, {data: uploadFile})

  }

  @needConfirmation()
  deleteAdmissionFile(i: number, d: UploadFile, category: string) {
    const iRef = this.mStorage.storage.refFromURL(d.fileUrl)
    iRef.delete().then(() => {
      this.mFirestore.collection(this.centerService.env.child).doc(this.child.childCode).update({
        [category]: firebase.firestore.FieldValue.arrayRemove(d)
      }).then(() => {
        this._snackBar.open('File Deleted Successfully')._dismissAfter(2000)
      })
    })
    console.log(iRef)
  }

  trackByMethod(index: number, el: any): number {
    return el.id;
  }

  openLink(fileUrl: string) {
    window.open(fileUrl)

  }

  getCalculatedAge(child: firebase.firestore.Timestamp){
    let timeDiff = Math.abs(Date.now() - (child).toDate().getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
    // console.log(age)
    return age
  }


  // @ts-ignore
  async getExtension(fileUrl: string) {
    let reg= this.mStorage.storage.refFromURL(fileUrl)
    const m =await reg.getMetadata()
    let contentType = m.contentType
    if (contentType) {
      // Extract the file extension from the content type
      const fileExtension = contentType.split('/')[1];
      console.log(fileExtension)

      return fileExtension;
    }
    else ''
  }
}
