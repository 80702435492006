import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AngularFireDatabase} from "@angular/fire/compat/database";

@Component({
  selector: 'app-add-field',
  templateUrl: './add-field.component.html',
  styleUrls: ['./add-field.component.scss']
})
export class AddFieldComponent implements OnInit {
  form!:FormGroup
type:any
  constructor(private mDatabase:AngularFireDatabase,
    public dialogRef: MatDialogRef<AddFieldComponent>,
              @Inject(MAT_DIALOG_DATA) public data:string
  ) {
    this.type =data
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      // type:new FormControl('')
    })
  }
  get Name() {
    return this.form?.get('name')
  }
  Submit() {

    if(this.form.valid)
    {
     const key =  this.mDatabase.database.ref('types/'+this.data).push().key
      this.mDatabase.object('types/'+this.data+'/'+key).set(this.Name?.value).then(()=>{
        this.dialogRef.close()
      })
    }
  }


}
