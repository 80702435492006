import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from '@angular/material/dialog';
import {DonationtableComponent} from "../donationtable/donationtable.component";
import {LogService} from "../../../services/log.service";

@Component({
    selector: 'app-donation',
    templateUrl: './donation.component.html',
    styleUrls: ['./donation.component.scss']
})
export class DonationComponent implements OnInit {

    submitted = false;
    donationForm: any;

    constructor(public dialogRef: MatDialogRef<DonationComponent>,
                private readonly mLogService: LogService,) {
    }


    ngOnInit(): void {
        this.donationForm = new FormGroup({
            "centername": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "date": new FormControl('', [Validators.required]),
            "dob": new FormControl('', [Validators.required]),
            "mobile": new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}$'), Validators.minLength(10), Validators.maxLength(10)]),
            "donorCity": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "country": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "state": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "pincode": new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(6), Validators.maxLength(6)]),
            "streetline1": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "streetline2": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "types": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "support": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            "needfollow": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),

        })
    }

    SubmitData() {
//first sumbit data
        this.submitted = true;
        //Ideal Code Should be like this
        // if(form.isValid){
        //   then submit data to service
        // }
        // this.childService.addChild(this.donationForm.value)

        console.log(this.donationForm.value);
        let activity = {
            date: new Date().getTime(),
            section: 'Donor',
            action: 'Submit',
            description: `Donor added to Donor by`,
            currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
//then close dialog
        this.dialogRef.close()

    }

    cancel() {
        this.dialogRef.close()
        this.submitted = false;
        console.log(this.donationForm.reset())
    }

    get centername() {
        return this.donationForm.get('centername');
    }

    get streetline1() {
        return this.donationForm.get('streetline1');
    }

    get streetline2() {
        return this.donationForm.get('streetline2');
    }

    get occupation() {
        return this.donationForm.get('occupation');
    }

    get types() {
        return this.donationForm.get('types');
    }

    get mobile() {
        return this.donationForm.get('mobile');
    }

    get city() {
        return this.donationForm.get('city');
    }

    get country() {
        return this.donationForm.get('country');
    }

    get state() {
        return this.donationForm.get('state');
    }

    get pincode() {
        return this.donationForm.get('pincode');
    }

    get support() {
        return this.donationForm.get('support');
    }

    get needfollow() {
        return this.donationForm.get('needfollow');
    }


}
