import { Component, OnInit } from '@angular/core';
import {MatSelectChange} from "@angular/material/select";
import {FormBuilder} from "@angular/forms";
import {CentreService} from "../../../services/centre.service";
import {ChildService} from "../../child/child.service";
import {VehicleService} from "../vehicle.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
  selector: 'app-edit-car-log-book',
  templateUrl: './edit-car-log-book.component.html',
  styleUrls: ['./edit-car-log-book.component.scss']
})
export class EditCarLogBookComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private centreService: CentreService,
              private childService: ChildService,
              private mVehicleService: VehicleService,
              private mDatabase: AngularFireDatabase,
              private readonly mFirestore: AngularFirestore) { }

  ngOnInit(): void {
  }
  // onCentreSelect($event: MatSelectChange) {
  //   if ($event.value != null) {
  //     this.childService.getAllotedChildListByCenter($event.value!)
  //         .subscribe(childData => {
  //           this.childBycentre = childData.map(child => ({
  //             childCode: child.childCode,
  //             firstName: child.firstName,
  //             lastName: child.lastName,
  //             gender: child.gender,
  //             centreCode: child.centreCode,
  //             centreName: child.centreName,
  //             guardians:child.guardians
  //           }));
  //         });
  //     console.log(this.childBycentre)
  //   }
  //
  // }
//
}
