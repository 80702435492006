import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FullChild} from "../../../interfaces/FullChild";
import {Timestamp} from "firebase/firestore";

@Component({
  selector: 'app-recent-discharge',
  templateUrl: './recent-discharge.component.html',
  styleUrls: ['./recent-discharge.component.scss']
})
export class RecentDischargeComponent implements OnInit,AfterViewInit {
  recentAdmissions: FullChild[] = []
  @Input() mChildren?: FullChild[]

  constructor() {


  }

  ngOnInit(): void {
  }

    ngAfterViewInit(): void {
        if (this.mChildren) {
            // Filtering out entries with null dateOfDischarge
            const filteredChildren = this.mChildren.filter((x: FullChild) => x.dateOfDischarge != null);

            // Sorting the filtered array by dateOfDischarge
            filteredChildren.sort((a, b) => {
                if (a.dateOfDischarge && b.dateOfDischarge) {
                    const dateA =(a.admissionData[1]?.dateOfDischarge as unknown as Timestamp)?.toDate()
                    const dateB =(b.admissionData[0]?.dateOfDischarge as unknown as Timestamp)?.toDate()

                    // @ts-ignore
                    return dateA - dateB;
                }
                return 0;
            });

            // Extracting the last 10 elements and reversing
            this.recentAdmissions = filteredChildren.slice(-10).reverse();

            console.log(filteredChildren.length);
        }
    }


}
