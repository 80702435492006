import {AfterViewInit, Component, isDevMode, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {CreatecenterComponent} from "../centers/createcenter/createcenter.component";
import {AddHospitalComponent} from "./add-hospital/add-hospital.component";
import {MatDialog} from "@angular/material/dialog";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {HospitalService} from "../../services/hospital.service";
import {Centre} from "../../interfaces/Centres";
import {Hospitals} from "../../interfaces/Hospitals";
import {EditHospitalComponent} from "./edit-hospital/edit-hospital.component";
import {
  ViewEducationIndividualComponent
} from "../../modules/education/components/view-education-individual/view-education-individual.component";
import {DisplayHospitalComponent} from "./display-hospital/display-hospital.component";
import {UserRole} from "../rename/add-user-role/add-user-role.component";
import {LogService} from "../../services/log.service";
import {needConfirmation} from "../../confirm-dialog/confirm-dialog.decorator";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-hospitals',
  templateUrl: './hospitals.component.html',
  styleUrls: ['./hospitals.component.css']
})
export class HospitalsComponent implements AfterViewInit, OnInit {
  env= isDevMode()? environment.testMode :environment.productionMode

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<Hospitals>;
  dataSource: MatTableDataSource<Hospitals>;
  hospitals: Hospitals[] = [];
  /** Columns displayed in the assessment-table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = [ 'hospitalName','shortName', 'city', 'doctorsName', 'socialWorker','actions'];

  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  hospital=this.permissions.filter((x)=>x.functionName=='Hospital')


  constructor(private matDialog: MatDialog, private mService: HospitalService,
              private readonly mLogService: LogService) {
    this.dataSource = new MatTableDataSource(this.hospitals);

    // this.dataSource = new HospitalsDataSource(mDatabase);
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  createHopital() {
    this.matDialog.open(AddHospitalComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }

  ngOnInit(): void {
    this.mService.getAllHosptitals().subscribe(hospitals => {
      this.hospitals = []
      hospitals.forEach(c => {
        // console.assessment-table( c.payload.toJSON())
        let hospital = c.payload.toJSON() as Hospitals
        let key = c.payload.key

          if (key != null) {
            hospital['id'] = key;
          }
        this.hospitals.push(hospital)
        this.hospitals.reverse()
        this.dataSource = new MatTableDataSource(this.hospitals);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort!;
      })
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  viewHospital(element:any) {
   console.table(element)
    this.matDialog.open(EditHospitalComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
        data:{element}
      }
    )
    let activity={
      date:new Date().getTime(),
      section: 'Hospital',
      action: 'Edit',

      description: `Hospital ${ element.hospitalName } Edited by`,
      currentIp: localStorage.getItem('currentip')!
    }
    this.mLogService.addLog(activity)

  }

  viewDialog(element:any) {
    this.matDialog.open(DisplayHospitalComponent,{data:element,
      width:'800%',
      height:'90%'})

  }

@needConfirmation({title:'Delete',message:'Are you sure you want to delete?'})
delete(element:any ) {
this.mService.deleteHospital(element.id).then(()=>{
  let activity={
    date:new Date().getTime(),
    section: 'Hospital',
    action: 'Delete',

    description: `Hospital ${ element.hospitalName } Deleted by`,
    currentIp: localStorage.getItem('currentip')!
  }
  this.mLogService.addLog(activity)

})

  }
}
