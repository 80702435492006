<div class="container ">
  <h1 class="bg-warning d-flex justify-content-center"> Driver Add Form</h1>
  <prettyjson [obj]="driverForm.value"></prettyjson>
<form [formGroup]="driverForm">
  <div class="row">
  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>First Name</mat-label>
    <input matInput placeholder="First Name" formControlName="firstName" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Middle Name</mat-label>
    <input matInput placeholder="Middle Name" formControlName="middleName">
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Last Name</mat-label>
    <input matInput placeholder="Last Name" formControlName="lastName" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Current Address</mat-label>
    <input matInput placeholder="Current Address" formControlName="currentAddress" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Previous Address</mat-label>
    <input matInput placeholder="Previous Address" formControlName="previousAddress" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Phone</mat-label>
    <input matInput placeholder="Phone" formControlName="phone" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Date of Birth</mat-label>
    <input matInput [matDatepicker]="picker" placeholder="Choose a date" formControlName="dob" required>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>




  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Date of Joining</mat-label>
    <input matInput [matDatepicker]="picker2" placeholder="Choose a date" formControlName="dateOfJoining" required>
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>



    <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Social Security</mat-label>
    <input matInput placeholder="Social Security" formControlName="socialSecurity" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Emergency Contact Name</mat-label>
    <input matInput placeholder="Emergency Contact Name" formControlName="emergencyContactName" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Relation to Contact</mat-label>
    <input matInput placeholder="Relation to Contact" formControlName="relationToContact" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Contact Address</mat-label>
    <input matInput placeholder="Contact Address" formControlName="contactAddress" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Contact Phone</mat-label>
    <input matInput placeholder="Contact Phone" formControlName="contactPhone" required>
  </mat-form-field>




    <h2>KYC Section</h2>

    <!-- PAN Document -->
    <div class="col-md-4 col-sm-12">
      <label>PAN Document:</label>
      <input type="file" (change)="onFileSelected($event, 'kycData.pan')" formControlName="kycData.pan" required>
    </div>

    <!-- Aadhar Document -->
    <div class="col-md-4 col-sm-12">
      <label>Aadhar Document:</label>
      <input type="file" (change)="onFileSelected($event, 'kycData.aadhar')" formControlName="kycData.aadhar" required>
    </div>

    <!-- Bank Details Document -->
    <div class="col-md-4 col-sm-12 mb-2">
      <label>Bank Details Document:</label>
      <input type="file" (change)="onFileSelected($event, 'kycData.bankDetails')" formControlName="kycData.bankDetails" required>
    </div>

    <!-- Passport Photo -->
    <div class="col-md-4 col-sm-12">
      <label>Passport Photo:</label>
      <input type="file" (change)="onFileSelected($event, 'kycData.passportPhoto')" formControlName="kycData.passportPhoto" required>
    </div>

    <!-- Police Verification Document -->
    <div class="col-md-4 col-sm-12">
      <label>Police Verification Document:</label>
      <input type="file" (change)="onFileSelected($event, 'kycData.policeVerification')" formControlName="kycData.policeVerification" required>
    </div>

    <!-- Electric Bill Document -->
    <div class="col-md-4 col-sm-12 mb-4">
      <label>Electric Bill Document:</label>
      <input type="file" (change)="onFileSelected($event, 'kycData.electricBill')" formControlName="kycData.electricBill" required>
    </div>



    <h2>Driver's License Information</h2>
  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>State</mat-label>
    <input matInput placeholder="State" formControlName="state" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>License </mat-label>
    <input matInput placeholder="License Type" formControlName="license" required>
  </mat-form-field>
  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label> Type</mat-label>
    <input matInput placeholder="License Type" formControlName="type" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Expiration Date</mat-label>
    <input matInput [matDatepicker]="expiredDatePicker" placeholder="Choose a date" formControlName="expirationDate" required>
    <mat-datepicker-toggle matSuffix [for]="expiredDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #expiredDatePicker></mat-datepicker>
  </mat-form-field>



  <h2>Driver Experience</h2>
  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Type of Equipment</mat-label>
    <input matInput placeholder="Type of Equipment" formControlName="typeOfEquipment" required>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>From Date</mat-label>
    <input matInput [matDatepicker]="fromDatePicker" placeholder="Choose a date" formControlName="fromDate" required>
    <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #fromDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>To Date</mat-label>
    <input matInput [matDatepicker]="toDatePicker" placeholder="Choose a date" formControlName="toDate" required>
    <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #toDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-md-4 col-sm-12">
    <mat-label>Approximate Miles</mat-label>
    <input matInput placeholder="Approximate Miles" formControlName="approxMiles" required>
  </mat-form-field>
  </div>
  <!-- Submit Button -->
 <div class="d-flex justify-content-center mb-4">
   <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button>
 </div>
</form>
</div>
