import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Reciept} from "./DonationModel";

@Injectable({
  providedIn: 'root'
})
export class DonationService {
constructor(private mFirestore:AngularFirestore) { }

getDonations(){
return  this.mFirestore.collection('donations').valueChanges({idField:'id'})
}
getDonationsWithId(){
return this.mFirestore.collection<Reciept>('donations',ref => ref.orderBy('createdAt','desc')).snapshotChanges()
}
  getFailedDonationsWithId(){
    return this.mFirestore.collection<Reciept>('failedDonations',ref => ref.orderBy('date','desc')).snapshotChanges()
  }
  getFilteredDonationsWithId(timestamp:number){

  return this.mFirestore.collection<Reciept>('donations',ref => ref.where('createdAt','>=',timestamp)).snapshotChanges()
  }
  deleteDoc(doc_id: any) {
  return this.mFirestore.collection('donations').doc(doc_id).delete()
  }

  getByRange(startDate: Date | null | undefined, endDate: Date | null | undefined) {
    return this.mFirestore.collection<Reciept>('donations',ref => ref.where('date','>=',startDate)
        .where('date','>=',startDate)
    ).valueChanges({idField:'id'})

  }
}
