<div class="container">
    <h1>Allot Slot</h1>
<!--    <prettyjson [obj]="slotData"></prettyjson>-->

    <section>
        <table>
            <tr>
                <th>Donor Name</th>
                <td>{{this.mData.donated_by}}</td>
            </tr>
            <tr>
                <th>Address</th>
                <td>{{this.mData.address}}</td>
            </tr>
            <tr>
                <th>City</th>
                <td>{{this.mData.city}}</td>
            </tr>
            <tr>
                <th>State</th>
                <td>{{this.mData.state}}</td>
            </tr>
            <tr>
                <th>PAN</th>
                <td>{{this.mData.pan}}</td>
            </tr>
            <tr>
                <th>Slot</th>
                <td>{{this.mData.mealSlot}}</td>
            </tr>
            <tr>
                <th>Amount</th>
                <td>{{this.mData.donation_amount}}</td>
            </tr>
            <tr>
                <th>Date</th>
                <td>{{this.mData.start}}</td>
            </tr>


        </table>

    </section>

<!--    <div class="div" [formGroup]="form">-->

        <mat-form-field>
            <mat-label>Select Centre</mat-label>
            <mat-select formControlName="centreCode">
                <mat-option value="ALBandra"> AL Bandra</mat-option>
                <mat-option value="ALGurunanak">AL Gurunanak Niwas</mat-option>
                <mat-option value="GurunanakB">AL Gurunanak B</mat-option>
                <mat-option value="ALDiamondGarden">AL Diamond Garden</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>Select Date</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="start">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field> &nbsp; &nbsp;
        <button mat-mini-fab color="accent" (click)="Search()">
            <mat-icon>search</mat-icon>
        </button>
    <div>
    <mat-form-field>
        <mat-label>Select Slot</mat-label>
        <mat-select>
            <mat-option value="lunchForChild">Lunch For Child</mat-option>
            <mat-option value="lunchForFamily">Lunch For Family</mat-option>
            <mat-option value ="dinnerForChild">Dinner For Child</mat-option>
            <mat-option value="dinnerForChild">Dinner For Family</mat-option>
        </mat-select>
    </mat-form-field>
    </div>
        <div class="d-flex justify-content-center">
            <button mat-raised-button color="primary">Allot</button>
        </div>
        <section>

<!--            <div class="text-center">Booking Info</div>-->
<!--            <div>-->
<!--                <button mat-raised-button color="primary"> Lunch For Child</button>-->
<!--            </div>-->
<!--            <div>-->
<!--                <button mat-raised-button color="accent"> Lunch For Family</button>-->
<!--            </div>-->
<!--            <div>-->
<!--                <button mat-raised-button color="primary"> Dinner For Child</button>-->
<!--            </div>-->
<!--            <div>-->
<!--                <button mat-raised-button color="accent"> Dinner For Family</button>-->
<!--            </div>-->
        </section>


    </div>


