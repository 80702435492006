<div class="container">

  <h2 class="text-center">Donor Form</h2>


  <form [formGroup]="DonorForm">
    <div class="row">

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Donor Name</mat-label>
          <input matInput placeholder="Donor Name" required formControlName="donorName">
          <mat-error *ngIf="donated_by.invalid  &&  (donated_by.touched || donated_by.dirty)">
            <span style="color: red;" *ngIf="donated_by.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="donated_by.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>

      </div>


      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Address</mat-label>
          <input matInput placeholder="Address" required   formControlName="address">
          <mat-error *ngIf="  address.invalid  &&  (address.touched || address.dirty)">
            <span style="color: red;" *ngIf="address.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="address.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>

      </div>



      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Date of Birth</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="DOB"   formControlName="dob">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <!--    pattern="[0-9]{10}$"-->
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Mobile No.</mat-label>
          <input matInput placeholder="Donor Mobile No" maxlength="10" required   formControlName="donorMobile">
          <mat-icon matSuffix>phone</mat-icon>
          <mat-error *ngIf="  donorMobile.invalid  &&  (donorMobile.touched || donorMobile.dirty)">
            <span style="color: red;" *ngIf="donorMobile.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="donorMobile.errors?.pattern">*Enter Only Number.</span>
          </mat-error>
        </mat-form-field>

      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Alternate Mobile No.</mat-label>
          <input matInput placeholder="Alternate Number" maxlength="10"    formControlName="alternateNumber">
          <mat-icon matSuffix>phone</mat-icon>
        </mat-form-field>

      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email"  required   formControlName="email">
          <mat-icon matSuffix>email</mat-icon>
          <mat-error *ngIf="  mEmail.invalid  &&  (mEmail.touched || mEmail.dirty)">
            <span style="color: red;" *ngIf="mEmail.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="mEmail.errors?.pattern">Enter a valid email</span>
          </mat-error>
        </mat-form-field>

      </div>



      <!--PAN CARD-->
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>PAN CARD NUMBER</mat-label>
          <input matInput placeholder="pan"  required   formControlName="pan">
          <mat-icon matSuffix>contact</mat-icon>
          <mat-error *ngIf=" pan.invalid  &&  (pan.touched || pan.dirty)">
            <span style="color: red;" *ngIf="pan.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="pan.errors?.pattern">*Enter a valid pan number.</span>
            <span  style="color: red;" *ngIf="pan.errors?.pattern">*Enter a valid pan number.</span>
          </mat-error>
        </mat-form-field>

      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput placeholder="City" required formControlName="donorCity">
          <mat-error *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
            <span style="color: red;" *ngIf="city.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>

      </div>


      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <input matInput placeholder="Country Name" required formControlName="country">
          <mat-error *ngIf="  country.invalid  &&  (country.touched || country.dirty)">
            <span style="color: red;" *ngIf="country.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="country.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>

      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>State</mat-label>
          <input matInput placeholder="State Name"  required formControlName="state">
          <mat-error *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
            <span style="color: red;" *ngIf="state.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</span>
          </mat-error>
        </mat-form-field>

      </div>

      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Pin Code</mat-label>
          <input matInput #postalCode maxlength="6" required  placeholder="pincode" formControlName="pincode"  >
          <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
          <mat-error *ngIf="  pincode.invalid  &&  (pincode.touched || pincode.dirty)">
            <span style="color: red;" *ngIf="pincode.errors?.required">*It is  Required.</span>
            <span  style="color: red;" *ngIf="pincode.errors?.pattern">*Enter Only Number.</span>
          </mat-error>
        </mat-form-field>

      </div>

    <div class="col-md-6">
      <mat-form-field appearance="outline" >
        <mat-label>Donor Type</mat-label>
        <mat-select formControlName="donorType">
          <mat-option value="individual">Individual</mat-option>
          <mat-option value="csr">CSR</mat-option>
          <mat-option value="foundation">Foundation</mat-option>
          <mat-option value="club">Club</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


      <!--      <div class="col-md-6">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Occupation</mat-label>-->
<!--          <input matInput placeholder="Occupation"  required formControlName="occupation">-->
<!--          <mat-error *ngIf="  occupation.invalid  &&  (occupation.touched || occupation.dirty)">-->
<!--            <span style="color: red;" *ngIf="occupation.errors?.required">*It is  Required.</span>-->
<!--            <span  style="color: red;" *ngIf="occupation.errors?.pattern">*Enter Only characters.</span>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->

<!--      </div>-->

<!--      <div class="col-md-6">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>Types</mat-label>-->
<!--          <input matInput placeholder="Types"  required formControlName="type">-->
<!--          <mat-error *ngIf="  type.invalid  &&  (type.touched || type.dirty)">-->
<!--            <span style="color: red;" *ngIf="type.errors?.required">*It is  Required.</span>-->
<!--            <span  style="color: red;" *ngIf="type.errors?.pattern">*Enter Only characters.</span>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->

<!--      </div>-->

<!--      <div class="col-md-10">-->
<!--        <mat-form-field appearance="outline">-->
<!--          <mat-label>How Would you like to support us</mat-label>-->
<!--          <input matInput   required formControlName="support">-->
<!--          <mat-error *ngIf="  support.invalid  &&  (support.touched || support.dirty)">-->
<!--            <span style="color: red;" *ngIf="support.errors?.required">*It is  Required.</span>-->
<!--            <span  style="color: red;" *ngIf="support.errors?.pattern">*Enter Only characters.</span>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->

<!--      </div>-->

    </div>

<div class="text-center">
    <button color="warn" mat-raised-button (click)="cancel()" >Cancel</button> &nbsp;
    <button color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>
</div>

  </form>

</div>
