 <div class="grid-container"style="padding: 50px">
  <div class="row row-cols-1 row-cols-md-3 g-4">
    <div class="col centre" *ngFor="let c of centreArray; let i =index"  >
      <div class=" typecenter" >
        <canvas baseChart
                [labels]="doughnutChartLabels"
                [datasets]="chartconfigurationsArray[i]"
                [options]="doughnutChartOptions"
                [legend]="true"
                [type]="'doughnut'" >

        </canvas>&nbsp;
        <div class="text-center">
        <label class="text-center"> <b clas>{{c.centreName}}</b></label>
        </div>
        </div>

<!--  <canvas baseChart-->
<!--          [labels]="doughnutChartLabels"-->
<!--          [datasets]="doughnutChartDatasets3"-->
<!--          [options]="doughnutChartOptions"-->
<!--          [legend]="true"-->
<!--          [type]="'doughnut'" >-->

<!--  </canvas>&nbsp;-->
<!--  <label class="typecenter"> <b>AL Pune Center</b></label>-->

    </div>




  </div>
</div>
<!--<button mat-raised-button color="primary"  (click)="openpopup()">Create center</button>-->


