


<div class="d-flex justify-content-end mt-2 me-2">
  <button color="accent" mat-raised-button id="create-center">
    <mat-icon>person_add</mat-icon>&nbsp;Add
  </button>&nbsp;
</div>

<div class="border w-25 m-2">
  <mat-icon class="fs-5 p-2 mx-2">search</mat-icon>
  <input type="text" placeholder="Search" class="border-0 p-2">
</div>


<table class="table">
  <thead>
  <tr>
    <th>SR NO</th>
    <th>Role</th>
    <th>Action</th>
  </tr>
  </thead>

  <tbody>
  <tr>
    <td>1</td>
    <td>Super Admin</td>
    <td>
      <button mat-icon-button color="accent"  matTooltip="Edit">
      <mat-icon>edit</mat-icon>
       </button>
      <button mat-icon-button color="warn"  matTooltip="Delete"><mat-icon>delete</mat-icon></button>
    </td>

  </tr>
  </tbody>

</table>
