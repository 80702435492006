<!-- allot-sponsorship-child.component.html -->
<div class="d-flex justify-content-end">
  <label>Total Transaction  Amount:</label>
  <div>{{mData.totalTransactionAmount}}</div>
</div>
<div class="d-flex justify-content-end">
  <label>Current  Amount:</label>
  <div>{{mData.remainingAmount}}</div>
</div>
<div>
  <h2>Select Centre:</h2>
  <mat-form-field appearance="outline">
    <mat-label>Centre</mat-label>
    <mat-select [(ngModel)]="selectedCentre" name="selectedCentre" (selectionChange)="onCentreSelect($event)">
      <mat-option *ngFor="let centre of centres$ | async" [value]="centre.centreCode">{{ centre.centreName }}</mat-option>
        <mat-option  value="All">All Centres</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="table-container">
  <table mat-table [dataSource]="datasource" class="mat-elevation-z8" matSort>

    <!-- Sr Column -->
    <ng-container matColumnDef="sr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
      <td mat-cell *matCellDef="let child;let i =index"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ i+1}} </td>
    </ng-container>

    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
      <td mat-cell *matCellDef="let child"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.firstName }} </td>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
      <td mat-cell *matCellDef="let child"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.lastName }} </td>
    </ng-container>

    <!-- Unit Column -->
    <ng-container matColumnDef="selectUnit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
      <td mat-cell *matCellDef="let child"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.selectUnit }} </td>
    </ng-container>

    <!-- Centre Name Column -->
    <ng-container matColumnDef="centreName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre Name </th>
      <td mat-cell *matCellDef="let child"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }" > {{ child.centreName }} </td>
    </ng-container>




    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
      <td mat-cell *matCellDef="let child"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }">
      <mat-form-field appearance="outline" *ngIf="child.sponsored!=true">
        <mat-label>
          Duration
        </mat-label>
        <mat-select [(ngModel)]="child.duration" [disabled]="this.selectedChild.includes(child)">
          <mat-option value="1">1 Months</mat-option>
          <mat-option value="2">2 Months</mat-option>
          <mat-option value="3">3 Months</mat-option>
          <mat-option value="4">4 Months</mat-option>
          <mat-option value="5">5 Months</mat-option>
          <mat-option value="6">6 Months</mat-option>
          <mat-option value="7">7 Months</mat-option>
          <mat-option value="8">8 Months</mat-option>
          <mat-option value="9">9 Months</mat-option>
          <mat-option value="10">10 Months</mat-option>
          <mat-option value="11">11 Months</mat-option>
          <mat-option value="12">12 Months</mat-option>
        </mat-select>
      </mat-form-field>
     <span *ngIf="child.sponsored==true">Sponsored</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let child"  [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }">
      <mat-form-field appearance="outline" *ngIf="child.sponsored!=true" >
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="child.startDate" [disabled]="this.selectedChild.includes(child)" >
          <input matEndDate placeholder="End date" [(ngModel)]="child.endDate" [disabled]="this.selectedChild.includes(child)">
        </mat-date-range-input>
        <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
        <span *ngIf="child?.sponsored==true">Sponsored</span>

      </td>

    </ng-container>



    <ng-container matColumnDef="checked" >
      <th  mat-header-cell *matHeaderCellDef mat-sort-header>Add</th>
      <td  mat-cell *matCellDef="let child">
        <button *ngIf="!this.selectedChild.includes(child) && this.mData.amount!=this.totalAmount && child.duration!=null && child.startDate!=null && child.endDate!=null" mat-mini-fab color="primary" (click)="addData(child)">+</button>
        <button *ngIf="this.selectedChild.includes(child)" mat-mini-fab color="accent" (click)="removeData(child)">-</button>
      </td>
    </ng-container>


    <!-- Define the table rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[ 20,50,100,200]"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>


</div>
<h2>TOTAL AMOUNT={{totalAmount}}</h2>
<div class="text-center">
  <button mat-raised-button color="primary" class="w-25" [disabled] = "this.mData.remainingAmount<totalAmount && this.totalAmount !=0" (click)="onSubmit()">Submit</button>
</div>
