import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Employee} from "../../../interfaces/Employee";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DialogRef} from "@angular/cdk/dialog";
import {LogService} from "../../../services/log.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {cloneDeep, isEqual} from "lodash";
import {MatSlideToggleChange} from "@angular/material/slide-toggle";
import firebase from "firebase/compat";
import { Functions } from '@angular/fire/functions';
import {AngularFireFunctions} from "@angular/fire/compat/functions";
import {Observable} from "rxjs";

@Component({
    selector: 'app-edit-employee-info',
    templateUrl: './edit-employee-info.component.html',
    styleUrls: ['./edit-employee-info.component.scss']
})
export class EditEmployeeInfoComponent implements OnInit {
    form: FormGroup
  departments$:Observable<any>

    initialFormState: any; //?add this
    constructor(@Inject(MAT_DIALOG_DATA) public md: Employee,
                private readonly matSnackbar: MatSnackBar,
                private readonly mLogService: LogService,
                private functions: AngularFireFunctions,
                private readonly mDialogRef: MatDialogRef<EditEmployeeInfoComponent>,
                private mDatabase: AngularFireDatabase) {
      // alert(this.md?.id)
        this.form = new FormGroup<any>({
            userName: new FormControl(this.md?.userName, Validators.required),
            email: new FormControl(this.md?.email,),
            mobile: new FormControl(this.md?.mobile),
            location: new FormControl(this.md?.location),
            department: new FormControl(this.md?.department),
            jobtitle: new FormControl(this.md?.jobtitle),
            disabled: new FormControl(!this.md?.disabled|| false),
        })
      this.departments$ = this.mDatabase.list('types/Employee Departments').valueChanges()

    }

    ngOnInit(): void {
        // alert(this.md?.email)
        this.initialFormState = cloneDeep(this.form); //?add this
    }

    updateUser() {
        const employeeRef = this.mDatabase.object('users/' + this.md.id)
        const submittedFormState: any = this.form; //?add this

        // Update the specific fields you want to change
        employeeRef.update(this.form.value)
            .then(() => {
                const differences = this.getFormDifference(this.initialFormState, submittedFormState)
                console.log('Employee data updated successfully');
                this.matSnackbar.open(`User details updated successfully`,'',{
                    duration:3000,
                    panelClass:'success'
                })
                let activity = {
                    date: new Date().getTime(),
                    section: 'Employee',
                    changes: differences,  //?add this
                    action: 'Edit',
                    description: `Employee ${this.form.get('username')?.value} edited by`,
                    currentIp: localStorage.getItem('currentip')!
                }
                this.mLogService.addLog(activity).then(() => {
                    this.mDialogRef.close()
                })

            })
            .catch(error => {
                console.error('Error updating employee data:', error);
            });

    }

    getFormDifference(initialFormState: any, submittedFormState: any) {
        const differences: any = {};
        for (const key in this.initialFormState.controls) {
            const initialControl = this.initialFormState.get(key);
            const submittedControl = submittedFormState.get(key);
            console.log(`Initial Value: ${initialControl.value}`);
            console.log(`Submitted Value: ${submittedControl.value}`);
            if (initialControl instanceof FormArray && submittedControl instanceof FormArray) {

                // Handle FormArray fields
                for (let i = 0; i < initialControl.length; i++) {
                    if (!isEqual(initialControl.at(i).value, submittedControl.at(i).value)) {

                        if (!differences[key]) {

                            differences[key] = [];
                        }
                      console.log('Difference Found')
                        differences[key].push({
                            index: i,
                            initialValue: initialControl.at(i).value,
                            submittedValue: submittedControl.at(i).value,
                        });
                    }
                }
            } else if (!isEqual(initialControl.value, submittedControl.value)) {
                // Handle regular FormControl fields
                // alert('differnce found')
              console.log(`${initialControl.value}=> ${submittedControl.value}`)
                differences[key] = {
                    initialValue: initialControl.value,
                    submittedValue: submittedControl.value,
                };
            }

        }
        return differences
    }

    onToggleChange($event: MatSlideToggleChange) {
        alert($event.checked)

        console.log('User UID',this.md.uid,)
        this.functions.httpsCallable('disableUser')({uid: this.md.uid,
        disabled:!$event.checked}).subscribe(res=>{
            console.log(res)
            this.matSnackbar.open('User status checked')._dismissAfter(3000)
        })
    }
}
