<div class="card">
    <div class="body">
    <h3 class="text-center rounded text-white">Admission Count Report {{heading}}</h3>
    <!--{{startDate}}  {{startDate|date:'medium'}}  <br>-->
    <!-- {{endDate}} {{endDate|date:'medium'}}  <br>-->
    <br>
    <div class="d-flex container ">
        <!--        <mat-form-field appearance="outline">-->
        <!--            <mat-select (selectionChange)="showSelections($event)" [formControl]="sortBySelect">-->
        <!--                <mat-option value="byYear">By Financial Year</mat-option>-->
        <!--                <mat-option value="byCentre">By Centre</mat-option>-->
        <!--                <mat-option value="byDate">By Date</mat-option>-->
        <!--            </mat-select>-->
        <!--        </mat-form-field>-->
        <div class="d-none">
            <mat-form-field appearance="outline">
                <mat-label>Centre</mat-label>
                <mat-select [ngModel]="selectedCentre">
                    <mat-option *ngFor="let c of (centres$|async)" [value]="c.centreCode">{{c.centreName}}</mat-option>
                    <mat-option value="All">All Centres</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline" class=" ms-2" class="justify-content-center" *ngIf="sortBy=='byYear'">
            <mat-select [formControl]="select" (selectionChange)="selectYear($event)" >
                <mat-option value="Please Select">Select Financial Year</mat-option>
                <mat-option value="2024">April 2024 - March 2025</mat-option>
                <mat-option value="2023">April 2023 - March 2024</mat-option>
                <mat-option value="2022">April 2022 - March 2023</mat-option>
                <mat-option value="2021">April 2021 - March 2022</mat-option>
                <mat-option value="2020">April 2020 - March 2021</mat-option>
                <mat-option value="2019">April 2019 - March 2020</mat-option>
                <mat-option value="2018">April 2018 - March 2019</mat-option>
                <mat-option value="2017">April 2017 - March 2018</mat-option>
                <mat-option value="2016">April 2016 - March 2017</mat-option>
                <mat-option value="2015">April 2015 - March 2016</mat-option>
                <mat-option value="2014">June 2014 - March 2015</mat-option>
                <mat-option value="Custom">Custom Date</mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="select.value==='Custom'">
            &nbsp;&nbsp;
            <mat-form-field appearance="outline">
                <mat-label>From</mat-label>
                <input matInput [matDatepicker]="start" [(ngModel)]="startDate">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;
            <mat-form-field appearance="outline">
                <mat-label>To</mat-label>
                <input matInput [matDatepicker]="end" [(ngModel)]="endDate">
                <mat-hint>DD/MM/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;<button mat-mini-fab color="accent" (click)="searchByDate()">
            <mat-icon>search</mat-icon>
        </button>

        </div>

    </div>
      <div class="d-flex justify-content-end">
        <mat-checkbox [(ngModel)]="showInactiveCentres">Show All Centres</mat-checkbox>
      </div>
    <table mat-table [dataSource]="dataSource" class="">

        <!-- Position Column -->
        <ng-container matColumnDef="centreName">
            <th mat-header-cell *matHeaderCellDef> Centre Name</th>
            <td mat-cell *matCellDef="let element"> {{element.centreName}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="new_admission">
            <th mat-header-cell *matHeaderCellDef>New Admission</th>
            <td mat-cell *matCellDef="let element"> {{element.new_admission}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="discharge">
            <th mat-header-cell *matHeaderCellDef> Discharge</th>
            <td mat-cell *matCellDef="let element"> {{element.discharge}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="follow_up_admission">
            <th mat-header-cell *matHeaderCellDef> Follow Up Admission</th>
            <td mat-cell *matCellDef="let element"> {{element.follow_up_admission}} </td>
        </ng-container>

        <ng-container matColumnDef="follow_up_discharge">
            <th mat-header-cell *matHeaderCellDef> Follow Up Discharge</th>
            <td mat-cell *matCellDef="let element"> {{element.follow_up_discharge}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


        <! -- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">Please select a financial year or a custom date</td>
        </tr>
    </table>
    </div>
</div>
<div class="row" *ngIf="showLog">
    <div class="col-md-3">
        <h2>Admission</h2>
        <p *ngFor="let d of admissionsCountChildarray">{{d.firstName}} {{d.lastName}}   -   {{ d.centreName}}</p>
    </div>
    <div class="col-md-3">
        <h2>Discharge</h2>
        <p *ngFor="let d of dischargeCountChildArray">{{d.firstName}} {{d.lastName}}   -   {{ d.centreName}}</p>
    </div>
    <div class="col-md-3">
        <h2>Follow Up</h2>
        <p *ngFor="let d of followUpCountChildArray">{{d.firstName}} {{d.lastName}}   -   {{ d.centreName}}</p>
    </div>
    <div class="col-md-3">
        <h2>Follow Out</h2>
        <p *ngFor="let d of followUpOutCountChildArray">{{d.firstName}} {{d.lastName}}   -   {{ d.centreName}}</p></div>

</div>

