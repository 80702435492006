import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Employee} from "../../../interfaces/Employee";
import {LogService} from "../../../services/log.service";

@Component({
  selector: 'app-add-car-fuels-details',
  templateUrl: './add-car-fuels-details.component.html',
  styleUrls: ['./add-car-fuels-details.component.scss']
})
export class AddCarFuelsDetailsComponent implements OnInit {
  carForm: FormGroup;
  vehicles:any[] =[]
   drivers: any[] =[];
  constructor(private fb: FormBuilder,
              private mVehicleService: VehicleService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private mLogService: LogService
              ) {
    this.carForm = this.fb.group({
      date: ['', Validators.required],
      fuel: ['', Validators.required],
      kilometers: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      vehicleNumber: ['', Validators.required],
      driverName: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    console.log(this.data)

    this.mVehicleService.getVehicles().subscribe(res => {
      console.log(res)
      this.vehicles = res
    })


    this.mVehicleService.getDriver().subscribe(res =>{
      this.drivers = res
    })


    this.carForm = this.fb.group({
      date: [this.getFormattedDate(this.data.date), Validators.required],
      fuel: [this.data.fuel, Validators.required],
      kilometers: [this.data.kilometers, [Validators.required, Validators.pattern(/^\d+$/)]],
      vehicleNumber: [this.data.vehicleNumber, Validators.required],
      driverName: [this.data.driverName, Validators.required]
    });
  }

  onSubmit() {
    if (this.carForm.valid) {
      this.mVehicleService.addDCarFuelsData(this.carForm.value).then(r => {
        console.log("car data added successfully")
        let activity={
          date:new Date().getTime(),
          section: 'Transport',
          action: 'Add',

          description: `Fuels details added by`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
      })
      console.log(this.carForm.value);
    }
    this.carForm.reset()
  }

  private getFormattedDate(timestamp: any): Date {
    if (timestamp && timestamp.seconds) {
      return new Date(timestamp.seconds * 1000);
    } else {
      return new Date(); // Set a default date or handle accordingly
    }
  }
}
