<div class="container">
  <div class="row ">

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input  [rangePicker]="picker">
        <input matStartDate [(ngModel)]="startDate" placeholder="Start date">
        <input matEndDate [(ngModel)]="endDate" placeholder="End date">
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <div class="col-md-6 mt-1">
      <div>
        <button mat-mini-fab color="accent" class="me-5" (click)="searchbyDateRange()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3 d-flex justify-content-end">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search Here..." #input>
      </mat-form-field>
    </div>
    <div class="col-md-9 d-flex justify-content-end">
      <button mat-raised-button class="m-1" (click)="exportexcel()" color="primary">
        <mat-icon>cloud_download</mat-icon> Download Table
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="excel-table" matSort>
        <!-- Donor Name Column -->
        <ng-container matColumnDef="donorName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Donor Name </th>
          <td mat-cell *matCellDef="let element"> {{ element.donorName }} </td>
        </ng-container>

        <!-- Donor Mobile Column -->
        <ng-container matColumnDef="donorMobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Donor Mobile </th>
          <td mat-cell *matCellDef="let element"> {{ element.donorMobile }} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> {{ element.email }} </td>
        </ng-container>

        <!-- Donor Address Column -->
        <ng-container matColumnDef="donoraddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Donor Address </th>
          <td mat-cell *matCellDef="let element"> {{ element.donoraddress }} </td>
        </ng-container>

        <!-- PAN Column -->
        <ng-container matColumnDef="pan">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> PAN </th>
          <td mat-cell *matCellDef="let element"> {{ element.pan }} </td>
        </ng-container>

        <!-- Donor City Column -->
        <ng-container matColumnDef="donorcity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> City </th>
          <td mat-cell *matCellDef="let element"> {{ element.donorcity }} </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
          <td mat-cell *matCellDef="let element"> {{ formatDate(element.date) }} </td>
        </ng-container>

        <!-- Define column order -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <mat-paginator [length]="resultsLength" [pageSizeOptions]="[5, 10, 20, 50, 75]"
                     aria-label="Select page of GitHub search results"></mat-paginator>
    </div>
  </div>

</div>
