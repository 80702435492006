<div class="row">
<form [formGroup]="pictureForm" class=" col-md-6">
    <h4>Upload photo to send thank you mail</h4>
    <!--      <mat-card-subtitle>{{subtitle}}</mat-card-subtitle>-->
    <div class="row">
        <div class="col-md-6">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>Document Image</mat-label>
                <ngx-mat-file-input formControlName="photo" placeholder="">
                </ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
                <mat-error *ngIf="pictureForm.controls['photo'].hasError('required')">
                    Click to Select File or Image
                </mat-error>
                <mat-error *ngIf="pictureForm.controls['photo'].hasError('image')">
                    Wrong File Format Images and pdf only
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>File Name</mat-label>
                <input matInput formControlName="description"  placeholder="Enter file name">
                <mat-error *ngIf="pictureForm.controls['description'].hasError('required')">
                    Enter Document Remark
                </mat-error>
            </mat-form-field>
            <button
                    (click)="postKitty()"
                    [disabled]="pictureForm.invalid || submitted"
                    color="primary"
                    mat-raised-button>Upload</button>
        </div>

        <div class="col-md-6">
            <img
                    *ngIf="kittyImagePreview"
                    [src]="kittyImagePreview"
                    alt="Image"
                    class="preview-image"
            />
        </div>
    </div>
    <mat-progress-bar *ngIf="submitted" [value]="uploadProgress$ | async" mode="determinate"></mat-progress-bar>

</form>
<div class="col-md-6" >
    <div  *ngIf="show">
    <a (click)="openLink(mDocuments.fileUrl)">
                    <img [src]="mDocuments?.fileUrl" style="width: 250px; height: 250px" class="img-thumbnail img-fluid">
                </a>

                <p style=" font-size: 14px" class="text-center">{{mDocuments?.description}}  <button mat-icon-button color="warn" (click)="deleteAdmissionFile(mDocuments.fileUrl)">
                    <mat-icon>delete</mat-icon>
                </button></p>
    </div>
            </div>
</div>
