import {Component, Inject, isDevMode, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HospitalService} from "../../../services/hospital.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TitleCasePipe} from "@angular/common";
import {LogService} from "../../../services/log.service";
import {environment} from "../../../../environments/environment";
import {cloneDeep, isEqual} from "lodash";

@Component({
  selector: 'app-edit-hospital',
  templateUrl: './edit-hospital.component.html',
  styleUrls: ['./edit-hospital.component.scss']
})
export class EditHospitalComponent implements OnInit {
  hasUnitNumber = true;
addressForm:FormGroup

  env= isDevMode()? environment.testMode :environment.productionMode

  initialFormState: any; //?add this
  constructor(private fb: FormBuilder,
              private mService: HospitalService,
              private matDialogRef: MatDialogRef<EditHospitalComponent>,
              private titlecasePipe: TitleCasePipe,
              private mHospitalService:HospitalService,
              private readonly mLogService: LogService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('element',this.data.element.hospitalName)
    console.log('table',this.data)
    this.addressForm = this.fb.group({
      // company: null,
      hospitalName: new FormControl(this.data?.element.hospitalName, Validators.required),
      // lastName: [null, Validators.required],
      doctorsName: new FormControl(this.data?.element.doctorsName, Validators.required),
      socialWorkerName: new FormControl(this.data?.element.socialWorkerName, Validators.required),
      address: new FormControl(this.data?.element.address, Validators.required),
      shortName: new FormControl(this.data?.element.shortName, Validators.required),
      // address2: new FormControl(this.data?.element.address2, Validators.required),
      city: new FormControl(this.data?.element.city, Validators.required),
      id: new FormControl (this.data?.element.id, Validators.required),
      state: new FormControl(this.data?.element.state, Validators.required),
      zipcode: new FormControl(this.data?.element.zipcode, [
        Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
      // shipping: ['free', Validators.required]
    });
  }
  mState = [
    {code: "AN", name: "Andaman and Nicobar Islands"},
    {code: "AP", name: "Andhra Pradesh"},
    {code: "AR", name: "Arunachal Pradesh"},
    {code: "AS", name: "Assam"},
    {code: "BR", name: "Bihar"},
    {code: "CG", name: "Chandigarh"},
    {code: "CH", name: "Chhattisgarh"},
    {code: "DH", name: "Dadra and Nagar Haveli"},
    {code: "DD", name: "Daman and Diu"},
    {code: "DL", name: "Delhi"},
    {code: "GA", name: "Goa"},
    {code: "GJ", name: "Gujarat"},
    {code: "HR", name: "Haryana"},
    {code: "HP", name: "Himachal Pradesh"},
    {code: "JK", name: "Jammu and Kashmir"},
    {code: "JH", name: "Jharkhand"},
    {code: "KA", name: "Karnataka"},
    {code: "KL", name: "Kerala"},
    {code: "LD", name: "Lakshadweep"},
    {code: "MP", name: "Madhya Pradesh"},
    {code: "MH", name: "Maharashtra"},
    {code: "MN", name: "Manipur"},
    {code: "ML", name: "Meghalaya"},
    {code: "MZ", name: "Mizoram"},
    {code: "NL", name: "Nagaland"},
    {code: "OR", name: "Odisha"},
    {code: "PY", name: "Puducherry"},
    {code: "PB", name: "Punjab"},
    {code: "RJ", name: "Rajasthan"},
    {code: "SK", name: "Sikkim"},
    {code: "TN", name: "Tamil Nadu"},
    {code: "TS", name: "Telangana"},
    {code: "TR", name: "Tripura"},
    {code: "UK", name: "Uttarakhand"},
    {code: "UP", name: "Uttar Pradesh"},
    {code: "WB", name: "West Bengal"}]

  ngOnInit(): void {
    console.table(this.data)
    this.initialFormState = cloneDeep(this.addressForm); //?add this



    // @ts-ignore
  }

  get address2() {
    return this.addressForm.get('address2');
  }

  get address() {
    return this.addressForm.get('address');
  }  get doctorsName() {
    return this.addressForm.get('doctorsName');
  }  get state() {
    return this.addressForm.get('state');
  }  get hospitalName() {
    return this.addressForm.get('hospitalName');
  }
  get shortName() {
    return this.addressForm.get('shortName');
  }
  get socialWorkerName() {
    return this.addressForm.get('socialWorkerName');
  }

  onBlur(formControl: any) {
    if(formControl.value !== null) {
      formControl.setValue(this.titlecasePipe.transform(formControl.value.toString().trim().toUpperCase()))
    }


  }
  onBlurSentence(formControl: any) {
    if(formControl.value !== null) {
      formControl.setValue(capitalizeSentence(formControl.value()))
    }
}
  Submit() {
    const formValues = this.addressForm.value;
    const submittedFormState: any = this.addressForm; //?add this

    // Update the Firebase Realtime Database
    this.mHospitalService.updatehospital(this.data.element.id,formValues)
      .then(() => {
        const differences = this.getFormDifference(this.initialFormState, submittedFormState)
        let activity={
          date:new Date().getTime(),
          section: 'Hospital',
          action: 'Update',

          changes: differences,  //?add this


          description: `Hospital ${this.addressForm.get('hospitalName')?.value} updated by`,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)
        // Handle success
        console.log('Data updated successfully');
        this.matDialogRef.close()
      })

  }

  getFormDifference(initialFormState: any, submittedFormState: any) {
    const differences: any = {};

    for (const key in this.initialFormState.controls) {
      const initialControl = this.initialFormState.get(key);
      const submittedControl = submittedFormState.get(key);
      console.log(`Initial Value: ${initialControl.value}`);
      console.log(`Submitted Value: ${submittedControl.value}`);
      if (initialControl instanceof FormArray && submittedControl instanceof FormArray) {

        // Handle FormArray fields
        for (let i = 0; i < initialControl.length; i++) {
          if (!isEqual(initialControl.at(i).value, submittedControl.at(i).value)) {

            if (!differences[key]) {

              differences[key] = [];
            }
            alert('differnce found')

            differences[key].push({
              index: i,
              initialValue: initialControl.at(i).value,
              submittedValue: submittedControl.at(i).value,
            });
          }
        }
      } else if (!isEqual(initialControl.value, submittedControl.value)) {
        // Handle regular FormControl fields
        alert('differnce found')
        alert(`${initialControl.value}=> ${submittedControl.value}`)
        differences[key] = {
          initialValue: initialControl.value,
          submittedValue: submittedControl.value,
        };
      }

    }
    return differences
  }

  Submit2() {

    // this.counsellingService.addAssessment(this.form.value).then(()=>{
    //     alert('Assessment Updated')
    // })

    if (this.addressForm.valid) {
      console.log('Form is valid')
      this.addressForm.addControl('createdAt', new FormControl(Date.now()))
      // this.mService(this.addressForm.getRawValue(),this.data.id).then(() => {
      //   alert('Hospital Updated')

      // })
    }
  }
  close(){
  this.matDialogRef.close()

  }

}
function capitalizeSentence(sentence:string) {
  if (!sentence) return ''; // Handle empty input

  const words = sentence.split(' ');
  const capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
    return word;
  });

  return capitalizedWords.join(' ');
}

