import {Component, Inject, isDevMode, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {CentreService} from "../../../services/centre.service";
import {Centre} from "../../../interfaces/Centres";
import {Observable} from "rxjs";
import {MatSelectChange} from "@angular/material/select";
import {FullChild} from "../../../interfaces/FullChild";
import {ChildService} from "../../child/child.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {UnitSponsorshipService} from "../unit-sponsorship.service";
import {environment} from "../../../../environments/environment";
import {LogService} from "../../../services/log.service";


@Component({
    selector: 'app-transfer-unit-sponsorship',
    templateUrl: './transfer-unit-sponsorship.component.html',
    styleUrls: ['./transfer-unit-sponsorship.component.scss']
})
export class TransferUnitSponsorshipComponent implements OnInit {
    mData: any;
    displayedColumns: string[] = [
      'sr',
        'firstName',
        'lastName',
        'selectUnit',
        'date',
        'transfer',
    ];
    centres$: Observable<Centre[]>
    selectedCentre = 'All'
    childData: any[] = []
    datasource!: MatTableDataSource<any>
    selectedChild: any[] = []
    totalAmount = 0
    endDatePicker: any;

    env = isDevMode() ? environment.testMode : environment.productionMode

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private centreService: CentreService,
                private childService: ChildService,
                private fireDatabase: AngularFirestore,
                private readonly mService:UnitSponsorshipService,
                private dialogReg: MatDialogRef<TransferUnitSponsorshipComponent>,
                private mLogService:LogService
    )
    {
        this.mData = data
        this.centres$ = this.centreService.getAllCentresSorting(this.selectedCentre)
// alert(JSON.stringify(this.mData))

    }

    ngOnInit(): void {

    }

    onCentreSelect($event: MatSelectChange) {
        this.childService.getAllAllootedChildByCentre(this.selectedCentre).then(res => {
            let data = res.docs.map(c => c.data() as FullChild)
          this.childData=[]
            console.log(data)
            data.forEach(c => {
                let x: any = {
                    firstName: c.firstName,
                    lastName: c.lastName,
                    childCode: c.childCode,
                    centreCode: c.centreCode,
                    centreName: c.centreName,
                    unit: c.selectUnit,
                    diagnosis: c.diagnosisSelected,
                    childCity: c.city,
                    childState: c.state,
                    dob: c.dob,
                  sponsored:c.sponsored

                }
                this.childData.push(x)

            })
            this.datasource = new MatTableDataSource<any>(this.childData)

            console.log(this.childData.length)
        })
    }

    @needConfirmation()
    addData(child: any) {
        let oldObject = {
            centreCode: this.mData.centreCode,
            centreName: this.mData.centreName,
            childCode: this.mData.childCode,
            startDate: this.mData.date,
            endDate: this.mData.date2,
            firstName: this.mData.firstName,
            lastName: this.mData.lastName,
            unit: this.mData.unit
        }
        let transferObj = {
            centreCode: child.centreCode,
            centreName: child.centreName,
            childCode: child.childCode,
            startDate: child.startDate,
            endDate: child.endDate,
            firstName: child.firstName,
            lastName: child.lastName,
            unit: child.unit,
            // lastEndDate:this.endDatePicker //Old Orginal code
            lastEndDate:child.endDate     ///Temporary need to rectify this
        }
        let mergedObject = {
            oldChild: oldObject, ...transferObj
        }

        this.mService.updateSponsoredChildren(this.mData.transactionId,mergedObject)
        // return  this.mFirestore.collection('sponsoredChildren').doc(this.mData.transactionId).update(mergedObject)
            .then(() => {
              console.log('Into then')
                this.fireDatabase.collection(this.env.child).doc(child.childCode).update({sponsored:true})
                this.fireDatabase.collection(this.env.child).doc(this.mData.childCode).update({sponsored:false})
                console.log('Data added to Firestore successfully.');
              let activity={
                date:new Date().getTime(),
                section: 'Unit Sponsorship',
                action: 'Update',

                description: `Transfer Unit Sponsorship Updated by`,
                currentIp: localStorage.getItem('currentip')!
              }
              this.mLogService.addLog(activity)
                // Optionally, you can perform any other actions after successful storage.
              this.dialogReg.close()
            })
            .catch((error) => {
                console.error('Error adding data to Firestore:', error);
            });
      this.dialogReg.close()
    }

    onSubmit() {
        //
        // let c=  { firstName: c.firstName,
        //      lastName: c.lastName,
        //      childCode: c.childCode,
        //      centreCode: c.centreCode,
        //      centreName: c.centreName,
        //      unit: c.selectUnit,
        //      diagnosis: c.diagnosisSelected,
        //      childCity: c.city,
        //      childState: c.state,
        //      dob: c.dob
        // }
        // let old=this.mData
        //
        //  const mergedObject = {...it, ...this.mData};
        // mergedObject.totalAmountUsed = this.totalAmount

        // this.fireDatabase.collection('sponsoredChildren').add(mergedObject)
        //   .then(() => {
        //     console.log('Data added to Firestore successfully.');
        //     // Optionally, you can perform any other actions after successful storage.
        //   })
        //   .catch((error) => {
        //     console.error('Error adding data to Firestore:', error);
        //   });

        let remainingAmount = this.mData.totalTransactionAmount - this.totalAmount
        this.mService.updateTransactionAmount(this.mData.transactionId,remainingAmount)
        .then(() => {

        })
    }
}
