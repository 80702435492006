<div class="card mt-2  p-3 ">
    <h5 class="header">Hospital Data (By Hospital)</h5>
<canvas  baseChart

  [datasets]="barChartData"
  [labels]="barChartLabels"
  [options]="barChartOptions"
  [plugins]="barChartPlugins"
  [legend]="barChartLegend"
  [type]="barChartType">
</canvas>
</div>
