<section class="">
  <div class="">

    <!-- Your content goes here  -->
    <div class="row clearfix">
      <div class="col-lg-4 col-md-12">
        <div class="card">
          <div class="m-b-20">
            <div class="contact-grid">
              <div class="profile-header l-bg-card2">
                <div class="user-name"><strong>{{childInfo.firstName}} {{childInfo.lastName}}</strong></div>
                <div class="text-white my-1 "><strong>AL CHILD CODE: {{childInfo.childCode}}</strong></div>
                <div class="text-white"><strong>Base center: {{childInfo.centreName}}</strong></div>
              </div>
              <img *ngIf="childInfo.admissionDocuments && childInfo.admissionDocuments.length > 0"
                   [src]="getChildPhotoUrl(childInfo.admissionDocuments)"
                   class="user-img" alt="">
              <p>
                <strong>Date of Birth : </strong><span
                class="text-muted">{{(childInfo.dob).toDate() | date:'dd/MM/yyyy'}}</span>
              </p>
              <p>
                <strong>Age : </strong>
                <span class="text-muted">{{calculateAge(childInfo.dob)}}</span>
              </p>
              <p>
                <strong>Gender : </strong>
                <span class="text-muted">{{childInfo.gender}}</span>
              </p>
              <p>
                <strong>Hobbies : </strong>
                <span class="text-muted">{{childInfo.hobbies}}</span>
              </p>
              <p>
                <strong>Blood Group : </strong>
                <span class="text-muted">{{childInfo.bloodGroup}}</span>
              </p>
              <p>
                <strong>Height : </strong>
                <span class="text-muted">{{childInfo.height}}</span>
              </p>
              <p>
                <strong>Weight : </strong>
                <span class="text-muted">{{childInfo.weight}}</span>
              </p>

            
            </div>
          </div>
        </div>
        <div class="card">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Admission Records">
              <div class="p-4">
                <!--                <h4 class="text-default">Family Section </h4>-->
                <!--                <p class="text-muted mb-0" *ngFor="let g of childInfo.guardians">-->
                <!--                  <span *ngIf="g.relation === 'Mother'">Mother:</span>-->
                <!--                  <span *ngIf="g.relation === 'Father'">Father:</span>-->
                <!--                  <span *ngIf="g.relation === 'GrandFather'">Grand Father:</span>-->
                <!--                  <span *ngIf="g.relation === 'Grand Mother'">Grand Mother:</span>-->
                <!--                  {{g.firstName}} {{g.lastName}}-->
                <!--                </p>-->
                <!--                <hr>-->

                <!--                <div class="row">-->
                <!--                  <div class="col-md-5">-->
                <!--                    <small class="text-muted">Admission Date: </small>-->
                <!--                    <p>{{(childInfo.dateOfAdmission).toDate() | date:'dd/MM/yyyy'}}</p>-->
                <!--                  </div>-->
                <!--                  <div *ngIf="childInfo.dischargeDate" class="col-md-4">-->
                <!--                    <small class="text-muted">Discharge Date: </small>-->
                <!--                    <p>{{(childInfo.dischargeDate).toDate() | date:'dd/MM/yyyy'}}</p>-->
                <!--                  </div>-->
                <!--                  <div *ngIf="childInfo.dischargeDate" class="col-md-3">-->
                <!--                    <small class="text-muted">Child Stay</small>-->
                <!--                    <p>{{daysDifference}} Days</p>-->
                <!--                  </div>-->
                <!--                </div>-->

                <section>
                  <div class="w-100">
                    <mat-table [dataSource]="datasource" matSort class="table table-bordered">
                      <!-- Add your columns here -->
                      <!--                      <ng-container matColumnDef="sr">-->
                      <!--                        <mat-header-cell *matHeaderCellDef mat-sort-header> Sr </mat-header-cell>-->
                      <!--                        <mat-cell *matCellDef="let row;let i =index"> {{i+1}} </mat-cell>-->
                      <!--                        <mat-footer-cell mat-footer-cell *matFooterCellDef>  </mat-footer-cell>-->

                      <!--                      </ng-container>-->


                      <ng-container matColumnDef="dateOfAdmission">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Admission</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.admissionDate|date:'dd/MM/yyyy'}} </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>

                      </ng-container>
                      <ng-container matColumnDef="dateOfDischarge">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Discharge</mat-header-cell>
                        <mat-cell *matCellDef="let row"> {{row.dischargeDate|date:'dd/MM/yyyy'}} </mat-cell>
                        <mat-footer-cell *matFooterCellDef></mat-footer-cell>

                      </ng-container>
                      <ng-container matColumnDef="duration">
                        <mat-header-cell *matHeaderCellDef mat-sort-header> Duration</mat-header-cell>
                        <mat-cell *matCellDef="let row">
                          <ng-container *ngIf="row.treatmentDuration !== 0">{{ row.treatmentDuration }}</ng-container>
                        </mat-cell>
                        <mat-footer-cell *matFooterCellDef>
                          {{ calculateTotalDuration(datasource.data) }}
                        </mat-footer-cell>
                      </ng-container>

                      <!-- Add other columns similarly -->

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
                      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>

                    </mat-table>

                    <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"></mat-paginator>
                  </div>
                </section>
                <hr>
              </div>
            </mat-tab>
            <mat-tab label="Treatment Records">
              <div class="p-4">
                <!--                <div class="row">-->
                <!--                  <h4 class="text-default">Medical Section </h4>-->
                <!--                  <div class="col-md-4" *ngIf="childInfo.diagnosisSelected && childInfo.diagnosisSelected.length > 0">-->
                <!--                    <small class="text-muted">Diagnosis: </small>-->
                <!--                    <ng-container *ngFor="let diagnosis of childInfo.diagnosisSelected">-->
                <!--                      <p *ngIf="isString(diagnosis)">{{ diagnosis }}</p>-->
                <!--                      <p *ngIf="isObject(diagnosis)">{{ diagnosis.name }}</p>-->
                <!--                    </ng-container>-->
                <!--                  </div>-->

                <!--                  <div class="col-md-4">-->
                <!--                    <small class="text-muted">Blood Group: </small>-->
                <!--                    <p>{{childInfo.bloodGroup}}</p>-->
                <!--                  </div>-->

                <!--                  <div class="col-md-4">-->
                <!--                    <small class="text-muted">Docter Name: </small>-->
                <!--                    <p>{{childInfo.doctorName}}</p>-->
                <!--                  </div>-->
                <!--                  <hr>-->
                <!--                  <div class="col-md-4">-->
                <!--                    <small class="text-muted">Allergies: </small>-->
                <!--                    <p>{{childInfo.allergies}}</p>-->
                <!--                  </div>-->
                <!--                </div>-->
                <!--                <hr>-->
                <table class="table table-bordered">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Height</th>
                    <th>Weight</th>
                  </tr>
                  </thead>
                  <tbody>


                  <ng-container *ngIf="  childInfo?.medical_history!=undefined ">

                 <tr *ngFor="let h of childInfo?.medical_history ">
                      <td>{{ h.res_timing | date:'dd/MM/yyyy'}} </td>
                      <td>{{h.height}}</td>
                      <td>{{h.weight}}</td>
                 </tr>


                  </ng-container>




                  </tbody>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card">
          <mat-tab-group>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">face</mat-icon>
                About Me
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>About</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-md-4  ">
                        <strong>Full Name</strong>
                        <!--                        <br>-->
                        <p
                          class="text-muted"> {{childInfo.firstName}} {{childInfo.middleName}} {{childInfo.lastName}}</p>
                      </div>

                      <div class="col-md-4  ">
                        <strong>Family</strong>
                        <!--                        <br>-->
                        <p class="text-muted mb-0" *ngFor="let g of childInfo.guardians">
                          <span *ngIf="g.relation === 'Mother'">Mother:</span>
                          <span *ngIf="g.relation === 'Father'">Father:</span>
                          <span *ngIf="g.relation === 'GrandFather'">Grand Father:</span>
                          <span *ngIf="g.relation === 'Grand Mother'">Grand Mother:</span>
                          {{g.firstName}} {{g.lastName}}
                        </p>
                      </div>

                      <div class="col-md-4  ">
                        <strong>Age as on Admission</strong>
                        <!--                        <br>-->
                        <p class="text-muted"> {{childInfo?.ageAsOnAdmission||'NA'}}</p>
                      </div>
<!--                      <div class="col-md-4  ">-->
<!--                        <strong>City</strong>-->
<!--                        &lt;!&ndash;                        <br>&ndash;&gt;-->
<!--                        <p class="text-muted"> {{childInfo.city}}</p>-->
<!--                      </div>-->
                      <div class="col-md-4  ">
                        <strong>State</strong>
                        <!--                        <br>-->
                        <p class="text-muted"> {{childInfo.state}}</p>
                      </div>
                      <!--                      <div class="col-md-4  ">-->
                      <!--                        <strong>Mobile</strong>-->
                      <!--&lt;!&ndash;                        <br>&ndash;&gt;-->
                      <!--                        <p class="text-muted">{{childInfo.mobile}}</p>-->
                      <!--                      </div>-->
                      <!--                      <div class="col-md-4  ">-->
                      <!--                        <strong>Caretaker Name</strong>-->
                      <!--&lt;!&ndash;                        <br>&ndash;&gt;-->
                      <!--                        <p class="text-muted"></p>-->
                      <!--                      </div>-->
                      <!--                      <div class="col-md-4  ">-->
                      <!--                        <strong>Caretaker Contact</strong>-->
                      <!--&lt;!&ndash;                        <br>&ndash;&gt;-->
                      <!--                        <p class="text-muted"></p>-->
                      <!--                      </div>-->
                      <!--                      <div class="col-md-4  ">-->
                      <!--                        <strong>Emergency Contact 1</strong>-->
                      <!--&lt;!&ndash;                        <br>&ndash;&gt;-->
                      <!--                        <p class="text-muted">{{childInfo.emergencyContactNumber1}}</p>-->
                      <!--                      </div>-->
                      <!--                      <div class="col-md-4  ">-->
                      <!--                        <strong>Emergency Contact 2</strong>-->
                      <!--&lt;!&ndash;                        <br>&ndash;&gt;-->
                      <!--                        <p class="text-muted">{{childInfo.emergencyContactNumber2}}</p>-->
                      <!--                      </div>-->
                      <div class="col-md-4  ">
                        <strong>Center Name</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{childInfo.centreName}}</p>
                      </div>
                      <div class="col-md-4  ">
                        <strong>Unit No</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{childInfo.selectUnit}}</p>
                      </div>
                      <!--                      <div class="col-md-4  ">-->
                      <!--                        <strong>Status</strong>-->
                      <!--&lt;!&ndash;                        <br>&ndash;&gt;-->
                      <!--                        <p class="text-muted">{{childInfo.status}}</p>-->
                      <!--                      </div>-->
                      <div class="col-md-4 ">
                        <strong>Hospital</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{childInfo.hospital}}</p>
                      </div>

                      <div class="col-md-4 ">
                        <strong>Cancer</strong>
                        <!--                        <br>-->
                        <ng-container *ngFor="let diagnosis of childInfo.diagnosisSelected">
                          <p *ngIf="isString(diagnosis)">{{ diagnosis }}</p>
                          <p *ngIf="isObject(diagnosis)">{{ diagnosis.name }}</p>
                        </ng-container>
                      </div>
                      <div class="col-md-4 ">
                        <strong>Doctor Name</strong>
                        <!--                        <br>-->
                        <p class="text-muted">{{childInfo.doctorName}}</p>
                      </div>


                    </div>
                    <br>
                  </div>
                </div>
              </div>
              <hr>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Education Session</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-lg-5 col-sm-6">
                        <div class="info-box7 l-bg-purple order-info-box7">
                          <div class="info-box7-block">
                            <!--                            <h4 class="m-b-5">Session</h4>-->
                            <h2 class="text-end"><i class="fas fa-chart-line float-start mt-2 mx-2"></i><span></span>
                            </h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                              <div> Individual Session<br></div>
                              <div> {{educationSession.length}} <br></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-5 col-sm-6">
                        <div class="info-box7 l-bg-card1 order-info-box7">
                          <div class="info-box7-block">
                            <!--                            <h4 class="m-b-20">Session</h4>-->
                            <h2 class="text-end"><i class="fas fa-smile float-start mt-2 mx-2"></i><span></span></h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                              <div> Group Session <br></div>
                              <div> {{childEducationGroupSessionCount}} <br></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Counselling Session</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-lg-5 col-sm-6">
                        <div class="info-box7 l-bg-purple order-info-box7">
                          <div class="info-box7-block">
                            <!--                            <h4 class="m-b-5">Session</h4>-->
                            <h2 class="text-end"><i class="fas fa-chart-line float-start mt-2 mx-2"></i><span></span>
                            </h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                              <div>Individual Session <br></div>
                              <div> {{counsellingSession.length}} <br></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-5 col-sm-6">
                        <div class="info-box7 l-bg-card1 order-info-box7">
                          <div class="info-box7-block">
                            <!--                            <h4 class="m-b-5">Session</h4>-->
                            <h2 class="text-end"><i class="fas fa-smile float-start mt-2 mx-2"></i><span></span></h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                              <div> Group Session <br></div>
                              <div> {{childGroupSessionCount}} <br></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">face</mat-icon>
                Child Photos
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Child Photos</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row row-gap-2">
                      <div *ngFor="let file of childInfo?.admissionDocuments" class="col-md-6 ">
                        <img src="{{file.fileUrl}}" *ngIf="file.description =='Child Photo' || file.description=='Family Photo'"  alt="" height="300px" class="childphoto card">

                      </div>
                      <div *ngFor="let file of childInfo?.dischargeDocuments" class="col-md-6 ">
                        <img src="{{file.fileUrl}}" *ngIf="file.description =='Child Photo' || file.description=='Family Photo'"  alt="" height="300px" class="childphoto card">

                      </div>
                      <div *ngFor="let file of childInfo?.followUpAdmissionDocuments" class="col-md-6 ">
                        <img src="{{file.fileUrl}}" *ngIf="file.description =='Child Photo' || file.description=='Family Photo'"  alt="" height="300px" class="childphoto card">

                      </div>
                      <div *ngFor="let file of childInfo?.followUpDischargeDocuments" class="col-md-6 ">
                        <img src="{{file.fileUrl}}" *ngIf="file.description =='Child Photo' || file.description=='Family Photo'"  alt="" height="300px" class="childphoto card">

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">face</mat-icon>
                Child Story
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Child Story</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row ">
                      <p class="text-muted">
                        {{childInfo.sickness}}
                      </p>


                    </div>

                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab>
              <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon msr-2">face</mat-icon>
                Remarks
              </ng-template>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="project_widget">
                  <div class="header">
                    <h2><strong>Remarks</strong></h2>
                  </div>
                  <div class="body">
                    <div class="row ">
                      <strong>Remark</strong>
                      <p class="text-muted">
                        {{childInfo.remark}}
                      </p>
                      <strong>Reason</strong>
                      <p class="text-muted">
                        {{childInfo.reason}}
                      </p>
                      <div class=" mt-2">

                        <div class="red">*Note: Select Weight or Height</div>
                        <ng-container *ngIf="this.show && this.showChart && this.height.length>=1 && this.dates.length>=1 && this.weight.length>=1">
<!--                          <app-apex-age-chart *ngIf="height.length>1 && show" [ageArrayInput]="weight" [followUpArray]="height"  ></app-apex-age-chart>-->
                          <apx-chart *ngIf="this.show" [series]="areaChartOptions.series!" [chart]="areaChartOptions.chart!"
                                     [xaxis]="areaChartOptions.xaxis!" [yaxis]="areaChartOptions.yaxis!"
                                     [colors]="areaChartOptions.colors!"
                                     [stroke]="areaChartOptions.stroke!" [legend]="areaChartOptions.legend!"
                                     [grid]="areaChartOptions.grid!"
                                     [tooltip]="areaChartOptions.tooltip!"
                                     [dataLabels]="areaChartOptions.dataLabels!"></apx-chart>
                        </ng-container>
                      </div>


                    </div>


                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>

