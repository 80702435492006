<mat-card class="bg-warning text-dark">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title><strong>{{ mData.firstName }} {{mData.lastName}}</strong></mat-card-title>
    <mat-card-subtitle><strong> {{ mData.unit }}</strong>  </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex justify-content-between">
      <div class=" ">
        <div>Sponsorship Program: <strong> {{ mData.duration }}</strong></div>
        <div>Donor Name:<strong> {{mData.donorName}}</strong></div>
        <div>Mobile Number:<strong> {{mData.mobile}}</strong></div>
        <div>Payment Terms:<strong> {{mData.paymentInformation}}</strong></div>

      </div>

      <div class="d-flex justify-content-between ">

      </div>
      <div class=" ">
        <div>Start Date <strong> {{ mData.startDate.toDate() | date: 'dd/MM/yyyy' }}</strong></div>

        <div>End Date <strong> {{ mData.endDate.toDate() | date: 'dd/MM/yyyy' }}</strong></div>
        <div>
          <mat-form-field>
            <mat-label>End date</mat-label>
            <input matInput [matDatepicker]="picker" [(ngModel)]="endDatePicker" [value]="mData.endDate.toDate()">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

      </div>
    </div>
  </mat-card-content>
</mat-card>



<div>
  <h2>Select Centre:</h2>
  <mat-form-field appearance="outline">
    <mat-label>Centre</mat-label>
    <mat-select [(ngModel)]="selectedCentre" name="selectedCentre" (selectionChange)="onCentreSelect($event)">
      <mat-option *ngFor="let centre of centres$ | async" [value]="centre.centreCode">         {{ centre.centreName }}</mat-option>

      <mat-option  value="All">All Centres</mat-option>

    </mat-select>
  </mat-form-field>
</div>

<div class="table-container">
  <table mat-table [dataSource]="datasource" class="mat-elevation-z8" matSort>

    <!-- First Name Column -->
    <ng-container matColumnDef="sr">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Sr </th>
      <td mat-cell *matCellDef="let child;let i= index" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ i+1 }} </td>
    </ng-container>


    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
      <td mat-cell *matCellDef="let child" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.firstName }} </td>
    </ng-container>

    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
      <td mat-cell *matCellDef="let child" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.lastName }} </td>
    </ng-container>

    <!-- Unit Column -->
    <ng-container matColumnDef="selectUnit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
      <td mat-cell *matCellDef="let child" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.unit }} </td>

    </ng-container>

    <!-- Centre Name Column -->
    <ng-container matColumnDef="centreName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Centre Name </th>
      <td mat-cell *matCellDef="let child" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }"> {{ child.centreName }} </td>
    </ng-container>


    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let child" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }">
        <mat-form-field *ngIf="child.sponsored!=true" >
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="Start date"  [(ngModel)]="child.startDate" [disabled]="this.selectedChild.includes(child)" >
            <input matEndDate placeholder="End date" [(ngModel)]="child.endDate" [disabled]="this.selectedChild.includes(child)">
          </mat-date-range-input>
          <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <span *ngIf="child.sponsored==true">Sponsored</span>
      </td>

    </ng-container>



    <ng-container matColumnDef="transfer" >
      <th  mat-header-cell *matHeaderCellDef mat-sort-header>Add</th>
      <td  mat-cell *matCellDef="let child" [ngClass]="{ 'sponsored-child': child.sponsored, 'non-sponsored-child': !child.sponsored }">
        <button *ngIf="!this.selectedChild.includes(child) && this.mData.amount!=this.totalAmount  && child.startDate!=null && child.endDate!=null" mat-raised-button color="primary" (click)="addData(child)">Transfer</button>
      </td>
    </ng-container>


    <!-- Define the table rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>


</div>
<!--<div class="text-center">-->
<!--  <button mat-raised-button color="primary" class="w-25" [disabled] = "this.mData.remainingAmount<totalAmount && this.totalAmount !=0" (click)="onSubmit()">Submit</button>-->
<!--</div>-->




