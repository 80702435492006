import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {DonorService} from "../donor.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {donor} from "../donordata";
import {LogService} from "../../../services/log.service";
import {formControl} from "@angular/core/schematics/migrations/typed-forms/util";

@Component({
  selector: 'app-donoradmin',
  templateUrl: './add-donor.component.html',
  styleUrls: ['./add-donor.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})

export class AddDonorComponent implements OnInit {
  submitted = false;
  DonorForm: any;

  constructor(public dialogRef: MatDialogRef<AddDonorComponent>,
              private donorservice: DonorService,
              private readonly snackBar:MatSnackBar,
              private readonly mLogService:LogService,

  ) {
  }

  ngOnInit(): void {
    // alert('donor form')
    this.DonorForm = new FormGroup({
      donorName: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      address: new FormControl('', [Validators.required]),
      dob: new FormControl('', ),
      donorMobile: new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
      alternateNumber: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(10), Validators.maxLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      pan: new FormControl('', [Validators.required, Validators.pattern("([A-Z]){5}([0-9]){4}([A-Z]){1}$"), Validators.minLength(10), Validators.maxLength(10)]),
      donorCity: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      country: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      state: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      pincode: new FormControl('', [Validators.required, Validators.pattern('[0-9 ]*'), Validators.minLength(6), Validators.maxLength(6)]),
      createdAt: new FormControl(new Date()),
      donorType: new FormControl('', Validators.required),
      // "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      // "type": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      // "support": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    })
  }
  // get timestamp() {
  //   return  firebase.default.firestore.FieldValue.serverTimestamp()
  //
  // }
  SubmitData() {
//first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    if(this.DonorForm.valid){
      // then submit data to service
      this.donorservice.addDonor(this.DonorForm.value, this.pan.value).then(()=>{
        let activity={
          date:new Date().getTime(),
          section: 'Donor',
          action: 'Add',
          description: `Session Added in Donor by user` ,
          currentIp: localStorage.getItem('currentip')!
        }
        this.mLogService.addLog(activity)

        this.dialogRef.close()
      })

    }
    else{
      this.snackBar.open('Please fill the form properly','')._dismissAfter(3000)
    }
    console.log(this.DonorForm.value);
//then close dialog

  }

  cancel() {
    this.dialogRef.close()
    this.submitted = false;
    console.log(this.DonorForm.reset())
  }

  get donated_by() {
    return this.DonorForm.get('donorName');
  }
  get mEmail() {
    return this.DonorForm.get('email');
  }

  get address() {
    return this.DonorForm.get('address');
  }




  get pan() {
    return this.DonorForm.get('pan');
  }

  get donorMobile() {
    return this.DonorForm.get('donorMobile');
  }
  get alternateNumber() {
    return this.DonorForm.get('alternateNumber');
  }

  get city() {
    return this.DonorForm.get('donorCity');
  }

  get country() {
    return this.DonorForm.get('country');
  }

  get state() {
    return this.DonorForm.get('state');
  }

  get pincode() {
    return this.DonorForm.get('pincode');
  }


}
