import { Component, OnInit } from '@angular/core';
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'app-child-health',
  templateUrl: './child-health.component.html',
  styleUrls: ['./child-health.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class ChildHealthComponent implements OnInit {
  childguardianForm: any;
  submitted = false;
  constructor() { }

  ngOnInit(): void {
    this. childguardianForm = new FormGroup({
      "doctorname": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "fileno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "socialname": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "height": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "weight": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "prescribed": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "sickness": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "treatment": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "medical": new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z]*')]),
      "hospital": new FormControl('', [Validators.required]),
      "allergies": new FormControl('', [Validators.required]),
      "diagnosis": new FormControl('', [Validators.required]),
      "months": new FormControl('', [Validators.required]),
      "days": new FormControl('', [Validators.required]),
    })
  }


  hospital: string[] = [
    'None',
    'Yes',
    'No',
  ];

  allergies:string[] = [
    'None',
    'Yes',
    'No',
  ];

  diagnosis: string[] = [
    'None',
    'Yes',
    'No',
  ];

  months:string[] = [
    'None',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

 // days = [  'o'  ,'1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

 // 'Tuesday',
    // 'Wednesday',
    // 'Thursday',
    // 'Friday',
    // 'Saturday',
    // 'Sunday',





  get doctorname() {
    return this. childguardianForm.get('doctorname');
  }

  get fileno() {
    return this. childguardianForm.get('fileno');
  }

  get socialname() {
    return this. childguardianForm.get('socialname');
  }

  get height() {
    return this. childguardianForm.get('height');
  }

  get weight() {
    return this. childguardianForm.get('weight');
  }

  get prescribed() {
    return this. childguardianForm.get('prescribed');
  }


  get sickness() {
    return this. childguardianForm.get('sickness');
  }

  get treatment() {
    return this. childguardianForm.get('treatment');
  }

  get medical() {
    return this. childguardianForm.get('medical');
  }




  SubmitData() {
    //first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    // if(form.isValid){
    //   then submit data to service
    // }

    // this.childService.addTempChild(this. childguardianForm.value)

    console.log(this. childguardianForm.value);
//then close dialog
//     this.dialogRef.close()
  }

  cancel() {
    // this.dialogRef.close()
    this.submitted = false;
    console.log(this. childguardianForm.reset())
  }
}
