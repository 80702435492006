<!-- donor-table.component.html -->

<div class="container mt-4">
  <h2>Online Donor Information</h2>
  <table class="table table-active">
    <tbody>
    <tr>
      <th>Donor Mobile</th>
      <td>{{ donors.donorMobile }}</td>
    </tr>
    <tr>
      <th>Donor Name</th>
      <td>{{ donors.donorName }}</td>
    </tr>
    <tr>
      <th>Donor Address</th>
      <td>{{ donors.donoraddress }}</td>
    </tr>
    <tr>
      <th>Donor City</th>
      <td>{{ donors.donorcity }}</td>
    </tr>
    <tr>
      <th>Email</th>
      <td>{{ donors.email }}</td>
    </tr>
    <tr>
      <th>PAN</th>
      <td>{{ donors.pan }}</td>
    </tr>
    </tbody>

  </table>
</div>
