import { Component, OnInit } from '@angular/core';
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-childguardian',
  templateUrl: './childguardian.component.html',
  styleUrls: ['./childguardian.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class ChildguardianComponent implements OnInit {
   childguardianForm: any;
  submitted = false;


  constructor() { }

  ngOnInit(): void {
    this. childguardianForm = new FormGroup({
      "documents": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "drivinglicense": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "rationcard": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "electionid": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      "firstname": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "lastname": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "middlename": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "aadhaarnumber": new FormControl('', [Validators.required, Validators.pattern('[0-9]{12}$'),Validators.maxLength(12),Validators.minLength(12)]),
      "relation": new FormControl('', [Validators.required]),
      "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
      "contactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'),Validators.maxLength(10),Validators.minLength(10)]),
      "altcontactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'),Validators.maxLength(10),Validators.minLength(10)]),
      "skill": new FormControl('', [Validators.required]),
    })
  }


  skill: string[] = [
    'None',
   'Driving',
    'Teaching',
    'Carpenting',
    'Photography',
    'Mobile Reparing',
    'AC reparing',
    'Farming'
  ];

  relation: string[] = [
    '--Select--',
    'Father',
    'Mother',
    'Brother',
    'Sister',
    'Paternal Uncle',
    'Paternal Aunt',
    'Maternal Aunt',
    'Paternal Grandfather',
    'Paternal Grandmother',
    'Maternal Grandfather',
    'Maternal Grandmother'
  ];

  get documents() {
    return this. childguardianForm.get('documents');
  }

  get altcontactno() {
    return this. childguardianForm.get('altcontactno');
  }

  get firstname() {
    return this. childguardianForm.get('firstname');
  }

  get lastname() {
    return this. childguardianForm.get('lastname');
  }

  get rationcard() {
    return this. childguardianForm.get('rationcard');
  }

  get middlename() {
    return this. childguardianForm.get('middlename');
  }


  get drivinglicense() {
    return this. childguardianForm.get('drivinglicense');
  }

  get occupation() {
    return this. childguardianForm.get('occupation');
  }

  get electionid() {
    return this. childguardianForm.get('electionid');
  }

  get contactno() {
    return this. childguardianForm.get('contactno');
  }

  get aadhaarnumber() {
    return this. childguardianForm.get('aadhaarnumber');
  }


  SubmitData() {
    //first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    // if(form.isValid){
    //   then submit data to service
    // }

    // this.childService.addTempChild(this. childguardianForm.value)

    console.log(this. childguardianForm.value);
//then close dialog
//     this.dialogRef.close()
  }

  cancel() {
    // this.dialogRef.close()
    this.submitted = false;
    console.log(this. childguardianForm.reset())
  }
}
