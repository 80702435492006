import {Component, Input} from '@angular/core';
import {FileUpload} from "./file-upload";
import {FileUploadService} from "./upload.service";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent {
  @Input() pathToDatabase:string|undefined
  @Input() dbType?:string='realtime'
  @Input ()storagePath?: string ='uploads/'

  selectedFiles!: FileList;
  currentFileUpload!: FileUpload;
  percentage!: number;


  constructor(private uploadService: FileUploadService) { }

  ngOnInit(): void {
  }

  // @ts-ignore
  selectFile(event): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    const file = this.selectedFiles.item(0);
    // @ts-ignore
    this.selectedFiles = undefined;

    // @ts-ignore
    this.currentFileUpload = new FileUpload(file);
  if(this.storagePath!='uploads/'){
    this.uploadService.pushFileToStorageWith(this.currentFileUpload,this.pathToDatabase,this.dbType!,this.storagePath!).subscribe(
        percentage => {
          this.percentage = Math.round(percentage);
        },
        error => {
          console.log(error);
        }
    );
  }
  else if (this.storagePath=='uploads/'){
    this.uploadService.pushFileToStorage(this.currentFileUpload,this.pathToDatabase,this.dbType!).subscribe(
      percentage => {
        this.percentage = Math.round(percentage);
      },
      error => {
        console.log(error);
      }
    );}
  }
}
