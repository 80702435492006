<!--<div class="d-flex justify-content-end mb-4 ">-->
<!--  <button  mat-raised-button color="accent" *ngIf="unitSponsorship[0].all || unitSponsorship[0].add" class="ms-3 mt-3" (click)="AddUnitSponsorship()">+ Unit Sponsorship</button>-->
<!--</div>-->
<div class="card p-2">
<div class="ngxTableHeader">
  <ul class="header-buttons-left ms-0 mb-0">
    <li class="dropdown">
      <h2>Sponsor</h2>
    </li>
    <!--      <li class="dropdown m-l-20">-->
    <!--        <label for="search-input"><i class="material-icons search-icon">search</i></label>-->
    <!--        <input placeholder="Search" type="text" class="browser-default search-field"-->
    <!--               (keyup)='applyFilter($event)' aria-label="Search box" #input>-->
    <!--      </li>-->
  </ul>
  <ul class="header-buttons mr-2">
    <li>
      <button   mat-mini-fab color="primary" *ngIf="unitSponsorship[0].all || unitSponsorship[0].add" class="ms-3 mt-3" (click)="AddUnitSponsorship()" >
        <mat-icon class="col-white" >add</mat-icon>
      </button>
    </li>
    <!--        <li>-->
    <!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
    <!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
    <!--          </div>-->
    <!--        </li>-->
  </ul>
</div>

<div class="">
  <div class="">
    <div class="col-lg-12 col-md-12">
      <table mat-table [dataSource]="dataSource" class="" matSort>

        <ng-container matColumnDef="donorName" >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Donor Name</th>
          <td mat-cell *matCellDef="let element">{{ element.donorName }}</td>
        </ng-container>
        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef>Mobile No.</th>
          <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let element">{{ element.address }}</td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let element">{{ element.state }}</td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>Country</th>
          <td mat-cell *matCellDef="let element">{{ element.country }}</td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>City</th>
          <td mat-cell *matCellDef="let element">{{ element.city }}</td>
        </ng-container>
        <ng-container matColumnDef="pincode">
          <th mat-header-cell *matHeaderCellDef>Pin code</th>
          <td mat-cell *matCellDef="let element">{{ element.pincode }}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{ element.email }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>From</th>
          <td mat-cell *matCellDef="let element">{{ element.date.toDate()| date }}</td>
        </ng-container>
        <ng-container matColumnDef="date2">
          <th mat-header-cell *matHeaderCellDef>To</th>
          <td mat-cell *matCellDef="let element">{{ element.date2.toDate() | date }}</td>
        </ng-container>
        <ng-container matColumnDef="angleName">
          <th mat-header-cell *matHeaderCellDef>Angle Name</th>
          <td mat-cell *matCellDef="let element">{{ element.angleName }}</td>
        </ng-container>
        <ng-container matColumnDef="paymentInformation">
          <th mat-header-cell *matHeaderCellDef>Payment Information</th>
          <td mat-cell *matCellDef="let element">{{ element.paymentInformation }}</td>
        </ng-container>
        <ng-container matColumnDef="transactionID">
          <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
          <td mat-cell *matCellDef="let element">{{ element.remark }}</td>
        </ng-container>
        <ng-container matColumnDef="transactionAmount">
          <th mat-header-cell *matHeaderCellDef>Total Transaction Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.totalTransactionAmount }}</td>
        </ng-container>
        <ng-container matColumnDef="remainingAmount">
          <th mat-header-cell *matHeaderCellDef>Remaining Amount</th>
          <td mat-cell *matCellDef="let element">{{ element.remainingAmount }}</td>
        </ng-container>
        <ng-container matColumnDef="panNumber">
          <th mat-header-cell *matHeaderCellDef>Pan Number</th>
          <td mat-cell *matCellDef="let element">{{ element.panNumber }}</td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell  *matCellDef="let element" >
          <div class="d-flex">
            <button mat-icon-button color="accent" matTooltip="View" (click)="openViewDialog(element)"><mat-icon>visibility</mat-icon></button>
            <button mat-raised-button *ngIf="unitSponsorship[0].all || unitSponsorship[0].add" [disabled]="element.remainingAmount==0" (click)="showChild(element)">Allot

            </button>
          </div> </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>
</div>
</div>
