import {Component, OnInit} from '@angular/core';
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private mAuth: AuthService) {
  localStorage.clear()
    this.mAuth.SignOut()
  }

  ngOnInit(): void {
  }

}
