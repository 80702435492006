<div class="container mt-5 mis-dashboard">

    <div class="row " >
        <div class="row mis-dashboard">

            <div class="col-md-12 col-sm-12 mb-4 card">
                <h3 class="text-center rounded text-white mt-3">Occupancy Report</h3>
                <table mat-table [dataSource]="dataSource" matSort>

                    <!-- Position Column -->
                    <ng-container matColumnDef="centername">
                        <th mat-header-cell *matHeaderCellDef> Centre Name</th>
                        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"
                            [matTooltip]="element.centreCode"> {{element?.centreName}} </td>
                        <td mat-footer-cell *matFooterCellDef><strong> Total</strong> </td>

                    </ng-container>


                    <!-- Available Units  Column -->
                    <ng-container matColumnDef="availableunits">
                        <th mat-header-cell *matHeaderCellDef> Available </th>
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.availableUnits}}   </td>
                        <td mat-footer-cell *matFooterCellDef> <strong>{{lastRow?.availableUnits}}</strong> </td>

                    </ng-container>
                    <!-- Weight Column -->
                    <ng-container matColumnDef="occupiedunits">
                        <th mat-header-cell *matHeaderCellDef> Occupied </th>
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.occupiedUnits}} </td>
                        <td mat-footer-cell *matFooterCellDef><strong> {{lastRow?.occupiedUnits}}</strong> </td>

                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="totalunits">
                        <th mat-header-cell *matHeaderCellDef> Total Rooms</th>
                        <td mat-cell *matCellDef="let element"
                            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.totalUnits}} </td>
                        <td mat-footer-cell *matFooterCellDef><strong> {{lastRow?.totalUnits}}</strong> </td>

                    </ng-container>
                    <ng-container matColumnDef="percentage">
                        <th mat-header-cell *matHeaderCellDef> Occupancy %</th>
                        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{getPercentage(element)}}%</span></td>
                        <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>

                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr *matNoDataRow class="mat-row">
                        <!--                <td class="mat-cell" colspan="4"  >No data matching the filter "{{input.value}}"</td>-->
                    </tr>
                </table>
            </div>

            <hr>

            <!*--------------------------------------------- Selection --------------------------------------------->
            <div class="d-flex justify-content-end ">

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="start" [(ngModel)]="mStartDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <mat-datepicker #start></mat-datepicker>
                </mat-form-field> &nbsp;&nbsp;&nbsp;

                <mat-form-field appearance="outline" class="col-md-2">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="end" [(ngModel)]="mEndDate">
                    <mat-hint>DD/MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                    <mat-datepicker #end></mat-datepicker>
                </mat-form-field>&nbsp;&nbsp;&nbsp;

                <mat-form-field appearance="outline" class="ms-2">
                    <mat-label>Select Centre</mat-label>
                    <mat-select [formControl]="selectCentre" (selectionChange)="onCentreSelection($event)"
                    >
                        <mat-option *ngFor="let c of centreSelectionArray"
                                    [value]=c.centreCode>{{c.centreName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                &nbsp;&nbsp; &nbsp;&nbsp;
                <button mat-mini-fab color="accent" (click)="resetDate()">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
            <!*--------------------------------------------- CARDS --------------------------------------------->
            <div class="row">
                <!*--------------------------------------------- UNIT ALLOCATION--------------------------------------------->
                <div class="col-lg-3 col-sm-6">
                    <div class="info-box7 l-bg-orange order-info-box7">
                        <div class="info-box7-block">
                            <h4 class="m-b-20">Current Unit Allocation</h4>
                            <h2 class="text-end"><i class="fas fa-bed float-start"></i><span>{{totalUnits}}</span></h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                                <div> Available <br>{{totalAvailableUnits}}</div>
                                <div> Occupied <br> {{totalOccupiedUnits}}  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!*--------------------------------------------- ADMISSION COUNT--------------------------------------------->
                <div class="col-lg-3 col-sm-6">
                    <div class="info-box7 l-bg-purple order-info-box7">
                        <div class="info-box7-block">
                            <h4 class="m-b-20">Admission Count</h4>
                            <h2 class="text-end"><i class="fas fa-chart-line float-start"></i><span>{{totalAdmissionCount}}</span></h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                                <div> Unique {{uniqueAdmissionsCount}} <br> (B) {{freshBoys}} (G) {{freshGirls}}</div>
                                <div> FollowUp {{followUpAdmissionsCount}}  <br>  (B) {{followupBoys}} (G) {{followupGirls}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!*--------------------------------------------- UNIQUE ADMISSIONS--------------------------------------------->
                <div class="col-lg-3 col-sm-6">
                    <div class="info-box7 l-bg-card1 order-info-box7">
                        <div class="info-box7-block">
                            <h4 class="m-b-20">Unique Admission</h4>
                            <h2 class="text-end"><i class="fas fa-smile float-start"></i><span>{{totalRegisteredChildren}}</span></h2>
                            <div class=" m-b-0 d-flex justify-content-between">
                                <div> Boys <br>{{totalRegisteredBoys}}</div>
                                <div> Girls <br> {{totalRegisteredGirls}}  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!*--------------------------------------------- TOTAL FOLLOW UP--------------------------------------------->
                <div class="col-lg-3 col-sm-6">
                    <div class="info-box7 l-bg-card2 order-info-box7">
                        <div class="info-box7-block">
                            <h4 class="m-b-20">Total Follow Ups</h4>
                            <h2 class="text-end"><i class="fas fa-user-friends float-start"></i><span>{{followupBoys+followupGirls}}</span></h2>
                            <!--                            <p class="m-b-0">Boys:{{followupBoys}} Girls {{followupGirls}}</p>-->
                            <div class=" m-b-0 d-flex justify-content-between">
                                <div>Boys <br>{{followupBoys}}</div>
                                <div> Girls <br> {{followupGirls}}</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <h3 class="mt-2  dashboard-heading  ">Hospitals and Age </h3>

            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" *ngIf="HospitalLables.length>=1 && HospitalCount.length>=1 && show">

                <!--            <app-hospitals-bar-graph [mLabels]="HospitalLables" [mCount]="HospitalCount"-->
                <!--                                     [datmodel]="hData"></app-hospitals-bar-graph>-->
                <app-apex-hospital-chart [mLabels]="HospitalLables" [mCount]="HospitalCount" [mFollowUpCount]="mFollowUpHospitalCount" ></app-apex-hospital-chart>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4" *ngIf="ageArray.length>1 && show">

                <app-apex-age-chart [ageArrayInput]="ageArray" [followUpArray]="mFollowUpAgeArray"   ></app-apex-age-chart>
                <!--            <app-age-bar [ageArrayInput]="ageArray" [followUpArray]="mFollowUpAgeArray"  [a]="a" [b]="b" [c1]="c1" [d]="d" [e]="e" [af]="af" [bf]="bf" [cf]="cf" [df]="df" [ef]="ef" > </app-age-bar>-->
            </div>
        </div>
        <!--------------------------------------------- OCCUPANCY TABLE --------------------------------------------->


        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mb-4" >
            <app-admission-chart *ngIf="showAdmissionChart" [centreNames]="mAdmissionChartCentres"
                                 [unique]="mAdmissionChartUniqueCount"
                                 [followUp]="mAdmissionChartFollowUpCount"></app-admission-chart>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <!--{{mAllChildren.length}}-->
            <ng-container *ngIf="mAllChildren.length>1" >
                <app-recent-admissions [mChildren]="mAllChildren"></app-recent-admissions>
            </ng-container>
        </div>
        <!*--------------------------------------------- UNIQUE DISCHARGES--------------------------------------------->
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <ng-container *ngIf="mAllChildren.length>1" >
                <app-recent-discharge [mChildren]="mAllChildren"></app-recent-discharge>
            </ng-container>
        </div>
        <!*--------------------------------------------- UNIQUE DISCHARGES--------------------------------------------->

        <!--        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 mb-4" >-->
        <!--            <app-admission-chart *ngIf="showAdmissionChart" [centreNames]="mAdmissionChartCentres"-->
        <!--                                 [unique]="mAdmissionChartUniqueCount"-->
        <!--                                 [followUp]="mAdmissionChartFollowUpCount"></app-admission-chart>-->
        <!--        </div>-->
        <div class="d-flex flex-wrap">


            <div class=" mt-4 col-md-12 mb-5 height-auto  ">
                <app-financial-report-table></app-financial-report-table>
            </div>
        </div>
    </div>
</div>
