import { Component, OnInit } from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {UserRole} from "../add-user-role/add-user-role.component";



@Component({
  selector: 'app-edit-user-role',
  templateUrl: './edit-user-role.component.html',
  styleUrls: ['./edit-user-role.component.scss']
})
export class EditUserRoleComponent implements OnInit {
 data: UserRole[] | null =[]
  constructor(
    private mDatabase: AngularFireDatabase
  ) {
  }

  ngOnInit(): void {
    this.getUserRoleData();
  }

  getUserRoleData(): void {
    this.mDatabase
      .object<UserRole[]>(`TestUsers/Pavan/`)
      .valueChanges().subscribe(res =>{
      let d = res
      this.data = d
    })



  }

  submit(): void {
  }
}
