import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList, AngularFireObject} from '@angular/fire/compat/database';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/compat/storage';

import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {FileUpload} from './file-upload';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {deleteField} from "@angular/fire/firestore";

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    private basePath = '/uploads';

    constructor(private db: AngularFireDatabase, private storage: AngularFireStorage, private mFirestore: AngularFirestore) {
    }

    pushFileToStorage(fileUpload: FileUpload, pathToDatabase: string | undefined, dbType: string): Observable<number> {
        const filePath = `${this.basePath}/${fileUpload.file.name}`;
        const storageRef = this.storage.ref(filePath);
        const uploadTask = this.storage.upload(filePath, fileUpload.file);

        uploadTask.snapshotChanges().pipe(
            finalize(() => {
                storageRef.getDownloadURL().subscribe(downloadURL => {
                    fileUpload.imageUrl = downloadURL;
                    fileUpload.fileName = fileUpload.file.name;

                    if (dbType == 'realtime') this.saveFileData(fileUpload, pathToDatabase!);
                    else if (dbType == 'firestore') this.saveFileDataFirestore(fileUpload, pathToDatabase!)
                });
            })
        ).subscribe();

        // @ts-ignore
        return uploadTask.percentageChanges();
    }

    pushFileToStorageWith(fileUpload: FileUpload, pathToDatabase: string | undefined, dbType: string,uploadPrefix:string): Observable<number> {
        const filePath = `${uploadPrefix}/${fileUpload.file.name}`;
        let storageRef: AngularFireStorageReference;
        storageRef = this.storage.ref(filePath);
        let uploadTask: AngularFireUploadTask;
        uploadTask = this.storage.upload(filePath, fileUpload.file);

        uploadTask.snapshotChanges().pipe(
            finalize(() => {
         //* Download Image
                storageRef.getDownloadURL().subscribe(downloadURL => {
                    fileUpload.imageUrl = downloadURL;
                    fileUpload.fileName = fileUpload.file.name;
//* Upload Image and fileRef
                    if (dbType == 'realtime') this.saveFileData(fileUpload, pathToDatabase!);
                    else if (dbType == 'firestore') this.saveFileDataFirestore(fileUpload, pathToDatabase!)
                });
            })
        ).subscribe();

        // @ts-ignore
        return uploadTask.percentageChanges();
    }

    private saveFileData(fileUpload: FileUpload, path: string): void {
        this.db.object(path).update(fileUpload);
    }

    private saveFileDataFirestore(fileUpload: FileUpload, path: string): void {
        let obj = {
            fileName: fileUpload.fileName,
            imageUrl: fileUpload.imageUrl
        }

        this.mFirestore.doc(path).update(obj);
    }

    // @ts-ignore
    getFiles(numberItems, path = '/uploads'): AngularFireList<FileUpload> {

        // return this.db.list(path, ref => ref.limitToLast(numberItems));
        return this.db.list(path, ref => ref.limitToLast(numberItems));
    }

    // @ts-ignore
    getFile(numberItems: number, path: firebase.database.Query | string = '/uploads', dbType: 'realtime' | 'firestore'){
        // return this.db.list(path, ref => ref.limitToLast(numberItems));
        return  this.db.object<FileUpload>(path)
    }
// @ts-ignore
    getFileFromFirestore(numberItems: number, path: firebase.database.Query | string = '/uploads', dbType: 'realtime' | 'firestore') {
        // return this.db.list(path, ref => ref.limitToLast(numberItems));
        return  this.mFirestore.doc<FileUpload>(path)
    }


    deleteFile(fileUpload: FileUpload, path: string): void {
        this.deleteFileDatabase(path)
            .then(() => {
                /**To delete file in database*/
                this.deleteFileStorage(fileUpload.fileName, path);
            })
            .catch(error => console.log(error));
    }
    deleteFileFirestore(fileUpload: FileUpload, path: string): void {
        this.deleteFileFDatabase(path)
            .then(() => {
                /**To delete file in database*/
                this.deleteFileStorage(fileUpload.fileName, path);
            })
            .catch(error => console.log(error));
    }
    private deleteFileDatabase(path: string): Promise<void> {
        this.db.object(`${path}/fileName`).remove();
        return this.db.object(`${path}/imageUrl`).remove();
    }
    private deleteFileFDatabase(path: string): Promise<void> {
        return this.mFirestore.doc(path).update({
            filename:deleteField(),
            imageUrl:deleteField()
        })

    }

    private deleteFileStorage(name: string, path: string): void {
        const storageRef = this.storage.ref(this.basePath);
        storageRef.child(name).delete();
    }
}
