import { Component, OnInit } from '@angular/core';
import {DatePipe} from "@angular/common";
import {FullChild} from "../../../interfaces/FullChild";
import {WeeklyReport} from "../../../modules/reports/ops-reports/weekly-reports/weekly-reports.component";
import {Centre} from "../../../interfaces/Centres";
import {ChildService} from "../../child/child.service";
import {CentreService} from "../../../services/centre.service";
import {AdmissionType} from "../../../interfaces/AdmissionType";
import {Subject, takeUntil} from "rxjs";
import {map} from "rxjs/operators";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-centre-efficiency',
  templateUrl: './centre-efficiency.component.html',
  styleUrls: ['./centre-efficiency.component.scss']
})
export class CentreEfficiencyComponent implements OnInit {
startDate=new Date()
    startDate1!: Date
    endDate1!: Date
    mAllChild: FullChild[] = []
    mSortedChild: FullChild[] = []
    displayedColumns: String[] = ['centername', 'totalunits', 'occupiedunits', 'occupiedunitsEnd', 'difference', 'percentage', 'admission', 'fAdmission', 'discharge', 'fDischarge', 'os', 'dama', 'alchamp'];

endDate=new Date()
  weeklyDates:any
    allCentres: Centre[] = [];
    mWeekly: WeeklyReport[] = [];
    pCOUNT = 0
    pCOUNT2 = 0
    pList: string[] = []
    pList2: string[] = []
    totalUnits = 0
    totalOccupiedUnits = 0
    totalAvailableUnits = 0
    mCentres: Centre[] = [];
    _destroyed$ = new Subject()
    dataSource!: MatTableDataSource<WeeklyReport>;


  constructor(
      private readonly mDatePipe:DatePipe,
      private readonly mChildService: ChildService,
      private readonly mCenterService: CentreService
  ) { }

  ngOnInit(): void {
    const date = new Date()
      if (this.mAllChild.length == 0) this.mChildService.getLatestData().pipe(takeUntil(this._destroyed$),
          map(x => x.docs.map(y => y.data())
          )
      ).subscribe(res => {
          this.mAllChild = res
          this.mSortedChild = this.mAllChild

      })
      this.initAllCentresData('All')
 }
    private calculateTotalUnits() {
        this.totalUnits = 0;
        this.totalAvailableUnits = 0;
        this.totalOccupiedUnits = 0;

        this.allCentres.forEach(c => {
            this.totalUnits += +c.totalUnits!;
            this.totalAvailableUnits += +c.availableUnits!;
            this.totalOccupiedUnits += +c.occupiedUnits!;
        });
    }

    private initAllCentresData(centreCode: string) {
        console.log('getAllCentre Called with:', centreCode);

        if (centreCode === 'All') {
            // At startup when centreCode == "All"
            if (this.allCentres.length === 0) {
                this.mCenterService.getAllCentres().subscribe(centres => {
                    this.allCentres = centres;
                    console.log('CENTRE LIST TABLE', centres);
                    this.calculateTotalUnits();
                    // Uncomment the following lines if needed
                    // this.addExtraRowToOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, 'Total');
                    // this.dataSource.paginator = this.paginator;
                    // this.getTotalUnitsCount(this.allCentres);
                });
            }
            console.log('allCentres if', this.allCentres.length);
        } else if (centreCode !== 'All') {
            console.log('Into getAllCentresData with centreCode ==', centreCode);
            this.mCentres = this.allCentres.filter(c => c.centreCode === centreCode);
            console.log('CENTRE LIST TABLE', this.mCentres);

            this.calculateTotalUnits();
            // Uncomment the following lines if needed
            // this.addExtraRowToOccupancyTable(this.totalUnits, this.totalAvailableUnits, this.totalOccupiedUnits, 'Total');
            this.dataSource = new MatTableDataSource(this.mWeekly);
            // this.dataSource.paginator = this.paginator;
            // this.getTotalUnitsCount(this.allCentres);

            // console.log('allCentres else', this.allCentres.length);
        }
    }

  search() {
 this.weeklyDates = getWeeklyDates(this.startDate, this.endDate);
    console.log(this.weeklyDates)
      this.searchData()

  }
    searchData() {
        this.mWeekly = []
        this.startDate1 =this.startDate
        this.endDate1 =this.endDate
        this.weeklyDates.forEach((it:any,i:number)=>{
            this.startDate1 =it
this.endDate1 =this.weeklyDates[i+1]
            this.calculateByCentre('AL Ahmedabad', 'ALAhmedabad', this.startDate1, this.endDate1)

        })
        // this.calculateByCentre('AL Ahmedabad', 'ALAhmedabad', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Bandra', 'ALBandra', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Bangalore', 'ALBangalore', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Chandigarh', 'ALChandigarh', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Diamond Garden', 'ALDiamondGarden', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Gurunanak Niwas', 'ALGurunanak', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Manipal', 'ALManipal', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Pune', 'ALPune', this.startDate1, this.endDate1)
        // this.calculateByCentre('AL Sandu Garden', 'ALSanduGarden', this.startDate1, this.endDate1)
        this.dataSource = new MatTableDataSource(this.mWeekly);
    }

    calculateByCentre(centreName: string, centreCode: string, startDate: Date, endDate: Date) {
        this.pList = [];
        this.pList2 = [];

        const currentCenter = this.allCentres.find(x => x.centreCode === centreCode);
        if (!currentCenter) {
            console.log('Center not found:', centreCode);
            return;
        }

        const admission: FullChild[] = [];
        const discharge: FullChild[] = [];
        let firstAdmission = 0;
        let firstDischarge = 0;
        let admissionCount = 0;
        let dischargeCount = 0;
        this.pCOUNT = 0;
        this.pCOUNT2 = 0;

        const totalPreviousAdmissions = this.mAllChild.filter(x => x.admissionData[0]?.dateOfAdmission?.toDate()! <= startDate);
        const totalPreviousAdmissions2 = this.mAllChild.filter(x => x.admissionData[0]?.dateOfAdmission?.toDate()! <= endDate);

        // console.log('TOTAL PREVIOUS ADMISSIONS', totalPreviousAdmissions.length);

        totalPreviousAdmissions.forEach(child => {
            if (child.centreCode === centreCode) {
                child.admissionData.forEach((ad, i) => {
                    this.checkPreviousAdmissionNew(i, ad, child.admissionData, this.pCOUNT, child.childCode);
                });
            }
        });

        totalPreviousAdmissions2.forEach(child => {
            if (child.centreCode === centreCode) {
                child.admissionData.forEach((ad, i) => {
                    this.checkPreviousAdmission2(i, ad, child.admissionData, this.pCOUNT2, child.childCode);
                });
            }
        });

        this.mAllChild.forEach(child => {
            child.admissionData.forEach((ad, index) => {
                if (index === 0) {
                    if (child.dateOfAdmission.toDate() >= startDate && child.dateOfAdmission.toDate() <= endDate && ad.centreCode === centreCode) {
                        firstAdmission++;
                    }
                }

                if (index === 1) {
                    if (ad.dateOfDischarge?.toDate()! >= startDate && ad.dateOfDischarge?.toDate()! <= endDate && ad.centreCode === centreCode) {
                        firstDischarge++;
                    }
                }

                if (index >= 2 && index % 2 === 0) {
                    const d = ad.dateOfAdmission?.toDate()!;
                    if (d > startDate && d < endDate && ad.centreCode === centreCode) {
                        admissionCount++;
                    }
                }

                if (index > 2 && index % 2 !== 0) {
                    const d = ad.dateOfDischarge?.toDate()!;
                    if (d > startDate && d < endDate && ad.centreCode === centreCode) {
                        dischargeCount++;
                    }
                }
            });
        });

        // console.log('finalPrevCount', this.pCOUNT);
        // console.table(this.pList);
        // console.table(this.pList2);
        const mCurrenctCentreTotalUnits = currentCenter.totalUnits!
        const totalStayingStart = this.pCOUNT;
        const totalStayingEnd = this.pCOUNT2;
        currentCenter.occupiedUnits = totalStayingEnd;

        const report: WeeklyReport = {
            centre: currentCenter,
            centreName: centreName,
            centreCode: centreCode,
            totalRooms: mCurrenctCentreTotalUnits,
            Occupied: totalStayingStart,
            OccupiedEnd: totalStayingEnd,
            Available: mCurrenctCentreTotalUnits - totalStayingStart,
            Occupancy: +this.getPercentage(currentCenter),
            uniqueAdmission: firstAdmission,
            uniqueDischarge: firstDischarge,
            followUpAdmission: admissionCount,
            followupDischarge: dischargeCount,
            os: 0,
            dama: 0,
            alwaysChamp: 0
        };

        this.mWeekly.push(report);
    }
    checkPreviousAdmission2(i: number, ad: AdmissionType, data: AdmissionType[], prevcount: number, childCode: string) {

        try {
            if (i % 2 == 0) {
                // Admission type  if child is only admitted but not discharged before concerning date
                if (data[i + 1] != null && (data[i].dateOfAdmission!.toDate() <= this.endDate1) && data[i + 1].dateOfDischarge!.toDate() >= this.endDate1) {
                    this.pCOUNT2++
                    this.pList2.push(childCode + ' - ' + data[length - 1] + `(${i})`)
                }
                // Admission type  if child is only admitted but not discharged before concerning date
                if (data[i + 1] == null && (data[i].dateOfAdmission!.toDate() <= this.endDate1)) {
                    this.pCOUNT2++
                    this.pList2.push(childCode + ' - ' + data[length - 1] + `(${i})`)

                }

                console.log('prevCount', this.pCOUNT2)  // ? This is the total present child count

            }
        } catch (e) {
            console.error(childCode + `=${i}=>`, e)
        }
    }
    checkPreviousAdmissionNew(i: number, ad: AdmissionType, data: AdmissionType[], prevcount: number, childCode: string) {


        try {
            if (i % 2 == 0) {
                // Admission type  if child is only admitted but not discharged before concerning date
                if (data[i + 1] != null && (data[i].dateOfAdmission!.toDate() <= this.startDate1) && data[i + 1].dateOfDischarge!.toDate() > this.startDate1) {
                    this.pCOUNT++
                    this.pList.push(childCode + ' - ' + data[length - 1] + `(${i})`)
                }
                // Admission type  if child is only admitted but not discharged before concerning date
                if (data[i + 1] == null && (data[i].dateOfAdmission!.toDate() <= this.startDate1)) {
                    this.pCOUNT++
                    this.pList.push(childCode + ' - ' + data[length - 1] + `(${i})`)

                }

                console.log('prevCount', this.pCOUNT)  // ? This is the total present child count

            }
        } catch (e) {
            console.error(childCode + `=${i}=>`, e)
        }
    }
    getPercentage(element: Centre) {
        if (element.totalUnits != null && element.occupiedUnits != null) {
            let total = element.totalUnits
            let available = element.occupiedUnits
            let percentage = available / total * 100

            return percentage.toFixed(2)
        }
        return ''
    }
    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}
function getWeeklyDates(startDate: Date, endDate: Date): Date[] {
    const weeklyDates: Date[] = [];
    const currentDate = new Date(startDate.getTime());

    while (currentDate <= endDate) {
        const weekStart = new Date(currentDate.getTime());
        weekStart.setDate(currentDate.getDate() - (currentDate.getDay() === 0 ? 6 : currentDate.getDay() % 7)); // Adjust to Monday

        const weekEnd = new Date(weekStart.getTime());
        weekEnd.setDate(weekEnd.getDate() + 6); // Move to Saturday

        weeklyDates.push(weekStart, weekEnd);

        currentDate.setDate(currentDate.getDate() + 7); // Move to next week
    }

    return weeklyDates;
}
