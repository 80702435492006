import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Centre} from "../../../interfaces/Centres";
import {Observable} from "rxjs";
import {UploadModel} from "../../../models/UploadModel";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import firebase from "firebase/compat/app";
import {AngularFireStorage} from "@angular/fire/compat/storage";

import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-view-centre',
  templateUrl: './view-centre.component.html',
  styleUrls: ['./view-centre.component.scss']
})
export class ViewCentreComponent implements OnInit {
centreForm:any
  centreModel!:Centre
  mDbType: 'realtime' | 'firestore' = 'firestore';
  mStoragePath?: string = 'centres/'
  mPath = 'ALCentres';
  mDocuments:UploadModel[]=[]

  documents$: Observable<unknown | undefined>

  constructor( @Inject(MAT_DIALOG_DATA) public data: {id: string,value:Centre},
               private mFirestore:AngularFirestore,
               private mStorage:AngularFireStorage,
               private _snackBar:MatSnackBar
  ) {
    this.centreModel = this.data.value as Centre
    this.mPath = 'ALCentres/'+this.centreModel.centreCode;
    this.documents$  = this.mFirestore.doc( this.mPath).valueChanges({idField:'id'})
    this.documents$.subscribe(res=>{
      // @ts-ignore
      this.mDocuments = res.documents as UploadModel
    })
  }

  ngOnInit(): void {
  this.centreModel = this.data.value as Centre

  }
  openLink(fileUrl: string) {
    window.open(fileUrl)

  }

  @needConfirmation()
  deleteAdmissionFile(i: number, d: UploadModel, category: string) {
    const iRef = this.mStorage.storage.refFromURL(d.url)
    iRef.delete().then(() => {
      this.mFirestore.doc(this.mPath).update({
        [category]: firebase.firestore.FieldValue.arrayRemove(d)
      }).then(() => {
        this._snackBar.open('File Deleted Successfully')._dismissAfter(2000)
      })
    })
    console.log(iRef)
  }
  trackByMethod(index: number, el: any): number {
    return el.id;
  }
}
