<div>
    <!-- Left Sidebar -->
    <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover()" (mouseleave)="mouseOut()">
        <!-- Menu -->
        <div class="menu">
            <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
                <ul class="list">
                    <li>
                        <div class="sidebar-profile clearfix">
                            <div class="profile-img">
                                <img src="../../../assets/images/userbig.png" alt="profile">
                            </div>
                            <div class="profile-info">
                                <h3>{{role}}</h3>
                                <p>Welcome {{userName}} ! </p>
                            </div>
                        </div>
                    </li>
                    <!-- Top Most level menu -->
                    <li *ngFor="let sidebarItem of sidebarItems"
                        [routerLinkActive]="sidebarItem.submenu.length !== 0 ? 'active' : 'active-top'">
                        <div class="header" *ngIf="sidebarItem.groupTitle  ">{{sidebarItem.title }} </div>
                        <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null"
                           *ngIf="!sidebarItem.groupTitle  && sidebarItem.showMenu===true ; "
                           [ngClass]="[sidebarItem.class]" (click)="callToggleMenu($event, sidebarItem.submenu.length)"
                           class="menu-top">
                            <i-feather [name]="sidebarItem.icon" class="sidebarIcon"></i-feather>
                            <!--                            <mat-icon   class="sidebarIcon">{{sidebarItem.icon}} </mat-icon>-->
                            <span class="hide-menu">{{sidebarItem.title  }}
              </span>
                        </a>
                        <!-- First level menu -->
                        <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0  ">
                            <ng-container *ngFor="let sidebarSubItem1 of sidebarItem.submenu">
                            <li  *ngIf="sidebarSubItem1.showMenu==true "
                                [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                                <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                                   (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)"
                                   [ngClass]="[sidebarSubItem1.class]">
                                    <i-feather [name]="sidebarSubItem1.icon" class="sidebarIcon"></i-feather>
                                    {{sidebarSubItem1.title }}
                                </a>
                                <!-- Second level menu -->
                                <ul class="ml-menu-2" *ngIf="sidebarSubItem1.submenu.length > 0 && sidebarSubItem1.showMenu ">
                                    <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                                        [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                                        <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                                           (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)"
                                           [ngClass]="[sidebarSubItem2.class]">
                                            {{sidebarSubItem2.title }}
                                        </a>
                                        <!-- Third level menu -->
                                        <ul class="ml-menu-3" *ngIf="sidebarSubItem2.submenu.length > 0  " >
                                            <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                                                [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                                                <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                                                   (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                                                   [ngClass]="[sidebarSubItem3.class]">
                                                    {{sidebarSubItem3.title }}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            </ng-container>
                        </ul>
                    </li>
                </ul>

            </ng-scrollbar>

        </div>
        <!-- #Menu -->
    </aside>
    <!-- #END# Left Sidebar -->
</div>
