
<h1 class="bg-warning text-center p-2">View Hospital Details</h1>
<table class="table table-active ">
  <tbody>
  <p class="m-0">Hospital Details</p>
  <tr >
    <th> Hospital Name </th>
    <td>{{data.hospitalName}}</td>
  </tr>
  <tr>

    <th> Short Name</th>
    <td>{{data.shortName}}</td>
  </tr>

  <tr >
    <th> Doctor Name </th>
    <td>{{data.doctorsName}}</td>
  </tr>

  <tr>
    <th> Social Worker Name </th>
    <td>{{data.socialWorkerName}}</td>
  </tr>

  <tr>
    <th>  Address </th>
    <td>{{data.address}}</td>
  </tr>


  <tr>
    <th> City</th>
    <td>{{data.city}}</td>
  </tr>
  <tr>
    <th> State</th>
    <td>{{data.state}}</td>
  </tr>

  <tr>
    <th> Pin Code </th>
    <td>{{data.zipcode}}</td>
  </tr>
  <br>


  </tbody>

</table>
