<table mat-table [dataSource]="dataSource" class=" text-center">

  <!-- Display Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element.date}} </td>
  </ng-container>

  <!-- Location Column -->
  <ng-container matColumnDef="fuels">
    <th mat-header-cell *matHeaderCellDef> Fuel(Diesel) </th>
    <td mat-cell *matCellDef="let element"> {{element.fuels}} </td>
  </ng-container>

  <ng-container matColumnDef="kilometers">
    <th mat-header-cell *matHeaderCellDef> Kilometers </th>
    <td mat-cell *matCellDef="let element">{{element.kilometers}} </td>
  </ng-container>

  <!-- Add more columns as needed -->

  <!-- Table Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
