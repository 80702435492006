import {Injectable, isDevMode} from '@angular/core';
import {AngularFirestore, } from "@angular/fire/compat/firestore";
import {distinctUntilChanged, map, shareReplay, tap} from "rxjs/operators";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {FormArray, FormGroup} from "@angular/forms";
import {FullChild} from "../../interfaces/FullChild";
import firebase from "firebase/compat/app";
import {environment} from "../../../environments/environment";
import {combineLatest, concat,  Observable} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class ChildService {
    env = isDevMode() ? environment.testMode : environment.productionMode
    readCount = 0

    constructor(private firestore: AngularFirestore,
                private realtimeDB: AngularFireDatabase) {
    }
    getCurrentChildCounter() {
        return this.realtimeDB.object(`${this.env.counters}/childCounter`).valueChanges()
    }

    addGuardian(childCode: string, guardian: FormArray) {
        return this.firestore.doc(`${this.env.caretakers}/caretakers`).set({[childCode]: guardian.value})
    }

//** TO GET PRESCREENING CHILD
    fetchPreScreeningChild() {
        return this.firestore.doc(this.env.prescreening).get()
    }

    getChildList() {
        const childDoc = this.firestore.doc(this.env.prescreening)
        return childDoc.snapshotChanges();
    }

    //?MIS
    getRegisteredChildList() {
        return this.firestore.collection<FullChild>(this.env.child).get().pipe(
            shareReplay(1)
        )
        // return this.firestore.collection<FullChild>('ALChild').get().pipe(
        //     shareReplay(1)
        // )
    }

    getRegisteredChildListByDateRange(startDate: Date, endDate: Date) {
        return this.firestore.collection<FullChild>(this.env.child, ref => ref.where('dateOfAdmission', '>=', startDate)
            .where('dateOfAdmission', '<=', endDate)).get().pipe(
            shareReplay(1)
        )
        // return this.firestore.collection<FullChild>('ALChild').get().pipe(
        //     shareReplay(1)
        // )
    }

    getRegisteredChildListByDateRangeAndCentre(centreCode: string, startDate: Date, endDate: Date) {
        return this.firestore.collection<FullChild>(this.env.child, ref => ref
            .where('centreCode', '==', centreCode)
            .where('dateOfAdmission', '>=', startDate)
            .where('dateOfAdmission', '<=', endDate)).get().pipe(
            shareReplay(1)
        )
        // return this.firestore.collection<FullChild>('ALChild').get().pipe(
        //     shareReplay(1)
        // )
    }

    //? MIS
    getRegisteredChildListByCentre(centreCode: string) {
        return this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode)).get().pipe(
            shareReplay(1)
        )
        // return this.firestore.collection<FullChild>('ALChild').get().pipe(
        //     shareReplay(1)
        // )
    }


//? MIS FINANCIAl REPORT
    getLatestData() {

        return this.firestore.collection<FullChild>('ALChild').get().pipe(
            tap(arr => console.log(`centre: read (${this.readCount += arr.docs.length} )docs`), shareReplay(1)
            ))

    }

    // getChildData():Observable<ChildData>{
    //   const childDoc= this.firestore.doc<ChildData>('testchild/testdocument')
    // return childDoc.snapshotChanges().pipe(map(changes=>{
    //   const data = changes.payload.data();
    //   const id  = changes.payload.id;
    //   // @ts-ignore
    //   return {id, ...data};
    // }));
    // }
    addTempChild(value: any) {
        this.firestore.collection(this.env.prescreening).add(value).then(() => {
            console.log('data sent successfully')
        })
            .catch((reason) => {
                console.log('error sending data' + reason)
            })
    }

    updatePrescreening(value: any) {
        this.firestore.collection(this.env.prescreening).doc(value.id).update(value)
            .then(() => {
                console.log('data updated successfully')
            })
            .catch((reason) => {
                console.log('error sending data' + reason)
            })
    }

    //* REGISTER CHILD
    enrollChild(childCode: string, form: FormGroup) {
        console.log('INTO ENROLL CHILD', childCode)
        return this.firestore.collection(this.env.child).doc(childCode).set(form.value).then(() => {
            const data = {
                'dateOfAdmission': form.get('dateOfAdmission')?.value,
                'type': 'Admission',
                'currentUnit': form.get('selectUnit')?.value,
                'centreCode': form.get('centreCode')?.value,
                'centreName': form.get('centreName')?.value
            }
            this.firestore.collection(this.env.child).doc(childCode).update({
                admissionData: firebase.firestore.FieldValue.arrayUnion(data)
            })
        })
    }

    fetchTestChildList() {
        const childDoc = this.firestore.collection<FullChild>(this.env.prescreening,
            ref => ref.orderBy('createdAt', 'desc'))
        return childDoc.snapshotChanges();

    }

//* Fetch All children from all centres in  firestore in registration child assessment-table all
    childRegistration() {
        const childDoc = this.firestore.collection<FullChild>(this.env.child)

        // @ts-ignore
        return childDoc.valueChanges({idField: 'id'}).pipe(
            tap(arr => {
                console.log(`read ${arr.length} docs`)
                console.log('ChildRegistration', this.env.child)
            },), shareReplay(1)
        );

    }

    childRegistrationbyCenter(centreCode: string) {
        console.log('INTO', 'childRegistrationbtCenter')
        // let startDate = new Date('2016-03-31')
        // let endDate = new Date('2017-04-01')
        // // alert( 'full year is : '+d)
        // console.log('NEW START DATE IS ', +startDate)
        // console.log('NEW END DATE IS ', +endDate)
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode)
            // .where('dateOfAdmission','<=',startDate).where('dateOfAdmission','<=',endDate)
        )
        console.log(childDoc)
        // @ts-ignore
        return childDoc.snapshotChanges().pipe(
            tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('Child Registration By Center', this.env.child)
                }
            ), shareReplay(1)
        );
    }

    getChildListByCenter(centreCode: string) {
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
        // @ts-ignore
        return childDoc.valueChanges({idField: 'id'})

    }

  getAllotedChildListByCenter(centreCode: string): Observable<FullChild[]> {
    const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true));
    return childDoc.valueChanges({ idField: 'id' }).pipe(
      map(children => children.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)))
    );
  }
    fetchAllotedChildListByCenter(centreCode: string) {
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true))
        // @ts-ignore
        return childDoc.get({idField: 'id'})
    }


    getAllotedChildListByCenterAboveThree(centreCode: string) {
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true).where('age', '>', 3))
        // @ts-ignore
        return childDoc.valueChanges({idField: 'id'})
    }
    // getAllotedChildListByCenterAboveThree2(centreCode: string): Observable<FullChild[]> {
    //     return this.firestore.collection<FullChild>(this.env.child, ref =>
    //         ref.where('centreCode', '==', centreCode)
    //             .where('isAlloted', '==', true)
    //             // .where('age', '>', 3)
    //     ).valueChanges({ idField: 'id' }).pipe(
    //         map(children => children.map(child => ({
    //             ...child,
    //             age: this.calculateLatestAge(child.dob.toDate())
    //         })))
    //     );
    // }
    getAllotedChildListByCentreLiveAgeAbove3(centreCode: string) {
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true))
        // @ts-ignore
        return childDoc.valueChanges({idField: 'id'})
    }

    getDischargedChildListByCenterAboveThreeWithinThreeDaysEducation(centreCode: string) {

        const currentDate = new Date();
        const currentDate2 = new Date();
        currentDate2.setDate(currentDate2.getDate()-30);
        // *Convert the date to Firestore Timestamp
        const timestamp = firebase.firestore.Timestamp.fromDate(currentDate2);
        const currentTimestamp = firebase.firestore.Timestamp.fromDate(currentDate);

        return this.firestore.collection<FullChild>(this.env.child).ref
            .where('centreCode','==',centreCode)
            .where('isAlloted', '==', false)
            .where('dischargeDate', '>=', timestamp)
            .where('dischargeDate', '<=', currentTimestamp)
            .get()



    }


    //? Used in Educatiom Group Component
    getAllotedChildListByCenterArrayAboveThree(centreCodes: string[]) {
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', 'in', centreCodes).where('isAlloted', '==', true).where('age', '>', 3))
        // @ts-ignore
        return childDoc.valueChanges({idField: 'id'})
    }

    editChild(childCode
                  :
                  string | undefined, value
                  :
                  any
    ) {
        return this.firestore.collection(this.env.child).doc(childCode).update(value)
        // return this.firestore.collection(this.env.child).doc(childCode).update(value)
    }

    getAllotedChildListByCenterarray(centreCodes: String[]) {
        const childDoc = this.firestore.collection<FullChild>(this.env.child, ref => ref.orderBy('firstName','asc').where('centreCode', 'in', centreCodes).where('isAlloted', '==', true))
        return childDoc.valueChanges({idField: 'id'})
    }

    //* Used in child registration Table For Alloted , Discharged and All Children
    allotedChildRegistrationbyCenter(selectedStatus: string, centreCode: string) {
        switch (selectedStatus) {
            case 'Staying': {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true))

                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
            case 'Discharged': {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', false))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
            case 'All': {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
            default: {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
        }
    }

    allotedChildRegistrationbyCenterCombineLatest(selectedStatus: string, centreCode: string) {
        switch (selectedStatus) {
            case 'Staying': {
                const centreQuery = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true)).valueChanges().pipe(tap(arr => {
                    console.log(`1read ${arr.length} docs`)
                    // console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
                const baseCenterQuery = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('baseCentreCode', '==', centreCode).where('isAlloted', '==', true)).valueChanges().pipe(tap(arr => {
                    console.log(`baseread ${arr.length} docs`)
                    // console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))

                let centrePlusBaseCentre = combineLatest(
                    centreQuery,
                    baseCenterQuery
                )

                // @ts-ignore
                return centrePlusBaseCentre.pipe(tap(arr => {
                        console.log(`read ${arr.length} docs`)
                        console.log('ChildRegistration', this.env.child)
                    },),
                    map(([burgers, donuts]) => {
                        // Perform any additional transformations if needed
                        // ...
                        console.log('MAP', removeDuplicates([...burgers, ...donuts]).length)
                        return removeDuplicates([...burgers, ...donuts])
                        // return [...burgers, ...donuts]

                    }), shareReplay(1))
            }
          case 'OM SHANTI': {
            const centreQuery = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('expired', '==', true)).valueChanges().pipe(tap(arr => {
              console.log(`1read ${arr.length} docs`)
              // console.log('ChildRegistration', this.env.child)
            },), shareReplay(1))
            const baseCenterQuery = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('baseCentreCode', '==', centreCode).where('expired', '==', true)).valueChanges().pipe(tap(arr => {
              console.log(`baseread ${arr.length} docs`)
              // console.log('ChildRegistration', this.env.child)
            },), shareReplay(1))

            let centrePlusBaseCentre = combineLatest(
              centreQuery,
              baseCenterQuery
            )

            // @ts-ignore
            return centrePlusBaseCentre.pipe(tap(arr => {
                console.log(`read ${arr.length} docs`)
                console.log('ChildRegistration', this.env.child)
              },),
              map(([burgers, donuts]) => {
                // Perform any additional transformations if needed
                // ...
                console.log('MAP', removeDuplicates([...burgers, ...donuts]).length)
                return removeDuplicates([...burgers, ...donuts])
                // return [...burgers, ...donuts]

              }), shareReplay(1))
          }
            case 'Discharged': {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', false))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
            case 'All': {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
            default: {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
        }
    }

    //* Duplicate record issue fixed in this one
    allotedChildRegistrationbyCenterCombineLatest2(selectedStatus: string, centreCode: string) {
        switch (selectedStatus) {
            case 'Staying': {
                const centreQuery$ = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', true)).valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`1read ${arr.length} docs`)
                    // console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
                const baseCenterQuery$ = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('baseCentreCode', '==', centreCode).where('isAlloted', '==', true)).valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`baseread ${arr.length} docs`)
                    // console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))

                // return this.performMergeQuery(centreQuery$,baseCenterQuery$)
                return combineLatest(
                    centreQuery$,
                    baseCenterQuery$
                ).pipe(
                    // Perform any additional transformations if needed
                    // ...
                    map(([query1Results, query2Results]) => {
                        return query1Results.concat(query2Results);
                    }),
                    distinctUntilChanged(), map(queryResults => {
                        const uniqueResults = [];
                        const idsSet = new Set();

                        for (const result of queryResults) {
                            const id = result.id;
                            if (result.baseCentreCode == null) result.baseCentreCode = result.centreCode
                            if (result.baseCentreName == null) result.baseCentreName = result.baseCentreName
                            if (!idsSet.has(id)) {
                                idsSet.add(id);
                                uniqueResults.push(result);
                            }
                        }

                        return uniqueResults;
                    }),

                    shareReplay(1))
            }
          case 'OM SHANTI': {
            const centreQuery$ = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('status', '==', "OM SHANTI")).valueChanges({idField: 'id'}).pipe(tap(arr => {
              console.log(`1read ${arr.length} docs`)
              // console.log('ChildRegistration', this.env.child)
            },), shareReplay(1))
            const baseCenterQuery$ = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('baseCentreCode', '==', centreCode).where('status', '==', "OM SHANTI")).valueChanges({idField: 'id'}).pipe(tap(arr => {
              console.log(`baseread ${arr.length} docs`)
              // console.log('ChildRegistration', this.env.child)
            },), shareReplay(1))

            // return this.performMergeQuery(centreQuery$,baseCenterQuery$)
            return combineLatest(
              centreQuery$,
              baseCenterQuery$
            ).pipe(
              // Perform any additional transformations if needed
              // ...
              map(([query1Results, query2Results]) => {
                return query1Results.concat(query2Results);
              }),
              distinctUntilChanged(), map(queryResults => {
                const uniqueResults = [];
                const idsSet = new Set();

                for (const result of queryResults) {
                  const id = result.id;
                  if (result.baseCentreCode == null) result.baseCentreCode = result.centreCode
                  if (result.baseCentreName == null) result.baseCentreName = result.baseCentreName
                  if (!idsSet.has(id)) {
                    idsSet.add(id);
                    uniqueResults.push(result);
                  }
                }

                return uniqueResults;
              }),

              shareReplay(1))
          }

            case 'Discharged': {
                const centreQuery$ = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode).where('isAlloted', '==', false)).valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`1read ${arr.length} docs`)
                    // console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
                const baseCenterQuery$ = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('baseCentreCode', '==', centreCode).where('isAlloted', '==', false)).valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`baseread ${arr.length} docs`)
                    // console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
                // @ts-ignore
                return combineLatest(
                    centreQuery$,
                    baseCenterQuery$
                ).pipe(
                    // Perform any additional transformations if needed
                    // ...
                    map(([query1Results, query2Results]) => {
                        return query1Results.concat(query2Results);
                    }),
                    distinctUntilChanged(), map(queryResults => {
                        const uniqueResults = [];
                        const idsSet = new Set();

                        for (const result of queryResults) {
                            const id = result.id;
                            if (result.baseCentreCode == null) result.baseCentreCode = result.centreCode
                            if (result.baseCentreName == null) result.baseCentreName = result.baseCentreName

                            if (!idsSet.has(id)) {
                                idsSet.add(id);
                                uniqueResults.push(result);
                            }
                        }

                        return uniqueResults;
                    }),

                    shareReplay(1))
            }
            case 'All': {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
            default: {
                const query = this.firestore.collection<FullChild>(this.env.child, ref => ref.where('centreCode', '==', centreCode))
                // @ts-ignore
                return query.valueChanges({idField: 'id'}).pipe(tap(arr => {
                    console.log(`read ${arr.length} docs`)
                    console.log('ChildRegistration', this.env.child)
                },), shareReplay(1))
            }
        }
    }


    getRegisteredChildListByCentreandDateRange(centreCode: string, mStartDate: Date, endDate: Date) {
        return this.firestore.collection<FullChild>('ALChild', ref => ref.where('centreCode', '==', centreCode).where('dateOfAdmission', '>=', mStartDate).where('dateOfAdmission', '<=', endDate)).get().pipe(
            shareReplay(1))
    }

    deletePrescreening(id: string) {
        return this.firestore.collection(this.env.prescreening).doc(id).delete()

    }

    getCalculatedAge(date: firebase.firestore.Timestamp) {
        let timeDiff = Math.abs(Date.now() - (date).toDate().getTime());
        let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
        // * UPDATING AGE IN FIRESTORE ALCHILD
        // this.mFirestore.collection('ALChild').doc(childCode).update({age: age}).then(() => {
        //     console.log(`SET DATE TO ${childCode} as`, age)
        // })
        //     .catch(() => {
        //         console.log('ERROR ADDING AGE IN', childCode)
        //     })
        console.log(age)
        return age
    }

    performMergeQuery(centreQuery$: Observable<FullChild[]>, baseCenterQuery$: Observable<FullChild[]>): Observable<FullChild[]> {
        // const collectionRef1: AngularFirestoreCollection<any> = this.firestore.collection('collection1');
        // const collectionRef2: AngularFirestoreCollection<any> = this.firestore.collection('collection2');
        //
        // const query1$: Observable<FullChild[]> = collectionRef1.valueChanges();
        // const query2$: Observable<FullChild[]> = collectionRef2.valueChanges();

        return concat(centreQuery$, baseCenterQuery$).pipe(
            distinctUntilChanged(),
            map(queryResults => {
                const uniqueResults = [];
                const idsSet = new Set();

                for (const result of queryResults) {
                    const id = result.id;

                    if (!idsSet.has(id)) {
                        idsSet.add(id);
                        uniqueResults.push(result);
                    }
                }

                return uniqueResults;
            })
        );
    }

    findChildByCode(childCode: string) {
        return this.firestore.firestore.collection(this.env.child).doc(childCode).get();
    }

    getDischargeChildsBeforeThreeDaysFromCurrentDate() {
        const currentDate = new Date();
        const currentDate2 = new Date();
        currentDate2.setDate(currentDate2.getDate()-3);
        // *Convert the date to Firestore Timestamp
        const timestamp = firebase.firestore.Timestamp.fromDate(currentDate2);
        const currentTimestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        return this.firestore.collection(this.env.child).ref
            .where('isAlloted', '==', false)
            .where('dischargeDate', '>=', timestamp)
            .where('dischargeDate', '<=', currentTimestamp)
            .get()

        // .where('admissionData.')

    }

    getDischargeChildsBeforeThreeDaysFromCurrentDateByCentre(centreCode: string) {
        const currentDate = new Date();
        const currentDate2 = new Date();
        currentDate2.setDate(currentDate2.getDate()-30);
        // *Convert the date to Firestore Timestamp
        const timestamp = firebase.firestore.Timestamp.fromDate(currentDate2);
        const currentTimestamp = firebase.firestore.Timestamp.fromDate(currentDate);
        return this.firestore.collection(this.env.child).ref
            .where('centreCode','==',centreCode)
            .where('isAlloted', '==', false)
            .where('dischargeDate', '>=', timestamp)
            .where('dischargeDate', '<=', currentTimestamp)
            .get()
    }
    getAllAllootedChild(){
        return this.firestore.firestore.collection(this.env.child).where('isAlloted','==',true).get()

    }
  getAllAllootedChildByCentre(centreCode:string){
    let query= this.firestore.firestore.collection(this.env.child).where('isAlloted','==',true).get()
if(centreCode!='All')query= this.firestore.firestore.collection(this.env.child).where('isAlloted','==',true).where('centreCode','==',centreCode)
  .get()
    return query
  }



getNonSponsoredAllotedChildren(centreCode:string){
  let query= this.firestore.firestore.collection(this.env.child).where('isAlloted','==',true)
    .where('sponsored','!=',true)
    .get()
  if(centreCode!='All')query= this.firestore.firestore.collection(this.env.child).where('sponsored','==',true).where('centreCode','==',centreCode).get()
  return query
}
  getSponsoredAllotedChildren(centreCode:string){
    let query= this.firestore.firestore.collection(this.env.child)
      .where('centreCode','==',centreCode)
      .where('isAlloted','==',true)
      .where('sponsored','!=',true)
      .get()
    if(centreCode!='All')query= this.firestore.firestore.collection(this.env.child).where('sponsored','==',true).where('centreCode','==',centreCode).get()
    return query

  }


    private calculateLatestAge(dob: Date): number {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }


    getChildByCode(fromChild: string) {
        console.log('getChildByCode (ChildService)',fromChild)
        return   this.firestore.collection<FullChild>(this.env.child).doc(fromChild).get().toPromise()


    }

    getAllChild() {
        return this.firestore.collection<FullChild>('ALChild').valueChanges({idField:'id'})
    }

    getChildrenByStatus(selectedStatus: string) {
        return undefined;
    }

   //  getChildrenByCentreAndStatus(selectedCentreCode: string, selectedStatus: string) {
   //    alert(`${selectedCentreCode} ${selectedStatus}`)
   //
   // if(selectedCentreCode=='All'){
   //     switch (selectedStatus){
   //         case 'All':{
   //             return this.firestore.collection<FullChild>(this.env.child).get().pipe(
   //                 shareReplay(1)
   //             )
   //         }
   //         case '':{
   //
   //         }
   //     }
   // }
   //  }
}





//? Function to combine two arrays removing duplicate elements
function removeDuplicates(arr: FullChild[]) {
    return Array.from(new Set(arr));
    // return arr.filter((value, index, self) => {
    //     return self.indexOf(value) === index;
    // });
}

