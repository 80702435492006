import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {merge, Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatSelectChange} from "@angular/material/select";
import {ChildService} from "../../child/child.service";
import {FullChild} from "../../../interfaces/FullChild";
import {VehicleService} from "../vehicle.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {MatOptionSelectionChange} from "@angular/material/core";
import {logEvent} from "@angular/fire/analytics";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
    selector: 'app-add-car-log-book',
    templateUrl: './add-car-log-book.component.html',
    styleUrls: ['./add-car-log-book.component.scss']
})
export class AddCarLogBookComponent implements OnInit {
    yourFormGroup: FormGroup;
    // yourFormGroup: FormGroup;
    isChildTrip: boolean = false;
    currentGuardian: string | undefined
    centres$: Observable<Centre[]>
    childBycentre!: { firstName: string; lastName: string; centreName: string; childCode: string; centreCode: string; guardians: any }[];
    selectedCentre!: string;
    selectedChildCode: any;
    vehicles: any[] = []
    drivers: any[] = [];
    purposeOfTrips: any[] = [];
    tripReasons: any[] = [];
    guardians: any[] = [];
    withChild: boolean = false;
    withoutChild: boolean = false;
    purposeOfTrips$!: Observable<any>;
    currentSelectedChild: FullChild | undefined
    childCodeIndex: string[] = []
    mData: any
heading:'ADD TRIP'|"EDIT TRIP" ='ADD TRIP'
    constructor(private fb: FormBuilder,
                private centreService: CentreService,
                private childService: ChildService,
                private mVehicleService: VehicleService,
                private mDialogRef:MatDialogRef<AddCarLogBookComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private mSnackbar:MatSnackBar,
                @Inject(MAT_DIALOG_DATA) public dialogData: any,
                private mDatabase: AngularFireDatabase,
                private readonly mFirestore: AngularFirestore) {
this.mData =data
      if(this.mData!=null)this.heading ="EDIT TRIP"
        this.yourFormGroup = this.fb.group({
            drop_location: ['', Validators.required],
            pick_up_location: ['', Validators.required],
            child_list: [],
            purpose_of_trip: ['', Validators.required],
            reason: [null],

            isRoundTrip: [false],
            remark: ['', Validators.required],
            status: ['', Validators.required],
            trip_ended_at: [Date, Validators.required],
            trip_started_at: [Date, Validators.required],
            from: ['', Validators.required],
          pick_up_meter_reading: ['', Validators.required],


            to: ['', Validators.required],

          drop_meter_reading: ['', Validators.required],
            centreCode: ['', Validators.required],
            personName: [''],
            triptype: [''],
            caretakers: [''],
            submittedFrom: ['MySpace'],
            vehicle_info: this.fb.group({
                driverAddress: ['', Validators.required],
                driverContactNo: [null],
                driverName: ['', Validators.required],
                // email: ['', [ Validators.email]],
                licenseNumber: ['', Validators.required],
                vehicleNumber: ['', Validators.required],
                vehicleType: ['', Validators.required]
            })
        });
if(this.mData){
  this.setValuesInForm(this.mData)

}

        this.centres$ = this.centreService.getAllCentres()
    }

    ngOnInit(): void {


        this.mVehicleService.getVehicles().subscribe(res => {
            console.log(res)
            this.vehicles = res
        })


        this.mVehicleService.getDriver().subscribe(res => {
            this.drivers = res
            console.log(this.drivers)
        })


        this.purposeOfTrips$ = this.mDatabase.list("types/Trip Purpose").valueChanges()


    }

    submitForm() {


        if (this.withChild) {
            const formData = {
                centreCode: this.yourFormGroup.value.centreCode,
                childName: this.yourFormGroup.value.childName,
                caretaker: this.yourFormGroup.value.caretaker,
            };
            //
            // this.mFirestore.collection("ALTrips").add(this.yourFormGroup.value).then(res => {
            //   console.log("Form submitted Successfully")
            // })

        } else {
            const formData = {
                centreCode: this.yourFormGroup.value.centreCode,
                personName: [this.yourFormGroup.value.personName],
                // ... other common data
            };
            // Handle submission with the created array

        }
        let docId= ''
        console.log(this.yourFormGroup.value)
     if(this.mData!=null)   this.mFirestore.doc(`ALTrips/${docId}`).set(this.yourFormGroup.value, {merge: true}).then(res=>{
            this.mDialogRef.close()
        })
      if(this.mData==null)   this.mFirestore.collection(`ALTrips`).add(this.yourFormGroup.value).then(res=>{
        this.mDialogRef.close()
      })
    }

    onCentreSelect($event: MatSelectChange) {
        if ($event.value != null) {
            this.childService.getAllotedChildListByCenter($event.value!)
                .subscribe(childData => {
                    this.childBycentre = childData.map(child => ({
                        childCode: child.childCode,
                        firstName: child.firstName,
                        lastName: child.lastName,
                        gender: child.gender,
                        centreCode: child.centreCode,
                        centreName: child.centreName,
                        guardians: child.guardians
                    }));
                });
            console.log(this.childBycentre)
        }

    }

    addChild($event: MatSelectChange) {
        this.currentSelectedChild = $event.value as FullChild
        console.log($event.value)

        let child = $event.value as FullChild
// this.addChildtoChildListArray(child)
//         this.guardians = $event.value.guardians


    }

    onDriverSelect() {

        const selectedDriver = this.drivers.find(driver => driver.firstName + ' ' + driver.lastName === this.yourFormGroup.get('vehicle_info.driverName')?.value);
        if (selectedDriver) {
            this.yourFormGroup.get('vehicle_info.driverAddress')?.setValue(selectedDriver.currentAddress||'');
            this.yourFormGroup.get('vehicle_info.driverContactNo')?.setValue(selectedDriver.contactPhone||'');
            this.yourFormGroup.get('vehicle_info.email')?.setValue(selectedDriver.email||'');
            this.yourFormGroup.get('vehicle_info.licenseNumber')?.setValue(selectedDriver.license||'');
            // this.yourFormGroup.get('vehicle_info.vehicleNumber')?.setValue(selectedDriver.vehicleNumber);
            // this.yourFormGroup.get('vehicle_info.vehicleType')?.setValue(selectedDriver.vehicleType);
        }
    }

    withOrWithoutChild($event: MatSelectChange) {
        if ($event.value == "withChild") {
            this.withChild = true
            this.withoutChild = false
            this.yourFormGroup.get('personName')!.reset();
        } else {
            this.withoutChild = true
            this.withChild = false

            this.yourFormGroup.get('childName')!.reset();

            this.yourFormGroup.get('caretaker')!.reset();

        }
    }

    get getChildList() {
        return this.yourFormGroup.get('child_list') as FormArray
    }

    addChildtoChildListArray(child: FullChild) {
        let childForm = this.fb.group(
            {
                firstName: new FormControl(child?.firstName, Validators.required),
                lastName: new FormControl(child?.lastName, Validators.required),
                childCode: new FormControl(child?.childCode, Validators.required),
                centreCode: new FormControl(child?.centreCode, Validators.required),
                centreName: new FormControl(child?.centreName, Validators.required),
                care_taker_list: this.fb.array([child.guardians])
            })

        if (!this.childCodeIndex.includes(child.childCode)) {
            try {
                this.getChildList.push(childForm)

            } catch (e: any) {
                console.log(e)
            }
            this.currentSelectedChild?.guardians.forEach(it => {
                if (!this.guardians.includes(it)) this.guardians.push(it)
            })
            this.childCodeIndex.push(child.childCode)
        }
        console.log('Child List Array', this.getChildList.length)
    }

    getCareTakerArray(i: number, child: any) {

        this.getChildList.push(child.guardians[i].firstName)
        this.currentSelectedChild?.guardians.forEach(it => {
            let g = it as any
            g.childCode =child.childCode
            g.childName =child.childName
            if (!this.guardians.includes(it)) this.guardians.push(g)
        })

    }

    onChildSelect($event: MatSelectChange) {
        this.guardians = []
        const result = $event.value
        console.log(result!!)
        result.forEach((children: any) => {
            children.guardians.forEach((it: any) => {
                const g = {
                    firstName: it.firstName,
                    lastName: it.lastName,
                    relation: it.relation,
                    childName:children.firstName + ' '+children.lastName,
                    childCode:children.childCode,

                }
                this.guardians.push(g)
                console.log(this.guardians)
            })
        })
    }

  private setValuesInForm(mData: any) {
    const formControls = this.yourFormGroup.controls;

    // Set values for top-level controls
    formControls['drop_location'].setValue(mData.drop_location || ''); // Handle potential undefined values
    formControls['pick_up_location'].setValue(mData.pick_up_location || '');
    formControls['child_list'].setValue(mData.child_List || []); // Assuming childList is an array
    formControls['purpose_of_trip'].setValue(mData.purpose_of_trip || '');
    formControls['reason'].setValue(mData.reason || null);
    formControls['isRoundTrip'].setValue(mData.isRoundTrip || false);
    formControls['remark'].setValue(mData.remark || '');
    formControls['status'].setValue(mData.status || '');
    formControls['trip_ended_at'].setValue(mData.trip_ended_at || new Date()); // Default to current date
    formControls['trip_started_at'].setValue(mData.trip_started_at || new Date()); // Default to current date
    formControls['trip_started_at'].setValue(mData.from || '');
    formControls['pick_up_meter_reading'].setValue(mData.pick_up_meter_reading || '');
    formControls['to'].setValue(mData.to || '');
    formControls['drop_meter_reading'].setValue(mData.drop_meter_reading || '');
    formControls['centreCode'].setValue(mData.centreCode || '');
    formControls['personName'].setValue(mData.personName || '');
    formControls['triptype'].setValue(mData.triptype || '');
    formControls['caretakers'].setValue(mData.caretakers || '');
    formControls['submittedFrom'].setValue(mData.submittedFrom || 'MySpace');

    // Set values for nested vehicle_info form group
    const vehicleInfoControls = (this.yourFormGroup.get('vehicle_info') as FormGroup).controls;
    vehicleInfoControls['driverAddress'].setValue(mData.driverAddress || '');
    vehicleInfoControls['driverContactNo'].setValue(mData.driverContactNo || null);
    vehicleInfoControls['driverName'].setValue(mData.driverName || '');
    // Omit email as commented out in the original code
    vehicleInfoControls['licenseNumber'].setValue(mData.licenseNumber || '');
    vehicleInfoControls['vehicleNumber'].setValue(mData.vehicleNumber || '');
    vehicleInfoControls['vehicleType'].setValue(mData.vehicleType || '');


  }
}
