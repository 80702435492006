import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AddDonorComponent} from "../add-donor/add-donor.component";
import {MatDialog} from "@angular/material/dialog";
import {DonorService} from "../donor.service";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ViewDonorComponent} from "../view-donor/view-donor.component";
import {Subject, takeUntil} from "rxjs";
import {EditDonorComponent} from "../edit-donor/edit-donor.component";
import firebase from "firebase/compat";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import Timestamp = firebase.firestore.Timestamp;
import {MatSelectChange} from "@angular/material/select";

export interface Donor{
  id:string
  donorName:string;
  pincode:string;
  zipcode:string;
  donorMobile:string;
  alternateNumber?:string;
  donoraddress:string;
  donorcity:string;
  email? :string;
  country? :string;
  state? :string;
  dob? :Timestamp;
  pan :string;
  donorType:string
}
@Component({
  selector: 'app-donor-table',
  templateUrl: './donor-table.component.html',
  styleUrls: ['./donor-table.component.scss']
})

export class DonorTableComponent implements OnInit,OnDestroy {

  displayedColumns: String[] = ['position','name','number','email', 'pan', 'donorType', 'city','address','Action'];
  donordata!:Donor[];
  private _destroyed$ = new Subject();



  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<Donor>;
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  donor=this.permissions.filter((x)=>x.functionName=='Donor')
  donorType: any[] = [];


  constructor(private matdialog: MatDialog,
              private readonly mSnackbar:MatSnackBar,
              private donorservice:DonorService) {// Create 100 users
    const users = Array.from({length: 100} )
    this.dataSource = new MatTableDataSource(this.donordata)


  }

  ngOnInit(): void {


    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

//Read
//     this.donorservice.getDonor().subscribe(titu=>{
//       this.donordata=[]
//       titu.forEach(y=>{
//         console.log("payload: ",y.payload.toJSON())
//         let donor = y.payload.toJSON() as donor
//         if (y.key != null) {
//           donor ['$key'] = y.key;
//         }
//         this.donordata.push(donor)
//         this.dataSource =new MatTableDataSource(this.donordata)
//         this.dataSource.paginator = this.paginator;
//       })
//
//     })
    this.donorservice.getDonorFromfirestore().pipe(
      takeUntil(this._destroyed$)
    ).subscribe(titu=>{
      this.donordata=[]
      titu.forEach(y=>{
        // console.log("payload: ",y)
        // let donor = y.payload.doc.data() as Donor
        // if (y.payload.doc.id != null) {
        //   donor.id = y.payload.doc.id;
        // @ts-ignore
        this.donordata.push(y as Donor)
        })
        this.dataSource =new MatTableDataSource(this.donordata)

        this.dataSource.paginator = this.paginator;
      this.donorType = this.dataSource.data.filter(x => x.donorType)
      console.log(this.donorType)
      })


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openpopup() {
    this.matdialog.open(AddDonorComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }

  ViewDonor(element:Donor) {
    this.matdialog.open(ViewDonorComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      data:element}
    )
  }
  EditDonor(element:Donor) {
    this.matdialog.open(EditDonorComponent,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
        data:element}
    )
  }
  ngOnDestroy(): void {
    // @ts-ignore
    this._destroyed$.next();
    this._destroyed$.complete();
  }

    DeleteDonor(element:string) {
    this.donorservice.deleteDonor(element).then(()=>{
      this.mSnackbar.open('Donor Deleted Successfully','',{
        panelClass:'success',
        duration:3000
      })
    })

    }

  onDonorType($event: MatSelectChange) {


  }
}
