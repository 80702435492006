<!-- donor-table.component.html -->
<div class="ngxTableHeader">
  <ul class="header-buttons-left ms-0 mb-0">
    <li class="dropdown">
      <h2>Online Donor Table</h2>
    </li>
          <li class="dropdown m-l-20">
            <label for="search-input"><i class="material-icons search-icon">search</i></label>
            <input placeholder="Search" type="text" class="browser-default search-field"
                   (keyup)='applyFilter($event)' aria-label="Search box" #input>
          </li>
  </ul>
  <ul class="header-buttons mr-2">
<!--    <li>-->
<!--      <button   mat-mini-fab color="primary" *ngIf="unitSponsorship[0].all || unitSponsorship[0].add" class="ms-3 mt-3" (click)="AddUnitSponsorship()" >-->
<!--        <mat-icon class="col-white" >add</mat-icon>-->
<!--      </button>-->
<!--    </li>-->
    <!--        <li>-->
    <!--          <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
    <!--            <img src="assets/images/icons/xlsx.png" alt="" />-->
    <!--          </div>-->
    <!--        </li>-->
  </ul>
</div>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Donor Mobile Column -->
  <ng-container matColumnDef="donorMobile">
    <th mat-header-cell *matHeaderCellDef> Donor Mobile </th>
    <td mat-cell *matCellDef="let donor"> {{ donor.donorMobile }} </td>
  </ng-container>

  <!-- Donor Name Column -->
  <ng-container matColumnDef="donorName">
    <th mat-header-cell *matHeaderCellDef> Donor Name </th>
    <td mat-cell *matCellDef="let donor"> {{ donor.donorName }} </td>
  </ng-container>

  <!-- Donor Address Column -->
  <ng-container matColumnDef="donorAddress">
    <th mat-header-cell *matHeaderCellDef> Donor Address </th>
    <td mat-cell *matCellDef="let donor"> {{ donor.donoraddress }} </td>
  </ng-container>

  <!-- Donor City Column -->
  <ng-container matColumnDef="donorCity">
    <th mat-header-cell *matHeaderCellDef> Donor City </th>
    <td mat-cell *matCellDef="let donor"> {{ donor.donorcity }} </td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let donor"> {{ donor.email }} </td>
  </ng-container>

  <!-- PAN Column -->
  <ng-container matColumnDef="pan">
    <th mat-header-cell *matHeaderCellDef> PAN </th>
    <td mat-cell *matCellDef="let donor"> {{ donor.pan }} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let donor">
      <button mat-icon-button color="accent" matTooltip="View" (click)="openViewDialog(donor)"><mat-icon>visibility</mat-icon></button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
<mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
               showFirstLastButtons
               aria-label="Select page of periodic elements">
</mat-paginator>
