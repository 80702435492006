<form class="about" [formGroup]="form" (ngSubmit)="SubmitData()">
  <h1 class="text-center mb-4 bg-warning p-2 rounded text-dark">Unit Sponsorship Registration</h1>
  <div class="row">

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Donor Name</mat-label>
      <input matInput placeholder="Donor Name" required formControlName="donorName">
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Mobile No.</mat-label>
      <input matInput placeholder="Mobile No." required formControlName="mobile">
      <mat-icon matSuffix>phone</mat-icon>
      <mat-error *ngIf="mobile.invalid && (mobile.touched || mobile.dirty)">
        <span style="color: red;" *ngIf="mobile.errors?.required">*It is required.</span>
        <span style="color: red;" *ngIf="mobile.errors?.pattern">*Enter only numbers.</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Address</mat-label>
      <input matInput placeholder="Address" required formControlName="address">
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>State</mat-label>
      <input matInput placeholder="State Name" required formControlName="state">
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Country</mat-label>
      <input matInput placeholder="Country Name" required formControlName="country">
      <mat-error *ngIf="country.invalid && (country.touched || country.dirty)">
        <span style="color: red;" *ngIf="country.errors?.required">*It is required.</span>
        <span style="color: red;" *ngIf="country.errors?.pattern">*Enter only characters.</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>City</mat-label>
      <input matInput placeholder="City" required formControlName="city">
      <mat-error *ngIf="city.invalid && (city.touched || city.dirty)">
        <span style="color: red;" *ngIf="city.errors?.required">*It is required.</span>
        <span style="color: red;" *ngIf="city.errors?.pattern">*Enter only characters.</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Pin Code</mat-label>
      <input matInput #postalCode maxlength="6" required placeholder="Pin Code" formControlName="pincode">
      <mat-hint align="end">{{ postalCode.value.length }} / 5</mat-hint>
      <mat-error *ngIf="pincode.invalid && (pincode.touched || pincode.dirty)">
        <span style="color: red;" *ngIf="pincode.errors?.required">*It is required.</span>
        <span style="color: red;" *ngIf="pincode.errors?.pattern">*Enter only numbers.</span>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" required formControlName="email">
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Date" required formControlName="date">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="picker2" placeholder="Date" required formControlName="date2">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Angle Name</mat-label>
      <input matInput placeholder="Types" required formControlName="angleName">
    </mat-form-field>
    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Payment Information</mat-label>
      <mat-select formControlName="paymentInformation">
        <mat-option value="check"> Cheque</mat-option>
        <mat-option value="upi"> UPI</mat-option>
        <mat-option value="online">Online</mat-option>
        <mat-option value="=NEFT">Bank Transfer/NEFT</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Remark Transaction ID/ Reference Number</mat-label>
      <input matInput required formControlName="remark">
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Amount</mat-label>
      <input matInput required formControlName="totalTransactionAmount">
    </mat-form-field>

    <mat-form-field class="col-md-6 col-sm-12" appearance="outline">
      <mat-label>Pan Number</mat-label>
      <input matInput required formControlName="panNumber">
    </mat-form-field>


    <div class="col-12 text-center">
      <button mat-raised-button class="px-3" color="warn" (click)="cancel()">Cancel</button>
      &nbsp;
      <button mat-raised-button class="px-4" type="submit" [disabled]="!submit" color="primary" (click)="SubmitData()">Save</button>
    </div>
  </div>
</form>
