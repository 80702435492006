import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {MatDialog} from "@angular/material/dialog";
import {AddTypeComponent} from "./add-type/add-type.component";
import {AngularFireDatabase, AngularFireList} from "@angular/fire/compat/database";
import {Observable, Subscription} from "rxjs";
import {MatSelectChange} from "@angular/material/select";
import {DataSource} from "@angular/cdk/collections";
import {MatInput} from "@angular/material/input";
import {MatFormField} from "@angular/material/form-field";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {needConfirmation} from "../../confirm-dialog/confirm-dialog.decorator";
import {UserRole} from "../rename/add-user-role/add-user-role.component";

export interface ListType {
    name: string
    id?: string
}

export class TypeData {
    key = ''
    name = ''
}

@Component({
    selector: 'app-types',
    templateUrl: './types.component.html',
    styleUrls: ['./types.component.scss']

})
export class TypesComponent implements OnInit, AfterViewInit {
    selectedType = '';
    mRef: Subscription | undefined;
    types: Array<ListType> = []
    displayedColumns: string[] = ['name', 'Actions'];
    typeList$: Observable<ListType[]>
    fieldForm!: FormGroup

    data: Array<TypeData> = []
    dataSource: MatTableDataSource<TypeData>;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  type=this.permissions.filter((x)=>x.functionName=='Types')


  constructor(private matDialog: MatDialog,
                private mDatabase: AngularFireDatabase) {
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator =this.paginator
      this.dataSource.sort =this.sort
        this.typeList$ = mDatabase.list<ListType>('typelist').valueChanges()
        this.fieldForm = new FormGroup({
            field: new FormControl('', Validators.required)
        })
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {


        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    applyFilter(event: MatSelectChange) {
        const filterValue = (event).value;
        console.log('dfdsf', filterValue)
        this.getData(filterValue)
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }
@needConfirmation({title:'Confirm',message:'So you want to add this type?'})
    AddType(enterAnimationDuration: string, exitAnimationDuration: string) {
        this.matDialog.open(AddTypeComponent, {
            width: '600px',
            enterAnimationDuration,
            exitAnimationDuration,
        });

    }
    //  150  556
    // 6 4 9 3 8

    getData(filterValue: any) {
        this.mRef = this.mDatabase.list(
            'types/' + filterValue).snapshotChanges().subscribe(snaps => {
            this.data = []
            snaps.forEach(snap => {
                console.log(snap.payload.val())
                const a = new TypeData()
                a.key = snap.key as string
                a.name = snap.payload.val() as string
                this.data.push(a)
                this.dataSource = new MatTableDataSource<TypeData>(this.data)
              this.dataSource.paginator = this.paginator

            })
        })

    }

  @needConfirmation()
    AddTypData() {
        const key = this.mDatabase.database.ref('types/' + this.selectedType).push().key
        if (this.fieldForm.valid) {
            const field = this.fieldForm.get('field')?.value
            this.mDatabase.object('types/' + this.selectedType + "/" + key).set(field).then(() => {
                this.fieldForm?.reset()
            })
        } else alert('Please Enter Form')
// alert(this.field)
    }


  @needConfirmation()
    Delete(key: string) {
        this.mDatabase.object('types/' + this.selectedType + "/" + key).remove().then(() => {

        })
    }
}
