import {Component, Inject, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {UploadModel} from "../../../models/UploadModel";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
interface UnitSP{
  sr:string
  profile:string
}
@Component({
  selector: 'app-renewal-unit-sponsorship',
  templateUrl: './renewal-unit-sponsorship.component.html',
  styleUrls: ['./renewal-unit-sponsorship.component.scss']
})
export class RenewalUnitSponsorshipComponent implements OnInit {
  dataSource: any;
  mDbType:  'firestore' = 'firestore';
  mStoragePath?: string = 'unitSponsorships/'
  mData:any

  displayedColumns: string[] = ['SR. N0', 'Profile', 'Name', 'Age', 'Address', 'Case Study',];
  mPath: any;
  startDate: any;
  endDate: any;
  constructor(   private readonly  mFirestore:AngularFirestore,
                 private readonly mStorage:AngularFireStorage,
                 @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.mData = data
this.mPath ='sponsorshipUploads/'+this.mData.id
    this.mStoragePath = this.mStoragePath + '/' + this.mData.id
    // this.mPath =this.env.documents+'/'+ this.mPath + '/assessmentSessions/' + data.id
    // console.log(`Document will be uploaded to ${this.mStoragePath} on ${this.mDbType} in ${this.mPath} `)
    // this.documents$  = this.mFirestore.doc( this.mPath).valueChanges({idField:'id'})
    // this.documents$.subscribe(res=>{
    //   // @ts-ignore
    //   this.mDocuments = res.documents as UploadModel
    // })
  }

  ngOnInit(): void {
    const name= {sr:'1',profile:'NA'}
    this.data.push(name)
    this.data.push(name)
    this.data.push(name)
    this.data.push(name)
    this.dataSource = new MatTableDataSource<UnitSP>(this.data)
    this.dataSource.data =this.data

  }

}
