import {AfterViewInit, Component, isDevMode, OnDestroy, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ChildCreateComponent} from "../child-create/child-create.component";
import {ChildService} from "../child.service";
import {Child} from "../childdata";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ChildEditComponent} from "../child-edit/child-edit.component";
import {MatTableDataSource} from "@angular/material/table";
import {FullChild} from "../../../interfaces/FullChild";
import {Router} from "@angular/router";
import {ViewChildComponent} from "../view-child/view-child.component";
import {Subject, takeUntil} from "rxjs";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {collectionGroup} from "@angular/fire/firestore";
import {UserRole} from "../../rename/add-user-role/add-user-role.component";
import {LogService} from "../../../services/log.service";
import {ViewRegisteredChildComponent} from "../view-registered-child/view-registered-child.component";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {environment} from "../../../../environments/environment";
import {ChildJourneyComponent} from "../child-journey/child-journey.component";


@Component({
    selector: 'app-childlist',
    templateUrl: './childlist.component.html',
    styleUrls: ['./childlist.component.scss']
})

export class ChildlistComponent implements AfterViewInit, OnDestroy {
    displayedColumns: String[] = ['position', 'firstName', 'lastName', 'age', 'gender', 'hospitalname', 'mobile', 'city','status', 'Action'];
    childData!: FullChild[]
    private _destroyes$ = new Subject()
    @ViewChild(MatPaginator) paginator1!: MatPaginator;
    @ViewChild(MatSort) sort1!: MatSort;
    dataSource: MatTableDataSource<FullChild>;
    child?: FullChild
    allpermission = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
    registraionPermissions = this.allpermission.filter((x) => x.functionName == 'Child Admission')
    prescreeningPermissions = this.allpermission.filter((x) => x.functionName == 'Child Prescreening')
    childcodeEntered = ''
    env = isDevMode() ? environment.testMode : environment.productionMode


    constructor(private matdialog: MatDialog,
                private router: Router,
                private mFirestore:AngularFirestore,
                private mLogService: LogService,
                private childService: ChildService) {
        this.dataSource = new MatTableDataSource(this.childData);
    }

    ngAfterViewInit(): void {
        // this.dataSource.paginator = this.paginator1;
        // this.dataSource.sort = this.sort1;

        const childs = this.childService.fetchTestChildList()
        childs.pipe(
            takeUntil(this._destroyes$)
        ).subscribe(data => {
            // console.assessment-table(data)
            const tempdocs: FullChild[] = []

            data.forEach((docSnapshots) => {
                // const c:Child
                // const c:Child=docSnapshots.payload.doc.id
                const child = ({id: docSnapshots.payload.doc.id, ...docSnapshots.payload.doc.data()} as FullChild)
                if (child.alloted != true) tempdocs.push(child)
                // console.assessment-table(docSnapshots.payload.doc.id)
                this.child = child
            })
            tempdocs.reverse()
            this.childData = tempdocs


            this.dataSource = new MatTableDataSource(this.childData)

            this.dataSource.data = this.childData
            this.dataSource.sort = this.sort1
            this.dataSource.paginator = this.paginator1
            // const x = data as Child;
            // this.childData.(x)
            // console.log(x.children)

            // console.log('Child: ', y[0])
        })
        // If the user changes the sort order, reset back to the first page.

        this.sort1.sortChange.subscribe(() => (this.paginator1.pageIndex = 0));

    }


    openpopup() {
        this.matdialog.open(ChildCreateComponent,
            {
                width: '90%', height: '95%',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '200ms',
            },
        )
    }

    datashow(index: Child) {
        console.log('showdata :' + index)
        this.matdialog.open(ViewChildComponent,
            {
                width: '90%', height: '95%',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '100ms',
                data: index as unknown as FullChild
                // @ts-ignore
            }
        )
    }

    showEdit(index: Child) {
        console.log('showdata :' + index)
        this.matdialog.open(ChildEditComponent,
            {
                width: '90%', height: '95%',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '100ms',
                data: index as unknown as FullChild
                // @ts-ignore
            }
        )
    }

    @needConfirmation()
    delete(element: any) {
        this.childService.deletePrescreening(element.id).then(res => {
            let activity = {
                date: new Date().getTime(),
                section: 'Prescreening',
                action: 'Delete',

                description: `Child Deleted ${element.firstName} ${element.lastName}} by  `,
                currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
        })
    }

    applyFilter($event: Event) {
        const filterValue = ($event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }

    }


    GoToAllotment(element: FullChild) {
        this.router.navigateByUrl('home/child-admission', {state: element})
    }

    DirectAllot() {
        this.router.navigateByUrl('home/child-admission')
    }

    ngOnDestroy(): void {
        this._destroyes$.next(null)
        this._destroyes$.complete()
    }

    searchChildByCode() {

        this.childService.findChildByCode(this.childcodeEntered).then(res => {
            let c = res
            if (c == null) alert('Invalid Child Code \n Please type in format of AL-YYYY-00XXXX')
            else {
                this.matdialog.open(ViewRegisteredChildComponent,
                    {
                        width: '90%', height: '95%',
                        enterAnimationDuration: '100ms',
                        exitAnimationDuration: '100ms',
                        data: c.data() as FullChild
                        // @ts-ignore
                        // }
                    })
            }
        })
    }

    Active(element:any) {
        this.mFirestore.collection(this.env.prescreening).doc(element.id).update({'status': 'Active'})
    }
    Closed(element:any) {
        this.mFirestore.collection(this.env.prescreening).doc(element.id).update({'status': 'Closed'})
    }

}
