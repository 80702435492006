<div class="container gallery">
<div class="d-flex justify-content-end mb-4">
  <button mat-raised-button color="primary" (click)="onClose()">Upload</button>
</div>


  <div class="row gallery">
    <div *ngFor="let documents of dataSource" class="col-md-4 mb-2">
      <mat-card *ngFor="let d of documents">
        <img [src]="d.url" [alt]="d.fileName"
             style="width: 100%; aspect-ratio: 3/2; object-fit: cover"
             class="mb-2"
             [class.selected]="isSelected(d.url)"
             (click)="selectImage(d.url)">
        <p class="fw-bold text-xxl">{{d.description}}</p>
      </mat-card>
    </div>
  </div>



</div>
