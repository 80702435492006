import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, Validators} from "@angular/forms";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FullChild} from "../../../interfaces/FullChild";
import {arrayUnion} from "@angular/fire/firestore";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-medical-history',
  templateUrl: './add-medical-history.component.html',
  styleUrls: ['./add-medical-history.component.scss']
})
export class AddMedicalHistoryComponent implements OnInit {
  myForm: any;
  selectedImage: File | null = null;
  childData: FullChild
  constructor(
    private readonly mDialogRef: MatDialogRef<AddMedicalHistoryComponent>,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private readonly mDialog: MatDialog,
    private readonly mSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) mdata: any,
    private formBuilder: FormBuilder
  ) {
    this.childData = mdata
    this.myForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      height: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      weight: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
      next_opd_date: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
  }


  onFileSelected(event: any) {
    this.selectedImage = event.target.files[0];
  }

  onSubmit() {
    if (this.myForm.valid) {
      if (this.selectedImage) {
        const filePath = `images/${Date.now()}_${this.selectedImage.name}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, this.selectedImage);

        task.snapshotChanges().subscribe(
          (snapshot) => {
            if (snapshot?.state === 'success') {
              // The image was successfully uploaded
              // You can now store the image URL in Firestore or perform other actions.

              fileRef.getDownloadURL().subscribe((url) => {
                let obj = {
                  res_url: url,
                  title: this.myForm.get('title')?.value,
                  description: this.myForm.get('description')?.value,
                  height: this.myForm.get('height')?.value,
                  weigth: this.myForm.get('height')?.value,
                  medical_history_type: 'During Stay',
                  res_timing: new Date().toString()
                }
                this.firestore.collection('ALChild').doc(this.childData.childCode).update({
                  "medical_history": arrayUnion(obj)
                },).then(() => {
                  this.mSnackBar.open("Submitted Successfully", '', {
                    panelClass: 'success',
                    duration: 3000
                  })
                  this.mDialogRef.close()

                });
              });
            }
          },
          (error) => {
            // Handle the error, e.g., show an error message
            console.error('Error uploading image:', error);
          }
        );
      } else {
        let obj = {
          title: this.myForm.get('title')?.value,
          description: this.myForm.get('description')?.value,
          height: this.myForm.get('height')?.value,
          weigth: this.myForm.get('height')?.value,
          medical_history_type: 'During Stay',
          res_timing: new Date().toString()
        }
        this.firestore.collection('ALChild').doc(this.childData.childCode).update({
          "medical_history": arrayUnion(obj)

        },).then(() => {
          this.mSnackBar.open("Submitted Successfully", '', {
            panelClass: 'success',
            duration: 3000
          })
          this.mDialogRef.close()
        });
      }

    } else {
      this.mSnackBar.open("Invalid Form")
    }

  }
}
