import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {VehicleService} from "../vehicle.service";
import {MatDialog} from "@angular/material/dialog";
import {AddCarFuelsDetailsComponent} from "../add-car-fuels-details/add-car-fuels-details.component";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-all-car-fuels-details-new',
  templateUrl: './all-car-fuels-details-new.component.html',
  styleUrls: ['./all-car-fuels-details-new.component.scss']
})
export class AllCarFuelsDetailsNewComponent implements OnInit {
  displayedColumns: string[] = [ 'date', 'vehicleNumber', 'driverName', 'fuels', 'kilometers', 'action'];
  dataSource: any[] = []
   drivers: any[] =[];
  vehicles: any[]=[];
   driverName: any;
   selectedVehicle: any;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private mDialog: MatDialog,
              private mVehicleService: VehicleService) {

  }

  ngOnInit(): void {
 this.mVehicleService.getCarFuelsData().subscribe(res =>{
      this.dataSource = res
      console.log(this.dataSource)
    })

    this.mVehicleService.getDriver().subscribe(res => {
      this.drivers = res
      console.log(this.drivers)
    })

    this.mVehicleService.getVehicles().subscribe(res => {
      console.log(res)
      this.vehicles = res
    })
  }

  OpenDialog() {
    this.mDialog.open(AddCarFuelsDetailsComponent, {
      maxHeight: '60vh',
    });

  }

  editInfo(element:any) {
    this.mDialog.open(AddCarFuelsDetailsComponent, {
      data:element
    })

  }

  deleteInfo(element:any) {
    this.mVehicleService.deleteCarFuelsData(element.id).then(res => {
      console.log("Deleted Successfully")
    })

  }

  onDriverSelect($event:any){
    this.driverName = $event.value
  }

  OnVehicleSelect($event:any){
    this.selectedVehicle = $event.value

  }

  applyFilter($event: KeyboardEvent) {
    const filterValue = ($event.target as HTMLInputElement).value;

    // this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
