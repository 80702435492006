import {Component, Inject, isDevMode, OnInit, ViewChild} from '@angular/core';
import {ChildService} from "../../child/child.service";
import {FullChild} from "../../../interfaces/FullChild";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {MatSelectChange} from "@angular/material/select";
import {AngularFirestore, DocumentData, DocumentReference} from "@angular/fire/compat/firestore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatCheckboxChange} from "@angular/material/checkbox";
import * as constants from "constants";
import {UnitSponsorshipService} from "../unit-sponsorship.service";
import {environment} from "../../../../environments/environment";
import firebase from "firebase/compat";
import WriteBatch = firebase.firestore.WriteBatch;
import firestore = firebase.firestore;
import {collection, doc, Firestore, writeBatch} from "@angular/fire/firestore";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: 'app-allot-sponsorship-child',
    templateUrl: './allot-child-sponsorship.component.html',
    styleUrls: ['./allot-child-sponsorship.component.scss']
})
export class AllotChildSponsorshipComponent implements OnInit {

    env = isDevMode() ? environment.testMode : environment.productionMode

    childData: any[] = []
    mData: any
    totalAmount = 0
    datasource: MatTableDataSource<FullChild>
    selectedChild: any[] = []
    displayedColumns: string[] = [
        'sr',
        'firstName',
        'lastName',
        'selectUnit',
        'duration',
        'date',
        'checked',
    ];

    selectedCentre = 'All'
    centres$: Observable<Centre[]>

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild(MatSort) sort!: MatSort;

    constructor(private childService: ChildService,
                private centreService: CentreService,
                private mService: UnitSponsorshipService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private dialogReg: MatDialogRef<AllotChildSponsorshipComponent>,
                private mDatabase: AngularFirestore,
                private firestore: Firestore,
                private readonly mSnackBar:MatSnackBar
    ) {
        this.mData = data
        this.centres$ = this.centreService.getAllCentresSorting(this.selectedCentre)
        this.datasource = new MatTableDataSource<any>(this.childData)
    }

    ngOnInit(): void {

    }

    onCentreSelect($event: MatSelectChange) {

        this.mService.fetchNonSponsoredChildren(this.selectedCentre).subscribe(res => {
            let data = res.docs.map(c => c.data() as FullChild)
           this.childData =[]
            console.log(data)
            data.forEach(c => {
                let x: any = {
                    firstName: c.firstName,
                    lastName: c.lastName,
                    childCode: c.childCode,
                    centreCode: c.centreCode,
                    centreName: c.centreName,
                    unit: c.selectUnit,
                    diagnosis: c.diagnosisSelected,
                    childCity: c.city,
                    childState: c.state,
                    dob: c.dob,
                  sponsored:c.sponsored
                }
                this.childData.push(x)
            })
            this.datasource = new MatTableDataSource<any>(this.childData)
          this.datasource.paginator= this.paginator;

            // console.log(this.childData.length)
        })
    }
    addData(child: any) {
        this.selectedChild.push(child)
        this.totalAmount = 0
        this.selectedChild.forEach(it => {
            let a = (+it.duration) * 25000
            this.totalAmount = this.totalAmount + a
            it.amount = a
        })
        // this.mData.amount = +this.mData.amount - +this.totalAmount
        console.log('SELECTED CHILDREN', this.selectedChild)
    }
    removeData(child: any) {
        this.selectedChild = this.selectedChild.filter(c => c != child)
        this.totalAmount = 0
        if (this.selectedChild.length == 0) this.totalAmount = 0
        this.selectedChild.forEach(it => {
            let a = (+it.duration) * 25000
            it.amount = a

            this.totalAmount = this.totalAmount + a
        })
        // this.mData.amount = this.mData.amount - this.totalAmount

    }

    testSubmit() {
        let a = {
            sponsorId: this.mData.id, // Add the sponsor's ID or any other relevant data
            children: this.selectedChild,
            totalAmount: this.totalAmount
        }
        console.log(a)
    }

    onSubmit() {
      {
        const batch = writeBatch(this.firestore)
        this.selectedChild.forEach(it => {
          const mergedObject = {...it, ...this.mData};
          mergedObject.totalAmountUsed = this.totalAmount
          const firestorePushId = generatePushId();
          console.log('MERGEOBJECT: ', mergedObject)
          console.log('firestorePushId : ', firestorePushId)
          const sponsoredChildrenRef = doc(this.firestore, `${this.env.sponsoredChildren}/${firestorePushId}`)
          batch.set(sponsoredChildrenRef, mergedObject, {merge: true})   //step 1  adding sponsored children
          console.log('sponsoredChildrenRef', sponsoredChildrenRef)
          const updateSponsorchildStatus = doc(this.firestore, `${this.env.child}/${it.childCode}`)
          batch.set(updateSponsorchildStatus, {sponsored: true}, {merge: true})    //step2 changing their status in ALCHILD
          console.log('updateSponsorchildStatus', updateSponsorchildStatus)

          // this.mFirestore.collection(this.env.sponsoredChildren).add(mergedObject)  //-
          // this.mService.updateSponsoredChildStatus(it,true)  //-

          //*     this.mService.addSponsoredChildren(mergedObject).then(() => {
          //         this.mService.updateSponsoredChildStatus(it,true)
          //
          //         console.log('Data added to Firestore successfully.');
          //         // Optionally, you can perform any other actions after successful storage.
          //     })
          //         .catch((error) => {
          //             console.error('Error adding data to Firestore:', error);
          //         })
        })
        console.log("total remaining amount", this.mData.remainingAmount)
        console.log("total amount", this.totalAmount)
        let remainingAmount = this.mData.remainingAmount - this.totalAmount
        console.log('remainingAmount',remainingAmount)
        console.log('this.mData.remainingAmount',this.mData.remainingAmount)
        console.log('this.totalAmount',this.totalAmount)
        // alert(remainingAmount)
        const transactionUpdate = doc(this.firestore, `${this.env.unitsponsorship}/${this.mData.transactionId}`)
        batch.update(transactionUpdate, {'remainingAmount': remainingAmount})  //step4 Updating remainging amount
        console.log('transactionUpdate', transactionUpdate)

        batch.commit().then(() => {
          this.mSnackBar.open('Successfully Saved')._dismissAfter(3000)

          this.dialogReg.close()

        })
      }
        // this.mDatabase.collection(this.env.unitsponsorship).doc(this.mData.transactionId).update({'remainingAmount': remainingAmount}).then(() => {

        // })

        // Now, store the data in Firestore

    }
}

function generatePushId() {
    // Characters used for the ID
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    // Length of the ID
    const length = 20;

    let pushId = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        pushId += chars.charAt(randomIndex);
    }

    return pushId;
}
