
<div class="container">
  <prettyjson [obj]="donor"></prettyjson>

  <div class=" pt-5 ">
    <mat-card>
      <mat-card-header>
        <!--            <mat-card-title-group>-->
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>View Donor  </mat-card-title>
        <mat-card-subtitle> </mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <tr><th scope="row">Donor Name</th>
            <td>{{donor.donorName}}</td>
          </tr>
          <tr><th scope="row">Donor Address</th>
            <td>{{donor.donoraddress}}</td>
          </tr>
          <tr *ngIf="donor.dob"><th >DOB</th><td>{{(donor!.dob).toDate() |date:'mediumDate'}}</td></tr>
          <tr><th >Amount</th><td>{{donor.email}}</td></tr>
          <tr><th >PAN</th><td>{{donor.pan}}</td></tr>
          <tr><th >Mobile Number</th><td>{{donor.donorMobile}}</td></tr>
          <tr><th >Alternate Number</th><td>{{donor.alternateNumber}}</td></tr>
          <tr><th >City</th><td>{{donor.donorcity}}</td></tr>
          <tr><th >Email</th><td>{{donor.email}}</td></tr>
          <tr><th >State</th><td>{{donor.state}}</td></tr>
          <tr><th >Country</th><td>{{donor.country}}</td></tr>
          <tr><th >Pincode</th><td>{{donor.pincode}}</td></tr>
          <!--              <tr><th >Method</th><td>{{donor.method}}</td></tr>-->


          </tbody>
        </table>
      </mat-card-content>
    </mat-card>



  </div>

  <!--      RESPONSE-->

</div>

