<div class="container">


<!--<prettyjson [obj]="mAllData"> </prettyjson>-->

        <section>
          <div class="ngxTableHeader">
            <ul class="header-buttons-left ms-0 mb-0">
              <li class="dropdown">
                <h2>Bookings</h2>
              </li>
              <li class="dropdown m-l-20">
                <label for="search-input"><i class="material-icons search-icon">search</i></label>
                <input placeholder="Search" type="text" class="browser-default search-field"
                       (keyup)='applyFilter($event)' aria-label="Search box" #input>
              </li>
            </ul>
            <ul class="header-buttons mr-2">
              <!--      <li>-->
              <!--        <button   mat-mini-fab color="primary" (click)="openAddCarLog()">-->
              <!--          <mat-icon class="col-white" >add</mat-icon>-->
              <!--        </button>-->
              <!--      </li>-->
            </ul>
          </div>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <!--            <ng-container matColumnDef="position">-->
                <!--                <th mat-header-cell *matHeaderCellDef>Sr.No</th>-->
                <!--                <td mat-cell *matCellDef="let element"></td>-->
                <!--            </ng-container>-->

                <!-- Name Column -->
                <ng-container matColumnDef="donated_by">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Donor Name</th>
                    <td mat-cell *matCellDef="let element">{{element.donated_by}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef>City</th>
                    <td mat-cell *matCellDef="let element"> {{element.city}}</td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="Address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let element"></td>
                </ng-container>

                <ng-container matColumnDef="pan">
                    <th mat-header-cell *matHeaderCellDef>Pan No.</th>
                    <td mat-cell *matCellDef="let element">{{element.pan}}</td>
                </ng-container>

                <ng-container matColumnDef="mealSlot">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Meal Slot</th>
                    <td mat-cell *matCellDef="let element">{{element.mealSlot}}</td>
                </ng-container>

                <ng-container matColumnDef="eventName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Event Name</th>
                    <td mat-cell *matCellDef="let element">{{element.eventName}}</td>
                </ng-container>

                <ng-container matColumnDef="event_message">
                    <th mat-header-cell *matHeaderCellDef>Event Message</th>
                    <td mat-cell *matCellDef="let element">{{element.eventMessage}}</td>
                </ng-container>

                <ng-container matColumnDef="centreCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Centre Code</th>
                    <td mat-cell *matCellDef="let element">{{element.centreCode}}</td>
                </ng-container>

                <ng-container matColumnDef="start">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking Date</th>
                    <td mat-cell *matCellDef="let element">{{element.start}}</td>
                </ng-container>
                <ng-container matColumnDef="payment_date">
                    <th mat-header-cell *matHeaderCellDef>Payment Date</th>
                    <td mat-cell *matCellDef="let element">{{element.start}}</td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>State</th>
                    <td mat-cell *matCellDef="let element">{{element.state}}</td>
                </ng-container>
                <ng-container matColumnDef="booking_id">
                    <th mat-header-cell *matHeaderCellDef>Booking ID</th>
                    <td mat-cell *matCellDef="let element">{{element.order_id}}</td>
                </ng-container>
                <ng-container matColumnDef="otg_certificate">
                    <th mat-header-cell *matHeaderCellDef>Need 80G</th>
                    <td mat-cell *matCellDef="let element">{{element.need80g ? 'Yes' : 'No'}}</td>
                </ng-container>
                <ng-container matColumnDef="recieptNo">
                    <th mat-header-cell *matHeaderCellDef>Reciept No</th>
                    <td mat-cell *matCellDef="let element">{{element.receipt_id}}</td>
                </ng-container>
                <ng-container matColumnDef="Action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Action</th>
                    <td mat-cell *matCellDef="let element">
                        <!--                                        <button mat-icon-button color="accent" (click)="viewDialog(element)" matTooltip="View"><mat-icon>remove_red_eye</mat-icon></button>-->
                        <button *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].edit " mat-icon-button color="primary" (click)="allotSlot(element)" matTooltip="Allot"><mat-icon>book</mat-icon></button>
<!--                        <button  mat-icon-button color="warn" *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].archive "  matTooltip="Delete"><mat-icon>delete</mat-icon></button>-->
<!--                        <button mat-icon-button *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].view " color="accent"  matTooltip="Send Email"><mat-icon>email</mat-icon></button>-->

                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          <mat-paginator [pageSizeOptions]="[25,50,100,200,250,500,1000]" aria-label="Select page of users"></mat-paginator>


        </section>






</div>
