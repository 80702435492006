import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    endDate?: Date | null
    startDate?: Date | null

    constructor(
      private readonly authService:AuthService,
      private router:Router
    ) {
      // if(authService.getRole()=='Teacher'){
      //   this.router.navigateByUrl('education')
      // }
      // else if(authService.getRole()=='Psychologist'){
      //   this.router.navigateByUrl('/home/counseling')
      // }
      // else if(authService.getRole()=='Centre'){
      //     this.router.navigateByUrl('/home/childdetails')
      // }
    }

    ngOnInit(): void {
    }


}
