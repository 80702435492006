import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Employee} from "../../../interfaces/Employee";
import {EmployeeService} from "../../../services/employee.service";

import {AddUserRoleComponent, UserRole} from "../../rename/add-user-role/add-user-role.component";
import {LogService} from "../../../services/log.service";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {CreateEmployee} from "../create-employee/create-employee";
import {EditEmployeeInfoComponent} from "../edit-employee-info/edit-employee-info.component";

@Component({
  selector: 'app-employeelist',
  templateUrl: './employeelist.component.html',
  styleUrls: ['./employeelist.component.scss']
})
export class EmployeelistComponent implements OnInit {
  displayedColumns: String[] = ['image','fullName','email','mobile','location','jobtitle' ,'department','status','action' ];
  employeedata!:Employee[];
data:UserRole[]=[]
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  dataSource: MatTableDataSource<Employee>
  permissions = JSON.parse(localStorage.getItem('permissions')!) as UserRole[]
  employees=this.permissions.filter((x)=>x.functionName=='Employees')


  constructor(private matdialog: MatDialog,
              private employeeservice:EmployeeService,
              private  readonly matDialog:MatDialog,
              private readonly mLogService:LogService,
              private  readonly mDatabase:AngularFireDatabase
              )
  { // Create 100 users
    const users = Array.from({length: 100} )
    this.dataSource = new MatTableDataSource(this.employeedata)
    console.log(this.dataSource)
  }


  ngOnInit(): void {
    this.addData()
    //Read
    this.employeeservice.getUsers().subscribe(snapshotchanges=>{
      this.employeedata=[]
      snapshotchanges.forEach(w=>{
        // console.log("payload: ",w)
        let emp = w.payload.toJSON() as Employee
        if (w.key != null) {
          emp['id'] = w.key;
        }
        this.employeedata.push(emp)


      })

        this.dataSource.data =this.employeedata
        this.dataSource.paginator = this.paginator;
      })


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }
  }


  openpopup() {
    this.matdialog.open(CreateEmployee,
      {
        width: '70%', height: '600px',
        enterAnimationDuration: '100ms',
        exitAnimationDuration: '200ms',
      }
    )
  }

  viewDialog(element:any) {

    this.matDialog.open(AddUserRoleComponent,{data:element,
      width:'800%',
      height:'90%'})

  }

  private addData() {
    this.data.push(   {functionName: "Child Admission", all:false,add:false,edit:false, archive:false, view:false, excel:false, showMenu:false })
    this.data.push({functionName: "Child Prescreening",all:false,add:false,edit:false , archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Counselling", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Education", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Meal Mate", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Unit Sponsorship", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "General Donation", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "CMS", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Child Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Counseling Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Education Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Donation Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Weekly Report", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Centres", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Employees", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Hospital", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Unit", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Types", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Donor", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Event", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Audit Logs", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
    this.data.push({functionName: "Dashboard", all:false,add:false,edit:false,  archive:false, view:false, excel:false, showMenu:false})
  }

  editDialog(element:any) {
    this.matDialog.open(EditEmployeeInfoComponent,{data:element,
      width:'800%',
      height:'90%'})

  }

  getStatus(element:any) {
    let status:string="Active"
    if(element.disabled==true) {
      status = "Inactive"
    } else status='Active'
  return status
  }
}
