<div class="container">
    <h2 class="rounded text-center">Centre Efficiency</h2>
    <mat-form-field appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker" >
            <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
            <input matEndDate placeholder="End date" [(ngModel)]="endDate">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
    <button mat-mini-fab color="primary" (click)="search()"><mat-icon>search</mat-icon></button>
</div>

<div>
    <div *ngFor="let d of weeklyDates"> {{d|date:'full'}}</div>

</div>
<div class="ngxTableHeader">
    <ul class="header-buttons-left ms-0 mb-0">
        <li class="dropdown">
            <h2>Occupancy Report <span *ngIf="startDate1">{{startDate1|date:'dd/MM/yyyy'}}</span><span *ngIf="endDate1">  -  {{endDate1|date:'dd/MM/yyyy'}}</span></h2>
        </li>
        <li class="dropdown m-l-20">
            <label for="search-input"><i class="material-icons search-icon">search</i></label>
            <input placeholder="Search" id="search-input" type="text" class="browser-default search-field"
                   (keyup)='applyFilter($event)' aria-label="Search box" #input>
        </li>
    </ul>
    <ul class="header-buttons mr-2">

        <li>
<!--            <div  class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">-->
<!--                <img src="../../../../../assets/images/icons/xlsx.png" alt="" />-->
<!--            </div>-->
        </li>
    </ul>
</div>

<table mat-table [dataSource]="dataSource" matSort id="excel-table">

    <!-- Position Column -->
    <ng-container matColumnDef="centername">
        <th mat-header-cell *matHeaderCellDef> Centre Name</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"
            [matTooltip]="element.centreCode"> {{element?.centreName}} </td>
        <!--                <td mat-footer-cell *matFooterCellDef><strong> Total</strong> </td>-->

    </ng-container>


    <!-- Available Units  Column -->
    <ng-container matColumnDef="availableunits">
        <th mat-header-cell *matHeaderCellDef> Available </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.Available}}   </td>
        <!--                <td mat-footer-cell *matFooterCellDef> <strong>{{lastRow?.availableUnits}}</strong> </td>-->

    </ng-container>
    <!-- Weight Column -->
    <ng-container matColumnDef="occupiedunits">
        <th mat-header-cell *matHeaderCellDef> Occupied As Of  <br>{{startDate1 |date:'dd/MM/yyyy'}} </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.Occupied}} </td>
        <!--                <td mat-footer-cell *matFooterCellDef><strong> {{lastRow?.occupiedUnits}}</strong> </td>-->

    </ng-container>
    <ng-container matColumnDef="occupiedunitsEnd">
        <th mat-header-cell *matHeaderCellDef> Occupied As Of <br> {{endDate1 |date:'dd/MM/yyyy'}} </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.OccupiedEnd}} </td>
        <!--                <td mat-footer-cell *matFooterCellDef><strong> {{lastRow?.occupiedUnits}}</strong> </td>-->

    </ng-container>
    <ng-container matColumnDef="difference">
        <th mat-header-cell *matHeaderCellDef> Difference  </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="element.isArchive?'archived':'normal'"> {{element.Occupied - element?.OccupiedEnd}} </td>
        <!--                <td mat-footer-cell *matFooterCellDef><strong> {{lastRow?.occupiedUnits}}</strong> </td>-->

    </ng-container>
    <!-- Weight Column -->
    <ng-container matColumnDef="totalunits">
        <th mat-header-cell *matHeaderCellDef> Total Rooms</th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="element.isArchive?'archived':'normal'"> {{element?.totalRooms}} </td>
        <!--                <td mat-footer-cell *matFooterCellDef><strong> {{lastRow?.totalUnits}}</strong> </td>-->

    </ng-container>
    <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef> Occupancy %</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{getPercentage(element.centre)}}%</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>

    <ng-container matColumnDef="admission">
        <th mat-header-cell *matHeaderCellDef> Unique Admission</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.uniqueAdmission}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <ng-container matColumnDef="discharge">
        <th mat-header-cell *matHeaderCellDef> Unique Discharge</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.uniqueDischarge}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <ng-container matColumnDef="fAdmission">
        <th mat-header-cell *matHeaderCellDef> Follow Up Admission</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.followUpAdmission}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <ng-container matColumnDef="fDischarge">
        <th mat-header-cell *matHeaderCellDef> Follow Up Discharge</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.followupDischarge}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <ng-container matColumnDef="os">
        <th mat-header-cell *matHeaderCellDef> OS</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.os}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <ng-container matColumnDef="dama">
        <th mat-header-cell *matHeaderCellDef> DAMA</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.dama}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <ng-container matColumnDef="alchamp">
        <th mat-header-cell *matHeaderCellDef> Always Champion</th>
        <td mat-cell *matCellDef="let element" [ngClass]="element.isArchive?'archived':'normal'"> <span *ngIf="element">  {{element.alwaysChamp}}</span></td>
        <!--                <td mat-footer-cell *matFooterCellDef><span> <strong *ngIf="lastRow"> {{getPercentage(lastRow!)}}%</strong></span> </td>-->

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <!--            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->

    <!-- Row shown when there is no matching data. -->
    <!--            <tr *matNoDataRow class="mat-row">-->
    <!--                <td class="mat-cell" colspan="4"  >No data matching the filter "{{input.value}}"</td>-->
    <!--            </tr>-->
</table>
