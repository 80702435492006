
<div class="container">

  <mat-tab-group fitInkBarToContent>
    <mat-tab label="Registered">
      <div>
      <BR>
      <button color="accent" mat-raised-button id="create-volunteer"  (click)="openpopup()"><mat-icon>person_add</mat-icon>&nbsp;Volunteer</button>&nbsp;
    </div>
      <br>

      <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search Here" #input>
      </mat-form-field>


      <section class="example-container mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="dataSource"    matSort class="child-table" matSortDisableClear matSortDirection="desc"  >
          <!--  POSITION Column-->
          <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="#"> Num. </th>
            <td mat-cell *matCellDef="let i = index">{{i+1}}
              <!--          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}-->
            </td>
          </ng-container>

          <!-- Center Column -->
          <ng-container matColumnDef="centercode">
            <th mat-header-cell *matHeaderCellDef>Volunteer Center Code </th>
            <td mat-cell *matCellDef="let element"> {{element.centercode  }} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="volunteercode">
            <th mat-header-cell *matHeaderCellDef>Volunteer Code </th>
            <td mat-cell *matCellDef="let element"> {{element.volunteercode}} </td>
          </ng-container>
          <!--      Child Name Column-->
          <ng-container matColumnDef="firstname" >
            <th mat-header-cell *matHeaderCellDef> First Name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstname  }} </td>
          </ng-container>

          <!--      Last Name Column-->
          <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef> Last Name </th>
            <td mat-cell *matCellDef="let element"> {{element.lastname  }} </td>
          </ng-container>

          <!-- City Column -->
          <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <!--    <ng-container matColumnDef="symbol">-->
          <!--      <th mat-header-cell *matHeaderCellDef> Symbol </th>-->
          <!--      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>-->
          <!--    </ng-container>-->

          <!--     Action-->
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element;let i = index">
              <!--          <button mat-icon-button>-->
              <!--            <mat-icon>archive</mat-icon>Archive-->

              <!--          </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-->
              <!--          <button  (click)="datashow(element)" mat-icon-button >-->
              <!--            <mat-icon>remove_red_eye</mat-icon>View-->
              <!--          </button>&nbsp;&nbsp;&nbsp;-->

              <!--          <button  mat-icon-button >-->
              <!--            <mat-icon >remove_red_eye</mat-icon>View-->
              <!--          </button>-->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <!--          <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>-->

          <!-- Row shown when there is no matching data. -->
          <tr *matNoDataRow class="mat-row">
            <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
          </tr>



        </table>

      </section>
      <mat-paginator [pageSizeOptions]="[25, 50, 100,1000]"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator> </mat-tab>
    <mat-tab label="Requested">
      <app-requested-volunteer></app-requested-volunteer>

    </mat-tab>
  </mat-tab-group>



</div>
