<div class="container">
    <!--<div>-->
    <!--</div>-->
    <div  class="row mt-2">



        <mat-form-field appearance="outline" class="col-md-3">
            <mat-label>Select Duration</mat-label>
            <mat-select  (selectionChange)="getSortedData($event)" >
                <mat-option [value]="7">Last 7 Days</mat-option>
                <mat-option [value]="30" >Last 30 Days </mat-option>
                <mat-option [value]="90"> Last 90 Days</mat-option>
                <mat-option [value]="0">View All</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-2">
            <mat-label>From</mat-label>
            <input matInput [matDatepicker]="start" [(ngModel)]="startDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" class="col-md-2">
            <mat-label>To</mat-label>
            <input matInput [matDatepicker]="end" [(ngModel)]="endDate">
            <mat-hint>DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end></mat-datepicker>
        </mat-form-field>

        <div class="col-md-2">
            <div >
                &nbsp;&nbsp;<button mat-raised-button color="accent" *ngIf="Donation.view || Donation.all" class="me-5" (click)="searchbyDateRange()">
                <mat-icon>search</mat-icon>Search
            </button>

            </div>
        </div>
        <!--  <button mat-raised-button  class="m-1" (click)="exportAllexcel()" color="primary">  <mat-icon> cloud_download</mat-icon>&nbsp; Download All</button>-->
        <!--<div class="d-flex justify-content-end">-->
        <!--    <mat-form-field appearance="outline" class="col-md-3">-->
        <!--  <mat-label>Filter</mat-label>-->
        <!--  <input matInput (keyup)="applyFilter($event)" placeholder="Search Here..." #input>-->
        <!--</mat-form-field> &nbsp;&nbsp;-->
        <!-- &nbsp;-->
        <!--      <button mat-raised-button *ngIf="Donation.all || Donation.excel"  class="m-1" (click)="exportexcel()" color="primary">  <mat-icon> cloud_download</mat-icon> &nbsp; Download Table </button>-->
        <!--&lt;!&ndash; &nbsp; <button mat-raised-button  class="m-1" (click)="exportToExcel()" color="primary">  <mat-icon> cloud_download</mat-icon> &nbsp; Download Table EXJS</button>&ndash;&gt;-->
        <!--</div>-->
    </div>
    <br>

    <div class="example-container mat-elevation-z8">
        <div class="example-loading-shade"
             *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner diameter="20px" *ngIf="isLoadingResults"></mat-spinner>
            <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
                GitHub's API rate limit has been reached. It will be reset in one minute.
            </div>
        </div>


        <div class=" card p-2">
            <div class="ngxTableHeader">
                <ul class="header-buttons-left ms-0 mb-0">
                    <li class="dropdown">
                        <h2>Donation Report</h2>
                    </li>
                    <li class="dropdown m-l-20">
                        <label for="search-input"><i class="material-icons search-icon">search</i></label>
                        <input placeholder="Search" type="text" class="browser-default search-field"
                               (keyup)='applyFilter($event)' aria-label="Search box" #input>
                    </li>
                </ul>
                <ul class="header-buttons mr-2">
                          <li>
                            <button   mat-mini-fab color="primary" (click)="openpopup()">
                              <mat-icon class="col-white" >add</mat-icon>
                            </button>
                          </li>
                    <li>
                        <div *ngIf="Donation.all || Donation.excel" class="export-button m-l-10" matTooltip="XLSX" (click)="exportexcel()">
                            <img src="assets/images/icons/xlsx.png" alt="" />
                        </div>
                    </li>
                </ul>
            </div>
            <div class="table-container">
                <table mat-table [dataSource]="dataSource" class="overflow-x-scroll" id="excel-table"
                       matSortActive="created" matSortDisableClear matSortDirection="desc" matSort>
                    <!-- Number Column -->

                    <!--      <ng-container matColumnDef="sr">-->
                    <!--        <th mat-header-cell *matHeaderCellDef >Sr No.</th>-->
                    <!--        <td   mat-cell *matCellDef="let row; let i =index">{{i+1}}</td>-->
                    <!--      </ng-container>-->
                    <!--------------------------------------------- DONOR NAME --------------------------------------------->
                    <ng-container matColumnDef="donated_by">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Donor Name</th>
                        <td mat-cell *matCellDef="let row">{{row.donated_by}}</td>
                    </ng-container>
                    <!--------------------------------------------- EMAIL --------------------------------------------->
                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let row">{{row.email}}</td>
                    </ng-container>
                    <!--------------------------------------------- TYPE --------------------------------------------->
                    <ng-container matColumnDef="dtype">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Donor Type</th>
                        <td mat-cell *matCellDef="let row"></td>
                    </ng-container>
                    <!--------------------------------------------- ISD CODE --------------------------------------------->
                    <ng-container matColumnDef="isd">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ISD Code</th>
                        <td mat-cell *matCellDef="let row">91</td>
                    </ng-container>



                    <!--------------------------------------------- MOBILE NUMBER --------------------------------------------->

                    <ng-container matColumnDef="mobile">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile Number of Donor</th>
                        <td mat-cell *matCellDef="let row">{{row.mobile}}</td>
                    </ng-container>
                    <!--------------------------------------------- ADDRESS --------------------------------------------->
                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                        <td mat-cell *matCellDef="let row">{{row.address}}</td>
                    </ng-container>
                    <!--------------------------------------------- STATE --------------------------------------------->
                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                        <td mat-cell *matCellDef="let row">{{row.state}}</td>
                    </ng-container>
                    <!--------------------------------------------- COUNTRY --------------------------------------------->

                    <ng-container matColumnDef="country">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                        <td mat-cell *matCellDef="let row">{{row.country}}</td>
                    </ng-container>
                    <!--------------------------------------------- PINCODE --------------------------------------------->
                    <ng-container matColumnDef="zipcode">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Pincode</th>
                        <td mat-cell *matCellDef="let row">{{row.zipcode}}</td>
                    </ng-container>
                    <!--------------------------------------------- NATIONALITY --------------------------------------------->
                    <ng-container matColumnDef="nationality">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Nationality</th>
                        <td mat-cell *matCellDef="let row">Indian</td>
                    </ng-container>
                    <!--------------------------------------------- PAN --------------------------------------------->
                    <ng-container matColumnDef="pan">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>PAN #</th>
                        <td mat-cell *matCellDef="let row">{{row.pan}}</td>
                    </ng-container>
                    <!--------------------------------------------- DONATION AMOUNT --------------------------------------------->
                    <ng-container matColumnDef="donation_amount">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Donation Amount(₹)</th>
                        <td mat-cell *matCellDef="let row">{{row.donation_amount}}</td>
                    </ng-container>

                    <!--------------------------------------------- CITY --------------------------------------------->
                    <ng-container matColumnDef="city" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>City</th>
                        <td mat-cell *matCellDef="let row">{{row.city}}</td>
                    </ng-container>
                    <!--------------------------------------------- PASSPORT --------------------------------------------->
                    <ng-container matColumnDef="passport" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Passport</th>
                        <td mat-cell *matCellDef="let row"></td>
                    </ng-container>
                    <!--------------------------------------------- HO --------------------------------------------->
                    <ng-container matColumnDef="ho" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>HO</th>
                        <td mat-cell *matCellDef="let row"></td>
                    </ng-container>
                    <!--------------------------------------------- DATE --------------------------------------------->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear  >
                            Date
                        </th>
                        <td mat-cell *matCellDef="let row">{{row.date?.toDate() | date:'dd-MM-yyyy hh:mm:aa'}}</td>
                    </ng-container>
                    <!--------------------------------------------- RECEIPT NUMBER --------------------------------------------->
                    <ng-container matColumnDef="receipt_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Reciept No.
                        </th>
                        <td mat-cell *matCellDef="let row">{{row.receipt_id}}</td>
                    </ng-container>
                    <!--------------------------------------------- TYPE   --------------------------------------------->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>
                            Purpose
                        </th>
                        <td mat-cell *matCellDef="let row">{{fullForm(row)}}</td>
                    </ng-container>

                    <!--------------------------------------------- 80G --------------------------------------------->
                    <ng-container matColumnDef="80g">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header >80G</th>
                        <td mat-cell *matCellDef="let row">Yes</td>
                    </ng-container>
                    <!--ACTIONS-->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> Action </th>
                        <td mat-cell *matCellDef="let element;let i = index">
                            <div class="d-flex">
                                <button mat-icon-button color="primary"  [routerLink]="['/home//manual-donation/',element.id]" >
                                    <mat-icon>receipt</mat-icon>&nbsp;

                                </button>&nbsp;
                                <button   mat-icon-button color="accent" (click)="viewRecepit(element)" >
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>&nbsp;&nbsp;&nbsp;
                            </div>
                            <!--//DELETE BUTTON-->
                            <!--                    <button  mat-icon-button (click)="delete(element.id)" >-->
                            <!--                      <mat-icon >delete</mat-icon>Delete-->
                            <!--                    </button>-->
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    <!--      80G-->



                </table>
            </div>
        </div>
        <!--  <mat-paginator  aria-label="Select page of users"></mat-paginator>-->

        <mat-paginator [length]="resultsLength"  [pageSizeOptions]="[20,50,75, 100,150]" aria-label="Select page of GitHub search results"></mat-paginator>
    </div>

</div>
