import {Component, Inject, isDevMode, OnInit} from '@angular/core';
import {Centre} from "../../../interfaces/Centres";
import {Observable} from "rxjs";
import {FormArray, FormBuilder, FormControl, Validators} from "@angular/forms";
import {Units} from "../../../interfaces/Units";
import {FullChild} from "../../../interfaces/FullChild";
import {ListType} from "../../types/types.component";
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {CentreService} from "../../../services/centre.service";
import {ChildService} from "../child.service";
import {UnitService} from "../../../services/unit.service";
import {Database, increment, ref, set} from "@angular/fire/database";
import {Firestore, increment as fireincrement} from "@angular/fire/firestore";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {AddHospitalComponent} from "../../hospitals/add-hospital/add-hospital.component";
import {MatSelectChange} from "@angular/material/select";
import {AddFieldComponent} from "../../types/add-field/add-field.component";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {environment} from "../../../../environments/environment";
import {Hospitals} from "../../../interfaces/Hospitals";
import {Guardian} from "../../caretakers/Guardians";
import {Sibling} from "../../../models/Sibling";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {IndianPostalCodesService} from "indian-postal-codes";
import {TitleCasePipe} from "@angular/common";
import {LogService} from "../../../services/log.service";
import firestore = firebase.firestore;
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import { cloneDeep, isEqual } from 'lodash';

interface Car {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-edit-full-child',
    templateUrl: './edit-full-child.component.html',
    styleUrls: ['./edit-full-child.component.scss']
})

export class EditFullChildComponent implements OnInit {
    // FAQ PANEL STATES
    isAdmin = true
    selectedCenter: Centre | undefined | null
    element: any;

    hospitals$: Observable<any>
    centers$: Observable<Centre[]>
    childForm: any;
    maxDateLimit = new Date()
    submitted = false;
    myControl = new FormControl('');
    options: string[] = ["Bandra Center", 'Chembur Center', 'Pune Center'];
    bloodGroupsArray = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-', 'NA']
    // diagnosticsArray = Array.from({length: 10}).map((_, i) => `Diagnosis ${i}`);
//  allergiesArrray = Array.from({length: 10}).map((_, i) => `Allergy  ${i}`);
    allCentres: Centre[] = []
    currentYear = new Date().getFullYear();
    minDate = new Date(this.currentYear - 14, 0, 1);
    maxDate = new Date(this.currentYear, 11, 31);
    isSelectedCentre = false
    /**  @param(tempID) : key for prescreening child doc key*/
    tempId: string | undefined;
    currentChildCode: string | undefined
    // @ts-ignore
    units$: Observable<Units[]>
    fb: FormBuilder
    routerData: FullChild;
    _diagnosis$: Observable<ListType[]>
    _allergies$: Observable<String[]>
    _skills$: Observable<String[]>
    _occupation$: Observable<String[]>
    _relation$: Observable<String[]>
//Documents
    titleAadhar = 'Adhaar Card'
    env = isDevMode() ? environment.testMode : environment.productionMode
    bloodgroup = []

    //To get current Year
    private result: any;
    initialFormState: any; //?add this


    constructor(private route: ActivatedRoute,
                private fbApi: FormBuilder,
                private mDatabase: AngularFireDatabase,
                private mCenterService: CentreService,
                private childService: ChildService,
                private unitService: UnitService,
                private mDatabaseRef: Database,
                private mLogService: LogService,
                private mFirestore: Firestore,
                private postalCodesService: IndianPostalCodesService,
                private titlecasePipe: TitleCasePipe,
                private firestore: AngularFirestore,
                private router: Router,
                private _snackBar: MatSnackBar,
                private matDialog: MatDialog
    ) {

        this.hospitals$ = mDatabase.list('dummy/hospitals').valueChanges()
        this.centers$ = this.mCenterService.getAllCentres()
        this.fb = fbApi
        this.routerData = this.router.getCurrentNavigation()?.extras.state as FullChild
        console.log(this.routerData)
        this._diagnosis$ = this.mDatabase.list<ListType>('types/Diagnosis').valueChanges()
        this._allergies$ = this.mDatabase.list<String>('types/Allergy').valueChanges()
        this._skills$ = this.mDatabase.list<String>('types/Skills').valueChanges()
        this._occupation$ = this.mDatabase.list<String>('types/Occupation').valueChanges()
        this._relation$ = this.mDatabase.list<String>('types/Relation').valueChanges()

    }

    ngOnInit(): void {

        // this.element = history.state; // Access the passed data
        this.element = this.routerData
        // Now you can use the 'element' data in your component
        console.log("this is element data: ", this.element.admissionData);
        // this.childForm = this.fb.group()
        // @ts-ignore
        console.log('CHILD==>', this.routerData?.guardians.length)

        // this.bloodgroup.push(this.routerData?.bloodGroup!)
        this.childForm = this.fb.group({

            firstName: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            middleName: new FormControl('', [Validators.pattern('[a-z A-Z ]*')]),
            lastName: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            bloodGroup: new FormControl('', []),
            // dob: new FormControl((this.routerData?.dob as unknown as Timestamp).toDate(), []),
            gender: new FormControl('',),
            mobile: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(10)]),
            city: new FormControl(''),
            district: new FormControl(''),
            country: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            state: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            pincode: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(6)]),
            childAadhaar: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(15)]),
            hobbies: new FormControl(''),
            allergies: new FormControl(''),
            aspirations: new FormControl(''),
            // ? Emergency Contact Name
            emergencyContactName1: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            emergencyContactRelation1: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            emergencyContactNumber1: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(6)]),
            emergencyContactName2: new FormControl(''),
            emergencyContactRelation2: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            emergencyContactNumber2: new FormControl('', [Validators.pattern('[0-9]*'), Validators.minLength(6)]),
            // Registration Info
            stayedBefore: new FormControl('', []),
            bag: new FormControl(null, []),
            guardianStayStatus: new FormControl('', []),
            // dateOfAdmission: new FormControl((this.routerData?.dateOfAdmission as unknown as Timestamp).toDate(), [Validators.required]),
            //Basic Info End
            // Doc Section
            // "documents": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            // "drivingLicense": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
            // "rationCard": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
            // "electionId": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
            // Doc Section End
//Guardian Info
            siblings: this.fb.array([]),
            guardians: this.fb.array([]),
            // "aadhaarnumber": new FormControl('', [Validators.required, Validators.pattern('[0-9]{12}$'), Validators.maxLength(12), Validators.minLength(12)]),
            // "relation": new FormControl('', [Validators.required]),
            // "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            // "contactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]),
            // "altcontactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]),
            // "skill": new FormControl('', [Validators.required]),
            //
            doctorName: new FormControl(''),
            fileNo: new FormControl(''),
            socialName: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            height: new FormControl('', [Validators.pattern('[.0-9]*'), Validators.maxLength(5)]),
            weight: new FormControl('', [Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
            prescribed: new FormControl(''),
            sickness: new FormControl('',),
            // "treatment": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            treatment: new FormControl(''),
            medical: new FormControl(''),
            hospital: new FormControl('', []),
            allergiesSelected: new FormControl('', []),
            // diagnosis: new FormControl(''),
            diagnosisSelected: new FormControl('', []),
            months: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(3)]),
            days: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(3)]),
            // timestamp: new FormControl(ServerValue.TIMESTAMP)
        })
        // dob: new FormControl((this.routerData?.dob as unknown as Timestamp).toDate(), []),

        this.updateFormControl('firstName', this.routerData.firstName)
        this.updateFormControl('middleName', this.routerData.middleName)
        this.updateFormControl('lastName', this.routerData.lastName)
        this.updateFormControl('bloodGroup', this.routerData.bloodGroup)
        this.updateFormControl('gender', this.routerData.gender)
        this.updateFormControl('mobile', this.routerData.mobile)
        this.updateFormControl('city', this.routerData.city)
        this.updateFormControl('country', this.routerData.country)
        this.updateFormControl('state', this.routerData.state)
        this.updateFormControl('pincode', this.routerData.pincode)
        this.updateFormControl('childAadhaar', this.routerData.childAadhaar)
        this.updateFormControl('hobbies', this.routerData.hobbies)
        this.updateFormControl('allergies', this.routerData.allergies)
        this.updateFormControl('aspirations', this.routerData.aspirations)
        this.updateFormControl('emergencyContactName1', this.routerData.emergencyContactName1)
        this.updateFormControl('emergencyContactName2', this.routerData.emergencyContactName2)
        this.updateFormControl('emergencyContactNumber1', this.routerData.emergencyContactNumber1)
        this.updateFormControl('emergencyContactNumber2', this.routerData.emergencyContactNumber2)
        this.updateFormControl('emergencyContactRelation1', this.routerData.emergencyContactRelation1)
        this.updateFormControl('emergencyContactRelation2', this.routerData.emergencyContactRelation2)
        this.updateFormControl('stayedBefore', this.routerData.stayedBefore)
        this.updateFormControl('bag', this.routerData.bag)
        this.updateFormControl('guardianStayStatus', this.routerData.guardianStayStatus)
        this.updateFormControl('doctorName', this.routerData.doctorName)
        this.updateFormControl('fileNo', this.routerData.fileNo)
        this.updateFormControl('socialName', this.routerData.socialName)
        this.updateFormControl('height', this.routerData.height)
        this.updateFormControl('weight', this.routerData.weight)
        this.updateFormControl('district', this.routerData.district)

        // this.setDataForm()

        this.routerData?.guardians?.forEach((it: any, i) => {
                this.setGuardianControl(it, i)

            }
        )
        this.routerData?.siblings?.forEach((it: any, i) => {
            this.setSiblingControl(it, i)

        })
        // this.childForm.get('bloodGroup').setValue(this.routerData?.bloodGroup)
        // this.childForm.get('gender').setValue(this.routerData?.gender)
        // // this.childForm.get('selectCentre').setValue({value:this.routerData?.centreName,disabled:true})
        //
        // this.childForm.get('sickness').setValue(this?.routerData?.sickness)
        //
        // this.childForm.get('allergiesSelected').setValue(this?.routerData?.allergiesSelected)
        // this.childForm.get('diagnosisSelected').setValue(this?.routerData?.diagnosisSelected)
        // this.childForm.get('treatment').setValue(this?.routerData?.treatment)
        // this.childForm.get('months').setValue(this?.routerData?.months)
        // this.childForm.get('days').setValue(this?.routerData?.days)
        // this.childForm.get('treatment').setValue(this?.routerData?.treatment)
        // this.childForm.get('medical').setValue(this?.routerData?.medical)
        // this.childForm.get('prescribed').setValue(this?.routerData?.prescribed)
        // this.childForm.get('guardianStayStatus').setValue(this?.routerData?.guardianStayStatus)
        // // this.childForm.get('hospital').setValue(this?.routerData?.hospital)
        // this.childForm.get('hospital').setValue(this.routerData?.hospital)
        // this.childForm.get('bag').setValue(this?.routerData?.bag)
        this.updateFormControl('sickness', this.routerData.sickness)
        this.updateFormControl('allergiesSelected', this.routerData.allergiesSelected)
        this.updateFormControl('diagnosisSelected', this.routerData.diagnosisSelected)
        this.updateFormControl('treatment', this.routerData.treatment)
        this.updateFormControl('months', this.routerData.months)
        this.updateFormControl('days', this.routerData.days)
        this.updateFormControl('treatment', this.routerData.treatment)
        this.updateFormControl('medical', this.routerData.medical)
        this.updateFormControl('prescribed', this.routerData.prescribed)
        this.updateFormControl('guardianStayStatus', this.routerData.guardianStayStatus)
        this.updateFormControl('hospital', this.routerData.hospital)
        this.updateFormControl('bag', this.routerData.bag)
        this.updateFormControl('hospital', this.routerData.hospital)
        this.updateFormControl('stayedBefore', this.routerData.stayedBefore)

        // this.addSiblingControl()
        this.tempId = this.routerData?.id

        this.mCenterService.getCentreListSnapshot().subscribe(centres => {
            centres.forEach(centre => {
                    const $key = centre.key
                    const data = centre.payload.toJSON()
                    const c = {$key, ...data}
                    this.allCentres.push(c as Centre)
                }
            )
        })
        this.initialFormState = cloneDeep(this.childForm); //?add this

    }

    relation: string[] = [
        'Father',
        'Mother',
        'Brother',
        'Sister',
        'Paternal Uncle',
        'Paternal Aunt',
        'Maternal Aunt',
        'Paternal Grandfather',
        'Paternal Grandmother',
        'Maternal Grandfather',
        'Maternal Grandmother'
    ];
    states: string[] = [
        'None',
        'Yes',
        'No',
    ]

    skill: string[] = [
        'None',
        'Driving',
        'Teaching',
        'Carpenting',
        'Photography',
        'Mobile Reparing',
        'AC reparing',
        'Farming'
    ];
    hospital: string[] = [
        'None',
        'Yes',
        'No',
    ];

    allergies: string[] = [
        'None',
        'Yes',
        'No',
    ];
    diagnosis: string[] = [
        'None',
        'Yes',
        'No',
    ];
    months: string[] = [
        'None',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // days = [  'o'  ,'1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'];

    // 'Tuesday',
    // 'Wednesday',
    // 'Thursday',
    // 'Friday',
    // 'Saturday',
    // 'Sunday',

    @needConfirmation()
    SubmitData() {
//first sumbit data
        this.submitted = true;
        const submittedFormState: any = this.childForm; //?add this

        //Ideal Code Should be like this
        // if (this.childForm.valid) {
        // const unitselected = this.childForm.get('selectUnit').value
        const childCode = this.routerData?.childCode
        const childName = this.childForm.get('firstName').value + ' ' + this.childForm.get('lastName').value
        // const centre = this.childForm.get('selectCentre').value.centreCode
        // console.log('Unit Selected', unitselected)
        // console.log('center on Sumbmit', centre)
        // this.childService.addTempChild(this.childForm.value)
        const g = this.childForm.get('guardians').value
        console.log('Guardian ', g)
        console.log(this.childForm.value);
        console.log(this.childForm.value);
        // this.childForm.removeControl('selectCentre')
        // this.childForm.addControl('isAlloted', new FormControl(true));
        // this.childForm.addControl('createdAt', new FormControl(new Date()));
        // this.childForm.addControl('centreCode', new FormControl(this.selectedCenter?.centreCode))
        // this.childForm.addControl('centreName', new FormControl(this.selectedCenter?.centreName));
        this.childService.editChild(childCode, this.childForm.value).then(() => {
            const differences = this.getFormDifference(this.initialFormState, submittedFormState)
            // Now, 'differences' contains the fields that changed from the initial state to the submitted state
            console.log('Differences:', differences);


            let activity = {
                date: new Date().getTime(),
                section: 'Admission',
                action: 'Edit',
                changes: differences,  //?add this
                description: `Child Deleted ${this.routerData?.childCode}} by  `,
                currentIp: localStorage.getItem('currentip')!
            }
            this.mLogService.addLog(activity)
            this._snackBar.open('Edit Successful', '')._dismissAfter(3000)
        })

        this.childService.addGuardian(childCode, this.childForm.get('guardians')).then(() => {
            console.log('Edit Successful')
            this.router.navigateByUrl('/home/childdetails')
        }).catch(reason => {
            console.log('failed due to', reason)
        })

        // }
        // else {
        //     this._snackBar.open('Please fill the form properly', '', {duration: 3000})
        //     // this.findInvalidControls()
        //     console.log(this.childForm.state)
        //
        //     console.log(this.findInvalidControls())
        // }
//then close dialog
//     this.dialogRef.close()

    }

    getFormDifference(initialFormState: any, submittedFormState: any) {
        const differences: any = {};

        for (const key in this.initialFormState.controls) {
            const initialControl = this.initialFormState.get(key);
            const submittedControl = submittedFormState.get(key);
            console.log(`Initial Value: ${initialControl.value}`);
            console.log(`Submitted Value: ${submittedControl.value}`);
            if (initialControl instanceof FormArray && submittedControl instanceof FormArray) {

                // Handle FormArray fields
                for (let i = 0; i < initialControl.length; i++) {
                    if (!isEqual(initialControl.at(i).value, submittedControl.at(i).value)) {

                        if (!differences[key]) {

                            differences[key] = [];
                        }
                        alert('differnce found')

                        differences[key].push({
                            index: i,
                            initialValue: initialControl.at(i).value,
                            submittedValue: submittedControl.at(i).value,
                        });
                    }
                }
            } else if (!isEqual(initialControl.value, submittedControl.value)) {
                // Handle regular FormControl fields
                alert('differnce found')
                alert(`${initialControl.value}=> ${submittedControl.value}`)
                differences[key] = {
                    initialValue: initialControl.value,
                    submittedValue: submittedControl.value,
                };
            }

        }
        return differences
    }

    TestForm() {
        console.log(this.childForm.value)
    }

    get doctorname() {
        return this.childForm.get('doctorName');
    }

    get fileno() {
        return this.childForm.get('fileNo');
    }

    get lastname() {
        return this.childForm.get('lastName');
    }

    get socialname() {
        return this.childForm.get('socialName');
    }

    get height() {
        return this.childForm.get('height');
    }


    get weight() {
        return this.childForm.get('weight');
    }

    get getdiagnosis() {
        return this.childForm.get('diagnosis');
    }

    get diagnosisSelected() {
        return this.childForm.get('diagnosisSelected');
    }

    get prescribed() {
        return this.childForm.get('prescribed');
    }


    get sickness() {
        return this.childForm.get('sickness');
    }

    get treatment() {
        return this.childForm.get('treatment');
    }

    get Months() {
        return this.childForm.get('months');
    }

    get Days() {
        return this.childForm.get('days');
    }

    get medical() {
        return this.childForm.get('medical');
    }

    get documents() {
        return this.childForm.get('documents');
    }

    get altcontactno() {
        return this.childForm.get('altcontactNo');
    }

    get firstname() {
        return this.childForm.get('firstName');
    }

    gfirstName(i: any): FormControl {

        const formArray = this.childForm.get('guardians') as FormArray;

        return <FormControl<any>>formArray.at(i).get('firstName')
    }

    gAnnualIncome(i: any): FormControl {

        const formArray = this.childForm.get('guardians') as FormArray;

        return <FormControl<any>>formArray.at(i).get('annualIncome')
    }

    gmiddleName(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;

        return <FormControl<any>>formArray.at(i).get('middleName')
    }

    // get gfirstName() {
    //   const g = this.childForm.get('guardians') as FormArray
    //   return g?.get('gfirstName')
    // }

    // get gmiddleName() {
    //   const g = this.childForm.get('guardians') as FormArray
    //   return g?.get('gmiddleName')
    // }

    glastname(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;

        return <FormControl<any>>formArray.at(i).get('lastName')
    }

    gaadhaarnumber(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;

        return <FormControl<any>>formArray.at(i).get('aadharNumber')
    }

    gRelation(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('relation')

    }

    guardianOccupation(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('occupation')
    }

    gContactNo(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('contactNo')
    }

    gAlteranate(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('alternateContactNo')
    }

    gElectionId(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('electionId')
    }

    gRationCard(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('rationCard')
    }

    gDrivingLicense(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('drivingLicense')
    }

    gOtherDocuments(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('otherDocuments')
    }

    gSkills(i: number): FormControl {
        const formArray = this.childForm.get('guardians') as FormArray;
        return <FormControl<any>>formArray.at(i).get('skills')
    }

    sFirstName(i: number): FormControl {
        const formArray = this.childForm.get('siblings') as FormArray;
        return <FormControl<any>>formArray.at(i).get('firstName')
    }

    sGender(i: number): FormControl {
        const formArray = this.childForm.get('siblings') as FormArray;
        return <FormControl<any>>formArray.at(i).get('gender')
    }

    sContactNumber(i: number): FormControl {
        const formArray = this.childForm.get('siblings') as FormArray;
        return <FormControl<any>>formArray.at(i).get('mobile')
    }

    sAge(i: number): FormControl {
        const formArray = this.childForm.get('siblings') as FormArray;
        return <FormControl<any>>formArray.at(i).get('age')
    }

    get rationcard() {
        return this.childForm.get('rationCard');
    }

    get middlename() {
        return this.childForm.get('middleName');
    }


    get drivinglicense() {
        const g = this.childForm.get('guardians') as FormArray
        // return g.get('drivingLicense')
        return this.childForm.get('drivingLicense');
    }

    get occupation() {
        return this.childForm.get('occupation');
    }


    get electionid() {
        return this.childForm.get('electionId');
    }

    get contactno() {
        return this.childForm.get('contactNo');
    }

    get childAadhaar() {
        return this.childForm.get('childAadhaar');
    }

    onBlur(formControl: any) {
        if (formControl.value !== null) {
            formControl.setValue(this.titlecasePipe.transform(formControl.value.toString().trim().toUpperCase()))
        }
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.childForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        return invalid;
    }

    cancel() {
        // this.dialogRef.close()
        this.submitted = false;
        console.log(this.childForm.reset())
        this.router.navigateByUrl('a/childdetails')
    }


    get mobile() {
        return this.childForm.get('mobile');
    }

    get city() {
        return this.childForm.get('city');
    }

    get country() {
        return this.childForm.get('country');
    }

    get state() {
        return this.childForm.get('state');
    }

    get pincode() {
        return this.childForm.get('pincode');
    }

    get Pincode() {
        return this.childForm.get('pincode');
    }

    get childAadhar() {
        return this.childForm.get('childAadhaar')
    }


    get Hobbies() {
        return this.childForm.get('hobbies');
    }

    get Allergies() {
        return this.childForm.get('allergies');
    }

    get AllergiesSelected() {
        return this.childForm.get('allergiesSelected');
    }

    get Aspirations() {
        return this.childForm.get('aspirations');
    }

    get District() {
        return this.childForm.get('district');
    }

    get getCentre() {
        return this.childForm.get('selectCentre');
    }

    get emergencyContactName1() {
        return this.childForm.get('emergencyContactName1')
    }

    get emergencyContactRelation1() {
        return this.childForm.get('emergencyContactRelation1')
    }

    get emergencyContactRelation2() {
        return this.childForm.get('emergencyContactRelation2')
    }

    get emergencyContactNumber1() {
        return this.childForm.get('emergencyContactNumber1')
    }

    get emergencyContactName2() {
        return this.childForm.get('emergencyContactName2')
    }

    get emergencyContactNumber2() {
        return this.childForm.get('emergencyContactNumber2')
    }


    get guardiansFormArray(): FormArray {
        return this.childForm.get('guardians') as FormArray;
    }

    get siblingFormArray(): FormArray {
        return this.childForm.get('siblings') as FormArray;
    }

    guardian() {
        return this.fb.group({
            childCode: this.childForm.get('childCode', Validators.required),
            firstName: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            middleName: this.fb.control('', [Validators.pattern('[a-zA-Z ]*')]),
            lastName: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            // aadharNumber: this.fb.control('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12), Validators.minLength(12)]),
            relation: this.fb.control('', Validators.required),
            occupation: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            contactNo: this.fb.control('', [Validators.required, Validators.pattern('[0-9]{10}$'), Validators.maxLength(10), Validators.minLength(10)]),
            // alternateContactNo: this.fb.control('', [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]{10}$'), Validators.minLength(10)]),
            // electionId: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
            // rationCard: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
            annualIncome: this.fb.control('', [Validators.required]),
            // drivingLicense: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
            otherDocuments: this.fb.control('', Validators.required),
            skills: this.fb.control('', Validators.required),
        })
    }

    setguardian(it: Guardian, i: number) {

        return this.fb.group({
            childCode: this.childForm.get('childCode', Validators.required),
            firstName: this.fb.control(it?.firstName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            middleName: this.fb.control(it?.middleName, [Validators.pattern('[a-zA-Z ]*')]),
            lastName: this.fb.control(it?.lastName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            // aadharNumber: this.fb.control('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(12), Validators.minLength(12)]),
            relation: this.fb.control(it?.relation, Validators.required),
            occupation: this.fb.control(it?.occupation, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            contactNo: this.fb.control(it?.contactNo, [Validators.required, Validators.pattern('[0-9]{10}$'), Validators.maxLength(10), Validators.minLength(10)]),
            // alternateContactNo: this.fb.control('', [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]{10}$'), Validators.minLength(10)]),
            // electionId: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
            // rationCard: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
            annualIncome: this.fb.control(it?.annualIncome, [Validators.required]),
            // drivingLicense: this.fb.control('', [Validators.required,Validators.pattern('[0-9]*')]),
            otherDocuments: this.fb.control(it?.otherDocuments, Validators.required),
            skills: this.fb.control(it?.skills, Validators.required),
        })

    }

    sibling() {
        return this.fb.group({
            firstName: this.fb.control('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            age: this.fb.control('', [Validators.required, Validators.maxLength(2), Validators.pattern('[0-9]*')]),
            gender: this.fb.control('', Validators.required),
            mobile: this.fb.control('', [Validators.min(10), Validators.pattern('[0-9]*')]),
        })
    }

    setSibling(it: Sibling) {
        return this.fb.group({
            firstName: this.fb.control(it?.firstName, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            age: this.fb.control(it?.age, [Validators.required, Validators.maxLength(2), Validators.pattern('[0-9]*')]),
            gender: this.fb.control(it?.gender, Validators.required),
            mobile: this.fb.control(it?.mobile, [Validators.min(10), Validators.pattern('[0-9]*')]),
        })
    }

    addGuardianControl() {
        this.guardiansFormArray.push(this.guardian());
    }

    setGuardianControl(it: Guardian, i: number) {
        console.log('into set guardian')
        this.guardiansFormArray.push(this.setguardian(it, i));
        if (it.relation != null) this.guardiansFormArray.at(i).get('relation')?.setValue(it.relation)
        if (it.occupation != null) this.guardiansFormArray.at(i).get('occupation')?.setValue(it.occupation)
    }

    addSiblingControl() {
        this.siblingFormArray.push(this.sibling());
        this.siblingFormArray.length
        console.log(this.siblingFormArray.length)
        // this.siblingFormArray.controls.forEach(it => {
        //   console.log(it)
        // })
    }

    setSiblingControl(it: Sibling, i: number) {
        this.siblingFormArray.push(this.setSibling(it));
        // this.siblingFormArray.at(i).get('relation')?.setValue(it.relation)
        // this.siblingFormArray.at(i).get('occupation')?.setValue(it.occupation)
    }

    removeSiblingControl() {
        // this.siblingFormArray.push(this.sibling());
        const limit = this.siblingFormArray.length
        this.siblingFormArray.removeAt(limit - 1)
        console.log(this.siblingFormArray.length)
        // this.siblingFormArray.controls.forEach(it => {
        //   console.log(it)
        // })
    }

    removeGuardainControl() {
        // this.siblingFormArray.push(this.sibling());
        const limit = this.guardiansFormArray.length
        if (limit > 1) this.guardiansFormArray.removeAt(limit - 1)
        console.log(this.guardiansFormArray.length)
        // this.siblingFormArray.controls.forEach(it => {
        //   console.log(it)
        // })
    }

    get getFormData() {
        return this.childForm.value.toJSON
    }

    openHospital() {
        this.matDialog.open(AddHospitalComponent,
            {
                width: '70%', height: '600px',
                enterAnimationDuration: '100ms',
                exitAnimationDuration: '200ms',
            }
        )
    }

    fetchUnits($event: MatSelectChange) {
        this.isSelectedCentre = true
        console.log('fetchUnits', $event.value)
        // this.selectedCenter  = this.allCentres.at()
        this.selectedCenter = $event.value
        this.units$ = this.mDatabase.list<Units>(`${this.env.units}/${this.selectedCenter?.centreCode}`, ref => ref.orderByChild('order')).valueChanges()
        console.log('selected', $event.value)
// this.childForm.setControl()
        this.childForm.addControl('centreCode', new FormControl(this.selectedCenter?.centreCode), Validators.required)
        this.childForm.addControl('centreName', new FormControl(this.selectedCenter?.centreName), Validators.required)
        // this.childForm.setControl( 'centreCode', this.selectedCenter?.centreCode)

    }


    addDiagnosis() {
        this.matDialog.open(AddFieldComponent, {
            data: 'Diagnosis'
        })
    }

    addAllergy() {
        this.matDialog.open(AddFieldComponent, {
            data: "Allergy"
        })
    }

    HospitalChange($event: Hospitals) {
        console.log($event)
        const h = $event as Hospitals
        this.doctorname.setValue(h.doctorsName)
        this.socialname.setValue(h.socialWorkerName)

    }

    updateFormControl(firstName: string, s: any) {
        if (s != null && s != undefined) this.childForm.get(firstName).setValue(s)
    }

    setDataForm() {
        this.childForm = this.fb.group({
            //Child Info
            /** childCode for latest child counter to genereate child code from database. */
            // "centreCode": new FormControl('', [Validators.required]),

            firstName: new FormControl('', [Validators.pattern('[a-zA-Z ]*')]),
            middleName: new FormControl(this.routerData?.middleName, [Validators.pattern('[a-z A-Z ]*')]),
            lastName: new FormControl(this.routerData?.lastName, [Validators.pattern('[a-zA-Z ]*')]),
            bloodGroup: new FormControl('', []),
            // dob: new FormControl((this.routerData?.dob as unknown as Timestamp).toDate(), []),
            gender: new FormControl('',),
            mobile: new FormControl(this.routerData?.mobile, [Validators.pattern('[0-9]*'), Validators.minLength(10)]),
            city: new FormControl(this.routerData?.city,),
            country: new FormControl(this.routerData?.country, [Validators.pattern('[a-zA-Z ]*')]),
            state: new FormControl(this.routerData?.state, [Validators.pattern('[a-zA-Z ]*')]),
            pincode: new FormControl(this.routerData?.pincode, [Validators.pattern('[0-9]*'), Validators.minLength(6)]),
            childAadhaar: new FormControl(this.routerData?.childAadhaar, [Validators.pattern('[0-9]*'), Validators.maxLength(15)]),
            hobbies: new FormControl(this.routerData?.hobbies),
            allergies: new FormControl(this.routerData?.allergies),
            aspirations: new FormControl(this.routerData?.aspirations),
            // ?Emergency Contact Name
            emergencyContactName1: new FormControl(this.routerData?.emergencyContactName1, [Validators.pattern('[a-zA-Z ]*')]),
            emergencyContactRelation1: new FormControl(this.routerData?.emergencyContactRelation1, [Validators.pattern('[a-zA-Z ]*')]),
            emergencyContactNumber1: new FormControl(this.routerData?.emergencyContactNumber1, [Validators.pattern('[0-9]*'), Validators.minLength(6)]),
            emergencyContactName2: new FormControl(this.routerData?.emergencyContactName2,),
            emergencyContactRelation2: new FormControl(this.routerData?.emergencyContactRelation2, [Validators.pattern('[a-zA-Z ]*')]),
            emergencyContactNumber2: new FormControl(this.routerData?.emergencyContactNumber2, [Validators.pattern('[0-9]*'), Validators.minLength(6)]),
            // Registration Info

            stayedBefore: new FormControl('', []),
            bag: new FormControl(null, []),
            guardianStayStatus: new FormControl(this.routerData?.guardianStayStatus, []),
            // dateOfAdmission: new FormControl((this.routerData?.dateOfAdmission as unknown as Timestamp).toDate(), [Validators.required]),
            //Basic Info End
            // Doc Section
            // "documents": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            // "drivingLicense": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
            // "rationCard": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
            // "electionId": new FormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
            // Doc Section End
//Guardian Info
            siblings: this.fb.array([]),
            guardians: this.fb.array([]),
            // "aadhaarnumber": new FormControl('', [Validators.required, Validators.pattern('[0-9]{12}$'), Validators.maxLength(12), Validators.minLength(12)]),
            // "relation": new FormControl('', [Validators.required]),
            // "occupation": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z]*')]),
            // "contactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]),
            // "altcontactno": new FormControl('', [Validators.required, Validators.pattern('[0-9]*'), Validators.maxLength(10), Validators.minLength(10)]),
            // "skill": new FormControl('', [Validators.required]),
            //
            doctorName: new FormControl(this.routerData?.doctorName),
            fileNo: new FormControl(this.routerData?.fileNo,),
            socialName: new FormControl(this.routerData?.socialName, [Validators.pattern('[a-zA-Z ]*')]),
            height: new FormControl(this.routerData?.height, [Validators.pattern('[.0-9]*'), Validators.maxLength(5)]),
            weight: new FormControl(this.routerData?.weight, [Validators.pattern('[0-9.]*'), Validators.maxLength(5)]),
            prescribed: new FormControl(''),
            sickness: new FormControl('',),
            // "treatment": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
            treatment: new FormControl(''),
            medical: new FormControl(''),
            hospital: new FormControl(this.routerData?.hospital, []),
            allergiesSelected: new FormControl('', []),
            diagnosis: new FormControl(this.routerData?.diagnosis),
            diagnosisSelected: new FormControl('', []),
            months: new FormControl(this.routerData?.months, [Validators.pattern('[0-9]*'), Validators.maxLength(3)]),
            days: new FormControl('', [Validators.pattern('[0-9]*'), Validators.maxLength(3)]),
            // timestamp: new FormControl(ServerValue.TIMESTAMP)
        })
    }

    findPinCode(pinCode: any) {
        if (pinCode.length == 6) {
            this.postalCodesService.findByPinCode(pinCode).subscribe((data) => {
                // console.log(data)
                this.result = data[0].PostOffice[0]
                if (data[0].Status === 'Success') {
                    this.result = data[0].PostOffice[0];
                    this.childForm.get('state').setValue(this.result.State)
                    this.childForm.get('city').setValue(this.result.Block)
                    this.childForm.get('country').setValue(this.result.Country)
                    this.childForm.get('district').setValue(this.result.District)
                    console.log(this.result)
                } else if (data[0].Status === 'Error') {
                    alert(data[0].Message);
                    this.result = [];
                } else if (data[0].Status === '404') {
                    alert(data[0].Message);
                    this.result = [];

                }
                // console.log(data)
            });
        }
    }

    updateDOB(event: any) {
        this.routerData.dob = event.value;
    }

    updateAdmissionDate(event: any) {
        this.routerData.dateOfAdmission = event.value;
    }

    toDate(timestamp: firestore.Timestamp): Date {
        return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    }

    updateDate(d: any) {

    }

    updateCentreCode(d: any) {

    }


    updateDischargeDate($event: MatDatepickerInputEvent<any, any>) {

    }


    updateTentativeDischargeDate($event: MatDatepickerInputEvent<any, any>) {

    }

    updateCentreName(d: any) {

    }

    back() {
        this.router.navigateByUrl('/home/childdetails')
    }
}

