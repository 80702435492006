import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {MY_FORMATS} from "../../../utils/formats";




@Component({
  selector: 'app-childadmission',
  templateUrl: './childadmission.component.html',
  styleUrls: ['./childadmission.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class ChildadmissionComponent implements OnInit {
  childadmissionForm: any;
  submitted = false;
  myControl = new FormControl('');
  options: string[] = ['Bandra Center', 'Chembur Center', 'Pune Center'];

  constructor() { }

  ngOnInit(): void {

    this.childadmissionForm= new FormGroup({
      "selectcentre": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "accompaniedby": new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
      "dob": new FormControl('', [Validators.required]),
      "guardian": new FormControl('', [Validators.required]),
      "stayedbefore": new FormControl('', [Validators.required]),
      "selectunit": new FormControl('', [Validators.required]),
      "accompanierel": new FormControl('', [Validators.required]),
      "bag": new FormControl('', [Validators.required]),
    })

  }

  relation: string[] = [
    'Father',
    'Mother',
    'Brother',
    'Sister',
    'Paternal Uncle',
    'Paternal Aunt',
    'Maternal Aunt',
    'Paternal Grandfather',
    'Paternal Grandmother',
    'Maternal Grandfather',
    'Maternal Grandmother'
  ];


  states:string[] = [
    'None',
    'Yes',
    'No',
  ]




  SubmitData() {
//first sumbit data
    this.submitted = true;
    //Ideal Code Should be like this
    // if(form.isValid){
    //   then submit data to service
    // }

    // this.childService.addTempChild(this.childadmissionForm.value)

    console.log(this.childadmissionForm.value);
//then close dialog
//     this.dialogRef.close()

  }

  cancel() {
    // this.dialogRef.close()
    this.submitted = false;
    console.log(this.childadmissionForm.reset())
  }



  get accompaniedby() {
    return this.childadmissionForm.get('accompaniedby');
  }

}
