<div class="container">
  <!--1) Event Name: [Name of the event]
  2) Event Description: [Briefly describe the event and its purpose]
  3) Event Type [ for center Fundraising event,Awareness event,Community event,Campaign launch event,Donor appreciation event,Visit etc ) and for child Medial,Sports etc ]
  4) Event Date and Time: [Date and time of the event]
  5) Event Location(s): [List all the locations where the event will be held]
  6) Event Contact Information ( Concern person mostly he/she will be employee ):
  6.1) Name of the Contact Person:
  6.2) Email:
  6.3) Phone Number:
  6.4) Address:-->
  <form [formGroup] ="myForm">
    <h2 class="rounded text-center">Create Event</h2>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Name Of Event</mat-label>
          <input matInput placeholder="Name Of Event" formControlName="eventName">
        </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field class="col-12" appearance="outline" >
          <mat-label>Event Type</mat-label>
          <mat-select  placeholder="Type Of Event" formControlName="eventType">
            <mat-option value="Fundraising">Fundraising </mat-option>
            <mat-option value="Awareness">Awareness </mat-option>
            <mat-option value="Community">Community </mat-option>
            <mat-option value="Campaign">Campaign</mat-option>
            <mat-option value="Donor Appreciation">Donor Appreciation</mat-option>
            <mat-option value="Visit">Visit</mat-option>
            <mat-option value="Other">Other</mat-option>
          </mat-select>
          <mat-error *ngIf="eventType?.touched && eventType?.invalid">Required</mat-error>
        </mat-form-field>
      </div>

<!--      <div class="col-md-4 col-sm-12">-->
<!--        <mat-form-field class="col-12" appearance="outline" >-->
<!--          <mat-label>Event For</mat-label>-->
<!--          <mat-select  placeholder="Type Of Event" formControlName="eventSelect" (selectionChange)="SelectEventType($event)">-->
<!--&lt;!&ndash;            <mat-option value="Child">Child</mat-option>&ndash;&gt;-->
<!--            <mat-option value="Centre">Centre</mat-option>-->
<!--          </mat-select>-->
<!--          <mat-error *ngIf="eventType?.touched && eventType?.invalid">Required</mat-error>-->
<!--        </mat-form-field>-->
<!--      </div>-->

       <ng-container *ngIf="eventFor=='Centre'">
      <div class="col-md-4 col-sm-12">
        <mat-form-field class="col-12" appearance="outline"   >
          <mat-label>Select  Centre</mat-label>
          <mat-select  formControlName="code" (selectionChange)="selectCode($event)" required multiple >
              <mat-option *ngFor="let t of (centre$|async)" [value]="{id:t.centreCode,centreName:t.centreName}">{{t.centreName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
       </ng-container>
      <div class="col-md-6">
      <mat-form-field appearance="outline" class="col-md-12">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="eventDate">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      </div>

      <div class="col-md-6 col-sm-12">
        <mat-form-field class="col-12" appearance="outline">
            <mat-label>Event Time</mat-label>
            <!-- The timepicker input -->
            <input matTimepicker #t="matTimepicker" formControlName="eventTime" [value]="timeSelected"
                   [strict]="false" id="timepicker-example" mode="12h"
                   placeholder="Please select time..." name="time">
            <!-- An icon for the timepicker input that will open the timepicker dialog when clicked -->
            <mat-icon matSuffix (click)="t.showDialog()">access_time</mat-icon>
          <!-- Error that will be shown when the input date is invalid -->
            <mat-error *ngIf="eventTime?.touched && eventTime?.invalid">Invalid Date</mat-error>
          </mat-form-field>
      </div>
<!--      <div class="col-md-6 col-sm-12">-->
<!--        <mat-form-field class="col-12" appearance="outline">-->
<!--          <mat-label>Event Locations</mat-label>-->
<!--          <mat-select  multiple placeholder="Type Of Event" formControlName="eventLocations">-->
<!--            <mat-option *ngFor="let l of (centre$|async)"[value]="l.centreCode">{{l.centreName}} </mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </div>-->
      <div class="col-md-6 col-sm-12">
        <mat-form-field class="col-12" appearance="outline" >
          <mat-label>Short Description</mat-label>
          <input matInput placeholder="Short Description" formControlName="description">
        </mat-form-field>
      </div>
    <h4>Contact Person For the Event</h4>
      <div class="col-md-4 col-sm-12">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Name </mat-label>
          <input matInput placeholder="Name " formControlName="name">
        </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email"  formControlName = 'email'>
        </mat-form-field>
      </div>
      <div class="col-md-4 col-sm-12">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label>Contact Number</mat-label>
          <input matInput placeholder="Number" formControlName="number">
        </mat-form-field>
      </div>
      <div class="col-md-4"></div>
<div class="col-md-4">
  <button *ngIf="event[0].add" mat-raised-button color="warn" type="reset"  class="mx-4" (click)="cancel()">Cancel</button>

  <button *ngIf="event[0].add" mat-raised-button color="primary" type="submit"  (click)="createEvent()">Create Event</button>
</div>
      <div class="col-md-4"></div>
    </div>
  </form>


</div>
