
<h1 class="bg-warning text-center rounded">Add Vehicle Details Form</h1>
<form [formGroup]="carForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Choose a date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="date">
      <mat-hint>dd/MM/yyyy</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Fuel:</mat-label>
      <mat-select formControlName="fuel">
        <mat-option value="petrol">Petrol</mat-option>
        <mat-option value="diesel">Diesel</mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Current KM of Meter:</mat-label>
      <input matInput type="text" formControlName="kilometers" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Vehicle Number:</mat-label>
      <mat-select formControlName="vehicleNumber">
        <mat-option *ngFor="let model of vehicles" [value]="model.vehicleNumber">
          {{ model.vehicleNumber }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-md-4">
      <mat-label>Driver Name:</mat-label>
      <mat-select formControlName="driverName">
        <mat-option *ngFor="let model of drivers" [value]="model.firstName +' '+ model.lastName">
          {{ model.firstName }} {{ model.lastName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="text-center">
    <button mat-raised-button color="accent" class="mb-4"  type="submit" >Submit</button>
  </div>
</form>
