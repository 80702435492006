<div class="container">

  <div class=" m-1 p-2">
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="selectionChange($event)">
    <mat-button-toggle value="all">All Events</mat-button-toggle>
<!--<mat-button-toggle value="Child">Child Events</mat-button-toggle>-->
    <mat-button-toggle value="Centre">Centre Events</mat-button-toggle>
  </mat-button-toggle-group>
  </div>

<!--<div class="event d-flex gap-5 m-1 p-5 ">-->
<!--  <div class="card " *ngFor="let e of (events$|async)">-->
<!--    <div class="bg-warning p-2" >-->
<!--      <h1 class="text-center">{{e.eventName}}</h1>-->
<!--    </div>-->
<!--      <div class="d-flex justify-content-end mt-1">-->
<!--        <button *ngIf="Events[0].all || Events[0].view" mat-stroked-button color="primary" class=" bg-warning text-dark  " [routerLink]='["/home/Events",e.key]'>View</button>-->
<!--      </div>-->

<!--    <table >-->
<!--      <tr><p>Event Details</p></tr>-->
<!--      <tr><th>Event Type</th><td>{{e.eventType}}</td></tr>-->
<!--      <tr><th>Event For</th><td>{{e.eventFor}}</td></tr>-->
<!--      <tr><th>Description</th><td>{{e.description}}</td></tr>-->
<!--      <tr><th>Date</th> <td>{{e.eventDate.toDate().getTime()|date:'dd/MM/yyyy'}}</td></tr>-->
<!--      <tr><th>Time</th> <td>{{e.eventTime.toDate().getTime()|date:'shortTime'}}</td></tr>-->
<!--      <br>-->
<!--      <tr><p>Contact Person Name</p></tr>-->
<!--      <tr><th>Name</th> <td>{{e.name}}</td></tr>-->
<!--      <tr><th>Email</th> <td>{{e.email}}</td></tr>-->
<!--      <tr><th>Number</th><td>{{e.number}}</td></tr>-->
<!--    </table>-->
<!--     <h4><strong> Located At:</strong></h4>-->
<!--      <div *ngFor="let l of e.code" >{{l?.centreName}}</div>-->
<!--  </div>-->
<!--</div>-->



    <div class="row clearfix">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="card">
          <div class="header d-flex justify-content-between">
            <h2>Events</h2>
            <div class="">
              <button mat-fab color="primary" *ngIf="Events[0].add || Events[0].all" aria-label="Example icon button with a bookmark icon" (click)="openCreateCenterDialog()">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
          <div class="body" >
            <div class="demo">
              <div class="container">
                <div class="d-flex  gap-2">
                  <div *ngFor="let e of (events$|async)" class="col-md-3 mx-4 mx-auto col-sm-6">
                    <div class="pricingTable">
                      <div class="pricingTable-header">
                        <i class="material-icons">brightness_medium</i>
                        <div class="price-value"> {{e.eventName}}
<!--                          <span class="month">per month</span>-->
                        </div>
                      </div>
                      <h3 class="heading"  *ngFor="let l of e.code">{{l.centreName}}</h3>
                      <div class="pricing-content">
                        <ul>
                          <li>
                            <b>Date</b> {{e.eventDate.toDate().getTime()|date:'dd/MM/yyyy'}}</li>
                          <li>
                            <b>Time</b> {{e.eventTime.toDate().getTime()|date:'shortTime'}}</li>
                          <li>
                            <b>Event For</b> {{e.eventFor}}</li>
                          <li>
                            <b>Description</b> {{e.description}}</li>
                          <br>
                        </ul>

                        <ul>
                          <li>
                            <b>Name</b> {{e.name}}</li>
                          <li>
                            <b>Email</b> {{e.email}}</li>
                          <li>
                            <b>Number</b> {{e.number}}</li>
                          <br>
                        </ul>
                      </div>
                      <div class="pricingTable-signup">
                        <a *ngIf="Events[0].all || Events[0].view" href="#" [routerLink]='["/home/Events",e.key]'>Photos</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


