import {Injectable} from '@angular/core';
import {AngularFireDatabase} from "@angular/fire/compat/database";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {where} from "@angular/fire/firestore";

export interface ActivityLog {
    date: number,
    section: string,
    action: string,
    user?: string,
    description: string
    currentIp: string


}

@Injectable({
    providedIn: 'root'
})
export class LogService {
    currentIp = localStorage.getItem('currentip')!

    constructor(
        private readonly mDatabase: AngularFireDatabase,
        private readonly mFirestore:AngularFirestore

    ) {

    }

    async  addLog (activity:ActivityLog)  {
        // activity.currentIp =this.currentIp
        let email =await localStorage.getItem('userEmail')
        // alert(email)
        activity.user=   email!
        activity.description=activity.description + ' '+ email
        activity.currentIp = this.currentIp
        this.mDatabase.list('activityLog').push(activity)
        console.log('Log Added ',JSON.stringify(activity))
    }
    getLogs(){
        return this.mDatabase.list<ActivityLog>('activityLog').valueChanges()
    }
  getLogsByCount(i:number){
    return this.mDatabase.list<ActivityLog>('activityLog',ref => ref.limitToLast(i)).snapshotChanges()
    // return this.mDatabase.list<ActivityLog>('activityLog').snapshotChanges()
  }

  addCounsellingLog(value:any){
       return this.mFirestore.collection('CounsellingLogs').add(value)
  }
    addEducationLog(value:any){
        return this.mFirestore.collection('EducationLogs').add(value)
    }
    updateCounsellingLog(value:any){
        return this.mFirestore.collection('CounsellingLogs').doc(value.id).update(value)
    }
    updateEducationLog(value:any){
        return this.mFirestore.collection('CounsellingLogs').doc(value.id).update(value)
    }
    deleteLog(id:string){
        return this.mFirestore.collection('CounsellingLogs').doc(id).delete()
    }
    deleteEducationLog(id:string){
        return this.mFirestore.collection('EducationLogs').doc(id).delete()
    }

    fetchCounsellingLog(selectedCentre: string){
      let q =  this.mFirestore.collection('CounsellingLogs',ref => ref.where('centreCode','==',selectedCentre)).valueChanges({idField:'id'})

    if(selectedCentre=="All")q= this.mFirestore.collection('CounsellingLogs').valueChanges({idField:'id'})
        return q
    }
    fetchEducationLogs(selectedCentre: string){
        let q =  this.mFirestore.collection('EducationLogs',ref => ref.where('centreCode','==',selectedCentre)).valueChanges({idField:'id'})

        if(selectedCentre=="All")q= this.mFirestore.collection('EducationLogs').valueChanges({idField:'id'})
        return q
    }

    updateStatus(element: any) {
        let status=element.status=='Pending'?'Verified':'Pending'
        return this.mFirestore.collection('CounsellingLogs').doc(element.id).update({status:status})

    }

    updateEducationStatus(element:any) {
        let status=element.status=='Pending'?'Verified':'Pending'
      return this.mFirestore.collection('EducationLogs').doc(element.id).update({status:status})   }

    updateSessionLog(sessionName: string, status: any, remark: string) {
        return this.mFirestore.doc(sessionName).update({status:status,
        remark:remark})

    }
}
