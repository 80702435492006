import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {UnitService} from "../../../services/unit.service";
import {needConfirmation} from "../../../confirm-dialog/confirm-dialog.decorator";
import {parseHtmlGracefully} from "@angular/core/schematics/utils/parse_html";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-edit-admission-reminder',
  templateUrl: './edit-admission-reminder.component.html',
  styleUrls: ['./edit-admission-reminder.component.scss']
})
export class EditAdmissionReminderComponent implements OnInit {
  form!: FormGroup;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      private  readonly unitService:UnitService,
      readonly matSnackbar:MatSnackBar,
      readonly matdialogRef:MatDialogRef<EditAdmissionReminderComponent>,
      private fb: FormBuilder
  ) { }





  ngOnInit() {
    this.unitService.testDelete()
    this.form = this.fb.group({
      centreCode: [this.data.centreCode, Validators.required],
      centreName: [this.data.centreName, Validators.required],
      childCode: [this.data.childCode, Validators.required],
      childName: [this.data.childName, Validators.required],
      followUpDate: [this.checkDate((this.data?.followUpDate))],
      remark: [this.data.remark, Validators.required]
    });
  }

@needConfirmation()
  onSubmit() {
this.unitService.updateReminder(this.form.value).then(()=>{
  this.matSnackbar.open('Updated Successfully')._dismissAfter(3000)
  this.matdialogRef.close()

})
  }
  onDelete(){
    // const key= this.data.key
    //  this.unitService.deleteReminder(key).then(()=>{
    //    this.matSnackbar.open('Deleted Successfully')._dismissAfter(3000)
    //  })
     this.matdialogRef.close()

   }

  private checkDate(date: any) {
    let d:any=''
    if(date!=null){
      d=(date as Timestamp).toDate()
    }
    else d=null
    return d

  }
}
