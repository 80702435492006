<div class="container">
  <h2 class="rounded text-center mt-2"> Event Posts </h2>
  <div>
    <div class="row">

      <cdk-virtual-scroll-viewport itemSize="10" class="viewport">
        <div class="row row-gap-2">
          <ng-container *cdkVirtualFor="let e of event?.events;let i = index">
            <!--------------------------------------------- NO BMI --------------------------------------------->
            <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <mat-card *ngIf="!e.isBMI" class="">
                <mat-card-header>
                  <!--            <div mat-card-avatar class="example-header-image"></div>-->
                  <mat-card-title class="text-capitalize">{{e.title}}</mat-card-title>
                  <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
                </mat-card-header>
                <ng-container *ngIf="e.res_type=='video'">
                  <iframe mat-card-image
                          src="https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/Child%2F47d6bb7d-0cf1-47b7-8084-526be0fbd4244608434017738393552.mp4?alt=media&token=ba5189e6-f018-45fa-8121-895e36975c56"
                          alt="Event Image Here"></iframe>
                </ng-container>
                <ng-container *ngIf="e.res_type!='video'">
                  <img class="im1 " mat-card-image [src]="e.res_url" alt="Event Image Here">
                </ng-container>
                <mat-card-content>
                  <p> <span>{{e.description}}</span>  {{e.hash_tags}}</p>

                  <strong>Tagged Children:
                    <span *ngFor="let c of e.child_codes">{{c.child_name}} </span>
                  </strong>
                </mat-card-content>
                <mat-divider></mat-divider>
                <mat-card-actions>
                  <div fxLayout="row">
                    <button mat-icon-button (click)="shareOnWhatsApp()">
                      <mat-icon class="col-blue" > textsms</mat-icon>
                    </button>
                    <button mat-icon-button>
                      <mat-icon class="col-red" (click)="shareOnEmail()">email</mat-icon>
                    </button>
                    <span fxFlex></span>
                    <button mat-button>
                      details
                    </button>
                  </div>
                </mat-card-actions>

              </mat-card>
              <!--------------------------------------------- WITH BMI --------------------------------------------->
              <mat-card *ngIf="e.isBMI" class="example-card">
                <mat-card-header>
                  <!--            <div mat-card-avatar class="example-header-image"></div>-->
                  <mat-card-title class="text-capitalize">{{e.title}}</mat-card-title>
                  <mat-card-subtitle>{{e.res_timing|date: 'medium'}}</mat-card-subtitle>
                </mat-card-header>
                <ng-container *ngIf="e.res_type=='video'">
                  <iframe mat-card-image
                          src="https://firebasestorage.googleapis.com/v0/b/acceife-fbdb5.appspot.com/o/Child%2F47d6bb7d-0cf1-47b7-8084-526be0fbd4244608434017738393552.mp4?alt=media&token=ba5189e6-f018-45fa-8121-895e36975c56"
                          alt="Event Image Here"></iframe>
                </ng-container>
                <ng-container *ngIf="e.res_type!='video'">
                  <img mat-card-image [src]="e.res_url" alt="Event Image Here">
                </ng-container>
                <mat-card-content>
                  <p>{{e.hash_tags}}</p>
                  <p class="text-capitalize">
                    {{e.description}}
                  </p>
                  <strong>Tagged Children: <span> {{e.child_codes}}</span>
                    <span *ngFor="let c of e.child_codes">{{c.child_name}}</span>
                  </strong>
                  <table style="width: 100%">
                    <th>Height</th>
                    <th>Weight</th>
                    <th>BMI</th>
                    <tr>
                      <!--                <th>Height </th>-->
                      <td> {{e.height}}</td>
                      <!--                <th>Weight </th>-->
                      <td> {{e.weight}}</td>
                      <!--                <th>BMI </th>-->
                      <td> {{e.bmi}}</td>
                    </tr>
                  </table>
                </mat-card-content>
                <mat-divider></mat-divider>
                <mat-card-actions>
                  <div fxLayout="row">
                    <button mat-icon-button>
                      <mat-icon class="col-blue">share</mat-icon>
                    </button>
                    <button mat-icon-button>
                      <mat-icon class="col-red">favorite</mat-icon>
                    </button>
                    <span fxFlex></span>
                    <button mat-button>
                      details
                    </button>
                  </div>
                </mat-card-actions>

              </mat-card>
            </div>
          </ng-container>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>

  </div>

</div>
