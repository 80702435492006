<h3 class="text-center">
  Volunteer Registration Form
</h3>
<br>
<form  class="about" [formGroup]="volunteerForm">
  <div class="row">

     <div class="col-12 text-center" >
    <div class="uploadfilecontainer"  (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
      <input hidden type="file" #fileInput (change)="uploadFile($event)">
      <button mat-icon-button  class="icon-button-large" aria-label="large button">
        <mat-icon>cloud_upload</mat-icon>
      </button>
      <h6>Upload your photo <br> Drag and Drop your files here <br>.JPG.PNG. </h6>
      <p> </p>
    </div>
      <div  class="files-list" *ngFor="let file of files;let i= index">
        <p> {{ file }} </p>
        <button   (click)="deleteAttachment(i)"  mat-icon-button  class="delete-file  icon-button-large" aria-label="large button">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Volunteer Center Code</mat-label>
        <input matInput placeholder="Volunteercentrecode" required formControlName="centrecode">
      </mat-form-field>
      <div *ngIf="  centrecode.invalid  &&  (centrecode.touched || centrecode.dirty)">
        <span style="color: red;" *ngIf="centrecode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="centrecode.errors?.pattern">*Enter Only Numbers.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Volunteer Code</mat-label>
        <input matInput placeholder="Vounteercode" required formControlName="volunteercode" >
      </mat-form-field>
      <div *ngIf="volunteercode.invalid  &&  (volunteercode.touched ||volunteercode.dirty)">
        <span style="color: red;" *ngIf="volunteercode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="volunteercode.errors?.pattern">*Enter Only Numbers.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Employee Role</mat-label>
        <input matInput placeholder="EmployeeRole" required formControlName="employeerole" >
      </mat-form-field>
      <div *ngIf="  employeerole.invalid  &&  (employeerole.touched || employeerole.dirty)">
        <span style="color: red;" *ngIf="employeerole.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="employeerole.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-5">
      <mat-form-field appearance="outline">
        <mat-label>Employee Status</mat-label>
        <input matInput placeholder="Employeestatus" required   formControlName="employeestatus">
      </mat-form-field>
      <div *ngIf="employeestatus.invalid  &&  (employeestatus.touched || employeestatus.dirty)">
        <span style="color: red;" *ngIf="employeestatus.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="employeestatus.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>


    <div class="col-3">
      <mat-form-field appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput placeholder="First Name" required formControlName="firstname"  >
      </mat-form-field>
      <div *ngIf="  firstname.invalid  &&  (firstname.touched || firstname.dirty)">
        <span style="color: red;" *ngIf="firstname.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="firstname.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-3">
      <mat-form-field appearance="outline">
        <mat-label>Middle Name</mat-label>
        <input matInput placeholder="Middle Name" required  formControlName="middlename" >
      </mat-form-field>
      <div *ngIf="  middlename.invalid  &&  (middlename.touched || middlename.dirty)">
        <span style="color: red;" *ngIf="middlename.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="middlename.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput placeholder="Last Name" required  formControlName="lastname">
      </mat-form-field>
      <div *ngIf="  lastname.invalid  &&  (lastname.touched || lastname.dirty)">
        <span style="color: red;" *ngIf="lastname.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="lastname.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-3">
      <mat-form-field appearance="outline">
        <mat-label >Gender</mat-label>
        <mat-select formControlName="gender">
          <mat-option value="Female">Female</mat-option>
          <mat-option value="Male">Male</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!--    pattern="[0-9]{10}$"-->
    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label>Mobile No.</mat-label>
        <input matInput placeholder="Mobileno" required   formControlName="mobile">
        <mat-icon matSuffix>phone</mat-icon>
      </mat-form-field>
      <div *ngIf="  mobile.invalid  &&  (mobile.touched || mobile.dirty)">
        <span style="color: red;" *ngIf="mobile.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="mobile.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

    <div class="col-3">
      <mat-form-field appearance="outline">
        <mat-label>Date of Birth</mat-label>
        <input matInput [matDatepicker]="picker" placeholder="DOB"  required formControlName="dob">
        <mat-hint>DD/MM/YYYY</mat-hint>
        <mat-datepicker-toggle  matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>



    <div class="col-10">
      <mat-form-field appearance="outline">
        <mat-label>Street</mat-label>
        <input matInput placeholder="Street" required formControlName="street">
      </mat-form-field>
      <div *ngIf="street.invalid  &&  (street.touched || street.dirty)">
        <span style="color: red;" *ngIf="street.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="street.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-3">
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput placeholder="City" required formControlName="city">
      </mat-form-field>
      <div *ngIf="  city.invalid  &&  (city.touched || city.dirty)">
        <span style="color: red;" *ngIf="city.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="city.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-4">
      <mat-form-field appearance="outline">
        <mat-label>State</mat-label>
        <input matInput placeholder="State Name"  required formControlName="state">
      </mat-form-field>
      <div *ngIf="  state.invalid  &&  (state.touched || state.dirty)">
        <span style="color: red;" *ngIf="state.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="state.errors?.pattern">*Enter Only characters.</span>
      </div>
    </div>

    <div class="col-3">
      <mat-form-field appearance="outline">
        <mat-label>Pin Code</mat-label>
        <input matInput #postalCode maxlength="6" required  placeholder="pincode" formControlName="pincode"  >
        <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
      </mat-form-field>
      <div *ngIf="  pincode.invalid  &&  (pincode.touched || pincode.dirty)">
        <span style="color: red;" *ngIf="pincode.errors?.required">*It is  Required.</span>
        <span  style="color: red;" *ngIf="pincode.errors?.pattern">*Enter Only Number.</span>
      </div>
    </div>

  </div>


  <button color="warn" mat-raised-button (click)="cancel()" >Cancel</button> &nbsp;
  <button color="primary" mat-raised-button type="submit" (click)="SubmitData()">Save</button>


</form>

