import {Component, OnInit, ViewChild} from '@angular/core';
import {UnitSponsorshipService} from "../unit-sponsorship.service";
import {MatSelectChange} from "@angular/material/select";
import {Observable} from "rxjs";
import {Centre} from "../../../interfaces/Centres";
import {CentreService} from "../../../services/centre.service";
import {MatTableDataSource} from "@angular/material/table";
import {FullChild} from "../../../interfaces/FullChild";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {isArray, isObject, isString} from "lodash";

@Component({
  selector: 'app-unsponsor-children-table',
  templateUrl: './unsponsor-children-table.component.html',
  styleUrls: ['./unsponsor-children-table.component.scss']
})
export class UnsponsorChildrenTableComponent implements OnInit {
  datasource!:any
  selectedCentre = 'All'
  centres$!: Observable<Centre[]>
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'gender',
    'age',
    'dob',
    'centreName',
    'cancer',
    'state',
    'hospital',
    // 'state',
    // 'city',
    // 'pincode',
    // 'action'
  ];
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private mService: UnitSponsorshipService,
              private centreService: CentreService,) {
    this.centres$ = this.centreService.getAllCentres()

  }

  ngOnInit(): void {
    this.datasource = new MatTableDataSource<FullChild>();
    this.datasource.sort = this.sort;
    this.datasource.paginator = this.paginator;
    this.mService.fetchNonSponsoredChildren2(this.selectedCentre).subscribe(res =>{
      console.log(res)
      this.datasource = new MatTableDataSource(res)
      this.datasource.sort =this.sort
      this.datasource.paginator =this.paginator

    })

  }

  onCentreSelect($event: MatSelectChange) {
    this.mService.fetchNonSponsoredChildren2(this.selectedCentre).subscribe(res =>{
      console.log(res)
      this.datasource = new MatTableDataSource(res)
      this.datasource.sort =this.sort
      this.datasource.paginator =this.paginator




    })

  }

  showChild(element:any) {

  }

  protected readonly isString = isString;
  protected readonly isObject = isObject;
  protected readonly isArray = isArray;
}
