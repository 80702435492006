<form [formGroup]="addressForm"  >
  <mat-card class="shipping-card">
    <mat-card-header>
      <mat-card-title>Hospital Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <mat-form-field class="full-width">-->
<!--            <input matInput placeholder="Company" formControlName="company">-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row">
        <div class="col-md-8">
          <mat-form-field class="full-width">
            <input matInput (blur)="onBlur(hospitalName)" placeholder="Hospital Name" formControlName="hospitalName">
            <mat-error *ngIf="hospitalName.invalid  &&  (hospitalName.touched || hospitalName.dirty)">
              <mat-error style="color: red;" *ngIf="hospitalName.errors?.required">*It is  Required.</mat-error>
              <mat-error style="color: red;" *ngIf="hospitalName.errors?.pattern">*Enter Only Character.</mat-error>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-md-4">
          <mat-form-field class="full-width">
            <input matInput  placeholder="Short Name" formControlName="shortName">
            <mat-error *ngIf="shortName.invalid  &&  (shortName.touched || shortName.dirty)">
              <mat-error style="color: red;" *ngIf="shortName.errors?.required">*It is  Required.</mat-error>
              <mat-error style="color: red;" *ngIf="shortName.errors?.pattern">*Enter Only Character.</mat-error>
            </mat-error>
          </mat-form-field>
        </div>


        <div>

          <mat-form-field class="full-width">
            <input matInput (blur)="onBlur(docterName)" placeholder="Doctors  Name" formControlName="doctorsName">
            <mat-error *ngIf="addressForm.get('doctorsName')?.invalid  &&  (addressForm.get('doctorsName')?.touched || addressForm.get('doctorsName')?.dirty)">
              <mat-error style="color: red;" *ngIf="addressForm.get('doctorsName')?.errors?.required">*It is  Required.</mat-error>
              <mat-error style="color: red;" *ngIf="addressForm.get('doctorsName')?.errors?.pattern">*Enter Only Character.</mat-error>
            </mat-error>
          </mat-form-field>
        </div>`

        <div>
        <mat-form-field class="full-width">
          <input matInput (blur)="onBlur(socialWorkerName)" placeholder="Medical Social Worker Name" formControlName="socialWorkerName">
          <mat-error *ngIf="addressForm.get('socialWorkerName')?.invalid  &&  (addressForm.get('socialWorkerName')?.touched || addressForm.get('socialWorkerName')?.dirty)">
            <mat-error style="color: red;" *ngIf="addressForm.get('socialWorkerName')?.errors?.required">*It is  Required.</mat-error>
            <mat-error style="color: red;" *ngIf="addressForm.get('socialWorkerName')?.errors?.pattern">*Enter Only Character.</mat-error>
          </mat-error>
        </mat-form-field>
      </div>


      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <textarea matInput (blur)="onBlur(address)"  placeholder="Address" formControlName="address"></textarea>
            <mat-error *ngIf="address.invalid  &&  (address.touched || address.dirty)">
              <mat-error style="color: red;" *ngIf="address.errors?.required">*It is  Required.</mat-error>
              <mat-error style="color: red;" *ngIf="address.errors?.pattern">*Enter Only Character.</mat-error>
            </mat-error>
          </mat-form-field>
        </div>razo
      </div>
<!--      <div class="row" *ngIf="hasUnitNumber">-->
<!--        <div class="col">-->
<!--          <mat-form-field class="full-width">-->
<!--            <textarea matInput (blur)="onBlur(address2)" placeholder="Landmark" formControlName="address2"></textarea>-->
<!--          </mat-form-field>-->
<!--        </div>-->
<!--      </div>-->
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput (blur)="onBlur(city)" placeholder="City" formControlName="city">
            <mat-error *ngIf="city.invalid  &&  (city.touched || city.dirty)">
            <mat-error *ngIf="city.errors?.required">City is <strong>required</strong></mat-error>
            <mat-error style="color: red;" *ngIf="city.errors?.pattern">*Enter Only Character.</mat-error>
<!--            <mat-error style="color: red;" *ngIf="addressForm.controls['city'].hasError('pattern')">*Enter Only Character.</mat-error>-->
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="full-width">
            <mat-select placeholder="State" formControlName="state">
              <mat-option *ngFor="let state of mState" [value]="state.name">
                {{ state.name }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
              State is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <input matInput #postalCode maxlength="6" placeholder="Pin Code" type="text" formControlName="zipcode">
            <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
            <mat-error *ngIf="zipcode.invalid  &&  (zipcode.touched || zipcode.dirty)">
            <mat-error *ngIf="zipcode.errors?.pattern">Only  Numbers is  <strong>allowed</strong></mat-error>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
<!--      <div class="row">-->
<!--        <div class="col">-->
<!--          <mat-radio-group formControlName="shipping">-->
<!--            <mat-radio-button value="free">Free Shipping</mat-radio-button>-->
<!--            <mat-radio-button value="priority">Priority Shipping</mat-radio-button>-->
<!--            <mat-radio-button value="nextday">Next Day Shipping</mat-radio-button>-->
<!--          </mat-radio-group>-->
<!--        </div>-->
<!--      </div>-->
    </mat-card-content>
    <mat-card-actions class="text-center">
      <button mat-raised-button color="accent" type="button" (click)="onCancel()">Cancel</button>
      <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">Submit</button>
    </mat-card-actions>
  </mat-card>
</form>
