import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {FullChild} from "../../../interfaces/FullChild";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {Centre} from "../../../interfaces/Centres";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CentreService} from "../../../services/centre.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AuthService} from "../../../auth.service";
import {ChildService} from "../child.service";
import {ChildCreateComponent} from "../child-create/child-create.component";
import {Child} from "../childdata";
import {ViewRegisteredChildComponent} from "../view-registered-child/view-registered-child.component";
import {MatSelectChange} from "@angular/material/select";
import {shareReplay, tap} from "rxjs/operators";
import firebase from "firebase/compat";
import Timestamp = firebase.firestore.Timestamp;
import {AdmissionType} from "../../../interfaces/AdmissionType";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-registered-child-excel',
  templateUrl: './registered-child-excel.component.html',
  styleUrls: ['./registered-child-excel.component.scss']
})
export class RegisteredChildExcelComponent implements AfterViewInit, OnDestroy {
  childs?: Observable<FullChild[]>
  selectedCentreCode: string = ''
  selectedStatus = 'Staying'
  displayedColumns: String[] = ['position', 'firstname', 'lastname', 'city', 'enrolledIn', 'status', 'unitCode', 'Action'];
  childData: FullChild[] = []
  private _destroyed$ = new Subject()
  @ViewChild(MatPaginator) paginator1!: MatPaginator;
  @ViewChild(MatSort) sort1!: MatSort;
  dataSource: MatTableDataSource<FullChild>;
  centres$: Observable<Centre[]>
  fileName = 'ALChild.xlsx';
  readCount =0  //Used in tap operator
  finalchildlist?: any[];

  constructor(private matdialog: MatDialog,
              private router: Router,
              private matDialog: MatDialog,
              private _snackBar: MatSnackBar,
              private readonly centreService: CentreService,
              private readonly mFirestore:AngularFirestore,
              readonly authService: AuthService,
              private childService: ChildService) {
    this.dataSource = new MatTableDataSource(this.childData);
    this.centres$ = this.centreService.getAllCentres()
  }

  ngAfterViewInit(): void {
     this.getAllChildToExport()

  }


  openpopup() {
    this.matdialog.open(ChildCreateComponent,
        {
          width: '90%', height: '95%',
          enterAnimationDuration: '100ms',
          exitAnimationDuration: '200ms',

        },
    )
  }

  showDischarge() {
    this._snackBar.open('Child Discharged Successfully', '', {duration: 3000})
  }

  datashow(index: Child) {
    console.log('showdata :' + index)
    this.matdialog.open(ViewRegisteredChildComponent,
        {
          width: '97%', height: '95%',
          enterAnimationDuration: '100ms',
          exitAnimationDuration: '100ms',
          data: index,
          autoFocus: false
          // @ts-ignore


        }
    )
  }

  // documentToDomainObject = _ => {
  //   const object = _.payload.doc.data();
  //   object.id = _.payload.doc.id;
  //   return object;
  // }}

  applyFilter($event: Event) {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }

  }

  gotoToEdit(element: FullChild) {
    this.router.navigateByUrl('home/edit-admission', {state: element})

  }

  ngOnDestroy(): void {
    this._destroyed$.next(null)
    this._destroyed$.complete()
  }

  getData(centreCode: string) {
    if (centreCode == '') {
      this._snackBar.open('Please select a centre to continue', '')._dismissAfter(2000)
    }
    if (centreCode != 'All' ) {
      this.childs = this.childService.allotedChildRegistrationbyCenter(this.selectedStatus, centreCode)
          // .pipe(takeUntil(this._destroyed$))
    }
    // else if (centreCode == 'All' && this.selectedStatus=='All') this.childs = this.childService.childRegistration().pipe(takeUntil(this._destroyed$))

    else if (centreCode == 'All') this.childs = this.childService.allotedChildRegistrationbyCenter(this.selectedStatus, centreCode)
        .pipe(takeUntil(this._destroyed$))


    this.childs?.subscribe(data => {
      console.log('childList Subscription', data)
      //   console.assessment-table(data)
      const tempdocs: FullChild[] = []
      data.forEach((docSnapshots) => {
        // const c:Child
        // const c:Child=docSnapshots.payload.doc.id
        tempdocs.push((docSnapshots as FullChild))
        // console.assessment-table(docSnapshots.payload.doc.id)

      })
      tempdocs.reverse()
      this.childData = tempdocs
      this.dataSource = new MatTableDataSource(this.childData)
      this.dataSource.data = this.childData
      this.dataSource.sort = this.sort1
      this.dataSource.paginator = this.paginator1

    })
    // If the user changes the sort order, reset back to the first page.

    // this.sort1.sortChange.subscribe(() => (this.paginator1.pageIndex = 0));

  }

  setSelectedCentreCode($event: MatSelectChange) {
    this.selectedCentreCode = $event.value
    console.log(this.selectedCentreCode)
    this.getData(this.selectedCentreCode)
  }

//** Original Excel  To get All child (Only Visible to Gaurav )
// exportexcel() {
//   /* assessment-table id is passed over here */
//   let element = document.getElementById('excel-assessment-table');
//
//   const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.childData);
//   // const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
//   // const ws: XLSX.WorkSheet =XLSX.utils.json_to_sheet(this.receipts)
//   ws['!cols'] = [];
//   // ws['!cols'][19] = {hidden: true};
//
//   /* O1 is your Column in Excel*/
//
//   /* generate workbook and add the worksheet */
//   const wb: XLSX.WorkBook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//
//   /* save to file */
//   XLSX.writeFile(wb, this.fileName);
//
//
// }

  private getAllChildToExport() {
    this.mFirestore.collection<FullChild>('ALCHILDBACKUPBEFOREMEETING').get().pipe(
        tap(arr => console.log(`centre: read (${this.readCount += arr.docs.length} )docs`), shareReplay(1)
        ))
        .subscribe(res => {
          let allchild = res.docs.map(y => y.data())
          let formattedAlChild: any[] = []
          formattedAlChild = allchild
          formattedAlChild.forEach(c => {
            if (c.dateOfAdmission != null) {
              c.dateOfAdmission = getFormattedDate ((c.dateOfAdmission as unknown as Timestamp).toDate())
            }
            if(c.dateOfDischarge!=null && typeof c.dateOfDischarge !== 'string'){
              c.dateOfDischarge= getFormattedDate ((c.dateOfDischarge as unknown as Timestamp).toDate())
            }
            if (c.dob != null) {
              c.dob = getFormattedDate( c.dob.toDate())
            }
            if (c.admissionData != null) {
              // @ts-ignore
              c.admissionData.forEach(a => {
                if (a.dateOfAdmission != null) {
                  a = a as AdmissionType
                  a.dateOfAdmission =getFormattedDate( (a.dateOfAdmission as unknown as Timestamp).toDate())
                }
                if (a.dateOfDischarge != null ) {
                  a = a as AdmissionType
                  a.dateOfDischarge =getFormattedDate ( (a.dateOfDischarge as unknown as Timestamp).toDate())
                }
              })
            }
          })
          this.finalchildlist = formattedAlChild
          console.log('xxxxx===>>>>>>', formattedAlChild)
          this.childData = allchild
          this.dataSource.data = allchild
          this.dataSource = new MatTableDataSource(allchild)
          this.dataSource.sort = this.sort1
          this.dataSource.paginator = this.paginator1
          let exportData = this.finalchildlist;
          return saveAs(
              new Blob([JSON.stringify(exportData, null, 2)], {type: 'JSON'}), 'ALCHILDBACKUPBEFOREMEETING.json'
          );
        })

// exportData is your array which you want to dowanload as json and sample.json is your file name, customize the below lines as per your need.
  }

}
function getFormattedDate(date:Date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '-' + day + '-' + year;
}
