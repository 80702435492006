<div class="container">

    <mat-form-field appearance="outline">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input  [rangePicker]="picker">
            <input matStartDate [(ngModel)]="startDate" placeholder="Start date">
            <input matEndDate [(ngModel)]="endDate" placeholder="End date">
        </mat-date-range-input>
        <mat-hint>dd/MM/yyyy – dd/MM/yyyy</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>


    </mat-form-field>&nbsp; &nbsp;
<button mat-mini-fab (click)="search()"><mat-icon>search</mat-icon></button>
  <button mat-mini-fab (click)="clearAll()"><mat-icon>clear</mat-icon></button>
<div class="card p-2">
    <h3 class="text-center">Hospital Chart</h3>

    <table class="table table-striped table-bordered">
    <thead>
    <tr>
        <th scope="col">Hospital</th>

        <th scope="col" *ngFor=" let c of uniqueCentres">{{c}} </th>

    </tr>
    </thead>
    <tbody>
    <tbody>
    <tr *ngFor="let data of tableData">
        <td>{{ data.hospital }}</td>
                <ng-container *ngFor="let centre of data.centres">
                    <td>{{ centre.count !== 0 ? centre.count : '-'}}</td>
                </ng-container>
    </tr>
    </tbody>

</table>
</div>
    <div class="card p-2">
    <h3 class="text-center">Unique Admission Age Chart</h3>
    <table class="table table-striped table-bordered">
        <thead>
        <tr>
            <th scope="col">Age Group</th>

            <th scope="col" *ngFor=" let c of uniqueCentres">{{c}} </th>

        </tr>
        </thead>
        <tbody>
        <tbody>
        <tr *ngFor="let data of uniqueAgeData">
            <td>{{ data.ageGroup }}</td>
            <ng-container *ngFor="let centre of data.centres">
                <td>{{ centre.count !== 0 ? centre.count : '-'}}</td>
            </ng-container>
        </tr>
        </tbody>

    </table>
    </div>
    <div class="card p-2">
    <h3 class="text-center">Follow Up Admission Age Chart</h3>
    <table class="table table-striped table-bordered">
        <thead>
        <tr>
            <th  scope="col">Age Group</th>

            <th  scope="col" *ngFor=" let c of uniqueCentres">{{c}} </th>

        </tr>
        </thead>
        <tbody>
        <tbody>
        <tr *ngFor="let data of followUpAgeData">
            <td>{{ data.ageGroup }}</td>
            <ng-container *ngFor="let centre of data.centres">
                <td>{{ centre.count !== 0 ? centre.count : '-'}}</td>
            </ng-container>
        </tr>
        </tbody>

    </table>
    </div>



</div>
