<div class="container">
    <form [formGroup]="mealForm">
<!--        <h3>{{TODAY_STR }} - {{this.date}}</h3>-->
<!--        <h3>{{todaysDate.getHours()}}</h3>-->
<!--        <h3>{{date}}</h3>-->
        <h2 class="text-center">{{centre.centreName}}</h2>
        <hr>
        <h4>Date: {{date|date}}</h4>
        <h5>For Child : 1000INR For Family: 4000INR</h5>
        <!--------------------------------------------- Slot Status --------------------------------------------->
        <div class="mb-5 " *ngIf="mSlots!=undefined && mSlots!=null">
            <h2>Existing Booked Slots for the day</h2>
            <span class="heading" style="margin-left: 0px" *ngIf="mSlots.lunchForChild ">Lunch For Child  </span>
            <span class="heading" style="margin-left: 0px" *ngIf="mSlots.lunchForFamily">Lunch For Family  </span>

            <span class="heading" style="margin-left: 0px" *ngIf="mSlots.dinnerForChild">Dinner For Child   </span>
            <span class="heading" style="margin-left: 0px" *ngIf="mSlots.dinnerForFamily">Dinner For Family  </span>

            <span class="heading" style="margin-left: 0px"
                  *ngIf="mSlots.lunchAndDinnerForChild">Lunch and Dinner For Child   </span>
            <span class="heading" style="margin-left: 0px"
                  *ngIf="mSlots.lunchAndDinnerForFamily">Lunch and Dinner For Family  </span>

            <!--      <span class="heading" *ngIf="!mSlots.bookedForLunch">Available For Lunch  :{{mSlots.bookedForLunch}} </span>-->
            <!--      <span class="heading" *ngIf="!mSlots.bookedForDinner">Available For Dinner:{{ mSlots.bookedForDinner}} </span>-->
        </div>
        <!--    <div class="mb-5 " *ngIf="mSlots==undefined">-->
        <!--      <h3>slots are null</h3>-->
        <!--      <span class="heading" style="margin-left: 0px">Total Slots :{{ centre.occupiedUnits}}  </span>-->
        <!--      <span class="heading" *ngIf="true">Available For Lunch  :{{centre.occupiedUnits}} </span>-->
        <!--      <span class="heading" *ngIf="true">Available For Dinner:{{ centre.occupiedUnits}} </span>-->
        <!--    </div>-->
        <!--------------------------------------------- Slot Status Ended --------------------------------------------->

        <div class="row">
            <!--------------------------------------------- SLOTS SELECT --------------------------------------------->
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Meal Slot</mat-label>
                    <mat-select formControlName="mealSlot" placeholder="" (selectionChange)="selectionChange()"
                                #mMealSlot>
                        <mat-option
                                [disabled]=" restrictLunch|| mSlots!.lunchAndDinnerForChild|| mSlots!.lunchAndDinnerForFamily|| mSlots!.lunchForChild ||mSlots!.lunchAndDinnerForChild ||mSlots!.lunchForFamily "
                                value="Lunch for child">Lunch for Child
                        </mat-option>
                        <mat-option
                                [disabled]=" restrictLunch||mSlots!.lunchAndDinnerForChild|| mSlots!.lunchAndDinnerForFamily|| mSlots!.lunchForFamily || mSlots!.lunchAndDinnerForFamily  || mSlots!.lunchForChild  "
                                value="Lunch for family">Lunch for Family
                        </mat-option>

                        <mat-option
                                [disabled]="restrictDinner|| mSlots!.lunchAndDinnerForChild|| mSlots!.lunchAndDinnerForFamily|| mSlots!.dinnerForChild || mSlots!.dinnerForFamily ||mSlots!.lunchAndDinnerForChild "
                                value="Dinner for child">Dinner for Child
                        </mat-option>
                        <mat-option
                                [disabled]="restrictDinner|| mSlots!.lunchAndDinnerForChild || mSlots!.lunchAndDinnerForFamily|| mSlots!.dinnerForFamily ||mSlots!.lunchAndDinnerForFamily! ||mSlots!.dinnerForChild "
                                value="Dinner for family">Dinner for Family
                        </mat-option>

                        <!--          <mat-option [disabled]="mSlots!.dinnerForChildAndFamily || mSlots!.dinnerForChild || mSlots!.dinnerForFamily "-->
                        <!--                      value="Dinner for child and family">Dinner for Child and Family-->
                        <!--          </mat-option>-->
<!--                        <mat-option-->
<!--                                [disabled]="mSlots!.lunchAndDinnerForChild|| mSlots!.lunchAndDinnerForFamily|| mSlots!.lunchAndDinnerForChild || mSlots!.lunchForChild ||mSlots!.dinnerForChild ||mSlots!.lunchForFamily|| mSlots!.dinnerForFamily"-->
<!--                                value="Lunch and dinner for child">Lunch and Dinner for Child-->
<!--                        </mat-option>-->
<!--                        <mat-option-->
<!--                                [disabled]="mSlots!.lunchAndDinnerForChild|| mSlots!.lunchAndDinnerForFamily|| mSlots!.lunchForChild || mSlots!.lunchForFamily ||mSlots!.dinnerForChild || mSlots!.dinnerForFamily "-->
<!--                                value="Lunch and dinner for family">Lunch and Dinner for Family-->
<!--                        </mat-option>-->
                        <!--          <mat-option [disabled]="mSlots!.lunchAndDinnerForFamily ||mSlots!.lunchForFamily"-->
                        <!--                      value="Lunch for child and dinner for family">Lunch for child and Dinner for Family-->
                        <!--          </mat-option>-->
                        <!--          -->
                        <!--          <mat-option-->
                        <!--            [disabled]=" mSlots!.lunchForChild || mSlots!.lunchAndDinnerForChildAndFamily || mSlots!.lunchForChild ||mSlots!.lunchForChildAndFamily ||mSlots!.lunchForFamily"-->
                        <!--            value="Lunch and dinner for child and family">Lunch and Dinner for Child and Family-->
                        <!--          </mat-option>-->
                        <!--            <mat-option   value="Lunch and Dinner">Both</mat-option>-->
                    </mat-select>
                    <mat-error *ngIf="mealSlot?.invalid  &&  (mealSlot?.touched || mealSlot?.dirty)">
                        <span style="color: red;" *ngIf="mealSlot?.errors?.required">*It is Required.</span>
                    </mat-error>
                </mat-form-field>

            </div>
            <!--------------------------------------------- EVENT NAME --------------------------------------------->

            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Event Name</mat-label>
                    <mat-select formControlName="eventName">
                        <mat-option value="Birthday">Birthday</mat-option>
                        <mat-option value="Anniversary">Anniversary</mat-option>
                        <mat-option value="Death">Death</mat-option>
                        <mat-option value="Thank You">Thank You</mat-option>
                        <mat-option value="Other">Other</mat-option>
                    </mat-select>
                    <mat-error *ngIf="eventName?.invalid  &&  (eventName?.touched || eventName?.dirty)">
                        <span style="color: red;" *ngIf="eventName?.errors?.required">*It is Required.</span>
                        <!--            <span style="color: red;" *ngIf="eventName?.errors?.pattern">*Enter Only C.</span>-->
                        <!--            <span style="color: red;" *ngIf="eventName?.errors?.minlength">Mobile number of 10 digits required</span>-->
                    </mat-error>
                </mat-form-field>
            </div>
            <!--------------------------------------------- Message of Description --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Message/Description</mat-label>
                    <input matInput formControlName="eventMessage"/>
                    <mat-error *ngIf="eventMessage?.invalid  &&  (eventMessage?.touched || eventMessage?.dirty)">
                        <span style="color: red;" *ngIf="eventMessage?.errors?.required">*It is Required.</span>
                        <!--            <span style="color: red;" *ngIf="eventName?.errors?.pattern">*Enter Only C.</span>-->
                        <!--            <span style="color: red;" *ngIf="eventName?.errors?.minlength">Mobile number of 10 digits required</span>-->
                    </mat-error>
                </mat-form-field>
            </div>

            <!--------------------------------------------- DONOR NAME --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Donor Name</mat-label>
                    <input matInput placeholder="Enter Donor Name" [formControl]="myControl" [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                                      (optionSelected)="optionSelect($event)">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.donorName}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="donorName?.invalid  &&  (donorName?.touched || donorName?.dirty)">
                        <span style="color: red;" *ngIf="donorName?.errors?.required">*It is Required.</span>
                        <span style="color: red;" *ngIf="donorName?.errors?.pattern">*Enter Only Character.</span>
                        <span style="color: red;" *ngIf="donorName?.errors?.minlength">Mobile number of 10 digits required</span>
                    </mat-error>
                </mat-form-field>
            </div>
            <!--------------------------------------------- MOBILE --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Mobile Number</mat-label>
                    <input matInput placeholder="Enter Donor Number" formControlName="mobile" maxlength="10">
                    <mat-error *ngIf="donorNumber?.invalid  &&  (donorNumber?.touched || donorNumber?.dirty)">
                        <span style="color: red;" *ngIf="donorNumber?.errors?.required">*It is Required.</span>
                        <span style="color: red;" *ngIf="donorNumber?.errors?.pattern">*Enter Only Number.</span>
                        <span style="color: red;" *ngIf="donorNumber?.errors?.minlength">Mobile number of 10 digits required</span>
                    </mat-error>
                </mat-form-field>

            </div>

            <!--------------------------------------------- ADDRESS --------------------------------------------->

            <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-sm-12 ">
                    <mat-label>Address</mat-label>
                    <textarea matInput formControlName="address"></textarea>
                    <mat-error
                            *ngIf="mealForm.controls.address.hasError('email') && !mealForm.controls.address.hasError('required')">
                        Please enter address
                    </mat-error>
                    <mat-error *ngIf="mealForm.controls.address.hasError('required')">
                        Address is <strong>required</strong></mat-error>

                </mat-form-field>
            </div>
            <!--------------------------------------------- STATE --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="state" class="">
                        <mat-option *ngFor="let s of stateslist" [value]="s.name">{{s.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="mealForm.controls.state.hasError('required')">
                        State is <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
            <!--------------------------------------------- ZIPCODE --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label>Pincode</mat-label>
                    <input matInput placeholder="Enter Zip Code" formControlName="zipcode" maxlength="6">
                    <mat-error *ngIf="mealForm.controls.zipcode.hasError('required')">Pincode is
                        <strong>required</strong></mat-error>
                    <mat-error
                            *ngIf="  mealForm.controls.zipcode.invalid  &&  (mealForm.controls.zipcode.touched || mealForm.controls.zipcode.dirty)">
                    <span style="color: red;"
                          *ngIf="mealForm.controls.zipcode.errors?.required">*It is Required.</span>
                        <span style="color: red;"
                              *ngIf="mealForm.controls.zipcode.errors?.pattern">*Enter Only Number.</span>
                        <span style="color: red;"
                              *ngIf="mealForm.controls.zipcode.errors?.minlength">Zipcode must be of 6 digits</span>
                    </mat-error>
                </mat-form-field>

            </div>
            <!--------------------------------------------- EMAIL --------------------------------------------->
            <div class="col-md-6">

                <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error
                            *ngIf="mealForm.controls.email.hasError('email') && !mealForm.controls.email.hasError('required')">
                        Please enter a valid email e.g. <strong>xyz@gmail.com</strong>
                    </mat-error>
                    <mat-error *ngIf="mealForm.controls.email.hasError('required')">
                        Email is <strong>required</strong></mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country">
                        <mat-option value="India"> India</mat-option>
                    </mat-select>
                    <mat-error *ngIf="mealForm.controls.country.hasError('required')">
                        Country is <strong>required</strong></mat-error>
                </mat-form-field>
            </div>

            <!--------------------------------------------- CITY --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="col-sm-12">
                    <mat-label>City</mat-label>
                    <input matInput placeholder="Enter City" formControlName="city">
                    <mat-icon matSuffix>location_city</mat-icon>
                    <!--                  <mat-error *ngIf="mealForm.controls.city.hasError('required')">-->
                    <!--                    City is <strong>required</strong></mat-error>-->
                    <!--                </mat-form-field>-->
                    <mat-error
                            *ngIf="  mealForm.controls.city.invalid  &&  (mealForm.controls.city.touched || mealForm.controls.city.dirty)">
                        <mat-error style="color: red;" *ngIf="mealForm.controls.city.errors?.required">*It is
                            Required.
                        </mat-error>
                        <mat-error style="color: red;" *ngIf="mealForm.controls.city.errors?.pattern">*Enter Only
                            characters.
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <!--          <div class="col-md-6">-->
            <!--            <mat-form-field appearance="outline" class="col-sm-12">-->
            <!--              <mat-label>Id Proof</mat-label>-->
            <!--              <input matInput placeholder="Id Proof" formControlName="idproof">-->
            <!--              <mat-icon matSuffix>credit_card</mat-icon>-->
            <!--              <mat-error-->
            <!--                *ngIf="mealForm.controls.idproof.hasError('email') && !mealForm.controls.idproof.hasError('required')">-->
            <!--                Please enter Id proof-->
            <!--              </mat-error>-->
            <!--              <mat-error *ngIf="mealForm.controls.idproof.hasError('required')">-->
            <!--                Id Proof is <strong>required</strong></mat-error>-->
            <!--            </mat-form-field>-->


            <!--          </div>-->


            <!--------------------------------------------- PAYMENT BY  --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Payment By</mat-label>
                    <mat-select formControlName="paymentMethod" (selectionChange)="selectedPayment($event)">
                        <mat-option value="Cash">Cash</mat-option>
                        <mat-option value="Cheque">Cheque</mat-option>
                        <mat-option value="Netbanking">Netbanking</mat-option>
                        <mat-option value="UPI">UPI</mat-option>
                        <mat-option value="Credit/Debit Card">Credit/Debit Card</mat-option>
                        <mat-option value="Pending"> Pending</mat-option>
                    </mat-select>
                    <mat-error
                            *ngIf="mealForm.get('paymentMethod')?.invalid  &&  (mealForm.get('paymentMethod')?.touched || mealForm.get('paymentMethod')?.dirty)">
                        <span style="color: red;" *ngIf="mealForm.get('paymentMethod')?.errors?.required">*It is Required.</span>
                    </mat-error>
                </mat-form-field>

            </div>
            <!--------------------------------------------- REMARK --------------------------------------------->
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Remark/Transaction ID /Reference Number</mat-label>
                    <input matInput formControlName="remark">
                    <mat-error
                            *ngIf="mealForm.get('remark')?.invalid  &&  (mealForm.get('remark')?.touched || mealForm.get('remark')?.dirty)">
                        <span style="color: red;"
                              *ngIf="mealForm.get('remark')?.errors?.required">*It is Required.</span>
                    </mat-error>
                </mat-form-field>

            </div>
            <!--------------------------------------------- PAN --------------------------------------------->
            <div class="col-md-6">

                <mat-form-field appearance="outline" class="col-sm-12" *ngIf="showpan">
                    <mat-label>Pan #</mat-label>
                    <input matInput placeholder="PAN Number" class="col-sm-12" formControlName="pan">
                    <mat-error
                            *ngIf="mealForm.controls.pan && !mealForm.controls.pan.hasError('required') ">
                        Enter a valid pan <strong>e.g. ABCDE9600Z</strong></mat-error>
                    <mat-error *ngIf="mealForm.controls.pan.hasError('required') ">
                        PAN is <strong>required</strong></mat-error>
                </mat-form-field>
            </div>
<!--            <div class="col-md-6 ">-->
<!--                <mat-checkbox class="example-margin" formControlName="need80g" (change)="checkNeed80G($event)">Need-->
<!--                    80G-->
<!--                </mat-checkbox>-->

<!--            </div>-->
            <div class="col-md-6 col-sm-12" *ngIf="mealForm.get('paymentMethod')?.value=='Pending'">
                <mat-form-field appearance="outline">
                    <mat-label>Follow Up On</mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="Reminder" formControlName="followUpOn">
                    <mat-hint>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; DD/MM/YYYY
                    </mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error
                            *ngIf="mealForm.get('followUpOn')?.invalid  &&  (mealForm.get('followUpOn')?.touched || mealForm.get('followUpOn')?.dirty)">
                        <span style="color: red;"
                              *ngIf="mealForm.get('followUpOn')?.errors?.required">*It is Required.</span>
                    </mat-error>
                </mat-form-field>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
            </div>
            <div class="col-md-4">
                <button *ngIf="mealMatePermissions[0].all || mealMatePermissions[0].add" mat-raised-button [disabled]="disabled" (click)="addMeal()" type="submit" color="accent"
                        style="width: 100%;border-radius: 20px">SUBMIT
                </button>
            </div>
            <div class="col-md-4">
                <div class="heading" *ngIf="totalAmount!=0" style="background: #ff3d00;color: white;font-weight: bold">
                    Total
                    Amount:{{totalAmount}} INR
                </div>

            </div>
        </div>


    </form>

</div>
