import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
// import * as fs from 'file-saver';
import * as logo from './logo';
import fs from "file-saver";
import ExcelJS from 'exceljs';

declare module 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  constructor() { }
exportExcel(excelData:any){
  //Title, Header & Data
  const title = excelData.title;
  const header = excelData.headers
  const data = excelData.data;

  //Create a workbook with a worksheet
  const workbook = new ExcelJS.Workbook();
  let worksheet = workbook.addWorksheet('Export Data');
  // worksheet.mergeCells('C1', 'F4');
  // let titleRow = worksheet.getCell('C1');
  // titleRow.value = title
  // titleRow.font = {
  //   name: 'Calibri',
  //   size: 16,
  //   underline: 'single',
  //   bold: true,
  //   color: { argb: '0085A3' }
  // }
  // titleRow.alignment = { vertical: 'middle', horizontal: 'center' }
  //
  // // Date
  // worksheet.mergeCells('G1:H4');
  // let d = new Date();
  // let date = d.getDate() + '-' + d.getMonth()+1 + '-' + d.getFullYear();
  // let dateCell = worksheet.getCell('G1');
  // dateCell.value = date;
  // dateCell.font = {
  //   name: 'Calibri',
  //   size: 12,
  //   bold: true
  // }
  // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }
  //
  // //Add Image
  // let myLogoImage = workbook.addImage({
  //   base64: logo.imgBase64,
  //   extension: 'png',
  // });
  // worksheet.mergeCells('A1:B4');
  // worksheet.addImage(myLogoImage, 'A1:B4');
  //
  // //Blank Row
  // worksheet.addRow([]);

  //Adding Header Row
  let headerRow = worksheet.addRow(header);
  // @ts-ignore
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '4167B8' },
      bgColor: { argb: '' }
    }
    cell.font = {
      bold: true,
      color: { argb: 'FFFFFF' },
      size: 12
    }
  })
  data.forEach((item:any) => {

    const values = Object.values(item);
    worksheet.addRow(values);
  });

  // data.forEach((d: any) => {
  //     let row = worksheet.addRow(Object.values(d));
  //
  //     let sales = row.getCell(6);
  //     let color = 'FF99FF99';
  //     // @ts-ignore
  //   if (+sales.value < 200000) {
  //       color = 'FF9999'
  //     }
  //
  //     sales.fill = {
  //       type: 'pattern',
  //       pattern: 'solid',
  //       fgColor: { argb: color }
  //     }
  //   }
  // );

  worksheet.getColumn(3).width = 20;
  worksheet.addRow([]);

  //Footer Row
  // let footerRow = worksheet.addRow(['Report Generated from Accesslife Admin Panel ' + date]);
  // footerRow.getCell(1).fill = {
  //   type: 'pattern',
  //   pattern: 'solid',
  //   fgColor: { argb: 'FFB050' }
  // };

  //Merge Cells
  // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);
processWorkbook(workbook).then(()=>{
  workbook.xlsx.writeBuffer().then((data: BlobPart) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    fs.saveAs(blob, title + '.xlsx');

  })
})
  //Generate & Save Excel File


}

}
async function processWorkbook(book: ExcelJS.Workbook) {
  const worksheets = book.worksheets;

  for (const sheet of worksheets) {
    const headers: string[] = [];

    sheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      const item: { [key: string]: any } = {};

      if (rowNumber === 1) {
        row.eachCell((cell, colNumber) => {
          if (colNumber) {
            headers.push(cell.value as string);
          }
        });
      }

      if (rowNumber > 1) {
        const rowValues = row.values;
        headers.forEach((header, colNumber) => {
          // @ts-ignore
          item[header] = rowValues[colNumber + 1];
        });
      }

      console.log(headers, item);
    });
  }

}
